import React, { useContext } from 'react';
import { GridApi, GridOptions } from 'ag-grid-community';
import { cloneDeep } from 'lodash-es';
import { HospiceItemSetContext } from '../../context';
import { FormsCellRenderer } from './CellRenderer';
import { AgGridTable } from './Table';
import { HospiceItemSmallTable } from './HospiceItemSmallTable';
import { horizontal } from './constants';
interface HospiceItemSetTableProps {
  viewModePrint?: boolean;
}

const frameworkComponents = {
  formsCellRenderer: FormsCellRenderer,
};

export const HospiceItemExpandedTable = ({
  viewModePrint,
}: HospiceItemSetTableProps) => {
  const { hospiceInfo } = useContext(HospiceItemSetContext);

  const info = hospiceInfo.hisapprovedpatientitemdto.length
    ? hospiceInfo.hisapprovedpatientitemdto
    : hospiceInfo.hiscachedpatientitemdto;
  const infoShow = [...info].filter((i) => i.balwayshide === 'false');
  const labelInfo = infoShow.reduce((seed, item) => {
    // add an extra row on top
    if (item.extraprelabel) {
      const newRow = { ...item };
      newRow.itemdescription = newRow.extraprelabel;
      newRow.itemnumber = '';
      newRow.value = '';
      newRow.formname = '';
      newRow.formavailability = '';
      newRow.formpersoncredentials = '';
      newRow.formpersonfirstname = '';
      newRow.formpersonlastname = '';
      seed.push(newRow);
      seed.push(item);
    } else {
      seed.push(item);
    }
    return seed;
  }, []);

  const newInfo = cloneDeep(labelInfo).reduce((seed: any, item: any) => {
    // todo recurssive function
    // level 0
    if (item.parentitemnumber === '') {
      seed.push(item);
    } else {
      // level 1
      const itemIndex = seed.findIndex(
        ({ itemnumber }: any) => itemnumber === item.parentitemnumber,
      );
      if (itemIndex > -1) {
        const newInfoItem = seed[itemIndex];
        if (newInfoItem.callRecords) {
          newInfoItem.callRecords.push(item);
        } else {
          newInfoItem.callRecords = [item];
        }
        seed[itemIndex] = newInfoItem;
      } else {
        // not OK
        // level 2
        const newIndex = seed.length - 1;
        const newInfoRecords = seed[newIndex].callRecords;
        const itemIndex2 = newInfoRecords.findIndex(
          ({ itemnumber }: any) => itemnumber === item.parentitemnumber,
        );
        if (itemIndex2 > -1) {
          const newInfoItem = newInfoRecords[itemIndex2];
          if (newInfoItem.callRecords) {
            newInfoItem.callRecords.push(item);
          } else {
            newInfoItem.callRecords = [item];
          }
          seed[newIndex].callRecords = newInfoRecords;
        } else {
          // not OK
          // level 3
          const newIndex2 = newInfoRecords.length - 1;
          const newInfoRecords2 = newInfoRecords[newIndex2].callRecords;
          const itemIndex3 = newInfoRecords2.findIndex(
            ({ itemnumber }: any) => itemnumber === item.parentitemnumber,
          );
          const newIndex3 =
            itemIndex3 === -1 ? newInfoRecords2.length - 1 : itemIndex3;
          const newInfoItem = newInfoRecords2[newIndex3];
          if (newInfoItem.callRecords) {
            newInfoItem.callRecords.push(item);
          } else {
            newInfoItem.callRecords = [item];
          }
          seed[newIndex].callRecords[newIndex2].callRecords[
            newIndex3
          ] = newInfoItem;
        }
      }
    }
    return seed;
  }, []);

  const onFirstDataRendered = (params: any) => {
    setTimeout(function () {
      const rowNode5 = params.api.getRowNode(5);
      const rowNode6 = params.api.getRowNode(6);
      const rowNode7 = params.api.getRowNode(7);
      if (
        rowNode5 &&
        rowNode5.data &&
        rowNode5.data.value.toLowerCase() === 'yes'
      ) {
        params.api.getDisplayedRowAtIndex(5).setExpanded(true);
      }
      if (
        rowNode6 &&
        rowNode6.data &&
        rowNode6.data.value.toLowerCase() === 'yes'
      ) {
        params.api.getDisplayedRowAtIndex(6).setExpanded(true);
      }
      if (
        rowNode7 &&
        rowNode7.data &&
        rowNode7.data.value.toLowerCase() === 'yes'
      ) {
        params.api.getDisplayedRowAtIndex(7).setExpanded(true);
      }
    }, 100);
  };

  const getColor = (params: any) => {
    if (params.data.displaycolor === 'dark') {
      return 'dark-row no-border';
    }
    if (params.data.displaycolor === 'dark-1') {
      return 'dark-1-row no-border';
    }
    if (params.data.displaycolor === 'dark-2') {
      return 'dark-2-row no-border';
    }
    if (params.data.displaycolor === 'dark-3') {
      return 'dark-3-row no-border';
    }
    return 'dark-row no-border';
  };

  const columnDefs = [
    {
      headerName: 'HIS Item',
      field: 'itemnumber',
      cellRenderer: 'agGroupCellRenderer',
      cellClass: (params: any) => getColor(params),
      width: 95,
      suppressSizeToFit: true,
      suppressMenu: true,
    },
    {
      headerName: 'HIS Item Description',
      field: 'itemdescription',
      flex: 1,
      cellClass: (params: any) => getColor(params),
      suppressMenu: true,
    },
    {
      headerName: 'Response',
      field: 'value',
      width: 130,
      suppressSizeToFit: true,
      cellClass: (params: any) => getColor(params),
      suppressMenu: true,
    },
    {
      headerName: 'Source of Information',
      field: 'formname',
      flex: 1,
      cellClass: (params: any) => getColor(params),
      suppressMenu: true,
      cellRendererFramework: FormsCellRenderer,
    },
  ];

  const getDetailRowData = (params: any) => {
    params.successCallback(params.data.callRecords);
  };

  const detailGridOptions = {
    masterDetail: true,
    detailRowAutoHeight: true,
    isRowMaster(dataItem: any) {
      return !!(dataItem && dataItem.callRecords);
    },
    onFirstDataRendered,
    columnDefs,
    // Label columns
    headerHeight: 0,
    rowHeight: 26,
    defaultColDef: {
      resizable: true,
    },
  };

  // level 1 grid options
  const gridOptions: GridOptions = {
    ...detailGridOptions,
    // level 2 grid options
    detailCellRendererParams: {
      getDetailRowData,
      detailGridOptions: {
        ...detailGridOptions,
        groupDefaultExpanded: 1,
        // level 3 grid options
        detailCellRendererParams: {
          getDetailRowData,
          detailGridOptions: {
            ...detailGridOptions,
            groupDefaultExpanded: 1,
            // level 4 grid options
            detailCellRendererParams: {
              getDetailRowData,
              detailGridOptions: {
                ...detailGridOptions,
                groupDefaultExpanded: 1,
              },
            },
          },
        },
      },
    },
    onGridReady: (params: any) => {
      const gridApi: GridApi = params.api;
      gridApi.sizeColumnsToFit();
      if (viewModePrint) {
        gridOptions.domLayout = 'print';
      }
    },
  };

  return (
    <React.Fragment>
      <HospiceItemSmallTable mode={horizontal} />
      <AgGridTable
        gridOptions={gridOptions}
        rowData={newInfo || []}
        frameworkComponents={frameworkComponents}
      />
    </React.Fragment>
  );
};

export default HospiceItemExpandedTable;
