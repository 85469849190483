import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { WidgetPrintHeader } from '@mummssoftware/common-ui';
import CarePlanFrequencyTable from '../sections/CarePlanFrequencyTable';
import { CarePlanFrequencyContext } from '../../context';
import defaults from '../../translations/en.json';

const Container = styled.div`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  width: 100%;
`;

const CarePlanFrequencyPrintBody = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.common.white};
  @media print {
    page-break-after: always;
    color: ${(props) => props.theme.palette.common.black};
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;

const PrintTable = styled.div`
  width: 700px;
  margin: auto;
`;
// way to get next page break with global printing
const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;

const texts: NonNullable<mumms.JSONObject> = defaults;

type CarePlanFrequencyViewPrintProps = {
  plans?: any;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};

const CarePlanFrequencyViewPrint = (props: CarePlanFrequencyViewPrintProps) => {
  const { plans, printConfig } = useContext(CarePlanFrequencyContext);

  const { onWidgetReady } = props;

  // on component did mount
  useEffect(() => {
    // callback to alert dashboard the view is ready
    onWidgetReady && onWidgetReady('CarePlanFrequency' as mumms.ComponentName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CarePlanFrequencyPrintBody>
        <WidgetPrintHeader
          title={texts.printTitle as NonNullable<mumms.JSONObject>}
          printConfig={printConfig}
        />
        <PrintTable>
          <CarePlanFrequencyTable
            smallWidget={false}
            viewModePrint
            plans={plans}
          />
        </PrintTable>
      </CarePlanFrequencyPrintBody>
      <PageBreak />
    </Container>
  );
};

export default CarePlanFrequencyViewPrint;
