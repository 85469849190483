/* istanbul ignore file */
import ExpandedRadio from './ExpandedRadio';
import ExpandedSelectBoxes from './ExpandedSelectBoxes';
import NestedInputComponent from './NestedInputComponent';
import TextArea from './TextArea';
import { Components } from './Components';

const components = {
  expandedradio: ExpandedRadio,
  expandedselectboxes: ExpandedSelectBoxes,
  textarea: TextArea,
  nestedInputComponent: NestedInputComponent,
};

Components.setComponents(components);

export { Components };
export default components;

