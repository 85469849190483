import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useReducer,
  useState,
} from 'react';
import moment from 'moment-timezone';
import produce, { castDraft } from 'immer';
// eslint-disable-next-line
import type { KeycloakInstance } from 'keycloak';
import { FormsContext, FormsExtendedPatient } from '../../context';
import {
  ADD_SUBMISSION,
  SET_ERROR_STATE,
  SET_IS_NEW_FORM,
  SET_LATEST_SUBMISSIONS,
  SET_SUBMISSION_ID,
  SET_TOKEN,
  SET_BUTTON_STATE,
} from './constants';

let Formio: typeof import('@mummssoftware/form').Formio;
let getCurrentFormioUser: typeof import('@mummssoftware/form').getCurrentFormioUser;
const FormImport = import('@mummssoftware/form').then((mod) => {
  Formio = mod.Formio;
  getCurrentFormioUser = mod.getCurrentFormioUser;
});

type formIoSubmission = import('formiojs').submission;

type latestSubmissionsOption = {
  label: string;
  value: string;
};

type FormContext = {
  readonly latestSubmissionId: any | null;
  latestSubmissions: latestSubmissionsOption[] | [] | null;
  readonly newForm: boolean;
  readonly showFormSelector: boolean;
  dispatch: any;
  // the below get typescript complaints
  // dispatch: import('redux').Dispatch;
  readonly errorState: string;
  readonly buttonState: boolean;
};

type FormContextProviderProps = {
  children?: ReactNode;
  keycloak: KeycloakInstance;
  patient?: FormsExtendedPatient | null;
  notcoveredmeds?: mumms.notCoveredMeds[] | null;
  formName: string;
  agency?: string | null;
};

type FormContextState = {
  latestSubmissions: latestSubmissionsOption[] | null;
  token: string | null;
  latestSubmissionId: string | null;
  newForm: boolean;
  buttonState: boolean;
  errorState: any;
};

const initFormContext = {
  latestSubmissionId: null,
  latestSubmissions: null,
  newForm: false,
};

const initialState = {
  latestSubmissions: null,
  token: null,
  latestSubmissionId: null,
  newForm: false,
  buttonState: false,
  errorState: null,
};

const mapSubmission = (option: formIoSubmission) => ({
  value: (option._id as string).toString(),
  label: new Date(option.created as string),
});

const mapOption = (option: { value: string; label: Date }) => ({
  value: option.value,
  label: `${moment(option.label).format('dddd, MMMM Do YYYY, h:mm a')}`,
});

export const mapLatestSubmissions = (result: formIoSubmission[]) => {
  if (result.length === 0) {
    return result;
  }
  const datedResult = result.map(mapSubmission);
  const sortedResult = datedResult
    .sort((a: any, b: any) => b.label - a.label)
    // keep only the last 10 results
    .slice(0, 10)
    .map(mapOption);
  return sortedResult;
};

const FormContextReducer = (
  draft: FormContextState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case ADD_SUBMISSION:
      {
        const mappedSubmission = mapOption(mapSubmission(action.payload));
        if (Array.isArray(draft.latestSubmissions)) {
          // assuming is newest for now
          draft.latestSubmissions.unshift(mappedSubmission);
        } else {
          draft.latestSubmissions = [mappedSubmission];
        }
      }
      break;
    case SET_IS_NEW_FORM:
      draft.newForm = action.payload;
      break;
    case SET_LATEST_SUBMISSIONS:
      draft.latestSubmissions = action.payload;
      break;
    case SET_SUBMISSION_ID:
      draft.latestSubmissionId = action.payload;
      break;
    case SET_TOKEN:
      draft.token = action.payload;
      break;
    case SET_ERROR_STATE:
      draft.errorState = action.payload;
      break;
    case SET_BUTTON_STATE:
      draft.buttonState = action.payload;
      break;
    default:
      break;
  }
};

export const curriedFormContextReducer = produce(FormContextReducer);

export const FormContext = createContext<FormContext>(
  initFormContext as FormContext,
);

export const FormContextProvider = ({
  children,
  keycloak,
  patient,
  formName,
  agency,
}: FormContextProviderProps) => {
  const patientNumber =
    patient && patient.patientNumber ? patient.patientNumber : null;
  const admitDate =
    patient && patient.episodes ? patient.episodes[0].admitDate : null;
  const mountedRef = useRef(true);
  const [
    {
      latestSubmissions,
      token,
      latestSubmissionId,
      newForm,
      errorState,
      buttonState,
    },
    dispatch,
  ] = useReducer(curriedFormContextReducer, initialState);
  // showFormSelector if newForm => no current form being edited
  // showFormSelector is being hidden when editing a form
  const [showFormSelector, setShowFormSelector] = useState(
    !!(newForm && latestSubmissions && latestSubmissions.length && !errorState),
  );

  const LocalFormio = useRef<import('@mummssoftware/form').Formio | null>(null);
  const {
    meetingMetadata: { dateFrom, dateTo },
  } = useContext(FormsContext);

  const setUserToken = useCallback(() => {
    if (LocalFormio.current) {
      if (!LocalFormio.current.getToken()) {
        getCurrentFormioUser(LocalFormio.current, keycloak.token || '')
          .then((user: any) => {
            if (LocalFormio.current && mountedRef.current) {
              dispatch({
                type: SET_TOKEN,
                payload: LocalFormio.current.getToken(),
              });
            }
          })
          .catch((error: any) => {
            if (mountedRef.current) {
              dispatch({
                type: SET_ERROR_STATE,
                payload: error,
              });
            }
          });
      } else if (mountedRef.current) {
        dispatch({
          type: SET_TOKEN,
          payload: LocalFormio.current.getToken(),
        });
      }
    }
  }, [dispatch, keycloak.token]);

  useEffect(() => {
    const checkShowSelector = !!(
      newForm &&
      latestSubmissions &&
      latestSubmissions.length &&
      !errorState
    );
    if (showFormSelector !== checkShowSelector) {
      setShowFormSelector(checkShowSelector);
    }
  }, [showFormSelector, newForm, latestSubmissions, errorState]);

  // 1. set formio, set token
  useEffect(() => {
    const setFormioToken = async () => {
      await FormImport;
      LocalFormio.current = new Formio(
        `${Formio.getProjectUrl()}/${formName}/submission`,
      );
      setUserToken();
    };
    setFormioToken();
    // prevent loadSubmissions for now
    // return () => {
    //   LocalFormio.current = null;
    // };
  }, [setUserToken, formName]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      // TODO formio last forms issues
      const params =
        (formName === 'idg-lcd' && (admitDate || dateFrom)) ||
        (formName === 'idg-pgba' && (admitDate || dateFrom))
          ? {
              /* eslint-disable indent */
              'metadata.patientNumber': patientNumber,
              'metadata.bin': agency,
              select: '_id,created,owner',
              created__gte: dateFrom || admitDate,
              ...(dateTo ? { created__lte: dateTo } : {}),
              sort: '-created',
              limit: 70,
            }
          : {
              'metadata.patientNumber': patientNumber,
              'metadata.bin': agency,
              select: '_id,created,owner',
              sort: '-created',
              limit: 70,
            };
      // ERROR_STATE: if bad formName is passed error is `Invalid alias`
      // SET_ERROR_STATE => if there is an errorState then a reload would be needed, that's why I am not resetting it to null anywhere
      if (LocalFormio.current) {
        if (!LocalFormio.current.formId) {
          LocalFormio.current = new Formio(
            `${Formio.getProjectUrl()}/${formName}/submission`,
          );
        }
        await LocalFormio.current
          .loadSubmissions({
            params,
          })
          // result can actually only be an array or an empty array, null patientNumber response is []
          .then((result: formIoSubmission[]) => {
            if (mountedRef.current) {
              const submissions = result
                ? mapLatestSubmissions(result)
                : result;
              dispatch({
                type: SET_LATEST_SUBMISSIONS,
                payload: submissions,
              });
            }
          })
          .catch((error: any) => {
            if (mountedRef.current) {
              dispatch({
                type: SET_ERROR_STATE,
                payload: error,
              });
            }
          });
      }
    };
    if (token) {
      fetchSubmissions();
    }
  }, [patientNumber, token, formName, admitDate, dateFrom, dateTo, agency]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  const selectedSubmissionId =
    latestSubmissionId ||
    (Array.isArray(latestSubmissions) && latestSubmissions.length
      ? latestSubmissions[0]
      : null);

  const context = useMemo(
    () => ({
      showFormSelector,
      errorState,
      buttonState,
      latestSubmissionId: selectedSubmissionId,
      latestSubmissions: castDraft(latestSubmissions),
      newForm,
      agency,
      dispatch,
    }),
    [
      showFormSelector,
      errorState,
      buttonState,
      selectedSubmissionId,
      latestSubmissions,
      newForm,
      agency,
      dispatch,
    ],
  );
  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};

FormContextProvider.displayName = 'FormContextProvider';

export default FormContextProvider;
