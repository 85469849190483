import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _isNil from "lodash-es/isNil";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Request from './Request';
import { getLdapDn, getAgency, validateAgency } from '../auth';
export class PimPgRest extends Request {
  constructor({
    url,
    keycloak,
    agency,
    signal
  }) {
    super({
      url,
      signal
    });

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      Agency: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "query", async (table, queryParams, schema) => {
      let headers = {};

      if (schema) {
        headers = _objectSpread(_objectSpread({}, headers), {}, {
          'Accept-Profile': schema
        });
      } else {
        // default to the public schema
        headers = _objectSpread(_objectSpread({}, headers), {}, {
          'Accept-Profile': 'public'
        });
      }

      const response = await this.get({
        api: `/api/query/${table}`,
        qs: queryParams,
        headers
      });
      return response;
    });

    _defineProperty(this, "getIdgReviews", async (patientNumber, queryParams = {}) => {
      const response = await this.get({
        api: `/api/idgreviews`,
        qs: queryParams
      });
      return response;
    });

    const headerAgency = agency && validateAgency(keycloak.tokenParsed, agency) ? agency : getAgency(getLdapDn(keycloak.tokenParsed));

    if (!headerAgency) {
      // TODO: better error message
      throw new Error(`could not find agency`);
    }

    this.url = new URL(url);
    this.keycloak = keycloak;
    this.commonHeaders.Agency = headerAgency;
    Object.defineProperty(this.commonHeaders, 'Authorization', {
      get() {
        const {
          token
        } = keycloak;
        return `Bearer ${token}`;
      }

    });
  }

  /**
   * Override method from Request class...
   *
   * For pgrest calls, we need to process the parameters in a particular order.
   * More work may need to be done here, but at the very least, we want to ensure
   * that the 'select' parm is first.
   *
   * We may want to eventually build out a PgRest request builder, but I don't think
   * we have enough information at this time to define the Acceptance Criteria
   * necessary to do that properly.
   */
  setParamsFromQs(queryString) {
    if (queryString) {
      const sortedQuerystring = Object.entries(queryString).sort((a, b) => {
        if (a[0] === 'select') return -1;else if (b[0] === 'select') return 1;
        return 0;
      });
      sortedQuerystring.forEach(([k, v]) => {
        if (!_isNil(v)) {
          this.setParam(k, v);
        }
      });
    }
  }

}
export default PimPgRest;