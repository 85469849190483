/** Setting up files/exports in here to make sure everything is using the same instance */
import {
  Templates,
  Form as ReactFormio,
  FormBuilder as ReactFormBuilder,
  Formio,
  Utils as FormioUtils,
  WebformBuilder,
} from 'react-formio';
import components, { Components } from '../components';
import * as customTemplates from '../templates';
 
const templates = {
  ...customTemplates,
};

Templates.current = templates;
Formio.setToken('');
Formio.logout();
Formio.setApiUrl(process.env.REACT_APP_FORMIO_URL as string);

export {
  components,
  Components,
  ReactFormio,
  Formio,
  FormioUtils,
  ReactFormBuilder,
  templates,
  Templates,
  WebformBuilder,
};
