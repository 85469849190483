import React, { useContext } from 'react';
import { ActionIcon } from '@mummssoftware/common-ui';
import { Printer } from '@mummssoftware/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { WidgetMenu } from '../WidgetMenu';
import { SideBarDiv, SidebarIcon } from '../../components/layout/WrapperDiv';
import { DashboardContext } from '../Context/DashboardContext';
import { Context } from '../../context';
import {
  SET_PRINT_INTERFACE_OPEN,
  REFRESH_DASHBOARD,
  patientDashboard,
} from '../Context/constants';
import { WidgetConfig } from '../Context/types';
import { ThemeMenu } from '../ThemeMenu';
type SideBarProps = {
  config: WidgetConfig[];
  topBarHeight: number;
};
// TODO fix ActionIcon in common ui
export const SideBar = ({ config, topBarHeight }: SideBarProps) => {
  const { dispatch } = useContext(DashboardContext);
  const { dashboardType } = useContext(Context);

  const openPrintInterface = () =>
    dispatch({
      type: SET_PRINT_INTERFACE_OPEN,
      payload: true,
    });
  const refreshDashboard = () =>
    dispatch({
      type: REFRESH_DASHBOARD,
    });

  const getRibbonIcons = () =>
    dashboardType === patientDashboard ? (
      <React.Fragment>
        <ActionIcon
          Icon={RefreshIcon}
          title="Refresh"
          style={{
            marginTop: 11,
            marginLeft: 2,
            marginRight: 0,
            width: 20,
            height: 20,
          }}
          onClick={refreshDashboard}
        />
        <ActionIcon
          Icon={Printer}
          title="Print"
          style={{
            marginTop: 11,
            marginLeft: 2,
            marginRight: 0,
            width: 20,
            height: 20,
          }}
          onClick={openPrintInterface}
        />
      </React.Fragment>
    ) : null;

  return (
    <SideBarDiv id="sidebar">
      <SidebarIcon topBarHeight={topBarHeight}>
        <ThemeMenu />
        {getRibbonIcons()}
        <WidgetMenu config={config} />
      </SidebarIcon>
    </SideBarDiv>
  );
};
