export const daystodeadline = 'daystodeadline';
export const demographicsstatus = 'demographicsstatus';
export const bshowadminactionbuttons = 'bshowadminactionbuttons';

export const approval = 'approval';
export const download = 'download';
export const cmsapproved = 'cmsapproved';

export const hisadmineventdto = 'hisadmineventdto';
export const hiscachedpatientitemdto = 'hiscachedpatientitemdto';

export const redColor = '#FD5C63';
export const greenColor = '#ACE1AF';

export const Sent = 'Sent';
export const APR = 'APR';
export const DWN = 'DWN';
export const SENT = 'SENT';
export const ACCPT = 'ACCPT';

export const APPROVE = 'APPROVE';
export const CMS_ACCEPTANCE = 'CMS ACCEPTANCE';

export const vertical = 'vertical'
export const horizontal = 'horizontal';

// todo translation files
export const hospiceData = [
  {
    name: 'Days To Deadline',
    key: daystodeadline,
    title: daystodeadline,
  },
  {
    name: 'Approval Available',
    key: hisadmineventdto,
    title: approval,
  },
  {
    name: 'Download and/or Send',
    key: hisadmineventdto,
    title: download,
  },
  {
    name: 'CMS Approved ',
    key: hisadmineventdto,
    title: cmsapproved,
  },
];
