import React, { useContext, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useComponentHeight, Watermark } from '@mummssoftware/common-ui';
import { AppContext } from '../App/AppContext';
import { MedContext } from '../App/MedContext';
import { SET_DIMENSIONS } from '../App/constants';

// TODO improve ActionTopBar Widget
const ActionTopBar = styled.div`
  ${(props) => (props.embedded ? '' : css``)}
  ${(props) => (props.hideActionBar ? 'display: none;' : '')}
  ${(props) =>
    props.dashboardEmbedded
      ? `border-right: 14px solid ${props.theme.palette.background.paper};`
      : ''}  
  width: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: ${(props) => `${props.theme.props.header.background}`};
  @media print {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  ${(props) =>
    props.embedded
      ? 'position: relative;'
      : css`
          margin-top: ${props.height}px;
          height: calc(100vh - ${props.height}px);
        `}
  @media print {
    overflow: visible;
    height: auto;
    margin: 0;
  }
`;

const MedicationContainer = styled.div`
  list-style-type: 'none';
  && {
    overflow: visible;
  }
  && > div:last-child > div {
    border-bottom: none;
  }
  @media print {
    display: none;
  }
`;

const Body = ({
  TopBarContent,
  Medications,
  Dialog,
  PrintView,
  headerRef,
  embedded,
  dashboardEmbedded,
}) => {
  const height = useComponentHeight(headerRef);
  const actionBarRef = useRef(null);
  const { hideActionBar, watermark, smallWidget } = useContext(AppContext);
  const { dispatch } = useContext(MedContext);

  useEffect(() => {
    if (actionBarRef.current && !smallWidget) {
      dispatch({
        type: SET_DIMENSIONS,
        payload: actionBarRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <ContentWrapper embedded={embedded} height={height}>
      {watermark && watermark.show ? (
        <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
      ) : null}
      <ActionTopBar
        embedded={embedded}
        hideActionBar={hideActionBar}
        dashboardEmbedded={dashboardEmbedded}
        ref={actionBarRef}
        id="actiontopbar"
      >
        {TopBarContent}
      </ActionTopBar>
      <MedicationContainer embedded={embedded}>
        {Medications}
      </MedicationContainer>
      {Dialog}
      {PrintView}
    </ContentWrapper>
  );
};

export default React.memo(Body);
