import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';
import { ExpandingTextField } from './ExpandingTextField';

const useStyles = makeStyles(() => ({
  paperWidthXs: {
    maxWidth: '260px',
  },
}));

export const CoveredDialog = ({ open, onClose, medication, handleChange }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const onNotCoveredReasonChange = useCallback(
    (reason) => {
      handleChange(medication, 'notcoveredreason', reason);
    },
    [handleChange, medication],
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="covered-status-selection"
      open={open}
      maxWidth="xs"
      classes={{ paperWidthXs: classes.paperWidthXs }}
    >
      <DialogTitle id="covered-dialog-title" disableTypography>
        {medication.drugdescription}
      </DialogTitle>
      <DialogContent dividers>
        <div>
          {medication.coveredbyhospice === 'false' ? (
            <ExpandingTextField
              onNotCoveredReasonChange={onNotCoveredReasonChange}
              notCoveredReason={medication.notcoveredreason}
              label={<FormattedMessage {...messages.reasonNotCovered} />}
              handleClose={handleClose}
            />
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};
