import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment-timezone';
import Request from './Request';
import PimPgRest from './PimPgRest';
import { uniqueByKey } from '../data';
export class Pim extends Request {
  constructor({
    url,
    pgRestUrl,
    keycloak,
    agency: _agency,
    signal
  }) {
    super({
      url,
      signal
    });

    _defineProperty(this, "pgRest", void 0);

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "flattenAndSortByKey", (users, key) => uniqueByKey(users.flat().sort((a, b) => {
      if (a.lastName === b.lastName) {
        return a.firstName > b.firstName ? 1 : -1;
      }

      return a.lastName > b.lastName ? 1 : -1;
    }), key));

    _defineProperty(this, "getPatientsByName", async name => name);

    _defineProperty(this, "getPatientList", async (siteId, tenId, agency, searchValue, eventDate, teamId) => {
      const response = await this.get({
        api: `/web/api/patients/name/v2/${searchValue}`,
        qs: {
          siteId,
          tenId,
          agency,
          activeDate: eventDate,
          teamId
        }
      });
      return 'data' in response ? response.data : response;
    });

    _defineProperty(this, "getPatientHeader", async (patientNumber) => {
      const response = await this.get({
        api: `/web/api/patients/${patientNumber}/patientHeader`
      });
      return 'entity' in response ? response.entity : response;
    });

    _defineProperty(this, "getMeds", async (patientNumber) => {
      const response = await this.get({
        api: `/web/api/drfirst/medications`,
        qs: {
          patientNumber
        }
      });
      return response;
    });

    _defineProperty(this, "getPIMUsersByRoles", (siteId, ...args) => {
      const baseRequest = roleId => this.get({
        api: `/web/api/persons/name/v2`,
        qs: {
          siteId,
          roleId
        }
      });

      return Promise.all(args.map(val => baseRequest(val)));
    });

    _defineProperty(this, "getAllDoctors", async siteId => {
      const workers = await this.getPIMUsersByRoles(siteId, '-9', '-11', '-51', '-66', '-10');
      return this.flattenAndSortByKey(workers, 'personNumber');
    });

    _defineProperty(this, "getAllEmployees", async siteId => {
      const workers = await this.getPIMUsersByRoles(siteId, '-4', '-5', '-7', '-8');
      return this.flattenAndSortByKey(workers, 'personNumber');
    });

    _defineProperty(this, "getWorkerList", async (siteId, agency, searchValue, teamId, roleId) => {
      const response = await this.get({
        api: `/web/api/persons/name/${searchValue}`,
        qs: {
          siteId,
          agency,
          searchValue,
          teamId,
          roleId
        }
      });
      return response;
    });

    _defineProperty(this, "getAllTeams", async (agency, tenId, siteId) => {
      const response = await this.get({
        api: '/web/api/teams',
        qs: {
          agency,
          tenId,
          siteId
        }
      });
      return response;
    });

    _defineProperty(this, "getUserInfo", async (RESTRICTED_ROLES) => {
      const response = await this.get({
        api: '/web/api/user/currentinfo'
      });

      if ('personId' in response) {
        const {
          credentials: userCredentials,
          currentHospiceBin: dataHospiceBin,
          currentSiteId: siteId,
          personId: userId,
          sites: siteList,
          siteTimezone: timezone,
          authorityList,
          firstName,
          hbcFirm,
          hospiceName,
          lastName,
          terminationDate,
          userRoleGroups,
          userRoles
        } = response,
              rest = _objectWithoutProperties(response, ["credentials", "currentHospiceBin", "currentSiteId", "personId", "sites", "siteTimezone", "authorityList", "firstName", "hbcFirm", "hospiceName", "lastName", "terminationDate", "userRoleGroups", "userRoles"]);

        const currentHospiceBin = dataHospiceBin !== 'mummsupport' ? dataHospiceBin : null;
        const currentSite = siteList.length ? siteList.find(({
          id
        }) => id === siteId) : null;
        const siteName = currentSite ? currentSite.name : '';
        const siteNickname = currentSite ? currentSite.nickName : null;
        const nonCertifyingRoles = Array.isArray(userRoles) && Array.isArray(RESTRICTED_ROLES) ? userRoles.find(role => RESTRICTED_ROLES.includes(role)) : false;
        const isNPRole = Array.isArray(userRoles) ? userRoles.includes('NP') : false;
        const doctorRole = Array.isArray(userRoleGroups) ? userRoleGroups.includes('Doctor') : false;
        const nurseRole = Array.isArray(userRoleGroups) ? userRoleGroups.includes('Nurse') : false;
        const isMumms = Array.isArray(userRoles) && Array.isArray(authorityList) ? userRoles.includes('MAD') && authorityList.includes('ROLE_ADMIN') : false;
        const isDoctor = doctorRole && !nonCertifyingRoles;
        const isNP = doctorRole && !!isNPRole;
        const isRoleGroupsWithEditCred = doctorRole || nurseRole;

        this.keycloak.onAuthRefreshSuccess = () => {
          this.logoutOnTermination(terminationDate, timezone);
        };

        this.logoutOnTermination(terminationDate, timezone);
        return _objectSpread({
          currentHospiceBin,
          isMumms,
          firstName,
          lastName,
          userId,
          userCredentials,
          hbcFirm,
          hospiceName,
          siteList,
          siteName,
          siteNickname,
          siteId,
          isDoctor,
          isNP,
          isRoleGroupsWithEditCred,
          userRoles,
          userRoleGroups,
          siteTimezone: timezone
        }, rest);
      }

      return response;
    });

    _defineProperty(this, "pgFunction", async callback => {
      if (this.pgRest) {
        return callback();
      } // eslint-disable-next-line


      console.warn('Initialize with pgRestUrl to use this function');
      return null;
    });

    _defineProperty(this, "query", (table, queryParams, schema) => this.pgFunction(() => this.pgRest.query(table, queryParams, schema)));

    _defineProperty(this, "getIdgReviews", (patientNumber, queryParams) => this.pgFunction(() => this.pgRest.getIdgReviews(patientNumber, queryParams)));

    _defineProperty(this, "getPatientDetail", async (patientId, agency, tenId, siteId) => {
      const response = await this.get({
        api: `/web/api/patients/${patientId}`,
        qs: {
          agency,
          tenId,
          siteId
        }
      });
      return response;
    });

    _defineProperty(this, "getPersonDetail", async (personId, agency, tenId, siteId) => {
      const response = await this.get({
        // override the params.agency with the one passed in (if necessary
        api: `/web/api/persons/${personId}`,
        qs: {
          agency,
          tenId,
          siteId
        }
      });
      return response;
    });

    _defineProperty(this, "getPersonRoles", async (personId, agency, tenId, siteId) => {
      const response = await this.get({
        api: `/web/api/persons/${personId}/roles`,
        qs: {
          agency,
          tenId,
          siteId
        }
      });
      return response;
    });

    _defineProperty(this, "getRoleById", async (roleId, agency, tenId, siteId) => {
      const response = await this.get({
        // override the params.agency with the one passed in (if necessary
        api: `/web/api/roles/${roleId}`,
        qs: {
          agency,
          tenId,
          siteId
        }
      });
      return response;
    });

    _defineProperty(this, "getPatientEpisodes", async (agency, tenId, siteId, patIdList, teamId, startDate, endDate) => {
      const response = await this.post({
        api: '/web/api/patients/episodes/',
        qs: {
          agency,
          tenId,
          siteId,
          startDate,
          endDate
        },
        data: patIdList
      });
      return response;
    });

    this.url = new URL(url);
    this.keycloak = keycloak;

    if (_agency) {
      this.url.searchParams.append('agency', _agency);
    }

    if (pgRestUrl) {
      this.pgRest = new PimPgRest({
        url: pgRestUrl,
        agency: _agency,
        keycloak
      });
    }

    Object.defineProperty(this.commonHeaders, 'Authorization', {
      get() {
        const {
          token
        } = keycloak;
        return `Bearer ${token}`;
      }

    });
  }

  logoutOnTermination(terminationDate, timezone) {
    if (terminationDate && moment().isAfter(moment(terminationDate).tz(timezone).format('YYYY-MM-DD'))) {
      this.keycloak.logout();
    }
  }

}
export default Pim;