import React from 'react';
import { ThemeProvider } from '@mummssoftware/common-ui';
import { ReactFormBuilder, templates } from './utils/internal';
import { setParseMissingKeyHandler, attachRippleEffect } from './utils';
import globalStyles from './globalStyles';
import labels from './data/test.json';
import labelsEs from './data/test-es.json';
// import testForm from './data/form-test.json';
import testForm from './data/clinicaldeclinelcd-1.json';

import './style/bootstrap.css';
import 'formiojs/dist/formio.form.css';

interface FormBuilderProps {
  form?: any;
}

export default class FormBuilder extends React.PureComponent<FormBuilderProps> {
  constructor(props: FormBuilderProps) {
    super(props);
    this.defaultNS = 'common';
    this.parseMissingKeyHandler = setParseMissingKeyHandler(
      labels,
      this.defaultNS,
    );
  }

  defaultNS: keyof typeof labels;

  parseMissingKeyHandler: ReturnType<typeof setParseMissingKeyHandler>;

  componentDidMount() {
    attachRippleEffect();
  }

  render() {
    const form = this.props.form || testForm;
    return (
      <ThemeProvider globalStyles={globalStyles}>
        <div style={{ margin: 20 }}>
          <ReactFormBuilder
            options={{
              templates,
              i18n: {
                ns: ['common', 'karnovsky', 'clinical-decline'],
                defaultNS: 'common',
                fallbackNS: ['common'],
                parseMissingKeyHandler: this.parseMissingKeyHandler,
                // debug: true,
                resources: {
                  en: labels,
                  es: labelsEs,
                },
              },
            }}
            form={form}
          />
        </div>
      </ThemeProvider>
    );
  }
}
