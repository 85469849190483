import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldBasic } from '@mummssoftware/common-ui';

import messages from './Messages';
import { DNRField, PhysicianField, PayerField } from './BasicComponents';

export const PatientAssignments = ({ classes, patient, currentCert }) => (
  <Fragment>
    <FieldBasic
      classes={classes}
      label={<FormattedMessage key={messages.ssn.id} {...messages.ssn} />}
      data={patient.ssn}
    />
    <DNRField classes={classes} patient={patient} />
    <PhysicianField classes={classes} patient={patient} />
    <PayerField classes={classes} currentCert={currentCert} />
    <FieldBasic
      classes={classes}
      label={<FormattedMessage key="idg-team" id="IDG Team" />}
      data={patient.teamPersonList}
    />
    {/* <FieldBasic
    label="CG"
    data={patient.primaryCaregiver}
  /> */}
  </Fragment>
);
