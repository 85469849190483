import React, { useImperativeHandle } from 'react';
import { find } from 'lodash-es';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  daystodeadline,
  approval,
  download,
  cmsapproved,
  greenColor,
  ACCPT,
  SENT,
  APR,
  DWN,
  APPROVE,
  CMS_ACCEPTANCE,
  vertical,
} from './constants';
import {
  CellDiv,
  StyledSpan,
  StyledButton,
  DownloadIcon,
} from '../../components/layout/WrapperDiv';
import { HisAdminStatusShortName, CellType } from './types';

const iconStyle = { display: 'flex', alignSelf: 'center' };

export const QuotaCellRenderer = React.forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    getReactContainerClasses() {
      return ['custom-cell'];
    },
    getReactContainerStyle() {
      return {
        display: 'inline-block',
        width: '100%',
      };
    },
  }));
  let title: any;

  const { mode, postData, data } = props;

  if (mode === vertical) {
    title = data && data.title;
  } else {
    title = props && props.title;
  }

  const value = props && props.value.value;

  // eslint-disable-next-line consistent-return
  const getCellUI = (
    type?: CellType,
    text?: string,
    color?: string,
    shortName?: HisAdminStatusShortName,
  ) => {
    switch (type) {
      case 'string':
        return (
          <CellDiv className="custom-cell">
            <StyledSpan color={color}>{text}</StyledSpan>
          </CellDiv>
        );
      case 'button': // can be APPROVE or CMS ACCEPTANCE => can be a post or a put
        return (
          <CellDiv className="custom-cell">
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => postData(shortName, 'POST')}
            >
              {text}
            </StyledButton>
          </CellDiv>
        );
      case 'downloaded':
        return (
          <CellDiv className="custom-cell">
            <StyledButton
              id="downloaded"
              variant="contained"
              color="primary"
              onClick={() => postData(DWN, 'POST')}
            >
              DOWNLOAD
            </StyledButton>
          </CellDiv>
        );
      case 'sent':
        return (
          <CellDiv className="custom-cell">
            <DownloadIcon>
              <GetAppIcon onClick={() => postData(DWN, 'POST')} />
            </DownloadIcon>
            <StyledButton
              id="sent"
              variant="contained"
              color="primary"
              onClick={() => postData(SENT, 'POST')}
            >
              MARK SENT
            </StyledButton>
          </CellDiv>
        );
      default:
        return <CellDiv className="custom-cell"></CellDiv>;
    }
  };

  const getStringValue = (stringdata: any, text: string) => {
    let newValue;
    const { personlastname, personfirstname, statusdate } = stringdata;
    if (personfirstname && personlastname) {
      newValue = `${text} by ${personfirstname} ${personlastname} on ${statusdate}`;
    } else {
      newValue = `${text} on ${statusdate}`;
    }
    return newValue;
  };

  const getUI = () => {
    let type: CellType = null;
    let color;
    let newValue;
    let method;
    let shortName: HisAdminStatusShortName = null;
    if (title === daystodeadline) {
      newValue = value;
      type = 'string';
    }
    if (title === approval) {
      shortName = APR;
      if (!value.length) {
        newValue = APPROVE;
        type = 'button';
      }
      if (value.length) {
        color = greenColor;
        type = 'string';
        const approved = find(value, {
          hisadminstatusshortname: APR,
        });
        if (approved) {
          newValue = getStringValue(approved, 'Approved');
        }
      }
    }
    if (title === download) {
      if (value.length) {
        const approved = find(value, {
          hisadminstatusshortname: APR,
        });
        const downloaded = find(value, {
          hisadminstatusshortname: DWN,
        });
        const sent = find(value, {
          hisadminstatusshortname: SENT,
        });

        if (sent) {
          newValue = getStringValue(sent, 'Sent');
          type = 'string';
          color = greenColor;
        } else if (approved && downloaded) {
          type = 'sent';
          newValue = SENT;
          shortName = SENT;
        } else if (approved && !downloaded) {
          type = 'downloaded';
        }
      }
    }
    if (title === cmsapproved) {
      if (value.length) {
        const sent = find(value, {
          hisadminstatusshortname: SENT,
        });
        const cmsAccepted = find(value, {
          hisadminstatusshortname: ACCPT,
        });

        if (cmsAccepted) {
          newValue = getStringValue(cmsAccepted, 'CMS Accepted');
          type = 'string';
          color = greenColor;
        } else if (sent) {
          newValue = CMS_ACCEPTANCE;
          type = 'button';
          shortName = ACCPT;
        }
      }
    }

    return getCellUI(type, newValue, color, shortName);
  };
  return getUI();
});

export const FormsCellRenderer = React.memo((props: any) => {
  const { data } = props && props;
  const {
    formpersoncredentials,
    formpersonfirstname,
    formpersonlastname,
    formdate,
    formname,
  } = data;
  let text;
  if (formpersonlastname && formpersonlastname && formdate) {
    text = `${formname} by ${formpersoncredentials} ${formpersonfirstname} ${formpersonlastname} on ${formdate}`;
  } else {
    text = `${formname}`;
  }

  return (
    <CellDiv>
      <span>{text}</span>
    </CellDiv>
  );
});
