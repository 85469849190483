import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  ActionIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  SectionHeader,
} from '@mummssoftware/common-ui';
import { AppContext } from '../../App/AppContext';

const StyledSpan = styled.span`
  ${(props) => (props.smallWidget ? `font-size: 16px;` : ';')}
  ${(props) =>
    props.smallWidget && props.small
      ? `font-size: 14px;`
      : ';'}
  display: 'inline-block';
`;
export const EpisodeExpansion = ({
  title,
  smallWidget,
  isExpanded,
  children,
  small,
}) => {
  const intl = useIntl();
  const handleChange = () => {
    setExpanded(!expanded);
  };
  const [expanded, setExpanded] = useState(isExpanded);

  const { siteTimezone } = useContext(AppContext);

  let episodeTitle;

  if (!title.referralDate) {
    episodeTitle = title;
  } else {
    let referralDateFormatted;
    let admitDateFormatted;
    let dischargeDateFormatted;

    if (siteTimezone) {
      const { utcAdmitDate, utcDischargeDate, utcReferralDate } = title;
      const admitDate = utcAdmitDate && moment.tz(utcAdmitDate, siteTimezone);
      admitDateFormatted = admitDate && moment(admitDate).format('MM/DD/YYYY');
      const referralDate =
        utcReferralDate && moment.tz(utcReferralDate, siteTimezone);
      referralDateFormatted =
        referralDate && moment(referralDate).format('MM/DD/YYYY');
      const dischargeDate =
        utcDischargeDate && moment.tz(utcDischargeDate, siteTimezone);
      dischargeDateFormatted =
        dischargeDate && moment(dischargeDate).format('MM/DD/YYYY');
    } else {
      // if there is not timezone use admitDate
      admitDateFormatted = title.admitDate;
      referralDateFormatted = title.referralDate;
      dischargeDateFormatted = title.dischargeDate;
    }

    if (referralDateFormatted && admitDateFormatted && dischargeDateFormatted) {
      episodeTitle = `R: ${referralDateFormatted} - A: ${admitDateFormatted} - D: ${dischargeDateFormatted}`;
    } else if (referralDateFormatted && admitDateFormatted) {
      episodeTitle = `R: ${referralDateFormatted} - A: ${admitDateFormatted}`;
    } else if (referralDateFormatted) {
      episodeTitle = `R: ${referralDateFormatted}`;
    }
  }

  const EpisodeTitle = () => (
    <StyledSpan smallWidget={smallWidget} small={small}>
      {episodeTitle}
    </StyledSpan>
  );

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange}>
      <ExpansionPanelSummary>
        <SectionHeader
          small={small}
          action={
            <ActionIcon
              title={
                expanded
                  ? intl.formatMessage({ id: 'Collapse' })
                  : intl.formatMessage({ id: 'Expand' })
              }
              Icon={expanded ? KeyboardArrowDown : KeyboardArrowRight}
            />
          }
          title={<EpisodeTitle />}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails top>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
