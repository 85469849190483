/* eslint-disable indent */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { auth } from '@mummssoftware/utils';
import { Watermark, WidgetHeader } from '@mummssoftware/common-ui';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import FacesheetComponent from './containers/FacesheetComponent';
import Providers from './Providers';
import { DEFAULT_LOCALE } from './i18n';

const Container = styled.div`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
  @media print {
    overflow: visible;
    visibility: hidden;
  }
`;

type FacesheetProps = {
  /* whether the app is off or online */
  online: boolean;
  keycloak?: Keycloak.KeycloakInstance | null;
  agency?: string | null;
  theme?: string | null;
  locale?: keyof mumms.translations;
  patientNumber?: string;
  /* if offline provide the html content */
  htmlContent?: any;
  appTitle?: string;
  onClick?: () => void;
  /* DASHBOARD- smallWidget mode (NEEDED bc when dashboard in XS mode problem with width calc */
  smallWidget?: boolean;
  /* If watermark should display on top of the widget. */
  /* If readonly = true, the widget will not be interactive */
  watermark?: {
    show: boolean;
    readOnly: boolean;
  };
  viewMode?: mumms.viewMode;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};

interface FacesheetState {
  html: any | null;
}

class Facesheet extends React.PureComponent<FacesheetProps, FacesheetState> {
  online: boolean;

  agency: string | undefined;

  constructor(props: FacesheetProps) {
    super(props);
    this.online = props.online;

    this.state = {
      html: null,
    };

    const { keycloak, agency } = props;

    if (keycloak && this.online) {
      // avoid logic is agency is passed as props
      if (agency) {
        this.agency = agency;
      } else {
        // if the agency has not been passed as props and this could be an admin check if in url
        const query = new URLSearchParams(window.location.search);
        const adminAgency = query.get('agency') as string;
        if (adminAgency) {
          this.agency = adminAgency;
        } else {
          // look for agency in keycloack for nonAdmin
          const parsedAgency = auth.getLdapDn(keycloak.idTokenParsed);
          this.agency = auth.getAgency(parsedAgency);
        }
      }
    }
  }

  getFacesheetData = async () => {
    if (this.online) {
      const { token } = this.props.keycloak as Keycloak.KeycloakInstance;
      const { patientNumber } = this.props;

      const url = `${process.env.REACT_APP_FACESHEET_URL}/v1/facesheets/${this.agency}/${patientNumber}.html`;
      const decoder = new TextDecoder('utf-8');
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          if (response.status === 404) {
            this.setState({ html: `${response.status}: Not Found` });
          } else {
            const reader = response.body.getReader();
            let html: any = '';

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;

            reader.read().then(function processText({ done, value }: any) {
              if (done) {
                self.setState({ html });
                return;
              }
              html += decoder.decode(value);

              // eslint-disable-next-line consistent-return
              return reader.read().then(processText);
            });
          }
          this.props.onWidgetReady &&
            this.props.onWidgetReady('Facesheet' as mumms.ComponentName);
        })
        .catch((err) => this.setState({ html: 'Error fetching Facesheet' }));
    } else {
      this.setState({ html: this.props.htmlContent });
    }
  };

  componentDidMount() {
    this.getFacesheetData();
  }

  printComponentRef: any;

  render() {
    const { html } = this.state;

    // NOTE remove body affecting page css
    // NOTE remove position fixed affecting app css
    // NOTE replace white for a less bright color
    const HTML = html
      ? html
          .replace('fixed', 'relative')
          .replace(/<body>/, '<body class="facesheet">')
          // limit body styling to "facesheet" class
          .replace(/body *{/g, 'body .facesheet {')
          .replace(
            /'.printFontColor {'/g,
            '.printFontColor { font-size: 10px; ',
          )
          .replace(
            /'.printFontColorBold {'/g,
            '.printFontColorBold { font-size: 10px; ',
          )
          .replace(
            /'<table class="hb-printer-section" align="center">'/g,
            '<table class="facesheet hb-printer-section" align="center">',
          )
          // limit div styling to "facesheet" class
          .replace(/div(.*){/g, '.facesheet div$1 {')
          // limit #content id styling to "facesheet" class
          .replace(/#content *{/g, '#content.facesheet-content {')
          // add "facesheet" stylename to #content id
          .replace(/id="content"/g, 'id="content" class="facesheet-content"')
          // limit #map-canvas id styling to "facesheet" class
          .replace(/#map-canvas *{/g, '#map-canvas.facesheet-map-canvas {')
          // add "facesheet" stylename to #map-canvas id
          .replace(
            /id="map-canvas"/g,
            'id="map-canvas" class="facesheet-map-canvas"',
          )
          // limit #@page styling to "facesheet" class (possibly ignored?)
          .replace(/@page(.*){/g, '.facesheet @page$1 {')
          // limit #@page styling to "facesheet" class (possibly ignored?)
          .replace(/@keyframes(.*){/g, '.facesheet @keyframes$1 {')
          // limit #@page styling to "facesheet" class (possibly ignored?)
          .replace(/@-webkit-keyframes *{/g, '.facesheet @-webkit-keyframes {')
          // fix bad replacement ("div.loading" becomes ".facesheet div.facesheet .loading")
          .replace(
            /\.facesheet div\.facesheet \.loading *{/g,
            '.facesheet div.loading {',
          )
      : '';

    const {
      appTitle,
      onClick,
      theme,
      locale = DEFAULT_LOCALE,
      watermark,
      smallWidget,
      viewMode,
    } = this.props;

    const facesheetViewMode = viewMode || (smallWidget ? 'small' : 'full');
    const isSmallView = Boolean(facesheetViewMode === 'small');
    const isPrintView = Boolean(facesheetViewMode === 'print');
    const isFullView = Boolean(facesheetViewMode === 'full');

    return (
      <Providers locale={locale} key={locale} theme={theme}>
        <Container>
          {!isPrintView && (
            <React.Fragment>
              <ReactToPrint
                content={() => this.printComponentRef}
                pageStyle="@page { size: auto;  margin: 20mm important!; } @media print { body { -webkit-print-color-adjust: exact; } }"
              >
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <WidgetHeader
                      appTitle={appTitle || 'Facesheet'}
                      versionInfo={`Facesheet v${process.env.REACT_APP_FACESHEET_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
                      onClick={onClick}
                      onPrint={handlePrint}
                      // OP-28466 Remove Printer Icon from Expanded Facesheet Widget
                      // showPrint={!isSmallView}
                      smallWidget={isSmallView}
                    />
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
              <div
                style={{ height: '100%', width: '100%', position: 'relative' }}
              >
                {watermark && watermark.show ? (
                  <Watermark
                    readOnly={watermark.readOnly}
                    smallWidget={isSmallView}
                  />
                ) : null}
              </div>
            </React.Fragment>
          )}
          <FacesheetComponent
            html={HTML}
            viewMode={facesheetViewMode}
            ref={(el: any) => {
              this.printComponentRef = el;
            }}
          />
        </Container>
      </Providers>
    );
  }
}

export default Facesheet;
