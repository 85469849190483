/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import {
  Loader,
  useComponentHeight,
  Watermark,
} from '@mummssoftware/common-ui';
import { FormsExtendedPatient } from '../../context';

import PatientChanges from '../PatientChanges';
import { ListForms } from '../Forms/ListForms';
import IdgTabs from '../Tabs';
import NotFound from '../NotFound';

type BodyProps = {
  headerRef: React.RefObject<HTMLElement>;
  patient?: FormsExtendedPatient;
  formName: string;
  readOnly: boolean;
  smallWidget?: boolean;
  dashboardEmbedded?: boolean;
  watermark?: {
    show: boolean;
    readOnly: boolean;
  };
};

const HistoryView = lazy(() => import('../History/View'));
const FormEdit = lazy(() => import('../Form/FormEdit'));

const AppContent = styled.div<{
  topBarHeight: number;
  dashboardHeight: number;
  dashboardEmbedded?: boolean;
}>`
  ${(props: any) =>
    props.dashboardEmbedded
      ? `background-color: ${props.theme.palette.background.paper};`
      : `;`}
  overflow: auto;
  margin-top: ${(props) => props.topBarHeight}px;
  width: 100%;
  /* There's a fix for this coming soon... */
  /* stylelint-disable-next-line function-calc-no-invalid */
  ${(props: any) =>
    props.dashboardEmbedded
      ? `height: ${props.dashboardHeight}px;`
      : `height: calc(100vh - ${props.topBarHeight}px);`}
  @media print {
    height: auto;
  }
`;

export const Body: React.SFC<BodyProps> = React.memo(
  ({
    patient,
    headerRef,
    formName,
    readOnly,
    smallWidget,
    dashboardEmbedded,
  }) => {
    const topBarHeight = useComponentHeight(headerRef);

    const {
      props: {
        dashboard: { smallWidgetHeight, widgetHeaderHeight, fullSizeRatio },
      },
    } = useTheme();

    const mainPaths = dashboardEmbedded
      ? ['/:patientNumber']
      : [
          '/:patientNumber/:formName/(form|changes)?/:submissionId?',
          '/:patientNumber/:formName/:submissionId?',
        ];

    const dashboardTopBarHeight =
      document &&
      document.getElementById('topbar') &&
      (document.getElementById('topbar') as any).clientHeight;
    const fullSizeHeight =
      fullSizeRatio * (window.innerHeight - dashboardTopBarHeight) -
      widgetHeaderHeight;

    const dashboardHeight = smallWidget
      ? smallWidgetHeight
      : (fullSizeHeight as number);

    return (
      <AppContent
        topBarHeight={topBarHeight}
        dashboardHeight={dashboardHeight}
        dashboardEmbedded={dashboardEmbedded}
      >
        <Switch>
          {patient && formName ? (
            <Suspense fallback={<Loader />}>
              <Switch>
                {(formName === 'idg-lcd' || formName === 'idg-pgba') && (
                  <Route
                    path="/:patientNumber/:formName/archive"
                    render={(props) => (
                      <HistoryView
                        patientNumber={
                          props.match.params.patientNumber as string
                        }
                      />
                    )}
                  />
                )}
                <Route
                  path={mainPaths}
                  render={
                    (props) => (
                      // NP-9689 LCD Recent Changes display overlaps- quick fix is to hide for 1.6 prod release
                      <IdgTabs
                        readOnly={readOnly}
                        formName={formName}
                        Form={<FormEdit Redirect={Redirect} {...props} />}
                        {...props}
                      />
                    )
                    // formName === 'idg-lcd' || formName === 'idg-pgba' ? (
                    //   <IdgTabs
                    //     readOnly={readOnly}
                    //     formName={formName}
                    //     dashboardEmbedded={dashboardEmbedded}
                    //     Form={<FormEdit Redirect={Redirect} {...props} />}
                    //     Changes={
                    //       <PatientChanges
                    //         patientNumber={props.match.params.patientNumber}
                    //       />
                    //     }
                    //     {...props}
                    //   />
                    // ) : (
                    //   <IdgTabs
                    //     readOnly={readOnly}
                    //     formName={formName}
                    //     Form={<FormEdit Redirect={Redirect} {...props} />}
                    //     {...props}
                    //   />
                    // )
                  }
                />
              </Switch>
            </Suspense>
          ) : null}
          {patient ? (
            <Suspense fallback={<Loader />}>
              <Route
                path="/:patientNumber"
                render={(props) => (
                  <ListForms dashboardEmbedded={dashboardEmbedded} {...props} />
                )}
              />
            </Suspense>
          ) : null}
          <Route path="/" render={(props) => <NotFound {...props} />} />
        </Switch>
      </AppContent>
    );
  },
);
