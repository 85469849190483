import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import {
  formatDateMMDDYY,
  formatDOBFromPim,
} from '@mummssoftware/utils/formatters';
import { messages } from '../Medication/messages';
import { AppContext } from '../../App/AppContext';

const PrintableBodyWrapper = styled.div`
  display: none;
  visibility: none;
  @media print {
    visibility: visible;
    display: flex;
    margin: 20px;
    padding: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    color: black;
    font-size: 16px;
    font-family: 'auto', sans-serif;
  }
  @media screen {
    display: none;
    visibility: none;
  }
`;

const MedContainer = styled.table`
  color: black;
`;

const Header = styled.thead`
  display: table-header-group;
`;

const Title = styled.div`
  display: block;
  text-align: left;
  line-height: 1.5;
  color: black;
`;

const SignatureLine = styled.div`
  list-style-type: none;
  padding-bottom: 5px;
`;

const OrderingProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
`;

const Divider = styled.div`
  width: auto;
  height: 50px;
  display: inline-block;
`;

const HeaderGrid = styled(Grid)`
  margin-top: 20px;
  padding: 0 6px;
`;

const MedicationsContains = styled.tbody`
  color: black;
`;

const Medication = styled.li`
  page-break-inside: avoid;
  list-style-type: none;
  padding: 5px;
  border-bottom: 1px solid lightgray;
`;

const Details = styled.ul`
  margin: 0;
`;

const DivSpaced = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DivSpacedLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const PrintHeader = styled.th`
  font-weight: 500;
`;

const Sig = styled.li``;

const getCoveredLabel = (covered) => {
  switch (covered) {
    case 'true':
      return <FormattedMessage {...messages.coveredYes} />;
    case 'false':
      return <FormattedMessage {...messages.coveredNo} />;
    default:
      return null;
  }
};
/**
 * This MUST be a class for React-Print to work
 */
class PrintSingleMedLayout extends React.Component {
  static contextType = AppContext;

  componentDidMount() {
    // Note not sure why needed here
    // const appContext = this.context;
  }

  render() {
    const appContext = this.context;
    const { patient } = appContext;
    const { medication, orderingPhysicianLabel } = this.props;
    return patient && medication ? (
      <PrintableBodyWrapper>
        <Title>
          <h3>{patient.hospiceName}</h3>
          <h3>{`${patient.lastName}, ${patient.firstName} (${patient.patientNumber})`}</h3>
          <h3>{`DOB: ${formatDOBFromPim(patient.dateOfBirth)}`}</h3>
          <h3>
            {patient.facility}
            <span> {patient.roomNumber}</span>
          </h3>
        </Title>
        <MedContainer>
          <Header>
            <tr>
              <th>
                <HeaderGrid direction="row" align="center" container>
                  <Grid xs={7} align="center" container item>
                    <Grid item>
                      <FormattedMessage {...messages.name} />
                    </Grid>
                  </Grid>
                  <Grid xs={5} align="center" container item>
                    <Grid xs={3} item>
                      <FormattedMessage {...messages.covered} />
                    </Grid>
                    <Grid xs={3} item>
                      <FormattedMessage {...messages.quantity} />
                    </Grid>
                    <Grid xs={3} item>
                      <FormattedMessage {...messages.start} />
                    </Grid>
                    <Grid xs={3} item>
                      <FormattedMessage {...messages.stop} />
                    </Grid>
                  </Grid>
                </HeaderGrid>
              </th>
            </tr>
          </Header>
          <MedicationsContains>
            <tr>
              <PrintHeader>
                <Medication key={`print-${medication.id}`}>
                  <Grid
                    direction="row"
                    justify="space-between"
                    align="center"
                    wrap="nowrap"
                    container
                  >
                    <Grid xs={7} align="center" container item>
                      <Grid item>{medication.drugdescription}</Grid>
                    </Grid>
                    <Grid xs={5} align="center" container item>
                      <Grid xs={3} item>
                        {getCoveredLabel(medication.coveredbyhospice)}
                      </Grid>
                      <Grid xs={3} item>
                        {medication.quantity}
                      </Grid>
                      <Grid xs={3} item>
                        {medication.startdate
                          ? formatDateMMDDYY(medication.startdate)
                          : `${formatDateMMDDYY(medication.lastupdatedate)}*`}
                      </Grid>
                      <Grid xs={3} item>
                        {formatDateMMDDYY(medication.stopdate)}
                      </Grid>
                    </Grid>
                  </Grid>
                  {medication.sig ? (
                    <Details>
                      <Sig>{medication.sig}</Sig>
                    </Details>
                  ) : null}
                </Medication>
              </PrintHeader>
            </tr>
          </MedicationsContains>
        </MedContainer>
        <Title>
          <h4>
            <FormattedMessage {...messages.verbalConfirm} />
          </h4>
          <Divider></Divider>
          <OrderingProviderContainer>
            <FormattedMessage {...messages.orderingProvider} />
            {medication.orderingphysicianfirstname ? (
              ` : ${orderingPhysicianLabel()}`
            ) : (
              <SignatureLine>
                ____________________________________
              </SignatureLine>
            )}
          </OrderingProviderContainer>
          <DivSpacedLine>
            <SignatureLine>____________________________________</SignatureLine>
            <SignatureLine>____________________________________</SignatureLine>
          </DivSpacedLine>
          <DivSpaced>
            <div>
              <FormattedMessage {...messages.nurseName} />
            </div>
            <div>
              <FormattedMessage {...messages.dateAndTime} />
            </div>
          </DivSpaced>
          <DivSpacedLine>
            <SignatureLine>____________________________________</SignatureLine>
            <SignatureLine>____________________________________</SignatureLine>
          </DivSpacedLine>
          <DivSpaced>
            <div>
              <FormattedMessage {...messages.signature} />
            </div>
            <div>
              <FormattedMessage {...messages.dateAndTime} />
            </div>
          </DivSpaced>
        </Title>
      </PrintableBodyWrapper>
    ) : null;
  }
}

export default PrintSingleMedLayout;
