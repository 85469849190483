import { colors } from '@material-ui/core';
import { css } from 'styled-components';
import { commonGlobal, flatPickrStyles, isMobile } from './common';
export default {
  palette: {
    type: 'dark',
    primary: {
      main: '#9edbf7'
    },
    secondary: colors.amber,
    grid: {
      headerFontColor: '#9edbf7'
    },
    graph: {
      border: '#ffffff',
      graphColor: '#66ffcc',
      secondGraphColor: '#FF6A00'
    },
    background: {
      default: '#1d1d1d',
      paper: '#454545',
      oddrow: '#403e3e',
      dark: '#454545',
      dark1: '#6A6A6A',
      dark2: '#888888',
      dark3: '#A0A0A0'
    },
    scores: {
      color: '#6A6A6A'
    },
    facesheet: {
      textColor: '#ffffff'
    },
    dashboard: {
      themeLoader: 'rgba(255, 255, 255, 0.8)'
    },
    text: {
      primary: '#eeeeee',
      secondary: '#faf39b',
      hint: '#a3a3a3',
      notes: '#ffffff'
    }
  },
  typography: {
    fontSize: 16
  },
  props: {
    grid: {
      headerHeight: 27,
      rowHeight: 27
    },
    header: {
      background: '#1d1d1d',
      rightTitle: {
        fontSize: 18,
        fontWeight: 'initial'
      },
      leftTitle: {
        fontSize: 19,
        fontWeight: 100
      },
      headerHeight: '40px'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#f3f6f9f2',
        color: '#000000'
      }
    }
  },
  global: css(["html{font-size:14px;}html body{-webkit-font-smoothing:auto;}", " ", " ", ""],
  /* eslint-disable indent */
  // eslint-disable-line
  isMobile ? '/* mobile scrollbars are more compact, use default */' : css(["::-webkit-scrollbar{width:14px;height:14px;}::-webkit-scrollbar-track,::-webkit-scrollbar-corner{background:transparent;opacity:0.4;}::-webkit-scrollbar-thumb{background:rgb(144,144,144);border:3px solid rgba(0,0,0,0);background-clip:padding-box;border-radius:6px;}"]), flatPickrStyles(), commonGlobal)
};