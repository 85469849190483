import React, { PureComponent, Fragment } from 'react';
import moment from 'moment-timezone';
import { Redirect } from 'react-router';
import { produce } from 'immer';
import { CardHeader, Grid, RootRef, withWidth } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { isWidthUp } from '@material-ui/core/withWidth';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import {
  CardHeaderTab as Tab,
  CloseButton,
  HeightProviderWithWindowHeight,
  PrintFooter,
  PrintHeader,
  NoRecordsDiv,
  NoRecordsPrintDiv,
} from '@mummssoftware/common-ui';
import { filterObj } from '@mummssoftware/utils/data';
import TimedLoader from '../Loading';
import PatientCertNav from './PatientCertNav';
import PatientCertBody from './PatientCertBody';
import { PatientCertContextProvider } from './PatientCertContext';
import { Popup } from '../CertForm/Components/Popup';
import { SuccessSnackbar } from '../Listing/Components/Snackbars';
import { MissingParams } from './MissingParams';
import { withAppContext } from '../App/AppContext';
import { withCertContext } from '../../Context';
import { processCerts } from '../../utils';
import {
  ATTD_KEYS_MAPPED,
  ATTD_CERT_KEYS,
  ATTENDING_FORM,
  CERT_KEYS_MAPPED,
  HOSP_CERT_KEYS,
  SET_CERT_START_DATE,
  SET_VERBAL_DATE_LIMIT,
  SET_LATE_CERT,
} from '../../constants';
import defaults from '../../translations/en.json';

const styles = (theme) => {
  const rootPrint = {
    '@media print': {
      height: 'auto !important',
      ...theme.props.printRules.noBorder['@media print'],
      ...theme.props.printRules.background['@media print'],
      div: {
        breakInside: 'avoid',
      },
    },
  };
  return {
    root: {
      background: theme.palette.background.header,
      marginTop: -3,
      overflowY: (props) => (props.embedded ? 'hidden' : 'auto'),
      overflowX: 'hidden',
      '&&&': {
        ...rootPrint,
      },
    },
    mobileRoot: {
      background: theme.palette.background.header,
      marginRight: -1.5,
      paddingRight: 1.5,
      '&&&': {
        ...rootPrint,
      },
    },
    firstChild: {
      border: (props) =>
        props.embedded ? null : `3px solid ${theme.palette.background.header}`,
      marginBottom: 1,
      borderTop: 0,
      '@media print': {
        '&&': {
          border: 'none',
          marginBottom: 4,
        },
      },
    },
    success: {
      color: theme.palette.text.primary,
      backgroundColor: green[600],
    },
    successMessage: {
      display: 'flex',
      alignItems: 'center',
    },
    successIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    topNav: {
      background: `${theme.palette.background.default}ce`,
      borderBottom: `3px solid ${theme.palette.background.header}`,
      position: 'sticky',
      top: 0,
      zIndex: 100,
      display: 'flex',
      flexWrap: 'wrap-reverse',
      padding: '0 10px',
      // TODO: Not be explicit here...
      ...(theme.props.isSafari ? { minHeight: 43 } : {}),
      '@media print': {
        border: 'none',
        ...theme.props.printRules.background['@media print'],
      },
    },
    topNavPrint: {
      background: `${theme.palette.background.default}ce`,
      borderBottom: `3px solid ${theme.palette.background.header}`,
      top: 0,
      zIndex: 100,
      display: 'flex',
      flexWrap: 'wrap-reverse',
      padding: '0 10px',
      // TODO: Not be explicit here...
      ...(theme.props.isSafari ? { minHeight: 43 } : {}),
      '@media print': {
        border: 'none',
        ...theme.props.printRules.background['@media print'],
      },
    },
    topNavContent: { alignSelf: 'flex-start' },
    topNavAction: {
      marginLeft: 'auto',
      paddingBottom: 2,
    },
  };
};

const rootStyle = (height = 0, isSinglePatientView) =>
  isSinglePatientView ? { maxHeight: `calc(100% - ${height}px` } : {};

// TODO: Get this outta here
const mobileHeight = (height = 0, windowHeight = 100, width) => {
  const adjustment =
    'var(--safe-area-inset-top) - var(--safe-area-inset-bottom)';
  // TODO: what was 53?? Card header is 43px, footer is 50px + safe-area-inset-bottom, possibly footer
  const heightAdjusted = height + 53;
  const setLargeHeight = () => Math.floor(0.8 * windowHeight) - heightAdjusted;
  return {
    height:
      isWidthUp('sm', width) && !window.innerHeight > window.innerWidth
        ? `calc(${setLargeHeight()}px - ${adjustment})`
        : `calc(${windowHeight - heightAdjusted}px - ${adjustment})`,
  };
};

/**
 * @name setErrorInfo
 * @param {number} errorCode Internal error code. On 4, stop retrying.
 * @param {number|null} errorStatus response status code
 */
const setErrorInfo = (errorCode, errorStatus) => ({ errorCode, errorStatus });

/**
 * @typedef Props
 * @prop {any} Context
 * @prop {string|number} [certIndex]
 * @prop {string} [dateFrom]
 * @prop {string} [dateTo]
 * @prop {string|number} electionNumber
 * @prop {boolean} [historicalView]
 * @prop {boolean} [isMobile]
 * @prop {string} insuranceCarrierId
 * @prop {boolean} [loaded]
 * @prop {boolean} [isSinglePatientView]
 * @prop {string} patientNumber
 * @prop {object} selectedPatient
 * @prop {boolean} showClose
 * @prop {string} siteId
 * @prop {function} logout
 * @prop {function} [onCancel]
 * @prop {function} onDataLoad
 * @prop {function} updateUserInfo
 */

/**
 *
 * @extends {React.Component<Props, {}>}
 */
class PatientCert extends PureComponent {
  /**
   * @param {Props} props
   */
  constructor(props) {
    super(props);

    this.state = {
      errorCode: 0,
      errorStatus: null,
      selectedPatient: null,
      submitting: false,
      successMessageOpen: false,
      successMessageId: null,
      navHbcLocation: '',
      missingParamDialogOpen: false,
      missingPhys: true,
      missingDate: true,
    };
  }

  static defaultProps = {
    onDataLoad: () => null,
  };

  headerRef = React.createRef();

  getCertKeys = (formType) =>
    formType === ATTENDING_FORM ? ATTD_KEYS_MAPPED : CERT_KEYS_MAPPED;

  getCert = (insuranceCarrierId, certIndex) =>
    this.state.selectedPatient.certHistory[insuranceCarrierId][certIndex];

  processCerts = (certs) =>
    processCerts(
      certs,
      this.props.pim,
      this.state.selectedPatient
        ? this.state.selectedPatient.primaryAddress &&
            this.state.selectedPatient.primaryAddress.state
        : null,
    );

  updateCert = (changes, insuranceCarrierId, certIndex, additionalState) => {
    const currentCert = this.getCert(insuranceCarrierId, certIndex);
    const updatedCert = { ...currentCert, ...changes };

    this.setState(
      produce((draft) => {
        if (additionalState) {
          Object.entries(additionalState).forEach(([key, value]) => {
            draft[key] = value;
          });
        }
        draft.selectedPatient.certHistory[insuranceCarrierId][
          certIndex
        ] = updatedCert;
      }),
    );
  };

  onNavigate = ({
    hbcLocation: navHbcLocation,
    number: navNumber,
    startDate: certStartDate,
  }) => {
    const siteName =
      navNumber && this.state.selectedPatient.headerEpisodes[navNumber].site;
    const newState = {
      missingPhys: true,
      missingDate: true,
      ...(this.state.navHbcLocation !== navHbcLocation
        ? { navHbcLocation }
        : {}),
      ...(this.state.siteName !== siteName ? { siteName } : {}),
      ...(this.state.certStartDate !== certStartDate ? { certStartDate } : {}),
    };

    this.setState(newState);

    if (this.state.certStartDate !== certStartDate) {
      this.props.dispatch({
        type: SET_CERT_START_DATE,
        payload: certStartDate,
      });
      this.props.dispatch({
        type: SET_VERBAL_DATE_LIMIT,
        payload: null,
      });
      this.props.dispatch({
        type: SET_LATE_CERT,
        payload: false,
      });
    }
  };

  updatePatientInfo = async (patientNumber, insuranceCarrierId, callback) => {
    const { logout, pim } = this.props;
    const [patDataResponse, patientHeaderResponse] = await Promise.all([
      pim.get({
        api: `/web/api/patients/certHistory/${patientNumber}`,
        qs: {
          insuranceCarrierId,
        },
      }),
      pim.getPatientHeader(patientNumber),
      // eslint-disable-next-line no-console
    ]).catch(console.error);

    if (
      patDataResponse &&
      !(patDataResponse instanceof Response) &&
      patientHeaderResponse &&
      !(patientHeaderResponse instanceof Response)
    ) {
      if (typeof callback === 'function') {
        callback(patDataResponse, insuranceCarrierId).then(() =>
          this.props.onDataLoad(),
        );
      } else {
        this.props.onDataLoad();
      }
      const headerEpisodes = patientHeaderResponse.episodes;

      const selectedPatient = { ...patDataResponse, headerEpisodes };

      this.setState({
        ...setErrorInfo(0, null),
        selectedPatient,
      });
    } else if (patDataResponse) {
      const { status } = patDataResponse;
      const { errorCode } = this.state;
      if (status >= 500 && status < 600 && errorCode < 4) {
        setTimeout(() => {
          this.setState(setErrorInfo(errorCode + 1, status), () => {
            this.updatePatientInfo(patientNumber, insuranceCarrierId, callback);
          });
        }, 3000);
      } else if (status === 401) {
        logout();
      } else {
        // Something else went wrong, this dog won't hunt
        // https://hbqa.mumms.com/web/api/patients/certHistory/5867?agency=demo1&siteId=17&roleId=-10
        // has no data
        this.setState(setErrorInfo(4, status));
      }
    } else if (patientHeaderResponse) {
      const { status, statusText } = patientHeaderResponse;
      if (status === 401) {
        logout();
      } else {
        // Something else went wrong, this dog won't hunt
        this.setState(setErrorInfo(4, statusText));
      }
    } else {
      this.setState(setErrorInfo(4, 404));
    }
  };

  /**
   * @param {string} narrative
   * @param {string} electionPeriodId
   * @param {boolean} attendingNarrative Whether this is for the attending cert
   * @param {string} endpoint The patient recerts endpoint to use
   * @param {number} attempts Ammount of attempts
   */
  postCertification = (
    narrative,
    electionPeriodId,
    attendingNarrative,
    endpoint = 'recordcert',
    personId,
    date,
    attempts = 0,
  ) =>
    this.props.pim
      .post({
        api: `/web/api/patients/recerts/${endpoint}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
        data: `narrative=${encodeURIComponent(narrative)}`,
        qs: {
          patientId: this.state.selectedPatient.patientId,
          siteId: this.props.siteId,
          electionPeriodId,
          attendingNarrative,
          personId,
          date,
        },
      })
      .then((res) => {
        const { status, ok } = res;
        if (!(res instanceof Response)) {
          return res;
        }
        if (status === 401) {
          return this.props.logout();
        }
        if ((status >= 500 && status < 600) || !ok) {
          if (attempts < 1) {
            return this.postCertification(
              narrative,
              electionPeriodId,
              attendingNarrative,
              endpoint,
              personId,
              date,
              attempts + 1,
            );
          }
          throw res;
        }
        return res;
      });

  recordArgsCache = null;

  recordCertification = (...args) => {
    const [
      insuranceCarrierId,
      certIndex,
      narrative,
      electionId,
      isAttendingForm,
      recordAll,
      endpoint,
      successMessageId,
      attendingPhysicianId,
      userId,
    ] = args;
    const { verbalDateLimit } = this.props;
    const isAttendingFormNotDoctor =
      this.props.isDoctor && isAttendingForm && attendingPhysicianId !== userId;
    const isAttendingFormDoctor =
      this.props.isDoctor && isAttendingForm && attendingPhysicianId === userId;
    let selectedDoctor;
    let selectedDate;
    const complete = (callback, additionalState = {}, noStateUpdate) => {
      this.recordArgsCache = null;
      const changes = { submitting: false, ...additionalState };
      if (noStateUpdate) {
        callback(changes);
      } else {
        this.setState(changes, callback);
      }
    };

    if (verbalDateLimit) {
      // if verbalFateLimit do not allow to record certification
      return null;
    }

    if (!this.props.isDoctor || isAttendingFormNotDoctor) {
      ({ selectedDoctor, selectedDate } = this.validateUserParams());
    } else if (isAttendingFormDoctor) {
      selectedDoctor = userId;
      selectedDate = moment(Date.now()).format('MM/DD/YYYY');
    }
    if (
      (!this.props.isDoctor &&
        (this.state.missingPhys || this.state.missingDate)) ||
      (isAttendingFormNotDoctor &&
        (this.state.missingPhys || this.state.missingDate))
    ) {
      this.setState({
        missingParamDialogOpen: true,
        missingDate: true,
        missingPhys: true,
      });

      this.recordArgsCache = args;
      return null;
    }

    return this.setState({ submitting: true }, async () => {
      await Promise.all([
        this.postCertification(
          narrative,
          electionId,
          isAttendingForm,
          endpoint,
          selectedDoctor,
          selectedDate,
        ),
        recordAll &&
          this.postCertification(
            'not required',
            electionId,
            true,
            endpoint,
            selectedDoctor,
            selectedDate,
          ),
      ])
        .then((res) => {
          if (res instanceof Response) {
            throw res;
          }
          complete(
            (additionalState) => {
              const lastResponse = res[1] || res[0];
              this.updateCert(
                filterObj(lastResponse, [...HOSP_CERT_KEYS, ...ATTD_CERT_KEYS]),
                insuranceCarrierId,
                certIndex,
                additionalState,
              );
            },
            {
              successMessageOpen: true,
              successMessageId,
            },
            true,
          );
        })
        .catch(() => {
          complete(
            () =>
              // TODO: better error handling here?
              /* eslint-disable */
              window.alert(this.props.intl.formatMessage({ id: 'certError' })),
            /* eslint-enable */
          );
        });
    });
  };

  validateUserParams = () => {
    const selectedDoctor =
      this.props.selectedDoctor && this.props.selectedDoctor.value;
    const selectedDate =
      this.props.selectedDate &&
      moment(this.props.selectedDate).format('MM/DD/YYYY');

    return { selectedDoctor, selectedDate };
  };

  handleMissingParamDialogClose = (e) => {
    const role = e.target.getAttribute('role');
    if ((role && role.includes('presentation')) || e.keyCode === 27) {
      this.setState({ missingParamDialogOpen: false });
    } else {
      /**
       * RULE: VERBAL always entered before SIGNATURE
      IF the verbal is late and no signature, THEN show message Verbal indicates a late date
      IF the signature is late but verbal date is timely ( date <= 2 days after the election period start date), THEN there should not be a message
      IF the signature is late ( date > 2 days after the election period start date) AND verbal is NULL, THEN show the message for Late Signature : Signature date indicates a late cert...
      IF the signature late is AND the Verbal is late, THEN show the message for Late Signature : Signature date indicates a late cert...
       */

      // check needed here when no change in date selected and default date selected in date picker
      // verbal date <= 2 days after the election period start date
      // no verbal date => false
      const verbalDateEntered =
        this.props.verbalDate || this.props.selectedDate;
      const timelyVerbal =
        moment(verbalDateEntered).diff(
          moment(this.state.certStartDate),
          'days',
        ) <= 2;

      // date entered is 2 days after start of cert && no or late verbal date
      const lateCert =
        moment(this.props.selectedDate).diff(
          moment(this.state.certStartDate),
          'days',
        ) > 2 && !timelyVerbal;

      if (lateCert && !this.state.lateCert && !this.props.lateCert) {
        this.props.dispatch({
          type: SET_LATE_CERT,
          payload: lateCert,
        });
        this.setState({ lateCert: true });
      } else {
        this.setState(
          {
            missingParamDialogOpen: false,
            missingPhys: !this.props.selectedDoctor,
            missingDate: !this.props.selectedDate,
          },
          () => {
            if (
              !this.state.missingPhys &&
              !this.state.missingDate &&
              Array.isArray(this.recordArgsCache)
            ) {
              this.recordCertification(...this.recordArgsCache);
            }
          },
        );
      }
    }
  };

  handleSuccessClose = () => {
    this.setState({
      successMessageOpen: false,
    });
  };

  closeForm = (info) => {
    // call cancel if defined or redirect to the root
    if (typeof this.props.onCancel === 'function') this.props.onCancel(info);
    else this.setState({ close: true });
  };

  componentDidUpdate() {
    const { errorCode, errorStatus } = this.state;
    if (errorCode > 3) {
      this.closeForm({ errorStatus });
    }
  }

  componentDidMount = () => {
    this.updatePatientInfo(
      this.props.patientNumber,
      this.props.insuranceCarrierId,
    );
    this.setState({ lateCert: false });
    this.props.onWidgetReady && this.props.onWidgetReady('DocLink');
  };

  render() {
    if (this.state.close) {
      if (this.props.embedded) {
        return (
          <NoRecordsDiv>
            <p>{defaults.noCert}</p>
          </NoRecordsDiv>
        );
      }
      if (this.props.printConfig) {
        return (
          <NoRecordsPrintDiv>
            <p>{defaults.noCert}</p>
          </NoRecordsPrintDiv>
        );
      }
      return <Redirect to="/" />;
    }
    const {
      electionNumber,
      errorCode,
      errorStatus,
      navHbcLocation,
      selectedPatient,
      submitting,
      successMessageId,
      successMessageOpen,
      missingParamDialogOpen,
      missingDate,
      missingPhys,
    } = this.state;
    const {
      classes,
      certIndex,
      Context,
      embedded,
      loaded,
      historicalView,
      isSinglePatientView,
      showClose,
      updateUserInfo,
      insuranceCarrierId,
      patientNumber,
      width,
      maxWidth,
      pim,
      intl: { formatMessage },
      printConfig,
    } = this.props;
    if (selectedPatient === null || loaded === false) {
      if (errorCode > 3 && !embedded && !printConfig) {
        /* eslint-disable no-alert */
        window.alert(
          this.props.intl.formatMessage({
            id: errorStatus === 404 ? 'noPatient' : 'certError',
          }),
        );
        /* eslint-enable */
      }
      return embedded ? null : <TimedLoader delay={2000} />;
    }
    return (
      <Context.Consumer>
        {({
          dateFrom,
          dateTo,
          enableClinicalLinks,
          m2Url,
          isMobile,
          hospiceBin,
          hospiceName,
          keycloak,
          ...rest
        }) => {
          const Nav = () => (
            <PatientCertNav
              hbcLocation={navHbcLocation}
              patient={selectedPatient}
              enableClinicalLinks={enableClinicalLinks}
              m2Url={m2Url}
              showClose={showClose}
              closeAction={this.closeForm}
              isMobile={isMobile}
              overridePrint={!!window.cordova}
            />
          );

          const HeaderAction = () =>
            isMobile ? (
              <CloseButton showClose={showClose} closeForm={this.closeForm} />
            ) : (
              <Nav />
            );

          return (
            <PatientCertContextProvider hospiceBin={hospiceBin}>
              <MissingParams
                open={missingParamDialogOpen}
                handleClose={this.handleMissingParamDialogClose}
                missingPhys={missingPhys}
                missingDate={missingDate}
              />
              <Popup />
              <SuccessSnackbar
                classes={classes}
                formatMessage={formatMessage}
                handleClose={this.handleSuccessClose}
                messageId={successMessageId}
                open={successMessageOpen}
              />
              {submitting ? (
                <TimedLoader>
                  {formatMessage({ id: 'cert.saving' })}
                </TimedLoader>
              ) : null}
              {embedded ? null : (
                <Fragment>
                  <PrintHeader
                    hospiceName={hospiceName}
                    siteName={this.state.siteName}
                  />
                  <RootRef rootRef={this.headerRef}>
                    <CardHeader
                      className={
                        printConfig ? classes.topNavPrint : classes.topNav
                      }
                      classes={{
                        content: classes.topNavContent,
                        action: classes.topNavAction,
                      }}
                      title={
                        <Tab classes={classes} patient={selectedPatient} />
                      }
                      action={printConfig ? null : <HeaderAction />}
                    />
                  </RootRef>
                </Fragment>
              )}
              <HeightProviderWithWindowHeight passedRef={this.headerRef}>
                {({ windowHeight: { innerHeight }, height }) => (
                  <div
                    className={
                      isMobile
                        ? `${classes.root} ${classes.mobileRoot}`
                        : classes.root
                    }
                    style={{
                      ...(isMobile
                        ? mobileHeight(height, innerHeight, width)
                        : rootStyle(height, isSinglePatientView)),
                      ...(maxWidth ? { maxWidth } : {}),
                    }}
                  >
                    <Grid
                      container
                      item
                      className={classes.firstChild}
                      xs={12}
                      style={isMobile ? { overflow: 'hidden' } : null}
                    >
                      <PatientCertBody
                        pim={pim}
                        keycloak={keycloak}
                        certIndex={certIndex}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        printConfig={printConfig}
                        electionNumber={electionNumber}
                        embedded={embedded}
                        formatMessage={formatMessage}
                        getCertKeys={this.getCertKeys}
                        historicalView={historicalView}
                        insuranceCarrierId={insuranceCarrierId}
                        m2Url={m2Url}
                        navCallback={this.onNavigate}
                        patient={selectedPatient}
                        patientNumber={patientNumber}
                        processCerts={this.processCerts}
                        recordCertification={this.recordCertification}
                        successMessageOpen={successMessageOpen}
                        submitting={submitting}
                        headerProps={{
                          enableClinicalLinks,
                          isMobile,
                        }}
                        certProps={{
                          enableClinicalLinks,
                          updateUserInfo,
                          ...rest,
                        }}
                      />
                    </Grid>
                  </div>
                )}
              </HeightProviderWithWindowHeight>
              {isMobile ? <Nav /> : null}
              {embedded || printConfig ? null : (
                <PrintFooter patient={selectedPatient} />
              )}
            </PatientCertContextProvider>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default injectIntl(
  withStyles(styles)(withWidth()(withAppContext(withCertContext(PatientCert)))),
);
