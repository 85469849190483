export const es = {
  /* common-ui component text */
  common: {
    'part-labels': {
      one: 'Part I. ',
      two: 'Part II. ',
      three: 'Part III. '
    },
    'select-score': 'Select appropriate score',
    'select-score-req': 'Select appropriate score - Required',
    indicate: 'Indicate',
    'indicate-req': 'Indicate - Required',
    other: 'Other',
    yes: 'Yes',
    Submit: 'Submit',
    close: 'Close',
    no: 'No',
    kps: 'KPS',
    pps: 'PPS',
    na: 'N/A',
    blank: ' ',
    logout: 'Cerrar Sesión',
    loading: 'Cargando',
    nav: {
      chart: 'Tabla',
      close: 'Cerrar',
      print: 'Imprimir',
      facesheet: 'Página de Portada'
    },
    patientId: 'ID del Paciente'
  },
  suggestBox: {
    create: "Usar '{input}'",
    noOpts: 'Sin opciones',
    placeholder: 'Seleccionar...'
  },

  /* General AG-Grid translations */
  grid: {
    page: 'Página',
    more: 'Más',
    to: 'a',
    of: 'de',
    next: 'Página Siguiente',
    last: 'Última Página',
    first: 'Primera Página',
    previous: 'Página Anterior',
    loadingOoo: 'Carga...',
    selectAll: 'Seleccionar Todo',
    searchOoo: 'Buscar...',
    blanks: 'Blanco',
    filterOoo: 'Filtrar...',
    applyFilter: 'Aplicar Filtro...',
    equals: 'Iguales',
    notEquals: 'No es igual',
    lessThan: 'Menos que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menos que o igual',
    greaterThanOrEqual: 'Mayor que o igual',
    inRange: 'En el rango',
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Empieza con',
    endsWith: 'Termina con',
    andCondition: 'Y',
    orCondition: 'O',
    group: 'Grupo',
    columns: 'Columnas',
    filters: 'Filtros',
    rowGroupColumns: 'Columnas de pivote',
    rowGroupColumnsEmptyMessage: 'Arrastra columnas al grupo',
    valueColumns: 'Columnas de valor',
    pivotMode: 'Modo de pivote',
    groups: 'Los grupos',
    values: 'Valores',
    pivots: 'Pivotes',
    valueColumnsEmptyMessage: 'Arrastre columnas para agregar',
    pivotColumnsEmptyMessage: 'Arrastra aquí para pivotar',
    toolPanelButton: 'Panel de herramientas',
    noRowsToShow: 'No hay filas',
    pinColumn: 'Columna de pines',
    valueAggregation: 'Agregación de valor',
    autosizeThiscolumn: 'Auto dimensionar esto',
    autosizeAllColumns: 'Auto dimensionar todo',
    groupBy: 'Agrupar por',
    ungroupBy: 'Desagrupar por',
    resetColumns: 'Restablecer columnas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    toolPanel: 'Panel de herramientas',
    export: 'Exportar',
    csvExport: 'Exportación CSV',
    excelExport: 'Exportación de Excel (.xlsx)',
    excelXmlExport: 'Exportación de Excel (.xml)',
    pinLeft: 'Pin izquierda',
    pinRight: 'Pin derecha',
    noPin: 'Sin pin',
    sum: 'Suma',
    min: 'Mínimo',
    max: 'Máximo',
    none: 'Nada',
    count: 'Contar',
    average: 'Promedio',
    filteredRows: 'Filtradas',
    selectedRows: 'Seleccionadas',
    totalRows: 'Filas totales',
    totalAndFilteredRows: 'Filas totales y filtradas',
    copy: 'Copia',
    copyWithHeaders: 'Copia con encabezados',
    ctrlC: 'Ctrl + C',
    paste: 'Pegar',
    ctrlV: 'Ctrl + V'
  }
};
export default es;