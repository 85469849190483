export const SET_MEASURE_SELECTED = 'SET_MEASURE_SELECTED';
export const SET_MEASURE_NAME = 'SET_MEASURE_NAME';
export const SET_OVERLAY = 'SET_OVERLAY';
export const SHOW_TABLE = 'SHOW_TABLE';
export const SET_TABLE_NAME = 'SET_TABLE_NAME';
export const MEASURE_TYPE = 'MEASURE_TYPE';
export const SET_NEW_MEASURE = 'SET_NEW_MEASURE';
export const SET_SCORES_METHOD = 'SET_SCORES_METHOD';
export const SET_SUBMIT_ACTIVE = 'SET_SUBMIT_ACTIVE';
export const SET_SCORES = 'SET_SCORES';
export const SET_SCORES_SUBCATEGORY = 'SET_SCORES_SUBCATEGORY';
export const RESET_SCORES = 'RESET_SCORES';
export const SET_LOADER = 'SET_LOADER';

export const vitalsType: mumms.measureTypeNames = 'vitals';
export const scoresType: mumms.measureTypeNames = 'scores';
export const limitationsType: mumms.measureTypeNames = 'limitations';

export const patientRefused = 'Patient Refused';
export const notTaken = 'Not Taken';
export const afebrile = 'Afebrile';
// TODO use missingReasons
export const missingReasons = [patientRefused, notTaken, afebrile];

export const editTable = 'editTable';
export const displayTable = 'displayTable';

export const noPost = 'noPost';
export const active = 'active';

export const dropdownFields = ['pps', 'kps', 'fast'];
export const highChartTimelineHeight = 20;
export const highChartYAxistWidth = 65;
export const nameWidth = 110;
export const chartHeightRatio = 0.5;
export const tableHeightRatio = 0.3;
// to avoid any scrolling
export const smallChartHeightRatio = 0.85;
// name width 110px- why did I leave this comment
export const fullChartWidth = 0.98;
export const marginBottom = 40;
export const tableHeight = 350;

const patientReporting = 'By Patient Reporting';
export const observation = 'By Observation';
export const observationMethods = [patientReporting, observation];
export enum ObservationMethod {
  PATIENT = 'By Patient Reporting',
  OBSERVATION = 'By Observation',
}
export const objective = 'objective';
export const subjective = 'subjective';

export const TabularView: mumms.printConfigView = 'Tabular';
