import React, { Fragment, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Listing } from '@mummssoftware/utils/constants';
import { FormattedMessage } from 'react-intl';
import { getUpdatedClassName } from './utils';
import { PrintLabel } from './PrintLabels';
import { withSelectors } from './Selectors';
import messages from '../CertMessages';
import { FORM_NARRATIVE_ID } from '../../../constants';
import 'flatpickr/dist/themes/material_green.css';

const BasePrintFooter = withSelectors(({ physContainerRef, PhysPicker }) => (
  <Grid container direction="row" spacing={1} style={{ marginTop: 10 }}>
    <PrintLabel
      ref={physContainerRef}
      label={<FormattedMessage {...messages.NAME} />}
      sm
    >
      {PhysPicker}
    </PrintLabel>
    <PrintLabel label={<FormattedMessage {...messages.SIGNATURE} />} sm />
    <PrintLabel label={<FormattedMessage {...messages.DATE} />} sm={3} />
  </Grid>
));

const PrintFooter = ({ alreadyCertified }) => {
  const physContainerRef = useRef(null);
  return (
    <BasePrintFooter
      absolutePhysPicker
      physSelectorId="picker-footer"
      alreadyCertified={alreadyCertified}
      dataPickerHeight="50%"
      physContainerRef={physContainerRef}
    />
  );
};
export const CertFooter = ({
  classes,
  alreadyCertified,
  footer,
  formNarrativeId,
  certificationDateTime,
  phys,
  updates,
  smallWidget,
  trailingMessage,
  printConfig,
}) => (
  <Fragment>
    {
      {
        print: <PrintFooter alreadyCertified={alreadyCertified} />,
        certified: (
          <Fragment>
            <Typography
              className={getUpdatedClassName(
                classes,
                updates,
                FORM_NARRATIVE_ID,
              )}
              style={smallWidget ? { fontSize: '14px' } : {}}
              paragraph
            >
              {
                formNarrativeId ? (
                  /* eslint-disable indent */
                  <FormattedMessage
                    {...messages.electronicallySigned}
                    values={{ phys, certificationDateTime }}
                  />
                ) : (
                  ''
                )
                /* eslint-enable */
              }
            </Typography>
            {printConfig && printConfig.view === Listing && !formNarrativeId && (
              <Typography
                className={getUpdatedClassName(
                  classes,
                  updates,
                  FORM_NARRATIVE_ID,
                )}
                style={smallWidget ? { fontSize: '14px' } : {}}
                paragraph
              >
                {trailingMessage}
              </Typography>
            )}
          </Fragment>
        ),
        default: null,
      }[footer]
    }
  </Fragment>
);
