import React from 'react';
import { Printer } from '@ionic-native/printer';
import { CardHeaderNav } from '@mummssoftware/common-ui';

/**
 * @param {{
 *   closeAction: function
 *   enableClinicalLinks: (boolean|undefined)
 *   hbcLocation: string
 *   m2Url: function
 *   patientNumber: string
 *   patient: import('../SharedPropDefs').Patient
 *   isMobile: (boolean|undefined)
 *   showClose: (boolean|undefined)
 *   overridePrint: (boolean|undefined)
 * }} props
 */
export const PatientCertNav = ({
  closeAction,
  enableClinicalLinks,
  hbcLocation,
  m2Url,
  patient,
  isMobile,
  showClose,
  overridePrint,
}) => {
  const m2NavCall = () =>
    m2Url('patient', patient.patientNumber, hbcLocation, '?render=mid-ui');

  return (
    <CardHeaderNav
      patient={patient}
      enableClinicalLinks={enableClinicalLinks}
      m2Url={m2NavCall}
      showClose={isMobile ? false : showClose}
      closeForm={closeAction}
      isMobile={isMobile}
      printFn={overridePrint ? () => Printer.print() : undefined}
    />
  );
};

export default PatientCertNav;
