import React, { Fragment, useState, useContext } from 'react';
import { differenceBy } from 'lodash-es';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ActionIcon } from '@mummssoftware/common-ui';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { WidgetConfig } from '../Context/types';
import { DashboardContext } from '../Context/DashboardContext';
import { SET_WIDGET_OPEN } from '../Context/constants';
import defaults from '../../translations/en.json';

const translations: NonNullable<mumms.JSONObject> = defaults;

type WidgetMenuProps = {
  config: WidgetConfig[];
};

const ITEM_HEIGHT = 45;

const useStyles = makeStyles(() => ({
  menuPaper: { maxHeight: ITEM_HEIGHT * 4, width: 160 },
  menuItem: {
    '&.MuiMenuItem-root': {
      fontSize: '1rem',
      lineHeight: 1,
    },
  },
}));

export const WidgetMenu = ({ config }: WidgetMenuProps) => {
  const classes = useStyles();

  const { widgetsOpen, dispatch } = useContext(DashboardContext);
  // we only want the plus dropdown to show if only one widget is open
  const isOnlyOneWidgetOpen = widgetsOpen.length === 1;
  const widgetConfig = config.filter((widget) => widget.expand === true);
  // widgets in the dropdown should not show the widgets currently open
  const widgetDropdown =
    widgetsOpen.length &&
    differenceBy(widgetConfig, widgetsOpen, (x: any) => x.componentName);

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (selectedItem: any) => () => {
    dispatch({
      type: SET_WIDGET_OPEN,
      payload: selectedItem,
    });

    setAnchorEl(null);
  };

  return isOnlyOneWidgetOpen ? (
    <Fragment>
      <ActionIcon
        title={translations.openNewWidget}
        style={{
          paddingRight: 4,
        }}
        Icon={AddToPhotosIcon}
        onClick={onOpen}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: {
            elevation8: classes.menuPaper,
          },
        }}
      >
        {(widgetDropdown as WidgetConfig[]).map((widget: WidgetConfig) => (
          <MenuItem
            key={widget.widgetTitle}
            onClick={onMenuItemClick(widget)}
            className={classes.menuItem}
          >
            {widget.widgetTitle}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  ) : null;
};
export default WidgetMenu;
