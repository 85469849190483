/* istanbul ignore file */
import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mummssoftware/common-ui';
import {
  flattenedTranslationMessages as translationMessages,
  DEFAULT_LOCALE,
} from './i18n';
import globalStyles from './globalStyles';
import App from './components/App/App';

const handleEmbed = (embed) => {
  if (typeof embed === 'boolean') {
    return embed;
  } else if (embed === 'true') {
    return true;
  }
  return false;
};

// stand alone url is http://reactdev.mumms.com:3000/?patient=13773&agency=demo1

/**
 * @extends {PureComponent<ClearScriptsProps>}
 */
class ClearScripts extends PureComponent {
  /**
   * @param {ClearScriptsProps} props
   */
  constructor(props) {
    super(props);
    if (typeof props.setTokens === 'function') {
      props.setTokens(props.keycloak);
    }
  }

  render() {
    const {
      embedded,
      dashboardEmbedded,
      appTitle,
      online,
      readonly,
      hideActionBar,
      medications,
      onClick,
      keycloak,
      logout,
      patientNumber,
      patientTopBar,
      smallWidget,
      doctors,
      currentUser,
      patient,
      dateTo,
      dateFrom,
      agency,
      toSearch,
      locale: passedLocale,
      onError,
      theme,
      viewMode,
      printConfig,
      maintainMed,
      watermark,
      onWidgetReady,
    } = this.props;

    const useGlobalStyles = globalStyles;
    const locale = passedLocale || DEFAULT_LOCALE;
    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translationMessages[locale]}
      >
        <ThemeProvider
          theme={theme || undefined}
          globalStyles={useGlobalStyles}
          suppressGlobal={!useGlobalStyles}
        >
          <BrowserRouter>
            <App
              embedded={handleEmbed(embedded)}
              dashboardEmbedded={dashboardEmbedded}
              appTitle={appTitle}
              online={online}
              readonly={readonly}
              maintainMed={maintainMed}
              patientTopBar={patientTopBar}
              medications={medications}
              hideActionBar={hideActionBar}
              onClick={onClick}
              onError={onError}
              to={dateTo}
              from={dateFrom}
              agency={agency}
              toSearch={toSearch}
              patient={patientNumber || patient?.patientNumber}
              keycloak={keycloak}
              doctors={doctors}
              currentUser={currentUser}
              patientHeader={patient}
              smallWidget={smallWidget}
              logout={online ? logout || keycloak.logout : null}
              watermark={watermark}
              viewMode={viewMode}
              printConfig={printConfig}
              onWidgetReady={onWidgetReady}
            />
          </BrowserRouter>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default ClearScripts;
