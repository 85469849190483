import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import Request from './Request';
export class ClearScripts extends Request {
  constructor({
    url,
    token: initToken,
    keycloak,
    agency: _agency
  }) {
    super({
      url
    });

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "getExternalUrl", async patientnumber => {
      const response = await this.get({
        api: `/clearscripts/thirdpartyurl`,
        qs: {
          patientnumber,
          action: 'update'
        }
      });
      return response;
    });

    _defineProperty(this, "getRxExternalUrl", async (site, agency, action) => {
      const response = await this.get({
        api: `clearscripts/thirdpartyurl/site/${site}`,
        qs: {
          action,
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "getMeds", async patientNumber => {
      const response = await this.get({
        api: `/clearscripts/medicationcards/patient/${patientNumber}`
      });
      return response;
    });

    _defineProperty(this, "updateMaintStatus", async (patientNumber, status) => {
      const response = await this.post({
        api: `/clearscripts/maintstatus/patient/${patientNumber}`,
        qs: {
          status
        }
      });
      return response;
    });

    _defineProperty(this, "putMed", async med => {
      const response = await this.post({
        api: `/clearscripts/medicationcard`,
        data: med,
        method: 'PUT'
      });
      return response;
    });

    _defineProperty(this, "syncMeds", async patientNumber => {
      const response = await this.post({
        api: `/clearscripts/syncdata/patient/${patientNumber}`
      });
      return response;
    });

    _defineProperty(this, "approveMeds", async (patientNumber, source) => {
      const response = await this.post({
        api: `/clearscripts/approvals/patient/${patientNumber}`,
        qs: {
          source
        }
      });
      return response;
    });

    _defineProperty(this, "getNotifications", async site => {
      const response = await this.get({
        api: `/clearscripts/notificationcounts`,
        qs: {
          site
        }
      });
      return response;
    });

    _defineProperty(this, "getUnreconciledMeds", async () => {
      const response = await this.get({
        api: `/clearscripts/patients/unreconciledmeds`
      });
      return response;
    });

    this.url = new URL(url);
    this.keycloak = keycloak;

    if (_agency) {
      this.url.searchParams.append('agency', _agency);
    }

    const token = initToken || keycloak.token;
    this.commonHeaders.Authorization = `Bearer ${token}`;
  }

}
export default ClearScripts;