import React, { createContext } from 'react';

export const AppContext = createContext({
  userId: 0,
  userCredentials: '',
  isDoctor: false,
  firstName: '',
  lastName: '',
});

export function withAppContext(Component) {
  return function contextComponent(props) {
    return (
      <AppContext.Consumer>
        {(context) => <Component {...props} {...context} />}
      </AppContext.Consumer>
    );
  };
}
