import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { submission } from 'react-formio';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (args: any) => any;
  }
}
const initialState = {};
const middlewares = [thunk];
let composeEnhancers = compose;
// Borrowed from react-boilerplate
// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

  // NOTE: Uncomment the code below to restore support for Redux Saga
  // Dev Tools once it supports redux-saga version 1.x.x
  // if (window.__SAGA_MONITOR_EXTENSION__)
  //   reduxSagaMonitorOptions = {
  //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
  //   };
  /* eslint-enable */
}

const enhancers = [applyMiddleware(...middlewares)];
const defaultSubmission = { name: 'idg-lcd' };

function createReducer(injectedReducers: any) {
  const rootReducer = combineReducers({
    ...injectedReducers,
    submissions: submission(defaultSubmission),
  });

  return rootReducer;
}
// TODO better solution? https://github.com/react-boilerplate/redux-injectors
// TODO THINK problem with types formio and import a new library with above

// Configure the store
const configureStore = () => {
  const store: any = createStore(
    createReducer({}),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Return the modified store
  return store;
};

export const injectAsyncReducer = (
  store: any,
  name: string,
  asyncReducer: any,
) => {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default configureStore;