import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  reset: {
    id: 'toolbar.reset',
  },
  dense: {
    id: 'toolbar.dense',
  },
  expand: {
    id: 'toolbar.expand',
  },
  showAll: {
    id: 'toolbar.showAll',
  },
  showActive: {
    id: 'toolbar.showActive',
  },
  showRecent: {
    id: 'toolbar.showRecent',
  },
  showNeedsApproval: {
    id: 'toolbar.showNeedsApproval',
  },

  collapse: {
    id: 'toolbar.collapse',
  },
  name: {
    id: 'medication.name',
  },
  quantity: {
    id: 'medication.quantity',
  },
  start: {
    id: 'medication.start',
  },
  stop: {
    id: 'medication.stop',
  },
  minimum: {
    id: 'medication.minimum',
  },
  patientMed: {
    id: 'medication.patientMed',
  },
  minimumTip: {
    id: 'medication.minimumTip',
  },
  physician: {
    id: 'medication.physician',
  },
  covered: {
    id: 'medication.covered',
  },
  coveredYes: {
    id: 'medication.coveredYes',
  },
  coveredNo: {
    id: 'medication.coveredNo',
  },
  reasonNotCovered: {
    id: 'medication.reasonNotCovered',
  },
  stopReason: {
    id: 'medication.stopReason',
  },
  orderingProvider: {
    id: 'medication.orderingProvider',
  },
  orderingPhys: {
    id: 'medication.orderingPhys',
  },
  choosePhys: {
    id: 'medication.choosePhys',
  },
  nurseName: {
    id: 'medication.nurseName',
  },
  needsApproval: {
    id: 'medication.needsApproval',
  },
  dateAndTime: {
    id: 'medication.dateAndTime',
  },
  signature: {
    id: 'medication.signature',
  },
  verbalConfirm: {
    id: 'medication.verbalConfirm',
  },
  dateFrom: {
    id: 'filters.from',
  },
  dateTo: {
    id: 'filters.to',
  },
  searchDefault: {
    id: 'filters.searchDefault',
  },
  modalTitle: {
    id: 'modal.title',
  },
  medsError: {
    id: 'medsError.error',
  },
  medsErrorTitle: {
    id: 'medsErrorTitle.title',
  },
});
