import { defineMessages } from 'react-intl';
export default defineMessages({
  close: {
    id: 'widget.header.close',
    defaultMessage: 'Close'
  },
  open: {
    id: 'widget.header.open',
    defaultMessage: 'Open'
  },
  openOutside: {
    id: 'widget.header.openOutside',
    defaultMessage: 'Open in new window'
  },
  print: {
    id: 'widget.header.print',
    defaultMessage: 'Print'
  }
});