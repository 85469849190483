import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Watermark } from '@mummssoftware/common-ui';
import { renderNoteDate } from '../../utils/utils';
import { NoteListWrapper, TextAreaWrapper, TextArea } from './WrapperDiv';
import { ProgressNotesContext } from '../../context';

const NoteList = (props) => {
  const [editableNote, setEditableNote] = useState({ editableNote: null });
  const {
    props: {
      dashboard: { smallWidgetHeight, fullSizeHeight },
    },
    palette: {
      primary: { main },
    },
  } = useTheme();

  const { smallWidget, watermark } = useContext(ProgressNotesContext);

  const handleChange = (e) => {
    if (e && e.target.value) {
      setEditableNote({ [e.target.name]: e.target.value });
      e.target.value = null;
    }
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      if (elementRef.current) {
        const target = elementRef.current;
        // target.scrollIntoView({
        //   block: 'nearest',
        //   inline: 'nearest',
        // });
        target.parentNode.scrollTop = target.offsetTop;
      }
    }, [elementRef.current]);
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(editableNote);
    }
  }, [editableNote]);

  let data;

  if (
    props != null &&
    props.notes != null &&
    Array.isArray(props.notes) &&
    props.notes.length
  ) {
    data = [...props.notes].reverse().map((n) => (
      <div key={n.id}>
        <article
          style={{
            margin: '10px',
            padding: '10px',
            border: 'solid 0.5px',
            borderColor: main,
          }}
        >
          <h4 style={{ paddingLeft: '5px', marginTop: '2px' }}>
            {renderNoteDate(n)} {n.personname}{' '}
            {n.credentials === 'null' || n.credentials === null
              ? ''
              : n.credentials}
          </h4>
          {n.note}
        </article>
      </div>
    ));
  }

  return (
    <NoteListWrapper
      id="note-header"
      smallWidget={smallWidget}
      smallWidgetHeight={smallWidgetHeight}
      fullSizeHeight={fullSizeHeight}
    >
      {watermark && watermark.show ? (
        <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
      ) : null}
      <TextAreaWrapper id="txt-area-wrapper" smallWidget={smallWidget}>
        {data}
        <AlwaysScrollToBottom />
      </TextAreaWrapper>
      <TextArea
        name="editableNote"
        placeholder="Please enter new note here"
        rows={2}
        cols={100}
        onBlur={handleChange}
      />
    </NoteListWrapper>
  );
};

export default NoteList;
