import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  textBox: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export const ExpandingTextField = (props) => {
  const {
    label,
    onNotCoveredReasonChange,
    notCoveredReason,
    handleClose,
  } = props;
  const [value, setValue] = useState(notCoveredReason || '');
  const classes = useStyles();

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
  };

  // const debounceMedChange = useCallback(
  //   debounce((nextValue) => {
  //     onNotCoveredReasonChange(nextValue);
  //   }, 1000),
  //   [],
  // );

  const saveReason = (event) => {
    const text = event.target.value;
    // if not empty text then save
    text !== '' && onNotCoveredReasonChange(text);
  };

  return (
    <form className={classes.textBox} noValidate autoComplete="off">
      <TextField
        id="not-covered-text"
        autoFocus
        label={label}
        multiline
        rowsMax={4}
        value={value}
        onChange={handleChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            saveReason(e);
            handleClose();
          }
        }}
      />
    </form>
  );
};
