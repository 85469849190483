import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mummssoftware/common-ui';
import { flattenedTranslationMessages as translationMessages } from './i18n';
type ProvidersProps = {
  children: React.ReactChild;
  locale: keyof mumms.translations;
  theme?: string | null;
};

class Providers extends React.PureComponent<ProvidersProps> {
  render() {
    const { locale, theme, children } = this.props;
    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translationMessages[locale] as any}
      >
        <ThemeProvider theme={theme || undefined}>{children}</ThemeProvider>
      </IntlProvider>
    );
  }
}

export default Providers;
