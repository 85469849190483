/* eslint-disable react/no-this-in-sfc */
/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { Typography, useTheme, Button } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { pick, flatten, cloneDeep } from 'lodash-es';
import moment from 'moment';
import {
  getDateRange,
  getMeasuresForGraph,
  getTypesByName,
} from '../MeasuresContext/utils/utils';
import { AllMeasuresDefined } from '../MeasuresContext/utils/type';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import { chartOptions, fastCategory, reverseFastMap } from './data';
import {
  smallChartHeightRatio,
  highChartTimelineHeight,
  chartHeightRatio,
  SET_MEASURE_SELECTED,
  SET_TABLE_NAME,
  SET_MEASURE_NAME,
  SET_NEW_MEASURE,
  SHOW_TABLE,
  MEASURE_TYPE,
  scoresType,
  objective,
  subjective,
} from '../MeasuresContext/utils/constants';
import {
  SparklineDiv,
  MeasureNameDiv,
  MeasuresWrapper,
  ChartWrapper,
  NamesWrapper,
  MainGraph,
  ScoreName,
  NoMeasuresDiv,
} from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';

require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);

type MeasuresGraphProps = {
  allMeasures: AllMeasuresDefined;
  smallWidget?: boolean;
  height: number;
  siteTimezone?: string | null;
  episodes: mumms.episode[];
};
const texts: NonNullable<mumms.JSONObject> = defaults;

export const MeasuresGraph = React.memo(
  ({
    allMeasures,
    smallWidget,
    height,
    siteTimezone,
    episodes,
  }: MeasuresGraphProps) => {
    const {
      props: {
        dashboard: { smallWidgetHeight },
      },
      palette: {
        common: { white },
        graph: { border, graphColor, secondGraphColor },
      },
    } = useTheme();
    // TODO use fontColor
    const {
      dispatch,
      newMeasure,
      nameSelected,
      measureSelected,
      methodSelected,
    } = useContext(MeasuresContext);
    const { measures, measuresTypes } = allMeasures;
    const measureType = localStorage.getItem(MEASURE_TYPE);
    const isScores = measureType === scoresType;

    const [measuresTypesState, setMeasuresTypesState] = useState<
      mumms.measureType[]
    >(measuresTypes);

    useEffect(() => {
      Highcharts.charts.forEach((chart) => {
        chart && chart.reflow();
      });
    }, [methodSelected]);

    // if we have not selected a specific measure nor adding a new measure then all measures shown
    useEffect(() => {
      if (!measureSelected && !newMeasure) {
        setMeasuresTypesState(measuresTypes);
      }
    }, [measuresTypes, measureSelected, newMeasure]);

    // ['bp', 'weight' ...]
    const measuresSelected = measuresTypesState.map(
      (measure) => measure.shortname,
    );

    // allMeasuresPicked = {bp: [data], weight: [data]}
    let allMeasuresPicked;
    let sortedScoresTypes;

    // only for SCORES
    if (isScores) {
      // not mutate measuresTypes directly
      const inUseObjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === objective && m.isinuse === 'true',
      );
      const notInUseObjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === objective && m.isinuse === 'false',
      );
      const objectiveMeasures = [
        ...notInUseObjectiveMeasures,
        ...inUseObjectiveMeasures,
      ];
      const subjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === subjective,
      );
      /** Sort by Objective last */
      sortedScoresTypes = [...subjectiveMeasures, ...objectiveMeasures];

      allMeasuresPicked = sortedScoresTypes.reduce((seed, val) => {
        if (val.shortname) {
          const shortName = val.shortname;
          (seed as any)[shortName] = (measures as any)[shortName] || [];
        }
        return seed;
      }, {});
    } else {
      allMeasuresPicked = pick(measures, measuresSelected);
    }

    const measuresSelectedArr: any = Object.values(allMeasuresPicked);
    const allMeasuresFordate: any = flatten(measuresSelectedArr);
    const dateRange = getDateRange(allMeasuresFordate);

    const typeNames: Record<
      mumms.measureShortNames,
      mumms.measureType
    > = getTypesByName(measuresTypesState);

    // Highcharts does not find moment when loaded as a module using import
    // https://github.com/highcharts/highcharts/issues/8661
    (window as any).moment = moment;

    // siteTimezone as timezone work by site id
    const tz = moment.tz.guess();
    const timezone = siteTimezone || tz;
    Highcharts.setOptions({
      time: {
        timezone,
      },
    });

    /**
     * variables for graph width and height small widget and not
     */
    const padding = 20;
    const minFullSizeChartHeight = 80;
    const maxFullSizeChartHeight = 185;

    const minSmallSizeChartHeight = 100;
    const smallHeight = smallWidgetHeight - padding;
    const numberMeasures = Object.keys(allMeasuresPicked).length;

    const smallChartHeight =
      (smallHeight * smallChartHeightRatio) / numberMeasures;
    const smallSizeChartHeight = Math.min(
      smallChartHeight,
      minSmallSizeChartHeight,
    );

    const fullChartSize = (height * 0.9) / numberMeasures;
    const minFullChartSize = Math.max(fullChartSize, minFullSizeChartHeight);
    const fullChartHeight = Math.min(minFullChartSize, maxFullSizeChartHeight);

    const [fullSizeChartHeight, setFullSizeChartHeight] = useState<number>(
      fullChartHeight,
    );

    // window.addEventListener('resize', function () {
    //   setTimeout(function () {
    //     Highcharts.charts.forEach(function (chart) {
    //       chart && chart.reflow();
    //     });
    //   });
    // });

    // chart height to update when view switches from stacked graphs to single graph
    useEffect(() => {
      if (!measureSelected && !newMeasure) {
        setFullSizeChartHeight(fullChartHeight);
      }
    }, [measureSelected, fullChartHeight, newMeasure]);

    const chartHeight = smallWidget
      ? smallSizeChartHeight
      : fullSizeChartHeight;

    const variant = smallWidget ? 'body2' : 'body1';

    useEffect(() => {
      // TODO see if size container can be smaller
      // TODO do I need to close it as only listening to chart-full?
      if (document && document.getElementById('chart-full')) {
        const resizeObserver = new ResizeObserver((entries: any) => {
          setTimeout(function () {
            Highcharts.charts.forEach(function (chart) {
              chart && chart.reflow();
            });
          });
        });
        resizeObserver.observe(
          document.getElementById('chart-full') as Element,
        );
      }
      /**
       * Different tooltips strategy depending on small/Full size widget
       */
      if (!smallWidget) {
        /**
         * In order to synchronize tooltips and crosshairs, override the
         * built-in events with handlers defined on the parent element.
         */
        ['mousemove', 'touchmove'].forEach((eventType: string) => {
          document &&
            document.getElementById('chart-full') &&
            (document as any)
              .getElementById('chart-full')
              .addEventListener(eventType, (e: any) => {
                let chart;
                let points: any;
                let point;
                let i;

                for (
                  i = Highcharts.charts.length;
                  i < Highcharts.charts.length;
                  i++
                ) {
                  chart = Highcharts.charts[i];
                  points = [];
                  chart &&
                    // TODO update for forEach
                    // eslint-disable-next-line no-loop-func
                    Highcharts.each(chart.series, (p: any, _i: number) => {
                      point = p.searchPoint(e, true);
                      if (point) points.push(point);
                    });
                  if (points) {
                    // TODO TypeError: q.getLabelConfig is not a function
                    if (points.length && chart && chart?.xAxis[0]) {
                      // tooltip.refresh needs tolotip shared true
                      (chart?.tooltip as any).refresh(points); // Show the tooltip
                      (chart?.xAxis[0] as any).drawCrosshair(e, points[0]); // Show the crosshair
                    }
                  }
                }
              });
        });

        /**
         * Registering Hide.
         */
        ['mouseleave', 'mouseout'].forEach((eventType: string) => {
          document &&
            document.getElementById('chart-full') &&
            (document as any)
              .getElementById('chart-full')
              .addEventListener(eventType, (e: any) => {
                let chart;
                let points: any;
                let point;
                let i;

                for (
                  i = Highcharts.charts.length;
                  i < Highcharts.charts.length;
                  i++
                ) {
                  chart = Highcharts.charts[i];
                  points = [];
                  chart &&
                    // TODO update for forEach
                    // eslint-disable-next-line no-loop-func
                    Highcharts.each(chart.series, (p: any, _i: number) => {
                      point = p.searchPoint(e, true);
                      if (point) points.push(point);
                    });
                  if (points) {
                    if (points.length && chart && chart?.xAxis[0]) {
                      // tooltip.refresh needs tolltip shared true
                      (chart?.tooltip as any).hide(points);
                      (chart?.xAxis[0] as any).hideCrosshair(e, points[0]);
                    }
                  }
                }
              });
        });
      }
    }, [smallWidget]);

    const fullSizeChart = chartHeightRatio * height;

    const setMeasure = (name: string) => {
      dispatch({
        type: SET_MEASURE_SELECTED,
        payload: name,
      });
      const measure = measuresTypes.filter((m) => m.shortname === name);
      // set the state to only the measure selected
      // adjust the size of the graph to individual size
      setMeasuresTypesState(measure);
      setFullSizeChartHeight(fullSizeChart);

      // when double clicking on name selected hide or show
      // think about adding a new measure as well
      if (name !== nameSelected && !newMeasure) {
        dispatch({
          type: SHOW_TABLE,
          payload: true,
        });
        dispatch({
          type: SET_MEASURE_NAME,
          payload: name,
        });
      } else {
        dispatch({
          type: SHOW_TABLE,
          payload: false,
        });
        dispatch({
          type: SET_MEASURE_NAME,
          payload: null,
        });
        dispatch({
          type: SET_MEASURE_SELECTED,
          payload: null,
        });
        dispatch({
          type: SET_TABLE_NAME,
          payload: null,
        });
        dispatch({
          type: SET_NEW_MEASURE,
          payload: false,
        });
      }
    };

    // when clicking on adding a NEW measure
    useEffect(() => {
      if (measureSelected && newMeasure) {
        const measure = measuresTypes.filter(
          (m) => m.shortname === measureSelected,
        );
        setMeasuresTypesState(measure);
        setFullSizeChartHeight(fullSizeChart);
      }
    }, [measuresTypes, measureSelected, newMeasure, fullSizeChart]);

    // function to get access to this
    function formatter(this: any) {
      // special tooltip for fast
      const y = (reverseFastMap as any)[this.y];
      const text = `<br/><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${y}`;
      return text;
    }

    /**
     * Synchronize zooming through the setExtremes event handler.
     */
    // function and not const to get access to this
    function syncExtremes(this: any, e: any) {
      const thisChart = this.chart;

      if (e.trigger !== 'syncExtremes') {
        // Prevent feedback loop

        // Highcharts.charts array will store all chart references for the current Highcharts instance.
        // An array containing the current chart objects in the page.
        // A chart's position in the array is preserved throughout the page's lifetime.
        // When a chart is destroyed, the array item becomes undefined
        const array =
          Highcharts.charts &&
          Highcharts.charts.filter((chart) => chart !== undefined);
        array.forEach((chart: any) => {
          if (chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: 'syncExtremes',
              });
            }
          }
        });
      }
    }

    const getSingleChartUI = (
      chartmeasures: mumms.measure[],
      name: mumms.measureShortNames,
      index: number,
    ) => {
      const show2ndData = typeNames[name].isgraphwithsecondaryval === 'true';
      const options: any = cloneDeep(chartOptions(border));
      options.chart.height = chartHeight;
      options.chart.marginLeft = smallWidget ? 0 : 60;
      (options.yAxis as any).visible = !smallWidget;

      let measureValues: any;

      // FAST category
      if (name === 'fast') {
        (options.yAxis as any).categories = fastCategory;
        measureValues = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value',
          'fast',
        );
        (options.tooltip as any).pointFormatter = formatter;
      } else {
        measureValues = getMeasuresForGraph(chartmeasures, dateRange, 'value');
      }

      if (episodes && episodes.length > 1 && measureSelected) {
        const plotBands: any[] = [];
        for (let i = 0; i < episodes.length - 1; i++) {
          plotBands.push({
            color: border,
            from: episodes[i].utcDischargeDate,
            to: episodes[i + 1].utcReferralDate,
            zIndex: 5,
          });
        }
        (options.xAxis as any).plotBands = plotBands;
      } else {
        (options.xAxis as any).plotBands = null;
      }

      /** only display last timeline */
      if (
        index === numberMeasures - 1 &&
        measureValues &&
        measureValues.length
      ) {
        (options.xAxis as any).visible = true;
        options.chart.height = chartHeight + highChartTimelineHeight;
      }

      const firstSerie = {
        id: name,
        data: measureValues,
        name,
        type: 'line',
        color: graphColor,
        fillOpacity: 0.3,
      };
      options.series = [firstSerie];

      (options.xAxis.events as any).setExtremes = syncExtremes;

      if (show2ndData) {
        // backend value2 to graph 2nd value
        const measureValues2 = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value2',
        );
        const secondSerie = {
          id: 'other',
          data: measureValues2,
          name,
          type: 'line',
          color: secondGraphColor,
          fillOpacity: 0.3,
        };
        options.series = [firstSerie, secondSerie];
      }

      // function to get access to this
      function smallWidgetFormatter(this: any) {
        // special tooltip for fast
        const y = name === 'fast' ? (reverseFastMap as any)[this.y] : this.y;
        const text = `${y}`;
        return text;
      }

      if (smallWidget) {
        (options as any).tooltip = {
          borderWidth: 1,
          pointFormatter: smallWidgetFormatter,
          headerFormat: '',
          crosshairs: [true],
        };
      }
      // had put a key in hoping it would trigger a HighChart re-render
      return (
        <HighchartsReact
          key={index}
          highcharts={Highcharts}
          options={options as any}
        />
      );
    };

    const getChartUI = (chartmeasures: mumms.formattedAllMeasures) => {
      if (chartmeasures && Object.keys(chartmeasures).length) {
        return Object.entries(chartmeasures).reduce(
          (seed: any, measure: any, index: number) => {
            const name = measure[0];
            const data = measure[1];
            seed.push(getSingleChartUI(data, name, index));
            return seed;
          },
          [],
        );
      }
      return null;
    };

    // void or null not assignable to ReactNode
    const getNamesUI = (chartmeasures: mumms.formattedAllMeasures) => {
      if (chartmeasures && Object.keys(chartmeasures).length) {
        return Object.keys(chartmeasures).reduce((seed: any, name: any) => {
          seed.push(getSingleNameUI(name));
          return seed;
        }, []);
      }
      return null;
    };

    const getSingleNameUI = (name: string) => {
      const fullSizeName = methodSelected ? (
        <ScoreName>{name.toUpperCase()}</ScoreName>
      ) : (
        <Button
          style={{
            textAlign: 'left',
          }}
          size="small"
          onClick={() => setMeasure(name)}
        >
          {name.toUpperCase()}
        </Button>
      );
      return (
        <SparklineDiv key={name} height={chartHeight}>
          <MeasureNameDiv
            smallWidget={smallWidget}
            showScoreForm={!!methodSelected}
          >
            {smallWidget ? (
              <Typography variant={variant}>{name}</Typography>
            ) : (
              fullSizeName
            )}
          </MeasureNameDiv>
        </SparklineDiv>
      );
    };

    const chartWrapperId = smallWidget ? 'chart-small' : 'chart-full';

    // eslint-disable-next-line no-nested-ternary
    return !Object.values(allMeasuresPicked).length && !newMeasure ? (
      <NoMeasuresDiv>
        <Typography variant={variant}>{texts.noMeasures}</Typography>
      </NoMeasuresDiv>
    ) : (
      <MainGraph id="main-graph">
        <MeasuresWrapper
          id="graph-area"
          smallWidget={smallWidget}
          key={methodSelected}
        >
          <NamesWrapper id="names">
            {getNamesUI(allMeasuresPicked as any)}
          </NamesWrapper>
          <ChartWrapper id={chartWrapperId}>
            {getChartUI(allMeasuresPicked)}
          </ChartWrapper>
        </MeasuresWrapper>
      </MainGraph>
    );
  },
);

export default MeasuresGraph;
