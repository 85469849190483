import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'listing.title',
  },
  maintainBTN: {
    id: 'listing.maintainBTN',
  },
  editBTN: {
    id: 'listing.editBTN',
  },
  stopEditBTN: {
    id: 'listing.stopEditBTN',
  },
  updateMedsBTN: {
    id: 'listing.updateMedsBTN',
  },
  medsApprovedPhyBTN: {
    id: 'listing.medsApprovedPhyBTN',
  },
  medsApprovedNonPhyBTN: {
    id: 'listing.medsApprovedNonPhyBTN',
  },
  minimumTip: {
    id: 'medication.minimumTip',
  },
  needsApproval: {
    id: 'Needs Approval',
  },
  dense: {
    id: 'listing.dense',
  },
  notSetup: {
    id: 'dialog.notSetup',
  },
  finished: {
    id: 'dialog.finished',
  },
  name: {
    id: 'medication.name',
  },
  physician: {
    id: 'medication.physician',
  },
  covered: {
    id: 'medication.covered',
  },
  quantity: {
    id: 'medication.quantity',
  },
  coveredYes: {
    id: 'medication.coveredYes',
  },
  coveredNo: {
    id: 'medication.coveredNo',
  },
  start: {
    id: 'medication.start',
  },
  stop: {
    id: 'medication.stop',
  },
  date: {
    id: 'medication.date',
  },
  approved: {
    id: 'medication.approved',
  },
  sig: {
    id: 'medication.sig',
  },
  physSigLine: {
    id: 'print.physSig',
  },
  physDateLine: {
    id: 'print.physDate',
  },
  physApprovalState: {
    id: 'print.approvalStatement',
  },
  reconcileAck: {
    id: 'print.reconcileAck',
  },
  verbalCol: {
    id: 'print.verbalCol',
  },
});
