import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _merge from "lodash-es/merge";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 * Originally found in react-boilerplate and modified for our purposes
 */

/* eslint-disable global-require, @typescript-eslint/no-explicit-any */
import flatten from 'flat';
import translationDefaults from './translationDefaults';
/* polyfills for locale-data */

/* istanbul ignore next */

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');

  require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en


  require('@formatjs/intl-pluralrules/dist/locale-data/es'); // Add locale data for es

}
/* istanbul ignore next */


if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');

  require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for de


  require('@formatjs/intl-relativetimeformat/dist/locale-data/es'); // Add locale data for de

}
/* eslint-enable */


const EN = 'en';
const ES = 'es'; // prettier-ignore

export const appLocales = [EN, ES];
export const getLocale = lang => appLocales.find(locale => lang.split(/[-_]/)[0] === locale) || EN;
/* istanbul ignore next */

export const DEFAULT_LOCALE = navigator.language ? getLocale(navigator.language) : EN;
export const initTranslationMessages = (addtlEnTranslationMessages, addtlEsTranslationMessages) => {
  const enTranslationMessages = _merge(translationDefaults.string.en, addtlEnTranslationMessages);

  const esTranslationMessages = _merge(translationDefaults.string.es, addtlEsTranslationMessages);

  const formatTranslationMessages = (locale, messages) => {
    /* eslint-disable indent */
    const defaultFormattedMessages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages).default : {};
    /* eslint-enable */

    const mergeFormattedMessages = (formattedMessages, key) => {
      const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
      return Object.assign(formattedMessages, {
        [key]: formattedMessage
      });
    };

    const merged = Object.keys(messages).reduce(mergeFormattedMessages, {});
    const flattened = flatten(_objectSpread(_objectSpread({}, enTranslationMessages), merged));
    return {
      default: merged,
      flattened
    };
  };

  const {
    default: enDefault,
    flattened: enFlattened
  } = formatTranslationMessages(EN, enTranslationMessages);
  const {
    default: esDefault,
    flattened: esFlattened
  } = formatTranslationMessages(ES, esTranslationMessages);
  return {
    formatTranslationMessages,
    flattenedTranslationMessages: {
      [EN]: enFlattened,
      [ES]: esFlattened
    },
    translationMessages: {
      [EN]: enDefault,
      [ES]: esDefault
    }
  };
};
export const {
  formats,
  defaultsFormats
} = translationDefaults;