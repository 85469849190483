import styled from 'styled-components';
import {
  fullChartWidth,
  highChartYAxistWidth,
  marginBottom,
} from '../../containers/MeasuresContext/utils/constants';

export const MainWrap = styled.div``;
export const MainGraph = styled.div<{
  isScores?: boolean;
}>`
  display: flex;
  ${(props: any) => (props.isScores ? `flex-direction: column;` : `;`)}
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const StyledScoresUIDiv = styled.div`
  padding-left: 2%;
  display: flex;
  flex-direction: column;
`;
// overflow hidden ag-grid react with display flex

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0.25rem;
`;

export const ScoreName = styled.span`
  font-size: 0.93rem;
`;
export const ScoreNameFocused = styled.span`
  font-size: 0.93rem;
  cursor: pointer;
`;

// margin-left for small widget display so that it is not stuck to the border
const nameMarginLeft = 10;
export const SparklineDiv = styled.div<{
  height?: number;
}>`
  display: flex;
  align-items: center;
  margin-left: ${nameMarginLeft}px;
  ${(props: any) => (props.height ? `height: ${props.height}px;` : `;`)}
`;

export const ScoresNameDiv = styled.div<{
  height?: number;
  color?: string;
}>`
  display: flex;
  align-items: center;
  margin-left: 59px;
  margin-right: 9px;
  padding: 3px;
  ${(props: any) => `border-top: 2px solid ${props.color};`}
  ${(props: any) => `border-bottom: 1px solid ${props.color};`}
  ${(props: any) => `border-right: 2px solid ${props.color};`}
  ${(props: any) => `border-left: 2px solid ${props.color};`}
`;

const nameMinWidth = 100;
export const MeasureNameDiv = styled.div<{
  smallWidget?: boolean;
  showScoreForm?: boolean;
  color?: string;
  print?: boolean;
}>`
  ${(props: any) =>
    props.smallWidget ? `min-width: 85px;` : `min-width: ${nameMinWidth}px;`}
  ${(props: any) =>
    props.smallWidget || props.showScoreForm ? `;` : `cursor: pointer;`}
  ${(props: any) => (props.color ? `color: ${props.color};` : ';')}
  ${(props: any) => (props.print ? `width: 65px;` : `;`)}
  justify-content: center;
`;

export const MeasuresTableWrapper = styled.div<{
  width?: number;
  height?: number;
}>`
  ${(props: any) => (props.width ? `width: ${props.width}px;` : `;`)}
  ${(props: any) =>
    props.height ? `height: ${props.height}px;` : `;`}
  border: 1px solid black;
`;

// background color
// align center
// smallWidgetHeight is 279px
export const MeasuresBody = styled.div<{
  smallWidgetHeight?: number;
  fullSizeHeight?: number;
  smallWidget?: boolean;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `height: ${props.smallWidgetHeight}px;`
      : `height: ${props.fullSizeHeight}px;`}
  ${(props: any) =>
    props.fullSizeHeight
      ? `max-height: ${props.fullSizeHeight}px;`
      : `;`}  
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.palette.background.paper};
  overflow: scroll;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  min-height: 279px;
  position: relative;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.palette.background.paper};
  overflow: scroll;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  min-height: 279px;
  position: relative;
`;

// 98% as it should not fill up all the entire space
const graphWidth = fullChartWidth * 100;
// when resizing the window would need this width: calc(95vw - 270px)
// issue with ScoresNameDiv margin-right of 9px
// props.openMeasures
// ? `width: calc(95vw - 270px);`
// : `width: ${graphWidth}%;`}
export const MeasuresWrapper = styled.div<{
  smallWidget?: boolean;
  openMeasures?: boolean;
  print?: boolean;
}>`
  display: flex;
  ${(props: any) =>
    // eslint-disable-next-line no-nested-ternary
    props.smallWidget ? `width: 100%;` : `width: ${graphWidth}%;`}
  ${(props: any) => (props.print ? `width: 750px;` : ';')}
  ${(props: any) => (props.print ? `margin-left: auto;` : ';')}
  ${(props: any) => (props.print ? `margin-right: auto;` : ';')}
`;
// props print last as overriding above width rules
export const GoBackDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: ${graphWidth}%;
  padding-left: 5%;
`;

export const MeasuresTableDiv = styled.div`
  margin-top: 5px;
  margin-left: ${highChartYAxistWidth + 10}px;
  margin-right: 20px;
`;

// so that high chart library gets it
export const ChartWrapper = styled.div`
  width: 100%;
`;

export const NamesWrapper = styled.div<{
  print?: boolean;
}>`
  ${(props: any) => (props.print ? `width: 65px;` : ';')}
`;

export const MeasureSparkLineWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const CellDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const MeasureValue = styled.span``;

export const UnitsValue = styled.span`
  padding-left: 5px;
`;
export const MeasureDescription = styled.span`
  font-style: italic;
`;
export const EnterCell = styled.span<{ width: string }>`
  display: block;
  cursor: pointer;
  width: ${(props) => props.width}px;
`;
export const MeasureCell = styled.div<{ width: string }>`
  width: ${(props) => props.width}px;
`;
export const PrintMeasureCell = styled.div<{ width: string }>`
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: center;
`;
export const MeasuresPrintDiv = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.common.white};
  @media print {
    page-break-after: always;
    color: ${(props) => props.theme.palette.common.black};
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;
export const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;

export const NoMeasuresDiv = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 1em;
`;
export const TablePrintDiv = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;
export const PrintTextDiv = styled.div<{
  color?: string;
}>`
  ${(props: any) => (props.color ? `color: ${props.color};` : ';')}
  display: flex;
  justify-content: center;
  font-size: 1em;
  letter-spacing: 0.0625em;
  font-weight: normal;
  text-transform: uppercase;
  padding: 10px 0px;
`;

export const ChartNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginBottom}px;
`;

export const ScoresNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`;
export const ScoresMethodsDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const MethodButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
`;

export const SubmitDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
`;

export const DividerDiv = styled.div`
  margin-top: 10px;
  margin-left: ${highChartYAxistWidth}px;
  margin-right: 5px;
`;

export const DividerSummaryDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DividerActionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
