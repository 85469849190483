import React, { createContext, ReactNode, useReducer } from 'react';
import { SET_FORM_ID, SET_PATIENT_MANIFEST_ID } from './constants';

type CPPContextProviderProps = {
  children: ReactNode;
};

type FormID = {
  formKey: string;
  formName: string;
};

type CPPContext = {
  formId: FormID | null;
  patientManifestId: string | null;
  dispatch?: any;
};

type CPPContextState = {
  formId: FormID | null;
  patientManifestId: string | null;
};
const initialState = {
  formId: null,
  patientManifestId: null,
};

const initCPPContext = {
  formId: null,
  patientManifestId: null,
};

export const CPPContextReducer = (
  state: CPPContextState,
  action: { type: string; payload: any },
) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_FORM_ID:
      newState.formId = action.payload;
      break;
    case SET_PATIENT_MANIFEST_ID:
      newState.patientManifestId = action.payload;
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const CPPContext = createContext<CPPContext>(
  initCPPContext as CPPContext,
);
export const CPPContextProvider = ({ children }: CPPContextProviderProps) => {
  const [{ formId, patientManifestId }, dispatch] = useReducer(
    CPPContextReducer,
    initialState,
  );
  return (
    <CPPContext.Provider
      value={{
        formId,
        patientManifestId,
        dispatch,
      }}
    >
      {children}
    </CPPContext.Provider>
  );
};

export default CPPContextProvider;
