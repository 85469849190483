import React from 'react';
import { DialogBox } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import messages from '../../Patient/Components/Messages';

export const RxModal = ({ open, toggleModal, link, isMobile }) => {
  const content = link.url ? (
    <iframe
      src={link.url}
      width="100%"
      height={isMobile ? 500 : 800}
      title="ClearScripts"
    ></iframe>
  ) : (
    <span>{link.message}</span>
  );
  return (
    <DialogBox
      open={open}
      fullWidth={!!link.url}
      maxWidth="xl"
      handleClick={toggleModal}
      title={<FormattedMessage {...messages.modalTitle} />}
    >
      {content}
    </DialogBox>
  );
};
