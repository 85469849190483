import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Card } from '@mummssoftware/utils/constants';
import produce from 'immer';
import { gridContainerStyle } from '@mummssoftware/common-ui';
import CertHistory from '../CertForm/CertHistory';
import PatientCertHeader from './PatientHeader';
import { CertBodyGrid } from '../CertForm/Components';

/**
 * @typedef {import("moment-timezone").Moment} Moment
 */

/**
 * @typedef PatientHistoryState
 * @prop {{[k: string]: Object[]}} processedForms
 * @prop {{[k: string]: boolean}} visibleSections
 */

/**
 * @typedef PatientHistoryProps
 * @prop {import('../CertForm/CertForm').CertFormProps} certProps Props for the CertForm component
 * @prop {import('./PatientHeader').PatientHeaderProps} headerProps Props for the PatientHeader component
 * @prop {function} processCerts
 * @prop {function} getCertKeys
 * @prop {function} m2Url
 * @prop {function} navCallback
 * @prop {string} [dateFrom] view certs starting from date
 * @prop {string} [dateTo] view certs up until date
 * @prop {boolean} [embedded] Changes view related items
 * @prop {function} formatMessage react-intl i18n function
 * @prop {boolean} [isMobile]
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {string} [patientNumber]
 * @prop {string|number} [electionNumber]
 *
 */

const ADD_VISIBLE_SECTION = 'addVisibleSection';
const REMOVE_VISIBLE_SECTION = 'removeVisibleSection';
const SET_PROCESSED_FORMS = 'setProcessedForms';
const REMOVE_PROCESSED_FORM = 'removeProcessedForm';

/**
 * Wrapper for ReactDOM's actually rather stable batchedUpdates function
 * @param {function} func
 */
const batchUpdates = (func) =>
  setTimeout(() => ReactDOM.unstable_batchedUpdates(() => func()), 0);

const setDates = (dateFrom, dateTo, config) => {
  if (dateFrom || dateTo) {
    if (config)
      return {
        startDate: dateFrom,
        endDate: dateTo,
      };
    return {
      startDate: dateFrom ? moment(dateFrom, ['MM-DD-YYYY']) : moment(),
      endDate: dateTo ? moment(dateTo, ['MM-DD-YYYY']) : moment(),
    };
  }
  return {};
};

const NoCerts = () => (
  <CertBodyGrid
    style={{ ...gridContainerStyle['&&'], paddingBottom: '.5rem' }}
    typographyProps={{ color: 'textPrimary' }}
  >
    <FormattedMessage id="history.noMatch" />
  </CertBodyGrid>
);

/**
 * Payer History Wrapper
 */
const CertHistoryWrap = memo(
  ({
    addVisibleSection,
    removeVisibleSection,
    currentSectionVisible,
    certHistoryProps,
    index,
    lastIndex,
    payerId,
    payerForms,
    visibleSectionsLength,
    printConfig,
    mostRecentCert,
    self,
    headerProps,
  }) => {
    if (!payerForms.length) {
      if (index === lastIndex && !visibleSectionsLength) {
        return <NoCerts />;
      }
      if (currentSectionVisible) {
        removeVisibleSection(payerId);
      }
      return null;
    }
    if (!currentSectionVisible) {
      addVisibleSection(payerId);
    }
    return (
      <CertHistory
        {...certHistoryProps(
          payerId,
          payerForms,
          printConfig,
          mostRecentCert,
          self,
          headerProps,
        )}
      />
    );
  },
);
/**
 * check dates, inclusive
 * @param {Moment} start start moment
 * @param {Moment} end end date
 * @param {Moment} verbalMoment form verbal date
 * @param {Moment} writtenMoment form written date
 */
const filterFunc = (start, end, verbalMoment, writtenMoment) =>
  // '[]' is inclusive, '()' would be exclusive, '(]' exclude start, include end
  verbalMoment.isBetween(start, end, 'days', '[]') ||
  writtenMoment.isBetween(start, end, 'days', '[]');

/**
 * @type {PatientHistoryState}
 */
const initialState = {
  processedForms: {},
  visibleSections: {},
};

function removeForm(forms, action) {
  const index = forms.findIndex(
    ({ formType, certIndex }) =>
      formType === action.formType && certIndex === action.certIndex,
  );
  if (index !== -1) {
    forms.splice(index, 1);
  }
  return forms;
}
/**
 * 
 * @param {PatientHistoryState} draft 
 * @param {{
      type: string,
      index: number,
      payerId: string,
      processedForms: State['processedForms']
    }} action 
 */
function historyReducer(draft, action) {
  switch (action.type) {
    case ADD_VISIBLE_SECTION:
      draft.visibleSections[action.payerId] = true;
      break;
    case REMOVE_VISIBLE_SECTION:
      delete draft.visibleSections[action.payerId];
      break;
    case SET_PROCESSED_FORMS:
      Object.keys(action.processedForms).forEach((payerId) => {
        draft.visibleSections[payerId] = true;
      });
      draft.processedForms = action.processedForms;
      break;
    case REMOVE_PROCESSED_FORM:
      draft.processedForms[action.payerId] = removeForm(
        draft.processedForms[action.payerId],
        action,
      );
      break;
    default:
  }
}

const curriedHistoryReducer = produce(historyReducer);

/**
 * PatientHistory component
 * @param {PatientHistoryProps} props
 */
const PatientHistory = ({
  certProps,
  dateFrom,
  dateTo,
  embedded,
  printConfig,
  formatMessage,
  getCertKeys,
  headerProps,
  m2Url,
  navCallback,
  patientNumber,
  patient,
  processCerts,
}) => {
  const self = useRef({
    formatMessage,
    getCertKeys,
    m2Url,
    navCallback,
    processCerts,
  });

  const [{ processedForms, visibleSections }, dispatch] = useReducer(
    curriedHistoryReducer,
    initialState,
  );
  const fromDate = printConfig && printConfig.fromDate;
  const toDate = printConfig && printConfig.toDate;
  const dateRange =
    printConfig && fromDate && toDate
      ? setDates(fromDate, toDate, printConfig)
      : setDates(dateFrom, dateTo);
  const [{ startDate, endDate }, setDateState] = useState(dateRange);

  const primaryPayerId = patient.certPayerIDs[0].id;
  const primaryHistory = patient.certHistory[primaryPayerId] || [];
  const mostRecentCert = primaryHistory[primaryHistory.length - 1];

  useEffect(() => {
    const refs = {
      formatMessage,
      getCertKeys,
      m2Url,
      navCallback,
      processCerts,
    };
    Object.entries(refs).forEach(([refName, ref]) => {
      if (ref !== self.current[refName]) {
        self.current[refName] = ref;
      }
    });
  }, [formatMessage, getCertKeys, m2Url, navCallback, processCerts]);

  useEffect(() => {
    setDateState(dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, fromDate, toDate]);

  useEffect(() => {
    if (mostRecentCert) {
      self.current.navCallback({
        hbcLocation: mostRecentCert.episode.hbcLocation,
      });
    }
  }, [mostRecentCert]);

  useEffect(() => {
    const handleForms = async () => {
      const nextProcessedForms = {};
      const promises = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < patient.certPayerIDs.length; i++) {
        const { id } = patient.certPayerIDs[i];
        const setForm = async () => {
          const currentHistory = patient.certHistory[id];
          let forms = await self.current.processCerts(currentHistory);
          let fallbackForm;
          forms = forms.filter(
            ({ complete, verbalComplete }) => !!complete || !!verbalComplete,
          );

          if (!!startDate && !!endDate && !moment(startDate).isAfter(endDate)) {
            forms = forms.filter((form) => {
              const { formType, certIndex } = form;
              const cert = currentHistory[certIndex];
              const {
                formPhysVerbalDate,
                formPhysWrittenDate,
              } = self.current.getCertKeys(formType);
              const verbalMoment = moment(cert[formPhysVerbalDate]);
              const writtenMoment = moment(cert[formPhysWrittenDate]);
              fallbackForm =
                verbalMoment.isSameOrBefore(endDate) ||
                writtenMoment.isSameOrBefore(endDate)
                  ? form
                  : fallbackForm;
              return filterFunc(
                startDate,
                endDate,
                verbalMoment,
                writtenMoment,
              );
            });
          }
          if (!forms.length && fallbackForm) {
            forms.push(fallbackForm);
          }

          nextProcessedForms[id] = forms;
        };
        promises.push(setForm());
      }
      await Promise.all(promises);
      dispatch({
        type: SET_PROCESSED_FORMS,
        processedForms: nextProcessedForms,
      });
    };
    handleForms();
  }, [endDate, patient, startDate]);

  const addVisibleSection = (payerId) => {
    batchUpdates(() => dispatch({ type: ADD_VISIBLE_SECTION, payerId }));
  };

  const removeVisibleSection = (payerId) => {
    batchUpdates(() => dispatch({ type: REMOVE_VISIBLE_SECTION, payerId }));
  };

  const certHistoryProps = useCallback(
    (payerId, forms) => {
      const onHide = (formType, certIndex) => {
        batchUpdates(() =>
          dispatch({
            type: REMOVE_PROCESSED_FORM,
            payerId,
            formType,
            certIndex,
          }),
        );
      };
      return {
        key: `cert-history-${patientNumber}-${payerId}`,
        formatMessage: self.current.formatMessage,
        m2Url: self.current.m2Url,
        getCertKeys: self.current.getCertKeys,
        certProps,
        forms,
        onHide,
        patient,
        patientNumber,
        payerId,
        printConfig,
        mostRecentCert,
        self,
        headerProps,
      };
    },
    [
      patientNumber,
      patient,
      certProps,
      printConfig,
      mostRecentCert,
      headerProps,
    ],
  );

  return (
    <Fragment>
      {embedded || (printConfig && printConfig.view === Card) ? null : (
        <PatientCertHeader
          key={`patient-header-${patientNumber}`}
          patient={patient}
          primaryAddress={patient.primaryAddress}
          currentCert={mostRecentCert}
          formatMessage={self.current.formatMessage}
          m2Url={self.current.m2Url}
          {...headerProps}
        />
      )}
      {Object.entries(processedForms).map(([payerId, forms], index) => (
        <CertHistoryWrap
          key={`history-body-wrap-${patientNumber}-${payerId}`}
          addVisibleSection={addVisibleSection}
          removeVisibleSection={removeVisibleSection}
          currentSectionVisible={visibleSections[payerId]}
          certHistoryProps={certHistoryProps}
          index={index}
          lastIndex={Object.keys(processedForms).length - 1}
          payerId={payerId}
          payerForms={forms}
          visibleSectionsLength={Object.keys(visibleSections).length}
          printConfig={printConfig}
          mostRecentCert={mostRecentCert}
          self={self}
          headerProps={headerProps}
        />
      ))}
    </Fragment>
  );
};

export default memo(PatientHistory);
