import { colors } from '@material-ui/core';
import { css } from 'styled-components';
import { commonGlobal, flatPickrStyles, isMobile } from './common';
/**
 * Font Colors
 *  text.title Widget Header Font: #FFFFFF
 *  // Widget Chart Header Font: #4eb7cd
 *  // Read Only Font: #9fa7b7
 *  text.secondary Editable/Current Font: #ffd76b
 *  error.main Required/Late Font: #ff6b6b
 *  background.default Hummingbird Logo Color: #21242e
 *  // Active Widget Nav Dock Title Font: #9fa7b7
 * //  Inactive Widget Nav Dock Title Font: #FFFFFF
 *  // Drawers Title Font: #2f3440
 *  background.header - Bottom Nav Drawer Title Font: #7681a5
 *
 * BG Colors
 * // Dance Floor BG: #21242e
 *  grid.headerBackground, background.paper Widget BG: #393f4f
 *  grid.headerBorderColor, divider Widget Header/Drawer/Tool bar BG: #5a6378
 * // Very Bottom BG: #393f4f
 * // Widget Nav Dock BG: #393f4f
 * // Active Module BG color: #21242e
 * // Inactive Module BG color: #5a6378
 *  primary.main Active Module Icon Color: #57d1eb
 *  text.hint Inactive Module Icon Color: #a5adbe
 *  grid.rowBorderColor Bottom Secondary Nav BG: #323745
 */

export default {
  palette: {
    type: 'dark',
    // was #57d1eb
    primary: {
      main: '#8aeaff'
    },
    error: {
      main: '#ff6b6b'
    },
    grid: {
      fontColor: '#dddddd',
      headerFontColor: '#8aeaff',
      headerBackground: '#393f4f',
      headerBorderColor: '#5a6378',
      rowBorderColor: '#323745'
    },
    graph: {
      border: '#ffffff',
      graphColor: '#66ffcc',
      secondGraphColor: '#FF6A00'
    },
    scores: {
      color: '#97abd3'
    },
    secondary: colors.amber,
    background: {
      default: '#21242e',
      paper: '#393f4f',
      // was #7681a5
      header: '#646d8c',
      oddrow: '#585f72',
      dark: '#393f4f',
      dark1: '#616572',
      dark2: '#81848E',
      dark3: '#9A9DA5'
    },
    facesheet: {
      textColor: '#ffffff'
    },
    dashboard: {
      themeLoader: 'rgba(255, 255, 255, 0.8)'
    },
    text: {
      primary: '#bbc8e2',
      secondary: '#ffd76b',
      // was #a5adbe
      hint: '#c5d0e6',
      title: '#ffffff',
      notes: '#ffffff'
    },
    divider: '#5a6378'
  },
  typography: {
    fontSize: 16
  },
  props: {
    grid: {
      headerHeight: 27,
      rowHeight: 27
    },
    header: {
      background: '#1d1d1d',
      rightTitle: {
        fontSize: 18,
        fontWeight: 'initial'
      },
      leftTitle: {
        fontSize: 19,
        fontWeight: 100
      },
      headerHeight: '40px'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#f3f6f9f2',
        color: '#000000'
      }
    }
  },
  global: css(["html{font-size:14px;}html body{-webkit-font-smoothing:auto;}", " ", " ", ""],
  /* eslint-disable indent */
  // eslint-disable-line
  isMobile ? '/* mobile scrollbars are more compact, use default */' : css(["::-webkit-scrollbar{width:14px;height:14px;}::-webkit-scrollbar-track,::-webkit-scrollbar-corner{background:transparent;opacity:0.4;}::-webkit-scrollbar-thumb{background:rgb(144,144,144);border:3px solid rgba(0,0,0,0);background-clip:padding-box;border-radius:6px;}"]), flatPickrStyles(), commonGlobal)
};