/* eslint-disable indent */
import React, { useState, useEffect, useContext } from 'react';
import { WidgetHeader } from '@mummssoftware/common-ui';
import { Observation } from '@mummssoftware/utils';
import {  Container } from './WrapperDiv';
import { ProgressNotesContext } from '../../context';
import NotesList from './NotesList';

type ProgressNotesComponentProps = {
  embedded?: boolean;
  currentUser: mumms.User;
  patient?: mumms.patient;
  view?: 'readonly' | 'editable';
  online: boolean;
  observation?: Observation;
  patientNumber?: string;
  agency?: string | null;
  progressNotes: any;
  onProgressNoteSave: any;
};

export const ProgressNotesComponent = React.memo(
  ({ patient, agency }: ProgressNotesComponentProps) => {
    const {
      smallWidget,
      progressNotes,
      onProgressNoteSave,
      currentUser,
      patientNumber,
      appTitle,
      onClick,
    } = useContext(ProgressNotesContext);

    const [patientProgressNotes, setPatientProgressNotes] = useState();

    useEffect(() => {
      setPatientProgressNotes(progressNotes);
    }, [progressNotes]);

    const saveNoteEventHandler = async (params: any) => {
      if (params.editableNote) {
        const progressNoteList = await onProgressNoteSave(
          currentUser.currentHospiceBin,
          params.editableNote,
          patientNumber,
          currentUser.personNumber,
          currentUser.siteId,
        );
        await setPatientProgressNotes(progressNoteList);
      }
    };

    return (
      <Container>
        <WidgetHeader
          appTitle={appTitle || 'Progress Notes'}
          versionInfo={`ProgressNote v${process.env.REACT_APP_PROGRESS_NOTES_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
          onClick={onClick}
          smallWidget={smallWidget}
        />

        <NotesList
          onChange={saveNoteEventHandler}
          notes={patientProgressNotes}
        />
      </Container>
    );
  },
);

export default ProgressNotesComponent;
