import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { FieldToolTip } from '@mummssoftware/common-ui';
import { formatPhoneNumber } from '@mummssoftware/utils/formatters';
import messages from './Messages';
import { dateFormat } from '../../../constants';
import { FieldBasicTranslatedLabel } from './BasicComponents';

export const PatientHeader = ({ classes, headerGrids, patient, full }) => (
  <Fragment>
    <FieldBasicTranslatedLabel
      classes={classes}
      gridProps={headerGrids}
      message={messages.dob}
      data={
        patient.dateOfBirth && (
          <FormattedDate
            value={moment.utc(patient.dateOfBirth)}
            {...dateFormat}
          />
        )
      }
    />
    <FieldBasicTranslatedLabel
      classes={classes}
      gridProps={headerGrids}
      message={messages.gender}
      data={
        patient.gender ? (
          <FormattedMessage
            id={patient.gender}
            defaultMessage={patient.gender}
          />
        ) : null
      }
    />
    <FieldBasicTranslatedLabel
      classes={classes}
      gridProps={headerGrids}
      message={messages.phone}
      data={formatPhoneNumber(patient.primaryPhone)}
    />
    <FieldToolTip
      classes={classes}
      label={
        <FormattedMessage key={messages.diagnoses.id} {...messages.diagnoses} />
      }
      TooltipProps={{ key: `${patient.patientNumber}.diag-tooltip` }}
      data={patient.diagnoses}
    />
    {full && (
      <FieldToolTip
        classes={classes}
        label={
          <FormattedMessage
            key={messages.allergies.id}
            {...messages.allergies}
          />
        }
        TooltipProps={{ key: `${patient.patientNumber}.allergy-tooltip` }}
        data={patient.allergies}
      />
    )}
  </Fragment>
);
