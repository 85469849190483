import styled from 'styled-components';
/* istanbul ignore file */

export const actionIconStyle = {
  width: 30,
  margin: '11px 3px 0 0',
  height: 30,
  padding: '0'
};
export const gridContainerStyle = {
  '&&': {
    position: 'relative',
    padding: '2px 18px',
    overflow: 'hidden'
  }
};
export const NoRecordsDiv = styled.div.withConfig({
  displayName: "commonStyles__NoRecordsDiv",
  componentId: "f6hpob-0"
})(["margin-left:1em;"]);
export const NoRecordsPrintDiv = styled.div.withConfig({
  displayName: "commonStyles__NoRecordsPrintDiv",
  componentId: "f6hpob-1"
})(["font-size:1.2em;margin-left:1em;@media print{p{color:black;padding:1em;}}"]);
export default {
  gridContainerStyle,
  actionIconStyle,
  NoRecordsDiv,
  NoRecordsPrintDiv
};