/*
 * CertForm Messages
 *
 * This contains all the text for the CertForm component.
 */

import { defineMessages } from 'react-intl';
// english messages
import defaults from '../../translations/en.json';

// TODO: Refactor all messages to use as defined in en.json
export default defineMessages({
  Certification: {
    id: 'Certification',
    defaultMessage: 'Certification',
  },
  attendingTitle: {
    id: 'cert.attendingTitle',
    defaultMessage: 'Attending Physician {baseTitle}',
  },
  hospiceTitle: {
    id: 'cert.hospiceTitle',
    defaultMessage: 'Hospice Physician {baseTitle}',
  },
  headerDays: {
    id: 'cert.days',
    defaultMessage: '({days} days)',
  },
  physStatement: {
    id: 'cert.physStatement',
    defaultMessage:
      "I, {name}, certify that this patient has a prognosis and limited life expectancy of six (6) months or less based on my clinical judgement regarding the normal course of the individual's illness.",
  },
  narrative: {
    id: 'cert.narrative',
    defaultMessage: defaults['cert.narrative'],
  },
  electronicallySigned: {
    id: 'cert.electronicallySigned',
    defaultMessage:
      'Electronically signed by {phys} on {certificationDateTime}.',
  },
  signedOn: {
    id: 'cert.signedOn',
    defaultMessage: '{date} at {time}',
  },
  NAME: {
    id: 'NAME',
    defaultMessage: 'NAME',
  },
  SIGNATURE: {
    id: 'SIGNATURE',
    defaultMessage: 'SIGNATURE',
  },
  DATE: {
    id: 'DATE',
    defaultMessage: 'DATE',
  },
  narrativeOnFile: {
    id: 'cert.narrativeOnFile',
    defaultMessage: defaults['cert.narrativeOnFile'],
  },
  noAttendingRequired: {
    id: 'cert.noAttendingRequired',
    defaultMessage: 'No attending certification required.',
  },
  f2fMissing: {
    id: 'cert.f2fMissing',
    defaultMessage:
      'Required Face to Face visit not completed and certification is not possible.',
  },
  outOfRange: {
    id: 'cert.outOfRange',
    defaultMessage: defaults['cert.outOfRange'],
  },
  f2fLink: {
    id: 'cert.f2fLink',
    defaultMessage: 'face-to-face encounter',
  },
  f2fConfirm: {
    id: 'cert.f2fConfirm',
    defaultMessage:
      'I further confirm that {f2fVerbiage} had a {f2fLink} with the above-named patient on {f2fDate}{f2fFindings} in determining continued eligibility for hospice care.',
  },
  shortf2fConfirm: {
    id: 'cert.shortf2fConfirm',
    defaultMessage:
      '{f2fVerbiage} had a {f2fLink} with the above-named patient on {f2fDate}.',
  },
  isF2fProvider: {
    id: 'cert.isF2fProvider',
    defaultMessage:
      ', and that I used the clinical findings from that encounter ',
  },
  notF2fProvider: {
    id: 'cert.notF2fProvider',
    defaultMessage:
      ' and provided me with clinical findings from that visit and that I used those findings ',
  },
  docReceived: {
    id: 'cert.docReceived',
    defaultMessage:
      'Documentation for this certification has been received from the transferring hospice and is on file in medical records.',
  },
  certAttest: {
    id: 'cert.certAttest',
    defaultMessage:
      "With my signature I hereby attest that I composed this narrative based on my review of the patient's medical record and/or examination of the patient.",
  },
  awaitAttending: {
    id: 'cert.awaitAttending',
    defaultMessage: 'Awaiting attending physician signature.',
  },
  Certify: {
    id: 'Certify',
    defaultMessage: defaults.Certify,
  },
  Verbal: {
    id: 'Verbal',
    defaultMessage: defaults.Verbal,
  },
  recordedBy: {
    id: 'cert.recordedBy',
    defaultMessage: defaults['cert.recordedBy'],
  },
  verbalOrderBy: {
    id: 'cert.verbalOrderBy',
    defaultMessage: defaults['cert.verbalOrderBy'],
  },
  onDate: {
    id: 'cert.onDate',
    defaultMessage: defaults['cert.onDate'],
  },
  'nonphys.Certify': {
    id: 'nonphys.Certify',
    defaultMessage: defaults['nonphys.Certify'],
  },
  'nonphys.Verbal': {
    id: 'nonphys.Verbal',
    defaultMessage: defaults['nonphys.Verbal'],
  },
  Required: {
    id: 'Required',
    defaultMessage: 'Required',
  },
  copyText: {
    id: 'cert.copyText',
    defaultMessage: 'Copy Text',
  },
  prevCert: {
    id: 'cert.prevCert',
    defaultMessage: 'Previous Certification',
  },
  nextCert: {
    id: 'cert.nextCert',
    defaultMessage: 'Next Certification',
  },
  benStartDate: {
    id: 'cert.benStartDate',
    defaultMessage: 'Benefit Start Date',
  },
  benEndDate: {
    id: 'cert.benEndDate',
    defaultMessage: 'Benefit End Date',
  },
  verbalDate: {
    id: 'cert.verbalDate',
    defaultMessage: 'Verbal Date',
  },
  verbalOnly: {
    id: 'cert.verbalOnly',
    defaultMessage: defaults['cert.verbalOnly'],
  },
  timeFormat: {
    id: 'cert.timeFormat',
    defaultMessage: 'Time in {f2fTimezone}',
  },
  'attd.1': {
    id: 'cert.attendingStatement.1',
    defaultMessage: defaults['cert.attendingStatement.1'],
  },
  'attd.2': {
    id: 'cert.attendingStatement.2',
    defaultMessage: defaults['cert.attendingStatement.2'],
  },
  'attd.3': {
    id: 'cert.attendingStatement.3',
    defaultMessage: defaults['cert.attendingStatement.3'],
  },
  'attd.4': {
    id: 'cert.attendingStatement.4',
    defaultMessage: defaults['cert.attendingStatement.4'],
  },
  'attd.5': {
    id: 'cert.attendingStatement.5',
    defaultMessage: defaults['cert.attendingStatement.5'],
  },
  'attd.6': {
    id: 'cert.attendingStatement.6',
    defaultMessage: defaults['cert.attendingStatement.6'],
  },
  'attd.7': {
    id: 'cert.attendingStatement.7',
    defaultMessage: defaults['cert.attendingStatement.7'],
  },
  'attd.8': {
    id: 'cert.attendingStatement.8',
    defaultMessage: defaults['cert.attendingStatement.8'],
  },
  'nid-attd.header': {
    id: 'northidaho.attendingStatement.header',
    defaultMessage: defaults['northidaho.attendingStatement.header'],
  },
  'nid-attd.question': {
    id: 'northidaho.attendingStatement.question',
    defaultMessage: defaults['northidaho.attendingStatement.question'],
  },
  'nid-attd.footer': {
    id: 'northidaho.attendingStatement.footer',
    defaultMessage: defaults['northidaho.attendingStatement.footer'],
  },
  'nid-attd.1': {
    id: 'northidaho.attendingStatement.1',
    defaultMessage: defaults['northidaho.attendingStatement.1'],
  },
  'nid-attd.2': {
    id: 'northidaho.attendingStatement.2',
    defaultMessage: defaults['northidaho.attendingStatement.2'],
  },
  'nid-attd.3': {
    id: 'northidaho.attendingStatement.3',
    defaultMessage: defaults['northidaho.attendingStatement.3'],
  },
  'nid-attd.4': {
    id: 'northidaho.attendingStatement.4',
    defaultMessage: defaults['northidaho.attendingStatement.4'],
  },
  'nid-attd.5': {
    id: 'northidaho.attendingStatement.5',
    defaultMessage: defaults['northidaho.attendingStatement.5'],
  },
  'nid-attd.6': {
    id: 'northidaho.attendingStatement.6',
    defaultMessage: defaults['northidaho.attendingStatement.6'],
  },
  'nid-attd.7': {
    id: 'northidaho.attendingStatement.7',
    defaultMessage: defaults['northidaho.attendingStatement.7'],
  },
  missingParams: {
    id: 'cert.missingParams',
    defaultMessage: defaults['cert.missingParams'],
  },
  printTitle: {
    id: 'cert.printTitle',
    defaultMessage: defaults['cert.printTitle'],
  },
  popup: {
    id: 'cert.popup',
    defaultMessage: defaults['cert.popup'],
  },
  certPhys: {
    id: 'cert.certPhys',
    defaultMessage: defaults['cert.certPhys'],
  },
  certDate: {
    id: 'cert.certDate',
    defaultMessage: defaults['cert.certDate'],
  },
  verbalDateLimit: {
    id: 'cert.verbalDateLimit',
    defaultMessage: defaults['cert.verbalDateLimit'],
  },
  lateCert: {
    id: 'cert.lateCert',
    defaultMessage: defaults['cert.lateCert'],
  },
  done: {
    id: 'Done',
    defaultMessage: defaults.Done,
  },
  ok: {
    id: 'OK',
    defaultMessage: defaults.OK,
  },
});
