import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const printViewHeight = '100%';

type FacesheetContainerProps = {
  smallWidget?: boolean;
  printView?: boolean;
  viewMode?: mumms.viewMode;
  dangerouslySetInnerHTML?: any;
  fullSizeHeight?: string;
  smallWidgetHeight?: string;
  textColor?: string;
  black?: string;
  theme?: any;
};
const FacesheetContainer = styled.div.attrs(
  (props: FacesheetContainerProps) => ({
    height:
      (Boolean(props.viewMode === 'small') && `${props.smallWidgetHeight}px`) ||
      (Boolean(props.viewMode === 'full') && `${props.fullSizeHeight}px`) ||
      (Boolean(props.viewMode === 'print') && printViewHeight),
    backgroundColor:
      (Boolean(props.viewMode === 'small') &&
        props.theme.palette.background.paper) ||
      (Boolean(props.viewMode === 'full') && '#e5e5e5') ||
      (Boolean(props.viewMode === 'print') && '#e5e5e5'),
    color:
      (Boolean(props.viewMode === 'small') && props.textColor) ||
      (Boolean(props.viewMode === 'full') && props.black) ||
      (Boolean(props.viewMode === 'print') && props.black),
    directionsCellDisplay:
      (Boolean(props.viewMode === 'print') && 'none') || 'table-cell',
    facesheetContentDisplay:
      (Boolean(props.viewMode === 'print' || props.viewMode === 'small') &&
        'none') ||
      'table',
    mediaPrintPosition:
      (Boolean(props.viewMode === 'print') && 'relative') || 'absolute',
    mediaPrintHeight:
      (Boolean(props.viewMode === 'print') && 'unset') || 'auto',
  }),
)`
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  .hb-printer-section {
    border-bottom: 1px ${(props) => props.color} solid;
  }
  section .hb-printer-section[style] {
    border-bottom: 1px ${(props) => props.color} solid !important;
  }
  table,
  th,
  td {
    color: ${(props) => props.color};
  }
  overflow: scroll;
  overscroll-behavior: contain;
  .dataGridOddRowPri,
  .dataGridEvenRowPri,
  .dataGridEvenRow,
  .dataGridOddRow {
    background-color: transparent;
  }
  .directions-header-external {
    display: ${(props) => props.directionsCellDisplay};
  }
  #showDirectionsHeader {
    display: none;
  }
  #map-canvas {
    display: none;
  }
  @media print {
    background-color: #ffffff;
    overflow: visible;
    visibility: visible;
    position: ${(props) => props.mediaPrintPosition};
    height: ${(props) => props.mediaPrintHeight};
    width: 100%;
    left: 0;
    top: 0;
    div {
      page-break-inside: avoid;
    }
    table {
      page-break-after: auto;
    }
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    #content.facesheet-content {
      display: none;
    }
  }
  @page {
    size: auto;
    margin: 20mm important!;
  }
  @media screen {
    #content.facesheet-content {
      display: ${(props) => props.facesheetContentDisplay};
      visibility: visible;
    }
  }
`;

export const FacesheetComponent = forwardRef(
  ({ viewMode, html }: any, ref: any) => {
    const {
      props: {
        dashboard: { smallWidgetHeight, fullSizeHeight },
      },
      palette: {
        facesheet: { textColor },
        common: { black },
      },
    } = useTheme();

    const isSmallWidget = Boolean(viewMode === 'small');
    // const isFullWidget = Boolean(viewMode === 'full');
    const isPrintWidget = Boolean(viewMode === 'print');

    return (
      <div ref={ref}>
        <FacesheetContainer
          id="facesheetContainer"
          className="printableContent"
          smallWidget={isSmallWidget}
          printView={isPrintWidget}
          viewMode={viewMode}
          dangerouslySetInnerHTML={{ __html: html }}
          fullSizeHeight={fullSizeHeight}
          smallWidgetHeight={smallWidgetHeight}
          textColor={textColor}
          black={black}
        />
      </div>
    );
  },
);

export default FacesheetComponent;
