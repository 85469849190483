import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import {
  // Paper,
  Grid,
} from '@material-ui/core';
import {
  CardHeaderTab,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@mummssoftware/common-ui';

type discreteProps = {
  discrete?: string;
};

type ContainerProps = {
  'print-hide'?: string;
};
type PanelProps = {
  discrete?: boolean;
  printHide?: boolean;
  subHeader?: React.ReactNode;
  title?: React.ReactNode;
};

const printRules = css`
  padding: 0;
  margin: 2rem 0;
`;

const ContainerGrid = styled(Grid)<ContainerProps>`
  padding: 2rem 0;
  ${(props) =>
    props['print-hide']
      ? css`
          && {
            @media print {
              display: none;
              ${printRules}
            }
          }
        `
      : css`
          @media print {
            ${printRules}
          }
        `}
`;

const HeaderGrid = styled(Grid)`
  padding: 0 10px;
`;

// background: props => props.theme.palette.background.default}ce
const BodyGrid = styled(Grid)<discreteProps>`
  ${(props) =>
    props.discrete
      ? ''
      : css`
          border: 3px solid ${props.theme.palette.background.header};
          border-radius: ${props.theme.shape.borderRadius}px;
          @media print {
            border: 1px solid ${props.theme.props.styledPrintRules.headerColor};
            border-radius: ${props.theme.shape.borderRadius}px;
          }
        `}
`;

const StyledPanel = styled(ExpansionPanel)<discreteProps>`
  ${(props) =>
    props.discrete
      ? css`
          && {
            background: #1111119e;
            margin-bottom: -15px;
            @media print {
              background: none;
            }
          }
        `
      : ''}
`;

const StyledPanelDetails = styled(ExpansionPanelDetails)<discreteProps>`
  ${(props) =>
    props.discrete
      ? css`
          && {
            margin-bottom: -10px;
          }
        `
      : ''}
`;
export const Panel: React.SFC<PanelProps> = ({
  children,
  discrete,
  printHide,
  subHeader,
  title,
}) => {
  const dis = discrete ? 'true' : undefined;
  const hideP = printHide ? 'true' : undefined;
  return (
    <ContainerGrid container print-hide={hideP}>
      <HeaderGrid item container>
        <CardHeaderTab bottom={-1}>{title}</CardHeaderTab>
      </HeaderGrid>
      <BodyGrid discrete={dis} item container>
        <StyledPanel discrete={dis} expanded>
          {subHeader ? (
            <ExpansionPanelSummary>{subHeader}</ExpansionPanelSummary>
          ) : null}
          <StyledPanelDetails discrete={dis}>
            <Fragment>{children}</Fragment>
          </StyledPanelDetails>
        </StyledPanel>
      </BodyGrid>
    </ContainerGrid>
  );
};

export default Panel;
