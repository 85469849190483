/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TopBar } from '@mummssoftware/common-ui';
import type { BaseAppProps } from './types';

export const FormsTopBar = React.memo(
  forwardRef(
    (
      { embedded, patient, logout, currentUser, formName }: BaseAppProps,
      ref,
    ) => {
      const matchTitle: Record<string, string> = {
        'idg-lcd': 'IDG',
        'idg-pgba': 'IDG',
        covid19: 'Form Covid19',
        electionaddendum: 'Election Addendum',
      };
      const title =
        formName && matchTitle[formName] ? matchTitle[formName] : 'Forms';

      if (embedded) {
        return null;
      }
      return (
        <TopBar
          ref={ref}
          title={title}
          subtitle={patient ? `${patient.firstName} ${patient.lastName}` : null}
          disableSitePicker
          currentUser={currentUser}
          logout={logout}
        />
      );
    },
  ),
);
