import { defineMessages } from 'react-intl';
export default defineMessages({
  chart: {
    id: 'common.nav.chart',
    defaultMessage: 'Chart'
  },
  close: {
    id: 'common.nav.close',
    defaultMessage: 'Close'
  },
  print: {
    id: 'common.nav.print',
    defaultMessage: 'Print'
  },
  facesheet: {
    id: 'common.nav.facesheet',
    defaultMessage: 'Facesheet'
  }
});