/*
 * Listing Messages
 *
 * This contains all the text for the Listing component.
 */

import { defineMessages } from 'react-intl';
import defaults from '../../../translations/en.json';

export const messages = defineMessages({
  certTab: {
    id: 'header.cerTab',
    defaultMessage: 'Certifications',
  },
  approvalTab: {
    id: 'header.approvalTab',
    defaultMessage: 'Unsigned Verbals',
  },
  daysOverdue: {
    id: 'grid.daysOverdue',
    defaultMessage: 'Overdue {days} days',
  },
  cert: {
    id: 'Certifying',
    defaultMessage: 'Certifying',
  },
  attd: {
    id: 'Attending',
    defaultMessage: 'Attending',
  },
  verb: {
    id: 'Verbal',
    defaultMessage: 'Verbal',
  },
  noVerbal: {
    id: 'noVerbal',
    defaultMessage: 'No Verbal',
  },
  dueToday: {
    id: 'dueToday',
    defaultMessage: 'Due Today',
  },
  sigRequired: {
    id: 'sigRequired',
    defaultMessage: 'Signature Required',
  },
  needed: {
    id: 'Needed',
    defaultMessage: 'Needed',
  },
  done: {
    id: 'Done',
    defaultMessage: 'Done',
  },
  resetAll: {
    id: 'grid.resetAll',
    defaultMessage: defaults['grid.resetAll'],
  },
  doneOn: {
    id: 'grid.doneOn',
    defaultMessage: 'Done {date}',
  },
  notRequired: {
    id: 'Not Required',
    defaultMessage: 'Not Required',
  },
  rowsLoaded: {
    id: 'grid.rowsLoaded',
    defaultMessage: '{rows} rows loaded',
  },
  chart: {
    id: 'chart',
    defaultMessage: 'Chart',
  },
  notSetup: {
    id: 'phead.notSetup',
    defaultMessage: 'This user is not configured for ClearScripts',
  },
});

export default messages;