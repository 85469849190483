import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { TopBar as MedsTopBar, WidgetHeader } from '@mummssoftware/common-ui';
import { formatDOBFromPim } from '@mummssoftware/utils/formatters';
import { messages } from '../utils/messages';
import Body from './Body';
import TopBarContent from '../Sections/TopBar/TopBarContent';
import Medications from './Medications';
import { withMedContext } from '../App/MedContext';
import {
  FILTER_MEDS,
  SET_SORT,
  UPDATE_MED,
  RESET_FILTERS,
  ONLY_NEED_APPROVAL,
  SET_ERROR_MODAL,
} from '../App/constants';
import { Loader, Modal } from '../Sections';
import PrintLayout from '../Sections/Print/PrintLayout';

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    notifyRowCount: false,
  },
  modalRoot: {
    marginTop: 'var(--safe-area-inset-top)',
    '& > * > *::-webkit-scrollbar-track, & > * > *::-webkit-scrollbar-corner': {
      background: `${theme.palette.background.default}ce`,
    },
    '@media print': {
      overflow: 'visible',
      visibility: 'hidden',
      display: 'none',
    },
  },
  modalRootMobile: {
    '& > * > *::-webkit-scrollbar-track, & > * > *::-webkit-scrollbar-corner': {
      background: theme.palette.background.header,
    },
    [theme.breakpoints.up('sm')]: {
      '@media print': {
        maxWidth: theme.props.isSafari ? 'calc(100% - 3cm)' : null,
      },
    },
  },
  loaderPaper: {
    background: 'transparent',
  },
  errorCell: {
    color: theme.palette.error.main,
  },
  openRoot: {
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    position: 'absolute',
    touchAction: 'none',
    ...theme.props.printRules.hide,
  },
  pLinkContainer: {
    position: 'relative',
    display: 'inline-block',
    marginLeft: -5,
    minWidth: 24,
    height: 14,
  },
  patientLink: {
    position: 'absolute',
  },
  certHeader: {
    '&:hover $certHeaderIcon': {
      opacity: 1,
    },
  },
  certHeaderIcon: {
    opacity: 0,
    transition: 'opacity 0.2s ease 0s',
  },
  certCellCheckRoot: {
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    cursor: 'default',
    color: theme.palette.primary.main,
    padding: 0,
    verticalAlign: 'text-bottom',
  },
  certCellIcon: { fontSize: 20 },
  iconLaunch: {
    fontSize: 18,
  },
});

const LoaderX = <Loader key="nested-loader" />;

class Listing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.prevFrom = false;
    this.prevTo = false;
    this.toSearch = false;
    this.state = {
      open: false,
      expand: !!props.viewMode,
      loaded: true,
      message: {},
      medsError: [],
    };
  }

  static defaultProps = {
    isMobile: false,
  };

  headerRef = React.createRef();

  containerRef = React.createRef();

  handleSearch = (event) => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: 'toSearch',
        paramVal: event.target.value,
      },
    });
  };

  resetFilters = () => {
    this.props.dispatch({ type: RESET_FILTERS });
  };

  expandAll = () => {
    this.setState(
      {
        expand: false,
      },
      () => {
        this.setState({
          expand: true,
        });
      },
    );
  };

  showAll = () => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: 'type',
        paramVal: 'all',
      },
    });
  };

  showActive = () => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: 'type',
        paramVal: 'active',
      },
    });
  };

  showNeedsApproval = () => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: 'type',
        paramVal: 'needsApproval',
      },
    });
  };

  showRecent = () => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: 'type',
        paramVal: 'recent',
      },
    });
  };

  collapseAll = () => {
    this.setState(
      {
        expand: true,
      },
      () => {
        this.setState({
          expand: false,
        });
      },
    );
  };

  handleExternalLink = async () => {
    const { getExternalURL } = this.props;
    this.setState({ loaded: false });
    const response = await getExternalURL();
    if (response.embeddable === 'false' && !response.status) {
      this.setState({
        url: response.url,
      });
      response.message = <FormattedMessage {...messages.finished} />;
      response.url = null;
    }
    this.toggleModal(response);
    this.setState({ loaded: true });
  };

  handleDateChange = (type, value) => {
    this.props.dispatch({
      type: FILTER_MEDS,
      payload: {
        updateParam: type,
        paramVal: value,
      },
    });
  };

  handleOnClick = () => {
    const {
      smallWidget,
      medications,
      onClick,
      dispatch,
      readonly,
    } = this.props;
    const medsError = medications
      .filter(
        (m) =>
          !m.orderingphysicianfirstname ||
          m.notcoveredreason === null ||
          !m.coveredbyhospice,
      )
      .map((m) => m.drugdescription);
    this.setState({
      medsError,
    });

    if (!smallWidget && !readonly && medsError && medsError.length) {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: true,
      });
    } else {
      onClick && onClick();
    }
  };

  toggleModal = async (response) => {
    const { open, url } = this.state;
    const { errorModalOpen, dispatch, smallWidget } = this.props;
    // if error in meds before closing the widget bar
    if (errorModalOpen) {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: false,
      });
    } else {
      if (!open && url) {
        const tab = window.open(url);
        this.setState({
          tab,
        });
      }
      const { updateMedications, updateMaintStatus } = this.props;
      const message = response || {};
      if (open) {
        await updateMaintStatus('complete');
        !smallWidget && updateMedications();
        const { tab } = this.state;
        tab && tab.close();
      }
      this.setState({
        open: !open,
        message,
      });
      if (open && smallWidget) {
        this.handleOnClick();
      }
    }
  };

  handleColClick = (cols) => {
    this.props.dispatch({ type: SET_SORT, payload: cols });
  };

  handleMedChange = (medication, key, value) => {
    this.props.dispatch({
      type: UPDATE_MED,
      payload: {
        med: medication,
        key,
        value,
      },
    });
  };

  componentDidMount() {
    // todo better handling of the hard coded id
    const id = this.props.smallWidget ? 'small-container' : 'large-container';
    const containerWidth = document.getElementById(id).clientWidth;
    this.setState({ containerWidth });
    if (this.props.maintainMed) {
      this.showNeedsApproval();
      this.props.dispatch({
        type: ONLY_NEED_APPROVAL,
        payload: true,
      });
    }
  }

  printComponentRef: any;

  render() {
    const {
      classes,
      hospiceBin,
      hospiceName,
      isMumms,
      firstName,
      lastName,
      embedded,
      patient,
      doctors,
      siteId,
      medications,
      siteNickname,
      siteList,
      handleAgencyChange,
      handleSiteChange,
      logout,
      handleApproveMeds,
      maintainMed,
      dashboardEmbedded,
      appTitle,
      onClick,
      patientTopBar,
      online,
      smallWidget,
    } = this.props;
    const { expand, open, message, loaded, medsError } = this.state;
    const { errorModalOpen } = this.props;
    const appBarTitle = `${patient?.firstName} ${patient?.lastName} (${
      patient?.patientNumber
    }) - ${
      patient && patient.sex ? patient.sex[0] : undefined
    } - DOB ${formatDOBFromPim(patient?.dateOfBirth)} (${patient?.age})`;
    const offLineTitle = `${patient?.firstName} ${patient?.lastName} (${patient?.patientNumber}) - Offline`;
    // eslint-disable-next-line no-nested-ternary
    const rest = online === false ? offLineTitle : appBarTitle;
    const title = <FormattedMessage {...messages.title} values={{ rest }} />;

    const handleApproveMedsOnly = () => {
      if (maintainMed) {
        this.props.dispatch({
          type: FILTER_MEDS,
          payload: {
            updateParam: 'type',
            paramVal: undefined,
          },
        });
      }
      handleApproveMeds();
    };

    const topBarHeightDashboard =
      document.getElementById('topbar') &&
      document.getElementById('topbar').clientHeight;

    return (
      <div
        id={smallWidget ? 'small-container' : 'large-container'}
        className={this.state.open ? classes.openRoot : ''}
        ref={this.containerRef}
      >
        {!loaded ? LoaderX : null}
        {/* show TopBar if not embedded or patientTopBar */}
        {(!embedded || patientTopBar) && (
          <MedsTopBar
            ref={this.headerRef}
            title={patient ? title : 'ClearScripts'}
            titleInfo={`v${process.env.REACT_APP_VERSION}`}
            firstName={firstName}
            lastName={lastName}
            siteList={siteList}
            position={patientTopBar ? 'relative' : 'fixed'}
            currentAgency={
              /* eslint-disable indent */
              hospiceBin
                ? {
                    hospiceBin,
                    hospiceName,
                  }
                : undefined
            }
            currentSite={{
              id: siteId,
              nickName: siteNickname,
            }}
            enableAgencyPicker={isMumms}
            logout={logout}
            noLogout={patientTopBar}
            onAgencyChange={handleAgencyChange}
            onSiteChange={handleSiteChange}
          />
        )}

        {dashboardEmbedded && (
          <ReactToPrint
            content={() => this.printComponentRef}
            pageStyle="@page { size: auto;  margin: 20mm 15mm important!; }"
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <WidgetHeader
                  appTitle={appTitle || 'Medications'}
                  versionInfo={`Clearscripts v${process.env.REACT_APP_CLEARSCRIPTS_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
                  onClick={this.handleOnClick}
                  onPrint={handlePrint}
                  showPrint={!smallWidget}
                  smallWidget={smallWidget}
                  position="left"
                >
                  <TopBarContent
                    handleApproveMeds={handleApproveMedsOnly}
                    handleExternalLink={this.handleExternalLink}
                    messages={messages}
                    smallWidget={smallWidget}
                  />
                </WidgetHeader>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        )}

        <Body
          embedded={embedded}
          dashboardEmbedded={dashboardEmbedded}
          headerRef={this.headerRef}
          TopBarContent={
            <TopBarContent
              handleApproveMeds={handleApproveMedsOnly}
              handleExternalLink={this.handleExternalLink}
              messages={messages}
            />
          }
          PrintView={
            <PrintLayout
              ref={(el) => {
                this.printComponentRef = el;
              }}
              medications={medications}
              patient={title}
              facility={patient.facility}
              hospiceName={patient.hospiceName}
            />
          }
          Medications={
            <Medications
              expand={expand}
              doctors={doctors}
              handleMedChange={this.handleMedChange}
              containerWidth={this.state.containerWidth}
              topBarHeight={topBarHeightDashboard}
              patient={patient}
              hospiceName={hospiceName}
              dashboardEmbedded={dashboardEmbedded}
              containerRef={this.containerRef}
              smallWidget={smallWidget}
            />
          }
          Dialog={
            <Modal
              open={open || errorModalOpen}
              toggleModal={this.toggleModal}
              message={message}
              error={errorModalOpen}
              medsError={medsError}
              onClick={onClick}
            />
          }
        />
      </div>
    );
  }
}

export default injectIntl(
  withStyles(styles, { withTheme: true })(withMedContext(Listing)),
);
