import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { isNumber } from 'lodash-es';
import { makeStyles } from '@material-ui/core/styles';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import { Context } from '../../context';
import { SubmitDiv } from '../layout/WrapperDiv';
import {
  SET_LOADER,
  SET_SCORES_METHOD,
  RESET_SCORES,
  SET_SUBMIT_ACTIVE,
  scoresType,
} from '../../containers/MeasuresContext/utils/constants';
import { MeasuresContext } from '../../containers/MeasuresContext/MeasuresContextProvider';

const useStyles = makeStyles((theme) => ({
  focused: {
    padding: '2px 5px',
    margin: 5,
    backgroundColor: (theme.palette as any).graph.graphColor,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
}));

export const Submit = () => {
  const classes = useStyles();
  const { dispatch, submitActive, methodSelected, scoresState } = useContext(
    MeasuresContext,
  );
  const {
    handleMeasureChange,
    handleErrorMsg,
    patient,
    currentUser,
  } = useContext(Context);
  const patientNumber = patient?.patientNumber;
  const patientSiteId = patient?.siteId.toString();
  const patientId = patient?.id;
  const personId = currentUser.isMumms ? null : currentUser.userId;
  const onClick = () => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    submitForm();
  };

  // eslint-disable-next-line consistent-return
  const submitForm = async () => {
    type Score = mumms.measure;

    const tz = moment.tz.guess();
    const date = formatPostDate(Date.now());
    const scores: any = [];

    try {
      // TODO: UPDATE THIS TO THE BULK ENDPOINT CALL INSTEAD OF A LOOP
      Object.keys(scoresState).forEach((measure: any) => {
        const measureName = measure;
        const scoreValue = scoresState[measure];
        const score: Score = {
          id: '',
          comments: '',
          displayalue: '',
          measurecategoryshortname: scoresType,
          measuredate: '',
          measuredatetime: date,
          measuredatetimeshifted: '',
          measuretypeid: '',
          measuretypename: '',
          measuretypeshortname: measureName,
          missingreason: '',
          observationmethod: methodSelected as string,
          patientid: (patientId as number).toString() as string,
          patientnumber: patientNumber as string,
          personid: personId as string,
          personcredentials: '',
          personfirstname: '',
          personlastname: '',
          personnumber: '',
          siteid: patientSiteId as string,
          timezone: tz,
          units: '',
          value: scoreValue as string,
          value2: '',
          enteredvalue: '',
        };
        if (isNumber(scoreValue) || typeof scoreValue === 'string') {
          scores.push(score);
        }
      });

      await handleMeasureChange(
        patientNumber as string,
        scores,
        'POST',
        scoresType,
      ).catch((e) => {
        console.log('e', e);
        // eslint-disable-next-line no-shadow
      });

      dispatch({
        type: SET_LOADER,
        payload: false,
      });
      dispatch({
        type: SET_SCORES_METHOD,
        payload: null,
      });
      dispatch({
        type: RESET_SCORES,
      });
      dispatch({
        type: SET_SUBMIT_ACTIVE,
        payload: false,
      });
      // TODO: Add response validation
    } catch (ex) {
      return false;
    }
  };

  return (
    <SubmitDiv>
      <Button
        type="submit"
        variant="contained"
        size="small"
        className={classes.focused}
        disabled={!submitActive}
        onClick={onClick}
      >
        Submit
      </Button>
    </SubmitDiv>
  );
};
