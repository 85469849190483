import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Returns an array with duplicate values removed. Highly performant.
 * @param source
 */
export const unique = source => {
  const {
    length
  } = source;
  const result = [];
  const seen = new Set();

  for (let index = 0; index < length; index++) {
    const value = source[index];
    if (seen.has(value)) continue; // eslint-disable-line no-continue

    seen.add(value);
    result.push(value);
  }

  return result;
};
/**
 * Returns an array of objects with duplicate objects by key removed.
 */

export const uniqueByKey = (source, key) => {
  const {
    length
  } = source;
  const result = [];
  const seen = new Set();

  for (let index = 0; index < length; index++) {
    const value = source[index];
    if (seen.has(value[key])) continue; // eslint-disable-line no-continue

    seen.add(value[key]);
    result.push(value);
  }

  return result;
};
/**
 * Takes two arrays of objects and returns the common elements
 */

export const filterByKey = (main, sub, key) => {
  const {
    length
  } = main;
  const seen = new Set();

  for (let index = 0; index < length; index++) {
    const value = main[index];
    if (seen.has(value[key])) continue; // eslint-disable-line no-continue

    seen.add(value[key]);
  }

  return sub.filter(subVal => seen.has(subVal[key]));
};
/**
 * Filters keys out of an object [raw], defined as an array of strings [allowed]
 * @param raw
 * @param allowed
 */

export const filterObj = (raw, allowed) => Object.keys(raw).filter(key => allowed.includes(key)).reduce((obj, key) => _objectSpread(_objectSpread({}, obj), {}, {
  [key]: raw[key]
}), {}); // TODO: Is this better suited elsewhere?

/**
 * Maps the diagnosisDTO class to the form api value
 * - als: 'L';
 * - cancer: 'C';
 * - dementia: 'Z';
 * - heart: 'V';
 * - hiv: 'A';
 * - liver': 'E';
 * - pulmonary: 'P';
 * - renal: 'R';
 * - strokecoma: 'N';
 * - The following are returned only when `true` is passed as the second arg:
 *   - neurologic: 'S';
 *   - debility: 'B';
 *   - other: 'O';
 */

export const getDiagnosisValue = (diagClass, includeSecondary) => {
  switch (diagClass) {
    case 'L':
      return 'als';

    case 'C':
      return 'cancer';

    case 'Z':
      return 'dementia';

    case 'V':
      return 'heart';

    case 'A':
      return 'hiv';

    case 'E':
      return 'liver';

    case 'P':
      return 'pulmonary';

    case 'R':
      return 'renal';

    case 'N':
      return 'strokecoma';

    default:
      if (includeSecondary) {
        switch (diagClass) {
          case 'S':
            return 'neurologic';

          case 'B':
            return 'debility';

          case 'O':
            return 'other';
        }
      }

      return '';
  }
};