export const chartOptions = (border: string | null) => ({
  chart: {
    height: 150,
    marginLeft: 60,
    spacingTop: 0,
    spacingBottom: 0,
    plotBorderWidth: 2,
    zoomType: 'x',
    backgroundColor: 'transparent',
    selectionMarkerFill: `rgba(102, 255, 204, 0.2)`,
  },
  title: null,
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    crosshair: true,
    events: {},
    visible: false,
    type: 'datetime',
    labels: {
      style: border
        ? {
            color: border,
          }
        : {},
    },
  },
  yAxis: {
    // to remove gridline set it at 0
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    title: {
      text: '',
      style: {
        color: border,
      },
    },
    labels: {
      y: 0,
      style: border
        ? {
            color: border,
          }
        : {},
    },
    plotOptions: {
      series: {
        label: false,
        connectNulls: true,
      },
    },
    // trick to not have labels overlapping to be fixed
    showLastLabel: false,
  },
  tooltip: {
    shared: true,
    crosshairs: [true],
    xDateFormat: '%b %e %y, %H:%M',
  },
  plotOptions: {
    series: {
      connectNulls: true,
    },
  },
  lang: {
    noData: 'No data to display',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '11px',
      color: border,
    },
  },
  series: [{}],
});

export const fastCategory = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6a',
  '6b',
  '6c',
  '6d',
  '6e',
  '7a',
  '7b',
  '7c',
  '7d',
  '7e',
  '7f',
];

export const fastMap = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4': 3,
  '5': 4,
  '61': 5,
  '62': 6,
  '63': 7,
  '64': 8,
  '65': 9,
  '71': 10,
  '72': 11,
  '73': 12,
  '74': 13,
  '75': 14,
  '76': 15,
};

export const reverseFastMap = {
  0: '1',
  1: '2',
  2: '3',
  3: '4',
  4: '5',
  5: '6a',
  6: '6b',
  7: '6c',
  8: '6d',
  9: '6e',
  10: '7a',
  11: '7b',
  12: '7c',
  13: '7d',
  14: '7e',
  15: '7f',
};
