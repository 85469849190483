type RadioPrintConfigView = {
  defaultOption: mumms.printConfigView;
  otherOption: mumms.printConfigView;
};

type RadioPrintConfig = {
  [k in mumms.ComponentName]?: RadioPrintConfigView;
};

export const radioPrintConfig: RadioPrintConfig = {
  Manifest: {
    defaultOption: 'Listing',
    otherOption: 'Contents',
  },
  ClearScripts: {
    defaultOption: 'Active',
    otherOption: 'All',
  },
  DocLink: {
    defaultOption: 'Card',
    otherOption: 'Listing',
  },
};

export const printOptionsConfig = {
  defaultOption: 'All Dates',
  otherOption: 'Date Range',
};

// Note this is left here in case we need it
export const checkboxPrintConfig: any = {
  // ClearScripts: {
  //   option: 'Signature',
  // },
};

export const Facesheet: mumms.ComponentName = 'Facesheet';
export const Manifest: mumms.ComponentName = 'Manifest';
