import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { isSmallTouchDevice } from '@mummssoftware/utils/web';
import { ActiveMedications } from '@mummssoftware/utils/constants';
import { ClearScripts, Pim } from '@mummssoftware/utils';
import { WidgetFallback, Loader } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import defaults from '../../translations/en.json';
import { messages } from '../utils/messages';
import { MedProvider } from './MedContext';
import { AppContext } from './AppContext';
import { getCurrentMedications, getAllMedications } from '../utils/utils';
import PrintLayout from '../Sections/Print/PrintLayout';
import Listing from '../Listing';
const translations = defaults;
/**
 * @typedef AppProps
 * @prop {mumms.User} [currentUser]
 * @prop {mumms.patient} [patientHeader]
 *
 */
const appRootHeights = {
  height: (props) => (props.embedded ? 'initial' : '100vh'),
};
const AppRoot = withStyles((theme) => ({
  appRoot: {
    ...(theme.props.isSafari && theme.props.isMobile ? {} : appRootHeights),
    marginLeft: 'var(--safe-area-inset-left)',
    marginRight: 'var(--safe-area-inset-right)',
    marginBottom: 'var(--safe-area-inset-bottom)',
    // overflow hidden was causing user unable to scroll to see all medications
    // overflow: (props) => (props.embedded ? 'visible' : 'hidden'),
    '@media print': {
      overflow: 'visible',
      visibility: 'hidden',
    },
  },
}))(({ children, classes: { appRoot } }) => (
  <div className={appRoot}>{children}</div>
));

const MedsPrintDiv = styled.div`
  @media print {
    page-break-after: always;
  }
`;

const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;
/**
 * @extends {React.PureComponent<AppProps>}
 */
class App extends React.PureComponent {
  /** @param {AppProps} props */
  constructor(props) {
    super(props);
    if (props.online) {
      this.pim = props.pim || new Pim({
        url: process.env.REACT_APP_PIM_BASEURL,
        keycloak: props.keycloak,
        token: props.keycloak.token,
        agency: props.agency,
      });
    }

    this.state = {
      patientID: props.patient,
      from: props.from,
      to: props.to,
      medications: props.medications || [],
      siteList: [],
      loaded: !props.online,
      init: !props.online,
      patient: props.patientHeader || null,
    };
  }

  getDataFromPim = async () => {
    const { patientID } = this.state;
    const {
      currentUser,
      doctors: allDoctors,
      keycloak,
      agency,
      patientHeader,
    } = this.props;

    const [data, patient] = await Promise.all([
      currentUser ? Promise.resolve(currentUser) : this.pim.getUserInfo(),
      patientHeader
        ? Promise.resolve(patientHeader)
        : this.pim.getPatientHeader(patientID),
    ]);

    const doctors = await (allDoctors
      ? Promise.resolve(allDoctors)
      : this.pim.getAllDoctors(patient.siteId));

    this.clearScripts = new ClearScripts({
      url: process.env.REACT_APP_CLEARSCRIPTS_BASEURL,
      keycloak,
      token: keycloak.token,
      agency: agency || data.currentHospiceBin,
    });

    this.setState({
      patient,
      doctors: doctors?.map((doctor) => ({
        label: `${doctor.lastName}${
          doctor.credentials ? ` ${doctor.credentials}` : ''
        }, ${doctor.firstName}`,
        firstName: doctor.firstName,
        lastName: doctor.lastName,
        credentials: doctor.credentials || '',
        value: doctor.personNumber,
      })),
      firstName: data.firstName,
      lastName: data.lastName,
      userId: data.userId,
      userCredentials: data.userCredentials,
      isRoleGroupsWithEditCred: data.isRoleGroupsWithEditCred,
      siteId: data.siteId,
      hospiceBin: data.currentHospiceBin,
      hospiceName: data.hospiceName,
      siteName: data.siteName,
      siteNickname: data.siteNickname,
      siteList: data.siteList,
      isDoctor: data.isDoctor,
      isMumms: data.isMumms,
      hbcFirm: data.hbcFirm,
      siteTimezone: data.siteTimezone,
    });
  };

  updateMedications = async () => {
    const { patientID } = this.state;
    const medications = await this.clearScripts.getMeds(patientID);
    this.setState({
      medications,
      loaded: true,
    });
  };

  getHbcLocation = (siteId, siteList) => {
    const currentSite = siteList.length
      ? siteList.find(({ id }) => id === siteId)
      : null;
    return currentSite ? currentSite.hbcLocation : null;
  };

  handleAgencyChange = (value) => {
    if (typeof value === 'string' && value !== this.state.hospiceBin) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('agency', value);
      const newRelativePathQuery = `${
        window.location.pathname
      }?${searchParams.toString()}`;
      this.props.history.push(newRelativePathQuery);
      /* eslint-disable no-restricted-globals */
      location.reload();
      /* eslint-enable */
    }
  };

  handleSiteChange = (siteId, siteNickname) => {
    this.setState((prevState) => {
      const hbcLocation = this.getHbcLocation(siteId, prevState.siteList);
      return {
        siteId,
        siteNickname,
        hbcLocation,
        enableClinicalLinks: !!(prevState.hbcFirm && hbcLocation),
      };
    });
  };

  getExternalURL = async () => {
    const { patientID } = this.state;
    const url = await this.clearScripts.getExternalUrl(patientID);
    const response = {};
    response.status = url.status;
    if (url.status === 403) {
      response.message = <FormattedMessage {...messages.notSetup} />;
      return response;
    }
    response.message = <FormattedMessage {...messages.finished} />;
    response.url = url.url;
    response.embeddable = url.embeddable;
    return response;
  };

  handleSyncMeds = async () => {
    const { patientID } = this.state;
    this.setState({ loaded: false });
    await this.clearScripts.syncMeds(patientID);
    await this.updateMedications();
    this.setState({ loaded: true });
  };

  handleApproveMeds = async () => {
    const { patientID } = this.state;
    const source = 'clearscripts-client';
    await this.clearScripts.approveMeds(patientID, source);
    await this.updateMedications();
  };

  updateMaintStatus = async (status) => {
    const { patientID } = this.state;
    const success = await this.clearScripts.updateMaintStatus(
      patientID,
      status,
    );
    return success;
  };

  handleMedChange = async (medication) => {
    await this.clearScripts.putMed(medication);
    this.updateMedications();
  };

  componentDidMount = async () => {
    if (this.props.online) {
      await this.getDataFromPim();
      this.updateMedications();
      if (!this.state.init) {
        this.setState({ init: true });
      }
    }
  };

  render() {
    const {
      patient,
      isDoctor,
      firstName,
      lastName,
      hbcLocation,
      hospiceBin,
      hospiceName,
      isMumms,
      siteId,
      siteList,
      siteName,
      siteNickname,
      from,
      medications,
      init,
      loaded,
      to,
      siteTimezone,
    } = this.state;
    const {
      embedded,
      logout,
      toSearch,
      maintainMed,
      online,
      dashboardEmbedded,
      appTitle,
      onClick,
      patientTopBar,
      hideActionBar,
      smallWidget,
      watermark,
      viewMode,
      printConfig,
      onWidgetReady,
    } = this.props;

    const readonly =
      this.props.readonly || !this.state.isRoleGroupsWithEditCred;

    const today = new Date();
    // All Meds show active meds first, discharged meds last
    const printMeds =
      printConfig && printConfig.view === ActiveMedications
        ? getCurrentMedications(medications, today)
        : getAllMedications(medications, today);

    const widgetTitle =
      printConfig && printConfig.view === ActiveMedications
        ? defaults.printTitleActiveMeds
        : defaults.printTitleAllMeds;

    const printTitle = `${patient?.firstName} ${patient?.lastName} (${patient?.patientNumber})`;

    // isPrintMode is a read only view from dashboard, this is history route in stand alone mode
    const isPrintMode = viewMode === 'print';
    // const isEmbeddedMode = viewMode === 'small' || viewMode === 'full';
    // const isStandAloneMode = !viewMode;

    // todo fix that!
    const RootLoader = smallWidget ? (
      <Loader key="root-loader" />
    ) : (
      <WidgetFallback
        embedded={embedded}
        appTitle={translations.medications}
        smallWidget={smallWidget}
      />
    );

    const isMobile = isSmallTouchDevice();

    const appContext = {
      userId: this.state.userId,
      userCredentials: this.state.userCredentials,
      isDoctor: this.state.isDoctor,
      firstName,
      lastName,
      hospiceBin,
      hospiceName,
      isMobile,
      isMumms,
      logout,
      siteName,
      patient,
      hideActionBar,
      readonly,
      smallWidget,
      dashboardEmbedded,
      watermark,
      viewMode,
      siteTimezone,
    };

    if (!init) {
      return (
        <WidgetFallback
          embedded={embedded}
          appTitle={translations.medications}
          smallWidget={smallWidget}
        />
      );
    }

    return (
      <MedProvider
        clearScripts={this.clearScripts}
        handleMedChange={this.handleMedChange}
        from={from}
        to={to}
        toSearch={toSearch}
        medications={medications}
      >
        <AppContext.Provider value={appContext}>
          {/* isPrintMode  */}
          {isPrintMode && (
            <React.Fragment>
              <MedsPrintDiv id="meds-print-div">
                <PrintLayout
                  medications={printMeds}
                  onWidgetReady={onWidgetReady}
                  patient={printTitle}
                  facility={patient.facility}
                  hospiceName={patient.hospiceName}
                  widgetTitle={widgetTitle}
                  isPrintMode={isPrintMode}
                  printConfig={printConfig}
                />
              </MedsPrintDiv>
              <PageBreak />
            </React.Fragment>
          )}

          <AppRoot embedded={embedded}>
            {!loaded ? RootLoader : null}

            {/* if no printMode then full or small or standalone modes  */}
            {!isPrintMode && (
              <Route
                path={['/']}
                render={(props) => (
                  <Listing
                    {...props}
                    from={from}
                    to={to}
                    toSearch={toSearch}
                    getExternalURL={this.getExternalURL}
                    handleSyncMeds={this.handleSyncMeds}
                    handleApproveMeds={this.handleApproveMeds}
                    embedded={embedded}
                    maintainMed={maintainMed}
                    patientTopBar={patientTopBar}
                    readonly={readonly}
                    online={online}
                    dashboardEmbedded={dashboardEmbedded}
                    appTitle={appTitle}
                    onClick={onClick}
                    getDataFromPim={this.getDataFromPim}
                    updateMedications={this.updateMedications}
                    updateMaintStatus={this.updateMaintStatus}
                    patient={this.state.patient}
                    // handleMedChange={this.handleMedChange}
                    doctors={this.state.doctors}
                    handleAgencyChange={this.handleAgencyChange}
                    handleSiteChange={this.handleSiteChange}
                    certContext={AppContext}
                    smallWidget={smallWidget}
                    logout={logout}
                    viewMode={viewMode}
                    {...{
                      isDoctor,
                      firstName,
                      lastName,
                      siteId,
                      siteNickname,
                      siteList,
                      hospiceBin,
                      hospiceName,
                      hbcLocation,
                      isMobile,
                      isMumms,
                    }}
                  />
                )}
              />
            )}
          </AppRoot>
        </AppContext.Provider>
      </MedProvider>
    );
  }
}

export default withRouter(App);
