import DefaultSelectBoxes from 'formiojs/components/selectboxes/SelectBoxes';
import { each, isUndefined } from 'lodash-es';
/**
 * TODO: Once babel 7.9.0 is released (some time after 03/06/20), swap type imports (the two typeof's below)
 * TODO: Track here: https://github.com/babel/babel/milestone/33
 */
import type { components } from 'formiojs/components';
import type { ExtendedComponentSchema } from 'formiojs';
import ExpandedRadio from '../ExpandedRadio';
import settingsForm from './settingsForm';

const type = 'expandedselectboxes';
export default class ExpandedSelectBoxes extends ExpandedRadio {
  checkComponentValidity: DefaultSelectBoxes['checkComponentValidity'];

  constructor(
    component: ComponentJSON | typeof components['base'],
    options: Record<string, any>,
    data: any,
  ) {
    super(component, options, data);
    this.type = type;
    const BaseSelectBoxes = new DefaultSelectBoxes(component, options, data);
    this.normalizeValue = BaseSelectBoxes.normalizeValue.bind(this);
    this.getValueAsString = BaseSelectBoxes.getValueAsString.bind(this);
    this.checkComponentValidity = BaseSelectBoxes.checkComponentValidity.bind(
      this,
    );
  }

  static type = type;

  static get builderInfo() {
    return {
      title: 'Expanded Select Boxes',
      icon: 'plus-square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: ExpandedSelectBoxes.schema(),
    };
  }

  static schema(...extend: ExtendedComponentSchema[]) {
    return ExpandedRadio.schema(
      {
        type: 'expandedselectboxes',
        label: 'Expanded Select Boxes',
        key: 'expandedSelectBoxes',
        inline: false,
      },
      ...extend,
    );
  }

  get emptyValue() {
    return {
      ...this._dataValue,
      value:
        this.component.values?.reduce((prev: Record<string, any>, value) => {
          if (value.value) {
            prev[
              typeof value.value === 'string'
                ? value.value
                : JSON.stringify(value.value)
            ] = false;
          }
          return prev;
        }, {}) || null,
    };
  }

  get inputInfo() {
    const info = super.elementInfo();
    info.attr.name += `[${this.id}]`;
    info.attr.type = 'checkbox';
    info.attr.class = 'form-check-input';
    return info;
  }

  setValue(value: any, flags: any) {
    const changed = this.updateValue(value, flags);
    // eslint-disable-next-line no-param-reassign
    value = this.dataValue.value;
    each(this.refs[this.inputKey], (input: HTMLInputElement) => {
      if (isUndefined(value[input.value])) {
        value[input.value] = false;
      }
      input.checked = !!value[input.value];
    });
    return changed;
  }

  getValue() {
    if (this.viewOnly || !this.refs.input || !this.refs.input.length) {
      return this.dataValue.value;
    }
    const value: Record<string, boolean> = {};
    each(this.refs.input, (input) => {
      value[input.value] = !!input.checked;
    });
    return value;
  }

  /**
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;
}
