import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import Form, { FormProps, FormioUtils } from '@mummssoftware/form';
import { getDiagnosisValue } from '@mummssoftware/utils/data';
import { checkRefs } from '../../utils';
import { FormsContext } from '../../context';
import { translationMessages } from '../../i18n';
import { AlsConsiderations } from '../../components';

type FormWrapperProps = {
  allowCreationNewForm?: boolean;
  onNewForm?: FormProps['onNewForm'];
  onSubmitDone?: FormProps['onSubmitDone'];
  readOnly?: boolean;
  theme?: string;
  patientNumber: string;
  submissionId?: string;
  /** for unit testing */
  _testForm?: mumms.JSONObject;
};

type diagValue = ReturnType<
  typeof import('@mummssoftware/utils/data')['getDiagnosisValue']
>;
type portalKeys = keyof typeof portalComponents;
type portalRefs = {
  [K in portalKeys]?: null | Element;
};

type createHandleComponentChangeArgs = {
  diagValue?: diagValue;
  onCheckRefs: any;
};

const getPrimaryDiagnosis = (diagnosisDTO: mumms.diagnosisDTO[]) => {
  const primary = diagnosisDTO.splice(
    diagnosisDTO.findIndex(({ rank }) => rank === 1),
    1,
  );
  return primary[0];
};

const portalComponents = {
  'als-portal': AlsConsiderations,
};

const createHandleComponentChange = ({
  diagValue,
  onCheckRefs,
}: createHandleComponentChangeArgs): FormProps['onComponentChange'] => (e) => {
  const {
    instance: { type, components },
  } = e;
  const findComponent = (apiKey: string, callback: (comp: any) => void) =>
    FormioUtils.findComponent(components, apiKey, null, callback);
  if (type === 'form') {
    // Formio change events resolve asynchronously
    setTimeout(() => {
      const { changed } = e.flags;
      if (!changed) {
        return;
      }
      if (changed.component.key === 'decline-criteria-met') {
        findComponent('primary-select', (comp) =>
          comp.hook('setPrimaryDiagnosis', { component: comp }),
        );
      }
      if (changed.component.properties?.checkRefs) {
        if (
          diagValue ||
          (changed.component.key === 'primary-select' &&
            typeof changed.value === 'string')
        ) {
          const value = diagValue || (changed.value as string);
          findComponent(value, (comp) => checkRefs(onCheckRefs, comp, value));
        }
      }
    });
  }
};

export const FormWrapper = ({
  onNewForm,
  // eslint-disable-next-line
  onSubmitDone = console.log,
  allowCreationNewForm,
  patientNumber,
  readOnly,
  submissionId,
  theme,
  _testForm,
}: FormWrapperProps) => {
  const {
    currentUser,
    keycloak,
    meetingMetadata,
    locale,
    patient,
    formName,
    agency,
  } = useContext(FormsContext);
  const [refRerender, updateRefRerender] = useState<any>(0);
  const refs = useRef<portalRefs>({});
  const diagnoses = [...(patient?.diagnosisDTO || [])];
  const [primaryDiagnosis] = useState(getPrimaryDiagnosis(diagnoses));
  const diagValue = getDiagnosisValue(primaryDiagnosis?.diagClass);
  const onCheckRefs = useCallback(
    (node: null | Element, id: portalKeys) => {
      const prevNode = refs.current[id];
      if (!node) {
        delete refs.current[id];
      } else {
        refs.current[id] = node;
      }
      if (prevNode !== refs.current[id]) {
        updateRefRerender(refRerender + 1);
      }
    },
    [refRerender],
  );

  const handleComponentChange = useMemo(
    () =>
      createHandleComponentChange({
        diagValue,
        onCheckRefs,
      }),
    [diagValue, onCheckRefs],
  );

  // hooks = hooks || initHooks(diagValue);
  return (
    <Fragment>
      <Form
        key={`${locale}-${patientNumber}-form`}
        additionalMetadata={meetingMetadata}
        formName={formName}
        i18nFallbackNS={formName === 'idg-pgba' ? 'idg-pgba' : 'idg-ngs'}
        keycloak={keycloak}
        currentUser={currentUser}
        readOnly={readOnly}
        // hooks={hooks}
        // debug
        patientNumber={patientNumber}
        patient={patient}
        submissionId={submissionId}
        locale={locale}
        agency={agency}
        translations={translationMessages}
        theme={theme}
        onComponentChange={handleComponentChange}
        readOnlyOnSubmission
        onSubmitDone={onSubmitDone}
        form={_testForm}
        onNewForm={onNewForm}
        allowCreationNewForm={allowCreationNewForm}
      />
      {Object.entries(refs.current).map(([key, portalEl]) => {
        const PortalComponent = portalComponents[key as portalKeys];
        return PortalComponent && portalEl instanceof Element
          ? ReactDOM.createPortal(<PortalComponent />, portalEl as Element)
          : null;
      })}
    </Fragment>
  );
};

/**
 * portalComponents?: Record<string, React.ReactNode>
 * portalRefs?: React.RefObject<Record<string, undefined | null | Element>>
 *
 * ...
 * render() {
 *  ...
 * }
 */

export default FormWrapper;
