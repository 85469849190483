import React from 'react';
import { Slide, Snackbar, SnackbarContent } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

const SuccessTransition = props => <Slide {...props} direction="right" />;

const RootSnackbar = props => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    style={{
      marginBottom: 'var(--safe-area-inset-bottom)',
      marginLeft: 'var(--safe-area-inset-left)',
    }}
    autoHideDuration={2000}
    TransitionComponent={SuccessTransition}
    {...props}
  >
    {props.children}
  </Snackbar>
);

export const SuccessSnackbar = ({
  classes,
  formatMessage,
  handleClose,
  messageId: id,
  open,
}) => (
  <RootSnackbar onClose={handleClose} open={open}>
    <SnackbarContent
      className={classes.success}
      aria-describedby="success-snackbar"
      message={
        <span id="success-snackbar" className={classes.successMessage}>
          <CheckCircleIcon className={classes.successIcon} />
          {id && formatMessage({ id })}
        </span>
      }
    />
  </RootSnackbar>
);

export const GridSnackBar = ({ open, message, closeHandler }) => (
  <RootSnackbar
    onClose={closeHandler}
    open={open}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
  />
);

export const CertSnackBar = ({ open, message, closeHandler }) => (
  <RootSnackbar
    open={open}
    onClose={closeHandler}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
  />
);
