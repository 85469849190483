import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { HospiceItemSetTableWrapper } from '../../components/layout/WrapperDiv';
import { TableMode } from './types';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

interface TableProps {
  frameworkComponents?: any;
  gridOptions?: any;
  rowData?: any;
  mode?: TableMode;
  loadingOverlayComponent?: any;
}

export const AgGridTable = ({
  frameworkComponents,
  gridOptions,
  rowData,
  mode,
  loadingOverlayComponent,
}: TableProps) => {
  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;

  return (
    <HospiceItemSetTableWrapper
      id="hospice-info-table-wrapper"
      // className="ag-theme-alpine"
      className={`${gridTheme.className} light hospice`}
      mode={mode}
    >
      <AgGridReact
        gridOptions={gridOptions}
        rowData={rowData || []}
        rowHeight={gridTheme.rowHeight}
        headerHeight={gridTheme.headerHeight}
        frameworkComponents={frameworkComponents}
        loadingOverlayComponent={loadingOverlayComponent}
      />
    </HospiceItemSetTableWrapper>
  );
};

export default AgGridTable;
