import { Accordion as MuiExpansionPanel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/** @component */

export const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    margin: 'auto',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {
    margin: 'auto'
  }
})(MuiExpansionPanel);