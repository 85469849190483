export type baseForm = {
  name: string;
  patient: string;
  worker: string;
  divisions: Array<{
    name: string;
    questions: Array<{
      label: string | null;
      answer: string | null;
    }>;
  }>;
};

const createDivision = (division: any) => `<div class="division">
    <div class="division-name">${division.name}</div>
    <table class="division">
      <tbody>
        <tr>
${division.body}
        </tr>
      </tbody>
    </table>
  </div>`;

export const unify = (form: baseForm) => `
    <root>
      <table class="questionnaire-view">
        <tbody>
          <tr>
            <td class="questionnaire-details questionnaire-header">
              <table>
                <tbody>
                  <tr>
                    <td class="questionnaire-name">${form.name}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td class="questionnaire-details" colspan="6">
              <table>
                <tbody>
                  <tr>
                    <td class="questionnaire-detail-title">
                      <nobr>Patient:</nobr>
                    </td>
                    <td class="questionnaire-detail">${form.patient}</td>
                    <td class="questionnaire-detail"></td>
                  </tr>
                  <tr>
                    <td class="questionnaire-detail-title">
                      <nobr>Electronically Generated By:</nobr>
                    </td>
                    <td class="questionnaire-detail">${form.worker}</td>
                    <td class="questionnaire-detail"></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td class="questionnaire-details" colspan="6"></td>
          </tr>
        </tbody>
      </table>
      ${form.divisions.reduce(
        (divisionstrings, division) =>
          `${divisionstrings}${createDivision(division)}`,
        '',
      )}
    </root>`;
