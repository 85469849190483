import React, { useContext } from 'react';
import { WidgetHeader } from '@mummssoftware/common-ui';
import { Typography } from '@material-ui/core';
import { Context } from '../../context';
import { NotFoundWrapper } from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';

declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type NotFoundProps = typeof RouteProps;

export const NotFound = (props: NotFoundProps) => {
  const {
    patientError,
    measuresTypesError,
    measuresError,
    patient,
  } = useContext(Context);
  let patientNumber: string | undefined;
  let status: number | undefined;
  let statusText: string | undefined;
  let measuresTypesStatus: number | undefined;
  let measuresTypesStatusText: string | undefined;
  let measuresStatus: number | undefined;
  let measuresStatusText: string | undefined;

  if (patientError) {
    ({ patientNumber, status, statusText } = patientError);
  } else {
    ({ patientNumber } = props.match.params);
  }
  if (measuresTypesError) {
    ({ measuresTypesStatus, measuresTypesStatusText } = measuresTypesError);
  }
  if (measuresError) {
    ({ measuresStatus, measuresStatusText } = measuresError);
  }

  // TODO spanish translation?
  // TODO agency name
  return (
    <React.Fragment>
      <WidgetHeader
        appTitle={defaults.measures}
        versionInfo={`Measures v${process.env.REACT_APP_MEASURES_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
        noIcon
      ></WidgetHeader>
      <NotFoundWrapper>
        {patientNumber ? (
          <Typography>
            {`Error loading patient ${patientNumber}: ${status} ${statusText}`}
          </Typography>
        ) : null}
        {!patient && !patientNumber ? (
          <Typography>Please enter a patient number.</Typography>
        ) : null}
        {measuresTypesError ? (
          <Typography>
            {`Error loading measures types for agency: ${measuresTypesStatus} ${measuresTypesStatusText}`}
          </Typography>
        ) : null}
        {measuresError ? (
          <Typography>
            {`Error loading measures for patient ${patientNumber}: ${measuresStatus} ${measuresStatusText}`}
          </Typography>
        ) : null}
      </NotFoundWrapper>
    </React.Fragment>
  );
};

export default NotFound;
