import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  FormattedDate,
  FormattedTime,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Grid, Link, Tooltip } from '@material-ui/core';
import {
  ActionIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  gridContainerStyle as gridContainer,
  SectionHeader,
} from '@mummssoftware/common-ui';
import { Listing, Card } from '@mummssoftware/utils/constants';
import { formatPhysicianName } from '@mummssoftware/utils/formatters';
import messages from './CertMessages';
import { CertHeader, CertNav, CertBody, CertFooter } from './Components';
// TODO a shared component folder with snackbar?
import { CertSnackBar } from '../Listing/Components/Snackbars';
import { withCertContext } from '../../Context';
import { allElectionsAttendingStates } from '../../utils';
import { formatNarrativeVal } from './Components/utils';
import {
  dateFormat,
  ATTENDING_FORM,
  FORM_NARRATIVE,
  NO_ATTENDING,
  SET_DIMENSIONS,
  SET_VERBAL_DATE,
  SET_BUTTON_CLICKED,
} from '../../constants';

// NOTE Overriding styles of Material UI with method 1 : class names, used with withStyles and className
// https://material-ui.com/customization/components/
const styles = (theme) => ({
  root: (props) =>
    props.printConfig
      ? {
          '&': {
            padding: '4px 0',
          },
        }
      : {
          '&': {
            padding: '4px 0',
            minHeight: '150px',
          },
        },
  label: {
    color: theme.palette.text.hint,
    ...theme.props.printRules.textSecondary,
  },
  updatableField: {
    transition: 'text-shadow 0.75s ease-out',
  },
  updatedField: {
    textShadow: `0px 3px 12px ${theme.palette.primary.main},
    0px -3px 12px ${theme.palette.primary.main},
    3px 0px 12px ${theme.palette.primary.main},
    -3px 0px 12px ${theme.palette.primary.main}
    `,
  },
  control: {
    padding: theme.spacing(2),
  },
  copyIcon: {
    padding: 0,
    margin: 6,
    height: 36,
    width: 36,
    position: 'absolute',
    right: 10,
    top: 4,
  },
  printItems: {
    whiteSpace: 'nowrap',
  },
  gridContainer: {
    ...gridContainer,
    height: 'max-content',
  },
  gridContainerBody: {
    ...gridContainer,
    '@media print': {
      '&&': { paddingTop: 12 },
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    '@media print': {
      display: 'none',
    },
  },
  loaderGridContainerBody: {
    '&&': {
      overflow: 'hidden',
      minHeight: 200,
      backgroundImage: `linear-gradient(
                          to bottom,
                          rgba(0, 0, 0, 0) 1%,
                          rgba(0, 0, 0, 0.25) 4%,
                          rgba(0, 0, 0, 0.25) 96%,
                          rgba(0, 0, 0, 0) 98%)`,
      margin: 0,
      padding: 18,
    },
    '&& > *': {
      padding: 12,
    },
  },
  hide: {
    display: 'none',
  },
  certFooter: {
    '&&': {
      padding: '4px',
    },
  },
});

/**
 * @typedef CertFormProps
 * @prop {object} classes
 * @prop {import('../SharedPropDefs').Certification} currentCert
 * @prop {object} currentCertKeys
 * @prop {boolean} [disableForward]
 * @prop {boolean} [disableBack]
 * @prop {boolean} [expandable] Sets whether form is expandable or not
 * @prop {string} formType
 * @prop {string} formPhysId
 * @prop {boolean} formPhysRestricted
 * @prop {boolean} [historicalView]
 * @prop {boolean} [isDoctor]
 * @prop {function} onNavigateCert
 * @prop {function} onHide
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {number} selectedCertIndex
 * @prop {boolean} submitting
 * @prop {function} updateUserInfo
 * @prop {string[]} updates
 * @prop {string} userId
 * @prop {boolean} embedded
 */
/**
 *
 * @extends {React.Component<CertFormProps, {}>}
 */
const Footer = ({
  alreadyCertified,
  certificationDateTime,
  classes,
  certPhysName,
  footerType,
  formNarrativeId,
  formType, // ok
  hideFooter,
  selectedCertIndex,
  updates,
  smallWidget,
  trailingMessage,
  printConfig,
}) =>
  hideFooter ? null : (
    <Grid
      className={`${classes.gridContainer} ${classes.certFooter}`}
      container
      item
      xs={12}
    >
      <CertFooter
        phys={certPhysName}
        footer={footerType}
        alreadyCertified={alreadyCertified}
        smallWidget={smallWidget}
        trailingMessage={trailingMessage}
        printConfig={printConfig}
        formType={formType}
        {...{
          classes,
          certificationDateTime,
          formNarrativeId,
          formType,
          selectedCertIndex,
          updates,
        }}
      />
    </Grid>
  );

class CertForm extends PureComponent {
  constructor(props) {
    super(props);
    const {
      currentCertKeys: { formNarrative },
      selectedCertIndex,
    } = this.props;
    this.certCache = new Array(selectedCertIndex);

    this.state = {
      expanded: true,
      narrative: this.getCertNarrative(props.currentCert, formNarrative),
      textCopy: '',
    };

    if (props.isDoctor === null) {
      props.updateUserInfo();
    }
    // NOTE: when cert-app is embedded need to know where to locate the physician picker on the page
    // position is calculated when clicking on the button, default is middle of page
    this.refCallback = (element) => {
      if (element) {
        this.element = element;
        const dimensions = element.getBoundingClientRect();
        props.dispatch({
          type: SET_DIMENSIONS,
          payload: dimensions,
        });
      }
    };
  }

  static defaultProps = {
    updates: [],
  };

  getCertNarrative = (cert, formNarrative) =>
    cert[formNarrative] === null ? '' : cert[formNarrative];

  setRecordAll = (
    attendingPhysicianId,
    electionNumber,
    attendingPhysWrittenDate,
  ) =>
    attendingPhysicianId === NO_ATTENDING &&
    electionNumber === 1 &&
    !attendingPhysWrittenDate;

  handleCertify = (e) => {
    const clicked = e.target.closest('button');
    const dimensions = this.element.getBoundingClientRect();
    // NOTE: when cert-app is embedded need to know where to locate the physician picker on the page
    // position is calculated when clicking on the button
    if (this.props.embedded && dimensions && dimensions.width > 500) {
      this.props.dispatch({
        type: SET_DIMENSIONS,
        payload: null,
      });
    }
    const buttonClicked =
      clicked.dataset.endpoint === 'recordverbal' ? 'Verbal' : 'Signature';
    this.props.dispatch({
      type: SET_BUTTON_CLICKED,
      payload: buttonClicked,
    });
    this.props.onCertify(
      this.state.narrative,
      this.props.currentCert.electionperiodId,
      this.props.formType === ATTENDING_FORM,
      this.setRecordAll(
        this.props.currentCert.episode.attendingPhysicianId,
        this.props.currentCert.electionNumber,
        this.props.currentCert.attendingPhysWrittenDate,
      ),
      clicked && clicked.dataset.endpoint,
      clicked && clicked.dataset.successmsg,
      this.props.currentCert.episode.attendingPhysicianId,
      this.props.userId,
    );
  };

  handleChanges = (prevCert) => {
    const { currentCert, currentCertKeys } = this.props;
    const updates = Object.entries(currentCertKeys).reduce((newArr, [k, v]) => {
      if (k === 'formPhys' && prevCert[v].id !== currentCert[v].id) {
        newArr.push(k);
      } else if (prevCert[v] !== currentCert[v]) {
        newArr.push(k);
      }
      return newArr;
    }, []);
    this.setState(
      (prevState) => ({
        narrative: updates.includes(FORM_NARRATIVE)
          ? currentCert[currentCertKeys.formNarrative]
          : prevState.narrative,
      }),
      () => {
        setTimeout(() => this.props.setUpdatedKeys(updates, 250));
      },
    );
  };

  handleExpand = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  captureNarrative = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  copyText = () => {
    document.querySelector('#narrative-field').select();
    document.execCommand('copy');
    this.setState((prevState) => ({
      textCopy: prevState.narrative,
      openSnackBar: true,
    }));
  };

  closeSnackbar = () => {
    this.setState({ openSnackBar: false });
  };

  pasteText = () => {
    this.setState((prevState) => ({
      narrative: prevState.textCopy,
      textCopy: '',
    }));
  };

  certPhysName = (alreadyCertified) => {
    const {
      currentCertKeys,
      isDoctor,
      firstName,
      lastName,
      userCredentials,
      currentCert: { [currentCertKeys.formPhys]: formPhys },
    } = this.props;
    let certPhysName = '';
    if (isDoctor && !alreadyCertified && !currentCertKeys.useFormPhys) {
      certPhysName = formatPhysicianName(
        '',
        firstName,
        lastName,
        userCredentials,
      );
    } else if (alreadyCertified || currentCertKeys.useFormPhys) {
      certPhysName = formatPhysicianName(
        '',
        formPhys.firstName,
        formPhys.lastName,
        formPhys.credentials,
      );
    }
    return certPhysName;
  };

  getF2fDetails = () => {
    const {
      currentCert: {
        electionNumber,
        electionNumberForF2F,
        face2faceVisit: {
          siteTimezone,
          effectiveDateUTC,
          provider,
          visit_id: visitId,
          cpcvisitid,
        },
        episode: { hbcLocation },
      },
      patient: { patientNumber },
      enableClinicalLinks,
      m2Url,
    } = this.props;
    const siteTz = siteTimezone || moment.tz.guess();
    const required =
      typeof electionNumberForF2F === 'number' &&
      electionNumber >= electionNumberForF2F;
    const f2fTimezone = moment().tz(siteTz).format('z');
    /* eslint-disable indent */
    const f2fDateTime = effectiveDateUTC
      ? moment
          .utc(effectiveDateUTC)
          // if siteTimezone === null, format breaks.
          .tz(siteTz)
      : '';
    const linkText = <FormattedMessage {...messages.f2fLink} />;
    /* eslint-enable */
    return {
      missing: required && visitId === null,
      date: f2fDateTime ? (
        <Tooltip
          title={
            <FormattedMessage
              {...messages.timeFormat}
              values={{ f2fTimezone }}
            />
          }
        >
          <span>
            <FormattedDate
              value={f2fDateTime}
              format="long"
              timeZone={siteTz}
            />
            {/* <FormattedTime value={f2fDateTime} timeZone={siteTz} /> */}
          </span>
        </Tooltip>
      ) : null,
      link:
        enableClinicalLinks && cpcvisitid ? (
          <Link
            href={m2Url(
              'f2fView',
              patientNumber,
              hbcLocation,
              `?render=mid-ui&visitId=${cpcvisitid}`,
            )}
            target="__blank"
          >
            {linkText}
          </Link>
        ) : (
          linkText
        ),
      provider,
      required,
    };
  };

  setCanCertify = (useFormPhys, userId, formPhysId, formPhysRestricted) =>
    !(userId === formPhysId && formPhysRestricted) &&
    !(useFormPhys && userId !== formPhysId && !formPhysRestricted);

  setBody = (f2fMissing, noAttendingRequired, inDateRange) => {
    const { verbalOnly } = this.props;
    let select = 'default';
    if (this.props.formType === ATTENDING_FORM && noAttendingRequired) {
      select = 'noAttendingRequired';
    } else if (f2fMissing || !inDateRange) {
      select = 'errorState';
    } else if (verbalOnly) {
      select = 'verbalOnly';
    }
    return select;
  };

  setFooter = (
    isDoctor,
    alreadyCertified,
    f2fMissing,
    transferredAndVerified,
    noAttendingRequired,
    formType,
  ) => {
    let select = 'default';
    if (transferredAndVerified) {
      return select;
    } else if (alreadyCertified) {
      select = 'certified';
    } else if (
      (!isDoctor && !f2fMissing) ||
      (formType === ATTENDING_FORM && !noAttendingRequired)
    ) {
      select = 'print';
    }
    return select;
  };

  setErrors = (f2fMissing, inDateRange) => ({
    outOfRange: !inDateRange,
    f2fMissing,
  });

  headerTitle = () => {
    const {
      formType: regularFormtype,
      currentCert: { electionNumber },
      patient,
      printFormType,
    } = this.props;
    const formType = this.props.printConfig ? printFormType : regularFormtype;
    const baseTitle = this.props.intl.formatMessage(messages.Certification);
    if (
      electionNumber === 1 ||
      allElectionsAttendingStates.includes(
        patient.primaryAddress && patient.primaryAddress.state,
      )
    ) {
      return formType === ATTENDING_FORM ? (
        <FormattedMessage {...messages.attendingTitle} values={{ baseTitle }} />
      ) : (
        <FormattedMessage {...messages.hospiceTitle} values={{ baseTitle }} />
      );
    }
    return baseTitle;
  };

  hideCert = (alreadyCertified, body, noNarrative, f2fMissing) =>
    /** Don't hide if it's still checking... */
    (!(body === 'loader') &&
      /** Go ahead though, if it's already certified, and there's no narrative. */
      alreadyCertified &&
      noNarrative) ||
    /** Or if there's no f2f. That's no bueno, right? */
    f2fMissing;

  componentDidUpdate(prevProps) {
    const {
      currentCert,
      expanded,
      selectedCertIndex,
      formType,
      currentCertKeys,
    } = this.props;
    if (expanded !== this.state.expanded && expanded !== prevProps.expanded) {
      this.handleExpand();
    }
    if (
      prevProps.selectedCertIndex !== selectedCertIndex ||
      prevProps.formType !== formType
    ) {
      // eslint-disable-next-line
      this.setState({
        narrative: this.getCertNarrative(
          currentCert,
          currentCertKeys.formNarrative,
        ),
      });
    } else if (
      prevProps.currentCert !== currentCert &&
      prevProps.currentCert.electionperiodId === currentCert.electionperiodId
    ) {
      this.handleChanges(prevProps.currentCert);
    }
    const formPhysVerbalDate = currentCert[currentCertKeys.formPhysVerbalDate];
    this.props.dispatch({
      type: SET_VERBAL_DATE,
      payload: formPhysVerbalDate,
    });
  }

  render() {
    const {
      classes,
      currentCertKeys,
      currentCert: {
        attendingPhysWrittenDate,
        electionNumber,
        attendingPhys: { id },
        episode: { attendingPhysicianId },
        expectedEndDate,
        [currentCertKeys.formPhys]: formPhys,
        [currentCertKeys.formPhysWrittenDate]: formPhysWrittenDate,
        [currentCertKeys.formPhysVerbalDate]: formPhysVerbalDate,
        [currentCertKeys.formNarrativeCreationDate]: formNarrativeCreationDate,
        [currentCertKeys.formNarrativeId]: formNarrativeId,
        [currentCertKeys.formTakenByFirstName]: formTakenByFirstName,
        [currentCertKeys.formTakenByLastName]: formTakenByLastName,
        [currentCertKeys.formVerbalTakenByFirstName]: formVerbalTakenByFirstName,
        [currentCertKeys.formVerbalTakenByLastName]: formVerbalTakenByLastName,
        [currentCertKeys.formVerbalTakenByCredentials]: formVerbalTakenByCredentials,
        narrativeSiteTimezone,
        priorHospice,
        startDate,
        documentationVerified,
      },
      disableForward,
      disableBack,
      expandable,
      formPhysId,
      formPhysRestricted,
      formType,
      historicalView,
      intl: { formatDate, formatMessage },
      isDoctor,
      // onHide,
      onNavigateCert,
      selectedCertIndex,
      submitting,
      userId,
      updates,
      embedded,
      smallWidget,
      printConfig,
      printFormType,
    } = this.props;
    const noAttendingRequired = id === NO_ATTENDING;
    const isDoctorAttendingPhys = attendingPhysicianId === userId;
    // if siteTimezone === null, format breaks.
    const siteTz = narrativeSiteTimezone || moment.tz.guess();
    const canCertify = this.setCanCertify(
      currentCertKeys.useFormPhys,
      userId,
      formPhys.id || formPhysId,
      formPhysRestricted,
    );
    const transferredAndVerified = !!(
      priorHospice === 'Transferred' && documentationVerified
    );
    const f2fDetails = this.getF2fDetails();
    const noNarrative =
      !this.state.narrative || /^\s*$/.test(this.state.narrative);
    const alreadyCertified = formPhysWrittenDate !== null;
    const certPhysName = this.certPhysName(alreadyCertified);
    const benStartDate = moment.utc(startDate);
    const benEndDate = moment.utc(expectedEndDate);
    const benPeriod =
      moment.duration(benEndDate.diff(benStartDate)).asDays() + 1;
    const attendingRequired =
      electionNumber === 1 &&
      attendingPhysWrittenDate === null &&
      !noAttendingRequired;
    const inDateRange = benStartDate.diff(moment(), 'days') < 15;
    let certificationDateTime;
    let takenBy;
    if (formPhysWrittenDate) {
      certificationDateTime = (
        <FormattedDate
          value={moment.utc(formPhysWrittenDate)}
          {...dateFormat}
        />
      );
    } else if (formNarrativeCreationDate) {
      const time = moment.utc(formNarrativeCreationDate).tz(siteTz);
      certificationDateTime = (
        <FormattedMessage
          {...messages.signedOn}
          values={{
            date: (
              <FormattedDate value={time} format="long" timeZone={siteTz} />
            ),
            time: <FormattedTime value={time} timeZone={siteTz} />,
          }}
        />
      );
    }
    if ((formTakenByFirstName || formTakenByLastName) && formPhysWrittenDate) {
      takenBy = formatMessage(messages.onDate, {
        msg: formatMessage(messages.recordedBy, {
          name: `${formTakenByFirstName} ${formTakenByLastName}`.trim(),
        }),
        date: formatDate(formPhysWrittenDate, dateFormat),
      });
    }

    let trailingMessage;
    if (alreadyCertified) {
      if (isDoctorAttendingPhys) {
        trailingMessage = formatMessage(messages.electronicallySigned, {
          phys: certPhysName,
          certificationDateTime,
        });
      } else {
        trailingMessage = formatNarrativeVal(formatMessage, takenBy);
      }
    } else {
      trailingMessage = null;
    }
    const headerGrids = {
      sm: 6,
      md: 4,
    };
    const HeaderTitle = () => (
      <Fragment>
        {this.headerTitle()} #{electionNumber}{' '}
        <span style={{ display: 'inline-block' }}>
          <FormattedMessage
            {...messages.headerDays}
            values={{ days: benPeriod }}
          />
        </span>
      </Fragment>
    );
    const body = this.setBody(
      f2fDetails.missing,
      noAttendingRequired,
      inDateRange,
    );
    const footerType = this.setFooter(
      isDoctor,
      alreadyCertified,
      f2fDetails.missing,
      transferredAndVerified,
      noAttendingRequired,
      formType, // ok
    );
    const hideFooter =
      printConfig && footerType !== 'print'
        ? false
        : footerType === 'default' ||
          (alreadyCertified && noNarrative) ||
          body === 'verbalOnly';
    // if (
    //   historicalView &&
    //   this.hideCert(alreadyCertified, body, noNarrative, f2fDetails.missing)
    // ) {
    //   if (typeof onHide === 'function') {
    //     onHide();
    //   }
    //   return null;
    // }

    return (
      <Fragment>
        <ExpansionPanel
          expanded={!expandable || this.state.expanded}
          onChange={this.handleExpand}
        >
          <ExpansionPanelSummary noCursor={!expandable}>
            <SectionHeader
              action={
                // TODO: Get this outta here
                <Fragment>
                  {expandable ? (
                    <ActionIcon
                      title={
                        this.state.expanded
                          ? this.props.intl.formatMessage({ id: 'Collapse' })
                          : this.props.intl.formatMessage({ id: 'Expand' })
                      }
                      Icon={
                        this.state.expanded
                          ? KeyboardArrowDown
                          : KeyboardArrowRight
                      }
                    />
                  ) : null}
                  {historicalView ? null : (
                    <CertNav
                      classes={classes}
                      disableBack={disableBack}
                      disableForward={disableForward}
                      formatMessage={this.props.intl.formatMessage}
                      onNavigateCert={onNavigateCert}
                    />
                  )}
                </Fragment>
              }
              title={<HeaderTitle />}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails bottom>
            <Grid
              ref={this.refCallback}
              container
              item
              className={classes.root}
              justify="center"
              spacing={1}
            >
              <CertSnackBar
                open={this.state.openSnackBar}
                message="Narrative Copied"
                closeHandler={this.closeSnackbar}
              />
              <CertHeader
                smallWidget={smallWidget}
                verbalTakenBy={
                  // eslint-disable-next-line no-nested-ternary
                  (formVerbalTakenByFirstName || formVerbalTakenByLastName) &&
                  formVerbalTakenByCredentials
                    ? `${formVerbalTakenByFirstName} ${formVerbalTakenByLastName}, ${formVerbalTakenByCredentials}`.trim()
                    : formVerbalTakenByFirstName || formVerbalTakenByLastName
                    ? `${formVerbalTakenByFirstName} ${formVerbalTakenByLastName}`.trim()
                    : null
                }
                {...{
                  classes,
                  headerGrids,
                  benStartDate,
                  benEndDate,
                  formPhysVerbalDate,
                  updates,
                }}
              />

              {/** should we have a CertFormPrint view?
               * CONS: means if we update the cert view needs to update the print
               * PRO: cleaner */}
              {printConfig && printConfig.view === Listing && (
                <Footer
                  key={`cert-footer-${printFormType}-${selectedCertIndex}`}
                  alreadyCertified={alreadyCertified}
                  certificationDateTime={certificationDateTime}
                  classes={classes}
                  certPhysName={certPhysName}
                  footerType={footerType}
                  formNarrativeId={formNarrativeId}
                  formType={printFormType}
                  hideFooter={hideFooter}
                  selectedCertIndex={selectedCertIndex}
                  updates={updates}
                  smallWidget={smallWidget}
                  trailingMessage={trailingMessage}
                  printConfig={printConfig}
                />
              )}

              {(!printConfig || (printConfig && printConfig.view === Card)) && (
                <CertBody
                  classes={classes}
                  Footer={
                    <Footer
                      key={`cert-footer-${formType}-${selectedCertIndex}`}
                      alreadyCertified={alreadyCertified}
                      certificationDateTime={certificationDateTime}
                      classes={classes}
                      certPhysName={certPhysName}
                      footerType={footerType}
                      formNarrativeId={formNarrativeId}
                      formType={printConfig ? printFormType : formType}
                      hideFooter={hideFooter}
                      selectedCertIndex={selectedCertIndex}
                      updates={updates}
                      smallWidget={smallWidget}
                      trailingMessage={trailingMessage}
                      printConfig={printConfig}
                    />
                  }
                  trailingMessage={trailingMessage}
                  embedded={embedded}
                  smallWidget={smallWidget}
                  f2fMissing={f2fDetails.missing}
                  dispatch={this.props.dispatch}
                  certPhysName={certPhysName}
                  certificationDateTime={certificationDateTime}
                  body={body}
                  handleCertify={this.handleCertify}
                  isDoctor={isDoctor}
                  canCertify={canCertify}
                  narrative={this.state.narrative}
                  formatMessage={this.props.intl.formatMessage}
                  errors={this.setErrors(f2fDetails.missing, inDateRange)}
                  alreadyCertified={alreadyCertified}
                  attendingRequired={attendingRequired}
                  formNarrativeId={formNarrativeId}
                  formPhysVerbalDate={formPhysVerbalDate}
                  formType={printConfig ? printFormType : formType}
                  transferredAndVerified={transferredAndVerified}
                  noNarrative={noNarrative}
                  selectedCertIndex={selectedCertIndex}
                  submitting={submitting}
                  takenBy={takenBy}
                  userId={userId}
                  attendingPhysicianId={attendingPhysicianId}
                  hospiceFormProps={{
                    pasteText: this.pasteText,
                    captureNarrative: this.captureNarrative,
                    copyText: this.copyText,
                    textCopy: this.state.textCopy,
                    certPhysName,
                    f2fDetails,
                    formPhys,
                    historicalView,
                    userId,
                    updates,
                  }}
                />
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    );
  }
}

CertForm.propTypes = {
  classes: PropTypes.object.isRequired,
  updates: PropTypes.array,
};

export default injectIntl(withStyles(styles)(withCertContext(CertForm)));
