/*
 * Patient Messages
 *
 * This contains all the text for the Patient component.
 */

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'phead.title',
    defaultMessage: 'Patient Information',
  },
  infoAsOf: {
    id: 'phead.infoAsOf',
    defaultMessage: 'Information below as of {date}',
  },
  dob: {
    id: 'phead.dob',
    defaultMessage: 'DOB',
  },
  ssn: {
    id: 'phead.ssn',
    defaultMessage: 'SSN',
  },
  dnr: {
    id: 'phead.dnr',
    defaultMessage: 'DNR',
  },
  gender: {
    id: 'phead.gender',
    defaultMessage: 'Gender',
  },
  phone: {
    id: 'phead.phone',
    defaultMessage: 'Phone',
  },
  diagnoses: {
    id: 'phead.diagnoses',
    defaultMessage: 'Diagnoses',
  },
  allergies: {
    id: 'phead.allergies',
    defaultMessage: 'Allergies',
  },
  referred: {
    id: 'phead.referred',
    defaultMessage: 'Referred',
  },
  admitted: {
    id: 'phead.admitted',
    defaultMessage: 'Admitted',
  },
  discharged: {
    id: 'phead.discharged',
    defaultMessage: 'Discharged',
  },
  reason: {
    id: 'phead.reason',
    defaultMessage: 'Reason',
  },
  county: {
    id: 'phead.county',
    defaultMessage: 'County',
  },
  loc: {
    id: 'phead.loc',
    defaultMessage: 'LOC',
  },
  payer: {
    id: 'phead.payer',
    defaultMessage: 'Payer',
  },
  modalTitle: {
    id: 'phead.modalTitle',
    defaultMessage: 'ClearScripts',
  },
  unknown: { id: 'Unknown', defaultMessage: 'Unknown' },
  male: { id: 'Male', defaultMessage: 'Male' },
  female: { id: 'Female', defaultMessage: 'Female' },
  phys: {
    id: 'phys',
    defaultMessage: 'Physician',
  },
  idgTeam: {
    id: 'IDG Team',
  },
  to: { id: 'to', defaultMessage: 'to' },
  asOf: { id: 'asOf', defaultMessage: 'as of' },
});

export default messages;