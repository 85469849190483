import styled from 'styled-components';
// import { Container as PreStyledContainer } from '../../components/layout/Container';
import PreStyledContainer from '../../components/layout/Container';

export const ContainerStyles = `
        
         position: fixed;
         display: block;
         width: 80%;
         height: 100%;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         overflow: scroll;
         align-items: center;
         padding-top: 10px;
         padding-bottom: 10px;
         background-color: #454545;


         #directionsHeader {
           display: none;
         }
         #map-canvas {
           display: none;
         }

         #directions-panel {
           display: none;
         }

         #directions-header {
           display: none;
         }

        .card-body div[ref="value"] {
            color: var(--mumms-text-secondary);
            margin-left: 1.25rem;
        }

        .card-body {
          font-size: 8pt;
        }

        .col-form-label {
          padding-top: 0pt;
          padding-bottom: 0pt;
        }

        .form-group {
          margin-bottom: 0pt;
        }

        .formio-collapse-icon {
          display: none;
        }

        .formio-collapse-icon + span {
          position: relative;
          left: -20px;
          line-hight: unset;
        }

         /** styles copied and modified from m2, didn't want to load all of the additional styles */
         .ag-react-container {
           flex: 1;
         }

         root td {
           padding: 0px;
         }

         .error-msg span {
           display: block;
           margin-bottom: 10px;
         }

         .ag-react-container > div > span {
           display: inline-flex;
         }


                  
          /** adding travis's css for med lsit on orders: */

          .med-header {
            margin-bottom: 5px;
          }

          .med-header th {
              text-align: start;
              color: #9edbf7;
              padding: 3px;
              font-size: 90%;
              border-bottom: 1px black dotted;
          }

          .med-list tr {
            line-height: 1.3em;
          }

          .med-name {
              font-size: 8pt;
              color: white;
              padding-left: 3px;
          }

          .med-addon {
              background-color: #857b3b;
              margin-left: 3px;
              padding: 0px 5px 0px 2px;
          }

          .med-info {
              color: #FAF39B;
              font-size: 8pt;
              padding-left: 3px;
          }

         & .PatientGrid {
           height: calc(100vh - 90px);
         }

         & .QuestionnaireGrid {
           height: calc(100vh - 90px);
         }

         & .questionnaireGrid {
           height: 50vh;
           min-height: 200px;
         }

         .ag-filter-header-container {
           height: 44px !important;
         }

         & root ul {
           padding-inline-start: 0;
         }
         & h3.manifest-summary {
           display: none;
         }
         & root #content {
           top: unset !important;
           bottom: unset !important;
         }

         & li.card {
           border: 3px solid var(--mumms-header) !important;
         }

         & .q-name {
           padding: 0 8px;
           position: relative;
           font-size: 1.3rem;
           font-weight: 500;
           line-height: 1.6;
           margin: 0;
         }
         & .cardContainer .listing .masonry li:not(.no-cards) {
           color: #ececec;
         }

         & .masonry .error {
           color: #ff7372;
         }

         & div.no-masonry {
           text-align: center;
         }

         & div.card-body.masonry {
           padding: 1.25rem;
           display: grid;
           grid-auto-rows: 14px;
           grid-row-gap: 4px;
           grid-column-gap: 4px;
           grid-template-columns: 1fr 1fr 1fr;
           /*  this was auto creating columns, wanted finer control */
           /*  grid-template-columns: repeat(auto-fill, minmax(400px,1fr)); */
         }

         /* masonry list item body */
         & li.masonry > div {
           display: inline-flex;
           flex-wrap: wrap;
           /* carefully arrived at by entering random values */
           margin-left: -0.62em;
           width: calc(100% - 20px);
         }

         & li.masonry > div > span.left {
           /* leave width for center-span */
           max-width: calc(100% - 15px);
           margin-right: 3px;
           padding-bottom: 3px;
           white-space: pre-line;
           flex: 0 1 auto;
         }

         & li.masonry > div > span.flex-center {
           flex: 1;
         }

         & li.masonry.masonry-questionnaire-section > div > span.left,
         & li.masonry.masonry-questionnaire-section > div > span.flex-center {
           margin-bottom: -3px;
         }

         & li.masonry > div > ul {
           width: 100%;
         }

         & li.masonry ul > li > div {
           width: calc(100% - 14px);
         }

         & li.masonry > div > span.left.form-name {
           font-weight: normal;
         }

         & li.masonry > div > span.flex-center:not(:empty) {
           max-width: fit-content;
         }

         & li.masonry > div > span.flex-center:empty {
           border-bottom: 1px solid rgba(0, 0, 0, 0.15);
           margin: 8px 6px 0;
           height: 0em;
         }

         & li.masonry > div > span.right {
           margin-left: auto;
         }

         & .masonry span.added:not(:empty),
         & .masonry span.right:not(:empty) {
           height: 1rem;
           line-height: 1;
           max-height: min-content;
           min-width: 100px;
           top: -1px;
           padding: 2px 3px;
           margin-bottom: 1px;
           margin-right: -3px !important;
           border-radius: 2px;
           border: 0.5px solid;
           border-left: 3px solid;
           border-right: 3px solid;
           font-family: 'Lucida Console', Monaco, monospace;
           font-size: 0.65rem;
           font-weight: normal;
           text-align: right;
         }

         & div.card-body.masonry .masonry span.added:not(.right) {
           white-space: nowrap;
           display: inherit;
           line-height: 1rem;
           width: fit-content;
           min-width: 0;
           border-color: transparent;
         }

         & .masonry span.right.created:not(:empty),
         & .masonry span.right[class*='created']:not(:empty) {
           border-color: #7eda67;
         }

         & .masonry span.right.edited,
         & .masonry span.right.added:not(:empty),
         & .masonry span.right[class*='added']:not(:empty),
         & .masonry span.right[class*='edited'] {
           border-color: #faf39b;
         }

         & .masonry span.right.last.recorded,
         & .masonry span.right.dced,
         & .masonry span.right[class*='dc'] {
           border-color: #e04d4c;
         }

         & div.card-body.masonry li span {
           padding-left: initial;
         }
         /* all li > spans should be displayed inline, to prevent weird breaks */
         & div.card-body.masonry section.masonry-section li span {
           display: inline;
           position: relative;
         }
         /* select only parent span to change spacing between bullet and text (unordered only) */
         & div.card-body.masonry section.masonry-section ul > li > span {
           margin-left: -6px;
         }

         & div.card-body.masonry section.masonry-section span.description {
           display: inline-table;
           white-space: pre-wrap;
           max-width: calc(100% - 75px);
         }

         & .masonry-response-children {
           margin-right: -1px;
         }

         & h3.masonry-header.dup {
           display: inline-block;
           width: 49%;
         }

         &
           div.card-body.masonry
           section.masonry-section.span-cols
           > ul
           > li
           > span {
           display: inline-grid;
           white-space: initial;
         }

         /* stylelint-disable selector-type-no-unknown */
         &
           div.card-body.masonry
           section.masonry-section.span-cols
           > ul
           > li:not(first-line) {
           white-space: nowrap;
         }
         /* stylelint-enable selector-type-no-unknown */

         & div.card-body.masonry > section ol {
           list-style: decimal inside;
           padding: 0px 5px;
         }

         /* enable commented out below for changing style of nested ordered lists */
         & div.card-body.masonry > section ol ol {
           padding: 0px 0px 0px 10px;
           /* list-style-type: lower-roman; */
         }

         /* for long sections that should span multiple columns. Adds bottom border for 2-col view */
         & section.masonry-section.span-cols {
           grid-row-start: 1;
           grid-column: 1 / 3;
           margin-bottom: -3px;
           border-bottom: 1px solid rgba(0, 0, 0, 0.15);
         }

         & section.masonry-section.span-cols > ul {
           /* column-count: 2; */
           grid-auto-rows: 14px;
           grid-row-gap: 0;
           display: grid;
           grid-template-columns: 1fr 1fr;
         }

         & div.card-body.masonry > section ul {
           list-style: disc inside;
           white-space: initial;
         }

         & div.card-body.masonry > section ul ul {
           padding-left: 10px;
           list-style-type: circle;
         }

         & div.card-body.masonry > section ul ul ul {
           list-style-type: square;
         }

         & div.card-body.masonry > section ul ul ul ul {
           list-style-type: circle;
         }

         & div.card-body.masonry > section ul.heading {
           list-style: none;
         }

         & li.masonry.masonry-questionnaire-section > div {
           margin-bottom: 6px;
         }

         & div.card-body.masonry > section ul.heading > li > div {
           width: calc(100% + 5px);
         }

         & div.card-body.masonry > section ul.heading > li > ul {
           padding: 0;
         }

         & div.card-body.masonry > section li.masonry.masonry {
           border: none;
           margin: 0 2px;
           font-size: 12px;
           width: 100%;
           background-color: transparent;
         }

         & div.card-body.masonry > section > ul > li.masonry.masonry {
           padding-left: 6px;
           width: calc(100% - 6px);
         }

         & section.masonry-section h3.masonry-header.dup {
           display: none;
         }

         /* Card section with fade background */
         & section.masonry-section h3.masonry-header.fade {
           font-size: 14px;
           margin-right: -1px;
           background-color: transparent;
           background-image: linear-gradient(
             to right,
             rgba(46, 46, 46, 1),
             rgba(46, 46, 46, 0.35)
           );
         }
         /* Card section with fade bottom border */
         & section.masonry-section h3.masonry-header.border {
           font-size: 14px;
           padding: 4px 4px 0px;
           background-color: transparent;
           border-bottom: solid 1px;
           border-image: linear-gradient(
               to right,
               rgba(46, 46, 46, 1),
               rgba(46, 46, 46, 0.35)
             )
             1 100%;
           border-image-slice: 1;
         }

         /* Card section with no background */
         & section.masonry-section h3.masonry-header {
           display: inline-flex;
           font-size: 14px;
           background-color: transparent;
         }

         /* Full width card */
         & section.masonry-section.full-width {
           grid-column: 1 / -1;
         }

         /* These media queries control the width of the list items, so the date boxes can remain in-line until it looks weird*/
         @media (min-width: 435px) and (max-width: 875px) {
           & li.masonry > div > span.left {
             max-width: 65%;
           }
         }

         @media (min-width: 950px) and (max-width: 1270px) {
           & li.masonry > div > span.left {
             max-width: 65%;
           }
         }

         @media (min-width: 1425px) {
           & li.masonry > div > span.left {
             max-width: 65%;
           }
         }
         /* Media queries for the rest of the grid */
         @media (max-width: 1270px) {
           & div.card-body.masonry {
             grid-template-columns: 1fr 1fr;
           }
         }

         @media (max-width: 875px) {
           & div.card-body.masonry {
             grid-template-columns: 1fr;
           }
         }

         @media (min-width: 875px) {
           & section.masonry-section.span-cols {
             grid-row-start: 1;
             grid-column: 1 / 3;
           }

           & section.masonry-section.span-cols > ul {
             column-count: 2;
           }

           &
             div.card-body.masonry
             section.masonry-section.span-cols
             > ul
             > li
             > span {
             display: inline-grid;
             white-space: initial;
           }
           /* stylelint-disable selector-type-no-unknown */
           &
             div.card-body.masonry
             section.masonry-section.span-cols
             > ul
             > li:not(first-line) {
             white-space: nowrap;
             /* stylelint-enable selector-type-no-unknown */
           }
           & section.masonry-section h3.masonry-header.dup {
             display: inline-block;
             width: 49%;
           }
         }

         @media (min-width: 1270px) {
           & section.masonry-section.span-cols {
             margin-bottom: -5px;
           }
         }

         div.question-descriptionx {
           margin: 6px 0px 6px 0px;
         }

         div.hide-question,
         div.hide-contact {
           margin-left: -15px;
           margin-top: 1px;
         }

         div.division-toggle {
           width: 11px;
           height: 11px;
           display: inline-block;
           margin: 1px 5px 1px 1px;
           background: url('images/dance-floor-icons/granite/tree-collapse.gif')
             no-repeat 0px 5px;
         }

         div.hide-question > div.division-toggle {
           margin: 0;
           position: relative;
           color: purple;
         }

         div.hide-contact > div.division-toggle {
           left: 4px;
           margin: 0;
           position: relative;
         }

         div.question-description-required-sticky {
           margin: 6px 0px 6px 0px;
         }

         div.question-description,
         div.question-description-required,
         div.question-description-sticky {
           display: inline-block;
           margin: 6px 0px 6px 0px;
         }

         div.asked-question {
           color: #ddd;
         }

         div.questionnaire-view div.asked-question {
           margin: 0px 0px 12px 0px;
         }

         div.division-edit > div.asked-questions > div.asked-question {
           padding-left: 25px;
         }

         div.further-questions {
           margin-left: 25px;
         }

         table.control-set {
           width: 100%;
           color: #ffffff;
         }

         /* begin questionnaire view */
         table.questionnaire-view,
         table.questionnaire-summary,
         table.division,
         div.division,
         div.division-last {
           width: 100%;
           width: -moz-calc(100% - 14px);
           width: calc(100% - 14px);
           margin-left: 5px;
           border: 1px solid #3a3a3a;
         }

         td.questionnaire-name,
         td.overview-questionnaire-name {
           padding: 5px 3px 5px 3px;
           font-weight: normal;
           font-size: 140%;
           width: 100%;
           color: black !important;
           background-color: #9edbf7;
         }
         root a {
           text-decoration: none;
         }

         root a:hover {
           text-decoration: underline;
         }
         a.questionnaire-name-button {
           display: none;
         }
         a.questionnaire-name-button,
         a.script-administer-button {
           vertical-align: middle;
           padding: 2px 3px 2px 3px;
           border-radius: 3px;
           text-align: center;
           font-size: 10px;
           border: 1px solid #9edbf7;
           color: #9edbf7;
         }
         td.questionnaire-name:hover,
         td.overview-questionnaire-name:hover {
           cursor: pointer;
         }

         a.questionnaire-name-button:hover,
         a.script-administer-button:hover {
           cursor: pointer;
         }

         td.questionnaire-name-button {
           vertical-align: middle;
           padding: 5px 3px 5px 3px;
           font-weight: normal;
           text-align: center;
           font-size: 10px;
           color: #9edbf7;
           background: #3a3a3a;
         }

         root table {
           table-layout: fixed;
         }

         div.questionnaire-details,
         td.questionnaire-details,
         table.questionnaire-details {
           width: 100%;
           background-color: #525252;
         }

         td.questionnaire-detail {
           padding: 1px 3px 1px 3px;
           font-size: 8pt;
           color: #faf39b;
         }

         td.questionnaire-q-and-a {
           padding: 1px 3px 1px 3px;
         }

         td.questionnaire-detail-title {
           font-size: 8pt;
           font-weight: normal;
           padding: 1px 3px 1px 3px;
           color: #ddd;
         }

         .division-name,
         .division-name-red {
           padding: 5px 0px 5px 2px;
           font-weight: normal;
           border-bottom: 1px solid #3a3a3a;
           color: #9edbf7;
           background-color: #3a3a3a;
         }

         .division-name-edit {
           margin: 5px 0px 5px 0px;
           /*font-family: Muli;*/
           font-weight: normal;
           padding: 5px;
           color: #9edbf7;
           background-color: #3a3a3a;
         }

         .division-name-problem {
           margin: 5px 0px 2px 0px;
           /*font-family: Muli;*/
           font-weight: normal;
           font-size: 90%;
           text-transform: uppercase;
           text-align: center;
           border-bottom: 1px solid #3a3a3a;
           color: #9edbf7;
         }

         div.division,
         div.division-last {
           padding: 0px 0px 0px 0px;
           border-left: 1px solid #3a3a3a;
           border-right: 1px solid #3a3a3a;
         }

         table.division {
           padding: 0px 1px 0px 1px;
         }

         td.division-problem {
           width: 33%;
           border-right: 1px solid #3a3a3a;
         }

         td.division-Related_To,
         td.division-Relacionado_a,
         td.division-Relacionado {
           padding-bottom: 3px;
           width: 50%;
           border-right: 1px solid #3a3a3a;
           border-left: 1px solid #3a3a3a;
           background-color: #525252;
         }

         td.division-Goals,
         td.division-Objetivos,
         td.division-Objetivos_Metas {
           width: 50%;
           border-top: 1px solid #3a3a3a;
           border-right: 1px solid #3a3a3a;
           border-left: 1px solid #3a3a3a;
           background-color: #525252;
         }

         td.division-Problems {
           padding-bottom: 3px;
           width: 50%;
           border-right: 1px solid #e0e0b1;
           border-left: 1px solid #e0e0b1;
         }

         td.division-assessment {
           width: 50%;
           padding-right: 5px;
           padding-left: 5px;
           border-right: 1px dotted black;
         }

         td.division-assessment-last {
           width: 50%;
           padding-right: 5px;
           padding-left: 5px;
         }

         table.asked-question-table {
           margin: 0px 0px 0px 0px;
           padding: 1px 1px 1px 1px;
         }

         table.further-questions-table {
           margin: 0px 0px 0px 10px;
           padding: 1px 1px 1px 1px;
           width: 95%;
         }

         td.question-description,
         td.question-description-fakey,
         td.multi-line-question-description,
         td.signature-line-question-description,
         td.date-line-question-description {
           /*font-family: Muli;*/
           padding: 2px 2px 2px 2px;
           font-size: 8pt;
           width: 30%;
           color: #ddd;
         }

         td.question-description-fakey {
           font-style: italic;
         }

         div.question-description-stacked {
           font-weight: normal;
           /*font-family: Muli;*/
           padding: 0px 2px 0px 2px;
           font-size: 8pt;
           color: #ddd;
         }

         div.responses-given-stacked {
           /*font-family: Muli;*/
           padding: 0px 2px 0px 2px;
           color: #faf39b;
         }

         td.responses-given,
         td.multi-line-responses-given,
         td.signature-line-responses-given,
         td.date-line-responses-given {
           /*font-family: Muli;*/
           padding: 2px 2px 2px 2px;
           color: #faf39b;
         }

         div.response-given-fakey {
           font-style: italic;
         }

         .signature-on-file,
         .control-add-ons {
           font-size: 7pt;
         }

         div.questionnaire-view div.division-name {
           margin-bottom: 12px;
         }

         /* end questionnaire view */

         /* begin pain/wound map */

         a.add-point:link,
         a.add-point:visited {
           display: block;
           padding: 4px 7px 4px 7px;
           text-decoration: none;
           border-bottom: 0px;
         }

         a.reset-point:link,
         a.reset-point:visited {
           display: block;
           padding: 4px 7px 4px 7px;
           text-decoration: none;
           border-bottom: 0px;
           border-left: 0px;
         }

         img.map,
         img.add-point {
           border: 0px;
         }

         span.point-label {
           position: relative;
           top: 1px;
         }

         div.required-point {
           display: block;
           padding: 4px 7px 4px 7px;
           color: #ff7777;
         }

         input.other,
         input.dc,
         input.unselect,
         input.other-sticky,
         input.dc-sticky,
         input.unselect-sticky {
           position: relative;
           top: 3px;
           margin-left: 10px;
           border: 0px;
           border-bottom: 1px solid black;
         }

         div.map {
           background: url('images/pain.gif');
           background-repeat: no-repeat;
           width: 599px;
           height: 244px;
           display: block;
           position: relative;
         }

         div.map-edit {
           background: url('images/pain.gif');
           width: 599px;
           height: 244px;
           display: block;
           position: relative;
         }

         span.pain-dot {
           background: url('images/pain_point_on.gif');
           background-repeat: no-repeat;
           width: 7px;
           height: 7px;
         }

         .pain-button {
           border-radius: 3px;
           padding: 3px;
           border: 1px solid #9edbf7;
         }

         ul.response-given {
           padding: 0px 0px 0px 0px;
           margin: 0px 0px 0px 14px;
           list-style-type: none;
           color: #faf39b;
         }

         li.response-given {
           padding: 0px 0px 0px 0px;
           margin: 0px 0px 0px 0px;
           white-space: pre-line;
           word-wrap: break-word;
           font-size: 8pt;
           color: #faf39b;
         }

         td.questionnaire-details > table {
           width: 100%;
           table-layout: auto;
         }

         table.further-questions-table ul.response-given {
           list-style-type: circle;
           color: #ddd;
         }

         /* end pain/wound map */

         /* begin questionnaire edit */

         .dc-button {
           cursor: pointer;
           vertical-align: middle;
           font-size: 6pt;
           border: 1px solid #9edbf7;
           color: #9edbf7;
         }

         input.control,
         input.control-sticky {
           vertical-align: top;
           color: #faf39b;
         }

         .inline-response,
         .inline-response-sticky {
           vertical-align: middle;
         }

         .disabled {
           color: grey;
         }

         input[type='text'] {
           border: 0px;
           border: 1px dotted #9e9e9e;
           background-color: #525252;
           color: #ffffff;
         }

         span.suggested-response-given,
         span.suggested-response-given-sticky {
           font-style: italic;
           vertical-align: middle;
         }

         /**
 * questionnaire view
 */

         /**
 * viewing the changes made during a visit
 */

         div.cancel {
           color: black !important;
         }

         div.unselect,
         div.unselect-by-parent,
         input.unselect {
           color: black;
           background-color: #857b3b;
         }

         div.unselect-sticky {
           display: none;
         }

         div.dc,
         div.dc-by-parent,
         input.dc {
           color: black;
           background-color: #857b3b;
         }

         span.action {
           font-size: 8pt;
           color: #ddd;
         }

         /* questionnaire history */
         /* remember: top right bottom left */

         .header-left {
           padding: 3px 3px 3px 3px;
           width: 99%;
         }

         .header-right {
           padding: 5px 3px 5px 3px;
           width: 100%;
           text-align: right;
         }

         .header-select-text {
           font-weight: bold;
           padding: 3px 3px 3px 3px;
         }

         .header-bold {
           font-size: 8pt;
           padding: 5px 16px 5px 3px;
           color: #ddd;
         }

         .header-from-to {
           font-size: 8pt;
           text-align: right;
           width: 60px;
           vertical-align: top;
           padding: 5px 3px 5px 3px;
           color: #ddd;
         }

         .header-visit-info {
           font-size: 8pt;
           padding: 5px 3px 5px 3px;
           color: #faf39b;
         }

         .from-to {
           text-align: right;
           padding-right: 2px;
           color: #faf39b;
         }

         .header-form {
           padding: 5px 3px 5px 3px;
           vertical-align: middle;
           color: #faf39b;
         }

         .select-box {
           font-size: 8pt;
         }

         .questionnaire-form {
           display: none;
         }

         td.questionnaire-details.questionnaire-header > table {
           margin: -1px -1px 0;
           table-layout: auto;
           width: -moz-calc(100% + 2px);
           width: calc(100% + 2px);
         }

         /**
  * style stuff for pac
  */

         /* remember: top right bottom left */

         @media screen {
           html {
             font-size: 12px;
             /*	overflow-y:				hidden;  */
           }

           body {
             border: 0px;
             margin: 0px;
             background: #fff;
             font-size: 110%;
             /*line-height:			1.8em; */
             width: 100%;
             text-align: left;
             color: #ffffff;
             background-color: #454545;
             overflow: visible;
             display: inline;
           }

           table#body {
             background: #454545;
           }

           div#top-icons {
             height: 35px;
             width: 100%;
             overflow: hidden;
             position: absolute;
             top: 0px;
             background-color: rgb(77, 77, 77);
             border-bottom: 5px solid #000000;
           }

           div.header-span {
             height: 100%;
             float: right;
             line-height: 35px;
             display: inline-block;
           }

           div#hummingbird-name {
             position: absolute;
             left: 0px;
             width: 15%;
             padding-left: 5px;
             font-size: 130%;
             color: #ffffff;
           }

           div#top-overview {
             height: 35px;
             width: 100%;
             overflow: hidden;
             position: absolute;
             top: 40px;
             padding-left: 5px;
             background-color: rgb(77, 77, 77);
             border-bottom: 5px solid #000000;
           }

           div#dancefloor {
             position: absolute;
             top: 80px;
             margin-bottom: 120px;
             height: -webkit-calc(100% - 200px); /* top 120px bottom 120px */
             height: -moz-calc(100% - 200px); /* top 120px bottom 120px */
             height: calc(100% - 200px);
             width: 100%;
             background-color: #454545;
           }

           .top-section {
             max-height: 32vh;
             overflow: initial;
             height: 32vh;
             border-bottom: 5px solid #000000;
           }

           .bottom-toolbar-icons {
             display: inline-block;
             float: left;
             height: 55px;
             width: 100%;
             position: absolute;
             border: none;
             overflow: hidden;
           }

           div#bottom-tasks {
             height: 35px;
             width: 100%;
             overflow: hidden;
             position: absolute;
             bottom: 75px;
             background-color: rgb(77, 77, 77);
             border-top: 5px solid #000000;
           }

           .bottom-toolbar-text {
             font-size: 10px;
             font-weight: normal;
             text-transform: uppercase;
             color: white;
             padding-left: 10px;
             padding-right: 10px;
             width: 20px;
           }

           div#bottom-icons {
             height: 75px;
             width: 100%;
             overflow: hidden;
             position: absolute;
             bottom: 0px;
             background-color: rgb(77, 77, 77);
           }

           .bottom-toolbar-icon {
             height: 40px;
             width: 40px;
             padding-left: 10px;
             padding-right: 10px;
             padding-top: 6px;
             display: inline-block;
           }

           div#bottom-icon-drawer {
             display: inline-block;
             white-space: nowrap;
             height: 75px;
             overflow: hidden;
             padding-bottom: 0px;
             box-shadow: 0pt 0pt 10px black inset;
             background-color: rgb(58, 58, 58);
           }

           div#logout-icon {
             width: 40px;
             height: 40px;
             padding-right: 5px;
           }

           .generic-button {
             display: inline-block;
             position: fixed;
             right: 5px;
             top: 48px;
           }

           .view-button {
             height: 15px;
             width: 30px;
           }

           input[type='submit'] {
             background-color: #454545;
             border: 1px solid #9edbf7;
             border-radius: 5px;
             color: #9edbf7;
             font-size: 9pt;
             height: 18px;
             width: 50px;
           }

           table.sidebar {
             background-color: #454545;
             border-top: 5px solid black;
             position: absolute;
             bottom: 0px;
           }

           a:visited,
           a:link {
             text-decoration: none;
             color: #9edbf7;
             font-weight: normal;
           }

           table.navigation td {
             text-decoration: none;
             padding: 2px 2px 0px 2px;
           }

           table.navigation a {
             font-weight: bold;
             text-decoration: none;
             color: #eeeeee;
           }

           table.navigation a:hover {
             text-decoration: underline;
           }

           /*td#flower-cell
{
    background:             #ffffff;
    background-image:       url(../images/mum_image_pac.jpg);
    height:                 52px;
    border-bottom: 1px solid black;
}*/

           td#task-title {
             background: #454545;
           }

           div#worker-name {
             color: #faf39b;
             position: absolute;
             right: 50px;
             text-transform: capitalize;
           }

           div#task-title-text {
             padding-top: 3px;
             color: #ffffff;
           }

           .currentpage-links {
             font-size: 13px;
             float: left;
             display: inline-block;
             text-transform: uppercase;
             padding: 5px 2px;
             color: #9edbf7;
           }

           .currentpage-dot {
             font-size: 7px;
             float: left;
             display: inline-block;
             line-height: 27px;
             padding: 0px;
             padding-top: 5px;
             color: #ddd;
           }

           td#side-bar {
             height: 70px;
             bottom: 0px;
             background-color: red;
           }

           td#side-bar,
           td#main-panel,
           td#main-cell {
             /*background-image:       url(../images/gradient.png);
    background-repeat:			repeat-x;*/
             background-color: #ffffff;
           }

           td#main-cell {
             padding: 10px 0px 0px 0px;
           }

           .details-nochange {
             padding-left: 5px;
             color: #ddd;
           }

           .details {
             padding-left: 5px;
             color: #ffffff;
           }

           .table-wrapper {
             padding-left: 1px;
             padding-right: 0;
             padding-top: 0;
           }

           div.chart_footer {
             display: none;
           }

           /* main */

           table {
             margin: 0px;
             border: 0px;
           }

           table.subtasks {
             width: auto;
             height: 35px;
           }

           table.his-preview td {
             padding: 2px 5px;
             border: 1px solid #3a3a3a;
           }

           span.his-problem {
             color: #ff7777;
           }

           div.visit-item {
             display: inline-table;
             margin-left: 5px;
             margin-top: 5px;
             margin-right: 5px;
             border: solid 1px #3a3a3a;
             background-color: #6a6a6a;
             color: #ddd;
             width: 80%;
           }

           tr.visit-item-head {
             background-color: #2e2e2e;
           }

           td.visit-item-head {
             padding: 4px 4px 2px 2px;
             width: 167px;
           }

           .visit-head {
             font-size: 110%;
             color: #9edbf7;
             padding-left: 5px;
           }

           td.visit-item-details {
             padding: 0px 0px 0px 0px;
             width: 510px;
           }

           tr.visit-item-detail {
             background-color: #6a6a6a;
           }

           td.visit-item-detail {
             vertical-align: middle;
             color: #ddd;
             font-weight: normal;
             padding-left: 3px;
             width: auto;
           }

           td.visit-item-stuff {
             border-top: 1px solid #3a3a3a;
           }

           span.delete-button {
             margin-left: 20px;
           }

           span.resolve-button {
             margin-left: 20px;
           }

           .head2 {
             font-size: 125%;
             color: #faf39b;
             padding-left: 5px;
           }

           .head-Chart {
             font-size: 125%;
             color: #faf39b;
             padding-left: 5px;
             float: left;
             padding-top: 2px;
             background-color: #2e2e2e;
             height: 25px;
           }

           .status-info {
             color: #ffffff;
           }

           div.archive-item {
             display: block;
             padding: 10px 10px 0px 10px;
             margin: 0.6em 0.6em 1.6em 0.6em;
           }

           .archive-head {
             display: block;
             font-weight: bold;
             font-size: 120%;
             padding: 6pt 0.3em 6pt 0.3em;
           }

           table.archive-commands-title {
             width: 100%;
             margin-top: 10px;
             color: #9edbf7;
             font-size: 12pt;
             text-transform: uppercase;
           }

           table.archive-commands {
             font-size: 10pt;
           }

           table.archive-commands > tbody {
             font-size: 10pt;
             color: #ddd;
           }

           td.archive-title {
             padding-top: 5px;
             padding-left: 5px;
           }

           td.archive-command-start {
             font-size: 9pt;
             width: 135px;
             padding-left: 5px;
             padding-top: 5px;
             padding-bottom: 5px;
           }

           td.archive-command-next {
             font-size: 9pt;
             text-align: left;
             width: 300px;
             color: #9edbf7;
             padding-left: 5px;
             vertical-align: middle;
           }

           td.archive-command-last {
             text-align: right;
             padding-left: 5px;
             font-size: 12px;
           }

           .archive-command-on {
             padding-bottom: 3px;
             margin-bottom: 3px;
           }

           /* location page archive commands */
           table.loc-archive-commands {
             margin: 0px 20px 0px 20px;
           }

           table.location-commands {
             float: right;
             margin-top: -16px;
           }

           td.loc-archive-command-start {
             padding: 0px 0px 0px 0px;
             font-weight: bold;
             font-size: 10pt;
           }

           td.loc-archive-command-next {
             padding: 0px 0px 0px 10px;
             font-weight: bold;
             font-size: 10pt;
             text-align: left;
           }

           td.loc-archive-command-last {
             padding: 0px 0px 0px 30px;
             font-weight: bold;
             font-size: 10pt;
             text-align: left;
           }

           .patient-visitor {
             margin-left: 10px;
             color: #faf39b;
           }

           .visit-date,
           .visit-time,
           .visit-type {
             width: 6.5em;
             padding: 0px 0px 4px 4px;
             margin: 0px 0.75em 0px 0px;
             font-size: 100%;
             border: 0px;
             text-align: left;
             vertical-align: middle;
             background-color: #6a6a6a;
             color: #faf39b;
           }

           .dt-change-button {
             display: none;
           }

           .dt-change-link {
             color: blue;
           }

           .visit-meds,
           .visit-questionnaires,
           .note {
             color: #ffffff;
           }

           .visit-med {
             position: relative;
             display: inline-block;
             padding: 3px;
             width: 550px;
           }

           .visit-quest {
             padding-left: 3px;
           }

           .visit-quest-buttons {
             float: right;
           }

           .copy {
             color: gray;
             margin: 0px 16px 0px 12px;
           }

           a.downlink {
             text-decoration: underline;
             font-weight: bold;
           }

           li {
             padding: 6px 0px 6px 0px;
           }

           /**
 * table
 */

           /**
 * listing
 */

           table.listing {
             border-collapse: collapse;
             border-spacing: 0px;
             border: 1px solid #3a3a3a;
           }

           table.questionnaire-summary tr,
           table.questionnaire-view tr {
             border-right: 1px solid #3a3a3a;
           }

           table.listing > tbody > tr:nth-child(odd) {
             background-color: #515151;
           }

           table.listing > tbody > tr:nth-child(even) {
             background-color: #6a6a6a;
           }

           table.his-preview {
             border-collapse: collapse;
             border-spacing: 0px;
             border: 1px solid #3a3a3a;
             margin-top: 5px;
             position: absolute;
             color: #ffffff;
             background-color: #2e2e2e;
           }

           table.his-preview > tbody > tr:nth-child(odd) {
             background-color: #515151;
             border: 1px solid #3a3a3a;
           }

           table.his-preview > tbody > tr:nth-child(even) {
             background-color: #6a6a6a;
             border: 1px solid #3a3a3a;
           }

           table.sortable a.sortheader {
             padding: 0px;
             display: block;
             text-decoration: none;
           }

           th.continue,
           th.end,
           th.start,
           thead#thead {
             background: #2e2e2e;
             color: #9edbf7;
             border-left: 1px solid #3a3a3a;
             padding: 0px 0.2em 0px 0.2em;
             text-align: center;
             font-size: 13px;
             font-weight: normal;
           }

           th.end {
             border-right: 1px solid #3a3a3a;
           }

           /*tbody#tbody
{
    overflow:               auto;
}

table.sortable td:first-child
{
    text-align:             center;
}*/

           table.listing td {
             text-align: left;
             border-left: 1px solid #3a3a3a;
             border-bottom: 1px solid #3a3a3a;
             padding: 0.3em 0.3em 0.3em 0.3em;
             color: #ffffff;
           }

           td.end {
             border-right: 1px solid #3a3a3a;
           }

           td.button {
             border: 0px;
             background-color: transparent;
             padding-right: 4px;
             text-align: right;
           }

           a.pac-button:hover {
             text-decoration: none;
           }

           .pac-button {
             background-color: #454545;
             border: 1px solid #9edbf7;
             border-radius: 5px;
             color: #9edbf7;
             text-decoration: none;
             min-width: 60px;
             text-align: center;
             padding: 1px 3px 1px 3px;
             margin: 0px 3px;
             display: inline-block;
             cursor: pointer;
           }

           div.visit-quest {
             padding-left: 3px;
             display: block;
             width: 100%;
           }

           div.visit-quest-title {
             max-width: 75%;
             float: left;
             display: inline-block;
             padding-top: 5px;
           }

           div.visit-quest-buttons {
             padding-right: 6px;
             display: inline-block;
             text-align: right;
             margin: 5px 0px;
           }

           #alert-zone {
             position: inherit;
             top: 20px;
             left: 50%;
             /*	margin-left: 			-200px; */
             z-index: 9999;
           }

           .alert-box {
             background: #2e2e2e;
             border: 1px solid #ddd;
             left: 50%;
             top: 50%;
             margin-left: -50px;
             margin-top: -50px;
             position: fixed;
             height: auto;
             width: auto;
           }

           .alert-box input {
             border: 1px solid #9edbf7;
             color: #9edbf7;
             background: transparent;
             border-radius: 2px;
             text-align: center;
             position: relative;
             bottom: 20%;
             height: 20px;
             font-weight: normal;
             font-size: 12px;
           }

           .alert-box input:hover {
             cursor: pointer;
           }

           .alert-message {
             display: block;
             height: auto;
             width: auto;
             padding-right: 5px;
             padding-left: 5px;
             font-weight: normal;
             font-size: 15px;
           }

           .alert-box-label {
             padding-left: 5px;
             padding-right: 5px;
             text-align: left;
             font-weight: normal;
             font-size: 15px;
           }

           .alert-box-visitid {
             color: red;
           }

           span.red {
             color: #f00;
           }

           div.head-Chart-heading {
             background-color: #2e2e2e;
             color: #faf39b;
             font-weight: normal;
             font-size: 125%;
             cursor: pointer;
             padding-left: 2px;
             padding-top: 5px;
           }

           div.chart-main-heading,
           div.chart-year-heading,
           div.chart-month-heading,
           div.chart-heading {
             background-color: #2e2e2e;
             color: #9edbf7;
             font-weight: normal;
             font-size: 125%;
             cursor: pointer;
             height: 25px;
             padding-top: 5px;
             margin-top: 1px;
           }

           div.chart-main-heading span {
             padding-top: 2px;
           }

           div.chart-main-heading span,
           div.chart-year-heading span,
           div.chart-month-heading span,
           div.chart-heading span {
             display: inline-block;
           }

           div.chart-main-heading {
             padding-left: 2px;
           }

           div.chart-year-heading {
             padding-left: 10px;
           }

           div.chart-month-heading {
             padding-left: 20px;
           }

           div.chart-heading {
             height: 20px;
             padding-top: 3px;
             padding-bottom: 2px;
             padding-left: 30px;
           }

           img.chart-img {
             height: 11px;
             width: 11px;
             padding-left: 5px;
           }

           .tree-icons {
             height: 20px;
             width: 20px;
           }

           table.chart-choose td {
             padding: 0px 6px;
           }

           input[type='radio'] {
             margin-left: 1px;
             margin-right: 5px;
             margin-bottom: 3px;
             margin-top: 2px;
           }

           .dateLabel {
             width: 13%;
             float: left;
             padding: 5px 0px;
           }

           .dateInput {
             width: 75px;
             float: left;
             padding: 5px 0px;
           }

           .datePicker {
             padding: 4px 0px;
           }

           .textInput {
             border: 1px dotted #ddd !important;
             margin-left: 5px;
             width: 70px;
           }

           .chart_button {
             background-color: transparent;
             border: 1px solid #9edbf7;
             border-radius: 3px;
             padding: 5px;
             font-size: 11px;
             color: #9edbf7;
             cursor: pointer;
             position: relative;
           }

           table.questionnaire-view,
           table.questionnaire-summary,
           div.division,
           div.division-last {
             margin-left: 8px;
           }

           table.questionnaire-view,
           table.questionnaire-summary {
             margin-top: 1px;
             width: calc(100% - 20px);
           }

           div.division,
           div.division-last {
             width: calc(100% - 22px);
             margin-top: 1px;
           }

           table.division {
             width: calc(100% - 20px);
             margin: 0px 0px 0px 8px;
             padding: 0px 1px 0px 1px;
           }

           div.division table.division {
             width: 100%;
             margin: 0px 0px 0px 0px;
             padding: 0px 1px 0px 1px;
           }

           ul.response-given {
             padding: 0px;
             margin: 0px;
           }

           td.division-Related_To,
           td.division-Relacionado_a,
           td.division-Relacionado {
             border-top: 1px solid #3a3a3a;
           }

           td.division-Interventions,
           td.division-Intervención,
           td.division-Plan_Establecido {
             border-top: 1px solid #3a3a3a;
           }

           td.question-description,
           td.question-description-fakey,
           td.multi-line-question-description,
           td.signature-line-question-description,
           td.date-line-question-description {
             font-size: 8pt;
           }

           li.response-given {
             font-size: 8pt;
           }

           div.question-description-stacked {
             font-size: 8pt;
           }

           td.print-header {
             display: none;
           }

           div.chart-anchors {
             padding-top: 1px;
           }
           /* color overrides from hbc */


           input[type='text'],
           table.questionnaire-details,
           div.questionnaire-details,
           td.questionnaire-details,
           td.division-Interventions,
           td.division-Intervención,
           td.division-Plan_Establecido {
             background-color: #454545;
           }
         }

         /***************************************************************************************
----------------------------------------------------------------------------------------
                                       Spinner
----------------------------------------------------------------------------------------
***************************************************************************************/

         .stop-scrolling {
           height: 100%;
           overflow: hidden;
         }

         .scene svg {
           z-index: 100;
           height: 300px;
           width: 300px;
           margin-left: -157px;
           margin-top: -150px;
           left: 50%;
           top: 50%;
           position: absolute;
         }

         .overlay {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           z-index: 10;
           background-color: rgba(0, 0, 0, 0.8);
         }

         div.chart_footer {
           display: none;
         }

         .error-msg {
           margin: 10px 10px 0;
         }

         .error-msg > p {
           max-width: 450px;
           margin: auto;
         }

         .error-msg img {
           vertical-align: bottom;
         }

         .error-msg button {
           display: block;
           margin: 0 auto;
         }

         /*     Cert App General Rules     */
         /*        KEEP THESE VAGUE        */
         /* class names are auto generated */
         #PhysCert {
           margin-top: 1px;
         }

         #cert-app h6 {
           margin-top: 2px;
         }

         .top-section *,
         .top-level *,
         .chart-main-heading *,
         .head-chart-heading *,
         .head-chart-heading {
           margin: 0px;
           padding: 0px;
           overflow-y: hidden;
           overflow-x: hidden;
         }

         /* remember: top right bottom left */

         div.question-description-required {
           color: #ff7777;
         }

         div.question-description-sticky,
         .response-sticky,
         input.other-sticky,
         input.dc-sticky,
         input.unselect-sticky,
         .inline-response-sticky,
         input.control-sticky,
         span.sticky {
           color: #b95ce0;
         }

         .unsticky {
           color: orange;
         }

         /* begin questionnaire view */
         table.questionnaire-view,
         table.questionnaire-summary {
           border: 1px solid #3a3a3a;
         }

         .division-name-red {
           color: #f7c444;
         }

         div.division-last {
           border-bottom: 1px solid #3a3a3a;
           background-color: #525252;
         }

         td.division-Interventions,
         td.division-Intervención,
         td.division-Plan_Establecido {
           border-right: 1px solid #3a3a3a;
           border-bottom: 1px solid #3a3a3a;
           background-color: #525252;
         }

         td.division-Patient_Information,
         td.division-Instrucciones {
           border-top: 1px solid #e0e0b1;
           border-left: 1px solid #e0e0b1;
           border-right: 1px solid #e0e0b1;
         }

         td.division-Activity,
         td.division-Actividad {
           border-top: 1px solid #e0e0b1;
           border-left: 1px solid #e0e0b1;
           border-right: 1px solid #e0e0b1;
         }

         /* end questionnaire view */

         /* begin pain/wound map */

         /* end pain/wound map */

         /* begin questionnaire edit */

         textarea {
           border: 1px dotted #9e9e9e;
           background-color: #525252;
           color: #ffffff;
         }

         textarea.response-sticky,
         textarea.response {
           margin-left: 1px;
           margin-top: 5px;
         }

         /**
 * questionnaire view
 */

         .notes-list { 
           color: #a3a3a3; 
           margin: 0px 1% 0 3%; 
           font-size: 8pt !important; 
          }

         /**
 * viewing the changes made during a visit
 */

         /* questionnaire history */
         /* remember: top right bottom left */

         div.division-toggle.expand {
           background: url('images/dance-floor-icons/granite/tree-expand.gif')
             no-repeat 0 0;
         }`;

export const Container = styled(PreStyledContainer)`
  ${ContainerStyles}
  & .masonry-header,
         & .heading {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
