import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldBasic } from '@mummssoftware/common-ui';
import { formatTemplate } from '@mummssoftware/utils/formatters';
import messages from './Messages';

export const FieldBasicTranslatedLabel = ({ data, message, ...rest }) => (
  <FieldBasic
    label={<FormattedMessage key={message.id} {...message} />}
    data={data}
    {...rest}
  />
);

export const DNRField = ({ classes, patient }) => (
  <FieldBasicTranslatedLabel
    classes={classes}
    message={messages.dnr}
    data={
      patient.dnr ? (
        <FormattedMessage
          key="dnr-data"
          id={patient.dnr}
          defaultMessage={patient.dnr}
        />
      ) : null
    }
  />
);

export const PhysicianField = ({ classes, patient }) => {
  const physName = formatTemplate`${patient.physicianName} ${
    patient.physicianCredentials || ''
  }`;
  return (
    <FieldBasicTranslatedLabel
      classes={classes}
      message={messages.phys}
      data={
        physName && physName.includes('No Attending') ? (
          <FormattedMessage
            key="phys-name"
            id={physName}
            defaultMessage={physName}
          />
        ) : (
          physName
        )
      }
    />
  );
};

export const PayerField = ({ classes, currentCert, gridProps }) => (
  <FieldBasicTranslatedLabel
    classes={classes}
    message={messages.payer}
    gridProps={gridProps}
    data={currentCert.carrierName}
  />
);

export const LOCField = ({ classes, currentCert, gridProps }) => (
  <FieldBasicTranslatedLabel
    classes={classes}
    message={messages.loc}
    gridProps={gridProps}
    data={currentCert.episode.loc}
  />
);
