/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
  HeightProvider,
  PrintFooter,
  PatientHeader,
} from '@mummssoftware/common-ui';
import { Pim } from '@mummssoftware/utils';
import NotFound from '../NotFound';
import DashboardComponent from '../Dashboard';
import { DashboardContext } from '../Context/DashboardContext';
import {
  Config,
  DashboardConfig,
  WidgetConfig,
  DashboardType,
} from '../Context/types';
import { patientDashboard } from '../Context/constants';

type BaseAppProps = {
  embedded?: boolean;
  currentUser: mumms.User;
  logout: () => void;
  keycloak: Keycloak.KeycloakInstance;
  patient?: mumms.patient;
  patientError?: {
    status: number;
    statusText?: string;
    patientNumber?: string;
  };
  pim: Pim;
  config: Config;
  dashboardType: DashboardType;
};
type AppProps = BaseAppProps & RouteComponentProps;

// avoid excessive scrolling height
const AppRoot = styled.div<{ showHeader?: boolean }>`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
  ${(props) => (props.showHeader ? `height: 100vh;` : `;`)}
  display: flex;
  @media print {
    visibility: hidden;
    overflow: visible;
  }
`;

const AppContent = styled.div<{
  height: number;
  showHeader?: boolean;
  isWidgetOpen?: boolean;
}>`
  margin-top: ${(props) => props.height}px;
  width: 100%;
  ${(props) => (props.showHeader ? `overflow: auto;` : `;`)}
  ${(props) => (props.isWidgetOpen ? `position: fixed;` : `;`)}
  ${(props) =>
    props.showHeader ? `height: calc(100vh - ${props.height}px);` : `;`}
  @media print {
    visibility: hidden;
    overflow: visible;
  }
`;

class App extends React.PureComponent<AppProps> {
  headerRef = createRef<HTMLElement>();

  render() {
    const {
      currentUser,
      keycloak,
      patient,
      pim,
      logout,
      config,
      patientError,
      dashboardType,
    } = this.props;
    const query = new URLSearchParams(this.props.location.search);
    // TODO const patientheader
    const showHeader = query.get('patientheader') === 'true';
    const dashboardConfig: DashboardConfig = config.widgets;

    return (
      <DashboardContext.Consumer>
        {({ widgetsOpen }) => {
          const isWidgetOpen = !!widgetsOpen.length;
          return (
            <AppRoot showHeader={showHeader}>
              {showHeader && patient && (
                <PatientHeader ref={this.headerRef} patient={patient} />
              )}

              <HeightProvider passedRef={this.headerRef}>
                {(height: number) => (
                  <AppContent
                    height={height}
                    showHeader={showHeader}
                    isWidgetOpen={isWidgetOpen}
                  >
                    <Switch>
                      {patientError && (
                        <Route
                          path="/"
                          render={(props) => <NotFound {...props} />}
                        />
                      )}
                      {/** PATIENT Dashboard or CLINICAL dashboard */}
                      {dashboardType === patientDashboard ? (
                        <Route
                          path="/"
                          render={(props) => (
                            <DashboardComponent
                              pim={pim}
                              keycloak={keycloak}
                              logout={logout}
                              currentUser={currentUser}
                              patient={patient}
                              config={
                                dashboardConfig.patientDashboard as WidgetConfig[]
                              }
                              topBarHeight={height}
                              {...props}
                            />
                          )}
                        />
                      ) : (
                        <Route
                          path="/"
                          render={(props) => (
                            <DashboardComponent
                              pim={pim}
                              keycloak={keycloak}
                              logout={logout}
                              currentUser={currentUser}
                              patient={patient}
                              config={
                                dashboardConfig.clinicalDashboard as WidgetConfig[]
                              }
                              topBarHeight={height}
                              {...props}
                            />
                          )}
                        />
                      )}
                    </Switch>
                  </AppContent>
                )}
              </HeightProvider>
              {patient && <PrintFooter patient={patient} />}
            </AppRoot>
          );
        }}
      </DashboardContext.Consumer>
    );
  }
}

export default withRouter(App);
