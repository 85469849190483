import moment from 'moment';
export function addTrailingPeriod(string, maxLength) {
  const stripped = string.trim();

  if (stripped.substring(stripped.length - 1) === '.' || typeof maxLength === 'number' && stripped.length > maxLength) {
    return stripped;
  }

  return `${stripped}.`;
}
/**
 *
 * @param {string} salutation
 * @param {string} first
 * @param {string} last
 * @param {string} cred
 */

export function formatPhysicianName(salutation, first, last, cred) {
  /* eslint-disable indent */
  return first && last ? formatTemplate`${salutation ? `${addTrailingPeriod(salutation, 4)} ` : ''}${first} ${last}${cred ? `, ${cred}` : ''}` : '';
  /* eslint-enable */
}
/**
 * Creates a multi-line address object for display
 */

export function formatAddressMultiLine({
  street1,
  street2,
  city,
  state,
  zip,
  zip4
}) {
  const addressLine3 = flattenTemplate`${city ? `${city},` : ''}
                                      ${state ? `${state}` : ''}
                                      ${zip || ''}${zip4 ? `-${zip4}` : ''}`;
  const truthCheck = !!(street1 || addressLine3.length);
  return {
    addressLine1: truthCheck ? street1 || '' : 'Address not on file',
    addressLine2: truthCheck ? street2 || '' : '',
    addressLine3
  };
}
/**
 * Formats a date into a normalized view
 */

export function formatDateMMDDYYYY(date) {
  return date ? moment(new Date(date)).format('MM-DD-YYYY') : '';
}
export function formatDateMMDDYY(date) {
  return date ? moment(new Date(date)).format('MM-DD-YY') : '';
}
export const formatPostDate = date => date ? `${moment.utc(date).format('YYYY-MM-DD HH:mm:ss')}` : '';
/**
 * Pim sends birthday in timestamps. Convert to UTC
 * @param date
 */

export function formatDOBFromPim(date) {
  return date ? moment(date).utc().format('MM-DD-YYYY') : '';
}
/**
 * Formats 10 digit US phone number for display, with optional intl code.
 */

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}
/**
 * This function is meant to be used as a "template tag" with variables. This allows for us to
 * use template strings with proper code indentation. This will flatten strings to one line.
 *
 * Omits falsey values.
 *
 * Example usage:q
 * @example
 * const adjective = 'raggedy';
 * const flattened = flattenTemplate`So here is us, on the
 *                   ${adjective} edge. Don't push
 *                   me, and I won't push you.`;
 * // So here is us, on the raggedy edge. Don't push me, and I won't push you.
 * @ignore Credit to https://muffinresearch.co.uk/removing-leading-whitespace-in-es6-template-strings/
 *
 * @param strings Array of separated template strings
 * @param values Array of values given to template string.
 *
 * @return Flattened string with white space stripped.
 */

export function flattenTemplate(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = '';
  values.forEach((val, i) => {
    output += (strings[i] !== undefined ? strings[i] : '') + (val !== undefined ? val : '');
  });
  output += strings[values.length]; // Split on newlines.

  const lines = output.split(/(?:\r\n|\n|\r)/); // Rip out the leading whitespace.

  return lines.map(line => line.replace(/^\s+/gm, '')).join(' ').trim();
}
/**
 * Modified from flattenTemplate, but leaves line returns in place. Good for cases such as
 * formatting an address for display.
 *
 * @param strings Array of separated template strings
 * @param  values Array of values given to template string.
 *
 * @return Template string with white space stripped from each line.
 *
 * @see flattenTemplate
 */

export function formatTemplate(strings, ...values) {
  let output = '';
  values.forEach((val, i) => {
    output += (strings[i] !== undefined ? strings[i] : '') + (val !== undefined ? val : '');
  });
  output += strings[values.length];
  return output.replace(/^(\s{2})+/gm, '').trim();
}