import React from 'react';
import { Grid } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { ActionIcon } from '@mummssoftware/common-ui';

import messages from '../CertMessages';
export const CertNav = ({
  disableBack,
  disableForward,
  onNavigateCert,
  formatMessage,
}) => (
  <Grid container justify="center" spacing={0}>
    <Grid item>
      <ActionIcon
        title={!disableBack ? formatMessage(messages.prevCert) : ''}
        Icon={ArrowBack}
        disabled={disableBack}
        onClick={() => {
          onNavigateCert(-1);
        }}
        data-testid="nav-back"
      />
    </Grid>
    <Grid item>
      <ActionIcon
        title={!disableForward ? formatMessage(messages.nextCert) : ''}
        Icon={ArrowForward}
        disabled={disableForward}
        onClick={() => {
          onNavigateCert(1);
        }}
        data-testid="nav-forward"
      />
    </Grid>
  </Grid>
);
