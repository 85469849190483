import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ActionIcon } from '@mummssoftware/common-ui';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import StopIcon from '@material-ui/icons/Stop';
import MedsApprovalButton from './MedsApprovalButton';
import { AppContext } from '../../App/AppContext';
import { MedContext } from '../../App/MedContext';
import { SET_MOBILE_EDITABLE } from '../../App/constants';

const StyledButton = styled.span`
  float: right;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const TopBarContent = ({
  handleExternalLink,
  handleApproveMeds,
  messages,
  smallWidget,
}) => {
  const { isDoctor, isMobile, patient } = useContext(AppContext);
  const { isMobileEditable, dispatch, medications } = useContext(MedContext);

  const { episodes } = patient;
  const isActivePatient =
    episodes && episodes[episodes.length - 1].dischargeDate === null;

  const needsApproval = medications.some(
    (medication) =>
      !medication.reconcileddate || medication.reconcileddate === '',
  );

  const handleMobileEdit = () => {
    dispatch({
      type: SET_MOBILE_EDITABLE,
      payload: !isMobileEditable,
    });
  };

  const editButtonMsg = isMobileEditable ? (
    <FormattedMessage {...messages.stopEditBTN} />
  ) : (
    <FormattedMessage {...messages.editBTN} />
  );

  // eslint-disable-next-line consistent-return
  const getTopBarContent = () => {
    if (!isActivePatient && !isMobile) {
      if (!isDoctor) {
        return null;
      }
      if (isDoctor && !needsApproval) {
        return null;
      }
      if (isDoctor && needsApproval) {
        return (
          <StyledButton>
            <MedsApprovalButton
              messages={messages}
              handleApproveMeds={handleApproveMeds}
            />
          </StyledButton>
        );
      }
    } else if (smallWidget) {
      return (
        <div>
          {/* Maintain Button if patient is ACTIVE */}
          {isActivePatient && (
            <StyledButton onClick={handleExternalLink}>
              <Button size="small" variant="outlined" color="primary">
                <FormattedMessage {...messages.maintainBTN} />
              </Button>
            </StyledButton>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {/* Mobile Edit Button */}
          {isMobile && (
            <ActionIcon
              title={editButtonMsg}
              style={{
                marginTop: 5,
              }}
              Icon={isMobileEditable ? StopIcon : EditIcon}
              onClick={handleMobileEdit}
            />
          )}

          {/* Maintain Button if patient is ACTIVE */}
          {isActivePatient && (
            <StyledButton onClick={handleExternalLink}>
              <Button size="small" variant="outlined" color="primary">
                <FormattedMessage {...messages.maintainBTN} />
              </Button>
            </StyledButton>
          )}

          {/* Approve Button */}
          {isDoctor && needsApproval && (
            <StyledButton>
              <MedsApprovalButton
                messages={messages}
                handleApproveMeds={handleApproveMeds}
              />
            </StyledButton>
          )}
        </div>
      );
    }
  };

  return getTopBarContent();
};

export default React.memo(TopBarContent);
