import React, { Fragment, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  ActionIcon,
  THEME,
  lightTheme,
  darkTheme,
  iceTheme,
  blueberryTheme,
} from '@mummssoftware/common-ui';
import FlareIcon from '@material-ui/icons/Flare';
import { SET_THEME_OVERLAY } from '../Context/constants';
import { Context } from '../../context';
import { DashboardContext } from '../Context/DashboardContext';

const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: { maxHeight: ITEM_HEIGHT * 4, width: 110 },
}));

export const ThemeMenu = () => {
  const classes = useStyles();
  const { dispatch } = useContext(DashboardContext);
  const { onDashboardThemeChange } = useContext(Context);
  const themeNames = [darkTheme, blueberryTheme, iceTheme, lightTheme];

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const setTheme = (theme: string) => () => {
    const storedTheme = localStorage.getItem(THEME);
    if (storedTheme !== theme) {
      // Communication to top window
      // reference the top window
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
      // const topWindow = window.top;

      window.top &&
        window.top.postMessage(theme, `${process.env.REACT_APP_PIM_BASEURL}`);

      // switch theme in local storage and update dashboard theme
      dispatch({
        type: SET_THEME_OVERLAY,
        payload: true,
      });
      // give time for the animated overlay to start
      setTimeout(function () {
        localStorage.setItem(THEME, theme);
        onDashboardThemeChange(theme);
      }, 500);
    }

    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ActionIcon
        Icon={FlareIcon}
        title="Themes"
        style={{
          marginTop: 11,
          marginLeft: 2,
          marginRight: 0,
          width: 20,
          height: 20,
        }}
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: { elevation8: classes.menuPaper },
        }}
      >
        {(themeNames as any).map((item: string) => (
          <MenuItem key={item} onClick={setTheme(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
export default ThemeMenu;
