import { FormioUtils } from '@mummssoftware/form';
import Webform from 'formiojs/Webform';
import produce from 'immer';

type FormComponent = import('formiojs/components').Components.form;
type meetingMetadata = import('./context').FormsContext['meetingMetadata'];

function isWebForm(component: FormComponent | Webform): component is Webform {
  return component instanceof Webform;
}

// TODO: Refactor to hook? possibly prove to be more stable, and less need for various verifications
export async function checkRefs(
  onCheckRefs: (portalEl?: Element | null, key?: string) => void,
  comp: FormComponent | Webform,
  key: string,
) {
  const portalName = `${key}-portal`;
  let portalComponent;
  if (isWebForm(comp)) {
    portalComponent = FormioUtils.getComponent(
      comp.components,
      portalName,
      true,
    );
  } else {
    await comp.subFormReady;
    if (comp.subForm && typeof comp.subForm.getComponent === 'function') {
      portalComponent = comp.subForm.getComponent(portalName);
    }
  }
  if (portalComponent) {
    await portalComponent.ready;
    const portalEl = portalComponent.refs.html;
    onCheckRefs(portalEl, portalName);
  }
}

export function updateMetadata(
  meetingMetadata: meetingMetadata,
  newMetadata: meetingMetadata,
) {
  let setNew = false;
  const arr = Object.entries(newMetadata);
  for (let i = 0; i < arr.length; i++) {
    const [key, val] = arr[i];
    if (
      !(key in meetingMetadata) ||
      meetingMetadata[key as keyof meetingMetadata] !== val
    ) {
      setNew = true;
      break;
    }
  }
  if (setNew) {
    return () =>
      produce((draft) => {
        arr.forEach(([key, val]) => {
          if (typeof val !== 'number') {
            (draft.context.meetingMetadata as meetingMetadata)[
              key as keyof meetingMetadata
            ] = val;
            // argh. Probably a better way to do this.
          } else if (key === 'idgMeetingId') {
            (draft.context
              .meetingMetadata as meetingMetadata).idgMeetingId = val;
          }
        });
      });
  }
  return null;
}
