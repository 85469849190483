/* eslint-disable indent */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StarsIcon from '@material-ui/icons/Stars';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { AppContext } from '../../App/AppContext';

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    backgroundColor: theme.palette.background.header,
    color: theme.palette.grid.headerFontColor,
    height: '2rem',
    fontWeight: 600,
  },
  history: {
    backgroundColor: theme.palette.background.header,
    height: '2rem',
  },
}));

const StyledTableHead = styled(TableHead)`
  && {
    font-size: 16px;
    padding: none;
  }
  ${(props) =>
    props['data-hide']
      ? `&& {
        visibility: collapse;
      }`
      : `;`}
`;

const StyledTableCell = styled(TableCell)`
  ${(props) =>
    props.smallwidget // react wants custom props to dom in lowercase
      ? `&& {
        padding: 0px 4px 0px 4px;
      }`
      : `&& {
    font-size: 16px;
    padding: 6px;
  }`}
`;

export const Headers = ({
  messages,
  order,
  orderBy,
  onRequestSort,
  hide,
  history,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort && onRequestSort(event, property);
  };
  const { smallWidget } = useContext(AppContext);
  const classes = useStyles();
  const coveredSymbol = <MonetizationOnIcon fontSize="small" />;
  const minStandardsMetSymbol = <StarsIcon fontSize="small" />;

  const headCells = smallWidget
    ? [
        {
          id: 'approval',
          numeric: false,
          disablePadding: false,
          label: 'approval',
          width: '3%',
          align: 'left',
          sort: false,
          show: false,
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'name',
          width: '40%',
          align: 'left',
          sort: true,
          show: true,
        },
        {
          id: 'start',
          numeric: false,
          disablePadding: false,
          label: 'start',
          width: '6%',
          align: 'left',
          show: true,
        },
      ]
    : [
        {
          id: 'approval',
          numeric: false,
          disablePadding: false,
          label: 'approval',
          width: '3%',
          align: 'left',
          sort: false,
          show: false,
        },
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'name',
          width: '25%',
          align: 'left',
          sort: true,
          show: true,
        },
        {
          id: 'sig',
          numeric: false,
          disablePadding: false,
          label: 'sig',
          width: '30%',
          align: 'left',
          sort: true,
          show: true,
        },
        {
          id: 'quantity',
          numeric: true,
          disablePadding: false,
          label: 'quantity',
          width: '2%',
          align: 'right',
          show: true,
        },
        {
          id: 'start',
          numeric: false,
          disablePadding: false,
          label: 'start',
          width: '6%',
          align: 'left',
          show: true,
        },
        {
          id: 'covered',
          numeric: false,
          disablePadding: false,
          label: coveredSymbol,
          width: '2%',
          symbol: true,
          tip: 'covered',
          align: 'center',
          sort: false,
          show: true,
        },
        {
          id: 'minStandardsMet',
          numeric: false,
          disablePadding: false,
          label: minStandardsMetSymbol,
          width: '2%',
          symbol: true,
          tip: 'minimumTip',
          align: 'center',
          sort: false,
          show: true,
        },
        {
          id: 'physician',
          numeric: false,
          disablePadding: false,
          label: 'physician',
          width: '18%%',
          align: 'left',
          sort: false,
          show: true,
        },
        {
          id: 'stop',
          numeric: false,
          disablePadding: false,
          label: 'stop',
          width: '6%',
          align: 'center',
          sort: false,
          show: true,
        },
        {
          id: 'print',
          numeric: false,
          disablePadding: false,
          label: 'print',
          width: '2%',
          align: 'left',
          sort: false,
          show: false,
        },
        {
          id: 'history',
          numeric: false,
          disablePadding: false,
          label: 'history',
          width: '2%',
          align: 'left',
          sort: false,
          show: false,
        },
      ];

  const TableLabel = (headCell) => {
    if (headCell.show === false) {
      return null;
    }
    if (headCell.symbol) {
      return (
        <Tooltip title={<FormattedMessage {...messages[headCell.tip]} />}>
          {headCell.label}
        </Tooltip>
      );
    }
    if (headCell.sort === false) {
      return headCell.label.charAt(0).toUpperCase() + headCell.label.slice(1);
    }
    return order ? (
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        <FormattedMessage {...messages[headCell.label]} />
      </TableSortLabel>
    ) : (
      <TableCell>
        <FormattedMessage {...messages[headCell.label]} />
      </TableCell>
    );
  };

  const className = history ? classes.history : classes.stickyHeader;

  return (
    <StyledTableHead className={className} hide={hide.toString()}>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            className={className}
            key={headCell.id}
            align={headCell.align}
            // padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            smallwidget={
              smallWidget && smallWidget.toString()
                ? smallWidget.toString()
                : undefined
            }
          >
            {TableLabel(headCell)}
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

Headers.propTypes = {
  // classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};
