import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import produce from 'immer';
import { filterMedications, sortMedications } from '../utils/utils';
import {
  FILTER_MEDS,
  RESET_FILTERS,
  SET_SORT,
  UPDATE_MED,
  UPDATE_MEDS,
  ONLY_NEED_APPROVAL,
  SET_ERROR_MODAL,
  SET_MOBILE_EDITABLE,
  SET_DIMENSIONS,
} from './constants';

const initSortState = {
  name: { sort: true, asec: true },
  sig: { sort: false, asec: false },
  covered: { sort: false, asec: false },
  quantity: { sort: false, asec: false },
  start: { sort: false, asec: false },
  stop: { sort: false, asec: false },
  approved: { sort: false, asec: false },
};
const initialContext = {
  medications: [],
  errorModalOpen: false,
  isMobileEditable: false,
  dimensions: null,
  ...initSortState,
};

export const MedContext = createContext(initialContext);
export const MedContextConsumer = MedContext.Consumer;
export const MedDispatchContext = createContext();
export const MedDispatchContextConsumer = MedDispatchContext.Consumer;

const { hasOwnProperty } = Object;

const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) => hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key],
  );

const createInitialState = (
  clearScripts,
  handleMedChange,
  toSearch,
  from,
  type,
  to,
) => ({
  clearScripts,
  handleMedChange,
  toSearch,
  type,
  from,
  to,
  sortState: initSortState,
  originalMeds: {},
  displayMeds: [],
  needsApprovalMeds: null,
  onlyNeedApproval: false,
  errorModalOpen: false,
  isMobileEditable: false,
  dimensions: null,
});

const handleFilter = (draft, action) => {
  // Update one of from, to, toSearch, or type
  if (action?.payload && typeof action.payload.updateParam === 'string') {
    draft[action.payload.updateParam] = action.payload.paramVal;
  }
  draft.displayMeds = filterMedications(Object.values(draft.originalMeds), {
    from: draft.from,
    to: draft.to,
    toSearch: draft.toSearch,
    type: draft.type,
    sortParams: draft.sortState,
  });
  if (draft.onlyNeedApproval) {
    draft.displayMeds = draft.displayMeds.filter((med) =>
      draft.needsApprovalMeds.includes(med.id),
    );
  }
};

const MedReducer = (draft, action) => {
  switch (action.type) {
    case UPDATE_MED:
      {
        const { med, key, value } = action.payload;
        const index = draft.displayMeds.findIndex(({ id }) => id === med.id);
        if (!!value && typeof value === 'object') {
          Object.entries(value).forEach(([k, v]) => {
            draft.originalMeds[med.id][k] = v;
          });
        } else {
          draft.originalMeds[med.id][key] = value;
        }
        draft.handleMedChange(draft.originalMeds[med.id]);
        if (index > -1) {
          draft.displayMeds[index] = draft.originalMeds[med.id];
        }
      }
      // }
      break;
    case UPDATE_MEDS:
      if (Array.isArray(action.payload)) {
        action.payload.forEach((med) => {
          if (
            !draft.originalMeds[med.id] ||
            !shallowCompare(draft.originalMeds[med.id], med)
          ) {
            draft.originalMeds[med.id] = med;
          }
          if (!draft.needsApprovalMeds && action.payload.length) {
            draft.needsApprovalMeds = action.payload
              .filter((m) => !m.reconcileddate || m.reconcileddate === '')
              .map((m) => m.id);
          }
        });
      }
      handleFilter(draft, action);
      break;
    case SET_SORT:
      draft.sortState = action.payload;
      draft.displayMeds = sortMedications(draft.displayMeds, draft.sortState);
      break;
    case FILTER_MEDS:
      handleFilter(draft, action);
      break;
    case RESET_FILTERS:
      draft.from = '';
      draft.to = '';
      draft.toSearch = '';
      draft.type = 'active';
      handleFilter(draft);
      break;
    case ONLY_NEED_APPROVAL:
      draft.onlyNeedApproval = action.payload;
      break;
    case SET_ERROR_MODAL:
      draft.errorModalOpen = action.payload;
      break;
    case SET_MOBILE_EDITABLE:
      draft.isMobileEditable = action.payload;
      break;
    case SET_DIMENSIONS:
      draft.dimensions = action.payload;
      break;
    default:
      handleFilter(draft);
      break;
  }
};
const curriedMedReducer = produce(MedReducer);

export const MedProvider = ({
  children,
  clearScripts,
  medications,
  handleMedChange,
  toSearch,
  from,
  to,
}) => {
  const [medState, dispatch] = useReducer(
    curriedMedReducer,
    createInitialState(clearScripts, handleMedChange, toSearch, from, to),
  );

  useEffect(() => {
    dispatch({ type: UPDATE_MEDS, payload: medications });
  }, [medications]);

  const context = useMemo(
    () => ({
      toSearch: medState.toSearch,
      from: medState.from,
      to: medState.to,
      medications: medState.displayMeds,
      errorModalOpen: medState.errorModalOpen,
      isMobileEditable: medState.isMobileEditable,
      dimensions: medState.dimensions,
      dispatch,
      ...medState.sortState,
    }),
    [
      medState.displayMeds,
      medState.sortState,
      medState.to,
      medState.from,
      medState.toSearch,
      medState.errorModalOpen,
      medState.isMobileEditable,
      medState.dimensions,
    ],
  );

  return (
    <MedDispatchContext.Provider value={dispatch}>
      <MedContext.Provider value={context}>{children}</MedContext.Provider>
    </MedDispatchContext.Provider>
  );
};

export function withMedContext(Component) {
  return function contextComponent(props) {
    return (
      <MedDispatchContextConsumer>
        {(dispatch) => (
          <MedContextConsumer>
            {(context) => (
              <Component {...props} {...context} dispatch={dispatch} />
            )}
          </MedContextConsumer>
        )}
      </MedDispatchContextConsumer>
    );
  };
}
