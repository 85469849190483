import React, { useCallback, useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { SuggestBox } from '@mummssoftware/common-ui';
import { AppContext } from '../../App/AppContext';
import { CertContext, CertDispatchContext } from '../../../Context';
import {
  SET_DOCTOR,
  SET_DATE,
  SET_VERBAL_DATE_LIMIT,
  SET_LATE_CERT,
} from '../../../constants';

const AbsSuggestBox = React.memo(styled(SuggestBox)`
  && {
    ${(props) => (props['data-absolute'] ? `position: absolute;` : ``)};
    z-index: 101;
    width: inherit;
  }

  &&.suggest-box--is-disabled .suggest-box__indicators {
    display: none;
  }

  && p:not(.MuiTypography-colorTextSecondary) {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 500;
  }
  && .MuiInput-underline:before {
    border-color: ${(props) => props.theme.shape.borderRadius}px;
  }
  @media print {
    && p:not(.MuiTypography-colorTextSecondary) {
      font-size: 1em;
      line-height: 1em;
      color: initial;
    }
    && p.MuiTypography-colorTextSecondary {
      display: none;
    }
    && .MuiInput-underline:before {
      border: none;
    }
    && .MuiSvgIcon-root {
      display: none;
    }
  }
`);

const StyledFlatpickr = React.memo(styled(Flatpickr)`
  width: 100%;
  ${(props) => (props['data-height'] ? `height: ${props['data-height']};` : '')}
  appearance: none;
  z-index: 100;
  position: ${(props) => (props['data-absolute'] ? 'absolute' : 'relative')};
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.palette.action.disabled};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 500;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1rem;
  &&:focus,
  &&:active {
    outline: none;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
  &&:hover {
    border-color: ${(props) => props.theme.palette.action.active};
  }
  @media print {
    font-size: 1em;
    color: inherit;
    border: none;
  }
`);

/** ()fixing all messed up syntax highlighting */

/**
 * @typedef DateSelectorProps
 *  @prop {boolean} [absolutePhysPicker]
 *  @prop {number} [datePickerHeight]
 *  @prop {boolean} [hide]
 *  @prop {Date} selectedDate
 *  @prop {string|Date} certStartDate
 *
 * @typedef {React.SFC<DateSelectorProps>} DateSelector
 */

/**
 * @type {DateSelector}
 */
export const DateSelector = React.memo(
  ({
    absolutePhysPicker,
    datePickerHeight,
    hide,
    selectedDate,
    certStartDate,
    verbalDate,
  }) => {

    const { dispatch } = useContext(CertDispatchContext);
    dispatch({
      type: SET_DATE,
      payload: null,
    });
    const [verbalDateLimit] = useState(
      moment(certStartDate).subtract(15, 'days'),
    );
    // verbal date <= 2 days after the election period start date
    const timelyVerbal =
      moment(verbalDate).diff(moment(certStartDate), 'days') <= 2;

    const [defaultDate] = useState(
      verbalDateLimit.isAfter(selectedDate)
        ? verbalDateLimit.format('YYYY-MM-DD')
        : moment(selectedDate).format('YYYY-MM-DD'),
    );
    const [maxDate] = useState(new Date());
    const handleDateChange = useCallback(
      (date) => {
        dispatch({
          type: SET_VERBAL_DATE_LIMIT,
          payload: null,
        });
        dispatch({
          type: SET_LATE_CERT,
          payload: false,
        });
        // cert date entered is 15 days prior to start of cert
        const diff = moment(certStartDate).diff(moment(date[0]), 'days') > 15;
        // date entered is 2 days after start of cert && no or late verbal date
        const lateCert =
          moment(date[0]).diff(moment(certStartDate), 'days') > 2 &&
          !timelyVerbal;
        if (diff) {
          dispatch({
            type: SET_VERBAL_DATE_LIMIT,
            payload: verbalDateLimit,
          });
        }
        if (lateCert) {
          dispatch({
            type: SET_LATE_CERT,
            payload: lateCert,
          });
        }
        if (selectedDate !== date[0]) {
          dispatch({
            type: SET_DATE,
            payload: date[0],
          });
        }
      },
      [dispatch, certStartDate, verbalDateLimit, selectedDate, timelyVerbal],
    );
    if (hide) {
      return null;
    }
    return (
      <StyledFlatpickr
        data-absolute={absolutePhysPicker}
        data-height={datePickerHeight}
        value={
          verbalDateLimit.isBefore(selectedDate) ? selectedDate : defaultDate
        }
        onChange={handleDateChange}
        options={{ minDate: verbalDateLimit.toDate(), maxDate }}
      />
    );
  },
);

/**
 * @typedef PhysSelectorProps
 *  @prop {string} [id]
 *  @prop {Record<string, any>[]} doctors
 *  @prop {boolean} [disabled]
 *  @prop {boolean} [absolute]
 *  @prop {any} [labelRef]
 *  @prop {any} [selectedDoctor]
 *
 * @typedef {React.SFC<PhysSelectorProps>} PhysSelector
 */

/**
 * @type {PhysSelector}
 */
export const PhysSelector = React.memo(
  ({ id, absolute, disabled, doctors, hide, labelRef, selectedDoctor }) => {
    /**
     *  TODO: This logic should be in SuggestBox itself; something isn't working with their
     *  TODO:   'auto' prop. Possibly related to the custom components
     */
    const [distanceFromBottom, setDistanceFromBottom] = useState(301);
    const [menuPlacement, setMenuPlacement] = useState('bottom');
    const { dispatch } = useContext(CertDispatchContext);
    const handleChange = useCallback(
      (doctor) => {
        dispatch({ type: SET_DOCTOR, payload: doctor });
      },
      [dispatch],
    );
    useEffect(() => {
      let mounted = true;
      if (mounted && labelRef && labelRef.current instanceof Element) {
        const { bottom } = labelRef.current.getBoundingClientRect();
        setDistanceFromBottom(window.innerHeight - bottom);
      }
      return () => {
        mounted = false;
      };
    }, [labelRef, setDistanceFromBottom]);
    useEffect(() => {
      const newPlacement = distanceFromBottom < 300 ? 'top' : 'bottom';
      if (newPlacement !== menuPlacement) {
        setMenuPlacement(newPlacement);
      }
    }, [menuPlacement, distanceFromBottom, setMenuPlacement]);
    if (hide) {
      return null;
    }
    return (
      <AbsSuggestBox
        inputId={id}
        data-absolute={absolute}
        disabled={disabled}
        handleChange={handleChange}
        menuPlacement={menuPlacement}
        menuPosition="fixed"
        value={selectedDoctor}
        options={doctors}
      />
    );
  },
);

export const withSelectors = (WrappedComponent) => ({
  absolutePhysPicker,
  alreadyCertified,
  datePickerHeight,
  physSelectorId,
  physContainerRef,
  ...props
}) => {
  const {
    doctors,
    formType,
    pickerDisabled,
    selectedDoctor,
    certStartDate,
    verbalDateLimit,
    lateCert,
    dimensions,
    embedded,
    verbalDate,
  } = useContext(CertContext);
  // keep isDoctor here in case the logic changes
  const { isDoctor } = useContext(AppContext);
  const [hideSelectors, setHideSelectors] = useState(alreadyCertified);
  useEffect(() => {
    const shouldHide = alreadyCertified;
    if (shouldHide !== hideSelectors) {
      setHideSelectors(shouldHide);
    }
  }, [hideSelectors, isDoctor, alreadyCertified]);
  return (
    <WrappedComponent
      {...props}
      physContainerRef={physContainerRef}
      dimensions={dimensions}
      embedded={embedded}
      selectedDoctor={selectedDoctor}
      verbalDateLimit={verbalDateLimit}
      lateCert={lateCert}
      PhysPicker={
        <PhysSelector
          key="selectors-phys-selector"
          hide={hideSelectors}
          id={physSelectorId}
          labelRef={physContainerRef}
          absolute={absolutePhysPicker}
          disabled={pickerDisabled}
          selectedDoctor={selectedDoctor}
          doctors={doctors[formType]}
        />
      }
      DatePicker={
        <DateSelector
          key="selectors-date-selector"
          hide={hideSelectors}
          absolutePhysPicker={absolutePhysPicker}
          datePickerHeight={datePickerHeight}
          certStartDate={certStartDate}
          verbalDate={verbalDate}
        />
      }
    />
  );
};
