import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Watermark,
  WidgetHeader,
  NoRecordsDiv,
} from '@mummssoftware/common-ui';
import { ScheduledVisitTable } from '../../components/sections/ScheduledVisitsTable';
import { ScheduledVisitContext } from '../../context';
import defaults from '../../translations/en.json';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

const Container = styled.div`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
`;

// passing "smallWidget" as a prop to the container
const ScheduledVisitBody = styled.div<{
  smallWidget: boolean | undefined;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `min-height: ${props.theme.props.dashboard.smallWidgetHeight}px;
    height: ${props.theme.props.dashboard.smallWidgetHeight}px;`
      : `height: ${props.theme.props.dashboard.fullSizeHeight}px;`}
  background-color: ${(props) => props.theme.palette.background.paper};
  width: 100%;
  overflow: auto;
  position: relative;
`;

type ScheduledVisitProps = {
  currentUser: mumms.User;
  patient: mumms.patient;
  view?: string | null;
  embedded?: boolean;
  appTitle?: string;
  online: boolean;
  /* DASHBOARD- smallWidget mode (NEEDED bc when dashboard in XS mode problem with width calc */
  onClick?: () => void;
  smallWidget?: boolean;
} & typeof RouteProps;

export const ScheduledVisitComponent = ({
  location: { search },
  view,
}: ScheduledVisitProps) => {
  const {
    scheduledVisits,
    appTitle,
    smallWidget,
    agency,
    currentUser,
    patient,
    watermark,
  } = useContext(ScheduledVisitContext);

  /** OP-27973  Send patient to scheduler as forced filter to decrease load times */
  const patientNumber = patient ? `patient=${patient.patientNumber}` : '';
  const agencyIfAdmin = currentUser.isMumms ? `agency=${agency}` : '';
  const hasParams = patientNumber || agencyIfAdmin ? '?' : '';
  const hasMultipleParams = patientNumber && agencyIfAdmin ? '&' : '';

  const link = `${
    process.env.REACT_APP_PIM_BASEURL as string
  }/scheduler/${hasParams}${patientNumber}${hasMultipleParams}${agencyIfAdmin}`;

  return (
    <Container>
      <WidgetHeader
        appTitle={appTitle || 'Scheduled Visits'}
        versionInfo={`Scheduled Visits v${process.env.REACT_APP_SCHEDULED_VISITS_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
        // App does not expand for now
        // onClick={onClick}
        smallWidget={smallWidget}
        link={link}
        // opens link in new window
        target="_blank"
      />
      <ScheduledVisitBody smallWidget={smallWidget}>
        {watermark && watermark.show ? (
          <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
        ) : null}
        {scheduledVisits.length ? (
          <ScheduledVisitTable
            smallWidget={smallWidget}
            scheduledVisits={scheduledVisits}
          />
        ) : (
          <NoRecordsDiv>
            <p>{defaults.noVisits}</p>
          </NoRecordsDiv>
        )}
      </ScheduledVisitBody>
    </Container>
  );
};

export default ScheduledVisitComponent;
