export const kcConfig = {
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
  'ssl-required': 'external',
  resource: 'jx-preview',
  'public-client': true,
  'confidential-port': 0,
  clientId: 'jx-preview',
};

export default kcConfig;
