import merge from 'lodash.merge';
import common from './common';
import blueberry from './blueberry';
import ice from './ice';
import dark from './dark';
import light from './light';
export * from './common';
export default {
  default: common,
  blueberry: merge({}, common, blueberry),
  ice: merge({}, common, ice),
  dark: merge({}, common, dark),
  light: merge({}, common, light)
};