import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createMuiTheme // getContrastRatio,
, darken // emphasize,
, fade, lighten } from '@material-ui/core/styles';
export function createExtendedTheme(baseTheme) {
  // global style change, options at https://material-ui.com/customization/theming/#theme-configuration-variables
  // we use ThemeProvider in all of our apps
  const theme = createMuiTheme(baseTheme);
  theme.palette.primary = _objectSpread(_objectSpread({}, theme.palette.primary), {}, {
    hoverBackgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    darkContrastText: theme.palette.getContrastText(theme.palette.primary.dark),
    paperContrast: theme.palette.primary.main // TODO: Re-enable the below if we allow custom colors
    //   getContrastRatio(
    //     theme.palette.primary.main,
    //     theme.palette.background.paper,
    //   ) > 4.5
    //     ? theme.palette.primary.main
    //     : emphasize(theme.palette.primary.main, 0.5),

  }); // augment the primary chart color

  theme.palette.chart = theme.palette.augmentColor(theme.palette.chart);
  theme.palette.text = _objectSpread(_objectSpread({}, theme.palette.text), {}, {
    contrastPrimaryPaper: theme.palette.getContrastText(theme.palette.primary.paperContrast)
  });
  theme.palette.tableBorder = theme.palette.type === 'light' ? lighten(fade(theme.palette.divider, 1), 0.88) : darken(fade(theme.palette.divider, 1), 0.8);
  document.documentElement.style.setProperty('--invert', theme.palette.type === 'dark' ? '100%' : '0%');
  return theme;
}
const blackList = ['global', 'mixins', 'overrides', 'props', 'shadows', 'typography'];
/**
 * Maps a theme object to snake cased CSS variables.
 *
 * @param theme 'theme' object
 * @param consecutive false on the first run, true on subsequent.
 *
 * @returns {object} Formatted as: --key-name: property
 */

export const mapToCSSVariables = (theme, consecutive) => Object.entries(theme).reduce((seed, [key, val]) => {
  if (blackList.includes(key)) {
    return seed;
  }

  const newKey = (consecutive ? `-${key}` : `--${key}`).replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);

  if (typeof val === 'function') {
    return seed;
  }

  if (Array.isArray(val)) {
    val.forEach((innerVal, i) => {
      seed[`${newKey}-${i}`] = innerVal;
    });
  }

  if (typeof val === 'object') {
    Object.entries(mapToCSSVariables(val, true)).forEach(([innerKey, innerVal]) => {
      seed[`${newKey}${innerKey}`] = innerVal;
    });
  }

  if (typeof val === 'string' || typeof val === 'number') {
    seed[newKey] = val.toString();
  }

  return seed;
}, {});
/**
 * Takes an object of css variables and sets them to the document style for.
 * access in CSS files, allowing for faster customization of CSS that falls
 * outside of the scope of our theme provider, such as vendor CSS.
 *
 * @param cssVariables
 */

export function updateCSSVariables(cssVariables) {
  Object.entries(cssVariables).forEach(([prop, value]) => document.documentElement.style.setProperty(prop, value));
}
export const setCSSVariables = baseTheme => {
  updateCSSVariables(mapToCSSVariables(baseTheme));
};
/** These were part of getExtendedTheme above, may come in use later */
// export function furtherExtensions(theme: CustomTheme) {
//   //   This is for extracting the digits of an rgb color, to add an alpha channel. See:
//   // https://stackoverflow.com/questions/40010597/how-do-i-apply-opacity-to-a-css-color-variable#answer-41265350
//   const extractFromParens = (s: string) =>
//     s.substring(s.indexOf('(') + 1, s.indexOf(')'));
//   theme.palette.augmentColor(
//     theme.palette.background,
//     'default',
//     'A200',
//     'A700',
//   );
//   theme.palette.background.lightRaw = extractFromParens(
//     theme.palette.background.light,
//   );
//   theme.palette.primary.darkContrastText = theme.palette.getContrastText(
//     theme.palette.primary.dark,
//   );
// }