import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldMultiData } from '@mummssoftware/common-ui';
import { FieldBasicTranslatedLabel } from './BasicComponents';
import messages from './Messages';

export const PatientDemographics = ({
  classes,
  patient,
  addressLine1,
  addressLine3,
  primaryAddress,
}) => (
  <Fragment>
    <Grid item xs={12}>
      <Typography color="textSecondary">{addressLine1}</Typography>
      <Typography color="textSecondary">{addressLine3}</Typography>
    </Grid>
    <FieldBasicTranslatedLabel
      classes={classes}
      message={messages.county}
      data={primaryAddress.county}
    />
    <FieldMultiData
      classes={classes}
      labelDataTuples={[
        ['', patient.maritalStatus ? patient.maritalStatus : 'unknown'],
        [' / ', patient.race ? patient.race : 'unknown '],
        [' / ', patient.ethnicity ? patient.ethnicity : 'unknown'],
      ]}
    />
  </Fragment>
);
