import React, { useCallback, useContext, lazy, Suspense } from 'react';
import { DelayedLoader } from '@mummssoftware/common-ui';
import { FormsContext } from '../../context';
import { Container } from '../../components';
import { FormContext } from './FormContext';
import { FormWrapper } from './FormWrapper';
import {
  ADD_SUBMISSION,
  SET_IS_NEW_FORM,
  SET_SUBMISSION_ID,
  SET_BUTTON_STATE,
} from './constants';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
  formName: string;
  submissionId?: string;
}>;
const ScoresContainer = lazy(() => import('../ScoresContainer'));

type FormEditProps = {
  Redirect: React.ElementType;
  theme?: string;
  /** for unit testing */
  _testForm?: mumms.JSONObject;
} & typeof RouteProps;

export const FormEdit = ({
  match: {
    params: { patientNumber, submissionId },
  },
  Redirect,
  theme,
  _testForm,
}: FormEditProps) => {
  const { currentUser, keycloak, patient, formName } = useContext(FormsContext);
  const {
    latestSubmissionId,
    latestSubmissions,
    dispatch,
    errorState,
    showFormSelector: showLatestForm,
  } = useContext(FormContext);
  // allow creation of new Form if there are no current form being edited and no old forms
  // if there are no old forms a new form should appear
  let allowCreationNewForm = !!(
    latestSubmissions && latestSubmissions.length === 0
  );
  // if errorState we don't want to block creation of new form
  if (errorState) {
    allowCreationNewForm = true;
  }

  let selectedSubmissionId: undefined | string;

  // if newForm or no form being edited
  if (showLatestForm) {
    if (latestSubmissionId === 'createNewForm') {
      selectedSubmissionId = latestSubmissionId;
      // allowCreationNewForm for FORM to know
      // allowCreationNewForm is false if not createNewForm
      allowCreationNewForm = true;
    } else {
      selectedSubmissionId = submissionId || latestSubmissionId?.value;
    }
  } else {
    // else null
    selectedSubmissionId = submissionId;
  }

  const onSubmitDone = useCallback(
    (submission: import('formiojs').completeSubmission) => {
      // eslint-disable-next-line
      console.log(submission);
      dispatch({
        type: ADD_SUBMISSION,
        payload: submission,
      });
      dispatch({
        type: SET_SUBMISSION_ID,
        payload: submission._id,
      });
      dispatch({
        type: SET_BUTTON_STATE,
        payload: true,
      });
    },
    [dispatch],
  );

  const alertNewForm = useCallback(
    (newForm: boolean) =>
      dispatch({
        type: SET_IS_NEW_FORM,
        payload: newForm,
      }),
    [dispatch],
  );

  if (
    currentUser === null ||
    keycloak === null ||
    (!Array.isArray(latestSubmissions) && !errorState)
  ) {
    return <DelayedLoader />;
  }
  if (!patient) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      {(formName === 'idg-pgba' || formName === 'idg-lcd') && (
        <Suspense fallback={<DelayedLoader />}>
          <ScoresContainer patientNumber={patientNumber} title="Measures" />
        </Suspense>
      )}
      <FormWrapper
        onNewForm={alertNewForm}
        onSubmitDone={onSubmitDone}
        allowCreationNewForm={allowCreationNewForm}
        theme={theme}
        patientNumber={patientNumber}
        submissionId={selectedSubmissionId}
        _testForm={_testForm}
      />
    </Container>
  );
};

export default FormEdit;
