import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import AlsDialog from './AlsDialog';
import { messages } from './messages';

const Wrap = styled.div``;

export const AlsConsiderations: React.SFC = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { formatMessage } = useIntl();
  return (
    <Wrap>
      <Button
        data-testid="als-button"
        color="primary"
        onClick={handleClickOpen}
      >
        {formatMessage(messages.title)}
      </Button>
      <AlsDialog handleClose={handleClose} open={open} />
    </Wrap>
  );
};

export default AlsConsiderations;
