import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { FieldBasic, FieldMultiData } from '@mummssoftware/common-ui';
import messages from './Messages';
import { dateFormat } from '../../../constants';
import { LOCField, FieldBasicTranslatedLabel } from './BasicComponents';

const episodeFieldTuples = (action, program, date) => [
  ['', action],
  [<FormattedMessage key={messages.to.id} {...messages.to} />, program],
  [
    <FormattedMessage key={messages.asOf.id} {...messages.asOf} />,
    date ? <FormattedDate value={moment.utc(date)} {...dateFormat} /> : null,
  ],
];

export const PatientEpisode = ({ classes, currentCert, full }) =>
  full ? (
    <Fragment>
      <FieldMultiData
        classes={classes}
        labelDataTuples={episodeFieldTuples(
          <FormattedMessage key="referred" {...messages.referred} />,
          <FormattedMessage
            key="referred-prog"
            id={currentCert.episode.program}
            defaultMessage={currentCert.episode.program}
          />,
          currentCert.episode.referralDate,
        )}
      />
      <FieldMultiData
        classes={classes}
        labelDataTuples={episodeFieldTuples(
          <FormattedMessage key="admit" {...messages.admitted} />,
          <FormattedMessage
            key="admit-prog"
            id={currentCert.episode.program}
            defaultMessage={currentCert.episode.program}
          />,
          currentCert.episode.admitDate,
        )}
      />
      {currentCert.episode.dischargeDate ? (
        <Fragment>
          <FieldMultiData
            classes={classes}
            labelDataTuples={episodeFieldTuples(
              <FormattedMessage key="discharge" {...messages.discharged} />,
              <FormattedMessage
                key="discharge-prog"
                id={currentCert.episode.program}
                defaultMessage={currentCert.episode.program}
              />,
              currentCert.episode.dischargeDate,
            )}
          />
          <FieldBasic
            classes={classes}
            label={
              <FormattedMessage key="discharge-reason" {...messages.reason} />
            }
            data={currentCert.episode.dischargeReason}
          />
        </Fragment>
      ) : null}
      <LOCField classes={classes} currentCert={currentCert} />
    </Fragment>
  ) : (
    <Fragment>
      <FieldBasicTranslatedLabel
        classes={classes}
        message={messages.referred}
        data={
          currentCert.episode.referralDate && (
            <FormattedDate
              value={moment.utc(currentCert.episode.referralDate)}
              {...dateFormat}
            />
          )
        }
      />
      <FieldBasicTranslatedLabel
        classes={classes}
        message={messages.admitted}
        data={
          currentCert.episode.admitDate && (
            <FormattedDate
              value={moment.utc(currentCert.episode.admitDate)}
              {...dateFormat}
            />
          )
        }
      />
    </Fragment>
  );
