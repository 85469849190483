import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SuggestBox } from '@mummssoftware/common-ui';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { FormContext } from './FormContext';
import { SET_SUBMISSION_ID, SET_BUTTON_STATE } from './constants';

const FormSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SuggestBoxWrapper = styled.div``;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const FormError = ({ errorState }: { errorState: string }) => (
  <Typography>{`Unable to fetch previous submissions: ${errorState}`}</Typography>
);

export const FormSelector = React.memo(() => {
  const {
    dispatch,
    errorState,
    buttonState,
    latestSubmissions,
    latestSubmissionId,
    newForm,
    showFormSelector,
  } = useContext(FormContext);
  const ErrorMsg =
    newForm && errorState ? <FormError errorState={errorState} /> : null;
  const [option, setOption] = useState(
    latestSubmissions ? latestSubmissions[0] : { label: '', value: '' },
  );
  useEffect(() => {
    if (
      option &&
      !option.value &&
      Array.isArray(latestSubmissions) &&
      latestSubmissions.length
    ) {
      setOption(latestSubmissions[0]);
    }
  }, [latestSubmissions, option, setOption]);

  useEffect(() => {
    if (latestSubmissionId !== 'createNewForm') {
      const newOpt = latestSubmissions?.find(
        ({ value }) => value === latestSubmissionId,
      );
      if (newOpt) {
        setOption(newOpt);
      }
    }
  }, [latestSubmissionId, latestSubmissions]);

  const clickHandler = useCallback(() => {
    dispatch({
      type: SET_BUTTON_STATE,
      payload: true,
    });
    dispatch({ type: SET_SUBMISSION_ID, payload: 'createNewForm' });
  }, [dispatch]);

  const changeHandler = useCallback(
    (selectedOption) => {
      setOption(selectedOption as any);
      dispatch({ type: SET_SUBMISSION_ID, payload: selectedOption });
    },
    [dispatch],
  );
  const classes = useStyles();
  if (!showFormSelector) {
    return ErrorMsg;
  }
  return (
    <Fragment>
      {ErrorMsg}
      <FormSelectorWrapper className={classes.root}>
        <SuggestBoxWrapper>
          <Typography variant="caption">View prior completed forms</Typography>
          <SuggestBox
            options={latestSubmissions || []}
            defaultValue={latestSubmissions ? latestSubmissions[0] : {}}
            value={option}
            onChange={changeHandler}
          />
        </SuggestBoxWrapper>
        <Button
          data-testid="form-selector-button"
          size="small"
          disabled={buttonState}
          color="primary"
          variant="outlined"
          onClick={clickHandler}
        >
          CREATE NEW FORM
        </Button>
      </FormSelectorWrapper>
    </Fragment>
  );
});

export default FormSelector;
