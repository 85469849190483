/**
 * Checks inner/clientHeight for best mobile compatibility
 */
export const getClientHeight = (optionsOrHeight, passedClientHeight) => {
  let innerHeight;
  let clientHeight = passedClientHeight;

  if (typeof optionsOrHeight === 'number') {
    innerHeight = optionsOrHeight;
  } else {
    innerHeight = optionsOrHeight.innerHeight;
    clientHeight = optionsOrHeight.clientHeight;
  }

  return innerHeight && clientHeight ? Math.min(innerHeight, clientHeight) : innerHeight || clientHeight || document.getElementsByTagName('body')[0].clientHeight;
};