import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import {
  Watermark,
  WidgetHeader,
  ToggleSwitch,
} from '@mummssoftware/common-ui';
import { LicenseManager } from 'ag-grid-enterprise';

import { cpfAll, cpfCurrent, CPF_VIEW } from '../../utils/constant';
import { CarePlanFrequencyContext } from '../../context';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import { CarePlanFrequencyTable } from '../../components/sections/CarePlanFrequencyTable';

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);
// import { Container } from '../../components/layout/Container';

const Container = styled.div`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
`;

// passing "smallWidget" as a prop to the container
const CarePlanFrequencyBody = styled.div<{
  smallWidget: boolean | undefined;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `min-height: ${props.theme.props.dashboard.smallWidgetHeight}px;
      height: ${props.theme.props.dashboard.smallWidgetHeight}px;`
      : `height: ${props.theme.props.dashboard.fullSizeHeight}px;`}
  background-color: ${(props) => props.theme.palette.background.paper};
  position: relative; /** relative for watermark */
  width: 100%;
  overflow: auto;
`;
const CarePlanFrequencyTableWrapper = styled.div<{
  smallWidget: boolean | undefined;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `height: ${props.theme.props.dashboard.smallWidgetHeight}px;`
      : 'height: 50vh;'}
  min-height: 279px;
`;

export const CarePlanFrequencyComponent = () => {
  const { appTitle, smallWidget, watermark, plans } = useContext(
    CarePlanFrequencyContext,
  );
  let cpfView = localStorage.getItem(CPF_VIEW);
  const [checked, setChecked] = useState<boolean>(cpfView === cpfAll || false);
  const [filter, setFilter] = useState(cpfView || cpfCurrent);

  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;

  const handleSwitchChange = (isChecked: boolean) => {
    cpfView = isChecked ? cpfAll : cpfCurrent;
    localStorage.setItem(CPF_VIEW, cpfView);
    setFilter(cpfView);
    setChecked(isChecked);
  };

  return (
    <Container>
      <WidgetHeader
        appTitle={appTitle || 'Care Plan Frequency'}
        versionInfo={`Care Plan v${process.env.REACT_APP_CARE_PLAN_FREQUENCY_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
        noIcon
        smallWidget
      >
        <ToggleSwitch
          rightText={'Current' as string}
          leftText={'All' as string}
          onToggle={(isChecked) => handleSwitchChange(isChecked)}
          isChecked={checked}
        />
      </WidgetHeader>
      <CarePlanFrequencyBody smallWidget>
        {watermark && watermark.show ? (
          <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
        ) : null}
        <CarePlanFrequencyTableWrapper
          smallWidget={smallWidget}
          className={`${gridTheme.className} light`}
        >
          <CarePlanFrequencyTable
            smallWidget={false}
            plans={plans}
            filter={filter}
          />
        </CarePlanFrequencyTableWrapper>
      </CarePlanFrequencyBody>
    </Container>
  );
};

export default CarePlanFrequencyComponent;
