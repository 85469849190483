import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
export const GridLabel = withStyles({
  item: {
    flexBasis: '50%',
    maxWidth: '100%'
  }
})(Grid);
export const GridData = withStyles({
  item: {
    flexBasis: 'auto',
    minWidth: '50%'
  }
})(Grid);
export const GridDataTooltip = withStyles(theme => ({
  item: {
    [theme.breakpoints.only('xs')]: {
      flexBasis: 'auto',
      minWidth: '50%'
    }
  }
}))(Grid);