import React, { useContext } from 'react';
import { Context } from '../../context';
import Form from './Form';
import { FormSelect } from './MeasureFormSelect';
import {
  patientRefused,
  notTaken,
  tableHeight,
} from '../../containers/MeasuresContext/utils/constants';
import { MeasuresContext } from '../../containers/MeasuresContext/MeasuresContextProvider';

type measuresType = mumms.measureType;

type MeasuresFormProps = {
  measuresTypes: measuresType[];
  chartHeight: number;
};

export const MeasuresForm = React.memo(
  ({ measuresTypes, chartHeight }: MeasuresFormProps) => {
    const {
      handleMeasureChange,
      handleErrorMsg,
      patient,
      currentUser,
    } = useContext(Context);

    const { tableSelected } = useContext(MeasuresContext);

    const patientNumber = patient?.patientNumber;
    const patientSiteId = patient?.siteId.toString();
    const patientId = patient?.id;
    const personId = currentUser.isMumms ? null : currentUser.userId;

    const handleCancel = () => null;

    /** Setting up the object to reference for each selection */
    const measureFields = (() => {
      const allMeasures: any = {};
      measuresTypes &&
        measuresTypes.forEach((measure: measuresType) => {
          const { id, shortname, readonly } = measure;
          const options =
            measure.iscontrolledlist === 'true'
              ? JSON.parse(measure.values)
              : [];
          const units =
            measure.units &&
            JSON.parse(measure.units)
              .filter((m: any) => m.unitshortname !== patientRefused)
              .filter((m: any) => m.unitshortname !== notTaken);

          allMeasures[shortname] = {};
          /* The unique value name */
          allMeasures[shortname].id = id;
          /* The label text for the value */
          allMeasures[shortname].label = shortname;
          /* The unique value name */
          allMeasures[shortname].name = shortname;
          allMeasures[shortname].readonly = readonly;
          /* The drop down items for the value */
          allMeasures[shortname].options = options;
          allMeasures[shortname].units = units;
        });
      return allMeasures;
    })();
    const measuresNames = Object.keys(measureFields);

    return (
      <Form
        fields={measureFields}
        handleMeasuresChange={handleMeasureChange}
        handleErrorMsg={handleErrorMsg}
        handleCancel={handleCancel}
        patientNumber={patientNumber as string}
        patientId={patientId as number}
        personId={personId as string}
        patientSiteId={patientSiteId as string}
        render={() => (
          <React.Fragment>
            {measuresNames &&
              measuresNames?.map((measure) => (
                <FormSelect
                  {...measureFields[measure]}
                  key={measure}
                  chartHeight={
                    measure === tableSelected
                      ? chartHeight + tableHeight
                      : chartHeight
                  }
                  measuresNames={measuresNames}
                />
              ))}
          </React.Fragment>
        )}
      />
    );
  },
);
