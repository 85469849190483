import React, { memo, useState, Fragment } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import {
  ActionIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  SectionHeader,
} from '@mummssoftware/common-ui';
import styled from 'styled-components';
import { HBCTreeCollapse, HBCTreeExpand } from '@mummssoftware/icons';
import { Card } from '@mummssoftware/utils/constants';
import CertForm from './CertForm';
import PatientCertHeader from '../Patient/PatientHeader';

const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;

const PrintDiv = styled.div`
  @media print {
    page-break-after: always;
  }
`;
/**
 * @typedef Props
 * @param {*} param0
 */
/**
 * @typedef CertHistoryProps
 * @prop {import('./CertForm').CertFormProps} certProps Props for the CertForm component
 * @prop {function} formatMessage react-intl i18n function
 * @prop {function} getCertKeys
 * @prop {*[]} forms
 * @prop {function} onHide
 * @prop {function} m2Url
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {string} [patientNumber]
 * @prop {number} [payerId]
 */

/**
 *
 * @param {CertHistoryProps} props
 */
const CertHistory = ({
  certProps,
  formatMessage,
  forms,
  getCertKeys,
  onHide,
  m2Url,
  patientNumber,
  patient,
  payerId,
  printConfig,
  mostRecentCert,
  self,
  headerProps,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [childrenExpanded, setChildrenExpanded] = useState(true);
  const currentHistory = patient.certHistory[payerId];
  const handleExpand = (e) => {
    if (e.target.closest('#child-expand')) {
      if (!expanded && !childrenExpanded) {
        setExpanded(true);
      }
      setChildrenExpanded(!childrenExpanded);
    } else {
      setExpanded(!expanded);
    }
  };
  return printConfig && printConfig.view === Card ? (
    <div>
      <div>
        {forms.map((form, i) => {
          const { length } = forms;
          const currentCert = currentHistory[form.certIndex];
          const onHideBound = () => onHide(form.formType, form.certIndex);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${form.certIndex}-${i}`}>
              <PrintDiv>
                {printConfig.view === Card && (
                  <PatientCertHeader
                    key={`patient-header-${patientNumber}`}
                    patient={patient}
                    primaryAddress={patient.primaryAddress}
                    currentCert={mostRecentCert}
                    formatMessage={self.current.formatMessage}
                    m2Url={self.current.m2Url}
                    {...headerProps}
                  />
                )}

                <CertForm
                  key={`cert-form-${patientNumber}-${payerId}-${currentCert.electionNumber}-${currentCert.episode.number}-${form.formType}`}
                  currentCert={currentCert}
                  currentCertKeys={getCertKeys(form.formType)}
                  expandable
                  expanded={childrenExpanded}
                  formPhysId={form.formPhysId}
                  formPhysRestricted={form.formPhysRestricted}
                  formType={form.formType}
                  printFormType={form.formType}
                  onHide={onHideBound}
                  historicalView
                  m2Url={m2Url}
                  patient={patient}
                  verbalOnly={!form.complete && form.verbalComplete}
                  {...certProps}
                  isDoctor={false}
                  printConfig={printConfig}
                />
              </PrintDiv>
              {printConfig && printConfig.view === Card && i !== length - 1 && (
                <PageBreak />
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <ExpansionPanel expanded={expanded} onChange={handleExpand}>
      <ExpansionPanelSummary>
        <SectionHeader
          border
          action={
            <Fragment>
              <ActionIcon
                id="child-expand"
                title={
                  childrenExpanded
                    ? formatMessage({ id: 'CollapseChildren' })
                    : formatMessage({ id: 'ExpandChildren' })
                }
                Icon={childrenExpanded ? HBCTreeExpand : HBCTreeCollapse}
              />
              <ActionIcon
                title={
                  expanded
                    ? formatMessage({ id: 'Collapse' })
                    : formatMessage({ id: 'Expand' })
                }
                Icon={expanded ? KeyboardArrowDown : KeyboardArrowRight}
              />
            </Fragment>
          }
          title={currentHistory[currentHistory.length - 1].carrierName}
        />
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        {forms.map((form) => {
          const currentCert = currentHistory[form.certIndex];
          const onHideBound = () => onHide(form.formType, form.certIndex);
          return (
            <CertForm
              key={`cert-form-${patientNumber}-${payerId}-${currentCert.electionNumber}-${currentCert.episode.number}-${form.formType}`}
              currentCert={currentCert}
              currentCertKeys={getCertKeys(form.formType)}
              expandable
              expanded={childrenExpanded}
              formPhysId={form.formPhysId}
              formPhysRestricted={form.formPhysRestricted}
              formType={form.formType}
              printFormType={form.formType}
              onHide={onHideBound}
              historicalView
              m2Url={m2Url}
              patient={patient}
              verbalOnly={!form.complete && form.verbalComplete}
              {...certProps}
              isDoctor={false}
              printConfig={printConfig}
            />
          );
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default memo(CertHistory);
