import React, { useContext } from 'react';
import { differenceBy } from 'lodash-es';
import { Watermark, WidgetHeader } from '@mummssoftware/common-ui';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { GlobalContext } from '../../context';
import { CarePlanTable } from './CarePlanTable';
import { CarePlanMenu } from './CarePlanMenu';
import {
  Container,
  CarePlanProblemsBody,
} from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type CarePlanProblemsProps = {
  currentUser: mumms.User;
  online: boolean;
  patient?: mumms.patient;
  patientCarePlanProblem: mumms.patientCarePlanProblem[];
  appTitle?: string;
  view?: string | null;
  smallWidget?: boolean;
} & typeof RouteProps;

export const CarePlanProblems = ({ smallWidget }: CarePlanProblemsProps) => {
  const {
    watermark,
    errorInfo,
    clearError,
    appTitle,
    patientCarePlanProblem,
    allCarePlanProblems,
  } = useContext(GlobalContext);

  const patientCarePlanProblemsNames = patientCarePlanProblem.map((cpp) => ({
    shortname: cpp.careplanproblemshortname,
  }));
  const newCarePlans = differenceBy(
    allCarePlanProblems,
    patientCarePlanProblemsNames,
    (x: any) => x.shortname,
  );

  const Alert = (props: AlertProps) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  return (
    <Container id="care-pan-container">
      {watermark && watermark.show ? (
        <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
      ) : null}
      <Snackbar
        open={!!errorInfo}
        autoHideDuration={3000}
        onClose={() => clearError()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={() => clearError()} severity="error">
          {errorInfo}
        </Alert>
      </Snackbar>
      <WidgetHeader
        appTitle={appTitle || defaults.title}
        versionInfo={`${defaults.title} v${process.env.REACT_APP_CARE_PLAN_PROBLEMS_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
        smallWidget={smallWidget}
        noIcon
        position="left"
      >
        {newCarePlans.length && <CarePlanMenu newCarePlans={newCarePlans} />}
      </WidgetHeader>
      <CarePlanProblemsBody
        id="care-plan-problems-body"
        smallWidget={smallWidget}
      >
        <CarePlanTable />
      </CarePlanProblemsBody>
    </Container>
  );
};

export default CarePlanProblems;
