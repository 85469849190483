import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Container } from '../../components/layout/Container';
import { Context } from '../../context';

declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type NotFoundProps = unknown & typeof RouteProps;

export const NotFound = (props: NotFoundProps) => {
  const { patientError } = useContext(Context);
  let patientNumber: string | undefined;
  let status: number | undefined;
  let statusText: string | undefined;
  if (patientError) {
    ({ patientNumber, status, statusText } = patientError);
  } else {
    ({ patientNumber } = props.match.params);
  }

  return (
    <Container>
      {patientNumber ? (
        <Typography>
          {`Error loading patient ${patientNumber}: ${status} ${statusText}`}
        </Typography>
      ) : (
        <Typography>Please enter a patient number.</Typography>
      )}
    </Container>
  );
};

export default NotFound;
