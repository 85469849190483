import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ATTENDING_FORM } from '../../../constants';
import messages from '../CertMessages';

const CertButton = ({
  disable,
  endpoint,
  handleCertify,
  message,
  successMessageId,
  smallWidget,
  ...props
}) => (
  <FormattedMessage {...message}>
    {(txt) => (
      <Button
        title={txt}
        size={smallWidget ? 'small' : 'medium'}
        variant="outlined"
        color="primary"
        disabled={disable}
        onClick={handleCertify}
        data-endpoint={endpoint}
        data-successmsg={successMessageId}
        {...props}
      >
        {txt}
      </Button>
    )}
  </FormattedMessage>
);

export const CertificationButtons = ({
  isDoctor,
  canCertify,
  noNarrative,
  submitting,
  handleCertify,
  showVerbal,
  padTop,
  alreadyCertified,
  formType,
  attendingPhysicianId,
  userId,
  smallWidget,
}) => {
  const isCertifiedDoctor = isDoctor && canCertify;
  const isDoctorAttendingPhys = attendingPhysicianId === userId;
  const showVerbalByRole =
    formType === ATTENDING_FORM && isDoctorAttendingPhys ? false : showVerbal;
  let certifyMessage;
  if (formType === ATTENDING_FORM) {
    certifyMessage = isDoctorAttendingPhys
      ? messages.Certify
      : messages['nonphys.Certify'];
  } else {
    certifyMessage = isCertifiedDoctor
      ? messages.Certify
      : messages['nonphys.Certify'];
  }

  return (
    <Grid
      item
      sm="auto"
      style={padTop ? { paddingTop: 'calc(1rem + 4px)' } : {}}
    >
      {showVerbalByRole ? (
        <CertButton
          disable={!noNarrative || submitting}
          handleCertify={handleCertify}
          message={
            isCertifiedDoctor ? messages.Verbal : messages['nonphys.Verbal']
          }
          endpoint="recordverbal"
          successMessageId="certVerbal"
          style={{ marginRight: '.4rem' }}
          smallWidget={smallWidget}
        />
      ) : null}
      {!alreadyCertified && (
        <CertButton
          disable={
            (isCertifiedDoctor && noNarrative && formType !== ATTENDING_FORM) ||
            submitting
          }
          handleCertify={handleCertify}
          message={certifyMessage}
          endpoint="recordcert"
          successMessageId="Certified"
          smallWidget={smallWidget}
        />
      )}
    </Grid>
  );
};

export default CertificationButtons;
