import React, { createContext, useEffect, useState } from 'react';

export const PatientCertContext = createContext({
  hospiceBin: null,
});

export const PatientCertContextProvider = ({ hospiceBin, children }) => {
  const [certContextState, setCertContextState] = useState({
    hospiceBin,
  });
  useEffect(() => {
    setCertContextState({ hospiceBin });
  }, [hospiceBin]);
  return (
    <PatientCertContext.Provider value={certContextState}>
      {children}
    </PatientCertContext.Provider>
  );
};
