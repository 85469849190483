/* eslint-disable no-nested-ternary */

import React, { useState, useCallback, useContext } from 'react';
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import styled from 'styled-components';
import { formatDateMMDDYY } from '@mummssoftware/utils/formatters';
import { SuggestBox } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';
import { CoveredDialog } from './CoveredDialog';
import { AppContext } from '../../App/AppContext';
import PrintMedicationButton from '../Print/PrintMedicationButton';
import {
  getMinStandartTooltipTitle,
  getMinStandardPlaceholder,
  minStandardOptions,
  getCoveredTooltipTitle,
  getCoveredPlaceholder,
  coveredOptions,
} from './constants';
import { MedContext } from '../../App/MedContext';
import HistoryMedicationsTable from '../History/HistoryMedicationsTable';

const StyledTableCell = styled(TableCell)`
  && {
    color: inherit;
    white-space: nowrap;
    ${(props) => (props.smallwidget ? `padding: 2px;` : `padding: 1px;`)}
    ${(props) => (props.smallwidget ? `font-size: 14px;` : `font-size: 15px;`)}
  }
`;
const StyledTruncateTableCell = styled(TableCell)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1px;
    ${(props) => (props.smallwidget ? `padding: 2px;` : `padding: 3px;`)}
    ${(props) => (props.smallwidget ? `;` : `padding-left: 8px;`)}
    ${(props) => (props.smallwidget ? `font-size: 14px;` : `font-size: 15px;`)}

    color: inherit;
  }
`;

const SmallDiv = styled.div`
  width: 40px;
`;

const ToggleDiv = styled.div`
  ${(props) =>
    props.isMobile && !props.isMobileEditable ? `;` : `cursor: pointer;`}
  width: 40px;
  display: flex;
  justify-content: center;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      minWidth: (props) => props.containerWidth * 0.97,
      padding: '4px',
      margin: '0px',
    },
    arrow: {
      color: theme.palette.common.white,
    },
  }),
);

const StyledTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'gray',
    fontSize: '1em',
  },
})(Tooltip);

const BaseMedication = ({
  className,
  classes,
  medication,
  handleChange,
  doctors,
  patient,
  containerWidth,
}) => {
  const [openCoveredDialog, setOpenCoveredDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const styleProps = {
    containerWidth,
  };
  const historyClasses = useStyles(styleProps);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const coveredError = medication.coveredbyhospice === '';
  const minStandardError =
    medication.medatadmit === 'false' && medication.medattestation === 'false';

  const handleCloseCoveredDialog = () => {
    setOpenCoveredDialog(false);
  };

  const checkDiscontinued = useCallback(() => {
    if (medication.stopped) {
      return classes.medInfoValue;
    }
    return '';
  }, [medication.stopped, classes.medInfoValue]);

  // OP-27361 - RED symbol if the hospice physician has yet to see and approve me
  const onPhysicianChange = useCallback(
    (e) => {
      const orderingphysicianlastname = e.lastName;
      const orderingphysicianfirstname = e.firstName;
      const orderingphysiciancredentials = e.credentials;
      handleChange(medication, 'orderingphysician', {
        orderingphysicianid: e.value,
        orderingphysicianfirstname,
        orderingphysicianlastname,
        orderingphysiciancredentials,
      });
    },
    [medication, handleChange],
  );

  const onMinStandardChange = useCallback(
    async (e) => {
      const { value } = e;
      if (value === 'medattestation') {
        handleChange(medication, 'medattestation', {
          medattestation: 'true',
          medatadmit: 'false',
        });
      } else {
        handleChange(medication, 'medattestation', {
          medattestation: 'false',
          medatadmit: 'true',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, medication],
  );

  const toggleMinStandard = () => {
    // the med is currently at medattestation === true
    const isMedattestation = medication.medattestation === 'true';
    // by toggling we are setting it to medatadmit === true
    if (isMedattestation) {
      handleChange(medication, 'medattestation', {
        medattestation: 'false',
        medatadmit: 'true',
      });
    } else {
      handleChange(medication, 'medattestation', {
        medattestation: 'true',
        medatadmit: 'false',
      });
    }
  };

  const onCoveredByHospiceChange = useCallback(
    async (e) => {
      const { value } = e;
      if (value === 'true') {
        handleChange(medication, 'coveredbyhospice', {
          coveredbyhospice: value,
          notcoveredreason: '',
        });
      } else {
        setOpenCoveredDialog(true);
        handleChange(medication, 'coveredbyhospice', value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, medication],
  );

  const toggleCoveredByHospice = () => {
    // the med is currently NOT covered
    const isNotCovered = medication.coveredbyhospice === 'false';
    // by toggling we are setting it to covered
    if (isNotCovered) {
      handleChange(medication, 'coveredbyhospice', {
        coveredbyhospice: 'true',
        notcoveredreason: '',
      });
    } else {
      setOpenCoveredDialog(true);
      handleChange(medication, 'coveredbyhospice', 'false');
    }
  };

  const orderingPhysicianLabel = () => {
    const {
      orderingphysicianfirstname: firstName,
      orderingphysicianlastname: lastName,
      orderingphysiciancredentials: credentials,
    } = medication;

    return `${lastName}${credentials ? ` ${credentials}` : ''}, ${firstName}`;
  };

  const { readonly, isMobile, smallWidget } = useContext(AppContext);
  const { isMobileEditable } = useContext(MedContext);

  const readonlyAction = () => null;

  const handleActionChange = (action) =>
    readonly
      ? readonlyAction
      : isMobile && !isMobileEditable
      ? readonlyAction
      : action;

  // TODO change the logic
  return smallWidget ? (
    <TableRow
      hover
      tabIndex={-1}
      key={medication.sig}
      className={`${className} ${checkDiscontinued()}`}
    >
      {/* NEEDS APPROVAL */}
      {!medication.reconcileddate || medication.reconcileddate === '' ? (
        <StyledTooltip title={<FormattedMessage {...messages.needsApproval} />}>
          <StyledTableCell>
            <FiberManualRecordIcon color="error" fontSize="small" />
          </StyledTableCell>
        </StyledTooltip>
      ) : (
        <StyledTableCell />
      )}
      {/* MEDICATION NAME */}
      <StyledTooltip
        title={medication.drugdescription}
        aria-label="medication name"
      >
        <StyledTruncateTableCell
          align="left"
          smallwidget={
            smallWidget && smallWidget.toString()
              ? smallWidget.toString()
              : undefined
          }
        >
          {medication.drugdescription}
        </StyledTruncateTableCell>
      </StyledTooltip>
      {/* MEDICATION STARTDATE */}
      <StyledTableCell
        align="left"
        smallwidget={
          smallWidget && smallWidget.toString()
            ? smallWidget.toString()
            : undefined
        }
      >
        {medication.startdate
          ? formatDateMMDDYY(medication.startdate)
          : `${formatDateMMDDYY(medication.lastupdatedate)}*`}
      </StyledTableCell>
    </TableRow>
  ) : (
    <TableRow
      hover
      tabIndex={-1}
      key={medication.sig}
      className={checkDiscontinued()}
    >
      {/* NEEDS APPROVAL */}
      {!medication.reconcileddate || medication.reconcileddate === '' ? (
        <StyledTooltip title={<FormattedMessage {...messages.needsApproval} />}>
          <StyledTableCell align="center">
            <FiberManualRecordIcon color="error" fontSize="small" />
          </StyledTableCell>
        </StyledTooltip>
      ) : (
        <StyledTableCell />
      )}
      {/* MEDICATION NAME */}
      <StyledTooltip
        title={medication.drugdescription}
        aria-label="medication name"
      >
        <StyledTruncateTableCell align="left">
          {medication.drugdescription}
        </StyledTruncateTableCell>
      </StyledTooltip>
      {/* MEDICATION INSTRUCTIONS */}
      <StyledTooltip title={medication.sig}>
        <StyledTruncateTableCell
          align="left"
          aria-label="medication description"
        >
          {medication.sig}
        </StyledTruncateTableCell>
      </StyledTooltip>
      {/* MEDICATION QUANTITY */}
      <StyledTableCell align="center">
        {Math.trunc(medication.quantity)}
      </StyledTableCell>
      {/* MEDICATION STARTDATE */}
      <StyledTableCell align="left">
        {medication.startdate
          ? formatDateMMDDYY(medication.startdate)
          : `${formatDateMMDDYY(medication.lastupdatedate)}*`}
      </StyledTableCell>

      {/* COVERED BY HOSPICE */}
      {coveredError ? (
        <StyledTableCell>
          <SmallDiv>
            <SuggestBox
              inputId={`covered-${medication.id}`}
              hasError={coveredError}
              fitcontent
              placeholder={getCoveredPlaceholder(medication)}
              tooltipTitle={getCoveredTooltipTitle(medication) || ''}
              options={coveredOptions}
              disabled={readonly || (isMobile && !isMobileEditable)}
              onChange={handleActionChange(onCoveredByHospiceChange)}
            />
          </SmallDiv>
          <CoveredDialog
            open={openCoveredDialog}
            onClose={handleCloseCoveredDialog}
            classes={classes}
            medication={medication}
            handleChange={handleChange}
          />
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <ToggleDiv
            isMobile={isMobile}
            isMobileEditable={isMobileEditable}
            onClick={handleActionChange(toggleCoveredByHospice)}
          >
            <StyledTooltip
              title={getCoveredTooltipTitle(medication) || ''}
              aria-label="med-covered"
            >
              <div>{getCoveredPlaceholder(medication)}</div>
            </StyledTooltip>
          </ToggleDiv>
          <CoveredDialog
            open={openCoveredDialog}
            onClose={handleCloseCoveredDialog}
            classes={classes}
            medication={medication}
            handleChange={handleChange}
          />
        </StyledTableCell>
      )}

      {/* MINIMUM STANDARDS MET */}
      {minStandardError ? (
        <StyledTableCell>
          <SmallDiv>
            <SuggestBox
              inputId={`min-standard-${medication.id}`}
              hasError={minStandardError}
              fitcontent
              placeholder={getMinStandardPlaceholder(medication)}
              tooltipTitle={getMinStandartTooltipTitle(medication)}
              options={minStandardOptions}
              disabled={readonly || (isMobile && !isMobileEditable)}
              onChange={handleActionChange(onMinStandardChange)}
            />
          </SmallDiv>
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <ToggleDiv
            isMobile={isMobile}
            isMobileEditable={isMobileEditable}
            onClick={handleActionChange(toggleMinStandard)}
          >
            <StyledTooltip
              title={getMinStandartTooltipTitle(medication)}
              aria-label="med-min-standard"
            >
              <div>{getMinStandardPlaceholder(medication)}</div>
            </StyledTooltip>
          </ToggleDiv>
        </StyledTableCell>
      )}

      {/* PHYSICIAN */}
      <StyledTableCell>
        <SuggestBox
          inputId={`physician-${medication.id}`}
          hasError={!medication.orderingphysicianfirstname}
          fitcontent
          placeholder={
            medication.orderingphysicianfirstname ? (
              orderingPhysicianLabel()
            ) : (
              <FormattedMessage {...messages.choosePhys} />
            )
          }
          options={doctors}
          disabled={readonly || (isMobile && !isMobileEditable)}
          onChange={handleActionChange(onPhysicianChange)}
        />
      </StyledTableCell>
      {/* MEDICATION STOPDATE */}
      <StyledTooltip title={medication.stopreason} aria-label="stop reason">
        <StyledTableCell align="center">
          {formatDateMMDDYY(medication.stopdate)}
        </StyledTableCell>
      </StyledTooltip>

      {/* PRINT MEDICATION */}
      <StyledTableCell align="center">
        <PrintMedicationButton
          patient={patient}
          medication={medication}
          orderingPhysicianLabel={orderingPhysicianLabel}
        />
      </StyledTableCell>

      {/* HISTORY MEDICATION */}
      {medication.history && (
        <StyledTableCell>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              classes={{
                tooltip: historyClasses.tooltip,
                arrow: historyClasses.arrow,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              containerWidth={containerWidth}
              title={
                <React.Fragment>
                  <HistoryMedicationsTable externalid={medication.externalid} />
                </React.Fragment>
              }
            >
              <IconButton
                aria-label="print row"
                size="small"
                // color="primary"
                onClick={handleTooltipOpen}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </StyledTableCell>
      )}
    </TableRow>
  );
};

export const Medication = withStyles((theme) => ({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: '6px',
      },
      paddingDefault: {
        padding: '6px',
      },
    },
  },
  hide: {
    display: 'none',
  },
  medInfo: {
    display: 'block',
    color: theme.palette.text.hint,
  },
  medInfoValue: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: '700',
  },
}))(React.memo(BaseMedication));
