import React, { Fragment, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ActionIcon } from '@mummssoftware/common-ui';
import AddIcon from '@material-ui/icons/Add';
import { CPPContext } from '../Context/CPPContext';
import { SET_FORM_ID } from '../Context/constants';

const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: { maxHeight: ITEM_HEIGHT * 6, width: 300 },
  menuItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
    fontSize: '1rem',
    whiteSpace: 'break-spaces',
  },
}));

type CarePlanMenuProps = {
  newCarePlans: mumms.carePlanProblem[];
};

export const CarePlanMenu = ({ newCarePlans }: CarePlanMenuProps) => {
  const classes = useStyles();

  const { dispatch } = useContext(CPPContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const openCarePlan = (cpp: mumms.carePlanProblem) => () => {
    // open the form
    dispatch({
      type: SET_FORM_ID,
      payload: {
        formKey: cpp.description,
        formName: cpp.shortname,
      },
    });

    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ActionIcon
        Icon={AddIcon}
        title="Care Plans"
        style={{
          margin: 0,
          marginRight: 10,
          width: 20,
          height: 20,
        }}
        onClick={onOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        PaperProps={{
          classes: { elevation8: classes.menuPaper },
        }}
      >
        {(newCarePlans as any).map((item: mumms.carePlanProblem) => (
          <MenuItem
            key={item.shortname}
            onClick={openCarePlan(item)}
            className={classes.menuItem}
          >
            {item.shortname}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
export default CarePlanMenu;
