import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const InlinePrintLabel = withStyles((theme) => ({
  inlineField: {
    maxWidth: 200,
    margin: '-8px 4px -2px 0',
    '@media print': {
      width: '2.5in',
    },
  },
  printUnderline: {
    '@media print': {
      '&&::before': {
        borderBottom: `1px solid ${theme.props.printRules.textSecondaryColor}`,
      },
    },
  },
}))(({ classes }) => (
  <TextField
    className={classes.inlineField}
    InputProps={{
      classes: {
        underline: classes.printUnderline,
      },
    }}
    disabled
  />
));

export const PrintLabel = withStyles((theme) => ({
  typography: {
    overflow: 'visible',
    position: 'relative',
    width: '-webkit-fill-available',
  },
  printContainer: {
    width: '100%',
    marginBottom: 20,
  },
  printLabelShrink: {
    top: 'inherit',
    bottom: -22,
  },
  printLabelDisabled: {
    '@media print': {
      '&&': {
        ...theme.props.printRules.textPrimary['@media print'],
      },
    },
  },
  printField: {
    '&&': {
      marginTop: 0,
    },
  },
  printUnderline: {
    '@media print': {
      '&&::before': {
        borderBottom: `1px solid ${theme.props.printRules.textSecondaryColor}`,
      },
    },
  },
}))(
  React.forwardRef(({ classes, label, sm = 6, children }, ref) => (
    <Grid zeroMinWidth item xs={12} sm={sm}>
      <Typography
        ref={ref}
        className={classes.typography}
        noWrap
        component="div"
      >
        {children}
        <TextField
          label={label}
          InputLabelProps={{
            shrink: true,
            classes: {
              disabled: classes.printLabelDisabled,
              shrink: classes.printLabelShrink,
            },
          }}
          InputProps={{
            classes: {
              root: classes.printField,
              underline: classes.printUnderline,
            },
          }}
          className={classes.printContainer}
          disabled
        />
      </Typography>
    </Grid>
  )),
);
