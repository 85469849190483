import React from 'react';
import PatientHistory from './PatientHistory';
import PatientRecert from './PatientRecert';

/**
 * @typedef Props
 * @prop {string|number} [certIndex]
 * @prop {import('../CertForm/CertForm').CertFormProps} certProps
 * @prop {string} [dateFrom]
 * @prop {string} [dateTo]
 * @prop {string|number} electionNumber
 * @prop {import('./PatientHeader').PatientHeaderProps} headerProps
 * @prop {boolean} [historicalView]
 * @prop {boolean} [isMobile]
 * @prop {string} insuranceCarrierId
 * @prop {boolean} [loaded]
 * @prop {function} m2Url
 * @prop {function} navCallback
 * @prop {string} patientNumber
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {function} processCerts
 * @prop {function} recordCertification
 * @prop {boolean} [submitting]
 * @prop {boolean} [successMessageOpen]
 */

/**
 * Selects the proper Cert Body
 * @param {Props} props
 */
const PatientCertBody = ({
  certIndex,
  dateFrom,
  dateTo,
  printConfig,
  electionNumber,
  embedded,
  historicalView,
  recordCertification,
  submitting,
  successMessageOpen,
  pim,
  keycloak,
  ...props
}) =>
  historicalView ? (
    <PatientHistory
      dateFrom={dateFrom}
      dateTo={dateTo}
      embedded={embedded}
      printConfig={printConfig}
      {...props}
    />
  ) : (
    <PatientRecert
      pim={pim}
      keycloak={keycloak}
      certIndex={certIndex}
      electionNumber={electionNumber}
      recordCertification={recordCertification}
      successMessageOpen={successMessageOpen}
      submitting={submitting}
      embedded={embedded}
      {...props}
    />
  );

export default PatientCertBody;
