export const WIDGET_CONFIG = 'WIDGET_CONFIG';
export const FORM_NAME = 'FORM_NAME';
export const SET_PRINT_INTERFACE_OPEN = 'SET_PRINT_INTERFACE_OPEN';
export const SET_PRINT_LAYOUT_OPEN = 'SET_PRINT_LAYOUT_OPEN';
export const SET_PRINT_CONFIG = 'SET_PRINT_CONFIG';
export const ADD_WIDGET_READY = 'ADD_WIDGET_READY';
export const RESET_WIDGETS_READY = 'RESET_WIDGETS_READY';
export const SET_LOADER = 'SET_LOADER';
export const SET_ALERT = 'SET_ALERT';
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE';
export const SET_WIDGET_OPEN = 'SET_WIDGET_OPEN';
export const RESET_WIDGET_OPEN = 'RESET_WIDGET_OPEN';
export const RESET_WIDGET_NAMES_OPEN = 'RESET_WIDGET_NAMES_OPEN';
export const SET_THEME_OVERLAY = 'SET_THEME_OVERLAY';
export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';
export const patientDashboard = 'patientDashboard';
export const clinicalDashboard = 'clinicalDashboard';
