import { formatPostDate } from '@mummssoftware/utils/formatters';

export default class ProgressNote {
  id;

  patientnumber;

  personnumber;

  siteid;

  note;

  noteddatetime;

  constructor(patientNumber, personNumber, siteid, note) {
    this.patientnumber = patientNumber;
    this.personnumber = personNumber;
    this.siteid = siteid;
    this.note = note;
    this.noteddatetime = formatPostDate(new Date());
  }
}
