import React, { createContext, ReactNode, useReducer } from 'react';
import {
  SET_NEW_MEASURE,
  SET_OVERLAY,
  SET_MEASURE_SELECTED,
  SET_MEASURE_NAME,
  SET_SCORES_METHOD,
  SHOW_TABLE,
  SET_SCORES,
  RESET_SCORES,
  SET_SUBMIT_ACTIVE,
  SET_SCORES_SUBCATEGORY,
  SET_LOADER,
  SET_TABLE_NAME,
  observation,
} from './utils/constants';
import { ScoresMethod } from './utils/type';

type MeasuresContextProviderProps = {
  children: ReactNode;
};

type MeasuresContext = {
  measureSelected: mumms.measureShortNames | null;
  tableSelected: mumms.measureShortNames | null;
  methodSelected: ScoresMethod | null;
  nameSelected: mumms.measureShortNames | null;
  newMeasure: boolean | null;
  submitActive: boolean | null;
  isLoading: boolean | null;
  loadingOverlay: boolean;
  dispatch?: any;
  showTable: boolean | null;
  scoresState: any;
  scoresSubcategory: mumms.SubcategoryType | any;
};

type MeasuresContextState = {
  measureSelected: mumms.measureShortNames | null;
  tableSelected: mumms.measureShortNames | null;
  methodSelected: ScoresMethod | null;
  nameSelected: mumms.measureShortNames | null;
  newMeasure: boolean | null;
  submitActive: boolean | null;
  isLoading: boolean | null;
  loadingOverlay: boolean;
  showTable: boolean | null;
  scoresState: any;
  scoresSubcategory: mumms.SubcategoryType | any;
};
const initialState = {
  measureSelected: null,
  tableSelected: null,
  methodSelected: null,
  nameSelected: null,
  submitActive: false,
  isLoading: false,
  newMeasure: null,
  loadingOverlay: false,
  showTable: false,
  scoresState: {},
  scoresSubcategory: {},
};

const initMeasuresContext = {
  measureSelected: null,
  tableSelected: null,
  methodSelected: null,
  nameSelected: null,
  newMeasure: null,
  loadingOverlay: false,
  isLoading: false,
  submitActive: false,
  showTable: false,
  scoresState: {},
  scoresSubcategory: {},
};

export const MeasuresContextReducer = (
  state: MeasuresContextState,
  action: { type: string; payload: any },
) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_NEW_MEASURE:
      newState.newMeasure = action.payload;
      break;
    case SET_OVERLAY:
      newState.loadingOverlay = action.payload;
      break;
    case SET_MEASURE_SELECTED:
      newState.measureSelected = action.payload;
      break;
    case SET_MEASURE_NAME:
      newState.nameSelected = action.payload;
      break;
    case SET_SCORES_METHOD:
      newState.methodSelected = action.payload;
      break;
    case SET_SCORES_SUBCATEGORY:
      newState.scoresSubcategory = action.payload;
      break;
    case SHOW_TABLE:
      newState.showTable = action.payload;
      break;
    case SET_LOADER:
      newState.isLoading = action.payload;
      break;
    case SET_TABLE_NAME:
      newState.tableSelected = action.payload;
      break;
    case SET_SCORES:
      {
        const { name } = action.payload;
        newState.scoresState[name] = action.payload.score;

        const isByObservationMethod = newState.methodSelected === observation;

        let isReady;
        if (isByObservationMethod) {
          isReady =
            Object.keys(newState.scoresState).length ===
            Object.keys(newState.scoresSubcategory.objective).length;
        } else {
          isReady =
            Object.keys(newState.scoresState).length ===
            Object.keys([
              ...newState.scoresSubcategory.subjective,
              ...newState.scoresSubcategory.objective,
            ]).length;
        }

        if (isReady) {
          newState.submitActive = true;
        }
      }
      break;
    case RESET_SCORES:
      newState.scoresState = {};
      break;
    case SET_SUBMIT_ACTIVE:
      newState.submitActive = action.payload;
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const MeasuresContext = createContext<MeasuresContext>(
  initMeasuresContext as MeasuresContext,
);
export const MeasuresContextProvider = ({
  children,
}: MeasuresContextProviderProps) => {
  const [
    {
      newMeasure,
      loadingOverlay,
      measureSelected,
      tableSelected,
      nameSelected,
      showTable,
      methodSelected,
      scoresState,
      submitActive,
      scoresSubcategory,
      isLoading,
    },
    dispatch,
  ] = useReducer(MeasuresContextReducer, initialState);
  return (
    <MeasuresContext.Provider
      value={{
        measureSelected,
        tableSelected,
        methodSelected,
        nameSelected,
        newMeasure,
        loadingOverlay,
        showTable,
        scoresState,
        submitActive,
        scoresSubcategory,
        isLoading,
        dispatch,
      }}
    >
      {children}
    </MeasuresContext.Provider>
  );
};

export default MeasuresContextProvider;
