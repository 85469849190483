import React, { useContext, useState, Fragment } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { find } from 'lodash-es';
import { Tooltip, Typography, FormControl, Select } from '@material-ui/core/';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import BlockIcon from '@material-ui/icons/Block';
import AddCommentIcon from '@material-ui/icons/AddComment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { ActionIcon } from '@mummssoftware/common-ui';
import { IErrors, IFieldProps } from './interfaces';
import { FormContext, IFormContext } from './Form';
import { MeasuresContext } from '../../containers/MeasuresContext/MeasuresContextProvider';
import {
  patientRefused,
  marginBottom,
} from '../../containers/MeasuresContext/utils/constants';
interface UIProps {
  height: string | number;
}

interface FormSelectProps extends IFieldProps {
  measuresNames: string[];
}
// todo minWidth !!!!!!!!!
// todo aquamarine in dashboard common-ui

const minWidth = 270;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: `${minWidth}px`,
      marginBottom: `${marginBottom}px`,
      height: (props: UIProps) => props.height,
    },
    formGroupUnits: {
      display: 'flex',
      alignItems: 'center',
      minWidth: `${minWidth}px`,
    },
    formGroupComment: {
      display: 'flex',
      alignItems: 'center',
      minWidth: `${minWidth}px`,
    },
    formControl: {
      alignSelf: 'center',
      margin: 10,
      width: 80,
    },
    unitsControl: {
      alignSelf: 'center',
      margin: 10,
      width: 80,
    },
    selectEmpty: {
      marginTop: 0,
      // not working center in middle select
      // '&.MuiSelect-selectMenu': {
      //   display: 'flex',
      //   justifyContent: 'center',
      // },
    },
    // AQUAMARINE
    disabledItem: {
      color: 'aquamarine',
      '&.Mui-disabled': {
        opacity: 1,
      },
    },
    formLabel: {
      color: (theme.palette as any).graph.border,
    },
    typo: {
      lineHeight: 'unset',
    },
    textLabel: {
      color: (theme.palette as any).graph.border,
      // COLOR THE SHRINK LABEL
      // '&.MuiInputLabel-shrink': {
      //   color: theme.palette.primary.main,
      // },
    },
    adorn: {
      alignSelf: 'flex-end',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export const FormSelect: React.FunctionComponent<FormSelectProps> = (props) => {
  const {
    id,
    options,
    readonly,
    units,
    value,
    name,
    required,
    disabled,
    chartHeight,
    measuresNames,
  } = props;
  const theme = useTheme();
  const {
    palette: {
      graph: { border },
    },
  } = useTheme();
  const { dispatch } = useContext(MeasuresContext);
  const defaultUnit =
    (units &&
      units.length &&
      find(units, {
        isdefaultunit: true,
      })) ||
    null;
  const defaultUnitName: string = defaultUnit
    ? defaultUnit.unitshortname
    : null;

  const styleProps = {
    height: chartHeight,
  };
  const classes = useStyles(styleProps);

  const {
    errors,
    setValues,
    setUnitsValues,
    setCommentsValues,
    validate,
    names,
  } = useContext(FormContext);

  const [commentOpen, setCommentOpen] = useState<boolean>(false);

  const marginIcon = 15;

  const blockStyleNeutral = {
    cursor: 'pointer',
    color: border,
    marginLeft: `${marginIcon}px`,
    marginRight: `${marginIcon}px`,
  };
  const blockStyleDisabled = {
    color: theme.palette.text.disabled,
    cursor: 'none',
    marginLeft: `${marginIcon}px`,
    marginRight: `${marginIcon}px`,
  };
  const blockStyleSelected = {
    cursor: 'pointer',
    marginLeft: `${marginIcon}px`,
    marginRight: `${marginIcon}px`,
  };

  const clearIconStyle = {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  };

  const [blockStyle, setBlockStyle] = useState<any>(blockStyleNeutral);
  const [blockDisabled, setBlockDisabled] = useState<boolean>(false);
  const [patientRefusedState, setPatientRefused] = useState<boolean>(false);
  /**
   * onPatientRefused NEEDS TO STORE PATIENT REFUSED
   */
  const onPatientRefused = () => {
    const patientRefusedValue = patientRefusedState
      ? !patientRefusedState
      : patientRefused;
    setUnitsValues({
      id,
      name,
      unitsValue: patientRefusedValue,
    });
    setPatientRefused(!patientRefusedState);
    // weird async setState as state not updated yet, if await setPatientRefused then state is updated
    return patientRefusedState
      ? setBlockStyle(blockStyleNeutral)
      : setBlockStyle(blockStyleSelected);
  };

  const commentStyleNeutral = {
    cursor: 'pointer',
    color: border,
  };

  const [commentStyle, setCommentStyle] = useState<any>(commentStyleNeutral);
  /**
   * onClickComment open the multi text field and highlights name
   */
  const onClickComment = () => {
    setCommentOpen(true);
  };

  const [multiTextInput, setMultiTextInput] = useState<string>('');

  /**
   * onCommentsChange storing COMMENTS
   */
  const onCommentsChange = (event: any) => {
    const targetValue = event.target.value;
    setMultiTextInput(targetValue);
    setCommentsValues({
      id,
      name,
      commentsValue: targetValue,
    });
  };
  /**
   * onCancelComment cancels COMMENTS
   */
  const onCancelComment = (event: any) => {
    setCommentOpen(false);
    setMultiTextInput('');
    setCommentsValues({
      id,
      name,
      commentsValue: '',
    });
  };

  const [textInput, setTextInput] = React.useState<string | undefined>(' ');

  /**
   * onTextValueChange storing text VALUES TEMP, RESP
   */
  const onTextValueChange = (event: any) => {
    const targetValue = event.target.value;
    // trim because of white space to get shrink labels
    const targetValueTrim = targetValue.trim();
    setTextInput(targetValueTrim);

    // patient refused logic icon disabled or not
    setBlockStyle(blockStyleDisabled);
    setBlockDisabled(true);

    setValues({
      id,
      name,
      value: targetValueTrim,
      defaultUnit: defaultUnitName,
    });
  };

  /**
   * onSelectValueChange is storing DROPDOWN VALUES KPS PPS
   */
  const onSelectValueChange = (event: any) => {
    const targetValue = event.target.value;
    const targetName = event.target.name;
    setValues({
      id,
      name: targetName,
      value: targetValue,
    });
    setUnitsValues({
      id,
      name,
      unitsValue: defaultUnitName,
    });

    // patient refused logic icon disabled or not
    setBlockStyle(blockStyleDisabled);
    setBlockDisabled(true);
  };

  const [unitsValue, setUnitValue] = useState<string>(defaultUnitName);

  /**
   * onUnitChange is storing UNIT change
   */
  const onUnitChange = (event: any) => {
    const targetValue = event.target.value;
    const targetName = event.target.name;
    setUnitValue(targetValue);
    setUnitsValues({
      id,
      name: targetName,
      unitsValue: targetValue,
    });
  };

  /**
   * Gets the validation error for the field
   * @param {IErrors} err - All the errors from the form
   * @returns {string[]} - The validation error
   */
  const getError = (err: IErrors): string => (err ? err[name] : '');

  /**
   * Gets the inline styles for editor
   * @param {IErrors} err - All the errors from the form
   * @returns {any} - The style object
   */
  const getEditorStyle = (err: IErrors): any =>
    getError(err)
      ? {
          borderColor: 'red',
        }
      : {};

  return (
    <FormContext.Consumer>
      {(context: IFormContext) => (
        <div className={classes.formGroup}>
          {readonly === 'false' ? (
            <Fragment>
              <div className={classes.formGroupUnits}>
                <FormControl
                  required={required}
                  disabled={disabled}
                  className={classes.formControl}
                >
                  {/* INPUT select for PPS, KPS ... */}
                  {options && options.length ? (
                    <Fragment>
                      <InputLabel id={name} className={classes.formLabel}>
                        {name}
                      </InputLabel>
                      <Select
                        className={classes.selectEmpty}
                        // value always undefined
                        value={value}
                        name={name}
                        onChange={onSelectValueChange}
                        inputProps={{
                          'aria-label': 'measure selection',
                        }}
                        onBlur={() => validate(name)}
                        style={getEditorStyle(errors)}
                        // default value throwing error
                        defaultValue="  "
                      >
                        <MenuItem
                          value=""
                          disabled
                          className={classes.disabledItem}
                        >
                          <em>{name}</em>
                        </MenuItem>

                        {options &&
                          options.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              <Tooltip title={option.displayvalue}>
                                <Typography noWrap className={classes.typo}>
                                  {option.value}
                                </Typography>
                              </Tooltip>
                            </MenuItem>
                          ))}
                      </Select>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {/* INPUT TEXT for resp, temp ... */}
                      <TextField
                        id={`${name}-text`}
                        // autoFocus={name === measuresNames[0]}
                        label={name}
                        value={textInput}
                        onChange={onTextValueChange}
                        autoComplete="off"
                        InputLabelProps={{
                          classes: {
                            formControl: classes.textLabel,
                          },
                        }}
                      />
                    </Fragment>
                  )}
                </FormControl>
                {/* UNITS */}
                {units && units.length && (
                  <FormControl
                    required={required}
                    disabled={disabled}
                    className={classes.unitsControl}
                  >
                    <InputLabel id={name} className={classes.formLabel}>
                      units
                    </InputLabel>
                    <Select
                      className={classes.selectEmpty}
                      value={unitsValue}
                      name={name}
                      onChange={onUnitChange}
                      inputProps={{
                        'aria-label': 'measure units',
                      }}
                      style={getEditorStyle(errors)}
                      defaultValue={defaultUnitName}
                    >
                      <MenuItem
                        value=""
                        disabled
                        className={classes.disabledItem}
                      >
                        <em>{name}</em>
                      </MenuItem>

                      {units &&
                        units.map((option: any) => (
                          <MenuItem
                            key={option.unitshortname}
                            value={option.unitshortname}
                          >
                            {option.unitshortname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                <ActionIcon
                  title="Patient refused"
                  style={blockStyle}
                  Icon={BlockIcon}
                  onClick={onPatientRefused}
                  disabled={blockDisabled}
                />
              </div>
              <div className={classes.formGroupComment}>
                {commentOpen ? (
                  <TextField
                    id={`${name}-multi`}
                    label={`${name} comments`}
                    multiline
                    rows={3}
                    value={multiTextInput}
                    onChange={onCommentsChange}
                    InputLabelProps={{
                      classes: {
                        formControl: classes.textLabel,
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          classes={{
                            root: classes.adorn,
                          }}
                        >
                          <Tooltip title="Clear comments">
                            <HighlightOffIcon
                              style={clearIconStyle}
                              onClick={onCancelComment}
                            />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                ) : (
                  <ActionIcon
                    title="Add comments"
                    style={commentStyle}
                    Icon={AddCommentIcon}
                    onClick={onClickComment}
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      )}
    </FormContext.Consumer>
  );
};
