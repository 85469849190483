import React, { useRef, useState, useEffect, useContext } from 'react';
import { Watermark, WidgetHeader, ActionIcon } from '@mummssoftware/common-ui';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import { LicenseManager } from 'ag-grid-enterprise';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AllMeasuresDefined } from '../MeasuresContext/utils/type';
import { Context } from '../../context';
import MeasuresTable from './MeasuresTable';
import {
  MainWrap,
  MeasuresBody,
  MeasuresTableWrapper,
  MeasuresTableDiv,
  GoBackDiv,
} from '../../components/layout/WrapperDiv';
import MeasuresSmallGraph from './MeasuresSmallGraph';
import MeasuresFullGraph from './MeasuresFullGraph';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import { MeasuresMenu } from './MeasuresMenu';
import {
  SHOW_TABLE,
  SET_MEASURE_NAME,
  tableHeightRatio,
  MEASURE_TYPE,
  SET_NEW_MEASURE,
  SET_MEASURE_SELECTED,
  highChartYAxistWidth,
  scoresType,
} from '../MeasuresContext/utils/constants';
import defaults from '../../translations/en.json';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

type MeasureProps = {
  patient: mumms.patient;
  currentUser: mumms.User;
  allMeasures: AllMeasuresDefined;
  noWidgetHeader?: boolean;
  onClick: () => void;
} & typeof RouteProps;

export const MeasuresComponent = React.memo(
  ({
    patient,
    onClick,
    currentUser,
    allMeasures,
    noWidgetHeader,
  }: MeasureProps) => {
    const {
      props: {
        grid: gridTheme = {
          className: '',
        },
        dashboard: { smallWidgetHeight, fullSizeHeight },
      },
    } = useTheme();
    const { appTitle, smallWidget, watermark, isPatientActive } = useContext(
      Context,
    );
    const { dispatch, showTable, newMeasure } = useContext(MeasuresContext);
    const { measuresTypes, allMeasuresTypes } = allMeasures;
    const notInUseMeasures = measuresTypes.filter(
      (vital) => vital.isinuse === 'false' && vital.readonly === 'false',
    );
    const measureType = localStorage.getItem(MEASURE_TYPE);

    const isScores = measureType === scoresType;

    // scores logic is different, always show the plus button
    const hasNotInUseMeasures = !!notInUseMeasures.length && !isScores;
    const measuresTypeNames = Object.keys(allMeasuresTypes);

    // NOTE this is to get the last column of the table rendered dynamically with correct size
    const targetRef = useRef(null);
    const [dimensions, setDimensions] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      if (targetRef.current) {
        setDimensions({
          width: (targetRef.current as any).offsetWidth as any,
          height: (targetRef.current as any).offsetHeight as any,
        });
      }
    }, []);

    const todaydate: Date | number = Date.now();

    const goBack = () => {
      // reset all
      dispatch({
        type: SHOW_TABLE,
        payload: false,
      });
      dispatch({
        type: SET_MEASURE_NAME,
        payload: null,
      });
      dispatch({
        type: SET_NEW_MEASURE,
        payload: false,
      });
      dispatch({
        type: SET_MEASURE_SELECTED,
        payload: null,
      });
    };

    const showPlusIcon = (
      _hasNotInUseMeasures: boolean,
      _smallWidget?: boolean,
      // eslint-disable-next-line consistent-return
    ) => {
      if (!_smallWidget && isPatientActive) {
        if (_hasNotInUseMeasures) {
          return <MeasuresMenu addIcon measures={notInUseMeasures} />;
        }
      }
    };

    const displayTableHeight = dimensions.height * tableHeightRatio;
    // min height as we need to be able to see 1 row display and the dropdown of 95 px
    const minDisplayTableHeight = 150;
    const tableHeight = Math.max(displayTableHeight, minDisplayTableHeight);
    // todo adjust this
    const tableFullChartWidth = 0.97;

    const displayTableWidth =
      dimensions.width * tableFullChartWidth - highChartYAxistWidth;

    return (
      <MainWrap id="mainwrap">
        {!noWidgetHeader && (
          <WidgetHeader
            appTitle={appTitle?.toLocaleUpperCase() || defaults.measures}
            versionInfo={`Measures v${process.env.REACT_APP_MEASURES_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
            smallWidget={smallWidget}
            onClick={onClick}
          >
            <MeasuresMenu
              measures={measuresTypeNames as mumms.measureTypeNames[]}
            />
            {showPlusIcon(hasNotInUseMeasures, smallWidget)}
          </WidgetHeader>
        )}

        <MeasuresBody
          id="measuresbody"
          ref={targetRef}
          smallWidget={smallWidget}
          smallWidgetHeight={smallWidgetHeight}
          fullSizeHeight={fullSizeHeight}
        >
          {watermark && watermark.show ? (
            <Watermark
              readOnly={watermark.readOnly}
              smallWidget={smallWidget}
            />
          ) : null}
          {showTable && (
            <GoBackDiv id="goback">
              <ActionIcon
                title="Go back"
                style={{ margin: 0 }}
                Icon={ArrowBackIcon}
                onClick={goBack}
              />
            </GoBackDiv>
          )}
          {smallWidget && (
            <MeasuresSmallGraph
              episodes={patient.episodes}
              allMeasures={allMeasures}
              smallWidget={smallWidget}
              height={dimensions.height}
              siteTimezone={currentUser.siteTimezone}
            />
          )}
          {!smallWidget && !newMeasure && (
            <MeasuresFullGraph
              episodes={patient.episodes}
              allMeasures={allMeasures}
              smallWidget={smallWidget}
              height={dimensions.height}
              siteTimezone={currentUser.siteTimezone}
            />
          )}
          {showTable && (
            <MeasuresTableDiv id="table-display">
              <MeasuresTableWrapper
                width={displayTableWidth}
                height={tableHeight}
                className={`${gridTheme.className} light`}
              >
                <MeasuresTable
                  patient={patient}
                  currentUser={currentUser}
                  allMeasures={allMeasures}
                  postdate={formatPostDate(todaydate)}
                  width={displayTableWidth}
                  isPatientActive={isPatientActive}
                />
              </MeasuresTableWrapper>
            </MeasuresTableDiv>
          )}
        </MeasuresBody>
      </MainWrap>
    );
  },
);

export default MeasuresComponent;
