/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cloneDeep, flatten } from 'lodash-es';
import {
  MeasuresPrintDiv,
  PrintTextDiv,
  NamesWrapper,
  ChartWrapper,
  ScoreName,
  SparklineDiv,
  MeasureNameDiv,
  MeasuresWrapper,
  PageBreak,
} from '../../components/layout/WrapperDiv';
import {
  getMeasuresForGraph,
  getTypesByName,
  getDateRange,
} from '../MeasuresContext/utils/utils';
import { highChartTimelineHeight } from '../MeasuresContext/utils/constants';
import { chartOptions, fastCategory } from '../Measures/data';
import { AllPrintMeasuresDefined } from '../MeasuresContext/utils/type';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type MeasuresGraphPrintProps = {
  allMeasures: AllPrintMeasuresDefined;
};

export const MeasuresGraphPrint = React.memo(
  ({ allMeasures }: MeasuresGraphPrintProps) => {
    const chartHeight = 90;
    const { measures, allMeasuresTypes } = allMeasures;
    // black color for printing, issue with the graph not allowing print color 
    const printColor = null;

    const getNamesUI = (chartmeasures: mumms.formattedAllMeasures) => {
      if (chartmeasures && Object.keys(chartmeasures).length) {
        return Object.keys(chartmeasures).reduce((seed: any, name: any) => {
          seed.push(getSingleNameUI(name));
          return seed;
        }, []);
      }
      return null;
    };
    const getSingleNameUI = (name: string) => (
      <SparklineDiv key={name} height={chartHeight}>
        <MeasureNameDiv print>
          <ScoreName>{name.toUpperCase()}</ScoreName>
        </MeasureNameDiv>
      </SparklineDiv>
    );
    const getSingleChartUI = (
      nameType: mumms.measureTypeNames,
      chartdata: mumms.formattedAllMeasures,
      chartmeasures: mumms.measure[],
      name: mumms.measureShortNames,
      index: number,
    ) => {
      const numberMeasures = Object.keys(chartdata).length;
      const measuresdate: any = Object.values(chartdata);
      const allMeasuresFordate: any = flatten(measuresdate);
      const dateRange = getDateRange(allMeasuresFordate);
      const typeNames: Record<
        mumms.measureShortNames,
        mumms.measureType
      > = getTypesByName((allMeasuresTypes as any)[nameType]);

      const show2ndData = typeNames[name].isgraphwithsecondaryval === 'true';
      const options = cloneDeep(chartOptions(printColor));
      options.chart.height = chartHeight;
      options.chart.marginLeft = 60;
      (options.yAxis as any).visible = true;

      let measureValues: any;

      // FAST category
      if (name === 'fast') {
        (options.yAxis as any).categories = fastCategory;
        measureValues = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value',
          'fast',
        );
      } else {
        measureValues = getMeasuresForGraph(chartmeasures, dateRange, 'value');
      }

      (options.xAxis as any).plotBands = null;

      /** only display last timeline */
      // TODO not working
      if (
        index === numberMeasures - 1 &&
        measureValues &&
        measureValues.length
      ) {
        (options.xAxis as any).visible = true;
        options.chart.height = chartHeight + highChartTimelineHeight;
      }

      const printColorLine = '#000000';

      const firstSerie = {
        id: name,
        data: measureValues,
        name: '',
        type: 'line',
        color: printColorLine,
        fillOpacity: 0.3,
      };
      options.series = [firstSerie];

      if (show2ndData) {
        // backend value2 to graph 2nd value
        const measureValues2 = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value2',
        );
        const secondSerie = {
          id: 'other',
          data: measureValues2,
          name: '',
          type: 'line',
          color: printColorLine,
          fillOpacity: 0.3,
        };
        options.series = [firstSerie, secondSerie];
      }

      // had put a key in hoping it would trigger a HighChart re-render
      return (
        <HighchartsReact
          key={index}
          highcharts={Highcharts}
          options={options as any}
        />
      );
    };
    const getChartUI = (
      _nameType: mumms.measureTypeNames,
      chartmeasures: mumms.formattedAllMeasures,
    ) => {
      if (chartmeasures && Object.keys(chartmeasures).length) {
        return Object.entries(chartmeasures).reduce(
          (seed: any, measure: any, index: number) => {
            const name = measure[0];
            const data = measure[1];
            seed.push(
              getSingleChartUI(_nameType, chartmeasures, data, name, index),
            );
            return seed;
          },
          [],
        );
      }
      return null;
    };
    const getGraphsByCategory = (
      _name: mumms.measureTypeNames,
      _measures: mumms.formattedAllMeasures,
    ) => (
      <MeasuresWrapper id="graph-area" print>
        <NamesWrapper print id="names">
          {getNamesUI(_measures as any)}
        </NamesWrapper>
        <ChartWrapper>{getChartUI(_name, _measures)}</ChartWrapper>
      </MeasuresWrapper>
    );

    const getGraphsPrint = (_measures: mumms.measureByType) => {
      const { length } = Object.keys(_measures);
      return Object.entries(_measures).map((val, i) => {
        const typename = val[0];
        const typevalues = val[1];
        return (
          <React.Fragment key={typename}>
            <MeasuresPrintDiv>
              <PrintTextDiv key={`${typename}`}>
                {typename}
              </PrintTextDiv>
              {getGraphsByCategory(
                typename as mumms.measureTypeNames,
                typevalues as mumms.formattedAllMeasures,
              )}
            </MeasuresPrintDiv>
            {i === length - 1 ? null : <PageBreak />}
          </React.Fragment>
        );
      });
    };

    const getMeasuresGraphPrint = (_measures: mumms.measureByType) => (
      <MeasuresPrintDiv>{getGraphsPrint(_measures)}</MeasuresPrintDiv>
    );

    return <React.Fragment>{getMeasuresGraphPrint(measures)}</React.Fragment>;
  },
);

export default MeasuresGraphPrint;
