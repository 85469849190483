import { MDCRipple } from '@material/ripple/index';
import '@material/ripple/dist/mdc.ripple.min.css';

export function setParseMissingKeyHandler<T, K extends keyof T>(
  labels: T,
  defaultNS: K,
) {
  // this sets the missing key criteria-met and yes/no
  return function parseMissingKeyHandler(key: string): string {
    if (key.indexOf('.') > 0) {
      const split = key.split('.')[1] as keyof T[K];
      const translated = labels[defaultNS][split];
      if (typeof translated === 'string') {
        return translated;
      }
    }
    return key;
  };
}

export function attachRippleEffect() {
  const formioEl = document.querySelector('.formio');
  function attachRipple(e: Event | KeyboardEvent) {
    /* istanbul ignore else */
    if (
      (!('code' in e) && e.target instanceof Element) ||
      ('code' in e &&
        (e.code === 'Space' || e.code === 'Enter') &&
        e.target instanceof Element)
    ) {
      const button = e.target.closest('.btn');
      if (button && !button.classList.contains('mdc-ripple-surface')) {
        button.classList.add(
          'mdc-ripple-surface',
          'mdc-ripple-surface--primary',
        );
        const ripple = MDCRipple.attachTo(button);
        ripple.activate();
        ripple.deactivate();
      }
    }
  }
  if (formioEl) {
    formioEl.addEventListener('keydown', attachRipple);
    formioEl.addEventListener('click', attachRipple);
  }
  return formioEl;
}

export * from './internal';
export * from './formUtils';
