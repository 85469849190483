/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Loader } from '@mummssoftware/common-ui';
import moment from 'moment';
import { GlobalContext } from '../../context';
import { CPPContext } from '../Context/CPPContext';
import { formatPostDate } from './utils';
import { SET_PATIENT_MANIFEST_ID } from '../Context/constants';

type QuestionnaireEngineProps = {
  formName: string;
};

export const QuestionnaireEngine: React.FC<QuestionnaireEngineProps> = ({
  formName,
}: QuestionnaireEngineProps) => {
  const [questionnaireHtml, setQuestionnaireHtml] = useState<
    string | undefined
  >(undefined);
  const [engineStarted, startEngine] = useState<boolean>(false);
  const { patient, currentUser, keycloak, observation } = useContext(
    GlobalContext,
  );
  const { dispatch } = useContext(CPPContext);

  let iframeWindow: HTMLIFrameElement | null;
  const firm = currentUser.hbcFirm;
  const currentSite = currentUser?.siteList?.filter(
    (s) => s.id === currentUser.siteId,
  )[0];
  const location = currentSite?.hbcLocation;

  const attemptFetch = async (url: string) => {
    const headers = new Headers();
    if (typeof keycloak?.token !== 'string') {
      keycloak.logout();
      return;
    }
    headers.set('keycloakToken', keycloak.token);

    // eslint-disable-next-line consistent-return
    return fetch(`${url}`, {
      headers,
      credentials: 'include',
    });
  };

  const fetchQuestionnaireEngine = async (src: string) => {
    let qhString = '';
    const getHtml = async () => {
      let res = (await attemptFetch(src)) as Response;
      if (res.status === 403) {
        res = (await attemptFetch(src)) as Response;
      }
      if (!res.ok) {
        return '';
      }
      return res.text();
    };
    qhString = await getHtml();
    qhString = qhString
      .replaceAll('src="/', `src="${process.env.REACT_APP_HBC_BASE_URL}/`)
      .replaceAll('href="/', `href="${process.env.REACT_APP_HBC_BASE_URL}/`);
    setQuestionnaireHtml(qhString);
  };

  window.addEventListener('message', (event) => {
    if (
      typeof event.data === 'object' &&
      event.data['questionnaire-engine-manifest-qfile']
    ) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      const { data } = event;

      const tz = moment.tz.guess();
      const submissionForm = {
        id: '',
        createdatetime: formatPostDate(Date.now()),
        createpersonid: currentUser?.userId?.toString() as string,
        createpersonnumber: currentUser?.personNumber?.toString() as string,
        modifieddatetime: formatPostDate(Date.now()),
        modifiedpersonid: currentUser?.userId?.toString() as string,
        modifiedpersonnumber: currentUser?.personNumber?.toString() as string,
        formname: data.formname as string,
        manifesttype: 'Questionnaire',
        patientnumber: patient?.patientNumber as string,
        siteid: currentUser?.siteId.toString() as string,
        source: 'questionnaireEngine',
        sourcekey: data.sourcekey as string,
        timezone: tz as string,
        url: data.url as string,
        urltype: 'clinical_qfile',
        responsedata: data.responsesBlob,
      };

      observation
        .saveManifest(submissionForm)
        .then((response) => {
          if (!(response instanceof Response)) {
            dispatch({
              type: SET_PATIENT_MANIFEST_ID,
              payload: response.id,
            });
          }
        })
        .catch((e: Error) => {
          console.error(e);
        });
    }
  });

  useEffect(() => {
    if (!engineStarted) {
      startEngine(true);
      localStorage.setItem('token', keycloak.token as string);
      fetchQuestionnaireEngine(
        `${process.env.REACT_APP_HBC_BASE_URL}/m2/questionnaireEngine/${firm}/${location}/${patient?.patientNumber}?id=${formName}&visitID=&sticky=no&loggedInWithSSO=true`,
      );
    }
  });

  useLayoutEffect(() => {
    if (iframeWindow != null) {
      const currentRefreshFunc = keycloak.onAuthRefreshSuccess;
      keycloak.onAuthRefreshSuccess = () => {
        if (currentRefreshFunc) {
          currentRefreshFunc();
        }
        if (iframeWindow && iframeWindow.contentWindow) {
          const message = { keycloakToken: keycloak.token };
          iframeWindow.contentWindow.postMessage(message, '*');
        }
      };
    }
  });

  if (!questionnaireHtml) {
    return <Loader />;
  }

  return (
    <iframe
      ref={(e) => {
        iframeWindow = e;
      }}
      title="questionnaireEngine"
      srcDoc={questionnaireHtml}
      name={process.env.REACT_APP_HBC_BASE_URL}
      style={{
        width: '100%',
        minHeight: '70vh',
      }}
    />
  );
};

export default QuestionnaireEngine;
