import { defineMessages } from 'react-intl';
export default defineMessages({
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading'
  },
  errorText: {
    id: 'common.errorText',
    defaultMessage: 'Issue fetching data '
  }
});