import React, { useContext } from 'react';
import { GridApi, GridOptions, CellClickedEvent } from 'ag-grid-community';
import { useTheme } from '@material-ui/core/styles';
import { DialogBox } from '@mummssoftware/common-ui';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { CarePlanProblemsTableWrapper } from '../../components/layout/WrapperDiv';
import { LastUpdatedPlanCellRenderer } from './CellRenderer';
import { GlobalContext } from '../../context';
import { CPPContext } from '../Context/CPPContext';
import { SET_FORM_ID, SET_PATIENT_MANIFEST_ID } from '../Context/constants';
import { QuestionnaireEngine } from './QuestionnaireEngine';
import { formatPostDate } from './utils';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);
interface CarePlanProblemsTableProps {
  viewModePrint?: boolean;
}

const frameworkComponents = {
  lastUpdatedPlanCellRenderer: LastUpdatedPlanCellRenderer,
};

export const CarePlanTable = ({
  viewModePrint,
}: CarePlanProblemsTableProps) => {
  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;
  const {
    patientCarePlanProblem,
    keycloak,
    patient,
    agency,
    smallWidget,
    currentUser,
    addPatientCarePlanProblem,
  } = useContext(GlobalContext);
  const { formId, dispatch, patientManifestId } = useContext(CPPContext);
  const formName = formId?.formName;
  const formKey = formId?.formKey;

  const { patientNumber, siteId } = patient;
  const { personNumber } = currentUser;

  const addCarePlanProblem = (patientmanifestid: string) => {
    const carePlan = {
      careplanproblemshortname: formName,
      patientnumber: patientNumber,
      createpersonnumber: personNumber,
      siteid: siteId.toString(),
      createdatetime: formatPostDate(Date.now()),
      patientmanifestid,
    };
    addPatientCarePlanProblem(patientNumber, carePlan);
  };

  const closeForm = () => {
    // if patientManifestId then addCarePlanProblem
    if (patientManifestId) {
      addCarePlanProblem(patientManifestId);
    }
    setTimeout(() => {
      dispatch({
        type: SET_FORM_ID,
        payload: null,
      });
      if (patientManifestId) {
        dispatch({
          type: SET_PATIENT_MANIFEST_ID,
          payload: null,
        });
      }
    }, 1000);
  };

  const attemptFetch = async (url: string) => {
    const headers = new Headers();
    if (typeof keycloak?.token !== 'string') {
      (keycloak as any).logout();
      return;
    }
    const clinicalServerFetch = url.includes(
      process.env.REACT_APP_HBC_BASE_URL as string,
    );

    // clinical server takes keycloak as separate header,
    // everything else uses it as a bearer token
    if (clinicalServerFetch) {
      headers.set('keycloakToken', keycloak.token);
    } else {
      headers.set('Authorization', `Bearer ${keycloak.token}`);
    }

    // eslint-disable-next-line consistent-return
    return fetch(`${url}`, {
      headers,
      credentials: clinicalServerFetch ? 'include' : 'same-origin',
    });
  };

  const fetchQFromBucket = async (qfile: string) => {
    const url = `${process.env.REACT_APP_BUCKET_GATEWAY_URL}/bucket/${agency}/patient/${patientNumber}/${qfile}`;
    try {
      const response = (await attemptFetch(url)) as Response;
      if (!response.ok) {
        return null;
      }
      const html = await response.text();
      return html;
    } catch (e) {
      return null;
    }
  };

  const columnTableDefs = [
    {
      headerName: 'Choose Care Plan',
      field: 'careplanproblemname',
      cellClass: 'no-border',
      suppressMenu: true,
    },
    {
      headerName: '',
      field: 'modifieddate',
      cellClass: 'no-border',
      headerClass: 'header-center',
      cellRendererFramework: LastUpdatedPlanCellRenderer,
      suppressMenu: true,
    },
  ];

  const gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
    },
    columnDefs: columnTableDefs,
    onCellClicked: (event: CellClickedEvent) => {
      const {
        patientmanifesturl,
        careplanproblemdescription,
        careplanproblemshortname,
      } = event.data;
      // get the url from bucket to display the latest HTML
      // fetchQFromBucket(patientmanifesturl).then((res) => {
      //   setHtml(res);
      // });
      dispatch({
        type: SET_FORM_ID,
        payload: {
          formKey: careplanproblemdescription,
          formName: careplanproblemshortname,
        },
      });
    },
    onGridReady: (params: any) => {
      const gridApi: GridApi = params.api;
      gridApi.sizeColumnsToFit();
      if (viewModePrint) {
        gridOptions.domLayout = 'print';
      }
    },
  };

  return (
    <CarePlanProblemsTableWrapper
      id="care-plan-table-wrapper"
      className={`${gridTheme.className} light hover`}
      smallWidget={smallWidget}
    >
      <DialogBox
        title={formName}
        disableBackdropClick
        open={!!formKey}
        handleClick={closeForm}
        draggable
        hideBackdrop
      >
        <QuestionnaireEngine formName={formKey as string} />
      </DialogBox>
      <AgGridReact
        gridOptions={gridOptions}
        rowData={patientCarePlanProblem || []}
        rowHeight={gridTheme.rowHeight}
        headerHeight={gridTheme.headerHeight}
        frameworkComponents={frameworkComponents}
      />
      {/* {htmlState && (
          <div
            id="cpp"
            style={{ height: '100%' }}
            dangerouslySetInnerHTML={{ __html: htmlState }}
          />
        )} */}
    </CarePlanProblemsTableWrapper>
  );
};

export default CarePlanTable;
