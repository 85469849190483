/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 */
/* polyfills for locale-data */
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';
/* eslint-disable global-require */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-pluralrules/dist/locale-data/es'); // Add locale data for es
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for de
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es'); // Add locale data for de
}
/* eslint-enable */

export const DEFAULT_LOCALE = navigator.language
  ? navigator.language.split(/[-_]/)[0]
  : 'en';

// prettier-ignore
export const appLocales = [
  'en',
  'es',
];

export const localeGridSettings = {
  en: {
    nameColsDesktop: {
      width: 150,
    },
    pId: {
      width: 105,
    },
    certDueDate: {
      width: 140,
    },
    daysUntilDue: {
      width: 150,
    },
    verbal: {
      width: 125,
    },
  },
  es: {
    nameColsDesktop: {
      width: 162,
    },
    pId: {
      width: 130,
    },
    certDueDate: {
      width: 145,
    },
    daysUntilDue: {
      width: 150,
    },
    verbal: {
      width: 138,
    },
  },
};

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
};

const longDateFormat = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

const medDateFormat = {
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
};

export const i18nFormats = {
  date: {
    longUTC: {
      ...longDateFormat,
      timeZone: 'UTC',
    },
    long: longDateFormat,
    medUTC: {
      ...medDateFormat,
      timeZone: 'UTC',
    },
    med: medDateFormat,
  },
};
