import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ScoresNumber } from '../layout/WrapperDiv';
import { MeasuresContext } from '../../containers/MeasuresContext/MeasuresContextProvider';
import {
  observation,
  SET_SCORES,
} from '../../containers/MeasuresContext/utils/constants';

// todo theme color
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 34,
    backgroundColor: (theme.palette as any).scores.color,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
  focused: {
    padding: 0,
    minWidth: 34,
    backgroundColor: (theme.palette as any).graph.graphColor,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
}));

type ScoresNumbersProps = {
  name: mumms.measureShortNames;
  scoresCategory: mumms.ScoresCategory;
};

export const ScoresNumbers = React.memo(
  ({ name, scoresCategory }: ScoresNumbersProps) => {
    const { dispatch, methodSelected } = useContext(MeasuresContext);
    const isByObservationMethod = methodSelected === observation;
    const isDisabled =
      methodSelected === null ||
      (isByObservationMethod && scoresCategory[name] === 'subjective');

    const scores = [...Array(11).keys()];
    const classes = useStyles();
    const scoresClassState = scores.reduce((seed, curr) => {
      seed[curr] = classes.root;
      return seed;
    }, {} as any);
    const [classState, setClassState] = useState(scoresClassState);
    const onClick = (i: any) => {
      setClassState({ ...scoresClassState, [i]: classes.focused });
      dispatch({
        type: SET_SCORES,
        payload: { name, score: i },
      });
    };
    // reset color button
    useEffect(() => {
      setClassState(scoresClassState);
    }, [methodSelected]);

    return (
      <ScoresNumber>
        {scores.map((i) => (
          <Button
            key={i}
            variant="contained"
            className={classState[i]}
            onClick={() => onClick(i)}
            disabled={isDisabled}
          >
            {i}
          </Button>
        ))}
      </ScoresNumber>
    );
  },
);
