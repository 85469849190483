import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useReducer,
} from 'react';
import produce from 'immer';
import { formatPhysicianName } from '@mummssoftware/utils/formatters';
import { filterByKey } from '@mummssoftware/utils/data';
import {
  ATTENDING_FORM,
  CERTIFYING_FORM,
  RESET_VALUES,
  SET_DOCTORS,
  SET_DOCTOR,
  SET_DATE,
  SET_FORM_TYPE,
  SET_PICKER_STATE,
  SET_PIM,
  SET_CLEARSCRIPTS,
  SET_CERT_START_DATE,
  SET_VERBAL_DATE_LIMIT,
  SET_LATE_CERT,
  SET_EMBEDDED,
  SET_DIMENSIONS,
  SET_VERBAL_DATE,
  SET_BUTTON_CLICKED,
  SET_SMALL_WIDGET,
} from './constants';

const initCertContext = {
  pim: null,
  setDoctors: null,
  formType: CERTIFYING_FORM,
  doctors: { [ATTENDING_FORM]: [], [CERTIFYING_FORM]: [] },
};

const defaultDoctor = null;

const createInitialState = (
  pim,
  clearScripts,
  embedded,
  smallWidget,
  watermark,
) => ({
  pim,
  clearScripts,
  embedded,
  smallWidget,
  formType: CERTIFYING_FORM,
  doctors: { [ATTENDING_FORM]: [], [CERTIFYING_FORM]: [] },
  selectedDoctor: null,
  selectedDate: null,
  pickerDisabled: false,
  certStartDate: null,
  verbalDateLimit: null,
  lateCert: false,
  dimensions: null,
  verbalDate: null,
  buttonClicked: null,
  watermark,
});

const CertContextReducer = (draft, action) => {
  switch (action.type) {
    case RESET_VALUES:
      // @note: picker is disabled when we have a programmatically set doctor.
      if (!draft.pickerDisabled) {
        draft.selectedDoctor = defaultDoctor;
      }

      break;
    case SET_DOCTORS:
      draft.selectedDoctor = defaultDoctor;
      draft.doctors = action.payload;
      break;
    case SET_DOCTOR:
      if (action.disablePicker) {
        draft.pickerDisabled = true;
      }
      draft.selectedDoctor = action.payload;
      break;
    case SET_DATE:
      draft.selectedDate = action.payload;
      break;
    case SET_FORM_TYPE:
      draft.formType = action.payload;
      break;
    case SET_PICKER_STATE:
      draft.pickerDisabled = action.payload;
      break;
    case SET_PIM:
      draft.pim = action.payload;
      break;
    case SET_CLEARSCRIPTS:
      draft.clearScripts = action.payload;
      break;
    case SET_CERT_START_DATE:
      draft.certStartDate = action.payload;
      break;
    case SET_VERBAL_DATE_LIMIT:
      draft.verbalDateLimit = action.payload;
      break;
    case SET_LATE_CERT:
      draft.lateCert = action.payload;
      break;
    case SET_EMBEDDED:
      draft.embedded = action.payload;
      break;
    case SET_SMALL_WIDGET:
      draft.smallWidget = action.payload;
      break;
    case SET_DIMENSIONS:
      draft.dimensions = action.payload;
      break;
    case SET_VERBAL_DATE:
      draft.verbalDate = action.payload;
      break;
    case SET_BUTTON_CLICKED:
      draft.buttonClicked = action.payload;
      break;
    default:
  }
};

const curriedContextReducer = produce(CertContextReducer);

const mapDoctors = (doctorArray) =>
  doctorArray?.map((doctor) => ({
    label: formatPhysicianName(
      '',
      doctor.firstName,
      doctor.lastName,
      doctor.credentials || '',
    ),
    value: doctor.id,
  }));

export const CertContext = createContext(initCertContext);
export const CertDispatchContext = createContext();
export const CertContextConsumer = CertContext.Consumer;
export const CertDispatchContextConsumer = CertDispatchContext.Consumer;

/**
 *
 * @param {{ children: React.ReactChildren, pim?: import('@mummssoftware/utils').Pim }} props
 */
export const CertContextProvider = ({
  children,
  pim,
  clearScripts,
  embedded,
  smallWidget,
}) => {
  const isMounted = useRef(false);
  const [certState, dispatch] = useReducer(
    curriedContextReducer,
    createInitialState(pim, clearScripts, embedded, smallWidget),
  );

  const setDoctors = useCallback(
    (site) => {
      async function fetchDoctors(siteId) {
        if (pim && typeof pim.getAllDoctors === 'function') {
          const doctors = await pim
            .getPIMUsersByRoles(siteId, '-9', '-51', '-66', '-10')
            .then((res) => pim.flattenAndSortByKey(res, 'personNumber'));
          const employees = await pim.getAllEmployees(siteId);
          if (isMounted.current) {
            const certDoctors =
              Array.isArray(doctors) && Array.isArray(employees)
                ? mapDoctors(filterByKey(doctors, employees, 'personNumber'))
                : [];
            dispatch({
              type: SET_DOCTORS,
              payload: {
                [ATTENDING_FORM]: mapDoctors(doctors),
                [CERTIFYING_FORM]: certDoctors,
              },
            });
          }
        }
      }
      fetchDoctors(site);
    },
    [pim],
  );
  useEffect(() => {
    if (pim !== certState.pim) {
      dispatch({ type: SET_PIM, payload: pim });
    }
  }, [certState.pim, pim]);

  useEffect(() => {
    if (clearScripts !== certState.clearScripts) {
      dispatch({ type: SET_CLEARSCRIPTS, payload: clearScripts });
    }
  }, [certState.clearScripts, clearScripts]);

  useEffect(() => {
    if (embedded !== certState.embedded) {
      dispatch({ type: SET_EMBEDDED, payload: embedded });
    }
  }, [certState.embedded, embedded]);

  useEffect(() => {
    if (smallWidget !== certState.smallWidget) {
      dispatch({ type: SET_SMALL_WIDGET, payload: smallWidget });
    }
  }, [certState.smallWidget, smallWidget]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const DispatchValue = useMemo(() => ({ dispatch, setDoctors }), [
    dispatch,
    setDoctors,
  ]);
  return (
    <CertContext.Provider value={certState}>
      <CertDispatchContext.Provider value={DispatchValue}>
        {children}
      </CertDispatchContext.Provider>
    </CertContext.Provider>
  );
};

export function withCertContext(Component) {
  return function contextComponent(props) {
    return (
      <CertContextConsumer>
        {(context) => (
          <CertDispatchContextConsumer>
            {(dispatchContext) => (
              <Component {...props} {...context} {...dispatchContext} />
            )}
          </CertDispatchContextConsumer>
        )}
      </CertContextConsumer>
    );
  };
}
