import React, { useContext } from 'react';
import { HospiceItemSetContext } from '../../context';

export const MessageOverlay = () => {
  const { hospiceInfo } = useContext(HospiceItemSetContext);
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: '#9edbf7', height: '10%' }}
    >
      <span> {hospiceInfo.demographicsstatus}</span>
    </div>
  );
};

export default MessageOverlay;
