// those are the dashboard widget dimensions
export const widget1by1Height = 320;
export const widgetHeaderHeight = 35;
const headerMarginBottomHeight = 2;
const dashboardWidgetMargin = 4;
export const widgetHeaderOverhead = 35 + 2 + 4; // smallWidgetHeight = 279 px

export const smallWidgetHeight = widget1by1Height - widgetHeaderHeight - headerMarginBottomHeight - dashboardWidgetMargin;
export const fullSizeRatio = 0.95;
const topBarHeight = document && document.getElementById('topbar') && document.getElementById('topbar').clientHeight; // full size needs to account for new header, knows the size from dashboard

export const fullSizeHeight = fullSizeRatio * (window.innerHeight - topBarHeight) - widgetHeaderHeight;