import messages from '../CertMessages';

export const formatNarrativeVal = (formatMessage, takenBy) =>
  formatMessage(messages.narrativeOnFile, {
    recordedMsg: takenBy || '',
  });

export const getUpdatedClassName = (classes, updates, field) => {
  let className = classes.updatableField;
  if (updates.includes(field)) {
    className += ` ${classes.updatedField}`;
  }
  return className;
};
