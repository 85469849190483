import { css } from 'styled-components';
import { commonGlobal, flatPickrStyles, isMobile } from './common';
/**
 * Font Colors
 *  Widget Header Font: #FFFFFF
 *  Widget Chart Header Font: #008aff
 *  Read Only Font: #666666
 *  Editable/Current: #ee8f00
 *  Required/Late Font: #ff0000
 *  Hummingbird Logo Color: #FFFFFF
 *  Active Widget Nav Dock Title Font: #999999
 *  Inactive Widget Nav Dock Title Font: #666666
 *  Drawers Title Font: #666666
 *  Bottom Nav Drawer Title Font: #666666
 *
 * BG Colors
 *  Dance Floor BG: #e6e5e5
 *  Widget BG: #FFFFFF
 *  Widget Header/Drawer/Tool bar BG: #b4b4b4
 *  Very Bottom BG: #FFFFFF
 *  Widget Nav Dock BG: #FFFFFF
 *  Active Module BG color: #e6e5e5
 *  Inactive Module BG color: #b4b4b4
 *  Active Module Icon Color: #008aff
 *  Inactive Module Icon Color: #FFFFFF
 *  Bottom Secondary Nav BG: #e6e5e5
 */

export default {
  palette: {
    type: 'light',
    primary: {
      main: '#0086ff'
    },
    error: {
      main: '#ee0000'
    },
    grid: {
      fontColor: '#090909',
      headerFontColor: '#0086ff',
      accentBorderColor: '#adb8cc',
      headerBackground: '#b4b4b4',
      headerBorderColor: '#adb8cc',
      rowBorderColor: '#b3bdd2',
      rowHoverBackground: '#dcdcdc'
    },
    graph: {
      border: '#000000',
      graphColor: '#0086ff',
      secondGraphColor: '#FF6A00'
    },
    scores: {
      color: '#7DC1FF'
    },
    background: {
      default: '#798db0',
      paper: '#ffffff',
      header: '#f1f1f1',
      oddrow: '#e5efff ',
      dark: '#e5efff',
      dark1: '#EEF5FF',
      dark2: '#F1F7FF',
      dark3: '#ffffff'
    },
    facesheet: {
      textColor: '#000000'
    },
    text: {
      primary: '#222222',
      secondary: '#de7200',
      hint: '#444444',
      title: '#ffffff',
      notes: '#000000'
    },
    divider: '#ced7ea'
  },
  typography: {
    fontSize: 16
  },
  props: {
    grid: {
      headerHeight: 27,
      rowHeight: 27
    },
    header: {
      background: '#e6e4e5',
      rightTitle: {
        fontSize: 18,
        fontWeight: 'initial'
      },
      leftTitle: {
        fontSize: 19,
        fontWeight: 100
      },
      headerHeight: '40px'
    },
    iconStroke: '#e6e5e5'
  },
  overrides: {
    MuiAccordionSummary: {
      content: {
        '& .MuiTypography-colorPrimary, & .MuiIconButton-colorPrimary:not(.Mui-disabled)': {
          // the default primary blue is too bright for text
          color: '#00458e'
        }
      }
    },
    MuiTypography: {
      colorPrimary: {
        '&.MuiTypography-h6': {}
      }
    }
  },
  global: css(["html{font-size:14px;}html body{-webkit-font-smoothing:auto;}", " ", " ", ""],
  /* eslint-disable indent */
  // eslint-disable-line
  isMobile ? '/* mobile scrollbars are more compact, use default */' : css(["::-webkit-scrollbar{width:14px;height:14px;}::-webkit-scrollbar-track,::-webkit-scrollbar-corner{background:transparent;opacity:0.4;}::-webkit-scrollbar-thumb{background:rgb(144,144,144);border:3px solid rgba(0,0,0,0);background-clip:padding-box;border-radius:6px;}"]), flatPickrStyles(), commonGlobal)
};