import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Dialog as MuiDialog,
  Grid,
  Typography,
} from '@material-ui/core';
import { CloseButton, SectionHeader } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withSelectors } from './Selectors';
import messages from '../CertMessages';
import { CertContext } from '../../../Context';

const Dialog = styled(MuiDialog)`
  && .MuiPaper-root.MuiDialog-paper {
    align-items: center;
    min-height: 150px;
    min-width: 350px;
    @media screen and (max-width: 350px) {
      min-width: calc(100vw - 8px);
    }
    overflow: hidden;
  }
`;

const GridContainer = styled(Grid)`
  max-width: 315px;
  padding: 8px;
`;
const GridItem = styled(Grid)`
  position: relative;
  min-height: 30px;
`;
export const Popup = withSelectors(({ verbalDateLimit, lateCert }) => {
  const { buttonClicked } = useContext(CertContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (verbalDateLimit || lateCert) {
      setOpen(true);
    }
  }, [verbalDateLimit, lateCert]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="popup-dialog" open={open}>
      <SectionHeader
        id="popup-dialog"
        title={<FormattedMessage {...messages.popup} />}
        nav={<CloseButton showClose closeForm={handleClose} />}
      />
      <GridContainer container spacing={1}>
        {verbalDateLimit ? (
          <GridItem xs={12} item>
            <Typography>
              <FormattedMessage
                {...messages.verbalDateLimit}
                values={{ date: verbalDateLimit }}
              />
            </Typography>
          </GridItem>
        ) : null}
        {lateCert ? (
          <GridItem xs={12} item>
            <Typography>
              <FormattedMessage
                {...messages.lateCert}
                values={{ type: buttonClicked }}
              />
            </Typography>
          </GridItem>
        ) : null}
        <GridItem
          style={{ paddingTop: '12px' }}
          container
          justify="center"
          xs={12}
          item
        >
          <Button variant="outlined" color="primary" onClick={handleClose}>
            <FormattedMessage {...messages.ok} />
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
});
