/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { NoRecordsDiv } from '@mummssoftware/common-ui';
import { isNumber, merge } from 'lodash-es';
import { MedContext } from '../App/MedContext';
import { Medication, EpisodeExpansion } from '../Sections/Medication';
import { Headers } from '../Sections/Medication/ColHeaders';
import { messages } from '../utils/messages';
import defaults from '../../translations/en.json';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

// todo use this again
// const SpaceDiv = styled.div`
//   height: 100px;
//   width: 100%;
// `;

const scrollBarWidth = 14;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: (props) =>
    props.dashboardEmbedded
      ? {
          background: theme.palette.background.paper,
          overflow: 'scroll',
          overscrollBehavior: 'contain',
          height: props.smallWidget
            ? theme.props.dashboard.smallWidgetHeight
            : theme.props.dashboard.fullSizeRatio *
                (window.innerHeight - props.topBarHeight) -
              theme.props.dashboard.widgetHeaderHeight -
              props.actionBarHeight,
          minHeight: theme.props.dashboard.smallWidgetHeight,
          width: '100%',
          marginBottom: theme.spacing(2),
          borderRadius: 0,
        }
      : {
          background: theme.palette.background.paper,
          overflow: 'visible',
          width: '100%',
          marginBottom: theme.spacing(2),
          borderRadius: 0,
        },
  table: (props) =>
    props.dashboardEmbedded
      ? {
          minWidth: props.containerWidth - scrollBarWidth,
          width: '100%',
        }
      : { minWidth: 750 },
  tableContainer: (props) =>
    props.dashboardEmbedded
      ? {
          background: theme.palette.background.paper,
        }
      : {
          background: theme.palette.background.paper,
          overflow: 'visible',
        },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  // need to sort by alpha, date, number, and bool
  if (orderBy === 'name') {
    const orderByName = 'drugdescription';
    if (b[orderByName].toLowerCase() < a[orderByName].toLowerCase()) {
      return -1;
    }
    if (b[orderByName].toLowerCase() > a[orderByName].toLowerCase()) {
      return 1;
    }
  }
  if (orderBy === 'sig' || orderBy === 'physician') {
    if (!a[orderBy]) return 1;
    if (!b[orderBy]) return -1;

    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  }
  if (orderBy === 'covered') {
    if (b.coveredbyhospice.toLowerCase() < a.coveredbyhospice.toLowerCase()) {
      return -1;
    }
    if (b.coveredbyhospice.toLowerCase() > a.coveredbyhospice.toLowerCase()) {
      return 1;
    }
  }

  if (orderBy === 'quantity') {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
  }

  if (orderBy === 'start') {
    const orderByDate = `${orderBy}date`;

    const aDate =
      !!a[orderByDate] && a[orderByDate] !== ''
        ? new Date(a[orderByDate])
        : new Date(a.lastupdatedate) || false;

    const bDate =
      !!b[orderByDate] && b[orderByDate] !== ''
        ? new Date(b[orderByDate])
        : new Date(b.lastupdatedate) || false;
    if (bDate < aDate) {
      return -1;
    }
    if (bDate > aDate) {
      return 1;
    }
  }
  if (orderBy === 'stop' || orderBy === 'approved') {
    const orderByDate = `${orderBy}date`;

    const aDate =
      !a[orderByDate] && a[orderByDate] !== ''
        ? new Date(a[orderByDate])
        : false;
    const bDate =
      !!b[orderByDate] && b[orderByDate] !== ''
        ? new Date(b[orderByDate])
        : false;
    if (bDate < aDate) {
      return -1;
    }
    if (bDate > aDate) {
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Medications = ({
  expand,
  doctors,
  patient,
  handleMedChange,
  ...props
}) => {
  const { medications, dimensions } = useContext(MedContext);

  const actionBarHeight = props.smallWidget ? undefined : dimensions;
  const newprops = props.smallWidget ? props : { ...props, actionBarHeight };
  const classes = useStyles(newprops);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const { episodes } = patient;
  const patientEpisodes =
    episodes &&
    [...episodes].reduce((seed, e) => {
      const { episodeNumber } = e;
      if (isNumber(episodeNumber)) {
        const title = {
          utcAdmitDate: e.utcAdmitDate,
          utcDischargeDate: e.utcDischargeDate,
          utcReferralDate: e.utcReferralDate,
          admitDate: e.admitDate,
          dischargeDate: e.dischargeDate,
          referralDate: e.referralDate,
          episodeNumber,
        };
        seed[episodeNumber] = { title };
      }

      return seed;
    }, {});

  const medicationsByEpisode = [...medications].reduce((seed, m) => {
    const { episodenum } = m;
    if (episodenum && episodenum !== '') {
      if (seed[episodenum]) {
        seed[episodenum].meds.push(m);
      } else {
        seed[episodenum] = {};
        seed[episodenum].meds = [m];
      }
    }
    // if there is no episodenum then all go to episodenum = 0
    if (!episodenum || episodenum === '') {
      if (seed[0]) {
        seed[0].meds.push(m);
      } else {
        seed[0] = {};
        seed[0].meds = [m];
      }
    }

    return seed;
  }, {});

  const allEpisodes = merge(medicationsByEpisode, patientEpisodes);
  const allEpisodesbyTitle = Object.values(allEpisodes).filter((e) => e.meds);
  const allEpisodesOrdered = allEpisodesbyTitle.reverse();

  const newEpisodes = [...allEpisodesOrdered].map((sequence) => {
    const { meds } = sequence;
    const newMedications = [...meds];
    const noParentMeds = [];

    // list of history meds
    const historyMeds = [...meds].filter((m) => m.iscurrentversion === 'n');
    // get a list of uniq externalid
    // const uniqHistory = uniqBy(historyMeds, (x) => x.externalid);
    // tag the med with medindex === 0 and with several externalid in same episode number with history === yes
    historyMeds.forEach((med) => {
      const index = newMedications.findIndex(
        ({ externalid, medindex }) =>
          medindex === '0' && externalid === med.externalid,
      );
      if (index > -1) {
        const newMed = {
          ...newMedications[index],
          history: 'yes',
        };
        newMedications[index] = newMed;
        // if parent not in current episode we still need to display those meds in the current episode, push to array
      } else {
        noParentMeds.push(med);
      }
    });
    // the meds to display are the one with iscurrentversion == yes and the one with iscurrentversion == no and no parent id
    const currentMeds = [...newMedications].filter(
      (m) => m.iscurrentversion === 'y',
    );
    const episodeMeds = [...currentMeds, ...noParentMeds];

    const deleted = [...episodeMeds].filter(
      (m) => m.stopdate && m.stopreason === 'deleted',
    );
    const stopped = [...episodeMeds].filter(
      (m) =>
        m.stopdate &&
        Date.now() >= new Date(m.stopdate) &&
        m.stopreason !== 'deleted',
    );

    const active = [...episodeMeds].filter(
      (m) =>
        ((m.stopreason !== 'deleted' || !m.stopreason) &&
          Date.now() < new Date(m.stopdate)) ||
        !m.stopdate,
    );
    const newMeds = {};
    if (active.length) {
      newMeds.active = active;
    }
    if (stopped.length) {
      newMeds.stopped = stopped;
    }
    if (deleted.length) {
      newMeds.deleted = deleted;
    }

    const newSequence = sequence;
    newSequence.meds = newMeds;

    return newSequence;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const NoMeds = () => {
    // Warning: Can't perform a React state update on an unmounted component.
    // This is a no - op, but it indicates a memory leak in your application
    const [text, setText] = useState('');
    const mountedRef = useRef(true);

    useEffect(
      () => () => {
        mountedRef.current = false;
      },
      [],
    );

    setTimeout(() => {
      mountedRef.current && setText(defaults.noMeds);
    }, 1500);

    return (
      <NoRecordsDiv>
        <p>{text}</p>
      </NoRecordsDiv>
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        {newEpisodes.length ? (
          <React.Fragment>
            {newEpisodes.map((e, i) => {
              const { title, meds } = e;
              return (
                <EpisodeExpansion
                  title={title}
                  smallWidget={props.smallWidget}
                  isExpanded={i === 0}
                  key={title.referralDate}
                >
                  {Object.keys(meds).map((key, ind) => (
                    <EpisodeExpansion
                      title={`${key} (${meds[key].length})`.toUpperCase()}
                      smallWidget={props.smallWidget}
                      isExpanded={key === 'active'}
                      key={`${key}-${ind}`}
                      small
                    >
                      <TableContainer
                        className={`ag-theme-hummingbird light ${classes.tableContainer}`}
                      >
                        <Table
                          stickyHeader={!!props.dashboardEmbedded}
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="medications table"
                          padding="none"
                        >
                          <Headers
                            classes={classes}
                            messages={messages}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            hide={ind !== 0}
                            // rowCount={medications.length}
                          />

                          <TableBody>
                            {stableSort(
                              meds[key],
                              getComparator(order, orderBy),
                            ).map((medication, index) => (
                              <Medication
                                className={index % 2 ? 'ag-row-odd' : ''}
                                key={medication.id}
                                expand={expand}
                                doctors={doctors}
                                medication={medication}
                                handleChange={handleMedChange}
                                medications={medications}
                                containerWidth={props.containerWidth}
                              />
                            ))}
                          </TableBody>
                        </Table>
                        {/* {!props.smallWidget && <SpaceDiv />} */}
                      </TableContainer>
                    </EpisodeExpansion>
                  ))}
                </EpisodeExpansion>
              );
            })}
          </React.Fragment>
        ) : (
          <NoMeds />
        )}
      </Paper>
    </>
  );
};

export default React.memo(Medications);
