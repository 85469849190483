import {
  flatten,
  forOwn,
  keysIn,
  has,
  isNil,
  isObject,
  isPlainObject,
  pickBy,
  some,
} from 'lodash-es';
import type Webform from 'formiojs/Webform';

import selectboxes from 'formiojs/components/selectboxes/SelectBoxes';
import moment from 'moment-timezone';

import { Formio, FormioUtils } from './internal';
import type {
  AnyComponent,
  AnyNestedComponent,
  HTMLComponent,
  NestedComponent,
  submission as formioSubmission,
  FormsExtendedPatient,
} from '../types';

const { flattenComponents, searchComponents, isLayoutComponent } = FormioUtils;

export function isHTMLComponent(
  comp: HTMLComponent | AnyComponent,
): comp is HTMLComponent & AnyComponent {
  return comp.type === 'htmlelement';
}

export function isSelectBoxesComponent(
  comp: selectboxes | AnyComponent,
): comp is selectboxes & AnyComponent {
  return comp.type === 'selectboxes';
}

export function isNestedComponent(
  comp: NestedComponent | AnyComponent,
): comp is AnyNestedComponent {
  return 'components' in comp && Array.isArray(comp.components);
}

export function isListLike(
  possibleList?: HTMLElement | HTMLElement[] | NodeList,
): possibleList is HTMLElement[] | NodeList {
  return !!possibleList && 'length' in possibleList;
}

export function validateSubmissionPatient(
  submission?: submission,
  patientNumber?: string,
) {
  return (
    !(submission?.metadata.patientNumber && patientNumber) ||
    submission.metadata.patientNumber === patientNumber
  );
}

/* eslint-disable consistent-return */
export const allFormsAction = async (
  callback: (webform: Webform) => void,
  prevKeys?: string[],
): Promise<void> => {
  const promises: (() => Promise<void>)[] = [];
  // todo: fix type
  const localFormio = (Formio as unknown) as { forms: Webform[] };
  const keys: string[] = prevKeys || [];
  Object.entries(localFormio.forms).forEach(([key, webform]) => {
    if (!keys.includes(key)) {
      promises.push(() =>
        webform.ready.then(() => {
          callback(webform);
        }),
      );
      keys.push(key);
    }
  });
  // Some forms aren't added to the forms object things have settled, so check for missed
  // entries. and execute again
  return Promise.all(promises.map((func) => func())).then(async () => {
    if (keys.length !== Object.keys(localFormio.forms).length) {
      return allFormsAction(callback, keys);
    }
  });
};
/* eslint-enable */

const redrawKeys = ['label'];
/**
 * Not every component instance has an exposed API for some values, but we want to set it
 * programmatically.
 *
 * Sometimes setting a value on an instance's component works fine, others it needs to be
 * set on the originalComponent, and even others it wants the instance itself. Fire a
 * shotgun at it and take out the guesswork.
 * @param component
 * @param key
 * @param value
 */
export function setComponentProperty(
  component: AnyComponent,
  key: keyof AnyComponent['component'],
  value: mumms.JSONPrimitive,
) {
  if (key in component.component && !isObject(component.component[key])) {
    (component as any)[key] = value;
    (component.component as any)[key] = value;
    (component.originalComponent as any)[key] = value;
    if (redrawKeys.includes(key)) {
      component.redraw();
    }
  }
}

export function setComponentPropAndRebuild(
  component: AnyComponent,
  key: keyof AnyComponent['component'],
  value: mumms.JSONPrimitive,
  newLabel?: string,
) {
  const { parent } = component;
  const index = parent.components.indexOf(component);
  setComponentProperty(component, key, value);
  try {
    parent.originalComponent.components[index][key] = value;
    parent.component.components[index][key] = value;
  } catch (e) {
    // no worries, the above just may not be defined
  }
  component.triggerChange();
  component.destroy();
  component.once(
    'change',
    () => {
      parent.destroyComponents();
      parent.addComponents();
      parent.redraw();
      const newComp = parent.getComponentById(component.id);
      if (
        typeof newLabel === 'string' &&
        newComp &&
        newComp.label !== newLabel
      ) {
        setComponentProperty(newComp, 'label', newLabel);
        newComp.redraw();
      }
    },
    false,
  );
}
/**
 * Retrieves the API keys for submission answers
 *
 * @param {Object} submission Form.io submission
 *
 * @return {array} Array of keys
 * @public
 */
/* istanbul ignore next: not currently in use */
export function getSubmissionKeys(submission: formioSubmission) {
  const search = (data: formioSubmission['data']) => {
    if (isPlainObject(data)) {
      let childKeys: string[] = [];
      forOwn(data, (val, key) => {
        let tempKeys: string[] = [];
        if (Array.isArray(val)) {
          const map = val.map((d) => {
            const keys = search(d);
            if (typeof d === 'object') {
              d.keys = keys;
            }
            return keys;
          });
          tempKeys = flatten(map);
        } else if (isPlainObject(val)) {
          tempKeys = search(val);
        }
        if (tempKeys.length) {
          childKeys = [...tempKeys, ...childKeys, key];
        }
      });
      return [
        ...childKeys,
        ...keysIn(
          pickBy(data, (val) => {
            if (!isNil(val) && typeof val === 'object') {
              return some(childKeys, (key) => has(val, key));
            }
            return !isNil(val) && val !== '' && val !== false;
          }),
        ),
      ];
    }
    return [];
  };
  return search(submission.data);
}

/* istanbul ignore next: not currently in use */
function hideMultiInput(
  inputList: HTMLElement[] | NodeList,
  selector = '[ref="wrapper"]',
) {
  [...inputList].forEach((input) => {
    if (input instanceof HTMLInputElement) {
      if (!input.checked) {
        const wrap = (input.closest(selector) as HTMLElement) || {
          hidden: true,
        };
        wrap.hidden = true;
      } else {
        input.classList.add('fa', 'fa-check-square-o');
      }
      // else {
      //   input.hidden = true;
      // }
    }
  });
}

/* istanbul ignore next: not currently in use */
function setHiddenComponent(component: AnyComponent, dataKeys: string[]) {
  if (
    !dataKeys.includes(component.key) ||
    !component.hasValue() ||
    component.key === 'submit'
  ) {
    // console.log('no value', component);
    component.component.hidden = true;
  } else if (component.refs.input && 'length' in component.refs.input) {
    hideMultiInput(component.refs.input);
  } else {
    console.log('no case', component);
  }
}

/* istanbul ignore next: not currently in use */
export async function setHiddenComponentsByKey(
  form: Webform,
  dataKeys: string[],
) {
  const layoutComponents: AnyNestedComponent[] = [];
  await form.ready;
  const flattened = flattenComponents(form.components, true) as AnyComponent[];
  Object.values(flattened).forEach((component: AnyComponent) => {
    if (isLayoutComponent(component)) {
      layoutComponents.push(component as AnyNestedComponent);
    } else {
      setHiddenComponent(component, dataKeys);
    }
  });
  if (layoutComponents.length) {
    layoutComponents.forEach((component) => {
      const allHidden = !searchComponents(component.components, {
        'component.hidden': false,
      }).length;
      if (component.hasInput && isListLike(component.refs.input)) {
        hideMultiInput(component.refs.input, '[ref*="wrapper"]');
      }
      if (
        // If the layoutComponent's children are all hidden, hide the parent.
        allHidden
      ) {
        component.component.hidden = true;
        /**
         * TODO: Is this still valid?
         * We need some slightly different logic for the datagrid components, as there
         * is only one instance per form definition, and if we want to hide different
         * questions for each we can check whether it should be visible.
         *
         * This might actually be a good way to go for hiding everything, but I
         * suspect it may make life more difficult when it comes to hiding the parents.
         */
      } else if (component.inDataGrid && isNestedComponent(component)) {
        component.components.forEach((child) => {
          // customConditionals expose data, row, and component global variables.
          child.customConditional = `show = row.keys && row.keys.indexOf(component.key) > -1;`;
        });
      }
      // if (!component.hidden) P
    });
  }
  return form;
}

export const getCurrentFormioUser = (
  formio: Formio,
  keycloakToken: string,
  retry = 0,
) => {
  const header = new Formio.Headers();
  header.set('Authorization', `Bearer ${keycloakToken}`);

  return formio
    .currentUser({
      external: true,
      header,
    })
    .catch((e: Error) => {
      if (retry < 1) {
        return getCurrentFormioUser(formio, keycloakToken, 1);
      }
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    });
};

/**
 * Retrieve primary diagnosis
 */
export const getPrimaryDiagnosis = (diagnosisDTO: mumms.diagnosisDTO[]) => {
  const primary = diagnosisDTO.splice(
    diagnosisDTO.findIndex(({ rank }) => rank === 1),
    1,
  );
  return primary[0];
};

/**
 * Retrieve the basic scaler attributes of patient based on formio apikey
 */
export const getPatientScaler = (
  componentkey?: string,
  patient?: FormsExtendedPatient,
) => {
  let value;
  switch (componentkey) {
    case 'firstname': {
      value = patient?.firstName;
      break;
    }
    case 'lastname': {
      value = patient?.lastName;
      break;
    }
    case 'fullname': {
      value = `${patient?.lastName  }, ${  patient?.firstName}`;
      break;
    }
    case 'patientnumber': {
      value = patient?.patientNumber;
      break;
    }
    case 'dateofbirth': {
      value = moment(patient?.dateOfBirth).format('MM/DD/YYYY');
      break;
    }
    case 'dnr': {
      value = patient?.dnr;
      break;
    }
    // case 'allergies': {
    //   value = patient?.allergies;
    //   break;
    // }
    case 'age': {
      value = patient?.age?.replace(' yrs', '');
      break;
    }
    case 'loc': {
      value = patient?.loc;
      break;
    }
    case 'facility': {
      value = patient?.facility;
      break;
    }

    case 'physicianname': {
      const attending = patient?.patientPhysicianDTOList?.splice(
        patient?.patientPhysicianDTOList?.findIndex(
          ({ phyisicanType }) => phyisicanType === 'ATTENDING_PHYSICIAN',
        ),
        1,
      );
      // dto is Record<string, unknown>[] -
      // how to get value
      // value = attending.physicianName;
      value = '';
      break;
    }
    case 'program': {
      value = patient?.episodes[patient.episodes.length - 1].program;
      break;
    }
    case 'admitdate': {
      value = patient?.episodes[patient.episodes.length - 1].admitDate;
      break;
    }
    case 'dischargedate': {
      value = patient?.episodes[patient.episodes.length - 1].dischargeDate;
      break;
    }
    case 'referraldate': {
      value = patient?.episodes[patient.episodes.length - 1].referralDate;
      break;
    }
    case 'episodenum': {
      value = patient?.episodes[
        patient.episodes.length - 1
      ].episodeNumber.toString();
      break;
    }
    case 'intermediary': {
      value = patient?.episodes[patient.episodes.length - 1].intermediary;
      break;
    }
    case 'site': {
      value = patient?.episodes[patient.episodes.length - 1].site;
      break;
    }
    case 'dischargereason': {
      value = patient?.episodes[patient.episodes.length - 1].dischargeReason;
      break;
    }
    case 'primarydiagnosis': {
      value = getPrimaryDiagnosis([...(patient?.diagnosisDTO || [])])
        ?.diagClass;
      break;
    }
    case 'scorefound': {
      value = patient?.physimpairmentscore?.scorefound;
      break;
    }
    case 'perfscore-criteria-met': {
      value = patient?.physimpairmentscore?.criteriamet;
      break;
    }
    case 'perfscore-criteria-metmsg': {
      value = patient?.physimpairmentscore?.criteriametmsg;
      break;
    }
    case 'measure-MAC-L': {
      value = patient?.latestScores?.['MAC-L']?.displayalue;
      break;
    }
    case 'measure-MAC-R': {
      value = patient?.latestScores?.['MAC-R']?.displayalue;
      break;
    }
    case 'measure-MTC-L': {
      value = patient?.latestScores?.['MTC-L']?.displayalue;
      break;
    }
    case 'measure-MTC-R': {
      value = patient?.latestScores?.['MTC-R']?.displayalue;
      break;
    }
    case 'measure-anxiety': {
      value = patient?.latestScores?.anxiety?.value;
      break;
    }
    case 'measure-appetite': {
      value = patient?.latestScores?.appetite?.value;
      break;
    }
    case 'measure-bmi': {
      value = patient?.latestScores?.bmi?.displayalue;
      break;
    }
    case 'measure-bp': {
      value = patient?.latestScores?.bp?.displayalue;
      break;
    }
    case 'measure-depression': {
      value = patient?.latestScores?.depression?.value;
      break;
    }
    case 'measure-drowsiness': {
      value = patient?.latestScores?.drowsiness?.value;
      break;
    }
    case 'measure-dyspnea': {
      value = patient?.latestScores?.dyspnea?.value;
      break;
    }
    case 'measure-fast': {
      value = patient?.latestScores?.fast?.value;
      break;
    }
    case 'measure-growth': {
      value = patient?.latestScores?.growth?.displayalue;
      break;
    }
    case 'measure-height': {
      value = patient?.latestScores?.height?.displayalue;
      break;
    }
    case 'measure-kps': {
      value = patient?.latestScores?.kps?.value;
      break;
    }
    case 'measure-map': {
      value = patient?.latestScores?.map?.displayalue;
      break;
    }
    case 'measure-nausea': {
      value = patient?.latestScores?.nausea?.value;
      break;
    }
    case 'measure-oxygen-sat': {
      value = patient?.latestScores?.['oxygen-sat']?.displayalue;
      break;
    }
    case 'measure-pain': {
      value = patient?.latestScores?.pain?.value;
      break;
    }
    case 'measure-pps': {
      value = patient?.latestScores?.pps?.value;
      break;
    }
    case 'measure-pulse': {
      value = patient?.latestScores?.pulse?.displayalue;
      break;
    }
    case 'measure-resp': {
      value = patient?.latestScores?.resp?.displayalue;
      break;
    }
    case 'measure-temp': {
      value = patient?.latestScores?.temp?.displayalue;
      break;
    }
    case 'measure-tiredness': {
      value = patient?.latestScores?.tiredness?.value;
      break;
    }
    case 'measure-weight': {
      value = patient?.latestScores?.weight?.displayalue;
      break;
    }
    case 'measure-wellbeing': {
      value = patient?.latestScores?.wellbeing?.value;
      break;
    }
    default: {
      value = 'KEY NOT FOUND';
      break;
    }
  }
  return value;
};
