import React, { createContext, ReactNode, useReducer } from 'react';
import {
  SET_PRINT_INTERFACE_OPEN,
  SET_PRINT_LAYOUT_OPEN,
  SET_PRINT_CONFIG,
  ADD_WIDGET_READY,
  RESET_WIDGETS_READY,
  SET_LOADER,
  SET_ALERT,
  SET_ALERT_MESSAGE,
  SET_WIDGET_OPEN,
  RESET_WIDGET_OPEN,
  RESET_WIDGET_NAMES_OPEN,
  SET_THEME_OVERLAY,
  REFRESH_DASHBOARD,
} from './constants';
import { PrintConfigInterface } from './types';

type DashboardContextProviderProps = {
  children: ReactNode;
};

type DashboardContext = {
  printInterfaceOpen: boolean | null;
  printLayoutOpen: boolean | null;
  loader: boolean | null;
  alertOpen: boolean;
  themeOverlay: boolean;
  refreshDashboard: number;
  alertMessage: string | null;
  widgetsReady: mumms.ComponentName[] | string[];
  widgetNamesOpen: mumms.ComponentName[] | string[];
  widgetsOpen: any;
  dashboardPrintConfig: PrintConfigInterface | null;
  dispatch?: any;
};

type DashboardContextState = {
  printInterfaceOpen: boolean | null;
  printLayoutOpen: boolean | null;
  loader: boolean | null;
  refreshDashboard: number;
  alertOpen: boolean;
  themeOverlay: boolean;
  alertMessage: string | null;
  widgetsReady: mumms.ComponentName[] | string[];
  widgetNamesOpen: mumms.ComponentName[] | string[];
  widgetsOpen: any;
  dashboardPrintConfig: PrintConfigInterface | null;
};

const initialState = {
  printInterfaceOpen: null,
  printLayoutOpen: null,
  refreshDashboard: 0,
  loader: null,
  dashboardPrintConfig: null,
  alertOpen: false,
  themeOverlay: false,
  alertMessage: null,
  widgetsReady: [],
  widgetNamesOpen: [],
  widgetsOpen: {},
};

const initDashboardContext = {
  printInterfaceOpen: null,
  printLayoutOpen: null,
  loader: null,
  dashboardPrintConfig: null,
  refreshDashboard: 0,
  alertOpen: false,
  themeOverlay: false,
  alertMessage: null,
  widgetsReady: [],
  widgetNamesOpen: [],
  widgetsOpen: {},
};

export const DashboardContextReducer = (
  state: DashboardContextState,
  action: { type: string; payload: mumms.ComponentName | any },
) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_PRINT_INTERFACE_OPEN:
      newState.printInterfaceOpen = action.payload;
      break;
    case SET_PRINT_CONFIG:
      newState.dashboardPrintConfig = action.payload;
      break;
    case SET_LOADER:
      newState.loader = action.payload;
      break;
    case ADD_WIDGET_READY:
      newState.widgetsReady.push(action.payload);
      break;
    case RESET_WIDGETS_READY:
      newState.widgetsReady = [];
      break;
    case RESET_WIDGET_NAMES_OPEN:
      newState.widgetNamesOpen = [];
      break;
    case SET_ALERT:
      newState.alertOpen = action.payload;
      break;
    case SET_ALERT_MESSAGE:
      newState.alertMessage = action.payload;
      break;
    case SET_PRINT_LAYOUT_OPEN:
      newState.printLayoutOpen = action.payload;
      break;
    case SET_THEME_OVERLAY:
      newState.themeOverlay = action.payload;
      break;
    case REFRESH_DASHBOARD:
      newState.refreshDashboard += 1;
      break;
    case SET_WIDGET_OPEN:
      {
        const name = action.payload.componentName;
        newState.widgetsOpen[name] = action.payload;
        newState.widgetNamesOpen.push(name);
      }
      break;
    case RESET_WIDGET_OPEN:
      {
        const name = action.payload.componentName;
        delete newState.widgetsOpen[name];
      }
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const DashboardContext = createContext<DashboardContext>(
  initDashboardContext as DashboardContext,
);
export const DashboardContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const [
    {
      printInterfaceOpen,
      printLayoutOpen,
      dashboardPrintConfig,
      widgetsReady,
      widgetsOpen,
      loader,
      alertOpen,
      alertMessage,
      widgetNamesOpen,
      themeOverlay,
      refreshDashboard,
    },
    dispatch,
  ] = useReducer(DashboardContextReducer, initialState);
  return (
    <DashboardContext.Provider
      value={{
        printInterfaceOpen,
        printLayoutOpen,
        dashboardPrintConfig,
        widgetsReady,
        widgetsOpen: Object.values(widgetsOpen),
        loader,
        alertOpen,
        alertMessage,
        widgetNamesOpen,
        themeOverlay,
        refreshDashboard,
        dispatch,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
