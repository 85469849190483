import moment from 'moment-timezone';
import { formatPhysicianName } from '@mummssoftware/utils/formatters';
import {
  ATTENDING_FORM,
  CERTIFYING_FORM,
  NO_ATTENDING,
  RESTRICTED_ROLES,
} from './constants';

function getCurrentEpisode(certHistory) {
  const lastCert = certHistory[certHistory.length - 1];
  return lastCert ? lastCert.episode.number : null;
}

/**
 *! This should not live here,but there are fires to put out. This needs to be handled
 *! correctly, and in a way that is scalable.
 */
export const allElectionsAttendingStates = ['MS'];

export const getFormPhys = (phys, id) => {
  let formPhys;
  if (phys.id === id) {
    formPhys = {
      label: formatPhysicianName(
        phys.salutation,
        phys.firstName,
        phys.lastName,
        phys.credentials,
      ),
      value: phys.id,
    };
  }
  return formPhys;
};

export const getFormTypeKeys = (cert, formType) => {
  if (formType === ATTENDING_FORM) {
    return {
      formType,
      physKey: 'attendingPhys',
      fallbackPhysId: cert.episode.attendingPhysicianId,
    };
  }
  return {
    formType,
    physKey: 'hospicePhys',
  };
};

export const processCert = async ({
  cert,
  certIndex,
  checkRoles,
  currentEpisode,
  electionNumber,
  fallbackPhysId,
  formPhysRestricted = false,
  formType,
  notInEpisode = currentEpisode !== cert.episode.number,
  physKey,
  pim,
}) => {
  // TODO: add defaultDate: admitted time if electionNumber === 1
  const formPhysId = cert[physKey].id || fallbackPhysId;
  let defaultDate = null;
  if (electionNumber === 1) {
    const start = moment(cert.startDate);
    const referralDate = moment(cert.episode.referralDate);
    if (
      start.isSame(referralDate, 'day') ||
      start.isSame(moment(cert.episode.admitDate), 'day')
    ) {
      defaultDate = referralDate.toDate();
    }
  }
  if (
    pim &&
    checkRoles &&
    typeof formPhysId === 'number' &&
    formPhysId !== NO_ATTENDING
  ) {
    /* eslint-disable no-console, no-param-reassign */
    const roles = await pim
      .get(`/web/api/persons/${formPhysId}/roles`)
      .catch(console.error);
    formPhysRestricted =
      /* eslint-enable */
      (roles &&
        roles.data &&
        !!roles.data.filter(({ shortName }) =>
          RESTRICTED_ROLES.includes(shortName),
        ).length) ||
      formPhysRestricted;
  }
  return {
    complete: notInEpisode || !!cert[`${physKey}WrittenDate`],
    verbalComplete: notInEpisode || !!cert[`${physKey}VerbalDate`],
    certIndex,
    defaultDate,
    electionNumber,
    formPhys: getFormPhys(cert[physKey], formPhysId),
    formPhysId,
    formPhysRestricted,
    formType,
    notInEpisode,
  };
};

export const processCerts = async (certs, pim, patientState) => {
  const currentEpisode = getCurrentEpisode(certs);
  return certs.reduce(async (previousForms, cert, certIndex) => {
    const forms = await previousForms;
    const newForms = [];
    const { electionNumber } = cert;
    const certForm = await processCert({
      cert,
      certIndex,
      currentEpisode,
      electionNumber,
      ...getFormTypeKeys(cert, CERTIFYING_FORM),
    });
    newForms.push(certForm);
    if (
      electionNumber === 1 ||
      allElectionsAttendingStates.includes(patientState)
    ) {
      const attdForm = await processCert({
        cert,
        certIndex,
        checkRoles: true,
        currentEpisode,
        electionNumber,
        ...getFormTypeKeys(cert, ATTENDING_FORM),
        pim,
      });
      newForms.push(attdForm);
    }
    forms.push(...newForms);
    return forms;
  }, Promise.resolve([]));
};
