export const en = {
  common: {
    'part-labels': {
      one: 'Part I.',
      two: 'Part II.',
      three: 'Part III.'
    },
    indicate: 'Indicate',
    other: 'Other',
    yes: 'Yes',
    submit: 'Submit',
    close: 'Close',
    no: 'No',
    kps: 'KPS',
    pps: 'PPS',
    na: 'N/A',
    loading: 'Loading',
    blank: ' ',
    nav: {
      chart: 'Chart',
      close: 'Close',
      logout: 'Logout',
      print: 'Print',
      facesheet: 'Facesheet'
    },
    patientId: 'Patient ID'
  }
  /* Override AG-Grid default translations below */
  // grid: {}

};
export default en;