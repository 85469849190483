import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { MedContext } from '../../App/MedContext';

const MedsApprovalButton = (props) => {
  const { messages, handleApproveMeds } = props;
  const { medications } = useContext(MedContext);

  const needsApproval = medications.some(
    (medication) =>
      !medication.reconcileddate || medication.reconcileddate === '',
  );

  const ApprovalButton = (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      onClick={handleApproveMeds}
    >
      <FormattedMessage {...messages.medsApprovedPhyBTN} />
    </Button>
  );
  return needsApproval ? ApprovalButton : null;
};
export default MedsApprovalButton;
