/* eslint-disable indent */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Table } from '@mummssoftware/common-ui';
import { LicenseManager } from 'ag-grid-enterprise';
import type {
  GridOptions,
  RowNode,
  IsColumnFuncParams,
  CellValueChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useTheme, Snackbar } from '@material-ui/core';
import { find, includes } from 'lodash-es';
import type { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import {
  ColumnDefs,
  TableData,
  AllMeasuresDefined,
  HeaderTitle,
  Field,
} from '../MeasuresContext/utils/type';
import ErrorBoundary from './ErrorBoundary';
import { Context } from '../../context';
import { Overlay } from './Overlay';
import {
  getAscendingMeasures,
  formatFullTableDate,
} from '../MeasuresContext/utils/utils';
import {
  MeasureCellRenderer,
  RecordedByCellRenderer,
  PostCellRenderer,
  UnitsCellRenderer,
  ValuesCellRenderer,
  ScoreCellRenderer,
} from './CellRenderer';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import defaults from '../../translations/en.json';
import {
  SET_OVERLAY,
  MEASURE_TYPE,
  patientRefused,
  notTaken,
  afebrile,
  dropdownFields,
  noPost,
  active,
  scoresType,
} from '../MeasuresContext/utils/constants';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

type Measures = mumms.formattedAllMeasures;

type MeasuresTableProps = {
  patient: mumms.patient;
  currentUser: mumms.User;
  allMeasures: AllMeasuresDefined;
  postdate?: string;
  width?: number;
  isPatientActive: boolean;
};
const texts: NonNullable<mumms.JSONObject> = defaults;

export const MeasuresTable = React.memo(
  ({
    patient,
    currentUser,
    allMeasures,
    postdate,
    width,
    isPatientActive,
  }: MeasuresTableProps) => {
    const { palette } = useTheme();
    const dateField = 'date';
    const recordedByField = 'recordedBy';
    const unitsField = 'units';
    const measureField = 'measureData';
    const commentsField = 'comments';
    const recordField = 'record';
    const methodField = 'observationmethod';

    // TODO correct measures and measurestype
    const { measures, measuresTypes, alertError, alertSuccess } = allMeasures;
    const {
      dispatch,
      loadingOverlay,
      measureSelected,
      tableSelected,
      newMeasure,
    } = useContext(MeasuresContext);
    const measuretype = localStorage.getItem(MEASURE_TYPE);
    const isScores = measuretype === scoresType;
    const recordSelected = measureSelected || tableSelected;
    const { handleMeasureChange, handleErrorMsg } = useContext(Context);
    const measureSelectedType: mumms.measureType | undefined | any = find(
      measuresTypes,
      {
        shortname: recordSelected,
      },
    );

    const getMeasureDefaultUnit = (_measureSelectedType: mumms.measureType) => {
      const units =
        _measureSelectedType.units &&
        JSON.parse((_measureSelectedType as mumms.measureType).units);
      const defaultUnit =
        units &&
        find(units, {
          isdefaultunit: true,
        });
      return defaultUnit;
    };

    const [measureDefaultUnit, setMeasureDefaultUnit] = useState<any>(
      getMeasureDefaultUnit(measureSelectedType),
    );
    useEffect(() => {
      setMeasureDefaultUnit(getMeasureDefaultUnit(measureSelectedType));
    }, [measureSelectedType]);

    const [patientMeasures, setPatientMeasures] = useState<Measures>(measures);
    useEffect(() => {
      setPatientMeasures(measures);
    }, [measures]);

    // *****const needed to post a measure****
    const [patientNumber] = useState<string>(patient.patientNumber);
    const [patientId] = useState<number>(patient.id);
    const [patientSiteId] = useState<string>(patient.siteId.toString());
    const [personId] = useState<string | number | null>(
      currentUser.isMumms ? null : currentUser.userId,
    );

    // *****const needed to post a measure****
    const [alertMessage, setAlertMessage] = useState<string | null>(
      alertError || alertSuccess,
    );

    useEffect(() => {
      const alert = alertError || alertSuccess;
      setAlertMessage(alert);
    }, [alertError, alertSuccess]);

    const [alertOpen, setAlertOpen] = useState<boolean>(!!alertMessage);
    useEffect(() => {
      dispatch({
        type: SET_OVERLAY,
        payload: false,
      });
      setAlertOpen(!!alertMessage);
    }, [alertMessage, dispatch, handleErrorMsg]);

    useEffect(() => {
      if (alertMessage) {
        setTimeout(() => {
          setAlertOpen(false);
          handleErrorMsg();
        }, 1000);
      }
    }, [alertMessage, handleErrorMsg]);

    // Clear PostData upon changing measure
    useEffect(() => {
      setPostData(clearPostData(measuresTypes));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // TODO explicit disable for measuresType
    }, [recordSelected]);

    const [postData, setPostData] = useState<Record<string, any>>({});

    const getPostData = useCallback(
      (data: Record<string, string>) => {
        const { shortName, field, value } = data;
        const seed = postData;
        if (seed[shortName]) {
          seed[shortName][field] = value;
        } else {
          seed[shortName] = {};
          seed[shortName][field] = value;
        }
        return seed;
      },
      [postData],
    );

    const clearPostData = useCallback(
      (measurestypes: mumms.measureType[]) => {
        measurestypes.forEach(
          (measure: mumms.measureType) => delete postData[measure.shortname],
        );
        return postData;
      },
      [postData],
    );

    const getMeasureData = useCallback(
      (measureshortname: mumms.measureShortNames) => {
        // timezone is not taken into account by back-end
        const timezone = '';
        const defaultUnitName =
          measureDefaultUnit && measureDefaultUnit.unitshortname;
        const commentsValue =
          (postData &&
            postData[measureshortname] &&
            postData[measureshortname][commentsField]) ||
          '';
        const measureValue =
          (postData &&
            postData[measureshortname] &&
            postData[measureshortname][measureField]) ||
          '';
        const unitsValue =
          (postData &&
            postData[measureshortname] &&
            postData[measureshortname][unitsField]) ||
          defaultUnitName;

        const measuredata = {
          comments: commentsValue as string,
          id: '',
          measurecategoryshortname: '',
          measuredate: '',
          measuredatetime: postdate as string,
          measuredatetimeshifted: '',
          measuretypeid: '',
          measuretypename: '',
          measuretypeshortname: measureshortname,
          patientid: patientId.toString() as string,
          patientnumber: '',
          personcredentials: '',
          personfirstname: '',
          personid: personId as string,
          personlastname: '',
          personnumber: '',
          siteid: patientSiteId as string,
          timezone,
          units: unitsValue,
          value: measureValue as string,
        };
        return measuredata;
      },
      [
        personId,
        patientId,
        patientSiteId,
        postdate,
        postData,
        measureDefaultUnit,
      ],
    );

    const saveMeasures = useCallback(
      (gridapi: GridApi, measure: mumms.measureShortNames) => {
        const doRecord =
          !!postData[measure] &&
          (!!postData[measure][measureField] ||
            !!postData[measure][commentsField] ||
            postData[measure][unitsField] === patientRefused ||
            postData[measure][unitsField] === afebrile ||
            postData[measure][unitsField] === notTaken);
        const measureData: any = getMeasureData(measure);

        if (doRecord) {
          handleMeasureChange(
            patientNumber,
            measureData,
            'POST',
            measuretype as string,
          );
          dispatch({
            type: SET_OVERLAY,
            payload: true,
          });
          // top pinned row disappear
          gridapi.setPinnedTopRowData([]);
          // For the checkmark to dispappear
          return true;
        }
        return false;
      },
      [
        dispatch,
        handleMeasureChange,
        postData,
        getMeasureData,
        measuretype,
        patientNumber,
      ],
    );

    const onCellValueChanged = (event: CellValueChangedEvent) => {
      const { field } = event.colDef;
      const { shortName } = event.data;
      const { value, api } = event;
      const rowNode = api.getPinnedTopRow(0);
      const index = 0;
      const top = 'top';
      if (field === commentsField) {
        setPostData(
          getPostData({
            shortName,
            field,
            value,
          }),
        );
      }
      if (field === unitsField) {
        setPostData(
          getPostData({
            shortName,
            field,
            value,
          }),
        );
        if (
          value !== patientRefused &&
          value !== notTaken &&
          value !== afebrile
        ) {
          api.setFocusedCell(index, measureField, top);
        } else {
          rowNode.setDataValue(recordField, active);
          api.setFocusedCell(index, commentsField, top);
        }
      }
      if (field === measureField) {
        const measurevalue = value && (value.value || value);
        setPostData(
          getPostData({
            shortName,
            field,
            value: measurevalue,
          }),
        );
        api.setFocusedCell(index, commentsField, top);
        rowNode.setDataValue(recordField, active);
      }
    };

    const getTableData = useCallback(
      (data: any) => {
        if (data && recordSelected && data[recordSelected]) {
          const measuredata: mumms.measure[] = data[recordSelected];
          const sortedMeasures = getAscendingMeasures(
            measuredata,
          ) as mumms.measure[];
          const tableData: any[] = sortedMeasures.map((row) => {
            const date = formatFullTableDate(row.measuredatetimeshifted);
            const measurevalue = {
              value: row.displayalue,
              comments: row.comments,
            };
            // Avoid seeing Enter Unit in table
            const units = row.units ? row.units : '  ';
            const tableRow: any = {
              ...row,
              date,
            };
            tableRow[measureField] = measurevalue;
            tableRow[recordField] = noPost;
            tableRow[unitsField] = units;
            return tableRow;
          });
          return tableData;
        }
        return [];
      },
      [recordSelected],
    );

    const cellCellEditorParams = (params: any) => {
      const { units } = params.data;
      let values = [];
      if (units !== patientRefused && units !== notTaken && units) {
        values = params.data.dropdownvalues;
      }
      return {
        cellHeight: 30,
        values,
        cellRendererFramework: ValuesCellRenderer,
      };
    };

    /* eslint-disable indent */
    const getTableCol = useCallback(() => {
      if (recordSelected && measureSelectedType) {
        // table column widths
        // padding of 16 with the scroll bar
        const padding = 16;
        const dateWidth = 115;
        const recordedByWidth = 150;
        const unitsWidth = 110;
        const saveWidth = 30;
        const methodWidth = 150;

        const measureSelectedName =
          recordSelected.charAt(0).toUpperCase() + recordSelected.slice(1);

        if (isScores) {
          const measureWidth = width
            ? width - dateWidth - recordedByWidth - methodWidth - padding
            : null;
          return [
            {
              headerName: 'Date' as HeaderTitle,
              field: dateField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: dateWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
            },
            {
              headerName: 'Recorded by' as HeaderTitle,
              field: recordedByField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: recordedByWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
              cellRendererFramework: RecordedByCellRenderer,
            },
            {
              headerName: measureSelectedName as HeaderTitle,
              field: measureField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: measureWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
              cellRendererFramework: ScoreCellRenderer,
            },
            {
              headerName: 'Method' as HeaderTitle,
              field: methodField as Field,
              cellClass: 'measures',
              width: methodWidth,
              suppressSizeToFit: true,
              suppressMenu: true,
              headerClass: 'header-center',
            },
          ];
        }
        const units = JSON.parse(
          (measureSelectedType as mumms.measureType).units,
        );
        const unitsCell = units.map((row: any) => row.unitshortname);
        const unit = measureDefaultUnit && measureDefaultUnit.unitshortname;
        const isDataDropdown =
          measureSelectedType && !!measureSelectedType.values;

        const measureName = measureDefaultUnit
          ? `${measureSelectedName} (${unit})`
          : `${measureSelectedName}`;

        // Needs to save width as api.sizeColumnsToFit() issue if no re-render
        const dataMeasureWidth = isDataDropdown ? 280 : 120;
        const commentWidth = width
          ? width -
            dateWidth -
            recordedByWidth -
            unitsWidth -
            dataMeasureWidth -
            saveWidth -
            padding
          : null;

        return [
          {
            headerName: 'Date' as HeaderTitle,
            field: dateField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: dateWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Recorded by' as HeaderTitle,
            field: recordedByField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: recordedByWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellRendererFramework: RecordedByCellRenderer,
          },
          {
            headerName: 'Units' as HeaderTitle,
            field: unitsField as Field,
            cellClass: 'measures',
            width: unitsWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellEditor: 'agRichSelectCellEditor',
            cellRendererFramework: UnitsCellRenderer,
            cellRendererParams: {
              width: unitsWidth,
            },
            cellEditorParams: {
              cellHeight: 30,
              values: unitsCell,
              cellRendererFramework: UnitsCellRenderer,
            },
          },
          {
            headerName: measureName as HeaderTitle,
            field: measureField as Field,
            cellClass: 'long-desc',
            headerClass: 'header-center',
            width: dataMeasureWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellEditor: isDataDropdown ? 'agRichSelectCellEditor' : null,
            cellRendererFramework: isDataDropdown
              ? ValuesCellRenderer
              : MeasureCellRenderer,
            cellRendererParams: {
              width: dataMeasureWidth,
            },
            cellEditorParams: isDataDropdown ? cellCellEditorParams : null,
          },
          {
            headerName: 'Comments' as HeaderTitle,
            field: commentsField as Field,
            cellClass: 'measures',
            width: commentWidth ? Math.max(commentWidth, 150) : null,
            suppressSizeToFit: true,
            suppressMenu: true,
            headerClass: 'header-center',
          },
          {
            headerName: '' as HeaderTitle,
            field: recordField as Field,
            cellClass: 'med-center no-border',
            cellRendererFramework: PostCellRenderer,
            width: saveWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellRendererParams: {
              saveMeasures,
              recordSelected,
            },
          },
        ];
      }
      return undefined;
    }, [
      recordSelected,
      measureSelectedType,
      saveMeasures,
      width,
      isScores,
      measureDefaultUnit,
    ]);

    const frameworkComponents = {
      measureCellRenderer: MeasureCellRenderer,
      unitsCellRenderer: UnitsCellRenderer,
      cancelCellRenderer: PostCellRenderer,
      valuesCellRenderer: ValuesCellRenderer,
      customLoadingOverlay: Overlay,
    };

    const getEditableCells = useCallback((params: IsColumnFuncParams) => {
      const { shortName } = params.data;
      const { field } = params.colDef;
      const isDropdownField = includes(dropdownFields, shortName);
      if (!params.node.isRowPinned()) {
        return false;
      } else if (
        // unitsField always editable as well as dropdown fields for kps pps
        (isDropdownField && field !== recordField) ||
        field === unitsField
      ) {
        return true;
      }
      // leave the logic here in case needs to switch back
      // const keys: string[] | [] = Object.keys(postData);
      // const isEditable = includes(keys, shortName);

      return field === measureField || field === commentsField;
    }, []);

    const getRowPinned = useCallback(() => {
      // a lot of logic here to know where to display the enter data row
      // only able to enter data if not readonly type and not score and not discharged patient
      if (newMeasure && measureSelectedType.readonly === 'false' && !isScores) {
        // KPS PPS ...
        const dropdownvalues =
          measureSelectedType &&
          measureSelectedType.values &&
          JSON.parse(measureSelectedType.values);

        // units dropdown should default to defaultUnit if present
        const defaultUnitName =
          measureDefaultUnit && measureDefaultUnit.unitshortname;
        const activePatientRow = defaultUnitName
          ? {
              dropdownvalues,
              shortName: recordSelected,
              units: defaultUnitName,
            }
          : {
              dropdownvalues,
              shortName: recordSelected,
            };

        // if is NOT an PatientActive then full width pinned row
        const pinnedTopRowData = isPatientActive
          ? activePatientRow
          : { fullWidth: true };
        return [pinnedTopRowData];
      }
      return undefined;
    }, [
      recordSelected,
      measureSelectedType,
      isPatientActive,
      isScores,
      measureDefaultUnit,
      newMeasure,
    ]);

    const getGridOptions = useCallback(
      (data: Measures) => {
        if (data) {
          const gridOptions: GridOptions = {
            defaultColDef: {
              resizable: true,
              editable: getEditableCells,
            },
            singleClickEdit: true,
            // disabling top row
            pinnedTopRowData: getRowPinned(),
            // this property tells grid to stop editing if the
            // grid loses focus
            stopEditingWhenGridLosesFocus: true,
            isFullWidthCell(rowNode) {
              // in this example, we check the fullWidth attribute that we set
              // while creating the data. what check you do to decide if you
              // want a row full width is up to you, as long as you return a boolean
              // for this method.
              return rowNode.data.fullWidth;
            },
            fullWidthCellRenderer(params) {
              const message =
                'Not possible to enter data for non active patients';

              const eDiv = document.createElement('div');
              eDiv.innerHTML = `<div style="display: flex; align-items: center; justify-content: center; height: 22px;">${message}</div>`;
              return eDiv.innerHTML;
            },
            overlayNoRowsTemplate:
              '<span style="padding: 5px; border: 2px solid #444;">No available measures to display</span>',
            getRowStyle: (params: { node: RowNode }) => {
              if (params.node.rowPinned) {
                return {
                  color: palette.primary.main,
                  'font-weight': 'bold',
                  border: `2px solid ${palette.primary.main}`,
                };
              }
              return undefined;
            },
          };
          return gridOptions;
        }
        return undefined;
      },
      [palette.primary.main, getRowPinned, getEditableCells],
    );

    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const onGridReady = ({ api, columnApi }: any) => {
      setGridApi(api);
      // Autosize all columns.
      // to make the currently visible columns fit the screen
      // columnApi.autoSizeAllColumns();
      api.sizeColumnsToFit();
    };

    const [tableData, setTableData] = useState<TableData | null>(
      getTableData(patientMeasures),
    );
    const [columnDefs, setColumnDefs] = useState<ColumnDefs | undefined>(
      getTableCol(),
    );
    const [gridOptions, setGridOptions] = useState<GridOptions | undefined>(
      getGridOptions(patientMeasures),
    );

    useEffect(() => {
      setColumnDefs(getTableCol());
    }, [getTableCol]);

    useEffect(() => {
      setTableData(getTableData(patientMeasures));
    }, [patientMeasures, getTableData]);

    useEffect(() => {
      setGridOptions(getGridOptions(patientMeasures));
    }, [patientMeasures, getGridOptions]);

    useEffect(() => {
      if (gridApi && loadingOverlay) {
        gridApi.showLoadingOverlay();
      }
      if (gridApi && !loadingOverlay) {
        gridApi.hideOverlay();
      }
    }, [loadingOverlay, gridApi]);

    useEffect(() => {
      if (gridApi && recordSelected) {
        const number = gridApi.getDisplayedRowCount();
        if (number === 0) {
          gridApi.showNoRowsOverlay();
        }
      }
      // put focus on measureField if default unit
      if (gridApi && measureDefaultUnit) {
        gridApi.setFocusedCell(0, measureField, 'top');
      }
    }, [recordSelected, measureDefaultUnit, gridApi]);

    // *****Alert saving measures****
    const Alert = (props: AlertProps) => (
      <MuiAlert elevation={6} variant="filled" {...props} />
    );
    const open = alertOpen;
    const handleClose = () => setAlertOpen(false);

    return recordSelected && measuresTypes.length ? (
      <React.Fragment>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            onClose={handleClose}
            severity={alertError ? 'error' : 'success'}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <ErrorBoundary>
          <Table
            id={recordSelected as string}
            AgGridReact={AgGridReact}
            rowData={tableData as any}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            frameworkComponents={frameworkComponents}
            loadingOverlayComponent="customLoadingOverlay"
            rowHeight={recordSelected === 'fast' ? 35 : undefined}
          />
        </ErrorBoundary>
      </React.Fragment>
    ) : (
      <p> {texts.noMeasures}</p>
    );
  },
);

export default MeasuresTable;
