import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  FieldBasic,
  DividerFullWidth as Divider,
} from '@mummssoftware/common-ui';
import styled from 'styled-components';
import { getUpdatedClassName } from './utils';
import messages from '../CertMessages';
import { dateFormat, FORM_PHYS_VERBAL_DATE } from '../../../constants';

const DatesDiv = styled.div`
  display: flex;
`;
const VerbalDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const captionStyle = { flexBasis: '100%' };

export const CertHeader = ({
  classes,
  headerGrids,
  benStartDate,
  benEndDate,
  formPhysVerbalDate,
  updates,
  verbalTakenBy,
  smallWidget,
}) =>
  smallWidget ? (
    <Grid
      className={classes.gridContainer}
      style={{ whiteSpace: 'pre' }}
      item
      container
      direction="column"
      xs={12}
    >
      <DatesDiv>
        <FieldBasic
          key="ben-start"
          style={smallWidget ? { fontSize: '14px' } : {}}
          classes={classes}
          gridProps={headerGrids}
          label={
            <FormattedMessage
              key="ben-start-label"
              {...messages.benStartDate}
            />
          }
          data={<FormattedDate value={benStartDate} {...dateFormat} />}
        />
        <FieldBasic
          key="ben-end"
          style={smallWidget ? { fontSize: '14px' } : {}}
          classes={classes}
          gridProps={headerGrids}
          label={
            <FormattedMessage key="ben-end-label" {...messages.benEndDate} />
          }
          data={<FormattedDate value={benEndDate} {...dateFormat} />}
        />
        {formPhysVerbalDate ? (
          <FieldBasic
            key="verb-date"
            style={smallWidget ? { fontSize: '14px' } : {}}
            classes={classes}
            gridProps={headerGrids}
            label={
              <FormattedMessage
                key="verbal-date-label"
                {...messages.verbalDate}
              />
            }
            data={<FormattedDate value={formPhysVerbalDate} {...dateFormat} />}
            DataTypographyProps={{
              className: getUpdatedClassName(
                classes,
                updates,
                FORM_PHYS_VERBAL_DATE,
              ),
            }}
          />
        ) : null}
      </DatesDiv>
      <VerbalDiv>
        {verbalTakenBy ? (
          <Typography variant="caption" style={captionStyle}>
            <FormattedMessage
              {...messages.verbalOrderBy}
              values={{ name: verbalTakenBy }}
            />
          </Typography>
        ) : null}
      </VerbalDiv>
      <Divider style={{ width: '100%' }} />
    </Grid>
  ) : (
    <Grid
      className={classes.gridContainer}
      style={{ whiteSpace: 'pre' }}
      item
      container
      direction="row"
      xs={12}
    >
      <FieldBasic
        key="ben-start"
        style={smallWidget ? { fontSize: '14px' } : {}}
        classes={classes}
        gridProps={headerGrids}
        label={
          <FormattedMessage key="ben-start-label" {...messages.benStartDate} />
        }
        data={<FormattedDate value={benStartDate} {...dateFormat} />}
      />
      <FieldBasic
        key="ben-end"
        style={smallWidget ? { fontSize: '14px' } : {}}
        classes={classes}
        gridProps={headerGrids}
        label={
          <FormattedMessage key="ben-end-label" {...messages.benEndDate} />
        }
        data={<FormattedDate value={benEndDate} {...dateFormat} />}
      />
      {formPhysVerbalDate ? (
        <Grid item container {...headerGrids} direction="row-reverse">
          <FieldBasic
            key="verb-date"
            style={smallWidget ? { fontSize: '14px' } : {}}
            classes={classes}
            label={
              <FormattedMessage
                key="verbal-date-label"
                {...messages.verbalDate}
              />
            }
            data={<FormattedDate value={formPhysVerbalDate} {...dateFormat} />}
            DataTypographyProps={{
              className: getUpdatedClassName(
                classes,
                updates,
                FORM_PHYS_VERBAL_DATE,
              ),
            }}
          />
          {verbalTakenBy ? (
            <Typography variant="caption" style={captionStyle}>
              <FormattedMessage
                {...messages.verbalOrderBy}
                values={{ name: verbalTakenBy }}
              />
            </Typography>
          ) : null}
        </Grid>
      ) : null}
      <Divider style={{ width: '100%' }} />
    </Grid>
  );
