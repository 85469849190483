/* eslint-disable camelcase */

import { createContext } from 'react';
import { DEFAULT_LOCALE } from './i18n';

export type patientQuestionnaire = {
  instanceId: string | null;
  date: string | null;
  time: string | null;
  type: string | null;
  name: string | null;
  worker: string | null;
  discipline: string | null;
  html: string | null;
};

export type patientQuestionnaires = [patientQuestionnaire?];

export type questionnaire = {
  q_name: string | null;
  q_id: string | null;
  q_file_name: string | null;
  q_type: string | null;
  patient: string | null;
  created_on_worker: string | null;
  created_on_date: string | null;
  last_edited_on_worker: string | null;
  last_edited_on_date: string | null;
  html?: string | null;
};

export type questionnaires = [questionnaire?];

export type ManifestContext = {
  keycloak: Keycloak.KeycloakInstance;
  currentUser: mumms.User;
  locale: keyof mumms.translations;
  patient?: mumms.patient;
  agency?: string | null;
  patientError?: {
    status: number;
    statusText?: string;
    patientNumber?: string;
  };
  questionnaires?: questionnaires;
  chartMetadata: {
    dateFrom?: string | null;
    dateTo?: string | null;
  };
  onClick?: () => void;
  appTitle?: string;
  embedded?: boolean;
  smallWidget?: boolean;
  watermark?: {
    show: boolean;
    readOnly: boolean;
  };
  viewMode?: mumms.viewMode;
  printConfig?: mumms.printConfig;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};

const initManifestContext = {
  locale: DEFAULT_LOCALE,
};

export const ManifestContext = createContext<ManifestContext>(
  initManifestContext as ManifestContext,
);
export const ManifestContextProvider = ManifestContext.Provider;
export const ManifestContextConsumer = ManifestContext.Consumer;
