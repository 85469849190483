import React from 'react';
import styled from 'styled-components';
import { Container as BaseMuiContainer } from '@material-ui/core';

type WrapProps = {
  'no-pad'?: boolean;
};

type ContainerProps = {
  children: Omit<React.ReactNode, 'undefined'>;
  className?: string;
  noPad?: boolean;
};

const Wrap = styled.div<WrapProps>`
  width: calc(100vw - 40px);
  background-color: #454545;
  margin: 0 20px 0 20px;
  overflow: visible;
  ${(props) => (props['no-pad'] ? '' : 'padding: 15px 0;')}
  ${(props) => props.theme.breakpoints.down('xs')} {
    width: calc(100vw - 20px);
    margin: 0 5px 0 5px;
  }
  @media print {
    visibility: hidden;
    overflow: visible;
  }
`;

const MuiContainer = styled(BaseMuiContainer)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    && {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  @media print {
    visibility: hidden;
    overflow: visible;
  }
`;

export const Container = ({ children, className, noPad }: ContainerProps) => (
  <Wrap no-pad={noPad} className={className}>
    <MuiContainer>{children}</MuiContainer>
  </Wrap>
);

export default Container;
