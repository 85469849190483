import { defineMessages } from 'react-intl';
export default defineMessages({
  logout: {
    id: 'common.nav.logout',
    defaultMessage: 'Logout'
  },
  site: {
    id: 'common.patientHeader.site',
    defaultMessage: 'Site of Admission'
  },
  phone: {
    id: 'common.patientHeader.phone',
    defaultMessage: 'Phone'
  },
  diagnosis: {
    id: 'common.patientHeader.diagnosis',
    defaultMessage: 'Diagnosis'
  },
  allergies: {
    id: 'common.patientHeader.allergies',
    defaultMessage: 'Allergies'
  },
  insurance: {
    id: 'common.patientHeader.insurance',
    defaultMessage: 'Insurance'
  },
  referredBy: {
    id: 'common.patientHeader.referredBy',
    defaultMessage: 'Referred By'
  },
  phys: {
    id: 'common.patientHeader.phys',
    defaultMessage: 'Phys'
  },
  dob: {
    id: 'common.patientHeader.dob',
    defaultMessage: 'DOB'
  },
  city: {
    id: 'common.patientHeader.city',
    defaultMessage: 'City'
  },
  street: {
    id: 'common.patientHeader.street',
    defaultMessage: 'Street'
  },
  state: {
    id: 'common.patientHeader.state',
    defaultMessage: 'State'
  },
  location: {
    id: 'common.patientHeader.location',
    defaultMessage: 'LOC'
  },
  zip: {
    id: 'common.patientHeader.zip',
    defaultMessage: 'Zip'
  },
  at: {
    id: 'common.patientHeader.at',
    defaultMessage: 'at'
  },
  to: {
    id: 'common.patientHeader.to',
    defaultMessage: 'to'
  },
  from: {
    id: 'common.patientHeader.from',
    defaultMessage: 'from'
  },
  asof: {
    id: 'common.patientHeader.asof',
    defaultMessage: 'as of'
  },
  unknown: {
    id: 'common.patientHeader.unknown',
    defaultMessage: 'Unknown'
  },
  referred: {
    id: 'common.patientHeader.referred',
    defaultMessage: 'Referred'
  },
  admitted: {
    id: 'common.patientHeader.admitted',
    defaultMessage: 'Admitted'
  },
  discharged: {
    id: 'common.patientHeader.discharged',
    defaultMessage: 'Discharged'
  },
  reason: {
    id: 'common.patientHeader.reason',
    defaultMessage: 'Reason'
  },
  noPhysician: {
    id: 'common.patientHeader.noPhysician',
    defaultMessage: 'No physician'
  },
  gender: {
    id: 'common.patientHeader.gender',
    defaultMessage: 'Gender'
  },
  county: {
    id: 'common.patientHeader.county',
    defaultMessage: 'County'
  }
});