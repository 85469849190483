import { createContext } from 'react';
import { Pim } from '@mummssoftware/utils';
import { DEFAULT_LOCALE } from './i18n';
import { Config, DashboardType } from './containers/Context/types';

export type Context = {
  keycloak: Keycloak.KeycloakInstance;
  currentUser: mumms.User;
  locale: keyof mumms.translations;
  patient?: mumms.patient;
  pim: Pim;
  config: Config;
  patientError?: {
    status: number;
    statusText?: string;
    patientNumber?: string;
  };
  onDashboardThemeChange: (arg: string) => void;
  dashboardType: DashboardType;
  siteId?: string | null;
};

const initContext = {
  locale: DEFAULT_LOCALE,
};

export const Context = createContext<Context>(initContext as Context);
export const ContextProvider = Context.Provider;
export const ContextConsumer = Context.Consumer;
