import React, { Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { LibraryAdd, FileCopy } from '@material-ui/icons';
import { MultilineTextField } from '@mummssoftware/common-ui';

import CertBodyGrid from './BodyGrid';
import { formatNarrativeVal, getUpdatedClassName } from './utils';
import messages from '../CertMessages';
import { FORM_NARRATIVE_ID } from '../../../constants';

const CertNarrativeInput = ({
  alreadyCertified,
  captureNarrative,
  copyText,
  classes,
  formatMessage,
  isDoctor,
  narrative,
  pasteText,
  print,
  selectedCertIndex,
  submitting,
  takenBy,
  textCopy,
  updates,
  smallWidget,
}) => (
  <Fragment>
    <MultilineTextField
      required
      key={`cert-${selectedCertIndex}`}
      id="narrative-field"
      label={alreadyCertified ? '' : formatMessage(messages.Required)}
      value={
        alreadyCertified && !narrative
          ? formatNarrativeVal(formatMessage, takenBy)
          : narrative
      }
      style={smallWidget ? { fontSize: '14px' } : {}}
      onChange={captureNarrative('narrative')}
      InputLabelProps={{
        shrink: print,
      }}
      InputProps={{
        readOnly: alreadyCertified,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        className: getUpdatedClassName(classes, updates, FORM_NARRATIVE_ID),
      }}
      disabled={!isDoctor || alreadyCertified || submitting}
    />

    {print || (alreadyCertified && !narrative) ? null : (
      <Tooltip title={textCopy === '' ? formatMessage(messages.copyText) : ''}>
        <IconButton
          classes={{ root: classes.copyIcon }}
          color="primary"
          disabled={textCopy !== '' && alreadyCertified}
          onClick={textCopy === '' ? copyText : pasteText}
        >
          {textCopy === '' ? <LibraryAdd /> : <FileCopy />}
        </IconButton>
      </Tooltip>
    )}
  </Fragment>
);

export const CertNarrativeField = ({
  historicalView,
  narrative,
  inputProps,
  smallWidget,
  trailingMessage,
}) => (
  <Fragment>
    {historicalView ? (
      <CertBodyGrid
        typographyProps={{ color: 'textSecondary' }}
        smallWidget={smallWidget}
      >
        {narrative}
        {trailingMessage}
      </CertBodyGrid>
    ) : (
      <CertNarrativeInput
        narrative={narrative}
        smallWidget={smallWidget}
        {...inputProps}
      />
    )}
  </Fragment>
);
