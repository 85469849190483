import { isObjectLike } from 'lodash-es';
import moment from 'moment-timezone';
import { getDiagnosisValue } from '@mummssoftware/utils/data';

import {
  getPrimaryDiagnosis,
  getPerfScoreData,
  perfScoreMetadata,
} from '@mummssoftware/utils/patient';
import selectboxes from 'formiojs/components/selectboxes/SelectBoxes';
import {
  isHTMLComponent,
  setComponentProperty,
  setComponentPropAndRebuild,
  getPatientScaler,
} from './utils/formUtils';

import type {
  comparatorKeys,
  AnyComponent,
  submission as formioSubmission,
  FormsExtendedPatient,
} from './types';


export type setValueArgs = {
  component: AnyComponent;
  submission: formioSubmission;
  components?: AnyComponent[];
  instance?: any;
  data?: any;
  utils?: any;
};

type setMetadataOptions = {
  patientProp?: keyof FormsExtendedPatient;
  getter?: (
    patient: FormsExtendedPatient,
  ) =>
    | FormsExtendedPatient[keyof FormsExtendedPatient]
    | Record<string, any>
    | undefined;
  metadataProp?: string;
  altDataSource?: mumms.JSONValue;
};

const heartModifiers = ['ischemic', 'congestive'] as const;
const comparators = {
  greaterThan: (value: number, control: number) => value > control,
  lessThan: (value: number, control: number) => value < control,
};

const parseComparatorArgs = (value = '', control = '') => ({
  value: parseInt(value, 10),
  control: parseInt(control, 10),
});

const setNumberValues = (
  component: AnyComponent,
  {
    value,
    control,
    comparator,
    property = '',
  }: {
    value: number;
    control: number;
    comparator?: comparatorKeys;
    property?: string;
  },
) => {
  if (!comparator || !comparators[comparator]) {
    return;
  }
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value) && !isNaN(control)) {
    if (isObjectLike(component.emptyValue)) {
      const currentValue = component.getValue();
      component.setValue({
        ...(currentValue || {}),
        [property]: comparators[comparator](value, control),
      });
    } else {
      component.setValue(comparators[comparator](value, control));
    }
  }
};

// Robert's version of LCD - not using
const setHtmlElementMessage = (
  refs: Record<string, HTMLElement>,
  message: any,
) => {
  if (refs.messageContainer) {
    if (message.message) {
      const msgSpan = document.createElement('span');
      // eslint-disable-next-line no-unused-expressions
      refs.html?.style.setProperty('margin-bottom', '.25rem');
      msgSpan.innerText = message.message;
      if (message.valid) {
        refs.messageContainer.classList.add('text-secondary');
      } else {
        refs.messageContainer.classList.remove('text-secondary');
        msgSpan.classList.add('error');
      }
      refs.messageContainer.innerHTML = '';
      refs.messageContainer.append(msgSpan);
    }
  }
};

/**
 * Hooks can be set up in the formio interface. Navigate to the form you want to add a
 * hook to, scroll down to `Form Controller`, and add the JavaScript to call them there.
 * @example
 * instance.once(
 *   'render',
 *   () =>
 *     // juuust in case the instance has no parent.
 *     instance.parent &&
 *     instance.parent.hook('setComorbidities', {
 *       component: utils.getComponent(components, 'comorbidities'),
 *     }),
 * );
 *
 * @note I imagine this will change a fair amount from this current implementation.
 */
// TODO: Generic 'translate html components' hook?
export default (patient?: FormsExtendedPatient) => {
  const setMetadata = (
    submission: formioSubmission,
    {
      patientProp,
      getter = (pat) => (patientProp ? pat[patientProp] : null),
      metadataProp,
      altDataSource,
    }: setMetadataOptions,
  ) => {
    if (submission) {
      let value;
      submission.metadata = submission.metadata || {};
      if (
        typeof patient !== 'undefined' &&
        patientProp &&
        patient[patientProp]
      ) {
        value = getter(patient);
      } else if (altDataSource && metadataProp) {
        submission.metadata[metadataProp] = altDataSource;
      }
      if (typeof value !== 'undefined' && (metadataProp || patientProp)) {
        submission.metadata[metadataProp || (patientProp as string)] = value;
      }
    }
  };

  const validCall = (
    component: AnyComponent | selectboxes | undefined,
    submission: formioSubmission | undefined,
    metadataProp: string,
  ) => component && submission?.metadata?.[metadataProp];

  return {
    //  Robert's version of LCD - not using
    setAge({ component, submission }: setValueArgs) {
      const prop = 'age';
      setMetadata(submission, { patientProp: prop });
      if (validCall(component, submission, prop)) {
        const { age = '', comparator } = component.component.properties || {};
        setNumberValues(component, {
          ...parseComparatorArgs(submission.metadata.age, age),
          comparator,
          property: prop,
        });
      }
    },
    setComorbidities({ component }: setValueArgs) {
      if (
        component &&
        typeof patient !== 'undefined' &&
        patient.diagnosisDTO &&
        !component.computedValuesSet
      ) {
        const originalValue = component.getValue();
        const defaultValue = [
          ...patient.diagnosisDTO,
          ...(patient.additionalDiagnosis || []),
        ].reduce<Record<string, boolean>>(
          (values, { diagClass, description, rank }) => {
            if (rank !== 1) {
              let value: string = getDiagnosisValue(diagClass, true);
              if (value === 'heart') {
                const modifier =
                  heartModifiers[
                    heartModifiers.findIndex((mod) =>
                      description.toLowerCase().includes(mod),
                    )
                  ];
                if (modifier) {
                  value = `${value}-${modifier}`;
                }
              }
              values[value] = true;
            }
            return values;
          },
          {},
        );
        component.setValue({ ...originalValue, ...defaultValue });
        component.computedValuesSet = true;
      }
    },
    // TODO: This function got overcomplicated...
    async setPerformanceScaleDetails({ component, submission }: setValueArgs) {
      setMetadata(submission, {
        patientProp: 'latestScores',
        getter: getPerfScoreData,
        metadataProp: 'perfScale',
      });
      const reRunHook = (comp: AnyComponent, args: setValueArgs) => () =>
        comp.root.parent
          ? comp.root.parent.hook('setPerformanceScaleDetails', args)
          : comp.root.hook('setPerformanceScaleDetails', args);
      if (component) {
        await component.ready;
        const { perfScale } =
          (submission?.metadata as {
            perfScale?: perfScoreMetadata;
          }) || {};
        const {
          namespace = 'common',
          perfKey = 'longName',
          type = perfScale?.type,
          fallbackType,
          comparator,
          comparatorValue,
        } =
          (component.component
            .properties as typeof component.component.properties & {
            type?: perfScoreMetadata['type'];
            fallbackType?: string;
          }) || {};
        let context: string | undefined;
        if (!type) {
          context = 'either';
        }
        const scaleName = component.t(
          type ? `${namespace}:${perfKey}.${type}` : 'common:eitherPerfScale',
        );
        const value = type && perfScale?.[type]?.score;
        let valid: undefined | boolean;
        component.previousVisibility = component.visible;
        if (comparator && typeof value === 'number') {
          const control = parseInt(comparatorValue as string, 10);
          valid = comparators[comparator](value, control);
        }
        const scaleAbbr = component.t(type || 'common:eitherPerfScaleAbbr');
        const label = component.t(component.label, {
          scaleInfo:
            context === 'either' ? scaleName : `${scaleName} (${scaleAbbr})`,
          scaleAbbr: component.t(type || 'common:eitherPerfScaleAbbr'),
        });
        // TODO score data performance scale KPS or PPS
        if (
          isHTMLComponent(component) &&
          typeof type === 'string' &&
          (typeof valid === 'boolean' || !fallbackType)
        ) {
          component.setContent(component.refs.html, label);
          const msgValid = [
            'some:valid',
            'Criteria met with latest {{scaleAbbr}} score of {{value}}%, recorded on {{date}}',
          ];
          const msgInvalid = [
            'some:invalid',
            'Criteria not met with latest {{scaleAbbr}} score of {{value}}%, recorded on {{date}}',
          ];
          setHtmlElementMessage(component.refs as Record<string, HTMLElement>, {
            message: component.t(valid ? msgValid : msgInvalid, {
              value: (perfScale as perfScoreMetadata)[type]?.score.toString(),
              scaleAbbr: component.t(type as string),
              date: moment((perfScale as perfScoreMetadata).date).format(
                'MMM DD, YYYY',
              ),
            }),
            valid,
          });
          component.setErrorClasses([component.refs.html], false, !valid, true);
        } else if (isHTMLComponent(component) && fallbackType) {
          if (component.root.changing) {
            component.once(
              'change',
              () =>
                setComponentPropAndRebuild(
                  component,
                  'type',
                  fallbackType,
                  label,
                ),
              false,
            );
          } else {
            setComponentPropAndRebuild(component, 'type', fallbackType, label);
          }
        } else if (component.label !== label && !component.root.changing) {
          setComponentPropAndRebuild(component, 'label', label, label);
        }
        const args = {
          component,
          submission,
        };
        component.once('change', reRunHook(component, args), false);
      }
    },
    setPrimaryDiagnosis({ component, submission }: setValueArgs) {
      const property = 'primaryDiagnosis';
      const diagValue = getDiagnosisValue(
        getPrimaryDiagnosis([...(patient?.diagnosisDTO || [])])?.diagClass,
      );
      setMetadata(submission, {
        metadataProp: property,
        altDataSource: diagValue,
      });
      let value: string | undefined;
      if (
        component &&
        component.visible &&
        !component.disabled &&
        diagValue &&
        (!component.hasValue() || component.getValue() === '')
      ) {
        value = diagValue;
        setComponentProperty(component, 'disabled', true);
        setComponentProperty(
          component,
          'label',
          (component.label = component.label?.endsWith('-disabled')
            ? component.label
            : `${component.label}-disabled`),
        );
        component.setValue(value);
      } else if (component.hasValue()) {
        value = component.getValue();
      }
      return value;
    },
    async setHiddenValues({ component, submission }: setValueArgs) {
      const reRunHook = (comp: AnyComponent, args: setValueArgs) => () =>
        comp.root.parent
          ? comp.root.parent.hook('setHiddenValues', args)
          : comp.root.hook('setHiddenValues', args);
      if (component) {
        await component.ready;
        const componentkey = component.key; // the formio api key
        let value;

        value = getPatientScaler(componentkey, patient);
        // todo how do i access this function in the scaler func?
        if (componentkey === 'primarydiagnosis') {
          value = getDiagnosisValue(value);
        }
        if (value == null || !value.trim()) {
          value = 'Not Found';
        }

        setComponentProperty(component, 'disabled', true);
        // component.setValue(value);
        // return value;
        let isempty = false;
        if (component.getValue() == null || !component.getValue().trim()) {
          isempty = true;
        }
        component.disabled = true;
        if (isempty) {
          component.setValue(value);
        }
      }
    },
    async setPhysiologicImpairmentQuestion({
      component,
      submission,
    }: setValueArgs) {
      const reRunHook = (comp: AnyComponent, args: setValueArgs) => () =>
        comp.root.parent
          ? comp.root.parent.hook('setPhysiologicImpairmentQuestion', args)
          : comp.root.hook('setPhysiologicImpairmentQuestion', args);
      if (component) {
        await component.ready;
        let context: string | undefined;
        const type = patient?.physimpairmentscore?.lowestperftype;
        const namespace = 'common';
        const perfKey = 'longName';
        const scaleAbbr = component.t(type || 'common:eitherPerfScaleAbbr');

        if (!type) {
          context = 'either';
        }
        const scaleName = component.t(
          type ? `${namespace}:${perfKey}.${type}` : 'common:eitherPerfScale',
        );
        const label = component.t(component.label, {
          scaleInfo:
            context === 'either' ? scaleName : `${scaleName} (${scaleAbbr})`,
          scaleAbbr: component.t(type || 'common:eitherPerfScaleAbbr'),
        });

        let isempty = false;
        if (component.getValue() == null || !component.getValue().trim()) {
          isempty = true;
        }
        component.disabled = true;
        if (isempty) {
          component.setValue(label);
        }
      }
    },
    async setPhysiologicImpairmentCriteriaMet({
      component,
      submission,
    }: setValueArgs) {
      const reRunHook = (comp: AnyComponent, args: setValueArgs) => () =>
        comp.root.parent
          ? comp.root.parent.hook('setPhysiologicImpairmentCriteriaMet', args)
          : comp.root.hook('setPhysiologicImpairmentCriteriaMet', args);
      if (component) {
        await component.ready;
        let context: string | undefined;
        const scorefound = patient?.physimpairmentscore?.scorefound;
        const criteriamet = patient?.physimpairmentscore?.criteriamet;
        const scaleAbbr = patient?.physimpairmentscore?.lowestperftype;
        const score = patient?.physimpairmentscore?.lowestperfscore;
        const scoreDate = moment(
          patient?.physimpairmentscore?.lowestperfdate,
        ).format('MMM DD, YYYY');

        const value = component.t(component.label, {
          met: criteriamet,
          scaleAbbr,
          score,
          scoreDate,
        });
        let isempty = false;
        if (component.getValue() == null || !component.getValue().trim()) {
          isempty = true;
        }
        component.disabled = true;
        if (isempty) {
          component.setValue(value);
        }
      }
    },
    /*  a test of setting criteriametmsg content as html 
   async setHiddenValuesHTML({ component, submission }: setValueArgs) {
      const reRunHook = (comp: AnyComponent, args: setValueArgs) => () =>
        comp.root.parent
          ? comp.root.parent.hook('setHiddenValuesHTML', args)
          : comp.root.hook('setHiddenValuesHTML', args);
      if (component && isHTMLComponent(component)) {
        await component.ready;
        const componentkey = component.key; // the formio api key
        let content;
        content = patient?.physimpairmentscore?.criteriametmsg;
        const msgSpan = document.createElement('span');
        // eslint-disable-next-line no-unused-expressions
        msgSpan.innerText = content;
        component.refs.messageContainer?.append(msgSpan);
        setComponentProperty(component, 'disabled', true);
      }
    }, */
    setDiagnoses({ component }: setValueArgs) {
      let relatedcomp = false;
      // const relatedcomp: boolean = component['isrelated'].value;
      // hack i'd like to get this from the custom property but dont know how
      if (component.key === 'relateddiagnoses') {
        relatedcomp = true;
      }
      if (
        component &&
        typeof patient !== 'undefined' &&
        patient.diagnosisDTO &&
        !component.computedValuesSet
      ) {
        const defaultValue = [
          ...patient.diagnosisDTO,
          ...(patient.additionalDiagnosis || []),
        ].reduce(
          (
            values,
            { diagClass, icd10diagnosis, icd10description, relatedTo, rank },
          ) => {
            let newvalues = '';
            if ((relatedTo && relatedcomp) || (!relatedTo && !relatedcomp)) {
              let value = `<tr><td >${  icd10diagnosis  }</td>`;
              // seems to cut data off: style="padding-left: 10px; padding-right: 10px;
              value =
                `${value 
                }<td>` +
                `&nbsp; &nbsp; &nbsp;${ 
                icd10description 
                }</td></tr>`;
              newvalues = values + value;
            } else {
              newvalues = values;
            }
            return newvalues;
          },
          '',
        );
        const strval = `<BR><TABLE>${  String(defaultValue)  }</TABLE><BR>`;

        let isempty = false;
        if (component.getValue() == null || !component.getValue().trim()) {
          isempty = true;
        }

        component.disabled = true;
        if (isempty) {
          component.setValue(strval);
        }

        component.computedValuesSet = true;
      }
    },
    // testing stuff
    // setDiagnosesGrid({ component }: setValueArgs) {
    //   let testgrid = [
    //     { icd10code: 'code1', icd10description: 'description1' },
    //     { icd10code: 'code2', icd10description: 'description2' },
    //   ];
    //   if (component.key === 'relateddiaggrid') {
    //     component.setValue(testgrid);
    //   }
    //   component.computedValuesSet = true;
    //   return testgrid;
    // },
    // setSelect({ component }: setValueArgs) {
    //   let test = [
    //     { label: 'choice3', value: 'choice3', shortcut: '' },
    //     { label: 'choice4', value: 'choice4', shortcut: '' },
    //   ];
    //   let selbox: selectboxes;
    //   //selbox.values = test;
    //   // if (isSelectBoxesComponent(component)) {
    //   //   component.pro = test;
    //   // }
    // },

    // this is working on create, but not on displaying filled out form
    // not called right now -  en.json replace 'Hospice Agency' in
    // electionaddendum.right.instructions-a
    //  with {{hospiceName}} and call this hook
    // - the issue is it doesn't work with html  - make it textfield
    setHospiceName({ component }: setValueArgs) {
      // const hospicename = patient.currentUser.hospiceName;
      const origcontent = component.label;
      const valid = true;
      const content = component.t(origcontent, {
        hospiceName: patient?.hospiceName,
      });
    },
    setNotCoveredMeds({ component }: setValueArgs) {
      if (
        component &&
        typeof patient !== 'undefined' &&
        patient.notCoveredMeds &&
        !component.computedValuesSet
      ) {
        const defaultValue = [...patient.notCoveredMeds].reduce(
          (values, { drugdescription, notcoveredreason }) => {
            let newvalues = '';
            let value = `<tr><td >${  drugdescription  }</td>`;
            value =
              `${value 
              }<td>` +
              `&nbsp; &nbsp; &nbsp;${ 
              notcoveredreason 
              }</td></tr>`;
            newvalues = values + value;
            return newvalues;
          },
          '',
        );
        const strval = `<BR><TABLE>${  String(defaultValue)  }</TABLE><BR>`;

        let isempty = false;
        if (component.getValue() == null || !component.getValue().trim()) {
          isempty = true;
        }

        component.disabled = true;
        if (isempty) {
          component.setValue(strval);
        }

        component.computedValuesSet = true;
      }
    },
    utils: {
      setMetadata,
      validCall,
    },
  };
};
