import { useResizeObserver } from '../DimensionUtils/ResizeProvider';
export const useComponentHeight = passedRef => {
  const {
    height
  } = useResizeObserver(passedRef);
  return height;
};
/**
 * @component
 * Component for passing height of ref component to children
 *
 * @example class SomeComponent extends React.Component {
 *            ref = React.createRef();
 *
 *            render() {
 *              return (
 *                <div>
 *                  <div ref={this.ref}>This supplies height</div>
 *                  <HeightProvider passedRef={this.ref}>
 *                    {height => (
 *                      <span>Height supplier is {height}px tall</span>
 *                    )}
 *                  </HeightProvider>
 *                </div>
 *              )
 *            }
 *          };
 */

export const HeightProvider = ({
  children,
  passedRef
}) => children(useComponentHeight(passedRef));