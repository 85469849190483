import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  ScoresMethodsDiv,
  MethodButtonsDiv,
} from '../layout/WrapperDiv';
import {
  observationMethods,
  SET_SCORES_METHOD,
  RESET_SCORES,
  SET_SUBMIT_ACTIVE,
} from '../../containers/MeasuresContext/utils/constants';
import { MeasuresContext } from '../../containers/MeasuresContext/MeasuresContextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 5px',
    margin: 5,
    backgroundColor: (theme.palette as any).scores.color,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
  focused: {
    padding: '2px 5px',
    margin: 5,
    backgroundColor: (theme.palette as any).graph.graphColor,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
}));

export const ScoresMethods = () => {
  const classes = useStyles();
  const { dispatch, methodSelected } = useContext(MeasuresContext);
  const scoresState = { ...[classes.root, classes.root] };
  const [classState, setClassState] = useState(scoresState);
  const onClick = (method: string, i: any) => {
    setClassState({ ...scoresState, [i]: classes.focused });
    dispatch({
      type: SET_SCORES_METHOD,
      payload: method,
    });
    dispatch({
      type: RESET_SCORES,
    });
    dispatch({
      type: SET_SUBMIT_ACTIVE,
      payload: false,
    });
  };

  return (
    <ScoresMethodsDiv>
      <MethodButtonsDiv>
        {observationMethods.map((method, i) => (
          <Button
            key={method}
            variant="contained"
            className={methodSelected ? classState[i] : classes.root}
            onClick={() => onClick(method, i)}
          >
            {`Record ${method}`}
          </Button>
        ))}
      </MethodButtonsDiv>
    </ScoresMethodsDiv>
  );
};
