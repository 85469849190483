/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useEffect, useContext } from 'react';
import { WidgetPrintHeader, NoRecordsPrintDiv } from '@mummssoftware/common-ui';
import { Context } from '../../context';
import { AllPrintMeasuresDefined } from '../MeasuresContext/utils/type';
import { TabularView } from '../MeasuresContext/utils/constants';
import defaults from '../../translations/en.json';
import { PageBreak } from '../../components/layout/WrapperDiv';
import MeasuresTablePrint from './MeasuresTablePrint';
import MeasuresGraphPrint from './MeasuresGraphPrint';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type Measures = mumms.measureByType;

type MeasuresPrintProps = {
  allMeasures: AllPrintMeasuresDefined;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};
const texts: NonNullable<mumms.JSONObject> = defaults;

export const MeasuresPrint = React.memo(
  ({ allMeasures, onWidgetReady }: MeasuresPrintProps) => {
    const { measures } = allMeasures;
    const { printConfig, measuresError } = useContext(Context);

    // signal to dashboard the print component is mounted
    useEffect(() => {
      onWidgetReady && onWidgetReady('Measures' as mumms.ComponentName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMeasuresPrintLayout = () =>
      measures && Object.keys(measures).length ? (
        getMeasuresPrint()
      ) : (
        <NoRecordsPrintDiv>
          <p> {texts.noMeasuresDateRange}</p>
        </NoRecordsPrintDiv>
      );
    // as of now we are not using TabularView but we could in the future
    const getMeasuresPrint = () =>
      printConfig && printConfig.view === TabularView ? (
        <MeasuresTablePrint measures={measures} />
      ) : (
        <MeasuresGraphPrint allMeasures={allMeasures} />
      );

    return (
      <React.Fragment>
        <WidgetPrintHeader
          title={texts.printTitle as NonNullable<mumms.JSONObject>}
          printConfig={printConfig}
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {measuresError ? (
          <NoRecordsPrintDiv>
            <p> {texts.errorMeasures}</p>
          </NoRecordsPrintDiv>
        ) : (
          getMeasuresPrintLayout()
        )}
        <PageBreak />
      </React.Fragment>
    );
  },
);

export default MeasuresPrint;
