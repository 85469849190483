import moment from 'moment-timezone';
// todo put in utils
/**
 *  format DATA in UTC for POST
 */
export const formatPostDate = (date: Date | string | number | moment.Moment) =>
  `${moment.utc(date).format('YYYY-MM-DD HH:mm:ss')}`;

export const formatFullTableDate = (
  date: Date | string | number | moment.Moment,
) => `${moment(date).format('MM-DD-YY @ HH:mm')}`;
