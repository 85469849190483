import { i18n } from '@mummssoftware/utils';
import enTranslationMessages from './translations/en.json';
import esTranslationMessages from './translations/es.json';

export const {
  translationMessages,
  flattenedTranslationMessages,
} = i18n.initTranslationMessages(enTranslationMessages, esTranslationMessages);

export const { DEFAULT_LOCALE } = i18n;
