/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  HeightProvider,
  TopBar as MeasuresTopBar,
} from '@mummssoftware/common-ui';
import {
  AllMeasures,
  AllMeasuresDefined,
  AllPrintMeasuresDefined,
} from '../MeasuresContext/utils/type';
import MeasuresComponent from '../Measures';
import NotFound from '../NotFound';
import MeasuresPrint from '../MeasuresPrint';

type BaseAppProps = {
  embedded?: boolean;
  noWidgetHeader?: boolean;
  currentUser: mumms.User;
  logout: () => void;
  patient?: mumms.patient;
  allMeasures: AllMeasures | AllPrintMeasuresDefined;
  onClick: () => void;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
  viewMode?: mumms.viewMode;
};
type AppProps = BaseAppProps & RouteComponentProps;

const AppRoot = styled.div<{ embedded?: boolean }>`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
  ${(props) => (props.embedded ? `height: initial;` : `height: 100vh;`)}
  display: flex;
  @media print {
    display: block;
    height: auto;
  }
`;

const AppContent = styled.div<{ height: number; embedded?: boolean }>`
  overflow: auto;
  margin-top: ${(props) => props.height}px;
  width: 100%;
  ${(props) =>
    props.embedded ? `;` : `height: calc(100vh - ${props.height}px)`};
  @media print {
    height: auto;
  }
`;

const mainPaths = ['/'];
class App extends React.PureComponent<AppProps> {
  headerRef = createRef<HTMLElement>();

  render() {
    const {
      currentUser,
      logout,
      patient,
      embedded,
      allMeasures,
      onClick,
      noWidgetHeader,
      viewMode,
      onWidgetReady,
    } = this.props;
    const { measures, measuresTypes } = allMeasures;

    const viewModePrint: boolean = viewMode === 'print';
    // this will be used when dashboard viewMode is using full and small
    const viewModeFull: boolean = viewMode === 'full';
    const viewModeSmall: boolean = viewMode === 'small';

    return (
      <React.Fragment>
        {viewModePrint && (
          <MeasuresPrint
            onWidgetReady={onWidgetReady}
            allMeasures={allMeasures as AllPrintMeasuresDefined}
          />
        )}
        {!viewModePrint && (
          <AppRoot embedded={embedded}>
            {/* if not in widget dashboard show the top bar , mainly stand alone development */}
            {!embedded && (
              <MeasuresTopBar
                ref={this.headerRef}
                title="Measures"
                subtitle={
                  patient ? `${patient.firstName} ${patient.lastName}` : null
                }
                disableSitePicker
                currentUser={currentUser}
                logout={logout}
              />
            )}

            <HeightProvider passedRef={this.headerRef}>
              {(height: any) => (
                <AppContent height={height} embedded={embedded}>
                  <Switch>
                    {patient && measures && measuresTypes ? (
                      <Route
                        path={mainPaths}
                        render={(props) => (
                          <MeasuresComponent
                            noWidgetHeader={noWidgetHeader}
                            patient={patient}
                            currentUser={currentUser}
                            onClick={onClick}
                            // allMeasures is changing type
                            allMeasures={allMeasures as AllMeasuresDefined}
                            {...props}
                          />
                        )}
                      />
                    ) : null}
                    <Route
                      path="/"
                      render={(props) => <NotFound {...props} />}
                    />
                  </Switch>
                </AppContent>
              )}
            </HeightProvider>
          </AppRoot>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(App);
