import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import {
  ActionIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  gridContainerStyle as gridContainer,
  SectionHeader,
} from '@mummssoftware/common-ui';
import { formatAddressMultiLine } from '@mummssoftware/utils/formatters';
import {
  messages,
  PatientHeader,
  PatientAssignments,
  PatientEpisode,
  PatientDemographics,
  DNRPhys,
  PayerLOC,
} from './Components';

const styles = (theme) => ({
  label: {
    color: theme.palette.text.hint,
    ...theme.props.printRules.textSecondary,
  },
  infoHeader: {
    '&&': {
      padding: '0 18px',
    },
    background: theme.palette.background.header,
    ...theme.props.printRules.header,
  },
  toolTipPopper: {
    marginTop: -10,
  },
  expansionPanelPrint: {
    '@media print': {
      height: '100% !important',
      visibility: 'unset',
    },
  },
  gridContainer,
  gridContainerTop: {
    '&': {
      padding: '4px 0',
    },
  },
  infoGridFirstSpacer: {
    position: 'absolute',
    top: 0,
    left: 7,
    background: theme.palette.background.paper,
    zIndex: 1,
    height: '100%',
    width: 5,
    ...theme.props.printRules.background,
  },
  infoGridCol: {
    position: 'relative',
    '& > div::before': {
      position: 'absolute',
      borderLeft: `3px solid ${theme.palette.background.header}`,
      content: '" "',
      marginRight: 0,
      left: -10,
      top: -2,
      height: '200%',
      '@media print': {
        borderLeft: `3px solid ${theme.props.printRules.headerColor}`,
      },
    },
  },
  gridCenter: {
    [theme.breakpoints.only('sm')]: {
      order: 1,
    },
  },
  gridLast: {
    [theme.breakpoints.only('xs')]: {
      order: -1,
    },
  },
  gridLastFull: {
    marginRight: 'auto',
  },
});

/**
 * @typedef PatientHeaderProps
 * @prop {object} classes
 * @prop {import('../SharedPropDefs').Certification} currentCert
 * @prop {function} formatMessage
 * @prop {Date} generatedOn
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {string} primaryAddress
 * @prop {boolean} full
 * @prop {boolean} [isMobile]
 */

/**
 *
 * @extends {React.Component<PatientHeaderProps, {}>}
 */
class PatientCertHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !props.isMobile,
    };
  }

  static defaultProps = {
    patient: {},
    primaryAddress: {},
    currentCert: {},
  };

  getInfoGridClassNames(classes, full) {
    return {
      gridFirst: `${
        full ? classes.gridFirstFull || '' : classes.gridFirst || ''
      } ${classes.infoGridCol}`,
      gridCenter: `${
        full ? classes.gridCenterFull || '' : classes.gridCenter || ''
      } ${classes.infoGridCol}`,
      gridLast: `${
        full ? classes.gridLastFull || '' : classes.gridLast || ''
      } ${classes.infoGridCol}`,
    };
  }

  handleChange = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  };

  render() {
    const {
      classes,
      currentCert,
      formatMessage,
      generatedOn,
      patient,
      primaryAddress,
      full,
    } = this.props;
    const { expanded } = this.state;
    const headerGrids = { sm: 6, md: 4 };
    const { addressLine1, addressLine3 } = formatAddressMultiLine(
      primaryAddress,
    );
    const RightCol = (props) =>
      full ? <PatientDemographics {...props} /> : <DNRPhys {...props} />;
    const CenterCol = (props) =>
      full ? (
        <PatientAssignments {...props} />
      ) : (
        <PayerLOC {...{ gridProps: { sm: 6, md: 12 }, ...props }} />
      );
    const { gridFirst, gridCenter, gridLast } = this.getInfoGridClassNames(
      classes,
      full,
    );
    return (
      <ExpansionPanel
        TransitionProps={{
          classes: {
            container: classes.expansionPanelPrint,
          },
        }}
        expanded={expanded}
        onChange={this.handleChange}
      >
        <ExpansionPanelSummary>
          <SectionHeader
            action={
              <ActionIcon
                title={
                  expanded
                    ? formatMessage({ id: 'Collapse' })
                    : formatMessage({ id: 'Expand' })
                }
                Icon={expanded ? KeyboardArrowDown : KeyboardArrowRight}
              />
            }
            title={<FormattedMessage {...messages.title} />}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails top>
          <Grid
            className={classes.gridContainerTop}
            container
            justify="center"
            spacing={1}
          >
            <Grid
              className={classes.gridContainer}
              container
              item
              xs={12}
              justify="flex-start"
            >
              <PatientHeader
                classes={classes}
                headerGrids={headerGrids}
                patient={patient}
                full={full}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={classes.infoHeader}
              justify="flex-start"
            >
              <Typography variant="caption" className={classes.label}>
                <FormattedMessage
                  {...messages.infoAsOf}
                  values={{
                    date: generatedOn && (
                      <Fragment>
                        <FormattedDate value={generatedOn} format="long" />{' '}
                        <FormattedTime value={generatedOn} />
                      </Fragment>
                    ),
                  }}
                />
              </Typography>
            </Grid>
            <Grid
              className={classes.gridContainer}
              item
              direction="row-reverse"
              container
              xs={12}
              justify="flex-start"
            >
              <div className={classes.infoGridFirstSpacer} />
              <Grid
                className={gridFirst}
                container
                item
                xs={12}
                sm={6}
                md={4}
                alignContent="flex-start"
              >
                <RightCol
                  classes={classes}
                  addressLine1={addressLine1}
                  addressLine3={addressLine3}
                  patient={patient}
                  primaryAddress={primaryAddress}
                />
              </Grid>
              <Grid
                className={gridCenter}
                container
                item
                xs={12}
                sm={full ? 6 : 12}
                md={4}
                alignContent="flex-start"
              >
                <CenterCol
                  classes={classes}
                  currentCert={currentCert}
                  patient={patient}
                />
              </Grid>
              <Grid
                className={gridLast}
                container
                item
                xs={12}
                sm={6}
                md={4}
                alignContent="flex-start"
              >
                <PatientEpisode
                  classes={classes}
                  currentCert={currentCert}
                  full={full}
                />
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

PatientCertHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  patient: PropTypes.object,
  primaryAddress: PropTypes.object,
  currentCert: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(PatientCertHeader);
