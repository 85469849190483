import { useState, useEffect, useMemo } from 'react';
import { getClientHeight } from './utils';

/**
 * Custom React Hook for getting window height
 */
export const useWindowHeight = () => {
  const getSize = useMemo(() => () => ({
    clientHeight: document.documentElement.clientHeight,
    innerHeight: window.cordova ? window.outerHeight : window.innerHeight,
    safeHeight: getClientHeight(window.innerHeight, document.documentElement.clientHeight),
    outerHeight: window.outerHeight // innerWidth: window.innerWidth,
    // outerWidth: window.outerWidth,

  }), []);
  const [windowHeight, setWindowHeight] = useState(getSize());

  function updateWindowHeight() {
    setWindowHeight(getSize());
  }
  /* eslint-disable react-hooks/exhaustive-deps */


  useEffect(() => {
    window.addEventListener('resize', updateWindowHeight);
    return function cleanup() {
      window.removeEventListener('resize', updateWindowHeight);
    };
  }, []);
  /* eslint-enable */

  return windowHeight;
};
/**
 * @component
 * Component to provide actual Window Height for use where 100vh fails. Especially useful
 * in iOS Safari where 100vh does not account for the presence of the navigation bar.
 */

export const WindowHeightProvider = ({
  children
}) => children(useWindowHeight());
/**
 * Custom hook for getting ref component height
 * @param {Object} props
 * @param {ReactElement} props.children
 * @param {HTMLDivElement | {}} props.passedRef
 */