/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

// docLink
export const Wrap = styled.div<{
  height?: number;
  hidden?: boolean;
}>`
  ${(props) => (props.height ? `max-height: ${props.height}px;` : `;`)}
  ${(props) =>
    props.hidden ? `display: none;` : `display: block;`}
  align-self: center;
`;

// docLink
export const ScrollWrap = styled.div<{ height?: number; width?: number }>`
  overflow: auto;
  ${(props) => (props.height ? `height: ${props.height - 40}px;` : `;`)}
  position: relative;
  overscroll-behavior: contain;
`;

// docLink
export const BodyWrap = styled.div<{
  smallWidget?: boolean;
}>`
  position: relative;
  overflow: hidden;
  ${(props) => (props.smallWidget ? `height: 279px;` : '')}
  ${(props) =>
    props.smallWidget
      ? `background-color: ${props.theme.palette.background.paper};`
      : ';'}
`;

export const DashboardMain = styled.div`
  display: flex;
  width: 100%;
`;
// WHY DOES @ page margin not work
export const DashboardPrintDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media print {
    font-family: 'auto', sans-serif;
    color: black !important;
    background-color: #ffffff;
    overflow: visible;
    visibility: visible;
    p {
      color: black;
    }
  }
`;

const sidebarWidth = 25;
// margin left to accomodate the vertical bar position fixed
export const GridDiv = styled.div`
  width: 100%;
  margin-left: ${sidebarWidth}px;
`;
export const SideBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: ${sidebarWidth}px;
  height: 100%;
  ${(props) => `background-color: ${props.theme.palette.background.paper};`}
  position: fixed;
  top: 0;
  left: 0;
`;

// there is a min height as we don't want the print interface to vary in size
export const PrintInterfaceDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 412px;
  min-width: 346px;
`;
export const CalendarDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const SubmitDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem;
`;
export const ModalDiv = styled.div<{
  show?: boolean;
}>`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 1000;
  margin-left: ${sidebarWidth}px;
  ${(props) => (props.show ? `visibility: visible` : `visibility: hidden;`)}
  ${(props) =>
    props.show ? `opacity: 1;` : `opacity: 0;`}
  flex-direction: row-reverse
`;

export const WidgetModal = styled.div<{
  numberOpen?: number;
  topBarHeight: number;
  name?: string;
}>`
  background-image: linear-gradient(
    top,
    black,
    black 8%,
    transparent 8%,
    transparent 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    black,
    black 8%,
    transparent 8%,
    transparent 100%
  );
  overflow: hidden;
  transform: scale(1);
  position: relative;
  transition: 0.2s ease-in-out all;
  margin-right: 5px;
  margin-left: 5px;
  animation: createBox 1s;
  @keyframes createBox {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
  ${(props) =>
    props.numberOpen === 1
      ? props.name === 'Manifest'
        ? `width: 80%;`
        : `width: 90%;`
      : `width: 45%;`} 
  ${(props) =>
    props.topBarHeight
      ? props.topBarHeight < 50
        ? `margin-top: ${props.topBarHeight}px;`
        : `margin-top: ${props.topBarHeight + 16}px;`
      : `;`}
  ${(props) =>
    props.topBarHeight
      ? props.topBarHeight < 50
        ? `max-height: 90%;`
        : `max-height: 78%;`
      : `max-height: 95%;`}
  ${(props) =>
    props.name === 'DocLink'
      ? `;`
      : `box-shadow: 0 0px 5px rgba(255, 255, 255, 0.72);`}
  ${(props) =>
    props.name === 'DocLink'
      ? `;`
      : `border: 1px solid rgba(255, 255, 255, 0.82);`}
      `;

export const SidebarIcon = styled.div<{
  topBarHeight: number;
}>`
  ${(props) =>
    props.topBarHeight ? `margin-top: ${props.topBarHeight}px;` : `;`}
`;

export const PrintSelectionDiv = styled.div`
  display: flex;
`;
