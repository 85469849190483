import moment from 'moment-timezone';

export const renderNoteDate = (n: any) => {
  if (n.id !== -1) {
    const time = moment
      .utc(n.noteddatetime)
      .tz(n.sitetimezone)
      .format('YYYY-MM-DD hh:mm a');
    return time;
  } return '';
};
