import React, { useContext } from 'react';
import { GridApi, GridOptions } from 'ag-grid-community';
import { find } from 'lodash-es';
import {
  hospiceData,
  approval,
  hisadmineventdto,
  hiscachedpatientitemdto,
  cmsapproved,
  daystodeadline,
  download,
  vertical,
  SENT,
  ACCPT,
} from './constants';
import { QuotaCellRenderer } from './CellRenderer';
import { HospiceItemSetContext } from '../../context';
import { formatPostDate } from './utils';
import { AgGridTable } from './Table';
import { TableMode } from './types';
import { MessageOverlay } from './MessageOverlay';
interface HospiceItemSetTableProps {
  viewModePrint?: boolean;
  mode?: TableMode;
}

const frameworkComponents = {
  quotaCellRenderer: QuotaCellRenderer,
  customLoadingOverlay: MessageOverlay,
};

export const HospiceItemSmallTable = ({
  viewModePrint,
  mode,
}: HospiceItemSetTableProps) => {
  const { hospiceInfo, patient, currentUser, postHISData } = useContext(
    HospiceItemSetContext,
  );

  const showMessage = hospiceInfo.bshowadminactionbuttons === 'false';

  const { patientNumber, siteId } = patient;
  const { personNumber } = currentUser;

  const postData = (shortName: string, method: string) => {
    const dataPost = {
      personnumber: personNumber,
      patientnumber: patientNumber,
      siteid: siteId.toString(),
      statusdatetime: formatPostDate(Date.now()),
      hisadminstatusshortname: shortName, // 'DWN'
    };
    postHISData(patientNumber, dataPost, method); // should it be PUT at times?
  };

  let tableData;
  if (mode === vertical) {
    tableData = hospiceData.map((entry) => ({
      ...entry,
      stage: {
        value: hospiceInfo[entry.key as mumms.HISkey],
      },
    }));
  } else {
    const data = { ...hospiceInfo };
    const newData = Object.keys(data).reduce((seed, key) => {
      if (
        key === daystodeadline ||
        key === hisadmineventdto ||
        key === hiscachedpatientitemdto
      ) {
        seed[key] = {
          value: data[key as mumms.HISkey],
        };
      }
      return seed;
    }, {} as any);
    tableData = [newData];
  }

  const getCellClass = (params: any) => {
    const { title } = params.data;
    if (title === approval) {
      const { value } = params.value;
      return value.length ? 'green-row no-border' : 'health no-border';
    }
    if (title === download) {
      const { value } = params.value;
      const sent = find(value, {
        hisadminstatusshortname: SENT,
      });
      return sent ? 'green-row no-border' : 'health no-border';
    }
    if (title === cmsapproved) {
      const { value } = params.value;
      const cmsAccepted = find(value, {
        hisadminstatusshortname: ACCPT,
      });
      return cmsAccepted ? 'green-row no-border' : 'health no-border';
    }
    return 'health no-border';
  };

  let columnTableDefs;
  if (mode === vertical) {
    columnTableDefs = [
      {
        headerName: 'HIS Process',
        field: 'name',
        cellClass: 'health no-border',
        suppressMenu: true,
        width: 155,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Stage',
        field: 'stage',
        cellRendererFramework: QuotaCellRenderer,
        cellClass: (params: any) => getCellClass(params),
        headerClass: 'header-center',
        cellRendererParams: {
          postData,
          mode,
        },
        suppressMenu: true,
      },
    ];
  } else {
    // todo translation files
    columnTableDefs = [
      {
        headerName: 'Approval Available',
        field: hisadmineventdto,
        cellClass: (params: any) => {
          const { value } = params.value;
          return value.length ? 'green-row no-border' : 'health no-border';
        },
        cellRendererFramework: QuotaCellRenderer,
        suppressMenu: true,
        cellRendererParams: {
          postData,
          mode,
          title: approval,
        },
      },
      {
        headerName: 'Download/Send',
        field: hisadmineventdto,
        cellClass: (params: any) => {
          const { value } = params.value;
          const sent = find(value, {
            hisadminstatusshortname: SENT,
          });
          return sent ? 'green-row no-border' : 'health no-border';
        },
        cellRendererFramework: QuotaCellRenderer,
        suppressMenu: true,
        cellRendererParams: {
          postData,
          title: download,
          mode,
        },
      },
      {
        headerName: 'CMS Approved',
        field: hisadmineventdto,
        cellClass: (params: any) => {
          const { value } = params.value;
          const cmsAccepted = find(value, {
            hisadminstatusshortname: ACCPT,
          });
          return cmsAccepted ? 'green-row no-border' : 'health no-border';
        },
        cellRendererFramework: QuotaCellRenderer,
        suppressMenu: true,
        cellRendererParams: {
          postData,
          title: cmsapproved,
          mode,
        },
      },
      {
        headerName: 'Days To Deadline',
        field: daystodeadline,
        cellClass: 'health no-border',
        cellRendererFramework: QuotaCellRenderer,
        suppressMenu: true,
        cellRendererParams: {
          title: daystodeadline,
          mode,
        },
      },
    ];
  }

  const gridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
    },
    columnDefs: columnTableDefs,
    onGridReady: (params: any) => {
      const gridApi: GridApi = params.api;
      gridApi.sizeColumnsToFit();
      showMessage && gridApi.showLoadingOverlay();
      if (viewModePrint) {
        gridOptions.domLayout = 'print';
      }
    },
  };

  return (
    <AgGridTable
      gridOptions={gridOptions}
      rowData={tableData || []}
      frameworkComponents={frameworkComponents}
      mode={mode}
      loadingOverlayComponent="customLoadingOverlay"
    />
  );
};

export default HospiceItemSmallTable;
