import React, { useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { dateComparator } from '../../utils/utils';
import { cpfAll } from '../../utils/constant';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

// TODO build a Visit Interface with proper key when we know exactly when we need
interface CarePlanFrequencyTableProps {
  smallWidget?: boolean;
  viewModePrint?: boolean;
  plans: [] | undefined | null;
  filter?: string;
  className?: string | undefined | null;
}
let gridApi: GridApi | null;
let gridColumnApi: any;

const defaultColDef = {
  flex: 1,
  minWidth: 20,
  filter: true,
  sortable: true,
  resizable: true,
};

/**
 * @component
 * Styled Care Plan Frequency Table. Pass plans and functions callbacks
 */
// TODO: TRANSLATIONS
export const CarePlanFrequencyTable = ({
  plans,
  filter,
  smallWidget,
  viewModePrint,
  className,
}: CarePlanFrequencyTableProps) => {
  const filterRef = useRef(filter);

  /**
   * On initial load, this takes care of selecting current rows
   * when the locally stored CPF view is "All"
   */
  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode(function (rowNode: RowNode, index) {
        if (rowNode.data.is_current && !isExternalFilterPresent()) {
          rowNode.setSelected(true);
        } else rowNode.setSelected(false);
      });
    }
  }, [gridApi]);

  /** On filter change, select or deselect the active ones. */
  useEffect(() => {
    if (gridApi) {
      filterRef.current = filter;
      gridApi.onFilterChanged();
      gridApi.forEachNodeAfterFilter(function (rowNode: RowNode, index) {
        if (rowNode.data.is_current && !isExternalFilterPresent()) {
          rowNode.setSelected(true);
        } else rowNode.setSelected(false);
      });
    }
  }, [filter]);

  /**
   * When the toggle is 'checked' or true, we show all CPFs.
   * filterRef holds a reference to the corresponding toggle value.
   * Ag-grid does not recognize state change, so that is why we are using ref.
   * https://stackoverflow.com/questions/63159747/ag-grid-external-filter-not-working-with-props
   */
  const isExternalFilterPresent = () => filterRef.current !== cpfAll;
  const doesExternalFilterPass = (rowNode: any) =>
    rowNode.data.is_current === 1;

  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;

  const gridOptions: GridOptions = {
    columnDefs: [
      {
        headerName: 'Role Group',
        field: 'role_group_name',
        // does not help with the new print mode, was of help when no printing interface
        width: viewModePrint ? 200 : undefined,
      },
      {
        headerName: 'Worker',
        field: 'worker',
        width: viewModePrint ? 200 : undefined,
      },
      {
        headerName: 'Frequency',
        field: 'frequency',
        width: viewModePrint ? 200 : undefined,
      },
      {
        headerName: 'Effective',
        field: 'effective',
        comparator: dateComparator,
        width: viewModePrint ? 116 : undefined,
      },
      {
        headerName: 'Current',
        field: 'is_current',
        filter: 'agNumberColumnFilter',
        hide: true,
      },
    ],
    onGridReady: (params: any) => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
      if (gridApi) {
        if (viewModePrint) {
          gridApi.setDomLayout('print');
        } else {
          gridApi.sizeColumnsToFit();
        }
      }
    },
  };

  return (
    <AgGridReact
      gridOptions={gridOptions}
      defaultColDef={defaultColDef}
      rowData={plans || []}
      animateRows
      rowSelection="multiple"
      suppressRowClickSelection
      /** https://www.ag-grid.com/javascript-grid/grid-callbacks/#reference-callbacks */
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      rowHeight={gridTheme.rowHeight}
      headerHeight={gridTheme.headerHeight}
    />
  );
};

export default CarePlanFrequencyTable;
