export const getStoppedMedications = (meds, today) =>
  Array.isArray(meds) && meds.length
    ? meds
        .filter((med) => med.stopdate && today > new Date(med.stopdate))
        .map((med) => ({ ...med, stopped: true }))
    : [];

export const getCurrentMedications = (meds, today) =>
  Array.isArray(meds) && meds.length
    ? meds.filter(
        (med) => today < new Date(med.stopdate) || med.stopdate === '',
      )
    : [];

export const getAllMedications = (meds) =>
  Array.isArray(meds) && meds.length ? meds : [];

export const getApprovedMedications = (meds) =>
  meds.filter((med) => med.reconcileddate.length);

export const sortPrintMeds = (meds) =>
  meds.sort((a, b) => {
    if (a.value < b.value) return 1;
    if (a.value > b.value) return -1;
    if (a.value === b.value) {
      if (a.secondaryValue > b.secondaryValue) {
        return 1;
      }
      return 2;
    }
    return 0;
  });

export const mapPrintMeds = (meds) =>
  meds.map((m, i) => ({
    index: i,
    value: new Date(
      m.startdate.split(' ')[0] || m.lastupdatedate.split(' ')[0],
    ),
    secondaryValue: m.drugdescription.toLowerCase(),
  }));

export const sortMedications = (
  meds,
  { name, sig, quantity, start, stop, covered, approved },
) => {
  if (sig.sort) {
    if (sig.asec) {
      return meds.sort((a, b) =>
        a.sig.toLowerCase() > b.sig.toLowerCase() ? 1 : -1,
      );
    }
    return meds.sort((a, b) =>
      a.sig.toLowerCase() < b.sig.toLowerCase() ? 1 : -1,
    );
  } else if (covered.sort) {
    if (covered.asec) {
      return meds.sort((a, b) =>
        a.coveredbyhospice.toLowerCase() > b.coveredbyhospice.toLowerCase()
          ? 1
          : -1,
      );
    }
    return meds.sort((a, b) =>
      a.coveredbyhospice.toLowerCase() < b.coveredbyhospice.toLowerCase()
        ? 1
        : -1,
    );
  } else if (quantity.sort) {
    if (quantity.asec) {
      return meds.sort((a, b) =>
        Number(a.quantity) > Number(b.quantity) ? 1 : -1,
      );
    }
    return meds.sort((a, b) =>
      Number(a.quantity) < Number(b.quantity) ? 1 : -1,
    );
  } else if (start.sort) {
    if (start.asec) {
      return meds.sort((a, b) => {
        const aStart = a.startdate || false;
        const bStart = b.startdate || false;
        if (new Date(aStart) === new Date(bStart)) return 0;
        return new Date(aStart) > new Date(bStart) ? 1 : -1;
      });
    }
    return meds.sort((a, b) => {
      const aStart = a.startdate || false;
      const bStart = b.startdate || false;
      if (new Date(aStart) === new Date(bStart)) return 0;
      return new Date(aStart) < new Date(bStart) ? 1 : -1;
    });
  } else if (stop.sort) {
    if (stop.asec) {
      return meds.sort((a, b) => {
        const aStop = a.stopdate || false;
        const bStop = b.stopdate || false;
        if (new Date(aStop) === new Date(bStop)) return 0;
        return new Date(aStop) > new Date(bStop) ? 1 : -1;
      });
    }
    return meds.sort((a, b) => {
      const aStop = a.stopdate || false;
      const bStop = b.stopdate || false;
      if (new Date(aStop) === new Date(bStop)) return 0;
      return new Date(aStop) < new Date(bStop) ? 1 : -1;
    });
  } else if (approved.sort) {
    if (approved.asec) {
      return meds.sort((a, b) => {
        const aApproved = a.stopdate || false;
        const bApproved = b.stopdate || false;
        if (new Date(aApproved) === new Date(bApproved)) return 0;
        return new Date(aApproved) > new Date(bApproved) ? 1 : -1;
      });
    }
    return meds.sort((a, b) => {
      const aStop = a.stopdate || false;
      const bStop = b.stopdate || false;
      if (new Date(aStop) === new Date(bStop)) return 0;
      return new Date(aStop) < new Date(bStop) ? 1 : -1;
    });
  } else if (name.sort) {
    if (name.asec) {
      return meds.sort((a, b) =>
        a.drugdescription.toLowerCase() > b.drugdescription.toLowerCase()
          ? 1
          : -1,
      );
    }
    return meds.sort((a, b) =>
      a.drugdescription.toLowerCase() < b.drugdescription.toLowerCase()
        ? 1
        : -1,
    );
  }
  return meds.sort((a, b) =>
    a.drugdescription.toLowerCase() > b.drugdescription.toLowerCase() ? 1 : -1,
  );
};

export const filterMedications = (
  medications,
  { to, from, toSearch, sortParams, type },
) => {
  let medicationsToUse = [...medications];
  // filter results by type
  if (type === 'active') {
    medicationsToUse =
      medicationsToUse?.length &&
      medicationsToUse.filter(
        (m) => !m.stopdate || new Date(m.stopdate) >= new Date(),
      );
  } else if (type === 'needsApproval') {
    medicationsToUse =
      medicationsToUse?.length &&
      medicationsToUse.filter(
        (m) => !m.reconcileddate || m.reconcileddate === '',
      );
  } else if (type === 'recent') {
    // get all meds from the last 7 days
    const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    medicationsToUse =
      medicationsToUse?.length &&
      medicationsToUse.filter(
        (m) =>
          new Date(m.startdate || m.lastupdatedate) >= new Date(sevenDaysAgo),
      );
  }

  // filter results by text if toSearch has a value
  if (toSearch) {
    medicationsToUse =
      medicationsToUse?.length &&
      medicationsToUse.filter((m) =>
        m.drugdescription.toLowerCase().includes(toSearch.toLowerCase()),
      );
  }
  // filter results by date range
  const unsortedMeds =
    (medicationsToUse?.length &&
      medicationsToUse.filter((m) => {
        const startDate = m.startdate || m.lastupdatedate;
        const isBeforeTo = to ? new Date(startDate) <= new Date(to) : true;
        const isAfterFrom =
          from && m.stopdate ? new Date(m.stopdate) >= new Date(from) : true;
        return (isAfterFrom || !from) && (isBeforeTo || !to);
      })) ||
    [];

  const today = new Date();
  const sortedMeds = unsortedMeds?.length
    ? sortMedications(unsortedMeds, sortParams)
    : [];
  const stoppedMeds = sortedMeds?.length
    ? getStoppedMedications(sortedMeds, today)
    : [];
  const currentMeds = sortedMeds?.length
    ? getCurrentMedications(sortedMeds, today)
    : [];
  return currentMeds.concat(stoppedMeds);
};
