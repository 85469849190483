import React, { useRef } from 'react';
import {
  Dialog as MuiDialog,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CloseButton, SectionHeader } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withSelectors } from '../CertForm/Components/Selectors';
import messages from '../CertForm/CertMessages';

const Dialog = styled(MuiDialog)`
  && .MuiPaper-root.MuiDialog-paper {
    align-items: center;
    min-height: 150px;
    min-width: 300px;
    overflow: hidden;
  }
`;

const GridContainer = styled(Grid)`
  max-width: 315px;
  padding: 8px 8px 12px;
`;
const GridItem = styled(Grid)`
  position: relative;
  min-height: 30px;
`;

const BaseMissingParams = withSelectors(
  ({
    PhysPicker,
    DatePicker,
    open,
    handleClose,
    missingPhys,
    missingDate,
    selectedDoctor,
    selectedDate,
    dimensions,
    embedded,
  }) => {
    // NOTE: Overriding styles with classes to customize CSS injected by Material-UI
    const useStyles = makeStyles({
      dialog: {
        position: 'absolute',
        left: dimensions ? dimensions.x : 100,
        top: dimensions ? dimensions.y : 100,
      },
    });
    const classes = useStyles();
    return (
      <Dialog
        classes={
          embedded && dimensions
            ? {
              paper: classes.dialog,
            }
            : {}
        }
        onClose={handleClose}
        aria-labelledby="missing-params-dialog"
        open={open}
      >
        <SectionHeader
          id="missing-params-dialog"
          title={<FormattedMessage {...messages.missingParams} />}
          nav={
            <CloseButton
              disabled={!selectedDoctor || !selectedDate}
              showClose
              closeForm={handleClose}
            />
          }
        />
        <GridContainer container spacing={1}>
          {missingPhys ? (
            <GridItem xs={12} item>
              <Typography>
                <FormattedMessage {...messages.certPhys} />:
              </Typography>
              {PhysPicker}
            </GridItem>
          ) : null}
          {missingDate ? (
            <GridItem xs={12} item>
              <Typography>
                <FormattedMessage {...messages.certDate} />:
              </Typography>
              {DatePicker}
            </GridItem>
          ) : null}
        </GridContainer>
      </Dialog>
    );
  },
);

export const MissingParams = (props) => {
  const physContainerRef = useRef(null);
  return (
    <BaseMissingParams
      {...props}
      physSelectorId="picker-modal"
      physContainerRef={physContainerRef}
    />
  );
};
