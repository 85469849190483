import styled, { css } from 'styled-components';

type HeaderProps = {
  /** set to true to account for existing top border */
  ['data-top']?: boolean;
  ['data-main']?: boolean;
};

export const Header = styled.h4<HeaderProps>`
  width: fill-available;
  display: flex;
  height: 30px;
  align-items: center;
  background: ${(props) => props.theme.palette.background.header};
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0.25rem 12px;
  margin-bottom: 0;
  ${(props) =>
    props['data-top']
      ? css`
          margin-top: -3px;
        `
      : ''}
  ${(props) =>
    props['data-main']
      ? css`
          font-size: 1.3rem;
          border-bottom: 1px solid ${props.theme.palette.background.header};
        `
      : ''}
  ${(props) => props.theme.props.styledPrintRules.header}
  ${(props) => props.theme.props.styledPrintRules.textPrimary}
`;
