import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

const longDateFormat = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
};
const medDateFormat = {
  month: '2-digit',
  day: '2-digit',
  year: '2-digit'
};
export const dateFormats = {
  date: {
    longUTC: _objectSpread(_objectSpread({}, longDateFormat), {}, {
      timeZone: 'UTC'
    }),
    long: longDateFormat,
    medUTC: _objectSpread(_objectSpread({}, medDateFormat), {}, {
      timeZone: 'UTC'
    }),
    med: medDateFormat
  }
};
export const dateFormat = {
  format: 'longUTC'
};