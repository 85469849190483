import React, { useState } from 'react';
import { includes } from 'lodash-es';
import { Tooltip } from '@material-ui/core/';
import BlockIcon from '@material-ui/icons/Block';
import { Checkmark } from '@mummssoftware/icons';
import {
  patientRefused,
  noPost,
  active,
} from '../MeasuresContext/utils/constants';
import {
  CellDiv,
  MeasureValue,
  MeasureDescription,
  EnterCell,
  MeasureCell,
  UnitsValue,
  PrintMeasureCell,
} from '../../components/layout/WrapperDiv';

export const UnitsCellRenderer = React.memo((props: any) => {
  const [width] = useState(
    props.width ||
      props.agGridReact.props.columnDefs[2].cellRendererParams.width,
  );

  return props.value ? (
    <MeasureCell width={width}>
      <UnitsValue>{props.value}</UnitsValue>
    </MeasureCell>
  ) : (
    <EnterCell width={width}>Enter Unit</EnterCell>
  );
});

export const PrintUnitsCellRenderer = React.memo((props: any) => {
  const [width] = useState(
    props.width ||
      props.agGridReact.props.columnDefs[2].cellRendererParams.width,
  );

  return (
    <PrintMeasureCell width={width}>
      <UnitsValue>{props.value}</UnitsValue>
    </PrintMeasureCell>
  );
});

export const ValuesCellRenderer = React.memo((props: any) => {
  const [width] = useState(
    props.width ||
      props.agGridReact.props.columnDefs[3].cellRendererParams.width,
  );

  return props.value ? (
    <MeasureCell width={width}>
      <MeasureValue>{props.value.value}</MeasureValue>
      <span>{'  '}</span>
      <MeasureDescription>{props.value.displayvalue}</MeasureDescription>
    </MeasureCell>
  ) : (
    <EnterCell width={width}>Enter Measure</EnterCell>
  );
});

export const ScoreCellRenderer = React.memo((props: any) => (
  <span>{props.value.value}</span>
));

export const MeasureCellRenderer = React.memo((props: any) => {
  const result = props && props.value;
  const value = result && result.value;
  const keys = result && Object.keys(props.value);
  const isDisplay = includes(keys, 'value');
  const comments = result && result.comments;
  const getIcon = () => <BlockIcon style={{ fontSize: 15, marginLeft: 2 }} />;
  return isDisplay ? (
    <CellDiv>
      <span>{value}</span>
      {comments === patientRefused && (
        <Tooltip title={comments}>{getIcon()}</Tooltip>
      )}
    </CellDiv>
  ) : (
    <span>{props.value}</span>
  );
});

export const PostCellRenderer = React.memo((props: any) => {
  const activeStyle = { cursor: 'pointer', color: '#9edbf7' };
  const disabledStyle = { color: 'grey' };
  const style = props.value !== active ? disabledStyle : activeStyle;

  const { recordSelected, saveMeasures } = props;
  const [recorded, setRecorded] = useState(false);
  return (
    <CellDiv>
      {!recorded && props.value !== noPost && (
        <Checkmark
          style={style}
          onClick={() => {
            const saved = saveMeasures(props.api, recordSelected);
            saved && setRecorded(true);
          }}
        />
      )}
    </CellDiv>
  );
});

export const RecordedByCellRenderer = ({ data }: any) => {
  const recordedByPinned = (user: mumms.User) => {
    if (user.firstName) {
      return user.userCredentials
        ? `${user.userCredentials} ${user.firstName.slice(0, 1)}. ${
            user.lastName
          }`
        : `${user.firstName.slice(0, 1)}. ${user.lastName}`;
    }
    return '';
  };
  const recordedBy = (user: mumms.measure) => {
    if (user.personfirstname) {
      return user.personcredentials
        ? `${user.personcredentials} ${user.personfirstname.slice(0, 1)}. ${
            user.personlastname
          }`
        : `${user.personfirstname.slice(0, 1)}. ${user.personlastname}`;
    }
    return '';
  };
  if (data.recordingUser) {
    const currentUser = data.recordingUser;
    return recordedByPinned(currentUser);
  }
  return recordedBy(data);
};
