/* eslint-disable no-nested-ternary */

import React, { useState, useCallback, useContext } from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { formatDateMMDDYY } from '@mummssoftware/utils/formatters';
import { SuggestBox } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import { messages } from '../Medication/messages';
import { CoveredDialog } from '../Medication/CoveredDialog';
import { AppContext } from '../../App/AppContext';
import {
  getMinStandartTooltipTitle,
  getMinStandardPlaceholder,
  minStandardOptions,
  getCoveredTooltipTitle,
  getCoveredPlaceholder,
  coveredOptions,
} from '../Medication/constants';

const StyledTableCell = styled(TableCell)`
  && {
    color: inherit;
    white-space: nowrap;
    ${(props) => (props.smallwidget ? `padding: 2px;` : `padding: 1px;`)}
    ${(props) => (props.smallwidget ? `font-size: 14px;` : `font-size: 15px;`)}
  }
`;
const StyledTruncateTableCell = styled(TableCell)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1px;
    ${(props) => (props.smallwidget ? `padding: 2px;` : `padding: 3px;`)}
    ${(props) =>
      props.smallwidget
        ? `font-size: 14px;`
        : `font-size: 15px;`}

    color: inherit;
  }
`;

const SmallDiv = styled.div`
  width: 40px;
`;

const ToggleDiv = styled.div`
  ${(props) =>
    props.isMobile && !props.isMobileEditable ? `;` : `cursor: pointer;`}
  width: 40px;
  display: flex;
  justify-content: center;
`;

const StyledTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'gray',
    fontSize: '1em',
  },
})(Tooltip);

const BaseMedication = ({ classes, medication, handleChange }) => {
  const [openCoveredDialog, setOpenCoveredDialog] = useState(false);

  const coveredError = medication.coveredbyhospice === '';
  const minStandardError =
    medication.medatadmit === 'false' && medication.medattestation === 'false';

  const handleCloseCoveredDialog = () => {
    setOpenCoveredDialog(false);
  };

  const checkDiscontinued = useCallback(() => {
    if (medication.stopped) {
      return classes.medInfoValue;
    }
    return '';
  }, [medication.stopped, classes.medInfoValue]);

  const orderingPhysicianLabel = () => {
    const {
      orderingphysicianfirstname: firstName,
      orderingphysicianlastname: lastName,
      orderingphysiciancredentials: credentials,
    } = medication;

    return `${lastName}${credentials ? ` ${credentials}` : ''}, ${firstName}`;
  };

  const { isMobile } = useContext(AppContext);
  const readonly = true;
  const isMobileEditable = false;

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={medication.sig}
      className={checkDiscontinued()}
    >
      {/* NEEDS APPROVAL */}
      {!medication.reconcileddate || medication.reconcileddate === '' ? (
        <StyledTooltip title={<FormattedMessage {...messages.needsApproval} />}>
          <StyledTableCell>
            <FiberManualRecordIcon color="error" fontSize="small" />
          </StyledTableCell>
        </StyledTooltip>
      ) : (
        <StyledTableCell />
      )}
      {/* MEDICATION NAME */}
      <StyledTooltip
        title={medication.drugdescription}
        aria-label="medication name"
      >
        <StyledTruncateTableCell align="left">
          {medication.drugdescription}
        </StyledTruncateTableCell>
      </StyledTooltip>
      {/* MEDICATION INSTRUCTIONS */}
      <StyledTooltip title={medication.sig}>
        <StyledTruncateTableCell
          align="left"
          aria-label="medication description"
        >
          {medication.sig}
        </StyledTruncateTableCell>
      </StyledTooltip>
      {/* MEDICATION QUANTITY */}
      <StyledTableCell align="center">
        {Math.trunc(medication.quantity)}
      </StyledTableCell>
      {/* MEDICATION STARTDATE */}
      <StyledTableCell align="left">
        {medication.startdate
          ? formatDateMMDDYY(medication.startdate)
          : `${formatDateMMDDYY(medication.lastupdatedate)}*`}
      </StyledTableCell>

      {/* COVERED BY HOSPICE */}
      {coveredError ? (
        <StyledTableCell>
          <SmallDiv>
            <SuggestBox
              inputId={`covered-${medication.id}`}
              hasError={coveredError}
              fitcontent
              placeholder={getCoveredPlaceholder(medication)}
              tooltipTitle={getCoveredTooltipTitle(medication)}
              options={coveredOptions}
              disabled={readonly}
            />
          </SmallDiv>
          <CoveredDialog
            open={openCoveredDialog}
            onClose={handleCloseCoveredDialog}
            classes={classes}
            medication={medication}
            handleChange={handleChange}
          />
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <ToggleDiv isMobile={isMobile} isMobileEditable={isMobileEditable}>
            <StyledTooltip
              title={getCoveredTooltipTitle(medication)}
              aria-label="med-covered"
            >
              <div>{getCoveredPlaceholder(medication)}</div>
            </StyledTooltip>
          </ToggleDiv>
          <CoveredDialog
            open={openCoveredDialog}
            onClose={handleCloseCoveredDialog}
            classes={classes}
            medication={medication}
            handleChange={handleChange}
          />
        </StyledTableCell>
      )}

      {/* MINIMUM STANDARDS MET */}
      {minStandardError ? (
        <StyledTableCell>
          <SmallDiv>
            <SuggestBox
              inputId={`min-standard-${medication.id}`}
              hasError={minStandardError}
              fitcontent
              placeholder={getMinStandardPlaceholder(medication)}
              tooltipTitle={getMinStandartTooltipTitle(medication)}
              options={minStandardOptions}
              disabled={readonly}
            />
          </SmallDiv>
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <ToggleDiv isMobile={isMobile} isMobileEditable={isMobileEditable}>
            <StyledTooltip
              title={getMinStandartTooltipTitle(medication)}
              aria-label="med-min-standard"
            >
              <div>{getMinStandardPlaceholder(medication)}</div>
            </StyledTooltip>
          </ToggleDiv>
        </StyledTableCell>
      )}

      {/* PHYSICIAN */}
      <StyledTableCell>
        {medication.orderingphysicianfirstname ? (
          orderingPhysicianLabel()
        ) : (
          <FormattedMessage {...messages.choosePhys} />
        )}
      </StyledTableCell>
      {/* MEDICATION STOPDATE */}
      <StyledTooltip title={medication.stopreason} aria-label="stop reason">
        <StyledTableCell align="left">
          {formatDateMMDDYY(medication.stopdate)}
        </StyledTableCell>
      </StyledTooltip>
    </TableRow>
  );
};

export const HistoryMedication = withStyles((theme) => ({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: '6px',
      },
      paddingDefault: {
        padding: '6px',
      },
    },
  },
  hide: {
    display: 'none',
  },
  medInfo: {
    display: 'block',
    color: theme.palette.text.hint,
  },
  medInfoValue: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: '700',
  },
}))(React.memo(BaseMedication));
