/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import {
  Loader,
  SectionHeader,
  ActionIcon,
  ToggleSwitch,
} from '@mummssoftware/common-ui';
import { Print, Check } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import { questionnaires } from '../../context';
import { Container, ContainerStyles } from './Container';

import 'ag-grid-community/dist/styles/agGridMaterialFont.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { unify } from './UnifyForm';

const Blank = styled.div`
  display: none;
`;

const ModalBackground = styled.div`
  display: block !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ToggleDiv = styled.div`
  display: inline-block;
  margin: 15px 3px 0 0;
`;

export type PrintModalProps = {
  display: boolean;
  selectedQs?: questionnaires;
  clearModal: () => void;
  doPrint: () => void;
  patient?: mumms.patient;
  facesheet: string;
};

export const unifyForm = unify;

export const PrintModal = ({
  display,
  selectedQs,
  clearModal,
  doPrint,
  patient,
  facesheet,
}: PrintModalProps) => {
  const qHtml = `<br/>${selectedQs
    ?.map((q) => q?.html)
    .join('<div style="background-color: #454545;"><br/></div>')}`;

  const [faceDisplayed, changeFaceDisplay] = useState<boolean>(false);
  const displayFace = () => {
    changeFaceDisplay(!faceDisplayed);
  };
  const contentRef = createRef<HTMLDivElement>();

  const html = faceDisplayed ? `<br/>${facesheet}${qHtml}` : qHtml;
  let styles = ContainerStyles;
  styles = styles.replace('width: 80%', 'width: 100%');
  styles = styles.replace(/background-color: [^;]+;/g, '');
  styles = styles.replace(/color: [^;]+;/g, '');

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    bodyClass: 'printingstuff',
    pageStyle: styles,
    copyStyles: false,
  }) as () => void;

  if (!display) {
    return <Blank />;
  }

  if (!html) {
    return (
      <ModalBackground>
        <Loader />
      </ModalBackground>
    );
  }

  return (
    <ModalBackground>
      <Container id="print-modal">
        <SectionHeader
          action={
            <div className="MuiButtonBase-root">
              <ActionIcon title="Print" Icon={Print} onClick={handlePrint} />
              {patient && (
                <ToggleDiv>
                  <ToggleSwitch
                    rightText="Display Facesheet"
                    leftText=""
                    isChecked={faceDisplayed}
                    onToggle={displayFace}
                  />
                </ToggleDiv>
              )}
            </div>
          }
          nav={<ActionIcon title="" Icon={Check} onClick={clearModal} />}
          title=""
        />
        <div
          id="modal-contents"
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: html }}
        />{' '}
      </Container>
    </ModalBackground>
  );
};
