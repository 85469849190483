import React, { Fragment } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import { IconButton } from '@material-ui/core';
import PrintSingleMedLayout from './PrintSingleMedLayout';
// import { AppContext } from '../AppContext';

class PrintMedicationButton extends React.PureComponent {
  // static contextType = AppContext;
  // printComponentRef: any;

  // componentDidMount() {
  //   // Note not sure why needed here
  //   // const appContext = this.context;
  // }

  render() {
    // const appContext = this.context;
    // const { patient } = appContext;
    const { patient, medication, orderingPhysicianLabel } = this.props;

    return (
      <Fragment>
        <PrintSingleMedLayout
          ref={(el) => {
            this.printComponentRef = el;
          }}
          patient={patient}
          medication={medication}
          orderingPhysicianLabel={orderingPhysicianLabel}
        />
        <ReactToPrint
          content={() => this.printComponentRef}
          pageStyle="@page { size: auto;  margin: 20mm 15mm important!; }"
        >
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <IconButton
                aria-label="print row"
                size="small"
                onClick={handlePrint}
              >
                <PrintIcon fontSize="small" />
              </IconButton>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </Fragment>
    );
  }
}

export default PrintMedicationButton;
