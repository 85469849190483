const pps = 'pps';
const kps = 'kps';
const perfScaleShortNames = [pps, kps];
/**
 * Retrieve primary diagnosis
 */

export const getPrimaryDiagnosis = diagnosisDTO => {
  const primary = diagnosisDTO.splice(diagnosisDTO.findIndex(({
    rank
  }) => rank === 1), 1);
  return primary[0];
};
/**
 * Retrieve the kps and/or pps, and track the lowest
 */

export const getPerfScoreData = ({
  latestScores: scores
}) => scores && perfScaleShortNames.reduce((seed, name) => {
  const scoreData = scores[name];

  if (scoreData !== undefined) {
    const score = parseInt(scoreData.value, 10);
    seed[name] = {
      score,
      date: scoreData.measuredatetime
    };

    if (seed.lowestScore === null || seed.lowestScore > score) {
      seed.lowestScore = score;
      seed.date = scoreData.measuredatetime;
      seed.type = scoreData.measuretypeshortname;
    }
  }

  return seed;
}, {
  date: null,
  lowestScore: null,
  type: null
});