import React from 'react';
import styled from 'styled-components';

const NoInfoSpan = styled.div`
  text-align: center;
  margin: auto;
`;

const NoInfoDiv = styled.div`
  min-height: 50px;
  display: grid;
`;

export const NoInfo: React.SFC = ({ children }) => (
  <NoInfoDiv>
    <NoInfoSpan>{children}</NoInfoSpan>
  </NoInfoDiv>
);
