/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useContext } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  makeStyles,
} from '@material-ui/core';
import { MedContext } from '../../App/MedContext';
import { HistoryMedication } from './HistoryMedication';
import { messages } from '../../utils/messages';
import { Headers } from '../Medication/ColHeaders';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    background: theme.palette.background.paper,
    overflow: 'hidden',
    width: '100%',
    borderRadius: 0,
  },
  table: { width: '100%' },
  tableContainer: {
    background: theme.palette.background.paper,
    overflow: 'visible',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const HistoryMedicationsTable = ({ expand, externalid, ...props }) => {
  const { medications, dimensions } = useContext(MedContext);
  const historyMeds = [...medications].filter(
    (m) => m.iscurrentversion === 'n' && m.externalid === externalid,
  );

  const actionBarHeight = props.smallWidget ? undefined : dimensions;
  const newprops = props.smallWidget ? props : { ...props, actionBarHeight };
  const classes = useStyles(newprops);

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer
          className={`ag-theme-hummingbird light ${classes.tableContainer}`}
          id="tooltip-table"
        >
          <Table
            stickyHeader={!!props.dashboardEmbedded}
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="medications table"
            padding="none"
          >
            <Headers classes={classes} messages={messages} history/>

            <TableBody>
              {historyMeds.map((medication, index) => (
                <HistoryMedication
                  className={index % 2 ? 'ag-row-odd' : ''}
                  key={medication.id}
                  expand={expand}
                  medication={medication}
                  medications={historyMeds}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default React.memo(HistoryMedicationsTable);
