import React, { Fragment, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ActionIcon } from '@mummssoftware/common-ui';
import AddCircleIcon from '@material-ui/icons/Add';
import {
  SET_MEASURE_SELECTED,
  SET_TABLE_NAME,
  SET_NEW_MEASURE,
  SHOW_TABLE,
  vitalsType,
  scoresType,
  MEASURE_TYPE,
  SET_SCORES_METHOD,
  RESET_SCORES,
  SET_SUBMIT_ACTIVE,
} from '../MeasuresContext/utils/constants';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import { Context } from '../../context';

const StyledSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

type MeasuresMenuProps = {
  // three scenarios
  measures: mumms.measureType[] | mumms.measureTypeNames[] | string[];
  addIcon?: boolean;
};

const ITEM_HEIGHT = 38;

const useStyles = makeStyles((theme) => ({
  menuPaper: { maxHeight: ITEM_HEIGHT * 4, width: 120 },
}));

export const MeasuresMenu = ({ measures, addIcon }: MeasuresMenuProps) => {
  // needs to account for 2 scenarios
  let items: any;
  if (addIcon) {
    items = (measures as mumms.measureType[]).map((m) => m.shortname);
  }

  const classes = useStyles();
  const { dispatch } = useContext(MeasuresContext);
  const { onMeasureSelectedChange } = useContext(Context);
  const measureType = localStorage.getItem(
    MEASURE_TYPE,
  ) as mumms.measureTypeNames;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event: any) => {
    dispatch({
      type: SET_SCORES_METHOD,
      payload: null,
    });
    dispatch({
      type: RESET_SCORES,
    });
    dispatch({
      type: SET_SUBMIT_ACTIVE,
      payload: false,
    });
    dispatch({
      type: SHOW_TABLE,
      payload: false,
    });
    dispatch({
      type: SET_NEW_MEASURE,
      payload: false,
    });
    dispatch({
      type: SET_MEASURE_SELECTED,
      payload: null,
    });
    dispatch({
      type: SET_TABLE_NAME,
      payload: null,
    });
    if (event.target.checked) {
      localStorage.setItem(MEASURE_TYPE, scoresType);
      onMeasureSelectedChange(scoresType);
    } else {
      localStorage.setItem(MEASURE_TYPE, vitalsType);
      onMeasureSelectedChange(vitalsType);
    }
  };

  const onOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (selectedItem: any) => () => {
    if (addIcon) {
      dispatch({
        type: SET_MEASURE_SELECTED,
        payload: selectedItem,
      });
      dispatch({
        type: SET_NEW_MEASURE,
        payload: true,
      });
      dispatch({
        type: SHOW_TABLE,
        payload: false,
      });
      // Bad hack to get table reset on measure selected
      // componentDidMount() is invoked immediately after a component is mounted (inserted into the tree).
      // this does mount the component and reset content of top pinned row: hide the node and show the node
      // React ForceRefesh on click does not work on refreshing top pinned row content.
      // AG GRID Force Refresh the top pinned rows on click does not work
      // api.redrawRows(redrawRowsParams) the top pinned rows does not work
      // api.refreshCells(cellRefreshParams) does not work or throws error
      setTimeout(() => {
        dispatch({
          type: SHOW_TABLE,
          payload: true,
        });
      }, 50);
    } else {
      // hide table and set to null
      dispatch({
        type: SHOW_TABLE,
        payload: false,
      });
      dispatch({
        type: SET_NEW_MEASURE,
        payload: false,
      });
      dispatch({
        type: SET_MEASURE_SELECTED,
        payload: null,
      });
      dispatch({
        type: SET_SCORES_METHOD,
        payload: null,
      });
    }

    setAnchorEl(null);
  };

  return (
    <Fragment>
      {!addIcon && (
        <Typography
          component="div"
          variant="body2"
          style={{ letterSpacing: 0 }}
        >
          <Grid component="div" container alignItems="center">
            <Grid item>{vitalsType.toLocaleUpperCase()}</Grid>
            <Grid item>
              <StyledSwitch
                checked={measureType === scoresType}
                onChange={handleChange}
                name="checked"
              />
            </Grid>
            <Grid item>ESAS</Grid>
          </Grid>
        </Typography>
      )}

      {addIcon && (
        <ActionIcon
          title="Add new measure"
          style={{ margin: 0 }}
          Icon={AddCircleIcon}
          onClick={onOpen}
        />
      )}
      {addIcon && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          PaperProps={{
            classes: { elevation8: classes.menuPaper },
          }}
        >
          {(items as any).map((item: string) => (
            <MenuItem key={item} onClick={onMenuItemClick(item)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Fragment>
  );
};
export default MeasuresMenu;
