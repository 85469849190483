export const VISITS_OFFLINE = 'VISITS_OFFLINE';
export const CURRENT_USER = 'CURRENT_USER';
export const AGENCY = 'AGENCY'


// additional default scheduled visit frequency fields
export const fields = {
  discipline: '',
  workerName: 'Not Scheduled',
  frequencyText: '',
  effectiveDate: '',
  status: 'incomplete',
  isCurrentPlan: true,
};

// TODO: When the pgRest works, use a api call instead so the roleGroups stay up todate

export const roleGroups =
  [
    {
      id: -15,
      name: 'Finance',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -16,
      name: 'Administration',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -17,
      name: 'Sales And Marketing',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: 17,
      name: 'Relationship Manager',
      issystemdefined: false,
      hcpcscode: '',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -9,
      name: 'Aide',
      issystemdefined: true,
      hcpcscode: 'G0156',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -3,
      name: 'Doctor',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -4,
      name: 'Nurse',
      issystemdefined: true,
      hcpcscode: 'G0154',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -18,
      name: 'Occupational Therapy',
      issystemdefined: true,
      hcpcscode: 'G0152',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -5,
      name: 'Physical Therapy',
      issystemdefined: true,
      hcpcscode: 'G0151',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -8,
      name: 'Social Worker',
      issystemdefined: true,
      hcpcscode: 'G0155',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -19,
      name: 'Speech Therapy',
      issystemdefined: true,
      hcpcscode: 'G0153',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -20,
      name: 'DMS',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -21,
      name: 'Bereavement',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -24,
      name: 'HIS',
      issystemdefined: true,
      hcpcscode: '',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -1,
      name: 'Hummingbird User',
      issystemdefined: true,
      hcpcscode: '',
      required: true,
      clinical: false,
      assignable: false,
    },
    {
      id: -2,
      name: 'Hospice Employee',
      issystemdefined: true,
      hcpcscode: '',
      required: true,
      clinical: false,
      assignable: false,
    },
    {
      id: -10,
      name: 'Spiritual',
      issystemdefined: true,
      hcpcscode: 'G9473',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -23,
      name: 'Dietary',
      issystemdefined: true,
      hcpcscode: 'G9474',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: -26,
      name: 'Other Counselor (MCCM)',
      issystemdefined: true,
      hcpcscode: 'G9475',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -11,
      name: 'Volunteer',
      issystemdefined: true,
      hcpcscode: 'G9476',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -27,
      name: 'Care Coordinator (MCCM)',
      issystemdefined: true,
      hcpcscode: 'G9477',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -28,
      name: 'Other Therapist (MCCM)',
      issystemdefined: true,
      hcpcscode: 'G9478',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -22,
      name: 'Pharmacy',
      issystemdefined: true,
      hcpcscode: 'G9479',
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: 18,
      name: 'Speech Therapist',
      issystemdefined: false,
      hcpcscode: 'G0153',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: 19,
      name: 'Other',
      issystemdefined: false,
      hcpcscode: null,
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: 20,
      name: 'Therapist',
      issystemdefined: false,
      hcpcscode: null,
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: 21,
      name: 'Occupational Therapist',
      issystemdefined: false,
      hcpcscode: 'G0152',
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -31,
      name: 'IDG Team Roles',
      issystemdefined: true,
      hcpcscode: null,
      required: false,
      clinical: false,
      assignable: true,
    },
    {
      id: -32,
      name: 'Surveyor',
      issystemdefined: true,
      hcpcscode: null,
      required: false,
      clinical: true,
      assignable: true,
    },
    {
      id: 22,
      name: 'Other Therapist',
      issystemdefined: false,
      hcpcscode: 'G5100',
      required: false,
      clinical: true,
      assignable: true,
    },
  ];

export const roleGroupsHash = roleGroups.reduce((obj: Record<string, any>, roleGroup) => {
  obj[roleGroup.id.toString()] = roleGroup;
  return obj
}, {})

export const roles =
  [
    {
      id: -10,
      name: 'Medical Director',
      shortName: 'MDR',
      rolegroup_id: -3,
    },
    {
      id: -14,
      name: 'Physical Therapist',
      shortName: 'PT',
      rolegroup_id: -5,
    },
    {
      id: -17,
      name: 'Social Worker',
      shortName: 'SW',
      rolegroup_id: -8,
    },
    {
      id: -16,
      name: 'Speech Therapist',
      shortName: 'ST',
      rolegroup_id: -19,
    },
    {
      id: -20,
      name: 'Chaplain',
      shortName: 'CL',
      rolegroup_id: -10,
    },
    {
      id: -69,
      name: 'Surveyor',
      shortName: 'SV',
      rolegroup_id: -32,
    },
    {
      id: -47,
      name: 'Pharmacist',
      shortName: 'PH',
      rolegroup_id: -22,
    },
    {
      id: -48,
      name: 'Registered Pharmacist',
      shortName: 'RPH',
      rolegroup_id: -22,
    },
    {
      id: -18,
      name: 'Certified Nurse Assistant',
      shortName: 'CNA',
      rolegroup_id: -9,
    },
    {
      id: -19,
      name: 'Home Health Aide',
      shortName: 'HHA',
      rolegroup_id: -9,
    },
    {
      id: -9,
      name: 'Physician',
      shortName: 'MD',
      rolegroup_id: -3,
    },
    {
      id: -11,
      name: 'Nurse Practitioner',
      shortName: 'NP',
      rolegroup_id: -3,
    },
    {
      id: -13,
      name: 'Registered Nurse',
      shortName: 'RN',
      rolegroup_id: -4,
    },
    {
      id: -12,
      name: 'Licensed Practical Nurse',
      shortName: 'LPN',
      rolegroup_id: -4,
    },
    {
      id: -15,
      name: 'Occupational Therapist',
      shortName: 'OT',
      rolegroup_id: -18,
    },
    {
      id: -45,
      name: 'Bereavement Coordinator',
      shortName: 'BC',
      rolegroup_id: -21,
    },
    {
      id: -46,
      name: 'Bereavement Clerk',
      shortName: 'BL',
      rolegroup_id: -21,
    },
    {
      id: -49,
      name: 'Registered Dietician',
      shortName: 'RD',
      rolegroup_id: -23,
    },
    {
      id: -50,
      name: 'HIS Clerk',
      shortName: 'HC',
      rolegroup_id: -24,
    },
    {
      id: -51,
      name: 'Doctor of Osteopathy',
      shortName: 'DO',
      rolegroup_id: -3,
    },
    {
      id: -52,
      name: 'Homemaker',
      shortName: 'HM',
      rolegroup_id: -9,
    },
    {
      id: -66,
      name: 'Hospice Physician',
      shortName: 'HP',
      rolegroup_id: -3,
    },
    {
      id: -61,
      name: 'Physician Assistant',
      shortName: 'PA',
      rolegroup_id: -3,
    },
    {
      id: -56,
      name: 'Certified Independent Social Worker',
      shortName: 'CISW',
      rolegroup_id: -8,
    },
    {
      id: -64,
      name: 'Licensed Clinical Social Worker',
      shortName: 'LCSW',
      rolegroup_id: -8,
    },
    {
      id: 41,
      name: 'Community Representative',
      shortName: null,
      rolegroup_id: 17,
    },
    {
      id: 64,
      name: 'Other Counselor2',
      shortName: 'O2',
      rolegroup_id: 22,
    },
    {
      id: -65,
      name: 'Clinical User',
      shortName: 'STA',
      rolegroup_id: -1,
    },
    {
      id: -23,
      name: 'Patient Care Volunteer',
      shortName: 'VOL',
      rolegroup_id: -11,
    },
  ];

export const rolesHash = roles.reduce((obj: Record<string, any>, role) => {
  obj[role.id.toString()] = role;
  return obj
}, {})

export const getRoleGroupName = (roleId: number | string) =>
  roleGroupsHash[rolesHash[roleId].rolegroup_id].name;

export const ScheduledVisitConfig = {

};
