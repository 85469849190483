import { defineMessages } from 'react-intl';
export default defineMessages({
  create: {
    id: 'common.suggestBox.create',
    // ICU uses apostrophes to escape
    defaultMessage: "Use ''{input}''"
  },
  noOpts: {
    id: 'common.suggestBox.noOpts',
    defaultMessage: 'No options'
  },
  placeholder: {
    id: 'common.suggestBox.placeholder',
    defaultMessage: 'Select...'
  }
});