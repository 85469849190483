/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import DefaultTextArea from 'formiojs/components/textarea/TextArea';

export default class TextArea extends DefaultTextArea {
  render() {
    if (this.options.readOnly && !this.dataValue) {
      return '';
    }
    return super.render();
  }
}
