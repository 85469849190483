import React from 'react';

type ErrorBoundaryState = {
  hasError: boolean;
};
export class ErrorBoundary extends React.PureComponent<
  Record<string, unknown>,
  ErrorBoundaryState
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <p>Failed to retrieve information</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
