import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Loader, WidgetPrintHeader } from '@mummssoftware/common-ui';
import { mobileDetect } from '@mummssoftware/utils/web';
import styled from 'styled-components';
import PatientCert from '../Patient/PatientCert';
import Listing from '../Listing/Listing';
import { AppContext } from './AppContext';
import { withCertContext } from '../../Context';
import { RESTRICTED_ROLES } from '../../constants';
import messages from '../CertForm/CertMessages';
const appRootHeights = {
  height: (props) => (props.embedded ? 'initial' : '100vh'),
  '@media print': {
    '&&': {
      height: 'auto',
    },
  },
};
// var are defined in defaultTheme
const AppRoot = withStyles((theme) => ({
  appRoot: {
    ...(theme.props.isSafari && theme.props.isMobile ? {} : appRootHeights),
    marginLeft: 'var(--safe-area-inset-left)',
    marginRight: 'var(--safe-area-inset-right)',
    marginBottom: 'var(--safe-area-inset-bottom)',
    overflow: 'hidden',
  },
}))(({ children, classes: { appRoot } }) => (
  <div className={appRoot}>{children}</div>
));
// TODO min-height
const CertAppBodyWrap = styled.div`
  max-width: ${(props) => (props.embedded ? '1400' : '1100')};
  min-height: ${(props) => props.embedded && 279}px;
  min-width: ${(props) => props.embedded && 250}px;
  margin: 2px auto;
  height: calc(100% - 4px);
`;
// with print needs a width
const CertAppPrintDiv = styled.div`
  width: auto;
  margin: auto;
  @media print {
    page-break-after: always;
    width: auto;
    max-width: 750px;
  }
`;
const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;
const singlePatientViews = (pathname) =>
  !['recert', 'history'].find((path) => pathname.includes(path));

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      siteId: 17,
      siteList: [],
      isDoctor: null,
      loaded: false,
      queryParams: props.location.search,
    };
  }

  updateUserInfo = async () => {
    const { pim, setDoctors, clearScripts } = this.props;
    const data = await pim.getUserInfo(RESTRICTED_ROLES);

    if (data && this.mounted) {
      const searchParams = new URLSearchParams(window.location.search);
      // site Id from URL if present
      const siteId =
        Number(searchParams.get('siteId')) > 0
          ? Number(searchParams.get('siteId'))
          : data.siteId;
      // NOTE this is because we have a problem with siteId not coming from URL-
      const siteName = siteId
        ? this.getSiteInfo(siteId, data.siteList, 'name')
        : data.siteName;

      const siteNickname = siteId
        ? this.getSiteInfo(siteId, data.siteList, 'nickName')
        : data.siteNickname;

      setDoctors(siteId);
      const hbcLocation = this.getSiteInfo(
        data.siteId,
        data.siteList,
        'hbcLocation',
      );
      this.setState({
        loaded: true,
        firstName: data.firstName,
        lastName: data.lastName,
        userId: data.userId,
        // passing agency because we need it for clearscripts
        agency: data.currentHospiceBin,
        userCredentials: data.userCredentials,
        // site Id from URL if present
        siteId,
        hospiceBin: data.currentHospiceBin,
        hospiceName: data.hospiceName,
        siteName,
        siteNickname,
        siteList: data.siteList,
        isDoctor: data.isDoctor,
        isNP: data.isNP,
        isMumms: data.isMumms,
        enableClinicalLinks: !!(data.hbcFirm && hbcLocation),
        hbcFirm: data.hbcFirm,
        hbcLocation,
      });

      if (data.clearScriptsLogin && siteNickname) {
        const rxData = await clearScripts.getNotifications(siteNickname);
        if (!(rxData instanceof Response)) {
          /* eslint-disable indent */

          const rxDataCount = rxData.length
            ? rxData.reduce((seed, val) => {
                if (seed[val.action]) {
                  seed[val.action] += val.count;
                } else {
                  seed[val.action] = val.count;
                }
                return seed;
              }, {})
            : null;
          if (rxDataCount) {
            const { rxmessage, rxsign } = rxDataCount;
            this.setState({
              rxmessage,
              rxsign,
            });
          }
        }
      }
    }
  };

  m2Url = (
    action,
    patientNumber,
    hbcLocation = this.state.hbcLocation,
    params = '',
  ) =>
    `${process.env.REACT_APP_HBC_BASE_URL}/m2/${action}/${this.state.hbcFirm}/${hbcLocation}/${patientNumber}${params}`;

  getSiteInfo = (siteId, siteList, key) => {
    const currentSite = siteList.length
      ? siteList.find(({ id }) => id === siteId)
      : null;
    return currentSite ? currentSite[key] : null;
  };

  handleAgencyChange = (value) => {
    if (typeof value === 'string' && value !== this.state.hospiceBin) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('agency', value);
      const newRelativePathQuery = `${
        window.location.pathname
      }?${searchParams.toString()}`;
      this.props.history.push(newRelativePathQuery);
      /* eslint-disable no-restricted-globals */
      location.reload();
      /* eslint-enable */
    }
  };

  handleSiteChange = (siteId) => {
    const { setDoctors } = this.props;
    setDoctors(siteId);

    localStorage.setItem('siteId-user', siteId);
    this.setState((prevState) => {
      const hbcLocation = this.getSiteInfo(
        siteId,
        prevState.siteList,
        'hbcLocation',
      );
      const siteNickname = this.getSiteInfo(
        siteId,
        prevState.siteList,
        'nickName',
      );
      return {
        siteId,
        siteNickname,
        hbcLocation,
        enableClinicalLinks: !!(prevState.hbcFirm && hbcLocation),
      };
    });
  };

  componentDidMount = () => {
    this.mounted = true;
    this.updateUserInfo();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    const {
      enableClinicalLinks,
      firstName,
      lastName,
      hbcLocation,
      hospiceBin,
      hospiceName,
      isMumms,
      isDoctor,
      isNP,
      loaded,
      siteId,
      siteList,
      siteName,
      siteNickname,
      queryParams,
      userId,
      rxmessage,
      rxsign,
      agency,
    } = this.state;
    const {
      dateFrom,
      dateTo,
      embedded,
      location: { pathname },
      insuranceCarrierId,
      onError,
      logout,
      keycloak,
      clearScripts,
      viewMode,
      patientNumber,
      printConfig,
    } = this.props;
    const isMobile = mobileDetect();
    const appContext = {
      userCredentials: this.state.userCredentials,
      isDoctor: this.state.isDoctor,
      isNP: this.state.isNP,
      enableClinicalLinks: this.state.enableClinicalLinks,
      m2Url: this.m2Url,
      dateFrom,
      dateTo,
      firstName,
      lastName,
      hospiceBin,
      hospiceName,
      isMobile,
      isMumms,
      logout,
      siteName,
      userId,
      keycloak: this.props.keycloak,
    };
    /**
     * The PatientCert has its own Loader, defer to that instance to avoid awkward re-render.
     *
     * TODO: Refactor Loader to top-level triggerable modal?
     */
    const isSinglePatientView = singlePatientViews(pathname);

    if (!loaded && isSinglePatientView) {
      return <Loader />;
    }

    // isPrintMode is a read only view from dashboard, this is history route in stand alone mode
    const isPrintMode = viewMode === 'print';
    // isEmbeddedMode is the widget in dashboard, this is recert route in stand alone mode
    const isEmbeddedMode = viewMode === 'small' || viewMode === 'full';
    // isStandAloneMode is docLink stand-alone mode
    const isStandAloneMode = !viewMode;

    return (
      <AppContext.Provider value={appContext}>
        {isPrintMode && (
          <div id="cert-print">
            <WidgetPrintHeader
              title={messages.printTitle}
              printConfig={printConfig}
            />
            <CertAppPrintDiv>
              <PatientCert
                historicalView
                isSinglePatientView
                logout={logout}
                loaded={loaded}
                Context={AppContext}
                isMobile={isMobile}
                patientNumber={patientNumber}
                insuranceCarrierId={insuranceCarrierId}
                onWidgetReady={this.props.onWidgetReady}
                printConfig={printConfig}
              />
            </CertAppPrintDiv>
            <PageBreak />
          </div>
        )}
        {isEmbeddedMode && (
          <AppRoot embedded>
            <CertAppBodyWrap embedded id="cert-embedded">
              <PatientCert
                keycloak={keycloak}
                isSinglePatientView
                logout={logout}
                embedded={embedded}
                loaded={loaded}
                Context={AppContext}
                isMobile={isMobile}
                updateUserInfo={this.updateUserInfo}
                patientNumber={patientNumber}
                certIndex={new URLSearchParams(queryParams).get('cert')}
                electionNumber={new URLSearchParams(queryParams).get(
                  'electionNumber',
                )}
                onCancel={onError}
                insuranceCarrierId={insuranceCarrierId}
              />
            </CertAppBodyWrap>
          </AppRoot>
        )}
        {isStandAloneMode && (
          <AppRoot>
            <Route
              path="/"
              exact
              render={(props) => (
                <Listing
                  {...props}
                  PatientCert={PatientCert}
                  updateUserInfo={this.updateUserInfo}
                  handleAgencyChange={this.handleAgencyChange}
                  handleSiteChange={this.handleSiteChange}
                  m2Url={this.m2Url}
                  certContext={AppContext}
                  keycloak={keycloak}
                  logout={logout}
                  clearScripts={clearScripts}
                  {...{
                    firstName,
                    lastName,
                    siteId,
                    siteName,
                    siteNickname,
                    siteList,
                    enableClinicalLinks,
                    hospiceBin,
                    hospiceName,
                    hbcLocation,
                    isMobile,
                    isMumms,
                    userId,
                    isDoctor,
                    isNP,
                    rxmessage,
                    rxsign,
                    agency,
                  }}
                />
              )}
            />
            <Route
              path="/recert/:patientNumber"
              exact
              render={(props) => (
                <CertAppBodyWrap id="cert-wrap">
                  <PatientCert
                    {...props}
                    keycloak={keycloak}
                    isSinglePatientView
                    logout={logout}
                    embedded={embedded}
                    loaded={loaded}
                    Context={AppContext}
                    isMobile={isMobile}
                    updateUserInfo={this.updateUserInfo}
                    patientNumber={props.match.params.patientNumber}
                    certIndex={new URLSearchParams(queryParams).get('cert')}
                    electionNumber={new URLSearchParams(queryParams).get(
                      'electionNumber',
                    )}
                    onCancel={onError}
                    insuranceCarrierId={insuranceCarrierId}
                  />
                </CertAppBodyWrap>
              )}
            />
            {/* as of now the history route is not being used in stand alone mode */}
            <Route
              path="/history/:patientNumber"
              exact
              render={(props) => (
                <div id="cert-wrap">
                  <PatientCert
                    {...props}
                    historicalView
                    isSinglePatientView
                    logout={logout}
                    embedded={embedded}
                    loaded={loaded}
                    Context={AppContext}
                    isMobile={isMobile}
                    updateUserInfo={this.updateUserInfo}
                    patientNumber={props.match.params.patientNumber}
                    certIndex={new URLSearchParams(queryParams).get('cert')}
                    electionNumber={new URLSearchParams(queryParams).get(
                      'electionNumber',
                    )}
                    onCancel={onError}
                    insuranceCarrierId={insuranceCarrierId}
                    onWidgetReady={this.props.onWidgetReady}
                  />
                </div>
              )}
            />
          </AppRoot>
        )}
      </AppContext.Provider>
    );
  }
}

export default withRouter(withCertContext(App));
