import { Plugins } from '@capacitor/core';
import { KEYCLOAK_TOKENS } from './constants';

const { SplashScreen } = Plugins;

export function setTokens(keycloak) {
  localStorage.setItem(
    KEYCLOAK_TOKENS,
    JSON.stringify({
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
    }),
  );
}

function getTokens() {
  return JSON.parse(localStorage.getItem(KEYCLOAK_TOKENS));
}

export function nativeInitSettings() {
  SplashScreen.hide().catch(() => null);
  return { checkLoginIframe: false, adapter: 'default', ...getTokens() };
}
