/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Table } from '@mummssoftware/common-ui';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useTheme } from '@material-ui/core';
import { HeaderTitle, Field } from '../MeasuresContext/utils/type';
import ErrorBoundary from '../Measures/ErrorBoundary';
import {
  getAscendingMeasures,
  formatFullTableDate,
} from '../MeasuresContext/utils/utils';
import {
  MeasureCellRenderer,
  RecordedByCellRenderer,
  PrintUnitsCellRenderer,
  ValuesCellRenderer,
  ScoreCellRenderer,
} from '../Measures/CellRenderer';
import { noPost } from '../MeasuresContext/utils/constants';
import {
  MeasuresPrintDiv,
  TablePrintDiv,
  PrintTextDiv,
} from '../../components/layout/WrapperDiv';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

type MeasuresTablePrintProps = {
  measures: mumms.measureByType;
};

export const MeasuresTablePrint = React.memo(
  ({ measures }: MeasuresTablePrintProps) => {
    const dateField = 'date';
    const recordedByField = 'recordedBy';
    const unitsField = 'units';
    const measureField = 'measureData';
    const commentsField = 'comments';
    const recordField = 'record';
    const methodField = 'observationmethod';

    const {
      props: {
        grid: gridTheme = {
          className: '',
        },
      },
    } = useTheme();

    const [patientMeasures, setPatientMeasures] = useState<mumms.measureByType>(
      measures,
    );
    useEffect(() => {
      setPatientMeasures(measures);
    }, [measures]);

    const getTableData = (measuredata: mumms.measure[]) => {
      if (measuredata) {
        const sortedMeasures = getAscendingMeasures(
          measuredata,
        ) as mumms.measure[];
        const tableData: any[] = sortedMeasures.map((row) => {
          const date = formatFullTableDate(row.measuredatetimeshifted);
          const measurevalue = {
            value: row.displayalue,
            comments: row.comments,
          };
          // Avoid seeing Enter Unit in table
          const units = row.units ? row.units : '  ';
          const tableRow: any = {
            ...row,
            date,
          };
          tableRow[measureField] = measurevalue;
          tableRow[recordField] = noPost;
          tableRow[unitsField] = units;
          return tableRow;
        });
        return tableData;
      }
      return [];
    };

    /* eslint-disable indent */
    const getTableCol = (
      measureName: mumms.measureShortNames,
      measuretype: mumms.measureTypeNames,
    ) => {
      // TODO should not be using string but a variable for 'scores'
      const isScores = measuretype === ('scores' as mumms.measureTypeNames);
      // those 3 measures have long descriptions
      const isLongDesc =
        measureName === 'kps' ||
        measureName === 'pps' ||
        measureName === 'fast';

      if (measureName) {
        // table column widths fixed number for consistent printing layout
        const dateWidth = 120;
        const recordedByWidth = 120;
        const unitsWidth = 120;
        const methodWidth = 120;
        const measureWidth = isLongDesc ? 200 : 130;
        const commentWidth = 170;

        const titleName =
          measureName.charAt(0).toUpperCase() + measureName.slice(1);

        if (isScores) {
          return [
            {
              headerName: 'Date' as HeaderTitle,
              field: dateField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: dateWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
            },
            {
              headerName: 'Recorded by' as HeaderTitle,
              field: recordedByField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: recordedByWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
              cellRendererFramework: RecordedByCellRenderer,
            },
            {
              headerName: titleName as HeaderTitle,
              field: measureField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: measureWidth,
              suppressMenu: true,
              suppressSizeToFit: true,
              cellRendererFramework: ScoreCellRenderer,
            },
            {
              headerName: 'Method' as HeaderTitle,
              field: methodField as Field,
              cellClass: 'measures',
              headerClass: 'header-center',
              width: methodWidth,
              suppressSizeToFit: true,
              suppressMenu: true,
            },
          ];
        }

        return [
          {
            headerName: 'Date' as HeaderTitle,
            field: dateField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: dateWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Recorded by' as HeaderTitle,
            field: recordedByField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: recordedByWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellRendererFramework: RecordedByCellRenderer,
          },
          {
            headerName: 'Units' as HeaderTitle,
            field: unitsField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: unitsWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellRendererFramework: PrintUnitsCellRenderer,
            cellRendererParams: {
              width: unitsWidth,
            },
          },
          {
            headerName: titleName as HeaderTitle,
            field: measureField as Field,
            cellClass: 'long-desc',
            headerClass: 'header-center',
            width: measureWidth,
            suppressMenu: true,
            suppressSizeToFit: true,
            cellRendererFramework: MeasureCellRenderer,
          },
          {
            headerName: 'Comments' as HeaderTitle,
            field: commentsField as Field,
            cellClass: 'measures',
            headerClass: 'header-center',
            width: commentWidth,
            suppressSizeToFit: true,
            suppressMenu: true,
          },
        ];
      }
      return undefined;
    };

    const frameworkComponents = {
      measureCellRenderer: MeasureCellRenderer,
      printUnitsCellRenderer: PrintUnitsCellRenderer,
      valuesCellRenderer: ValuesCellRenderer,
    };

    const onGridReady = ({ api, columnApi }: any) => {
      // Autosize all columns.
      // to make the currently visible columns fit the screen
      // columnApi.autoSizeAllColumns();
      api.sizeColumnsToFit();
      // ag-grid print mode
      // print mode sets height depending on content
      // ag grid needs a defined height otherwise
      api.setDomLayout('print');
    };

    const getTablesByCategory = (
      _measures: mumms.formattedAllMeasures,
      measuretype: mumms.measureTypeNames,
    ) =>
      Object.entries(_measures).map((val) => {
        const measurename = val[0];
        const measurevalues = val[1];
        const isLongDesc =
          measurename === 'kps' ||
          measurename === 'pps' ||
          measurename === 'fast';
        return (
          <TablePrintDiv key={measurename}>
            <Table
              id={measurename as string}
              key={measurename as string}
              AgGridReact={AgGridReact}
              rowData={getTableData(measurevalues as mumms.measure[])}
              columnDefs={getTableCol(
                measurename as mumms.measureShortNames,
                measuretype,
              )}
              onGridReady={onGridReady}
              rowHeight={isLongDesc ? 40 : 26}
              frameworkComponents={frameworkComponents}
            />
          </TablePrintDiv>
        );
      });

    const getTablesPrint = (_measures: mumms.measureByType) =>
      Object.entries(_measures).map((val) => {
        const typename = val[0];
        const typevalues = val[1];
        return (
          <React.Fragment key={typename}>
            <PrintTextDiv key={`${typename}`}>{typename}</PrintTextDiv>
            {getTablesByCategory(
              typevalues as mumms.formattedAllMeasures,
              typename as mumms.measureTypeNames,
            )}
          </React.Fragment>
        );
      });

    const getMeasuresTablePrint = (_measures: mumms.measureByType) => (
      <ErrorBoundary>
        <MeasuresPrintDiv className={`${gridTheme.className} light`}>
          {getTablesPrint(_measures)}
        </MeasuresPrintDiv>
      </ErrorBoundary>
    );

    return (
      <React.Fragment>{getMeasuresTablePrint(patientMeasures)}</React.Fragment>
    );
  },
);

export default MeasuresTablePrint;
