import React, { Fragment, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { BasicList, Loader } from '@mummssoftware/common-ui';
import { formatPhysicianName } from '@mummssoftware/utils/formatters';
import { ATTENDING_FORM } from '../../../constants';
import { CertificationButtons } from './Buttons';
import { InlinePrintLabel } from './PrintLabels';
import CertBodyGrid from './BodyGrid';
import { CertNarrativeField } from './Narrative';
import messages from '../CertMessages';
import { PatientCertContext } from '../../Patient/PatientCertContext';
import { CertContext } from '../../../Context';

/**
 * @typedef f2fDetails
 *  @prop {string} [date]
 *  @prop {string} [link]
 *  @prop {boolean} [required]
 *  @prop {string} [provider]
 */
const PhysStatementDoctorName = ({ certPhysName, contextDoctor }) => {
  let Comp = <InlinePrintLabel />;
  if (certPhysName.length) {
    Comp = certPhysName;
  } else if (contextDoctor) {
    Comp = contextDoctor.label;
  }
  return Comp;
};

const CertDescriptionStatement = ({
  phys,
  isProvider,
  f2fRequired,
  providerSalutation,
  providerFirst,
  providerLast,
  providerCred,
  f2fDate,
  f2fLink,
  smallWidget,
  f2fMissing,
}) => {
  const { selectedDoctor } = useContext(CertContext);
  const f2fVerbiage = isProvider
    ? 'I'
    : /* eslint-disable indent */
      formatPhysicianName(
        providerSalutation,
        providerFirst,
        providerLast,
        providerCred,
      );
  /* eslint-enable */
  const confirm = smallWidget ? 'shortf2fConfirm' : 'f2fConfirm';

  return (
    <div>
      <CertBodyGrid typographyProps={{ component: 'div' }}>
        {!smallWidget && (
          <FormattedMessage
            {...messages.physStatement}
            values={{
              name: (
                <PhysStatementDoctorName
                  certPhysName={phys}
                  contextDoctor={selectedDoctor}
                />
              ),
            }}
          />
        )}
      </CertBodyGrid>
      {(!smallWidget && f2fRequired) ||
      (smallWidget && f2fRequired && !f2fMissing) ? (
        <CertBodyGrid smallWidget={smallWidget}>
          <FormattedMessage
            {...messages[confirm]}
            values={{
              f2fVerbiage,
              f2fLink,
              f2fDate,
              f2fFindings: isProvider ? (
                <FormattedMessage {...messages.isF2fProvider} />
              ) : (
                <FormattedMessage {...messages.notF2fProvider} />
              ),
            }}
          />
        </CertBodyGrid>
      ) : null}
    </div>
  );
};

const CertConfirmationStatement = ({
  CertButtons,
  Footer,
  isDoctor,
  showVerbal,
  smallWidget,
}) => (
  <CertBodyGrid
    typographyProps={{ component: 'div' }}
    smallWidget={smallWidget}
  >
    <Grid container alignItems="center" justify="center" spacing={1}>
      {!smallWidget && (
        // With my signature I hereby ... footer not needed smallWidget
        <Grid
          item
          xs={12}
          // eslint-disable-next-line no-nested-ternary
          sm={CertButtons && isDoctor ? (showVerbal ? 8 : 10) : 12} // Sorry
          style={{ marginRight: 'auto' }}
        >
          <FormattedMessage {...messages.certAttest} />
        </Grid>
      )}
      {Footer}
      {CertButtons}
    </Grid>
  </CertBodyGrid>
);

/**
 *! This should not live here,but there are fires to put out. This needs to be handled
 *! correctly, and in a way that is scalable.
 */
const attdKeys = {
  default: 'attd',
  northidaho: 'nid-attd',
};
let attdMsgKey = attdKeys.default;
let attendingMessageKeys;
const setAttendingKeys = () => {
  attendingMessageKeys = [];
  for (let i = 0; i < 8; i++) {
    const msgKey = `${attdMsgKey}.${i + 1}`;
    if (messages[msgKey]) {
      attendingMessageKeys.push(msgKey);
    }
  }
};
setAttendingKeys();

const QuestionTypography = styled(Typography)`
  margin-bottom: -1em;
`;

const CertBodyAttending = ({
  CertButtons,
  Footer,
  trailingMessage,
  smallWidget,
}) => (
  <PatientCertContext.Consumer>
    {({ hospiceBin }) => {
      const prevKey = attdMsgKey;
      if (attdKeys[hospiceBin] && attdMsgKey !== attdKeys[hospiceBin]) {
        attdMsgKey = attdKeys[hospiceBin];
      } else if (!attdKeys[hospiceBin] && attdMsgKey !== attdKeys.default) {
        attdMsgKey = attdKeys.default;
      }
      if (attdMsgKey !== prevKey) {
        setAttendingKeys();
      }
      return (
        <Fragment>
          {messages[`${attdMsgKey}.header`] ? (
            <CertBodyGrid
              typographyProps={{ component: 'div' }}
              smallWidget={smallWidget}
            >
              <FormattedMessage {...messages[`${attdMsgKey}.header`]} />
            </CertBodyGrid>
          ) : null}
          <CertBodyGrid
            typographyProps={{ component: 'div' }}
            smallWidget={smallWidget}
          >
            {messages[`${attdMsgKey}.question`] ? (
              <QuestionTypography>
                <FormattedMessage {...messages[`${attdMsgKey}.question`]} />
              </QuestionTypography>
            ) : null}
            {!smallWidget && (
              <BasicList
                type="unordered"
                messages={messages}
                messageKeys={attendingMessageKeys}
              />
            )}
            {trailingMessage ? (
              <CertBodyGrid
                typographyProps={{ color: 'textSecondary' }}
                smallWidget={smallWidget}
              >
                {trailingMessage}
              </CertBodyGrid>
            ) : null}
          </CertBodyGrid>
          {messages[`${attdMsgKey}.footer`] ? (
            <CertBodyGrid
              typographyProps={{ component: 'div' }}
              smallWidget={smallWidget}
            >
              <FormattedMessage {...messages[`${attdMsgKey}.footer`]} />
            </CertBodyGrid>
          ) : null}
          {Footer}
          {CertButtons ? (
            <CertBodyGrid
              typographyProps={{ component: 'div' }}
              smallWidget={smallWidget}
            >
              <Grid container alignItems="center" justify="center" spacing={1}>
                {CertButtons}
              </Grid>
            </CertBodyGrid>
          ) : null}
        </Fragment>
      );
    }}
  </PatientCertContext.Consumer>
);

/**
 * @typedef CertBodyHospiceProps
 *  @prop {*} classes
 *  @prop {function} captureNarrative
 *  @prop {function} copyText
 *  @prop {string} [narrative]
 *  @prop {function} pasteText
 *  @prop {string} textCopy
 *  @prop {boolean} alreadyCertified
 *  @prop {string} certPhysName
 *  @prop {function} formatMessage
 *  @prop {f2fDetails} f2fDetails:
 *  @prop {*} formPhys
 *  @prop {boolean} [historicalView]
 *  @prop {boolean} isDoctor
 *  @prop {boolean} print
 *  @prop {boolean} submitting
 *  @prop {number} selectedCertIndex
 *  @prop {string|number} userId
 *  @prop {*} [updates]
 */
/**
 *
 * @param {CertBodyHospiceProps} props
 */
const CertBodyHospice = ({
  classes,
  captureNarrative,
  copyText,
  narrative,
  pasteText,
  textCopy,
  alreadyCertified,
  certPhysName,
  formatMessage,
  f2fDetails: {
    date: f2fDate,
    link: f2fLink,
    required: f2fRequired,
    provider: f2fProvider,
  },
  formPhys,
  historicalView,
  isDoctor,
  print,
  submitting,
  selectedCertIndex,
  takenBy,
  userId,
  smallWidget,
  updates,
  f2fMissing,
  trailingMessage,
}) => {
  const disabledNarrative = !isDoctor || alreadyCertified || submitting;
  return (
    <Fragment>
      <CertDescriptionStatement
        classes={classes}
        phys={certPhysName}
        isProvider={
          (!alreadyCertified && userId === f2fProvider.id) ||
          (alreadyCertified && formPhys.id === f2fProvider.id)
        }
        providerFirst={f2fProvider.firstName}
        providerLast={f2fProvider.lastName}
        providerCred={f2fProvider.credentials}
        f2fDate={f2fDate}
        f2fLink={f2fLink}
        f2fRequired={f2fRequired}
        smallWidget={smallWidget}
        f2fMissing={f2fMissing}
      />

      {/** narrative box showing logic */}
      {smallWidget && disabledNarrative && !narrative ? null : (
        <Grid item xs={12}>
          <Typography style={smallWidget ? { fontSize: '14px' } : {}}>
            <FormattedMessage {...messages.narrative} />
          </Typography>
          <div style={{ position: 'relative' }}>
            <CertNarrativeField
              historicalView={historicalView}
              narrative={narrative}
              smallWidget={smallWidget}
              trailingMessage={trailingMessage}
              inputProps={{
                alreadyCertified,
                captureNarrative,
                copyText,
                classes,
                formatMessage,
                isDoctor,
                narrative,
                pasteText,
                print,
                selectedCertIndex,
                submitting,
                takenBy,
                textCopy,
                updates,
              }}
            />
          </div>
        </Grid>
      )}
    </Fragment>
  );
};

export const CertBodyTransfer = () => (
  <CertBodyGrid>
    <FormattedMessage {...messages.docReceived} />
  </CertBodyGrid>
);

const DefaultCertBody = ({
  classes,
  Footer,
  narrative,
  alreadyCertified,
  attendingRequired,
  canCertify,
  errorState,
  hospiceFormProps,
  formatMessage,
  formNarrativeId,
  formPhysVerbalDate,
  formType,
  handleCertify,
  isDoctor,
  noNarrative,
  submitting,
  selectedCertIndex,
  takenBy,
  attendingPhysicianId,
  userId,
  smallWidget,
  f2fMissing,
  trailingMessage,
}) => {
  let print;
  if (!isDoctor && !alreadyCertified) {
    print = true;
  }
  const showCertButton = !formNarrativeId && !errorState;
  const showVerbal = !formPhysVerbalDate;

  const CertButtons = () =>
    showCertButton ? (
      <CertificationButtons
        padTop={!!Footer}
        noNarrative={noNarrative}
        isDoctor={isDoctor}
        canCertify={canCertify}
        submitting={submitting}
        handleCertify={handleCertify}
        showVerbal={showVerbal}
        alreadyCertified={alreadyCertified}
        formType={formType}
        userId={userId}
        attendingPhysicianId={attendingPhysicianId}
        smallWidget={smallWidget}
      />
    ) : null;
  return (
    <Fragment>
      {alreadyCertified && attendingRequired ? (
        <CertBodyGrid
          typographyProps={{ color: 'error' }}
          smallWidget={smallWidget}
        >
          <FormattedMessage {...messages.awaitAttending} />
        </CertBodyGrid>
      ) : null}
      {formType === ATTENDING_FORM ? (
        <CertBodyAttending
          trailingMessage={trailingMessage}
          smallWidget={smallWidget}
          Footer={Footer}
          CertButtons={<CertButtons />}
        />
      ) : (
        <Fragment>
          <CertBodyHospice
            classes={classes}
            alreadyCertified={alreadyCertified}
            formatMessage={formatMessage}
            isDoctor={isDoctor}
            narrative={narrative}
            print={print}
            selectedCertIndex={selectedCertIndex}
            submitting={submitting}
            takenBy={takenBy}
            smallWidget={smallWidget}
            f2fMissing={f2fMissing}
            trailingMessage={trailingMessage}
            {...hospiceFormProps}
          />
          {!formNarrativeId && alreadyCertified ? null : (
            <CertConfirmationStatement
              key={`cert-footer-${formType}-${selectedCertIndex}`}
              CertButtons={<CertButtons />}
              Footer={Footer}
              isDoctor={isDoctor}
              showVerbal={showVerbal}
              smallWidget={smallWidget}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

/**
 * @typedef Props
 * @prop {any} classes
 * @prop {React.ReactElement} Footer
 * @prop {'loader'|'noAttendingRequired' | 'errorState' | 'default' | 'verbalOnly'} body
 * @prop {boolean} transferredAndVerified
 * @prop {{ [k: string ]: string }} errors
 * @prop {boolean} alreadyCertified
 * @prop {boolean} attendingRequired
 * @prop {boolean} canCertify
 * @prop {string} [narrative]
 * @prop {CertBodyHospiceProps} hospiceFormProps
 * @prop {function} formatMessage
 * @prop {string|number} [formNarrativeId]
 * @prop {string} [formPhysVerbalDate]
 * @prop {'CERTIFYING'|'ATTENDING'} formType
 * @prop {function} handleCertify
 * @prop {boolean} isDoctor
 * @prop {boolean} noNarrative
 * @prop {boolean} submitting
 * @prop {number} selectedCertIndex
 * @prop {boolean} [embedded]

 */
/**
 * @param {Props} props
 */
// TODO
export const CertBody = ({
  classes,
  Footer,
  body,
  transferredAndVerified,
  errors,
  embedded,
  smallWidget,
  f2fMissing,
  ...rest
}) => (
  <Fragment>
    <div style={embedded ? { minHeight: '196px' } : {}}>
      {
        {
          loader: (
            <Grid
              container
              className={classes.loaderGridContainerBody}
              item
              xs={12}
            >
              <Loader contained />
            </Grid>
          ),
          noAttendingRequired: (
            <Grid container className={classes.gridContainerBody} item xs={12}>
              <CertBodyGrid smallWidget={smallWidget}>
                <FormattedMessage {...messages.noAttendingRequired} />
              </CertBodyGrid>
            </Grid>
          ),
          errorState: (
            <Grid container className={classes.gridContainerBody} item xs={12}>
              {Object.entries(errors).map(([key, error]) =>
                error ? (
                  <CertBodyGrid
                    key={key}
                    className={classes.errorMessage}
                    smallWidget={smallWidget}
                  >
                    <FormattedMessage {...messages[key]} />
                  </CertBodyGrid>
                ) : null,
              )}
              {/** do not change order */}
              <DefaultCertBody
                classes={classes}
                Footer={Footer}
                smallWidget={smallWidget}
                f2fMissing={f2fMissing}
                {...rest}
                errorState
                isDoctor={false}
              />
            </Grid>
          ),
          default: (
            <Grid container className={classes.gridContainerBody} item xs={12}>
              {transferredAndVerified ? (
                <CertBodyTransfer />
              ) : (
                <DefaultCertBody
                  classes={classes}
                  smallWidget={smallWidget}
                  f2fMissing={f2fMissing}
                  {...rest}
                  Footer={Footer}
                />
              )}
            </Grid>
          ),
          verbalOnly: (
            <Grid container className={classes.gridContainerBody} item xs={12}>
              <CertBodyGrid smallWidget={smallWidget}>
                <FormattedMessage {...messages.verbalOnly} />
              </CertBodyGrid>
            </Grid>
          ),
        }[body]
      }
    </div>
  </Fragment>
);
