import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, ColumnApi, GridOptions } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import styled from 'styled-components';
import { formatVisitDisplayDate, isPastEvent } from '../../utils/utils';
import { getRoleGroupName } from '../../utils/constant';

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

// TODO build a Visit Interface with proper key when we know exactly when we need
interface ScheduledVisitTableProps {
  smallWidget?: boolean;
  scheduledVisits: Record<string, any>[];
}

type roleId = string | number | null | undefined;

let gridApi: GridApi;

const gridOptions: GridOptions = {
  columnDefs: [
    {
      headerName: 'Start Date',
      field: 'start_date',
    },
    {
      headerName: 'Time',
      field: 'time',
    },
    {
      headerName: 'Care Provider',
      field: 'care_provider',
    },
    {
      headerName: 'Role Group',
      field: 'role_group',
    },
  ],
  onGridReady: (params: any) => {
    gridApi = params.api;
    gridApi.sizeColumnsToFit();
  },
};

const getVisitTime = (isFullDayEvent: boolean, startDate: any) =>
  isFullDayEvent
    ? 'Any'
    : new Date(startDate).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

const getRoleGroup = (roleId: roleId) =>
  roleId ? getRoleGroupName(roleId) : 'NA';

const getCareProvider = (careProvider: any) =>
  careProvider && careProvider.firstName
    ? `${careProvider.lastName}, ${careProvider.firstName}`
    : careProvider;

const ScheduledVisitsTableWrapper = styled.div<{
  smallWidget: boolean | undefined;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `
        min-height: ${props.theme.props.dashboard.smallWidgetHeight}px;
        height: ${props.theme.props.dashboard.smallWidgetHeight}px;`
      : 'height: 50vh;'}
`;

/**
 * @component
 * Styled ScheduledVisit Table. Pass visits and functions callbacks
 */
// TODO: TRANSLATIONS
export const ScheduledVisitTable = ({
  scheduledVisits,
  smallWidget,
}: ScheduledVisitTableProps) => {
  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;

  const visits = scheduledVisits
    ? scheduledVisits.map((visit) => ({
        start_date: formatVisitDisplayDate(visit.startDate),
        time: getVisitTime(visit.isFullDayEvent, visit.startDate),
        care_provider: getCareProvider(visit.careProvider),
        role_group: getRoleGroup(visit.roleId),
      }))
    : [];

  return (
    <ScheduledVisitsTableWrapper
      smallWidget={smallWidget}
      className={`${gridTheme.className} light`}
    >
      <AgGridReact
        gridOptions={gridOptions}
        rowData={visits}
        rowHeight={gridTheme.rowHeight}
        headerHeight={gridTheme.headerHeight}
      />
    </ScheduledVisitsTableWrapper>
  );
};

export default ScheduledVisitTable;

/**
 * VISIT OBJECT EXAMPLE
 */
// endDate: "2020-11-10T16:15:00Z"
// id: 2772500
// isDeleted: false
// isException: false
// isFullDayEvent: false
// isRecurring: false
// parentEventId: null
// patientCarePlanId: null
// patientId: 3
// personId: -48
// priorEndDate: null
// priorStartDate: null
// recurringDayOfWeek: null
// recurringPattern: null
// recurringPatternEndDate: null
// recurringPatternMaxOccurrences: 0
// recurringSaveOption: null
// recurringType: null
// recurringWeekOfMonth: null
// roleId: null
// siteId: 17
// startDate: "2020-11-10T15:45:00Z"
// visitType: "DIRECT_CARE"
