import React from 'react';
import { Loader } from '@mummssoftware/common-ui';

export const Overlay = () => (
  <div
    className="ag-custom-loading-cell"
    style={{ paddingLeft: '10px', lineHeight: '25px' }}
  >
    <Loader />
  </div>
);
