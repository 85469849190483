import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { DialogContent, Dialog } from '@material-ui/core';
import { ActionIcon, BasicList, SectionHeader } from '@mummssoftware/common-ui';
import { Checkmark } from '@mummssoftware/icons';
import { messages } from './messages';

type AlsDialogProps = {
  handleClose: any;
  open: boolean;
};

type ListItemProps = {
  message: MessageDescriptor;
  intl: ReturnType<typeof useIntl>;
};

const dialogMessages = ['one', 'two', 'three', 'four', 'five', 'six'] as const;

export const AlsDialog: React.SFC<AlsDialogProps> = ({ handleClose, open }) => {
  const intl = useIntl();
  return (
    <Dialog
      onClose={handleClose}
      data-testid="als-dialog"
      aria-labelledby="als-dialog-title"
      open={open}
    >
      <SectionHeader
        title={intl.formatMessage(messages.title)}
        nav={
          <ActionIcon
            data-testid="als-close"
            title={intl.formatMessage({ id: 'common.close' })}
            Icon={Checkmark}
            onClick={handleClose}
          />
        }
        id="als-dialog-title"
      />
      <DialogContent>
        <BasicList messages={messages} messageKeys={dialogMessages} />
      </DialogContent>
    </Dialog>
  );
};

export default AlsDialog;
