/**
 * LOGIC MIN STANDARD
 * medatadmit === 'true' => Med at Admission => A
 * medattestation === 'true' => Minimum standards met => Y
 * LOGIC COVERED
 * coveredbyhospice === 'true' => Covered => Y
 * coveredbyhospice === 'fase' => Not Covered => N
 */

export const getMinStandartTooltipTitle = (med) => {
  if (med.medatadmit === 'true') {
    return 'Med at Admission';
  } else if (med.medattestation === 'true') {
    return 'Minimum standards met';
  }
  return 'Not Answered';
};

export const getMinStandardPlaceholder = (med) => {
  if (med.medatadmit === 'true') {
    return 'A';
  } else if (med.medattestation === 'true') {
    return 'Y';
  }
  return '?';
};

export const minStandardOptions = [
  { label: 'Y', value: 'medattestation' },
  { label: 'A', value: 'medatadmit' },
];

export const getCoveredTooltipTitle = (med) => {
  if (med.notcoveredreason !== '') {
    return med.notcoveredreason;
  } else if (med.coveredbyhospice === 'true') {
    return 'Covered';
  } else if (med.coveredbyhospice === 'false') {
    return 'Not Covered';
  }
  return 'Not Answered';
};

export const getCoveredPlaceholder = (med) => {
  if (med.coveredbyhospice === 'true') {
    return 'Y';
  } else if (med.coveredbyhospice === 'false') {
    return 'N';
  }
  return '?';
};

export const coveredOptions = [
  { label: 'Y', value: 'true' },
  { label: 'N', value: 'false' },
];
