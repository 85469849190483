/*  eslint-disable max-classes-per-file, @typescript-eslint/ban-ts-comment, new-cap */
import DefaultRadio from 'formiojs/components/radio/Radio';
import type { components } from 'formiojs/components';
import type { ExtendedComponentSchema } from 'formiojs';
import NestedInputComponent from '../NestedInputComponent';
import settingsForm from './settingsForm';

const type = 'expandedradio';

export interface ExpandedRadio {
  refs: Omit<
    typeof NestedInputComponent.prototype.refs,
    'wrapper' | 'input'
  > & {
    wrapper?: HTMLElement[];
    input?: HTMLInputElement[];
  };
}
export class ExpandedRadio extends NestedInputComponent {
  currentValue: any;

  previousValue: any;

  constructor(
    component: ComponentJSON | typeof components['base'],
    options: Record<string, any>,
    data: any,
  ) {
    super(component, options, data);
    this.type = this.type || type;
    const BaseRadio = new DefaultRadio(component, options, data);
    this.getValueAsString = BaseRadio.getValueAsString.bind(this);
    this.setValueAt = BaseRadio.setValueAt.bind(this);
    this.resetValue = BaseRadio.resetValue.bind(this);
    this.normalizeValue = BaseRadio.normalizeValue.bind(this);
    this.dataValue = this.dataValue || { ...this.defaultValue };
    this.previousValue = this.dataValue.value || null;
  }

  static type = type;

  get templateName() {
    return this.type;
  }

  get emptyValue(): Record<string, any> | string | null {
    return { ...(this._dataValue || {}), value: '' };
  }

  get isRadio() {
    return this.component.inputType === 'radio';
  }

  get defaultSchema() {
    return ExpandedRadio.schema();
  }

  static get builderInfo() {
    return {
      title: 'Expanded Radio',
      icon: 'dot-circle-o',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: ExpandedRadio.schema(),
    };
  }

  static schema(...extend: ExtendedComponentSchema[]) {
    return NestedInputComponent.schema(
      DefaultRadio.schema({
        type: this.type,
        label: 'Default Label',
        input: true,
        tree: false,
        components: [],
      }),
      ...extend,
    );
  }

  get inputKey() {
    return `${this.key}-input`;
  }

  get wrapperKey() {
    return `${this.key}-wrapper`;
  }

  get inputInfo() {
    const info = super.elementInfo();
    info.type = 'input';
    info.changeEvent = 'click';
    info.attr.class = 'form-check-input';
    info.attr.name = `${info.attr.name}[${this.id}]`;
    return info;
  }

  // copied from original Radio component, may need to pass super flags or implement custom logic, tbd
  updateValue(value: any, flags: any) {
    const changed = super.updateValue(value, flags);
    if (changed && this.refs.wrapper) {
      // add/remove selected option class
      const localValue = this.dataValue.value;
      const optionSelectedClass = 'radio-selected';

      this.refs.wrapper.forEach((wrapper: Element, index: number) => {
        const input = this.refs.input && this.refs.input[index];
        if (input && input.value.toString() === localValue?.toString()) {
          // add class to container when selected
          this.addClass(wrapper, optionSelectedClass);
        } else {
          this.removeClass(wrapper, optionSelectedClass);
        }
      });
    }

    if (!flags || !flags.modified || !this.isRadio) {
      return changed;
    }

    // If they clicked on the radio that is currently selected, it needs to reset the value.
    this.currentValue = this.dataValue.value;
    const shouldResetValue =
      !(flags && flags.noUpdateEvent) &&
      this.previousValue === this.currentValue;
    if (shouldResetValue) {
      this.resetValue();
      this.triggerChange();
    }
    this.previousValue = this.dataValue.value;
    return changed;
  }

  attach(element: Element) {
    this.loadRefs(element, {
      [this.inputKey]: 'multiple',
      [this.wrapperKey]: 'multiple',
    });
    this.refs.input = this.refs[this.inputKey] as HTMLInputElement[];
    this.refs.wrapper = this.refs[this.wrapperKey] as HTMLElement[];
    this.refs.input.forEach((input: HTMLInputElement, index: number) => {
      this.addEventListener(input, this.inputInfo.changeEvent, () =>
        this.updateValue(null, {
          modified: true,
        }),
      );
      this.addShortcut(
        input,
        this.component?.values && this.component.values[index]?.shortcut,
      );

      if (this.isRadio) {
        input.checked = this.dataValue.value === input.value;
        this.addEventListener(input, 'keyup', (event: KeyboardEvent) => {
          if (event.key === ' ' && this.dataValue.value === input.value) {
            event.preventDefault();

            this.updateValue(null, {
              modified: true,
            });
          }
        });
      }
    });
    return super.attach(element);
  }

  detach(element: any) {
    if (element && this.refs.input) {
      this.refs.input.forEach((input: HTMLInputElement, index: number) => {
        this.removeShortcut(
          input,
          this.component?.values && this.component.values[index]?.shortcut,
        );
      });
    }
    super.detach();
  }

  getValue() {
    if (this.viewOnly || !this.refs.input || !this.refs.input.length) {
      return this.dataValue.value;
    }
    let { value } = this.dataValue;
    this.refs.input.forEach((input: HTMLInputElement) => {
      if (input.checked) {
        value = input.value;
      }
    });
    return value;
  }

  /**
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  render() {
    return super.render(
      this.renderTemplate('expandedradio', {
        children: this.renderComponents(),
        key: this.key,
        nestedKey: this.nestedKey,
        collapsed: this.collapsed,
        input: this.inputInfo,
        inline: this.component.inline,
        customChildClass: this.component.customChildClass,
        values: this.component.values,
        value: this.dataValue.value,
        row: this.row,
      }),
    );
  }
}

export default ExpandedRadio;
