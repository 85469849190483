import React from 'react';
import styled from 'styled-components';
import { flatten, uniqBy, chunk } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import {
  formatDateMMDDYY,
  formatDateMMDDYYYY,
} from '@mummssoftware/utils/formatters';
import { WidgetPrintHeader, NoRecordsPrintDiv } from '@mummssoftware/common-ui';
import { safariDetect } from '@mummssoftware/utils/web';
import { messages } from '../../utils/messages';
import { sortPrintMeds, mapPrintMeds } from '../../utils/utils';
import defaults from '../../../translations/en.json';

const isSafari = safariDetect();

const DivSpacedLine = styled.div`
  display: block;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: -50px;
`;

const SignatureLine = styled.div`
  list-style-type: none;
  padding-bottom: 5px;
`;

const StyledApprovalStatement = styled.div`
  margin-top: 30px;
  border: 1px solid;
  padding: 20px;
  display: block;
  border-color: white;
  @media print {
    border-color: black;
  }
`;

// const BlockLine = styled.div`
//   margin-top: 1px;
//   border: 1px solid white;
//   padding: 5px;
// `;

const PrintHeader = styled.th`
  font-weight: 500;
`;
// display none if not printing
const PrintContainer = styled.div`
  ${(props) => (props.isPrintMode ? '' : 'display: none;')}
  overflow: visible;
  color: white !important;
  @media print {
    @page {
      size: landscape;
      margin: 5mm 0mm;
    }
    display: block;
    color: black !important;
    background-color: #ffffff;
    overflow: visible;
    visibility: visible;
    body {
      /* this affects the margin on the content before sending to printer */
      margin: 0px;
      -webkit-print-color-adjust: exact;
    }
    div {
      page-break-inside: avoid;
    }
    table {
      page-break-after: auto;
    }
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

// the color is white for the print interface
// the color is black when printing
const PrintTable = styled.table`
  width: 100%;
  display: table;
  color: white;
  visibility: visible;
  font-size: ${isSafari ? '.08in' : '10px'};
  @media print {
    font-family: 'auto', sans-serif;
    color: black;
  }
`;

const Header = styled.thead`
  display: table-header-group;
`;

const HeaderGrid = styled(Grid)`
  margin-top: 10px;
  padding: 0 6px;
`;

const PrintDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const MedicationsContains = styled.tbody``;

const Medication = styled.li`
  page-break-inside: avoid;
  list-style-type: none;
  padding: 5px;
  border-bottom: 1px solid lightgray;
`;

const Details = styled.div`
  margin: 0;
  margin-top: 8px;
  width: 75%;
  font-style: italic;
  font-size: ${isSafari ? '.06in' : '8px'};
`;

const StyledHeader = styled.h3`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const LandscapeOrientation = () => (
  <style type="text/css">
    {'@media print{@page {size: landscape; margin: 5mm 0mm;}}'}
  </style>
);

const MedicationName = styled.span`
  font-size: ${isSafari ? '.10in' : '14px'};
  font-weight: bold;
`;

const formatDate = (m) => formatDateMMDDYY(m).replaceAll('-', '/');

// RIBBON Widget: Include all Electronic boxes for each physician that signed electronically, no duplicates, within the date range
// MEDS Widget: Include only the LAST Electronic box for each physician that signed electronically.
const buildElectronicSigList = (allmeds, config) => {
  const flags = {};
  const sigList = allmeds
    .map((m) => ({
      reconciledphysicianfirstname: m.reconciledphysicianfirstname,
      reconciledphysicianid: m.reconciledphysicianid,
      reconciledphysicianlastname: m.reconciledphysicianlastname,
      reconciledsource: m.reconciledsource,
      reconcileduserfirstname: m.reconcileduserfirstname,
      reconcileduserid: m.reconcileduserid,
      reconcileduserlastname: m.reconcileduserlastname,
      reconcileddate: m.reconcileddate,
    }))
    .filter((m) => {
      if (flags[m.reconcileddate] || !m.reconcileddate) {
        return false;
      }
      flags[m.reconcileddate] = true;
      return true;
    });

  const list = sigList.reduce((seed, med) => {
    if (seed[med.reconciledphysicianid]) {
      seed[med.reconciledphysicianid].push(med);
    } else {
      seed[med.reconciledphysicianid] = [med];
    }
    return seed;
  }, {});
  const uniqList = Object.values(list).map((listitem) =>
    uniqBy(listitem, (x) => formatDateMMDDYYYY(x.reconcileddate)),
  );
  const orderedList = uniqList.map((listitem) =>
    listitem.sort(
      (a, b) => new Date(b.reconcileddate) - new Date(a.reconcileddate),
    ),
  );
  if (config) {
    return flatten(Object.values(orderedList));
  }
  const lastItem = orderedList.map((listitem) => listitem[0]);
  return flatten(Object.values(lastItem));
};

const medsNotReconciled = (meds) => meds.filter((m) => !m.reconcileddate);

const getCoveredLabel = (covered) => {
  switch (covered) {
    case 'true':
      return <FormattedMessage {...messages.coveredYes} />;
    case 'false':
      return <FormattedMessage {...messages.coveredNo} />;
    default:
      return null;
  }
};

const verbalReceived = (medication) => {
  const {
    preparerfirstname: firstName,
    preparerlastname: lastName,
  } = medication;

  return firstName ? `${lastName}, ${firstName}` : '';
};

const orderingPhysicianLabel = (medication) => {
  const {
    orderingphysicianfirstname: firstName,
    orderingphysicianlastname: lastName,
    orderingphysiciancredentials: credentials,
  } = medication;

  return `${lastName}${credentials ? ` ${credentials}` : ''}, ${firstName}`;
};

class PrintLayout extends React.Component {
  componentDidMount() {
    // Include this so React won't complain about making this a
    // functional component.
    const { onWidgetReady } = this.props;
    // callback to alert dashboard the view is ready
    setTimeout(() => {
      onWidgetReady && onWidgetReady('ClearScripts');
    }, 2000);
  }

  render() {
    const {
      medications,
      patient,
      facility,
      hospiceName,
      widgetTitle,
      isPrintMode,
      printConfig,
    } = this.props;

    const today = new Date();
    const isIdgMeeting = printConfig && printConfig.idg;
    const medsToPrint = !!medications.length;
    const activeMeds = medications.filter(
      (med) => today < new Date(med.stopdate) || med.stopdate === '',
    );
    const mappedActive = mapPrintMeds(activeMeds);
    const mappedActiveMeds = sortPrintMeds(mappedActive);

    const inactiveMeds = medications.filter(
      (med) => med.stopdate && today > new Date(med.stopdate),
    );

    const mappedInactive = mapPrintMeds(inactiveMeds);
    const mappedInactiveMeds = sortPrintMeds(mappedInactive);

    const sortedActive = mappedActiveMeds.map((el) => activeMeds[el.index]);
    const sortedInactive = mappedInactiveMeds.map(
      (el) => inactiveMeds[el.index],
    );
    const allMeds = [...sortedActive, ...sortedInactive];
    const chunckedAllMeds = chunk(allMeds, 15);

    return (
      <PrintDiv id="meds-print-layout">
        {widgetTitle && <WidgetPrintHeader title={widgetTitle} />}
        <PrintContainer isPrintMode={isPrintMode}>
          {medsToPrint ? (
            <PrintTable>
              <Header>
                <tr>
                  <th>
                    <StyledHeader>
                      {patient} - {hospiceName} - {facility}
                    </StyledHeader>
                    <HeaderGrid direction="row" align="left" container>
                      <Grid xs={5} align="left" container item>
                        <Grid item>
                          <FormattedMessage {...messages.name} />
                        </Grid>
                      </Grid>
                      <Grid xs={7} align="left" container item>
                        <Grid xs={2} item>
                          <FormattedMessage {...messages.quantity} />
                        </Grid>
                        <Grid xs={3} item>
                          <FormattedMessage {...messages.physician} />
                        </Grid>
                        <Grid xs={3} item>
                          <FormattedMessage {...messages.verbalCol} />
                        </Grid>
                        <Grid xs={1} item>
                          <FormattedMessage {...messages.covered} />
                        </Grid>
                        <Grid xs={3} item>
                          <FormattedMessage {...messages.date} />
                        </Grid>
                      </Grid>
                    </HeaderGrid>
                  </th>
                </tr>
              </Header>
              <LandscapeOrientation></LandscapeOrientation>
              <MedicationsContains>
                {chunckedAllMeds.map((medsarray, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`print-${i}`}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <PrintHeader key={`print-${i}`}>
                      {medsarray.map((medication, ind) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Medication key={`print--${ind}-${medication.id}`}>
                          <Grid
                            direction="row"
                            justify="space-between"
                            align="left"
                            container
                          >
                            <Grid xs={5} align="left" container item>
                              <Grid item>
                                <MedicationName>{`${medication.drugdescription}`}</MedicationName>
                              </Grid>
                            </Grid>
                            <Grid xs={7} align="left" container item>
                              <Grid xs={2} item>
                                {Math.trunc(medication.quantity)}
                              </Grid>
                              <Grid xs={3} item>
                                {medication.orderingphysicianid &&
                                  orderingPhysicianLabel(medication)}
                              </Grid>
                              <Grid xs={3} item>
                                {verbalReceived(medication)}
                              </Grid>
                              <Grid xs={1} item>
                                {getCoveredLabel(medication.coveredbyhospice)}
                              </Grid>
                              <Grid xs={3} item>
                                {medication.startdate
                                  ? formatDate(medication.startdate)
                                  : `${formatDate(medication.lastupdatedate)}`}

                                {medication.stopdate
                                  ? ` - ${formatDate(medication.stopdate)}`
                                  : ''}
                              </Grid>
                            </Grid>
                          </Grid>
                          {medication.sig ? (
                            <Details>{medication.sig}</Details>
                          ) : null}
                        </Medication>
                      ))}
                    </PrintHeader>
                  </tr>
                ))}
              </MedicationsContains>
              {/* only show boxes if NOT IDG MEETING */}
              {!isIdgMeeting && (
                <tfoot>
                  <tr>
                    <PrintHeader>
                      {/* only show  blank Manual Signature boxes when printing from Meds sheet */}
                      {/* only show if there is a physician listed that does not have login credentials */}
                      {/* <BlockLine /> */}
                      {/* <BlockLine /> */}
                      {!printConfig && medsNotReconciled(medications).length ? (
                        <StyledApprovalStatement>
                          <FormattedMessage {...messages.physApprovalState} />
                          <Grid direction="row" justify="flex-end" container>
                            <Grid xs={4} item>
                              <DivSpacedLine>
                                <SignatureLine>
                                  X___________________________Date___________
                                </SignatureLine>
                              </DivSpacedLine>
                            </Grid>
                          </Grid>
                        </StyledApprovalStatement>
                      ) : null}
                      {buildElectronicSigList(medications, printConfig).map(
                        (m, i) => (
                          <StyledApprovalStatement
                            // eslint-disable-next-line react/no-array-index-key
                            key={`signed-${i}-${m.id}-${m.lastupdatedate}`}
                          >
                            <FormattedMessage
                              {...messages.reconcileAck}
                              values={{
                                doctor: `${m.reconcileduserlastname}, ${m.reconcileduserfirstname}`,
                                date: m.reconcileddate.split(' ')[0],
                              }}
                            />
                          </StyledApprovalStatement>
                        ),
                      )}
                    </PrintHeader>
                  </tr>
                </tfoot>
              )}
            </PrintTable>
          ) : (
            <NoRecordsPrintDiv>
              <p>{defaults.noMeds}</p>
            </NoRecordsPrintDiv>
          )}
        </PrintContainer>
      </PrintDiv>
    );
  }
}

export default PrintLayout;
