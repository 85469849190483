import React from 'react';
import styled from 'styled-components';
import { Backdrop as BaseBackdrop } from '@material-ui/core';
import { DelayedLoader as BaseLoader } from '@mummssoftware/common-ui';

const Backdrop = styled(BaseBackdrop)`
  && {
    z-index: 1000;
  }
`;
export const Loader = () => (
  <Backdrop open>
    <BaseLoader />
  </Backdrop>
);
