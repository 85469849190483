import React, { lazy, Suspense } from 'react';
import ClearScripts from '@mummssoftware/clearscripts';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Dialog, Box } from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  ActionIcon,
  CardHeaderTab,
  HeightProviderWithWindowHeight,
  Loader,
  TopBar as ButtonAppBar,
  DialogBox,
} from '@mummssoftware/common-ui';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { RxModal } from './Components/RxModal';
import messages from './Components/Messages';

const CertListingGrid = lazy(() => import('./CertListingGrid'));
const ApprovalListingGrid = lazy(() => import('./ApprovalListingGrid'));

const modalMaxWidth = 1100;
const baseCertWrapStyle = {
  display: 'contents',
  height: '100%',
  maxWidth: modalMaxWidth,
};

const NavWrapper = styled.div`
  margin-right: 8px;
`;

const RxIconWrap = styled.div`
  margin-right: ${(props) => (props.isMobile ? '2px' : '15px')};
`;

const styles = (theme) => ({
  modalRoot: {
    marginTop: 'var(--safe-area-inset-top)',
    '& > * > *::-webkit-scrollbar-track, & > * > *::-webkit-scrollbar-corner': {
      background: `${theme.palette.background.default}ce`,
    },
    '@media print': {
      height: 'fit-content',
      top: 'unset !important',
      bottom: 'unset !important',
    },
  },
  modalRootMobile: {
    '& > * > *::-webkit-scrollbar-track, & > * > *::-webkit-scrollbar-corner': {
      background: theme.palette.background.header,
    },
    [theme.breakpoints.up('sm')]: {
      '@media print': {
        maxWidth: theme.props.isSafari ? 'calc(100% - 3cm)' : null,
      },
    },
  },
  modal: {
    maxWidth: modalMaxWidth,
    alignSelf: 'flex-start',
    background: `linear-gradient(to bottom, transparent 120px,${theme.palette.background.paper} 120px)`,
    width: '100%',
    isolation: 'isolate',
    [theme.breakpoints.only('xs')]: {
      maxHeight: 'calc(100vh - 3px)',
      margin: '2px 0',
    },
    '@media print': {
      background: theme.props.printRules.backgroundColor,
      margin: theme.props.printRules.bodyMargin,
      boxShadow: 'none',
    },
  },
  modalWidthFalse: {
    alignSelf: 'flex-start',
  },
  loaderPaper: {
    background: 'transparent',
  },
  openRoot: {
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    position: 'absolute',
    filter: 'blur(1px)',
    touchAction: 'none',
    ...theme.props.printRules.hide,
  },
});

class Listing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      certOpen: false,
      medOpen: false,
      rxModalOpen: false,
      certLoaded: false,
      certNav: false,
      approvalNav: true,
      rxLink: null,
    };
  }

  static defaultProps = {
    isMobile: false,
    enableClinicalLinks: false,
  };

  headerRef = React.createRef();

  handleSelectCertTab = () => {
    this.setState({ certNav: false, approvalNav: true });
  };

  handleSelectApprovalTab = () => {
    this.setState({ certNav: true, approvalNav: false });
  };

  handleCertClose = () => {
    this.setState({
      certOpen: false,
      certLoaded: false,
      fullScreenDialog: false,
    });
  };

  handleMedClose = () => {
    this.setState({
      medOpen: false,
    });
  };

  openCertWindow = ({ event: { target }, data }) => {
    // sometimes target is actually an svg path, so check the parent element as well
    const isExternalLink =
      target.attributes['external-link'] ||
      target.parentElement.attributes['external-link'];

    if (!isExternalLink) {
      this.setState({
        certOpen: true,
        fullScreenDialog: true,
        selectedPatientId: data.patientNumber,
        selectedPatientCarrierId: data.insuranceCarrierId,
        selectedPatientElectionNumber: data.electionNumber,
      });
    }
  };

  openRxModal = async (action) => {
    const rxLink = (await this.getExternalURL(action)) || {};
    this.setState({
      rxModalOpen: true,
      rxLink,
    });
  };

  closeRxModal = () => {
    this.setState({
      rxModalOpen: false,
    });
  };

  getExternalURL = async (action) => {
    const url = await this.props.clearScripts.getRxExternalUrl(
      this.props.siteNickname,
      this.props.agency,
      action,
    );
    const response = {};
    response.status = url.status;
    if (url.status === 403) {
      response.message = <FormattedMessage {...messages.notSetup} />;
      return response;
    }
    response.url = await url?.text();
    return response;
  };

  onCertLoad = () => {
    this.setState({ certLoaded: true, fullScreenDialog: false });
  };

  openCellWindow = (data) => {
    const patient = data.data;
    // this is read-only view if not a physician or a nurse practitioner
    if (this.props.isDoctor || this.props.isNP) {
      if (data.colDef.field === 'numUnreconciledMeds') {
        this.setState({
          medOpen: true,
          selectedPatientId: patient.patientNumber,
          medTitle: `Medications: ${patient?.patientFirstname} ${patient?.patientLastname} (${patient?.patientNumber})`,
        });
      }
    }
  };

  render() {
    const {
      classes,
      PatientCert,
      certContext,
      enableClinicalLinks,
      firstName,
      handleAgencyChange,
      handleSiteChange,
      hbcLocation,
      hospiceBin,
      hospiceName,
      isDoctor,
      isMobile,
      isMumms,
      lastName,
      logout,
      m2Url,
      siteId,
      siteList,
      siteNickname,
      updateUserInfo,
      userId,
      keycloak,
      rxmessage,
      rxsign,
    } = this.props;
    const { rxModalOpen, rxLink } = this.state;
    return (
      <div
        className={
          this.state.certOpen || this.state.medOpen ? classes.openRoot : ''
        }
      >
        <ButtonAppBar
          ref={this.headerRef}
          title={
            <Box display="flex" flexDirection="row">
              <NavWrapper>
                <CardHeaderTab
                  handleClick={this.handleSelectCertTab}
                  active={this.state.approvalNav}
                  bottom={-2}
                >
                  {this.props.intl.formatMessage(messages.certTab)}
                </CardHeaderTab>
              </NavWrapper>
              <NavWrapper>
                <CardHeaderTab
                  handleClick={this.handleSelectApprovalTab}
                  active={this.state.certNav}
                  bottom={-2}
                >
                  {this.props.intl.formatMessage(messages.approvalTab)}
                </CardHeaderTab>
              </NavWrapper>
            </Box>
          }
          titleInfo={`v${process.env.REACT_APP_VERSION}`}
          firstName={firstName}
          lastName={lastName}
          leftAction={
            <RxIconWrap isMobile={isMobile}>
              {rxmessage > 0 && (
                <ActionIcon
                  title={`Refills & Rx Changes: ${rxmessage}`}
                  htmlColor="#DC143C"
                  style={{
                    margin: 0,
                    'margin-right': 3,
                    width: 20,
                    height: 20,
                    boxShadow: '0 0 10px #DC143C',
                  }}
                  onClick={() => this.openRxModal('rxmessage')}
                  Icon={ErrorRoundedIcon}
                />
              )}
              {rxsign > 0 && (
                <ActionIcon
                  title={`Rx Pending & Sent: ${rxsign}`}
                  htmlColor="#DC143C"
                  style={{
                    margin: 0,
                    width: 20,
                    height: 20,
                    boxShadow: '0 0 10px #DC143C',
                  }}
                  onClick={() => this.openRxModal('rxsign')}
                  Icon={ErrorRoundedIcon}
                />
              )}
            </RxIconWrap>
          }
          siteList={siteList}
          currentAgency={
            /* eslint-disable indent */
            hospiceBin
              ? {
                  hospiceBin,
                  hospiceName,
                }
              : undefined
          }
          currentSite={{
            id: siteId,
            nickName: siteNickname,
          }}
          shadow={false}
          enableAgencyPicker={isMumms}
          logout={logout}
          onAgencyChange={handleAgencyChange}
          onSiteChange={handleSiteChange}
        />
        <HeightProviderWithWindowHeight passedRef={this.headerRef}>
          {({ windowHeight, height }) => (
            <Suspense fallback={<Loader />}>
              {!this.state.certNav && (
                <CertListingGrid
                  isDoctor={isDoctor}
                  certClosed={!this.state.medOpen && !this.state.certLoaded}
                  siteId={siteId}
                  userId={userId}
                  enableClinicalLinks={enableClinicalLinks}
                  hbcLocation={hbcLocation}
                  isMobile={isMobile}
                  m2Url={m2Url}
                  openCertWindow={this.openCertWindow}
                  windowHeight={windowHeight}
                  height={height}
                />
              )}
              {!this.state.approvalNav && (
                <ApprovalListingGrid
                  isDoctor={isDoctor}
                  medClosed={!this.state.medOpen}
                  siteId={siteId}
                  userId={userId}
                  enableClinicalLinks={enableClinicalLinks}
                  hbcLocation={hbcLocation}
                  isMobile={isMobile}
                  m2Url={m2Url}
                  openCellWindow={this.openCellWindow}
                  windowHeight={windowHeight}
                  height={height}
                />
              )}
            </Suspense>
          )}
        </HeightProviderWithWindowHeight>
        <Dialog
          classes={{
            root: `${classes.modalRoot} ${
              isMobile ? classes.modalRootMobile : ''
            }`,
            paperFullScreen: classes.loaderPaper,
            paperWidthLg: classes.modal,
            paperWidthFalse: classes.modalWidthFalse,
          }}
          maxWidth={this.state.certLoaded ? 'lg' : false}
          fullScreen={this.state.fullScreenDialog}
          open={this.state.certOpen}
          onClose={this.handleCertClose}
        >
          <div
            id="cert-wrap"
            style={
              isMobile
                ? { overflow: 'hidden', ...baseCertWrapStyle }
                : baseCertWrapStyle
            }
          >
            <PatientCert
              key={`cert-modal-${this.state.selectedPatientId}`}
              Context={certContext}
              maxWidth={modalMaxWidth}
              logout={logout}
              patientNumber={this.state.selectedPatientId}
              insuranceCarrierId={this.state.selectedPatientCarrierId}
              electionNumber={this.state.selectedPatientElectionNumber}
              onCancel={this.handleCertClose}
              onDataLoad={this.onCertLoad}
              updateUserInfo={updateUserInfo}
              showClose
            />
          </div>
        </Dialog>
        <DialogBox
          top
          open={this.state.medOpen}
          fullWidth={this.state.medOpen}
          maxWidth="xl"
          handleClick={this.handleMedClose}
          title={this.state.medTitle}
        >
          <ClearScripts
            embedded
            maintainMed
            online
            patientNumber={this.state.selectedPatientId}
            keycloak={keycloak}
            logout={logout}
            dateFrom={undefined}
            dateTo={undefined}
            agency={localStorage.getItem('agency-support')}
            onError={undefined}
          />
        </DialogBox>
        {rxLink && (
          <RxModal
            open={rxModalOpen}
            toggleModal={this.closeRxModal}
            link={rxLink}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(withStyles(styles, { withTheme: true })(Listing));
