/**
 * A few notes on browser detection:
 * iOS Browsers all use Safari at their core. Any web app built to be compatible with
 * Safari on iOS (at least as far as styling goes) should work on other iOS browsers.
 *
 * Desktop and iOS Safari are extremely similar. It seems like iOS Safari may have some
 * possible limitations compared to desktop, so develop for mobile first. If it works
 * there, it should work on desktop as well.
 */
export function safariDetect() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit || !!window.ApplePaySession;
} // navigator.userAgent for IPAD does not show iPad nor Mobile for Version/13.1 (ipad 7th)
// By default Safari will now request the desktop websites on the iPad.
// for version below it will show ipad
// IPAD => Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.1 Safari/605.1.15
// PHONE => "Mozilla/5.0 (iPhone; CPU iPhone OS 12_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Mobile/15E148 Safari/604.1"

export function mobileDetect() {
  return /Mobi|Android/i.test(navigator.userAgent);
}
/* 
  ##Device = iPad Pro
  ##Screen = 1024 to 1366px
*/

const breakpoints = {
  xl: 1700,
  lg: 1366,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 0
}; // isSmallTouchDevice => ipad or iphone
// needed as no reliable way with navigator.userAgent

export function isSmallTouchDevice() {
  // For ios below 13 this is how to see if ipad
  const isMobile = mobileDetect(); // For ios above 13 Use Navigator.maxTouchPoints to detect if the user's device has a touchscreen.

  const hasTouchPoint = navigator.maxTouchPoints > 1; // but Desktop have touchscreen as well...
  // IPad width is mainly 1024, means as well desktop in xs mode is detected ipad

  const isLargeScreen = window.innerWidth > breakpoints.lg;
  return isMobile || hasTouchPoint && !isLargeScreen;
}