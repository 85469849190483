/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { formatDateMMDDYYYY } from '@mummssoftware/utils/formatters';
import ClearScripts from '@mummssoftware/clearscripts';
import CarePlanFrequency from '@mummssoftware/care-plan-frequency';
import CarePlanProblems from '@mummssoftware/care-plan-problems';
import ScheduledVisits from '@mummssoftware/scheduled-visits';
import CertApp from '@mummssoftware/cert-app';
import Manifest from '@mummssoftware/manifest';
import Facesheet from '@mummssoftware/facesheet';
import Forms from '@mummssoftware/forms';
import Measures from '@mummssoftware/measures';
import HospiceItemSet from '@mummssoftware/hospice-item-set';
import ProgressNotes from '@mummssoftware/progressnotes';
import {
  ComponentMap,
  MapProps,
  GetCompProps,
  PrintConfigInterface,
  PrintWidgetConfigInterface,
} from '../Context/types';
import { DashboardPrintDiv } from '../../components/layout/WrapperDiv';
import { Context } from '../../context';
import { DashboardContext } from '../Context/DashboardContext';
import { ADD_WIDGET_READY } from '../Context/constants';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

type ContainerType = {
  count?: string; /// Passing Optional Props
};

const StyledDiv = styled.div<ContainerType>`
  --count: ${(props) => props.count};
  margin-top: calc(var(--count) + 5px);
`;
type DashboardPrintProps = {
  dashboardPrintConfig: PrintConfigInterface;
  printConfigNames: Record<mumms.ComponentName, string>;
};
type fwdRef = React.MutableRefObject<any> | React.Ref<any>;

export const DashboardPrintComponent = forwardRef(
  (
    { dashboardPrintConfig, printConfigNames }: DashboardPrintProps,
    ref: fwdRef,
  ) => {
    const { search } = useLocation();
    const useQuery = () => new URLSearchParams(search);
    const query = useQuery();
    const agency = query.get('agency');
    const mainPrintConfig = dashboardPrintConfig && {
      ...dashboardPrintConfig,
    };

    // fromDate & toDate interval needed
    const fromDate = dashboardPrintConfig && dashboardPrintConfig.fromDate;
    const toDate = dashboardPrintConfig && dashboardPrintConfig.toDate;
    const printDateConfig: any =
      fromDate && toDate
        ? {
            fromDate: formatDateMMDDYYYY(fromDate[0]),
            toDate: formatDateMMDDYYYY(toDate[0]),
          }
        : {};

    const printConfigState = Object.entries({
      ...dashboardPrintConfig,
    }).reduce((seed: any, printWidget: any) => {
      const name = printWidget[0];
      const config = printWidget[1];
      const printConfig =
        typeof config === 'boolean'
          ? printDateConfig
          : { ...printDateConfig, ...config };
      seed[name] = printConfig;
      return seed;
    }, {});

    // delete those 2 keys to get the generatePrintGrid array
    mainPrintConfig && delete mainPrintConfig?.fromDate;
    mainPrintConfig && delete mainPrintConfig?.toDate;
    const printWidgetConfig: PrintWidgetConfigInterface = mainPrintConfig && {
      ...mainPrintConfig,
    };

    // widgetConfig is array of componentName to generatePrintGrid
    const widgetConfig: string[] | null = printWidgetConfig
      ? Object.keys(printWidgetConfig).filter(
          (key) => (printWidgetConfig as any)[key],
        )
      : null;

    const { pim, keycloak, currentUser, patient } = useContext(Context);
    const { dispatch } = useContext(DashboardContext);
    const patientNumber = patient && patient.patientNumber;

    const getComponent = ({ name, title }: GetCompProps) =>
      Map[name] &&
      Map[name]({
        title,
      });

    /* generic widget callback implementation */
    const onWidgetReady = (widgetName: mumms.ComponentName) => {
      if (widgetName) {
        dispatch({
          type: ADD_WIDGET_READY,
          payload: widgetName,
        });
      }
    };

    const viewMode: mumms.viewMode = 'print';

    const Map: ComponentMap = {
      DocLink: () => (
        <CertApp
          agency={agency || undefined}
          keycloak={keycloak}
          patientNumber={patientNumber}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
          key={dashboardPrintConfig ? 'DocLink' : 'print'}
          printConfig={printConfigState.DocLink}
        />
      ),
      CarePlanFrequency: ({ title }: MapProps) => (
        <CarePlanFrequency
          appTitle={title}
          agency={agency || undefined}
          currentUser={currentUser}
          embedded
          keycloak={keycloak}
          online
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
          smallWidget
          view={undefined}
          key={dashboardPrintConfig ? 'CarePlanFrequency' : 'print'}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
          printConfig={printConfigState.CarePlanFrequency}
        />
      ),
      ScheduledVisits: ({ title }: MapProps) => (
        <ScheduledVisits
          embedded
          online
          appTitle={title}
          currentUser={currentUser}
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
          agency={agency}
          view={undefined}
          key={dashboardPrintConfig ? 'ScheduledVisits' : 'print'}
        />
      ),
      ClearScripts: ({ title }: MapProps) => (
        <ClearScripts
          appTitle={title}
          agency={agency || undefined}
          currentUser={currentUser}
          dashboardEmbedded
          embedded
          hideActionBar
          keycloak={keycloak}
          online
          patient={patient}
          patientNumber={patientNumber}
          key={dashboardPrintConfig ? 'ClearScripts' : 'print'}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
          printConfig={printConfigState.ClearScripts}
        />
      ),
      Facesheet: ({ title }: MapProps) => (
        <Facesheet
          appTitle={title as string}
          agency={agency}
          keycloak={keycloak}
          online
          patientNumber={patientNumber}
          key={dashboardPrintConfig ? 'Facesheet' : 'print'}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
        />
      ),
      Forms: ({ title }: MapProps) => (
        <Forms
          appTitle={title as string}
          agency={agency}
          currentUser={currentUser}
          dashboardEmbedded
          embedded
          keycloak={keycloak}
          patientNumber={patientNumber}
          patient={patient}
          pim={pim}
          key={dashboardPrintConfig ? 'Forms' : 'print'}
        />
      ),
      Manifest: ({ title }: MapProps) => (
        <Manifest
          appTitle={title as string}
          agency={agency}
          embedded
          keycloak={keycloak}
          patientNumber={patientNumber}
          currentUser={currentUser}
          patient={patient}
          key={dashboardPrintConfig ? 'Manifest' : 'print'}
          viewMode={viewMode}
          printConfig={printConfigState.Manifest}
          onWidgetReady={onWidgetReady}
        />
      ),
      Measures: ({ title }: MapProps) => (
        <Measures
          appTitle={title}
          agency={agency}
          currentUser={currentUser}
          embedded
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
          key={dashboardPrintConfig ? 'Measures' : 'print'}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
          printConfig={printConfigState.Measures}
        />
      ),
      HospiceItemSet: ({ title }: MapProps) => (
        <HospiceItemSet
          embedded
          online
          appTitle={title}
          agency={agency}
          currentUser={currentUser}
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          pim={pim}
        />
      ),
      ProgressNotes: ({ title }: MapProps) => (
        <ProgressNotes
          online
          agency={agency || undefined}
          embedded
          keycloak={keycloak}
          patientNumber={patientNumber}
          currentUser={currentUser}
          patient={patient}
          viewMode={viewMode}
          onWidgetReady={onWidgetReady}
          printConfig={printConfigState.ProgressNotes}
        />
      ),
      CarePlanProblems: ({ title }: MapProps) => (
        <CarePlanProblems
          agency={agency || undefined}
          currentUser={currentUser}
          embedded
          pim={pim}
          keycloak={keycloak}
          patient={patient}
          patientNumber={patientNumber}
          onWidgetReady={onWidgetReady}
          printConfig={printConfigState.ProgressNotes}
        />
      ),
    };

    const generatePrintGrid = (config: mumms.ComponentName[]) =>
      config.map((widgetName: mumms.ComponentName, i) => (
        <StyledDiv count={`${i}px`} id={widgetName} key={widgetName}>
          {getComponent({
            title: printConfigNames[widgetName],
            name: widgetName,
          })}
        </StyledDiv>
      ));

    return (
      <DashboardPrintDiv id="dashboard-print" ref={ref}>
        {widgetConfig &&
          generatePrintGrid(widgetConfig as mumms.ComponentName[])}
      </DashboardPrintDiv>
    );
  },
);
export default DashboardPrintComponent;
