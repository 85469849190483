import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const CertBodyGrid = ({
  children,
  className,
  gridProps = {},
  typographyProps = {},
  smallWidget,
  ...props
}) => (
  <Grid
    item
    xs={12}
    style={{ paddingBottom: '1rem' }}
    className={className}
    {...props}
    {...gridProps}
  >
    <Typography
      {...typographyProps}
      style={smallWidget ? { fontSize: '14px' } : {}}
    >
      {children}
    </Typography>
  </Grid>
);

export default CertBodyGrid;
