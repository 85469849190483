import Component from 'formiojs/components/_classes/component/Component';
import NestedComponent from 'formiojs/components/_classes/nested/NestedComponent';
import {
  isEmpty,
  isEqual,
  isNil,
  isObject,
  // throttle,
  // merge,
  unset,
  has as _has,
  get as _get,
  set as _set,
} from 'lodash-es';
import { Components } from '../Components';

type flags = Record<string, boolean>;
const restoreAndRedraw = (comp: any, child?: any) => () => {
  setTimeout(() => {
    const value = child?.getValue();
    comp.parent.restoreComponentsContext();
    comp.parent.onChange();
    if (child) {
      child.restored = true;
      child.setValue(value);
    } else {
      comp.parent.redraw();
    }
  }, 0);
};

export interface NestedInputComponent {
  refs: typeof NestedComponent.prototype.refs & {
    header?: HTMLElement;
  };
}
export class NestedInputComponent extends NestedComponent {
  _data: any;

  _dataValue: any;

  _type: string;

  constructor(
    component: ComponentJSON | typeof Component /* | Component */,
    options: Record<string, any>,
    data: any,
  ) {
    super(component, options, data);
    this.components = this.components || [];
    this._type = '';
  }

  init() {
    this.getContainers();
    super.init();
  }

  get type() {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  static schema(...extend: import('formiojs').ExtendedComponentSchema[]) {
    return NestedComponent.schema(
      {
        defaultValue: Object.freeze({ value: '' }),
      },
      ...extend,
    );
  }

  get key() {
    return _get(this.component, 'key', '');
  }

  set key(newKey: any) {
    this.component.key = newKey;
  }

  set dataValue(_value: any) {
    this._dataValue = this._dataValue || { ...this.defaultValue };
    let value = _value;
    if (value !== null && value !== undefined) {
      value = this.hook('setDataValue', value, this.key, this._data);
    }
    if (
      isObject(value) &&
      (Object.prototype.hasOwnProperty.call(value, 'value') ||
        Object.prototype.hasOwnProperty.call(value, 'value'))
    ) {
      this._dataValue = { ...this._dataValue, ...value };
    } else {
      this._dataValue.value = value;
    }
    _set(this._data, this.key, this.dataValue);
  }

  get dataValue() {
    this._dataValue = this._dataValue || { ...this.defaultValue };
    return this._dataValue;
  }

  get emptyValue() {
    return { ...(this._dataValue || {}), ...this.defaultValue };
  }

  hasChanged(newValue: value, oldValue: value) {
    let localOldValue;
    if (
      isObject(oldValue) &&
      Object.prototype.hasOwnProperty.call(oldValue, 'value')
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      localOldValue = oldValue.value;
    } else {
      localOldValue = oldValue;
    }
    // If we do not have a value and are getting set to anything other than undefined or null, then we changed.
    if (newValue !== undefined && newValue !== null && !this.hasValue()) {
      return true;
    }
    return !isEqual(newValue, localOldValue);
  }

  /** largely identical to base NestedComponent.createComponent -- adds parentValue key */
  createComponent(
    component: any,
    options: any = this.options,
    data: any = this.data,
    before?: any,
  ) {
    const comp: any = super.createComponent(component, options, data, before);
    if (component.parentValue) {
      comp.parentValue = component.parentValue;
    }

    comp.on('saveComponent', restoreAndRedraw(comp));
    comp.on('componentChange', (event?: Record<string, any>) => {
      const child = event?.instance;
      if (child && comp.hasComponent(child) && !child.restored) {
        comp.once('componentChange', restoreAndRedraw(comp, child));
      }
    });
    // comp.onAny((type: any, event: any) => console.log(type, event));
    return comp;
  }

  attach(element: any) {
    const superPromise = super.attach(element);
    const childPromises: any[] = [];
    // this.getContainers();
    if (Array.isArray(this.component.values)) {
      this.component.values.forEach(({ value }, index) => {
        const key = this.getValueKey(`${value}`);
        const { absolute: containerKey } = this.getContainerKeys(
          `${value}`,
          index,
        );
        const containerRefKey = `${containerKey}-container`;
        this.loadRefs(element, {
          header: 'single',
          collapsed: this.collapsed,
          [key]: 'single',
          [containerRefKey]: 'single',
        });
        const container = this.components.find(
          ({ key: compKey }) => compKey === containerKey,
        );
        if (container && this.refs[key]) {
          container.attach(this.refs[key]);
        }
      });
    }

    if (this.component.collapsible && this.refs.header) {
      this.addEventListener(this.refs.header, 'click', () => {
        this.collapsed = !this.collapsed;
      });
    }
    return Promise.all([superPromise, ...childPromises]);
  }

  getContainerKeys(value: string, index: number) {
    const relative = `${index}.${value}-container`;
    return { relative, absolute: `${this.nestedKey}.${relative}` };
  }

  getValueKey(value: string) {
    return `${this.nestedKey}-${value}`;
  }

  getContainerData(path: string) {
    if (_has(this.dataValue, path)) {
      return _get(this.dataValue, path);
    }

    _set(this.dataValue, path, {});
    return _get(this.dataValue, path);
  }

  getContainers() {
    this.components = this.components || [];
    if (
      Array.isArray(this.component.values) &&
      !(this.root && this.root.changing)
    ) {
      this.component.values.forEach(({ value }, index) => {
        if (value) {
          const { absolute } = this.getContainerKeys(`${value}`, index);
          if (
            !this.componentComponents.find(
              ({ key: compKey }) => compKey === absolute,
            )
          ) {
            const containerSchema = Components.components.container.schema({
              key: absolute,
              parentValue: value,
              allowData: true,
            });
            this.componentComponents.push(containerSchema);
          }
        }
      });
      this.component.components = this.componentComponents.filter(
        ({ key, parentValue }) =>
          this.component.values?.find(({ value }, index) => {
            const { absolute } = this.getContainerKeys(`${value}`, index);
            return key === absolute && value === parentValue;
          }),
      );
    }
  }

  componentContext(_comp?: any) {
    return this.dataValue;
  }

  unset() {
    unset(this._data, `${this.key}.value`);
  }

  setValue(value: value, flags: flags = {}) {
    let changed = false;
    const hasValue = this.hasValue();
    if (hasValue && isEmpty(this.dataValue)) {
      flags.noValidate = true;
    }
    if (!value || !isObject(value) || !hasValue) {
      changed = true;
      this.dataValue = this.defaultValue;
    } else {
      changed = this.hasChanged(value, this.dataValue);
      this.dataValue = value;
    }

    Component.prototype.setValue.call(this, value, flags);
    super.setValue(value, flags);
    this.updateOnChange(flags, changed);
    return changed;
  }

  get allowData() {
    return true;
  }

  getValueAsString(_value?: value) {
    if (isObject(this.dataValue) || Array.isArray(this.dataValue)) {
      return JSON.stringify(this.dataValue);
    } else if (isNil(this.dataValue)) {
      return this.dataValue;
    }
    return this.dataValue.toString();
  }

  /**
   * Get the value of this component.
   *
   * @returns {*}
   */
  getValue() {
    return this.dataValue;
  }

  resetValue() {
    this.unset();
    this.setPristine(true);
  }

  resetChildValues() {
    this.getComponents().forEach((comp) => comp.resetValue());
    this.resetValue();
  }

  updateValue(value: value, flags: flags) {
    // todo: investigate, see Formio NestedComponent setNestedValue
    if (this.options.readOnly && flags.resetValue) {
      flags.resetValue = false;
    }
    return this.components.reduce(
      (changed, comp) =>
        comp.updateValue(null, { ...flags, resetValue: false }) || changed,
      Component.prototype.updateValue.call(this, value, flags),
    );
  }

  setNestedValue(component: any, value: value, flags: flags, changed: boolean) {
    // eslint-disable-next-line
    component._data = this.componentContext(component);
    return super.setNestedValue(component, value, flags, changed);
  }

  render(children: any) {
    // If already rendering, don't re-render.
    return super.render(
      children ||
        this.renderTemplate(this.templateName, {
          children: this.renderComponents(),
          nestedKey: this.nestedKey,
          collapsed: this.collapsed,
        }),
    );
  }

  renderComponents(components: any[] = this.components) {
    if (this.options.readOnly) {
      this.restoreComponentsContext();
    }
    const children = components.map((component: any) => [
      component.parentValue,
      component.render(),
    ]);
    return children;
  }
}

export default NestedInputComponent;
