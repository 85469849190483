import React, { PureComponent, Fragment } from 'react';
import { Backdrop } from '@material-ui/core';
import { Loader } from '@mummssoftware/common-ui';

class TimedLoader extends PureComponent {
  constructor(props) {
    super(props);
    this.enableLoader = this.enableLoader.bind(this);

    this.state = {
      displayLoader: false,
    };

    this.timer = setTimeout(this.enableLoader, props.delay ? props.delay : 250);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableLoader() {
    this.setState({ displayLoader: true });
  }

  render() {
    const { displayLoader } = this.state;
    const { children } = this.props;

    if (!displayLoader) {
      return null;
    }

    return (
      <Fragment>
        <Loader style={{ zIndex: 2000 }}>{children}</Loader>
        <Backdrop open style={{ zIndex: 1000 }} />{' '}
      </Fragment>
    );
  }
}

export default TimedLoader;
