import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoteListWrapper = styled.div<{
  smallWidgetHeight?: number;
  fullSizeHeight?: number;
  smallWidget?: boolean;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `height: ${props.smallWidgetHeight}px;`
      : `height: ${props.fullSizeHeight}px;`}
  ${(props: any) =>
    props.fullSizeHeight
      ? `max-height: ${props.fullSizeHeight}px;`
      : `;`}  
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.palette.background.paper};
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  min-height: 279px;
  position: relative;
  overflow: auto;
`;

export const TextAreaWrapper = styled.div<{
  smallWidget?: boolean;
}>`
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.notes};
  background-color: ${(props) => props.theme.palette.background.paper};
  border-color: ${(props) => props.theme.palette.common.black};
  margin-top: 5px;
  height: 90%;
  overflow: auto;
  overscroll-behavior: contain;
  width: 100%;
  ${(props: any) => (props.smallWidget ? `height: 70%;` : `height: 85%;`)}
`;

export const TextArea = styled.textarea`
  height: 62px;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.common.white};
  width: 98%;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
`;
