import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import Request from './Request';
export class Workflow extends Request {
  constructor({
    agency: _agency,
    url,
    keycloak,
    signal
  }) {
    super({
      url,
      signal
    });

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "getPatientHIS", async (patientnumber, agency) => {
      const response = await this.get({
        api: `/his/hispatientadmin/patient/${patientnumber}`,
        qs: {
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "postHISData", async (event, agency, method) => {
      const response = await this.post({
        api: `/his/hisadminevent`,
        data: event,
        method: method || 'POST',
        qs: {
          agency
        }
      });
      return response;
    });

    this.url = new URL(url);
    this.keycloak = keycloak;

    if (_agency) {
      this.url.searchParams.append('agency', _agency);
    }

    Object.defineProperty(this.commonHeaders, 'Authorization', {
      get() {
        const {
          token
        } = keycloak;
        return `Bearer ${token}`;
      }

    });
  }

}
export default Workflow;