import React from 'react';
import ReactDOM from 'react-dom';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { KeycloakInitializer } from '@mummssoftware/utils';
import { DEFAULT_LOCALE } from './constants';
import { kcConfig } from './security';
import { setTokens, nativeInitSettings } from './native';
import Dashboard from './dashboard';
import defaults from './widgetconfig.json';
const config = defaults;

KeycloakInitializer.keycloak = kcConfig;
const { keycloak } = KeycloakInitializer;

if (typeof process.env.REACT_APP_SENTRY !== 'undefined' && window.Raven) {
  window.Raven.config(process.env.REACT_APP_SENTRY).install();
}

const { StatusBar } = Plugins;
const logout = async () => {
  /** For any mobile apps, harmless on the web */
  await StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(() => null);
  keycloak.logout({ redirectUri: `${process.env.REACT_APP_LOGOUTURL}` });
};

const timeout = async () => {
  await StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(() => null);
  keycloak.logout({ redirectUri: `${process.env.REACT_APP_TIMEOUTURL}` });
};

StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(() => null);

keycloak
  .init({
    onLoad: 'login-required',
    // TODO: Alt native mobile check...?
    ...(window.cordova ? nativeInitSettings() : {}),
  })
  .success(async (authenticated) => {
    if (authenticated) {
      // Will only succeed on native
      await StatusBar.setStyle({ style: StatusBarStyle.Dark })
        .then(() => setTokens(keycloak))
        .catch(() => null);
      // Returns promise, not implemented in web view so we can ignore
      const queryParams = new URLSearchParams(window.location.search);
      const theme = queryParams.get('theme');
      const agency = queryParams.get('agency');
      const locale = queryParams.get('locale') || DEFAULT_LOCALE;
      const pimBaseurl = `${process.env.REACT_APP_PIM_BASEURL}`;
      const pimTTLEndpoint = `${process.env.REACT_APP_PIM_CLIENTTTL_ENDPOINT}`;

      const initializeSessionManagement = () =>
        window.sessionManagement.initializeSessionManagement({
          keycloak,
          sessionUnifierUrl: `${process.env.REACT_APP_SESSION_BASE_URL}/session/`,
          pimTTLUrl: `${pimBaseurl}${pimTTLEndpoint}`,
          logoutFunction: timeout,
        });
      if (window.sessionManagement) {
        initializeSessionManagement();
      } else {
        document.addEventListener(
          'DOMContentLoaded',
          initializeSessionManagement,
        );
      }

      // change the name of the component being rendered to your component you imported above
      ReactDOM.render(
        <Dashboard
          embedded
          agency={agency}
          locale={locale}
          keycloak={keycloak}
          logout={logout} // bind custom logout functionality
          theme={theme}
          config={config}
        />,
        document.querySelector('#root'),
      );
    } else {
      // this shouldn't occur, but if it does...
      logout();
    }
  });
