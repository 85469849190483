import React from 'react';
import { WithStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
interface ProgressBodyProps extends WithStyles {
  contained?: boolean;
  windowHeight?: number;
}
const zIndex = 1000;

const Spinner = styled.div`
  margin: 100px auto 0;
  width: 530px;
  text-align: center;
  && > div {
    ${(props) => props.theme.breakpoints.down('xs')} {
      width: 90px;
      height: 90px;   
  }
    width: 150px;
    height: 150px;
${(props) =>
  `background-color: ${props.theme.palette.dashboard.themeLoader};`}    
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  && .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  && .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

const useStyles = makeStyles(() => ({
  fixedLoaderRoot: () => ({
    position: 'fixed',
    display: 'flex',
    margin: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: 0,
    zIndex,
  }),
  progressRoot: {
    color: '#ffa500',
  },
}));

const ProgressBody: React.FunctionComponent<ProgressBodyProps> = ({
  children,
  contained,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classes.fixedLoaderRoot}>
      <Spinner className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </Spinner>
    </div>
  );
};

export const ThemeLoader = (props: any) => <ProgressBody {...props} />;

export default ThemeLoader;
