/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs as BaseTabs, Tab as BaseTab } from '@material-ui/core';
import { FormContext } from '../Form';
import { Container } from '../../components';

declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
  formName: string;
  submissionId?: string;
  0?: string;
}>;

type IdgTabsProps = {
  Form: React.ReactNode;
  Changes?: React.ReactNode;
  Scores?: React.ReactNode;
  formName: string;
  readOnly: boolean;
  dashboardEmbedded?: boolean;
} & typeof RouteProps;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const FormSelector = lazy(() => import('../Form/FormSelector'));

const Tabs = styled(BaseTabs)`
  &.MuiTabs-root {
    align-self: baseline;
    min-height: 0;
  }
`;
const Tab = styled(BaseTab)`
  &.MuiButtonBase-root {
    outline: none;
    min-height: 36px;
  }
`;
const ToolbarWrapper = styled.div<{ formName: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.formName
      ? 'justify-content: space-between;'
      : 'justify-content: flex-end;'}
`;

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {children}
    </div>
  );
}

function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IdgTabs = (props: IdgTabsProps) => {
  const {
    match: { params },
    Form,
    Changes,
    formName,
    readOnly,
    dashboardEmbedded,
  } = props;
  const { errorState } = useContext(FormContext);
  const [value, setValue] = useState(params[0] || 'form');
  const history = useHistory();
  function handleChange(
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string,
  ) {
    setValue(newValue);
    !dashboardEmbedded &&
      history.replace(
        `/${params.patientNumber}/${newValue}/${params.submissionId || ''}${
          history.location.search
        }`,
      );
  }

  return (
    <div>
      <Container noPad>
        <ToolbarWrapper formName={false}>
          {/* <ToolbarWrapper formName={formName.slice(0, 3) === 'idg'}> */}
          {/* NP-9689 LCD Recent Changes display overlaps- quick fix is to hide
          for 1.6 prod release */}
          {/* {((formName === 'idg-lcd' && !readOnly) ||
            (formName === 'idg-pgba' && !readOnly)) && (
            <Tabs
              textColor="primary"
              value={value}
              onChange={handleChange}
              aria-label="View"
            >
              <Tab label="Form" value="form" {...a11yProps('form')} />
              <Tab
                label="Recent Changes"
                value="changes"
                {...a11yProps('changes')}
              />
            </Tabs>
          )} */}
          {!errorState && !readOnly && (
            <Suspense fallback={null}>
              <FormSelector />
            </Suspense>
          )}
        </ToolbarWrapper>
      </Container>
      <TabPanel value={value} index="form">
        {Form}
      </TabPanel>
      <TabPanel value={value} index="changes">
        {Changes}
      </TabPanel>
    </div>
  );
};

export default IdgTabs;
