import { css } from 'styled-components';
import { commonGlobal, flatPickrStyles, isMobile } from './common';
/**
 * Font Colors
 *  Widget Header Font: #6f809f
 *  Widget Chart Header Font: #8899bb
 *  Read Only: #505a72
 *  Editable/Current: #f39800
 *  Required/Late Font: #ff0000
 *  Hummingbird Logo Color: #3e5890
 *  Active Widget Nav Dock Title Font: #96a6c2
 *  Inactive Widget Nav Dock Title Font: #3e5890
 *  Drawers Title Font: #9aa6b9
 *  Bottom Nav Drawer Title Font: #3e5890

 * BG Colors
 *  Dance Floor BG: #bdcbe3
 *  Widget BG: #e5efff
 *  Widget Header/Drawer/Tool bar BG: #ffffff
 *  Very Bottom BG: #e5efff
 *  Widget Nav Dock BG: #FFFFFF
 *  Active Module BG color: #bdcbe3
 *  Inactive Module BG color: #FFFFFF
 *  Active Module Icon Color: #3e5890
 *  Inactive Module Icon Color: #96a6c2
 *  Bottom Secondary Nav BG:  #bdcbe3
 */

export default {
  palette: {
    type: 'light',
    primary: {
      main: '#355694'
    },
    // ag grid header and icons
    error: {
      main: '#da0000'
    },
    grid: {
      fontColor: '#090909',
      headerFontColor: '#355694',
      // medication headers
      accentBorderColor: '#adb8cc',
      headerBackground: '#e5efff',
      headerBorderColor: '#adb8cc',
      rowBorderColor: '#b3bdd2',
      rowHoverBackground: '#bacbe7'
    },
    graph: {
      border: '#000000',
      graphColor: '#FF6A00',
      secondGraphColor: '#00cccc'
    },
    scores: {
      color: '#355694'
    },
    background: {
      default: '#405881',
      // this is background color
      paper: '#e5efff',
      // this is even row color and other
      header: '#ffffff',
      oddrow: '#ccdfff',
      // this is odd row color
      dark: '#ccdfff',
      dark1: '#D6E5FF',
      dark2: '#DEEAFF',
      dark3: '#E5EEFF'
    },
    facesheet: {
      textColor: '#000000'
    },
    dashboard: {
      themeLoader: 'rgba(0, 0, 0, 0.8)'
    },
    text: {
      primary: '#253b62',
      // widget titles and others
      secondary: '#FF6A00',
      hint: '#505a72',
      title: '#3a4d6f',
      notes: '#000000'
    },
    divider: '#ced7ea'
  },
  typography: {
    fontSize: 16
  },
  props: {
    grid: {
      headerHeight: 27,
      rowHeight: 27
    },
    header: {
      background: '#dce9ff',
      rightTitle: {
        fontSize: 18,
        fontWeight: 'initial'
      },
      leftTitle: {
        fontSize: 19,
        fontWeight: 100
      },
      headerHeight: '40px'
    },
    iconStroke: '#badeff'
  },
  global: css(["html{font-size:14px;}html body{-webkit-font-smoothing:auto;}", " ", " ", ""],
  /* eslint-disable indent */
  // eslint-disable-line
  isMobile ? '/* mobile scrollbars are more compact, use default */' : css(["::-webkit-scrollbar{width:14px;height:14px;}::-webkit-scrollbar-track,::-webkit-scrollbar-corner{background:transparent;opacity:0.4;}::-webkit-scrollbar-thumb{background:rgb(144,144,144);border:3px solid rgba(0,0,0,0);background-clip:padding-box;border-radius:6px;}"]), flatPickrStyles(), commonGlobal)
};