export default `
.form-group > .formio-component-form > .formio-form {
  min-height: 40px;
}

.discrete.form-group > .formio-component-form > .formio-form {
  min-height: 0px;
}

.form-nested-data,
.form-check + div[class^='formio-component-nested-'],
.form-check + div[class*=' formio-component-nested-'] {
  margin: 0 0 0 1.25em;
}


.form-nested-instructions,
.form-check + div[class^='formio-component-nested-'],
.form-check + div[class*=' formio-component-nested-'] {
  margin: 0 0 0 3.25em;
}

.form-nested-data > div > div:not(.empty) {
  padding-top: calc(0.375rem + 1px);
}

.form-nested-cancer-data > div > div:not(.empty) {
  margin-left: 5rem;
}

.formio-component-form .card-title > span,
.formio-component-form .col-form-label > span,
.formio .card-title > span,
.formio .col-form-label > span {
  white-space: pre-line;
}

.formio-component-form .choices__list--single .choices__item,
.formio .choices__list--single .choices__item {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  width: calc(100% - 32px);
}

.formio-component-form .choices__list--dropdown,
.formio-component-form .choices[data-type*='select-one'] .choices__input,
.formio .choices__list--dropdown,
.formio .choices[data-type*='select-one'] .choices__input {
  background: var(--palette-background-paper);
  border-color: var(--palette-divider);
  word-break: normal;
}

.formio-component-form
  .choices__list--dropdown
  .choices__item--selectable.is-highlighted,
.formio .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: var(--palette-action-hover);
}

.formio-component-form .is-open .choices__list--dropdown,
.formio .is-open .choices__list--dropdown {
  border-color: var(--palette-divider);
}

.formio-component-form .choices[data-type*='select-one'].is-open:after {
  border-color: transparent transparent var(--mumms-primary);
}

.formio-component-form .choices[data-type*='select-one']:after {
  border-color: var(--mumms-primary) transparent transparent;
}

.formio-component-radio.rtl-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form-nested-container.indent-children > div,
.formio-component-radio.indent-children > div,
.formio-component-expandedselectboxes.indent-children > div,
.formio-component-container.indent-children > div,
.formio-component-selectboxes.indent-children > .form-radio {
  margin-left: 1.25rem;
}

ol.form-radio {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

[class^='formio-child-list-'] > .form-radio > .form-check,
[class*=' formio-child-list-'] > .form-radio > .form-check,
[class^='formio-list-'],
[class*=' formio-list-'] {
  display: list-item;
  color: var(--mumms-text-hint);
}

[class^='formio-child-list-'] > .form-radio > .form-check,
[class*=' formio-child-list-'] > .form-radio > .form-check,
[class^='formio-list-'],
[class*=' formio-list-'] {
  padding-left: 2rem;
  margin-left: 2rem;
}

.form-group[class^='formio-list-'],
.form-group[class*=' formio-list-'] {
  margin-bottom: 0.5rem;
}

[class^='formio-child-list-'] > .form-radio > .form-check,
[class*=' formio-child-list-'] > .form-radio > .form-check,
.form-check[class^='formio-list-'],
.form-check[class*=' formio-list-'] {
  padding-left: 3rem;
}

.formio-hidden[class^='formio-list-'],
.formio-hidden[class*=' formio-list-'] {
  display: none;
}

.discrete > .card > .card-body > .formio-component[class^='formio-list-'],
.discrete > .card > .card-body > .formio-component[class*=' formio-list-'] {
  padding-left: 4px;
  margin-left: -4px;
}

[class^='formio-child-list-'] > .form-radio > .form-check + .form-nested-data,
[class*=' formio-child-list-'] > .form-radio > .form-check + .form-nested-data,
.form-check[class^='formio-list-'] + .form-nested-data,
.form-check[class*=' formio-list-'] + .form-nested-data {
  padding-left: 3.75rem;
}

.form-check[class^='formio-list-'] > *,
.form-check[class*=' formio-list-'] > *,
[class^='formio-child-list-'] > .form-radio > .form-check > *,
[class*=' formio-child-list-'] > .form-radio > .form-check > *,
[class^='formio-list-'] > label.col-form-label,
[class*='formio-list-'] > label.col-form-label,
[class^='formio-list-'] > div.form-check,
[class*=' formio-list-'] > div.form-check {
  display: flex;
}

[class^='formio-child-list-']
  > .form-radio
  > .form-check
  > *:not(.invalid-feedback),
[class*=' formio-child-list-']
  > .form-radio
  > .form-check
  > *:not(.invalid-feedback),
[class^='formio-list-'] > *:not(.invalid-feedback),
[class*=' formio-list-'] > *:not(.invalid-feedback) {
  color: var(--mumms-text-primary);
}

.formio-child-list-numeric > .form-radio > .form-check,
.formio-list-numeric {
  list-style: decimal;
}

.formio-child-list-lower-alpha > .form-radio > .form-check,
.formio-list-lower-alpha {
  list-style: lower-alpha;
}

.formio-child-list-upper-alpha > .form-radio > .form-check,
.formio-list-upper-alpha {
  list-style: upper-alpha;
}

.big-tooltip > div > label > .tooltip > .tooltip-inner {
  max-width: 275px;
  text-align: left;
}

p.tooltip-inner > br {
  line-height: 1.35rem;
}

div.tooltip-inner > br {
  display: block;
  content: ' ';
  height: 0.35rem;
}

.rtl-input > .checkbox {
  padding-left: 0;
  margin-right: 1.25rem;
}

.rtl-input > .checkbox > label {
  display: flex;
  flex-direction: row-reverse;
}

.rtl-input > .checkbox > label > span {
  margin-right: 20px;
}

.rtl-input > .checkbox > label > input[type='checkbox'] {
  margin-left: 0px;
}

.formio-errors .error {
  font-weight: 600;
}

.row.uncontained {
  margin: 0 0 12px;
}

.formio-component.emphasize > label,
.formio-component.emphasize > *:first-child > label > span {
  font-size: 1.14rem;
  line-height: 1.3rem;
}

.alpha-label.form-group,
.formio-component-container.discrete {
  margin-bottom: 0;
}

.formio-component-container.discrete > div > .field-label > label {
  padding: 0;
}

.formio-component-container.alpha-label > div > .field-label {
  color: var(--mumms-text-hint) !important;
  flex-basis: 20px !important;
  margin-right: 2rem !important;
}

.formio-component-panel > div > .card-header .formio-collapse-icon.text-muted {
  color: var(--mumms-primary) !important;
}

.formio-component-panel.primary > .card > .card-header > span > span {
  padding: 0 8px;
  position: relative;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.6;
  margin: 0;
}

.formio-component-panel.primary > .card.border {
  border: 3px solid var(--mumms-header) !important;
  background-color: var(--mumms-header);
}

.formio-component-panel.primary > div > .card-header .formio-collapse-icon {
  font-size: 1.2rem;
  margin: 0px 0px 0 8px;
}

.formio-component-panel.primary > .card.border > .card-body {
  background-color: var(--mumms-paper);
  border-radius: 0 0 3px 3px;
}

.formio-component-panel.discrete > div {
  border: none !important;
}

.formio-component-panel.discrete > div > .card-header {
  /* color: var(--mumms-text-secondary) !important; */
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}

.formio-component-panel.discrete > div > .card-header .formio-collapse-icon {
  position: absolute;
  left: -20px;
  line-height: unset;
}

.formio-component-panel.discrete
  > div
  > .card-header
  .formio-collapse-icon::before {
  vertical-align: middle;
}

.formio-component-panel.discrete > div > .card-body {
  padding: 0;
}

.formio.builder .drag-container {
  padding: 10px;
}

.formio.builder .drag-container:hover {
  cursor: move;
  border: dotted 2px #ccc;
  margin: -2px;
}

.formio-dialog.formio-dialog-theme-default.component-settings
  .formio-dialog-content.formio-dialog-content {
  background: var(--palette-text-constrast-primary-paper);
}

.formio-dialog .component-edit-container {
  width: calc(100% - 5px);
}

.formio .component-btn-group {
  display: flex;
  justify-content: flex-end;
}

.formio .checkbox-panel > div.card > div.card-body > .formio-component {
  margin: 0px;
}

.read-only .formio-component-expandedselectboxes.indent-children > div {
  margin-left: 0;
}

.read-only .formio-form label {
  display: inline-flex;
}

.read-only input[disabled].fa {
  -webkit-appearance: none;
  color: var(--mumms-primary);
}

.read-only .formio-form div[ref='value'] {
  color: var(--mumms-text-secondary);
  margin-left: 1.25rem;
}

.read-only .form-check span {
  color: var(--mumms-text-secondary);
}

.formio
  .checkbox-panel
  > div.card
  > div.card-body
  > .formio-component
  > .formio-component {
  margin: 0px;
}

.formio
  .checkbox-panel
  > div.card
  > div.card-body
  > .formio-component
  > .formio-component
  > div {
  margin-bottom: 1rem;
}

@media print {
  .formio-form {
    color: #444444;
  }

  .field-required:after {
    display: none;
  }

  .formio-form .card-header {
    color: black;
    background-color: #dddddd;
    border-bottom: none;
  }

  .formio-component-panel.primary > .card.border > .card-body {
    background-color: transparent;
  }

  .formio-component-panel.primary > .card.border {
    border: 1px solid #dddddd !important;
    background-color: transparent;
  }

  .formio-form .card {
    background-color: transparent;
  }

  .formio-form .fa {
    display: none;
  }

  :not(.formio-form) > .form-group.formio-component {
      break-inside: avoid-page;
  }

  .read-only .formio-form div[ref='value'] {
    color: black;
  }

  [class^='formio-child-list-']
    > .form-radio
    > .form-check
    > *:not(.invalid-feedback),
  [class*=' formio-child-list-']
    > .form-radio
    > .form-check
    > *:not(.invalid-feedback),
  [class^='formio-list-'] > *:not(.invalid-feedback),
  [class*=' formio-list-'] > *:not(.invalid-feedback) {
    color: #444444;
  }
}
`;
