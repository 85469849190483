import { useLayoutEffect, useState, useCallback } from 'react';
export const useResizeObserver = ref => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0
  });
  const handleResize = useCallback(entries => {
    if (!Array.isArray(entries)) {
      return;
    }

    const entry = entries[0];
    if (entry.contentRect.width !== dimensions.width || entry.contentRect.height !== dimensions.height) setDimensions({
      width: entry.contentRect.width,
      height: entry.contentRect.height
    });
  }, [dimensions]);
  useLayoutEffect(() => {
    if (!ref.current) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    let RO;

    if (typeof ResizeObserver !== 'undefined') {
      RO = new ResizeObserver(handleResize);
      RO.observe(ref.current);
    } else if (ref.current.clientWidth !== dimensions.width || ref.current.clientHeight !== dimensions.height) {
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      });
    }

    return () => {
      if (RO) {
        RO.disconnect();
      }
    };
  }, [ref, handleResize, dimensions]);
  return dimensions;
};
export const ResizeProvider = ({
  children,
  passedRef
}) => children(useResizeObserver(passedRef));