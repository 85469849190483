import React, { Fragment } from 'react';
import { Loader, ThemeProvider } from '@mummssoftware/common-ui';
import { Pim, shallowEqual, Observation } from '@mummssoftware/utils';
import { isSmallTouchDevice } from '@mummssoftware/utils/web';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import PouchDB from 'pouchdb-browser';
import PouchDBUpsert from 'pouchdb-upsert';
import MemDownPouch from 'pouchdb-adapter-memory';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import type Webform from 'formiojs/Webform';
import {
  ReactFormio,
  Formio,
  Components,
  components,
  Templates,
  templates,
} from './utils/internal';
import {
  allFormsAction,
  getCurrentFormioUser,
  setParseMissingKeyHandler,
  attachRippleEffect,
  validateSubmissionPatient,
} from './utils';
import { formatTranslationMessages, DEFAULT_LOCALE } from './i18n';
import initHooks from './hooks';
import globalStyles from './globalStyles';

import './style/bootstrap.css';
import 'formiojs/dist/formio.form.css';
import type { FormsExtendedPatient } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
type hooks = Record<string, Function | Record<string, Function>>;
type submission = import('formiojs').submission;
type getSubmission = typeof import('react-formio').getSubmission;
type resetSubmission = typeof import('react-formio').resetSubmission;
/**
 * These props can be provided by a redux store, or passed in to the un-connected component.
 */
export type ConnectedFormProps = {
  getSubmission: (args: {
    name: string;
    id: string;
    formId?: string;
    done?: () => void;
  }) => void | getSubmission | import('redux').AnyAction;
  resetSubmission: (
    name: string,
  ) => void | resetSubmission | import('redux').AnyAction;
  submission?: submission;
};

/**
 * Non-redux props.
 */
export type FormProps = {
  /** Current mumms user. Providing this will keep the Form from hitting the current info endpoint. */
  currentUser?: mumms.User;
  /** formio form id */
  formId?: string;
  /** Formio form name. currently defaults to the ngs-idg form  */
  formName: string;
  /** Keycloak instance, for use with Pim calls */
  keycloak: Keycloak.KeycloakInstance;
  /** Any additional metadata you wish to add to the form */
  additionalMetadata?: { [k: string]: string | number | undefined | null };
  onComponentChange?: import('react-formio').FormProps['onComponentChange'];
  /** Once submit completes, allows access to final submission object */
  onSubmitDone?: import('react-formio').FormProps['onSubmitDone'];
  /** HB patientNumber */
  patientNumber?: string;
  /** patient Object */
  patient?: FormsExtendedPatient;
  /** formio submission id */
  submissionId?: string;
  /** set `true` for debugging i18n, form load, change events, etc */
  debug?: boolean | number;
  /** theme to use for the form */
  theme?: string;
  readOnly?: boolean;
  /** whether to enable readOnly on submit/loading a submission */
  readOnlyOnSubmission?: boolean;
  hooks?: hooks;
  /** form object prop; will disable default submission functionality */
  form?: mumms.JSONObject;
  /** Additional i18next fallback namespaces */
  i18nFallbackNS?: string | string[];
  /** Will deprecate, just putting offline plugin behind a flag for now */
  useOfflinePlugin?: boolean;
  locale?: keyof mumms.translations;
  agency?: string | null;
  translations?: mumms.translations;
  onNewForm?: (newForm: boolean) => void;
  allowCreationNewForm?: boolean;
};

/**
 * All Form props, connected and otherwise
 */
export type CombinedProps = FormProps & ConnectedFormProps;

interface FormState {
  /** The formio form name */
  formName: string;
  /**
   * Note that I haven't found a good way to make this immutable - formio does with it
   * what it will. Could clone it before passing it in, but that feels like a lot.
   */
  submission: submission;
  /** Current mumms User */
  currentUser: mumms.User | null;
  errorState?: Error;
  /**
   * Not sure this will come in to play, but the potential here for infinite loops is
   * certainly present
   */
  formioAttempts: number;
  formioUser?: any;
  readOnly: boolean;
  /* Whether the form is ready to display */
  ready: boolean;
  /* Which render mode to use. Note that switching requires a re-draw. */
  renderMode: import('formiojs').renderMode;
  hooks: hooks;
  storageKey: string;
}

PouchDB.plugin(PouchDBUpsert);
PouchDB.plugin(MemDownPouch);

const NO_WORKER = 'NO_WORKER';
const baseSubmission = {
  data: {},
  metadata: {},
};

const renderModes = {
  FORM: 'form',
  HTML: 'html',
};

const defaultFallbackNS = ['common'];
/**
 * The bare, unconnected Form wrapper.
 */
export class Form extends React.Component<CombinedProps, FormState> {
  formio: Formio;

  mounted?: boolean;

  static db: PouchDB.Database;

  renderModeChanged = false;

  fallbackNS = defaultFallbackNS;

  constructor(props: CombinedProps) {
    super(props);
    const hooks = {
      ...initHooks(props.patient),
      ...props.hooks,
    };
    // bind for potential usage in constructor
    this.addSubmissionMetadata = this.addSubmissionMetadata.bind(this);
    this.createStorageKey = this.createStorageKey.bind(this);
    this.addFallbackNamespace = this.addFallbackNamespace.bind(this);
    if (typeof process.env.REACT_APP_FORMIO_URL === 'string') {
      // eslint-disable-next-line
      // @ts-ignore
      Formio.use({
        options: {
          form: {
            // eslint-disable-next-line
            // @ts-ignore
            ...(Formio.options ? Formio.options.form : {}),
            hooks,
          },
        },
      });
      this.formio = new Formio(process.env.REACT_APP_FORMIO_URL, {
        hooks,
      });
    } else {
      throw new Error('Missing env variable: REACT_APP_FORMIO_URL');
    }
    if (props.i18nFallbackNS) {
      this.addFallbackNamespace(props.i18nFallbackNS);
    }
    // plugin to get observation/formsubmissioncdc endpoint working
    const AuthPlugin = {
      priority: 0,
      preRequest: (requestArgs: any) => {
        const { headers } = requestArgs.opts;
        headers.Authorization = `Bearer ${props.keycloak.token}`;
        return requestArgs;
      },
    };

    Formio.registerPlugin(AuthPlugin, 'auth');

    const submission: submission =
      props.submission &&
      props.submission.metadata &&
      validateSubmissionPatient(props.submission, props.patientNumber)
        ? props.submission
        : { ...baseSubmission };
    let currentUser: FormState['currentUser'] = null;
    let storageKey = '';
    this.translations = formatTranslationMessages(
      props.translations || {
        en: {},
        es: {},
      },
    );
    this.observation = new Observation({
      url: process.env.REACT_APP_OBSERVATION_URL as string,
      keycloak: props.keycloak,
      agency: props.agency,
    });
    if (props.currentUser) {
      // info is being passed in
      currentUser = props.currentUser;
      storageKey = this.createStorageKey({
        patientNumber: props.patientNumber,
        formName: props.formName,
        user: currentUser,
      });
      this.addSubmissionMetadata(
        submission.metadata,
        currentUser,
        props.patientNumber,
      );
      Form.initDB(currentUser);
    } else {
      /**
       * it's up to us to handle this stuff. Let's add a Pim handler, and update the
       * metadata in componentDidMount.
       */
      this.pim = new Pim({
        url: process.env.REACT_APP_PIM_BASEURL || '',
        keycloak: props.keycloak,
        token: props.keycloak.token || '',
        agency: props.agency,
      });
    }
    this.defaultNS = 'common';
    this.parseMissingKeyHandler = setParseMissingKeyHandler(
      this.translations[props.locale || DEFAULT_LOCALE],
      this.defaultNS,
    );
    // this makes sure we are getting our components and templates
    Components.setComponents(components);
    Templates.current = templates;
    this.state = {
      formName: props.formName || '',
      formioAttempts: 0,
      renderMode: props.readOnly ? renderModes.HTML : renderModes.FORM,
      readOnly: props.readOnly || false,
      ready: !props.submissionId,
      storageKey,
      hooks,
      submission,
      currentUser,
    };
  }

  shouldComponentUpdate(
    { submission: newSubmission, ...newProps }: ConnectedFormProps,
    newState: FormState,
  ) {
    const {
      props: { submission, ...props },
      state,
    } = this;

    return (
      !shallowEqual(submission, newSubmission) ||
      !shallowEqual(props, newProps) ||
      !shallowEqual(state, newState)
    );
  }

  defaultNS: 'common';

  pim?: Pim;

  observation: Observation;

  translations: mumms.translations;

  parseMissingKeyHandler: ReturnType<typeof setParseMissingKeyHandler>;

  addSubmissionMetadata = (
    metadata: submission['metadata'],
    user: mumms.User,
    patientNumber: string | undefined = this.props.patientNumber,
  ) => {
    if (user.isMumms) {
      metadata.workerId = null;
    } else {
      metadata.workerId = user.userId;
    }
    metadata.hospiceId = user.currentHospiceId;
    metadata.siteId = user.siteId;
    metadata.patientNumber = patientNumber;
    metadata.agency = this.props.agency;
    metadata.locale = this.props.locale;
    metadata.bin = user.currentHospiceBin;
    metadata.formName = this.props.formName;
    if (this.props.additionalMetadata) {
      Object.entries(this.props.additionalMetadata).forEach(([key, value]) => {
        metadata[key] = value;
      });
    }
    return metadata;
  };

  addFallbackNamespace = (fallbackNS: string | string[]) => {
    if (Array.isArray(fallbackNS)) {
      this.fallbackNS = [...this.fallbackNS, ...fallbackNS];
    } else {
      this.fallbackNS.push(fallbackNS);
    }
  };

  static getDerivedStateFromProps(props: CombinedProps, state: FormState) {
    if (
      typeof props.formName === 'string' &&
      props.formName !== state.formName
    ) {
      return {
        formName: props.formName,
      };
    }
    return null;
  }

  static initDB(user: mumms.User) {
    const isFormUrl = /form/i.test(window.location.pathname);
    const isDashboardUrl = /dashboard/i.test(window.location.pathname);
    const isSmallSizeTouchDevice: boolean = isSmallTouchDevice();
    // if inside hb (iframe situation) and ipad
    if (isSmallSizeTouchDevice && !isFormUrl && !isDashboardUrl) {
      // for mobile solution is to allows PouchDB to fall back to LocalStorage on browsers that don't support either IndexedDB or WebSQL.
      // problem is iframe Safari does not support IndexedDB and ioS13 and below (ipad 7th and below) does not support WebSQL (but ioS14 does)
      // need to use local storage
      // pouchdb-adapter-localstorage has been deprecated => PouchDB.plugin(LocalStoragePouch);
      // error is AssertionError: .status required, old abstract-leveldown => confirmed this means deprecated
      // so using memory when isMobile
      // This pouch will act exactly like a normal one – replicating, storing attachments, pagination, etc. – but it will be deleted as soon as the user closes their browser.
      // WHAT IT MEANS : when user closes their browser if the form has been submitted no problem
      // if the user starts a form but does not finish then it wont remember the work in progress
      // right now WIP only works in stand-alone forms
      this.db = new PouchDB(user.personNumber || NO_WORKER, {
        adapter: 'memory',
      });
    } else {
      this.db = new PouchDB(user.personNumber || NO_WORKER);
    }
  }

  createStorageKey = ({
    patientNumber = this.props.patientNumber,
    user = this.state.currentUser,
    formName = this.props.formName,
  }) =>
    `${formName}-${user ? user.personNumber : NO_WORKER}${
      patientNumber ? `-${patientNumber}` : ''
    }`;

  createNewForm = async () => {
    const submission = { ...baseSubmission };
    this.addSubmissionMetadata(
      submission.metadata,
      this.props.currentUser as mumms.User,
      this.props.patientNumber,
    );

    // this clears the previous submission
    this.props.resetSubmission(this.state.formName);

    this.getStoredSubmission(submission);
  };

  getStoredSubmission = async (
    submission: submission = this.state.submission,
  ) => {
    const { readOnly, storageKey } = this.state;
    if (this.props.allowCreationNewForm === true && readOnly) {
      this.setState(
        {
          readOnly: false,
          renderMode: renderModes.FORM,
        },
        () => {
          this.getStoredSubmission(submission);
        },
      );
      // todo: this keeps submitted submissions from persisting locally, is this always desired?
    } else if (!readOnly) {
      let shouldDelete = false;
      let newState = {};
      const newSub = await Form.db
        .putIfNotExists({ _id: storageKey, submission })
        .then((res) => {
          if (res.updated) {
            if (typeof this.props.onNewForm === 'function') {
              this.props.onNewForm(true);
            }
            if (this.props.allowCreationNewForm === false && this.mounted) {
              shouldDelete = true;
              newState = {
                readOnly: true,
                renderMode: renderModes.HTML,
              };
              return null;
            }
          }
          return Form.db.get(res.id);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          return { submission };
        });
      if (newSub && !shouldDelete) {
        newState = { submission: newSub.submission, ...newState };
      } else if (shouldDelete) {
        // we actually delete the stored submission and reset it to false
        this.deleteStoredSubmission();
      }
      if (this.mounted) {
        this.setState(newState);
      }
    }
  };

  storeSubmission = async () => {
    const { ready, readOnly, storageKey, submission } = this.state;
    if (ready && !readOnly) {
      /* eslint-disable no-console */
      return Form.db
        .upsert(storageKey, () => ({ submission }))
        .catch(console.error);
      /* eslint-enable */
    }
    return null;
  };

  debouncedStoreSubmission = debounce(this.storeSubmission, 500);

  deleteStoredSubmission = () => {
    const { storageKey } = this.state;
    /* eslint-disable no-console */
    return Form.db
      .upsert(storageKey, () => ({ _deleted: true }))
      .catch(console.error);
    /* eslint-enable */
  };

  /** For debugging */
  onAny = (...args: any) => {
    if (this.props.debug) {
      // eslint-disable-next-line no-console
      console.log(args);
    }
  };

  onFormLoad = (form: any) => {
    this.onAny(form, 'formLoad');
  };

  onInitialized = () => {
    this.onAny(
      'init',
      `mounted: ${this.mounted} renderChanged: ${this.renderModeChanged} and ready state: ${this.state.ready}`,
    );
    if (this.renderModeChanged) {
      this.renderModeChanged = false;
    } else if (this.mounted) {
      this.setState({ ready: true });
    }
  };

  onRender = () => {
    this.onAny('render');
    if (!Formio.getToken() && this.mounted) {
      this.setState(
        { formioUser: null },
        () =>
          this.state.formioAttempts < 3 &&
          this.getCurrentFormioUser().then((formioUser: any) => {
            this.setState(({ formioAttempts }) => ({
              formioAttempts: formioAttempts + 1,
              formioUser,
            }));
          }),
      );
    }

    // TODO: There are some occasional async shenanigans, here's a band-aid
    if (!this.state.ready) {
      setTimeout(() => {
        if (this.mounted && !this.state.ready) {
          this.setState({
            ready: true,
            /* eslint-disable indent */
            ...(this.props.debug
              ? {
                  errorState: new Error(
                    "Something maybe went wrong initializing the form?? I don't know. It looks fine.",
                  ),
                }
              : {}),
            /* eslint-enable */
          });
        }
      }, 2500);
    }
  };

  onComponentChange = (e: import('formiojs').componentChangeEvent) => {
    this.onAny(e, 'componentChange');
    if (this.props.onComponentChange) {
      this.props.onComponentChange(e);
    }
    if (this.state.submission._id) {
      this.redrawOnSetSubmission(this.state.renderMode);
    }
    if (e.flags.fromSubmission && this.state.readOnly) {
      this.redrawOnSetSubmission(this.state.renderMode, true);
    }
  };

  onChange = (e: any) => {
    this.onAny(e, 'change');
    this.debouncedStoreSubmission();
  };

  onSubmitDone = (submission: import('formiojs').completeSubmission) => {
    const { onSubmitDone } = this.props;
    this.setState(
      { readOnly: true, renderMode: renderModes.HTML },
      async () => {
        if (typeof onSubmitDone === 'function') {
          const submissionId = submission._id;
          const formName =
            this.props.formName === 'idg-lcd' ||
            this.props.formName === 'idg-pgba'
              ? 'idg'
              : this.props.formName;
          const url = this.props.agency
            ? `${this.props.patientNumber}/${formName}/${submissionId}?agency=${this.props.agency}&viewmode=readonly`
            : `${this.props.patientNumber}/${formName}/${submissionId}?viewmode=readonly`;
          const tz = moment.tz.guess();
          const date = `${formatPostDate(Date.now())}`;
          const submissionForm = {
            id: '',
            createdatetime: date,
            createpersonid: this.props.currentUser?.userId?.toString() as string,
            createpersonnumber: this.props.currentUser?.personNumber as string,
            formname: this.props.formName as string,
            manifesttype: 'Questionnaire',
            patientnumber: this.props.patientNumber as string,
            siteid: this.props.currentUser?.siteId.toString() as string,
            source: 'FORMIO',
            sourcekey: submissionId as string,
            timezone: tz as string,
            url: url as string,
            urltype: 'FORMIOSUBMISSIONID',
          };
          // TODO better way to handle form not saved to qfile, if error should not submit to Formio and indicate to user issue?
          // eslint-disable-next-line no-unused-expressions
          this.observation.saveManifest(submissionForm).catch((e: Error) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
          onSubmitDone(submission);
        }
        this.deleteStoredSubmission().then(this.disableForms);
      },
    );
  };

  getCurrentFormioUser = () => {
    const {
      keycloak: { token = '' },
      useOfflinePlugin,
    } = this.props;
    return getCurrentFormioUser(this.formio, token)
      .then((formioUser: any) => {
        if (useOfflinePlugin && formioUser) {
          import('./initOffline').then(({ registerServiceWorker }) => {
            registerServiceWorker(formioUser.data, token);
          });
        }
        this.checkSubmission();
        return formioUser;
      })
      .catch((e: Error) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  checkSubmission() {
    if (
      this.props.submissionId &&
      this.props.submissionId !== this.state.submission._id
    ) {
      this.props.getSubmission({
        name: this.state.formName,
        id: this.props.submissionId,
      });
    }
  }

  /**
   * Hopefully this is a temporary solution. Passing "readOnly" to the react formio
   * component after it's been mounted doesn't cause anything to update, and changing the
   * key on the component causes it to fully re-render.
   */
  disableForms = () =>
    allFormsAction((webform) => {
      webform.disabled = true;
    });

  redrawOnSetSubmission(renderMode: string, force?: boolean) {
    const createDebouncedRedraw = (webform: Webform) =>
      debounce(() => webform.redraw(), 50);
    return allFormsAction(
      (
        webform: Webform & {
          dbRedraw?: (webform: Webform) => void;
          changeTriggered?: boolean;
        },
      ) => {
        webform.dbRedraw = webform.dbRedraw || createDebouncedRedraw(webform);
        if (
          renderMode === renderModes.HTML &&
          webform.element &&
          !webform.element.classList.contains('read-only')
        ) {
          webform.element.classList.add('read-only');
        }
        if (force || !webform.changeTriggered) {
          webform.triggerChange();
          webform.changeTriggered = true;
          webform.once(
            'change',
            () => webform.dbRedraw && webform.dbRedraw(webform),
            false,
          );
        }
      },
    );
  }

  // TODO: Better name for this?
  setUpdatedSubmission = ({ submission: prevSubmission } = this.state) => {
    const { currentUser } = this.state;
    const { patientNumber, readOnlyOnSubmission, submission } = this.props;
    if (!this.renderModeChanged && this.mounted) {
      if (
        currentUser &&
        submission?._id &&
        submission._id !== prevSubmission._id &&
        validateSubmissionPatient(submission, patientNumber)
      ) {
        this.addSubmissionMetadata(submission.metadata, currentUser);
        this.setState(
          ({ readOnly: prevReadOnly, renderMode: prevRenderMode }) => {
            const readOnly = !!(prevReadOnly || readOnlyOnSubmission);
            const renderMode = readOnly ? renderModes.HTML : renderModes.FORM;
            this.renderModeChanged = renderMode !== prevRenderMode;
            return {
              submission,
              readOnly,
              renderMode,
              ready: false,
            };
          },
          this.storeSubmission,
        );
      }
    }
  };

  componentDidUpdate(
    { submissionId: prevSubmissionId }: CombinedProps,
    prevState: FormState,
  ) {
    const { submissionId } = this.props;
    if (prevSubmissionId !== submissionId) {
      if (submissionId === 'createNewForm') {
        this.createNewForm();
      } else {
        this.checkSubmission();
      }
    } else {
      this.setUpdatedSubmission(prevState);
    }
  }

  componentDidMount() {
    this.mounted = true;
    attachRippleEffect();
    const promises = [this.getCurrentFormioUser];
    if (!this.state.currentUser && this.pim instanceof Pim) {
      promises.push(this.pim.getUserInfo);
    }
    Promise.all(promises.map((task) => task()))
      .then(([formioUser, currentUser]) => {
        const newState = { formioUser };
        if (this.mounted) {
          if (currentUser) {
            this.setState(({ submission }) => {
              Form.initDB(currentUser);
              submission.metadata = this.addSubmissionMetadata(
                submission.metadata,
                currentUser,
              );
              return {
                ...newState,
                currentUser,
                storageKey: this.createStorageKey({
                  patientNumber: this.props.patientNumber,
                  formName: this.props.formName,
                  user: currentUser,
                }),
              };
            });
          } else {
            this.setState(newState);
          }
        }
        // undefined to getStoredSubmission to work
        return undefined;
      })
      .then(this.getStoredSubmission)
      .catch((err: Error) => {
        this.setState({ ready: true, errorState: err });
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      errorState,
      formioAttempts,
      formioUser,
      formName,
      hooks,
      readOnly,
      ready,
      renderMode,
      submission,
    } = this.state;
    const { debug, form, theme } = this.props;
    const noUser = !formioUser && formioAttempts < 3;
    const showLoader = !errorState && (noUser || !ready);
    if (!formName) {
      // eslint-disable-next-line no-console
      console.warn('No form name provided');
    }
    return (
      <ThemeProvider theme={theme} globalStyles={globalStyles}>
        <Fragment>
          {showLoader ? <Loader key={`loader-${formName}`} /> : null}
          {errorState && <div>{errorState.message}</div>}
          {!noUser ? (
            <div style={ready ? {} : { display: 'none' }}>
              <ReactFormio
                key={`${renderMode}-${submission._id}`}
                src={
                  // full=true loads all subforms right away
                  !form
                    ? `${process.env.REACT_APP_FORMIO_URL}/${formName}?full=true&live=true`
                    : undefined
                }
                form={form}
                formioform={(this.formio as any).Form}
                submission={submission}
                onSubmitDone={this.onSubmitDone}
                onRender={this.onRender}
                onComponentChange={this.onComponentChange}
                onFormLoad={this.onFormLoad}
                options={{
                  hooks,
                  readOnly,
                  renderMode,
                  i18n: {
                    defaultNS: this.defaultNS,
                    fallbackNS: this.fallbackNS,
                    parseMissingKeyHandler: this.parseMissingKeyHandler,
                    debug: typeof debug === 'number' && debug > 1,
                    resources: this.translations,
                  },
                }}
                onInitialized={this.onInitialized}
                onChange={this.onChange}
                onCustomEvent={(e: any) => {
                  this.onAny(e, 'customEvent');
                }}
              />
            </div>
          ) : null}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Form;
