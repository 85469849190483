import React from 'react';
import styled from 'styled-components';
import { Container as BaseMuiContainer } from '@material-ui/core';

type WrapProps = {
  'no-pad'?: boolean;
  'no-margin'?: boolean;
};

type ContainerProps = {
  children: Omit<React.ReactNode, 'undefined'>;
  className?: string;
  noPad?: boolean;
  noMargin?: boolean;
  id?: string;
};

const Wrap = styled.div<WrapProps>`
  ${(props) => (props['no-margin'] ? '' : 'margin: 0 20px 0 20px;')}
  overflow: visible;
  ${(props) => (props['no-pad'] ? '' : 'padding: 15px 0;')}
  ${(props) => props.theme.breakpoints.down('xs')} {
    width: calc(100vw - 20px);
    ${(props) => (props['no-margin'] ? '' : 'margin: 0 5px 0 5px;')}
  }
  @media print {
    margin: 0;
    width: 100%;
  }
`;

const MuiContainer = styled(BaseMuiContainer)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    && {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  @media print {
    padding: 0;
  }
`;

const MuiContainerNoPaddingMargin = styled.div`
  ${(props) => props.theme.breakpoints.down('xs')} {
    && {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  @media print {
    padding: 0;
  }
`;

export class Container extends React.PureComponent<ContainerProps> {
  render() {
    const { children, className, noPad, id, noMargin } = this.props;
    if (noPad && noMargin) {
      return (
        <Wrap id={id} no-pad={noPad} no-margin={noMargin} className={className}>
          <MuiContainerNoPaddingMargin>{children}</MuiContainerNoPaddingMargin>
        </Wrap>
      );
    }
    return (
      <Wrap id={id} no-pad={noPad} no-margin={noMargin} className={className}>
        <MuiContainer>{children}</MuiContainer>
      </Wrap>
    );
  }
}

export default Container;
