import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoInfo } from '../../components';
import { FormWrapper } from './FormWrapper';
type FormViewProps = {
  patientNumber: string;
  submissionId?: string;
  initCallback?: (changes: boolean) => void;
};

export const FormView: React.SFC<FormViewProps> = React.memo(
  ({ submissionId, patientNumber, initCallback = () => null }) => {
    useEffect(() => {
      initCallback(!submissionId);
    }, [submissionId, initCallback]);

    if (!submissionId) {
      return (
        <NoInfo>
          <FormattedMessage id="form-view.no-changes" />
        </NoInfo>
      );
    }
    return (
      <FormWrapper
        readOnly
        submissionId={submissionId}
        patientNumber={patientNumber}
      />
    );
  },
);
