import React, { useEffect } from 'react';
import styled from 'styled-components';
import { WidgetPrintHeader } from '@mummssoftware/common-ui';
import HospiceItemSmallTable from '../HospiceItemSet/HospiceItemSmallTable';
import {
  PrintContainer,
  HospiceItemSetPrintBody,
} from '../../components/layout/WrapperDiv';

// way to get next page break with global printing
const PageBreak = styled.div`
  @media print {
    visibility: hidden;
  }
`;

type HospiceItemSetPrintProps = {
  hospiceInfo: mumms.HIS;
  onWidgetReady?: (arg: mumms.ComponentName) => void;
};
export const HospiceItemSetPrint = (props: HospiceItemSetPrintProps) => {
  const { onWidgetReady } = props;

  // on component did mount
  useEffect(() => {
    // callback to alert dashboard the view is ready
    onWidgetReady && onWidgetReady('HospiceItemSet' as mumms.ComponentName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrintContainer>
      <HospiceItemSetPrintBody>
        <WidgetPrintHeader title="hospiceInfo" />
        <HospiceItemSmallTable viewModePrint />
      </HospiceItemSetPrintBody>
      <PageBreak />
    </PrintContainer>
  );
};

export default HospiceItemSetPrint;
