import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _isNil from "lodash-es/isNil";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export function isResponse(response) {
  return response instanceof Response;
}

function isPostOptions(options) {
  return 'data' in options;
}

const baseOptions = {
  headers: {},
  qs: {}
}; // TODO: Better error handling.

export class Request {
  constructor({
    url,
    signal
  }) {
    _defineProperty(this, "url", void 0);

    _defineProperty(this, "signal", void 0);

    _defineProperty(this, "basePath", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    this.url = new URL(url);
    this.basePath = this.url.pathname;
    this.signal = signal;
  }

  setParam(key, value) {
    this.url.searchParams.set(key, value);
  }

  deleteParam(key) {
    this.url.searchParams.delete(key);
  }

  mergeOptions(options) {
    const newOpts = _objectSpread(_objectSpread({}, baseOptions), options);

    return isPostOptions(newOpts) ? newOpts : newOpts;
  }

  getPathName(api) {
    if (this.basePath !== '/') {
      return this.basePath + (api.startsWith('/') ? api : `/${api}`);
    }

    return api;
  }
  /**
   * Split this functionality out from the "setup" method so that it can be overridden
   * in the PimPgRest class for building the queryparms portion of the url.
   */


  setParamsFromQs(queryString) {
    if (queryString) {
      Object.entries(queryString).forEach(([k, v]) => {
        if (!_isNil(v)) {
          this.setParam(k, v);
        }
      });
    }
  }

  setup(options) {
    const mergedOptions = this.mergeOptions(options);
    this.setParamsFromQs(mergedOptions.qs); //  if (mergedOptions.qs) {
    //    Object.entries(mergedOptions.qs).forEach(([k, v]) => {
    //      if (!isNil(v)) {
    //        this.setParam(k, v);
    //      }
    //    });
    //  }

    this.url.pathname = this.getPathName(mergedOptions.api);
    return mergedOptions;
  }

  cleanup(options) {
    Object.keys(options.qs).forEach(k => {
      this.deleteParam(k);
    });
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */


  parseJSON(response) {
    /* eslint-enable */
    if (response.status === 204 || response.status === 205) {
      return null;
    }

    const contentType = response.headers.get('content-type');

    if (response.ok && typeof contentType === 'string' && contentType.includes('application/json')) {
      const backRes = response.clone();
      return response.json().catch(() => backRes);
    }

    return response;
  }

  get(args = {
    headers: {},
    qs: {}
  }) {
    let options;

    if (typeof args === 'string') {
      options = _objectSpread(_objectSpread({}, baseOptions), {}, {
        api: args
      });
    } else {
      options = args;
    }

    const mergedOptions = this.setup(options);
    return fetch(this.url.href, {
      method: 'GET',
      mode: 'cors',
      signal: this.signal,
      headers: new Headers(_objectSpread(_objectSpread({}, this.commonHeaders), mergedOptions.headers))
    }).then(this.parseJSON).finally(() => {
      this.cleanup(mergedOptions);
    });
  }

  post(args = {
    headers: {},
    qs: {}
  }) {
    let options;

    if (typeof args === 'string') {
      options = _objectSpread(_objectSpread({}, baseOptions), {}, {
        api: args
      });
    } else {
      options = args;
    }

    options.method = options.method || 'POST';
    const mergedOptions = this.setup(options);
    return fetch(this.url.href, {
      method: mergedOptions.method,
      mode: 'cors',
      signal: this.signal,
      headers: new Headers(_objectSpread(_objectSpread({}, this.commonHeaders), mergedOptions.headers)),
      body: typeof options.data === 'string' ? options.data : JSON.stringify(options.data)
    }).then(this.parseJSON).finally(() => {
      this.cleanup(mergedOptions);
    });
  }

  delete(args = {
    headers: {},
    qs: {}
  }) {
    let options;

    if (typeof args === 'string') {
      options = _objectSpread(_objectSpread({}, baseOptions), {}, {
        api: args
      });
    } else {
      options = args;
    }

    const mergedOptions = this.setup(options);
    return fetch(this.url.href, {
      method: 'DELETE',
      mode: 'cors',
      signal: this.signal,
      headers: new Headers(_objectSpread(_objectSpread({}, this.commonHeaders), mergedOptions.headers))
    }).then(this.parseJSON).finally(() => {
      this.cleanup(mergedOptions);
    });
  }

}
export default Request;