import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'als.considerations.title',
  },
  one: {
    id: 'als.considerations.one',
  },
  two: {
    id: 'als.considerations.two',
  },
  three: {
    id: 'als.considerations.three',
  },
  four: {
    id: 'als.considerations.four',
  },
  five: {
    id: 'als.considerations.five',
  },
  six: {
    id: 'als.considerations.six',
  },
});
