const keycloakAdapter = window.Keycloak;

const KeycloakInitializer = (() => {
  const config = {
    realm: '',
    url: '',
    resource: '',
    clientId: '',
    'ssl-required': 'external',
    'public-client': true,
    'confidential-port': 0
  };
  let keycloak;

  function setKeycloak() {
    try {
      keycloak = keycloakAdapter(config);
    } catch (e) {
      throw new Error(e);
    }
  }

  return {
    get keycloak() {
      if (!keycloak) {
        setKeycloak();
      }

      return keycloak;
    },

    set keycloak(additionalConfig) {
      if (!additionalConfig.resource && additionalConfig.clientId) {
        additionalConfig.resource = additionalConfig.clientId;
      }

      Object.entries(additionalConfig).forEach(([key, value]) => {
        if (key in config) {
          config[key] = value;
        }
      });
      setKeycloak();
    }

  };
})();

export default KeycloakInitializer;