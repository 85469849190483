export const getLdapDn = tokenParsed => (tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.ldapDN) || (tokenParsed === null || tokenParsed === void 0 ? void 0 : tokenParsed.ldapDn) || '';
export const getAgency = ldapDn => ldapDn && ldapDn.split(',ou=')[2];
export const isMummsAdmin = tokenParsed => {
  var _tokenParsed$realm_ac;

  return tokenParsed && ((_tokenParsed$realm_ac = tokenParsed.realm_access) === null || _tokenParsed$realm_ac === void 0 ? void 0 : _tokenParsed$realm_ac.roles.includes('admin'));
};
export const validateAgency = (tokenParsed, agency) => getAgency(getLdapDn(tokenParsed)) === agency || isMummsAdmin(tokenParsed);
export const getHbcLocation = (siteId, siteList) => {
  const currentSite = siteList.length ? siteList.find(({
    id
  }) => id === siteId) : null;
  return currentSite ? currentSite.hbcLocation : null;
};