export const ATTENDING_FORM = 'ATTENDING';
export const AUTH_FAILURE = 'auth failed';
export const CERTIFYING_FORM = 'CERTIFYING';
export const FORM_NARRATIVE = 'formNarrative';
export const FORM_NARRATIVE_CREATION_DATE = 'formNarrativeCreationDate';
export const FORM_NARRATIVE_ID = 'formNarrativeId';
export const FORM_PHYS = 'formPhys';
export const FORM_PHYS_VERBAL_DATE = 'formPhysVerbalDate';
export const FORM_PHYS_WRITTEN_DATE = 'formPhysWrittenDate';
export const FORM_TAKEN_BY_FIRST = 'formTakenByFirstName';
export const FORM_TAKEN_BY_LAST = 'formTakenByLastName';
export const FORM_VERBAL_TAKEN_BY_FIRST = 'formVerbalTakenByFirstName';
export const FORM_VERBAL_TAKEN_BY_LAST = 'formVerbalTakenByLastName';
export const FORM_VERBAL_TAKEN_BY_CREDENTIALS = 'formVerbalTakenByCredentials';
export const GRID_COLUMN_KEY = 'listing-columns';
export const GRID_FILTER_KEY = 'listing-filter-model';
export const GRID_SORT_KEY = 'listing-sort-model';
export const APPROVAL_GRID_COLUMN_KEY = 'approval-listing-columns';
export const APPROVAL_GRID_FILTER_KEY = 'approval-listing-filter-model';
export const APPROVAL_GRID_SORT_KEY = 'approval-listing-sort-model';
export const KEYCLOAK_TOKENS = 'keycloak-tokens';
export const NO_ATTENDING = -1;
export const RESTRICTED_ROLES = ['NP', 'PA'];
export const UNEXPECTED_TYPE = 'unexpected file type';

/* for CertContext reducer */
export const RESET_VALUES = 'RESET_VALUES';
export const SET_DOCTORS = 'SET_DOCTORS';
export const SET_DOCTOR = 'SET_DOCTOR';
export const SET_DATE = 'SET_DATE';
export const SET_FORM_TYPE = 'SET_FORM_TYPE';
export const SET_PICKER_STATE = 'SET_PICKER_STATE';
export const SET_PIM = 'SET_PIM';
export const SET_CLEARSCRIPTS = 'SET_CLEARSCRIPTS';
export const SET_CERT_START_DATE = 'SET_CERT_START_DATE';
export const SET_VERBAL_DATE_LIMIT = 'SET_VERBAL_DATE_LIMIT';
export const SET_LATE_CERT = 'SET_LATE_CERT';
export const SET_EMBEDDED = 'SET_EMBEDDED';
export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_VERBAL_DATE = 'SET_VERBAl_DATE';
export const SET_BUTTON_CLICKED = 'SET_BUTTON_CLICKED';
export const SET_SMALL_WIDGET = 'SET_SMALL_WIDGET';

const createCertKeys = (
  formNarrative,
  formNarrativeCreationDate,
  formNarrativeId,
  formPhys,
  formPhysVerbalDate,
  formPhysWrittenDate,
  formTakenByFirstName,
  formTakenByLastName,
  formVerbalTakenByFirstName,
  formVerbalTakenByLastName,
  formVerbalTakenByCredentials,
  dmsPrefix,
  useFormPhys,
) => ({
  get [FORM_NARRATIVE]() {
    return formNarrative;
  },
  get [FORM_NARRATIVE_CREATION_DATE]() {
    return formNarrativeCreationDate;
  },
  get [FORM_NARRATIVE_ID]() {
    return formNarrativeId;
  },
  get [FORM_PHYS]() {
    return formPhys;
  },
  get [FORM_PHYS_VERBAL_DATE]() {
    return formPhysVerbalDate;
  },
  get [FORM_PHYS_WRITTEN_DATE]() {
    return formPhysWrittenDate;
  },
  get [FORM_TAKEN_BY_FIRST]() {
    return formTakenByFirstName;
  },
  get [FORM_TAKEN_BY_LAST]() {
    return formTakenByLastName;
  },
  get [FORM_VERBAL_TAKEN_BY_FIRST]() {
    return formVerbalTakenByFirstName;
  },
  get [FORM_VERBAL_TAKEN_BY_LAST]() {
    return formVerbalTakenByLastName;
  },
  get [FORM_VERBAL_TAKEN_BY_CREDENTIALS]() {
    return formVerbalTakenByCredentials;
  },
  get dmsPrefix() {
    return dmsPrefix;
  },
  get useFormPhys() {
    return useFormPhys;
  },
});

export const ATTD_CERT_KEYS = [
  'attendingNarrative',
  'attendingNarrativeCreationDate',
  'attendingNarrativeId',
  'attendingPhys',
  'attendingPhysVerbalDate',
  'attendingPhysWrittenDate',
  'attendingTakenByFirstName',
  'attendingTakenByLastName',
  'attendingVerbalTakenByFirstName',
  'attendingVerbalTakenByLastName',
  'attendingVerbalTakenByCredentials',
];
export const HOSP_CERT_KEYS = [
  'hospiceNarrative',
  'hospiceNarrativeCreationDate',
  'hospiceNarrativeId',
  'hospicePhys',
  'hospicePhysVerbalDate',
  'hospicePhysWrittenDate',
  'hospiceTakenByFirstName',
  'hospiceTakenByLastName',
  'hospVerbalTakenByFirstName',
  'hospVerbalTakenByLastName',
  'hospVerbalTakenByCredentials',
];
export const ATTD_KEYS_MAPPED = createCertKeys(...ATTD_CERT_KEYS, 'attd', true);
export const CERT_KEYS_MAPPED = createCertKeys(
  ...HOSP_CERT_KEYS,
  'cert',
  false,
);

export const dateFormat = {
  format: 'longUTC',
};
