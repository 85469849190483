/* eslint-disable react/no-this-in-sfc */
/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { Typography, useTheme, Snackbar } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { pick, flatten, cloneDeep } from 'lodash-es';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import moment from 'moment';
import { Loader } from '@mummssoftware/common-ui';
import {
  getDateRange,
  getMeasuresForGraph,
  getTypesByName,
} from '../MeasuresContext/utils/utils';
import { AllMeasuresDefined } from '../MeasuresContext/utils/type';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import { chartOptions, fastCategory, reverseFastMap } from './data';
import {
  chartHeightRatio,
  SET_MEASURE_SELECTED,
  SET_MEASURE_NAME,
  SET_NEW_MEASURE,
  SET_SCORES_SUBCATEGORY,
  SHOW_TABLE,
  MEASURE_TYPE,
  scoresType,
  objective,
  subjective,
} from '../MeasuresContext/utils/constants';
import {
  ScoresNameDiv,
  MeasureNameDiv,
  MeasuresWrapper,
  ChartWrapper,
  MainGraph,
  ScoreNameFocused,
  StyledScoresUIDiv,
  ScoreName,
  ChartNameWrap,
} from '../../components/layout/WrapperDiv';
import defaults from '../../translations/en.json';
import { ScoresNumbers } from '../../components/scores/ScoresNumber';
import { ScoresMethods } from '../../components/scores/ScoresMethods';
import { Submit } from '../../components/scores/Submit';
import { Context } from '../../context';
import { MeasuresForm } from '../../components/measures/MeasuresForm';
import { Divider } from './Divider';

require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);

type MeasuresFullGraphProps = {
  allMeasures: AllMeasuresDefined;
  smallWidget?: boolean;
  height: number;
  siteTimezone?: string | null;
  episodes: mumms.episode[];
};
const texts: NonNullable<mumms.JSONObject> = defaults;

// NOTES: the alert takes care of clearing error messages => handleErrorMsg
// that is why not done in respective scores and measures forms

export const MeasuresFullGraph = React.memo(
  ({
    allMeasures,
    smallWidget,
    height,
    siteTimezone,
    episodes,
  }: MeasuresFullGraphProps) => {
    const {
      props: {
        dashboard: { smallWidgetHeight },
      },
      palette: {
        common: { white },
        graph: { border, graphColor, secondGraphColor },
      },
    } = useTheme();
    // TODO use fontColor
    const {
      dispatch,
      newMeasure,
      nameSelected,
      measureSelected,
      methodSelected,
      isLoading,
    } = useContext(MeasuresContext);
    const { isPatientActive, handleErrorMsg } = useContext(Context);
    const {
      measures,
      measuresTypes,
      allMeasuresTypes,
      alertError,
      alertSuccess,
    } = allMeasures;
    const measureType = localStorage.getItem(MEASURE_TYPE);
    const isScores = measureType === scoresType;

    const scoresMetrics: any = allMeasuresTypes[scoresType];
    const scoresCategory: mumms.ScoresCategory =
      scoresMetrics &&
      [...scoresMetrics].reduce((seed: any, score: any) => {
        seed[score.shortname] = score.subcategory;
        return seed;
      }, {});

    // todo should be done in Context in index.tsx
    const scoresSubcategory: mumms.SubcategoryType =
      scoresMetrics &&
      [...scoresMetrics].reduce((seed: any, score: any) => {
        if (!seed[score.subcategory]) {
          seed[score.subcategory] = [score.shortname];
        } else {
          seed[score.subcategory].push(score.shortname);
        }
        return seed;
      }, {});

    useEffect(() => {
      scoresSubcategory &&
        dispatch({
          type: SET_SCORES_SUBCATEGORY,
          payload: scoresSubcategory,
        });
    }, []);

    const [measuresTypesState, setMeasuresTypesState] = useState<
      mumms.measureType[]
    >(measuresTypes);

    useEffect(() => {
      Highcharts.charts.forEach((chart) => {
        chart && chart.reflow();
      });
    }, [methodSelected]);

    // if we have not selected a specific measure nor adding a new measure then all measures shown
    useEffect(() => {
      if (!measureSelected && !newMeasure) {
        setMeasuresTypesState(measuresTypes);
      }
    }, [measuresTypes, measureSelected, newMeasure]);

    useEffect(() => {
      setMeasuresTypesState(measuresTypes);
    }, [isScores, measuresTypes]);

    // ['bp', 'weight' ...]
    const measuresSelected = measuresTypesState.map(
      (measure) => measure.shortname,
    );
    // *****ALERT******
    const [alertMessage, setAlertMessage] = useState<string | null>(
      alertError || alertSuccess,
    );

    useEffect(() => {
      const alert = alertError || alertSuccess;
      setAlertMessage(alert);
    }, [alertError, alertSuccess]);

    const [alertOpen, setAlertOpen] = useState<boolean>(!!alertMessage);
    useEffect(() => {
      setAlertOpen(!!alertMessage);
    }, [alertMessage, dispatch, handleErrorMsg]);

    useEffect(() => {
      if (alertMessage) {
        setTimeout(() => {
          setAlertOpen(false);
          handleErrorMsg();
        }, 1000);
      }
    }, [alertMessage, handleErrorMsg]);
    // *****END OF ALERT******

    // allMeasuresPicked = {bp: [data], weight: [data]}
    let allMeasuresPicked;
    let sortedScoresTypes;
    let allActiveMeasuresTypes;

    // only for SCORES
    if (isScores) {
      // not mutate measuresTypes directly
      const inUseObjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === objective && m.isinuse === 'true',
      );
      const notInUseObjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === objective && m.isinuse === 'false',
      );
      const objectiveMeasures = [
        ...notInUseObjectiveMeasures,
        ...inUseObjectiveMeasures,
      ];
      const subjectiveMeasures = [...measuresTypesState].filter(
        (m) => m.subcategory === subjective,
      );
      /** Sort by Objective last */
      sortedScoresTypes = [...subjectiveMeasures, ...objectiveMeasures];

      allMeasuresPicked = sortedScoresTypes.reduce((seed, val) => {
        if (val.shortname) {
          const shortName = val.shortname;
          (seed as any)[shortName] = (measures as any)[shortName] || [];
        }
        return seed;
      }, {});
    } else {
      const activeMeasuresTypesAll: any = [...measuresTypesState].reduce(
        (seed, val) => {
          if (val.shortname) {
            const shortName = val.shortname;
            (seed as any)[shortName] = val;
          }
          return seed;
        },
        {},
      );
      allMeasuresPicked = pick(measures, measuresSelected);
      const measuresNames = Object.keys(allMeasuresPicked);
      allActiveMeasuresTypes = measuresNames.reduce((seed, val) => {
        if (activeMeasuresTypesAll[val]) {
          (seed as any).push(activeMeasuresTypesAll[val]);
        }
        return seed;
      }, []);
    }

    const measuresSelectedArr: any = Object.values(allMeasuresPicked);
    const allMeasuresFordate: any = flatten(measuresSelectedArr);
    const dateRange = getDateRange(allMeasuresFordate);

    const typeNames: Record<
      mumms.measureShortNames,
      mumms.measureType
    > = getTypesByName(measuresTypesState);

    // Highcharts does not find moment when loaded as a module using import
    // https://github.com/highcharts/highcharts/issues/8661
    (window as any).moment = moment;

    // siteTimezone as timezone work by site id
    const tz = moment.tz.guess();
    const timezone = siteTimezone || tz;
    Highcharts.setOptions({
      time: {
        timezone,
      },
    });

    /**
     * variables for graph width and height small widget and not
     */
    const maxFullSizeChartHeight = 185;

    const fullChartSize = 140;
    const fullChartHeight = Math.min(fullChartSize, maxFullSizeChartHeight);

    const [fullSizeChartHeight, setFullSizeChartHeight] = useState<number>(
      fullChartHeight,
    );

    // // chart height to update when view switches from stacked graphs to single graph
    useEffect(() => {
      if (!measureSelected && !newMeasure) {
        setFullSizeChartHeight(fullChartHeight);
      }
    }, [measureSelected, fullChartHeight, newMeasure]);

    const chartHeight = fullSizeChartHeight;

    const variant = 'body1';

    const fullSizeChart = chartHeightRatio * height;

    const setMeasure = (name: string) => {
      dispatch({
        type: SET_MEASURE_SELECTED,
        payload: name,
      });
      const measure = measuresTypes.filter((m) => m.shortname === name);
      // set the state to only the measure selected
      // adjust the size of the graph to individual size
      setMeasuresTypesState(measure);
      setFullSizeChartHeight(fullSizeChart);

      // when double clicking on name selected hide or show
      // think about adding a new measure as well
      if (name !== nameSelected && !newMeasure) {
        dispatch({
          type: SHOW_TABLE,
          payload: true,
        });
        dispatch({
          type: SET_MEASURE_NAME,
          payload: name,
        });
      } else {
        dispatch({
          type: SHOW_TABLE,
          payload: false,
        });
        dispatch({
          type: SET_MEASURE_NAME,
          payload: null,
        });
        dispatch({
          type: SET_MEASURE_SELECTED,
          payload: null,
        });
        dispatch({
          type: SET_NEW_MEASURE,
          payload: false,
        });
      }
    };

    // function to get access to this
    function formatter(this: any) {
      // special tooltip for fast
      const y = (reverseFastMap as any)[this.y];
      const text = `<br/><span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${y}`;
      return text;
    }

    const getSingleChartUI = (
      chartmeasures: mumms.measure[],
      name: mumms.measureShortNames,
      index: number,
    ) => {
      const show2ndData = typeNames[name].isgraphwithsecondaryval === 'true';
      const options: any = cloneDeep(chartOptions(border));
      options.chart.height = chartHeight;
      options.chart.marginLeft = smallWidget ? 0 : 60;
      (options.yAxis as any).visible = !smallWidget;

      let measureValues: any;

      // FAST category
      if (name === 'fast') {
        (options.yAxis as any).categories = fastCategory;
        measureValues = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value',
          'fast',
        );
        (options.tooltip as any).pointFormatter = formatter;
      } else {
        measureValues = getMeasuresForGraph(chartmeasures, dateRange, 'value');
      }

      if (episodes && episodes.length > 1 && measureSelected) {
        const plotBands: any[] = [];
        for (let i = 0; i < episodes.length - 1; i++) {
          plotBands.push({
            color: border,
            from: episodes[i].utcDischargeDate,
            to: episodes[i + 1].utcReferralDate,
            zIndex: 5,
          });
        }
        (options.xAxis as any).plotBands = plotBands;
      } else {
        (options.xAxis as any).plotBands = null;
      }

      (options.xAxis as any).visible = true;

      const firstSerie = {
        id: name,
        data: measureValues,
        name,
        type: 'line',
        color: graphColor,
        fillOpacity: 0.3,
      };
      options.series = [firstSerie];

      if (show2ndData) {
        // backend value2 to graph 2nd value
        const measureValues2 = getMeasuresForGraph(
          chartmeasures,
          dateRange,
          'value2',
        );
        const secondSerie = {
          id: 'other',
          data: measureValues2,
          name,
          type: 'line',
          color: secondGraphColor,
          fillOpacity: 0.3,
        };
        options.series = [firstSerie, secondSerie];
      }

      // function to get access to this
      function smallWidgetFormatter(this: any) {
        // special tooltip for fast
        const y = name === 'fast' ? (reverseFastMap as any)[this.y] : this.y;
        const text = `${y}`;
        return text;
      }

      if (smallWidget) {
        (options as any).tooltip = {
          borderWidth: 1,
          pointFormatter: smallWidgetFormatter,
          headerFormat: '',
          crosshairs: [true],
        };
      }
      // had put a key in hoping it would trigger a HighChart re-render
      return (
        <ChartNameWrap key={index}>
          {getSingleNameUI(name)}
          <HighchartsReact
            key={index}
            highcharts={Highcharts}
            options={options as any}
          />
          {!measureSelected && (
            <Divider allMeasures={allMeasures} name={name} />
          )}
        </ChartNameWrap>
      );
    };

    const getChartUI = (chartmeasures: mumms.formattedAllMeasures) => {
      if (chartmeasures && Object.keys(chartmeasures).length) {
        return Object.entries(chartmeasures).reduce(
          (seed: any, measure: any, index: number) => {
            const name = measure[0];
            const data = measure[1];
            seed.push(getSingleChartUI(data, name, index));
            return seed;
          },
          [],
        );
      }
      return null;
    };

    const getSingleNameUI = (name: mumms.measureShortNames) => {
      const showScoresNumbers = isPatientActive;
      const fullSizeName = methodSelected ? (
        <ScoreName>{name.toUpperCase()}</ScoreName>
      ) : (
        <ScoreNameFocused onClick={() => setMeasure(name)}>
          {name.toUpperCase()}
        </ScoreNameFocused>
      );
      return (
        <ScoresNameDiv key={name} color={border}>
          <MeasureNameDiv
            smallWidget={smallWidget}
            showScoreForm={!!methodSelected}
          >
            {fullSizeName}
          </MeasureNameDiv>
          {showScoresNumbers && isScores && (
            <ScoresNumbers name={name} scoresCategory={scoresCategory} />
          )}
        </ScoresNameDiv>
      );
    };

    const chartWrapperId = 'chart-full';
    // todo better handling of this
    const headerHeight = 30;
    const expandHeight = 40;

    // *****Alert saving measures****
    const Alert = (props: AlertProps) => (
      <MuiAlert elevation={6} variant="filled" {...props} />
    );
    const open = alertOpen;
    const handleClose = () => setAlertOpen(false);

    // eslint-disable-next-line no-nested-ternary
    return !Object.values(allMeasuresPicked).length && !newMeasure ? (
      <Typography variant={variant}>{texts.noMeasures}</Typography>
    ) : (
      <MainGraph id="main-graph" isScores={isScores || !isPatientActive}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            onClose={handleClose}
            severity={alertError ? 'error' : 'success'}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {isLoading && <Loader />}
        {!measureSelected && isPatientActive && isScores && <ScoresMethods />}
        <MeasuresWrapper
          id="graph-area"
          smallWidget={smallWidget}
          key={`${isScores.toString()}-${measureSelected}`}
          // openMeasures={!isScores && !measureSelected}
        >
          <ChartWrapper id={chartWrapperId}>
            {getChartUI(allMeasuresPicked)}
          </ChartWrapper>
        </MeasuresWrapper>
        {!measureSelected && isPatientActive && isScores && <Submit />}
        {!measureSelected && !isScores && isPatientActive && (
          <StyledScoresUIDiv id="measures-form">
            <MeasuresForm
              chartHeight={chartHeight + headerHeight + expandHeight}
              measuresTypes={allActiveMeasuresTypes as mumms.measureType[]}
              key={alertMessage ? 'key1' : 'key0'}
            />
          </StyledScoresUIDiv>
        )}
      </MainGraph>
    );
  },
);

export default MeasuresFullGraph;
