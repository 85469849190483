import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Request from './Request';
export class Scheduler extends Request {
  constructor({
    url,
    token: initToken,
    keycloak,
    agency: _agency,
    siteId: _siteId,
    tenId: _tenId
  }) {
    super({
      url
    });

    _defineProperty(this, "keycloak", void 0);

    _defineProperty(this, "siteId", void 0);

    _defineProperty(this, "tenId", void 0);

    _defineProperty(this, "agency", void 0);

    _defineProperty(this, "commonHeaders", {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    _defineProperty(this, "saveEvent", async (eventObj, siteId, agency) => {
      const response = await this.post({
        api: '/scheduler/api/events',
        data: eventObj,
        method: 'POST',
        qs: {
          siteId,
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "deleteEvent", async (eventId, siteId, agency) => {
      const response = await this.delete({
        api: `/scheduler/api/events/${eventId}`,
        qs: {
          siteId,
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "deleteEvents", async (eventIds, siteId, agency) => {
      const response = await this.delete({
        api: `/scheduler/api/events/`,
        qs: {
          eventIds: eventIds && eventIds.length ? eventIds.toString() : null,
          siteId,
          agency
        }
      });
      return response;
    });

    _defineProperty(this, "getAllEvents", async (agency, startDate, endDate, tenId, siteId, personId, patIdListArgs, roleId, roleListArgs, filterArgs) => {
      let filters;
      let patIdList;
      let roleList;

      if (filterArgs && filterArgs.length) {
        filters = filterArgs.toString();
      }

      if (patIdListArgs && patIdListArgs.length) {
        patIdList = patIdListArgs.toString();
      }

      if (roleListArgs && roleListArgs.length) {
        roleList = roleListArgs.toString();
      }

      const response = await this.get({
        api: `/scheduler/api/events`,
        qs: {
          agency,
          startDate,
          endDate,
          tenId,
          siteId,
          personId,
          patIdList,
          roleId,
          roleList,
          filters
        }
      });
      return response;
    });

    _defineProperty(this, "getEventAverages", async (agency, tenId, siteId, period, startDate, endDate, personId, roleGroupId, roleId) => {
      const response = await this.get({
        api: '/scheduler/api/events/averages',
        qs: {
          agency,
          tenId,
          siteId,
          period,
          startDate,
          endDate,
          personId,
          roleGroupId,
          roleId
        }
      });
      return response;
    });

    _defineProperty(this, "getPatientCarePlanFrequencies", async (agency, siteId, tenId, patientNumber, roleList, fromDate, toDate) => {
      const response = await this.get({
        api: `/scheduler/api/patient-care-plans`,
        qs: {
          agency,
          siteId,
          tenId,
          patientNumber,
          roleList,
          fromDate,
          toDate
        }
      });
      return response;
    });

    _defineProperty(this, "savePatientCarePlanFrequency", async carePlanFrequencyObj => {
      const response = await this.post({
        api: '/scheduler/api/patient-care-plans',
        data: carePlanFrequencyObj,
        method: 'POST',
        qs: {
          siteId: this.siteId,
          agency: this.agency
        }
      });
      return response;
    });

    _defineProperty(this, "updatePatientCarePlanFrequency", async carePlanFrequencyObj => {
      const response = await this.post({
        api: '/scheduler/api/patient-care-plans',
        data: carePlanFrequencyObj,
        method: 'PUT',
        qs: {
          siteId: this.siteId,
          agency: this.agency
        }
      });
      return response;
    });

    _defineProperty(this, "patchPatientCarePlanFrequency", async (carePlanFrequencyId, updatesObj) => {
      const response = await this.post({
        api: `/scheduler/api/patient-care-plans/${carePlanFrequencyId}`,
        method: 'PATCH',
        qs: _objectSpread({
          siteId: this.siteId,
          agency: this.agency
        }, updatesObj)
      });
      return response;
    });

    _defineProperty(this, "deletePatientCarePlanFrequency", async carePlanId => {
      const response = await this.delete({
        api: `/scheduler/api/patient-care-plans/${carePlanId}`
      });
      return response;
    });

    _defineProperty(this, "getPatIdsInRange", async (agency, siteId, tenId, startDate, endDate, patIdList, filters, personId) => {
      if (patIdList && patIdList.length === 1 && patIdList[0]) {
        return {
          data: [patIdList[0]]
        };
      }

      const response = await this.get({
        api: '/scheduler/api/patients',
        qs: {
          agency,
          siteId,
          tenId,
          startDate,
          endDate,
          personId,
          patIdList,
          filters
        }
      });
      return response;
    });

    _defineProperty(this, "savePatientEpisodes", async (agency, patEpList) => {
      const response = await this.post({
        api: '/scheduler/api/patients/episodes',
        qs: {
          agency
        },
        data: patEpList,
        method: 'PUT'
      });
      return response;
    });

    this.url = new URL(url);
    this.keycloak = keycloak;
    this.agency = _agency;
    this.siteId = _siteId;
    this.tenId = _tenId;

    if (_agency) {
      this.url.searchParams.append('agency', _agency);
    }

    const token = initToken || keycloak.token;
    this.commonHeaders.Authorization = `Bearer ${token}`;
  }

}
export default Scheduler;