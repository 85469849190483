import React, { useContext } from 'react';
import { Watermark } from '@mummssoftware/common-ui';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { HospiceItemSetContext } from '../../context';
import { HospiceItemSmallTable } from './HospiceItemSmallTable';
import { HospiceItemExpandedTable } from './HospiceItemExpandedTable';
import { Container } from '../../components/layout/WrapperDiv';
import { vertical } from './constants';

export declare const RouteProps: import('react-router-dom').RouteComponentProps<{
  patientNumber: string;
}>;

type HospiceItemSetProps = {
  currentUser: mumms.User;
  online: boolean;
  patient?: mumms.patient;
  hospiceInfo: mumms.HIS;
  appTitle?: string;
  view?: string | null;
  smallWidget?: boolean;
} & typeof RouteProps;

export const HospiceItemSetComponent = ({
  smallWidget,
}: HospiceItemSetProps) => {
  const { watermark, errorInfo, clearError } = useContext(
    HospiceItemSetContext,
  );

  const Alert = (props: AlertProps) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );

  return (
    <Container id="hospice-item-set-container">
      {watermark && watermark.show ? (
        <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
      ) : null}
      <Snackbar
        open={!!errorInfo}
        autoHideDuration={3000}
        onClose={() => clearError()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={() => clearError()} severity="error">
          {errorInfo}
        </Alert>
      </Snackbar>
      {smallWidget ? (
        <HospiceItemSmallTable mode={vertical} />
      ) : (
        <HospiceItemExpandedTable />
      )}
    </Container>
  );
};

export default HospiceItemSetComponent;
