import React from 'react';
import { merge } from 'lodash-es';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Loader } from '@mummssoftware/common-ui';
import { formatPostDate } from '@mummssoftware/utils/formatters';
import { IErrors, IFields, IFormState, IValues } from './interfaces';
import { MEASURE_TYPE } from '../../containers/MeasuresContext/utils/constants';

type ScoreName = any;
interface IFormStateEnhanced extends IFormState {
  names: string[];
  unitsValues: any;
  commentsValues: any;
}
export interface IFormContext extends IFormStateEnhanced {
  /* Function that allows values in the values state to be set */
  setValues: (values: IValues) => void;
  setUnitsValues: (values: IValues) => void;
  setCommentsValues: (values: IValues) => void;
  /* Function that validates a field */
  validate: (fieldName: string) => void;
}

/*
 * The context which allows state and functions to be shared with Field.
 * Note that we need to pass createContext a default value which is why undefined is unioned in the type
 */
export const FormContext = React.createContext<IFormContext>(undefined!);

/**
 * Validates whether a field has a value
 * @param {IValues} values - All the field values in the form
 * @param {string} fieldName - The field to validate
 * @returns {string} - The error message
 */
export const required = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === ''
    ? 'This must be populated'
    : '';

interface IFormProps {
  patientNumber: string;
  patientId: number;
  personId?: string | null;
  patientSiteId: string | null;
  /* The props for all the fields on the form */
  fields: IFields;
  /* A prop which allows content to be injected */
  render: () => React.ReactNode;
  /* event handler */
  handleMeasuresChange: (
    patientNumber: string,
    score: mumms.measure,
    method: 'POST' | 'PUT',
    measureSelected: string,
  ) => Promise<void>;
  /* cancel adding measures handler */
  handleCancel: () => void;
  handleErrorMsg: () => void;
}

const styles = (theme: any) => ({
  focused: {
    padding: '2px 5px',
    margin: 5,
    backgroundColor: (theme.palette as any).graph.graphColor,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: (theme.palette as any).graph.graphColor,
      color: theme.palette.common.black,
    },
  },
});
class MeasuresForm extends React.PureComponent<IFormProps, IFormStateEnhanced> {
  constructor(props: IFormProps) {
    super(props);
    const errors: IErrors = {};
    const values: IValues = {};
    const unitsValues: IValues = {};
    const commentsValues: IValues = {};
    const disabled = true;
    const isLoading = false;
    const names: string[] = [];
    this.state = {
      values,
      unitsValues,
      commentsValues,
      errors,
      disabled,
      isLoading,
      names,
    };
  }

  /**
   * Stores new FIELD values for measures in state
   * @param {IValues} values - The new field values
   */
  private setValues = (newValues: IValues) => {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [newValues.name]: newValues,
      },
      unitsValues: prevState.unitsValues,
      commentsValues: prevState.commentsValues,
      disabled: false,
      names: [...prevState.names, newValues.name],
    }));

    this.validateForm();
  };

  /**
   * Stores new COMMENTS values for measures in state
   * @param {IValues} values - The new field values
   */
  private setCommentsValues = (newValues: IValues) => {
    if (newValues.commentsValue !== '') {
      this.setState((prevState) => ({
        commentsValues: {
          ...prevState.commentsValues,
          [newValues.name]: newValues,
        },
        values: prevState.values,
        unitsValues: prevState.unitsValues,
        disabled: false,
        names: [...prevState.names, newValues.name],
      }));
    }
  };

  /**
   * Stores new UNITS values for measures in state
   * @param {IValues} values - The new field values
   */
  private setUnitsValues = (newValues: IValues) => {
    if (newValues.value === false) {
      const newState = this.state;
      // DELETE => does that go against react do not mutate state???
      // todo check
      delete newState.unitsValues[newValues.name];
      this.setState(newState);
    } else {
      this.setState((prevState) => ({
        unitsValues: {
          ...prevState.unitsValues,
          [newValues.name]: newValues,
        },
        values: prevState.values,
        commentsValues: prevState.commentsValues,
        disabled: false,
        names: [...prevState.names, newValues.name],
      }));
    }
  };

  /**
   * Executes the validation rule for the field and updates the form errors
   * @param {string} fieldName - The field to validate
   * @returns {string} - The error message
   */
  private validate = (fieldName: string) => {
    let newError = '';

    if (
      this.props.fields[fieldName] &&
      this.props.fields[fieldName].validation
    ) {
      newError = this.props.fields[fieldName].validation!.rule(
        this.state.values,
        fieldName,
        this.props.fields[fieldName].validation!.args,
      );
    }
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [fieldName]: newError },
    }));
    return newError;
  };

  /**
   * Returns whether there are any errors in the errors object that is passed in
   * @param {IErrors} errors - The field errors
   */
  private haveErrors(errors: IErrors) {
    const haveError: any[] = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(errors).forEach((key: string) => {
      if (errors[key].length > 0) {
        haveError.push(true);
      }
    });
    const hasError = haveError.length > 1;
    return hasError;
  }

  /**
   * Handles form submission
   * @param {React.FormEvent<HTMLFormElement>} e - The form event
   */
  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    this.setState((prevState) => ({
      values: { ...prevState.values },
      unitsValues: { ...prevState.unitsValues },
      commentsValues: { ...prevState.commentsValues },
      disabled: true,
      isLoading: true,
    }));

    if (this.validateForm()) {
      const submitSuccess: boolean = await this.submitForm();
      // INFO: this submit has a delay right now because
      // we are making a post call for each measure instead of bulk
      // this.props.handleCancel();

      // todo time for the success message to show in the pop up, disabled here as active i the graph
      // this.props.handleErrorMsg();

      // reset the state after submit
      // this.setState({
      //   values: {},
      //   unitsValues: {},
      //   commentsValues: {},
      //   errors: {},
      //   disabled: true,
      //   isLoading: false,
      //   names: [],
      // });
    }
  };

  /**
   * Executes the validation rules for all the fields on the form and sets the error state
   * @returns {boolean} - Whether the form is valid or not
   */
  private validateForm(): boolean {
    const errors: IErrors = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(this.props.fields).map((measureName: string) => {
      errors[measureName] = this.validate(measureName);
    });
    const hasErrors = this.haveErrors(errors);

    this.setState({ errors });
    return !hasErrors;
  }

  /**
   * Submits the form to the http api
   * @returns {boolean} - Whether the form submission was successful or not
   */
  private async submitForm(): Promise<boolean> {
    type Score = mumms.measure;

    const {
      patientNumber,
      patientId,
      personId,
      patientSiteId,
      handleMeasuresChange,
      handleErrorMsg,
    } = this.props;
    const { values, unitsValues, commentsValues } = this.state;
    const newValue = {
      ...values,
    };
    const newUnitsValues = {
      ...unitsValues,
    };
    const newCommentsValues = {
      ...commentsValues,
    };
    const newState = merge(newValue, newUnitsValues, newCommentsValues);
    const timezone = moment.tz.guess();
    const postdate = formatPostDate(Date.now());
    const measures: any = [];
    const measuretype = localStorage.getItem(MEASURE_TYPE);

    try {
      // TODO: UPDATE THIS TO THE BULK ENDPOINT CALL INSTEAD OF A LOOP
      Object.keys(newState).forEach((measure: any) => {
        const measureName = newState[measure].name;
        const measureValue = newState[measure].value || '';
        const commentsValue = newState[measure].commentsValue || '';
        const unitsValue =
          newState[measure].unitsValue || newState[measure].defaultUnit || '';

        const measureData = {
          comments: commentsValue as string,
          id: '',
          measurecategoryshortname: '',
          measuredate: '',
          measuredatetime: postdate as string,
          measuredatetimeshifted: '',
          measuretypeid: '',
          measuretypename: '',
          measuretypeshortname: measureName,
          patientid: patientId.toString() as string,
          patientnumber: patientNumber,
          personcredentials: '',
          personfirstname: '',
          personid: personId as string,
          personlastname: '',
          personnumber: '',
          siteid: patientSiteId as string,
          timezone,
          units: unitsValue,
          value: measureValue as string,
        };
        measures.push(measureData);
      });

      await handleMeasuresChange(
        patientNumber,
        measures,
        'POST',
        measuretype as string,
      ).catch((e) => {
        // eslint-disable-next-line no-shadow
        const errors: IErrors = {};
        const isLoading = false;
        errors[new Date().toString()] = e;
        this.setState({ errors, isLoading });
      });

      const isLoading = false;
      this.setState({ isLoading });

      return true;
      // TODO: Add response validation
    } catch (ex) {
      return false;
    }
  }

  public render() {
    const { errors, disabled, submitSuccess, isLoading } = this.state;
    const { handleCancel } = this.props;

    const context: IFormContext = {
      ...this.state,
      setValues: this.setValues,
      setUnitsValues: this.setUnitsValues,
      setCommentsValues: this.setCommentsValues,
      validate: this.validate,
    };

    return (
      <FormContext.Provider value={context}>
        {isLoading ? <Loader /> : null}
        {this.props.children}
        <form id="scores-form" onSubmit={this.handleSubmit}>
          <div className="measures-form-container">
            {this.props.render()}
            <div style={{ marginTop: '2%' }} className="form-group">
              <span style={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className={(this.props as any).classes.focused}
                  disabled={this.haveErrors(errors) || disabled || isLoading}
                >
                  Submit
                </Button>
              </span>
            </div>
            {submitSuccess && (
              <div className="alert alert-info" role="alert">
                The form was successfully submitted!
              </div>
            )}
            {submitSuccess === false && !this.haveErrors(errors) && (
              <div className="alert alert-danger" role="alert">
                Sorry, an unexpected error has occurred
              </div>
            )}
            {submitSuccess === false && this.haveErrors(errors) && (
              <div className="alert alert-danger" role="alert">
                Sorry, the form is invalid. Please review, adjust and try again
              </div>
            )}
          </div>
        </form>
      </FormContext.Provider>
    );
  }
}
export default withStyles(styles, { withTheme: true })(MeasuresForm);
