/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect, Provider } from 'react-redux';
import {
  getSubmission,
  selectSubmission,
  resetSubmission,
  submission,
} from 'react-formio';
import Form, { FormProps } from './Form';
import store, { injectAsyncReducer } from './store';

const mapStateToProps = (state: any) => ({
  submission: selectSubmission('submission', state),
});

const mapDispatchToProps = (
  dispatch: import('redux').Dispatch,
  // ownProps: FormProps,
) => ({
  getSubmission: ({
    name,
    id,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    done = () => () => {},
    formId = '',
  }: {
    name: string;
    id: string;
    formId?: string;
    done?: import('react-formio').actionCallback;
  }) => dispatch(getSubmission(name, id, formId, done)),
  resetSubmission: (name: string) => dispatch(resetSubmission(name)),
});

/**
 * The same Form component, with redux connected. Requires a Provider above.
 */
export const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form);

/** The connected form component, conveniently with it's own provider. */
export const FormWithProvider = React.memo(
  ({
    currentUser,
    formId,
    formName,
    keycloak,
    additionalMetadata,
    onComponentChange,
    onSubmitDone,
    patientNumber,
    patient,
    submissionId,
    debug,
    theme,
    readOnly,
    readOnlyOnSubmission,
    hooks,
    form,
    i18nFallbackNS,
    useOfflinePlugin,
    locale,
    agency,
    translations,
    onNewForm,
    allowCreationNewForm,
  }: FormProps) => {
    const asyncStore = injectAsyncReducer(
      store(),
      'submission',
      submission({ name: formName }),
    );
    return (
      <Provider store={asyncStore}>
        <ConnectedForm
          currentUser={currentUser}
          formId={formId}
          formName={formName}
          keycloak={keycloak}
          additionalMetadata={additionalMetadata}
          onComponentChange={onComponentChange}
          onSubmitDone={onSubmitDone}
          patientNumber={patientNumber}
          patient={patient}
          submissionId={submissionId}
          debug={debug}
          theme={theme}
          readOnly={readOnly}
          readOnlyOnSubmission={readOnlyOnSubmission}
          hooks={hooks}
          form={form}
          i18nFallbackNS={i18nFallbackNS}
          useOfflinePlugin={useOfflinePlugin}
          locale={locale}
          agency={agency}
          translations={translations}
          onNewForm={onNewForm}
          allowCreationNewForm={allowCreationNewForm}
        />
      </Provider>
    );
  },
);

export default FormWithProvider;
