import React from 'react';
import { renderNoteDate } from '../../utils/utils';

const NotesListPrint = (props) => {
  let data;

  if (props != null && props.notes != null) {
    data = props.notes.map((n) => (
      <div key={n.personname}>
        <h4 style={{ paddingLeft: '5px' }}>
          {renderNoteDate(n)} {n.personname} {n.credentials}
        </h4>
        <article
          style={{
            margin: '10px',
            padding: '10px',
            border: 'solid 0.5px',
            '@media print': {
              maxWidth: '700px',
              borderColor: 'black !important',
            },
          }}
        >
          {n.note}
        </article>
      </div>
    ));
  }

  const readonlyTextArea = {
    fontSize: 14,
    borderColor: '#2e2e2e',
    marginTop: '5px',
    // height: '225px',
    // overflowY: 'scroll',
  };

  return (
    <div>
      <div style={readonlyTextArea}>{data}</div>
    </div>
  );
};

export default NotesListPrint;
