/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import {
  PrintFooter,
  PrintHeader,
  Watermark,
  WidgetHeader,
} from '@mummssoftware/common-ui';
import { FormsTopBar } from './TopBar';
import { Body } from './Body';
import { FormsContext, FormsExtendedPatient } from '../../context';
import { FORM_NAME } from '../Form/constants';

interface AppProps {
  embedded?: boolean;
}

const AppRoot = styled.div`
  margin-left: var(--safe-area-inset-left);
  margin-right: var(--safe-area-inset-right);
  margin-bottom: var(--safe-area-inset-bottom);
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
    height: auto;
  }
`;

const FormsContainer = styled.div`
  min-height: 279px;
  width: 100%;
  position: relative;
`;

type headerOpts = {
  idgTeam?: string | null;
  idgMeetingDate?: string | null;
  hospiceName: string;
  siteName?: string;
  patient?: FormsExtendedPatient;
};

const createPrintHeaders = ({
  idgTeam,
  idgMeetingDate,
  hospiceName,
  siteName,
  patient,
}: headerOpts) => {
  if (patient) {
    return {
      top: `${idgTeam ? `${idgTeam} - ` : ''}${patient.firstName} ${
        patient.lastName
      } (#${patient.patientNumber})`,
      bottom: `${
        idgMeetingDate
          ? `${moment(idgMeetingDate).format('MM/DD-YYYY')} - `
          : ''
      }${hospiceName} ${siteName}`,
    };
  }
  return {
    top: hospiceName,
    bottom: siteName,
  };
};

const Content = ({ embedded }: AppProps) => {
  const headerRef = useRef<HTMLElement>(null);
  const {
    currentUser,
    keycloak,
    patient,
    notcoveredmeds,
    meetingMetadata: { idgTeam, idgMeetingDate },
    formName,
    appTitle,
    onClick,
    dashboardEmbedded,
    smallWidget,
    watermark,
  } = useContext(FormsContext);
  const [{ top, bottom }, setPrintText] = useState({
    top: currentUser.hospiceName,
    bottom: currentUser.siteName,
  });
  const { search } = useLocation();
  const useQuery = () => new URLSearchParams(search);
  const query = useQuery();
  const viewLayout = query.get('viewmode');
  const readOnly: boolean = viewLayout === 'readonly';

  useEffect(() => {
    const { top: newTop, bottom: newBottom } = createPrintHeaders({
      idgTeam,
      idgMeetingDate,
      hospiceName: currentUser.hospiceName,
      siteName: currentUser.siteName,
      patient,
    });
    if (newTop !== top || newBottom !== bottom) {
      setPrintText({ top: newTop, bottom: newBottom });
    }
  }, [
    bottom,
    top,
    currentUser.hospiceName,
    currentUser.siteName,
    idgTeam,
    idgMeetingDate,
    patient,
    setPrintText,
  ]);

  const handleOnClick = () => {
    localStorage.removeItem(FORM_NAME);
    if (onClick) {
      onClick();
    }
  };

  return (
    <AppRoot>
      {!readOnly && (
        <React.Fragment>
          <PrintHeader hospiceName={top} siteName={bottom} />
          <FormsTopBar
            ref={headerRef}
            embedded={embedded}
            currentUser={currentUser}
            patient={patient}
            formName={formName}
            logout={keycloak.logout}
          />
        </React.Fragment>
      )}

      {dashboardEmbedded && (
        <WidgetHeader
          onlyCloseWidget
          appTitle={appTitle || 'Forms'}
          versionInfo={`Forms v${process.env.REACT_APP_FORMS_VERSION}, SmartChart v${process.env.REACT_APP_VERSION}`}
          onClick={handleOnClick}
          smallWidget={smallWidget}
        />
      )}
      <FormsContainer>
        {watermark && watermark.show ? (
          <Watermark readOnly={watermark.readOnly} smallWidget={smallWidget} />
        ) : null}
        <Body
          formName={formName}
          patient={patient}
          headerRef={headerRef}
          readOnly={readOnly}
          dashboardEmbedded={dashboardEmbedded}
          smallWidget={smallWidget}
          watermark={watermark}
        />
        {patient && <PrintFooter patient={patient} />}
      </FormsContainer>
    </AppRoot>
  );
};

export default React.memo(Content);
