import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  forms: {
    covid19: {
      id: 'forms.covid',
    },
    idg: {
      id: 'forms.idg',
    },
    electionaddendum: {
      id: 'forms.electionaddendum',
    },
    nursing: {
      id: 'forms.nursing',
    },
    socialServices: {
      id: 'forms.social-services',
    },
    spiritual: {
      id: 'forms.spiritual',
    },
    physician: {
      id: 'forms.physician',
    },
    volunteer: {
      id: 'forms.volunteer',
    },
    fallRisk: {
      id: 'forms.fall-risk',
    },
  },
  beta: {
    id: 'form-view.beta',
  },
  comingSoon: {
    id: 'form-view.coming-soon',
  },
  chooseQuestionnaire: {
    id: 'forms-common.choose-questionnaire',
    defaultMessage: 'Choose Questionnaire',
  },
});
