/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { DialogBox } from '@mummssoftware/common-ui';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { messages } from '../Medication/messages';
import { MedContext } from '../../App/MedContext';
import { SET_ERROR_MODAL } from '../../App/constants';

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

const errorModal = (medsError, dispatch, onClick) => {
  const listNames =
    medsError.length && medsError.map((name) => <li key={name}>{name}</li>);
  const onOKClick = () =>
    dispatch({
      type: SET_ERROR_MODAL,
      payload: false,
    });
  const onCancelClick = () => {
    dispatch({
      type: SET_ERROR_MODAL,
      payload: false,
    });
    onClick && onClick();
  };
  return (
    <React.Fragment>
      <FormattedMessage {...messages.medsError} />
      <ul>{listNames}</ul>
      <ButtonDiv>
        <Button
          title="OK"
          size="small"
          variant="outlined"
          color="primary"
          onClick={onOKClick}
        >
          OK
        </Button>
        <Button
          title="Cancel"
          size="small"
          variant="outlined"
          color="primary"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </ButtonDiv>
    </React.Fragment>
  );
};

export const Modal = ({
  open,
  toggleModal,
  message,
  error,
  medsError,
  onClick,
}) => {
  const { dispatch } = useContext(MedContext);

  const title = error ? (
    <FormattedMessage {...messages.medsErrorTitle} />
  ) : (
    <FormattedMessage {...messages.modalTitle} />
  );
  const content = error ? (
    errorModal(medsError, dispatch, onClick)
  ) : message.url ? (
    <iframe
      src={message.url}
      width="100%"
      height="800"
      title="ClearScripts"
    ></iframe>
  ) : (
    <span>{message.message}</span>
  );
  return (
    <DialogBox
      open={open}
      fullWidth={!!message.url}
      maxWidth="xl"
      handleClick={toggleModal}
      title={title}
    >
      {content}
    </DialogBox>
  );
};
