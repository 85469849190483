/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { SectionHeader } from '@mummssoftware/common-ui';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridOptions } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '@mummssoftware/common-ui/style/css/ag-theme-hummingbird.css';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { FormsContext } from '../../context';
import { FORM_NAME } from '../Form/constants';

LicenseManager &&
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY as string);

let gridApi: GridApi;

const ListFormsWrapper = styled.div<{
  smallWidget: boolean | undefined;
}>`
  ${(props: any) =>
    props.smallWidget
      ? `
    min-height: ${props.theme.props.dashboard.smallWidgetHeight}px;
    height: ${props.theme.props.dashboard.smallWidgetHeight}px;`
      : 'height: 50vh;'}
`;

type FormName = keyof typeof messages.forms;

/**
 * Add to these list to dynamically display forms.
 * Use form id from apps/forms/src/containers/Forms/messages.ts
 * */
const activeFormsList: Array<FormName> = ['covid19', 'electionaddendum'];
const betaFormsList: Array<FormName> = ['idg'];
const comingSoonFormsList: Array<FormName> = [
  'nursing',
  'socialServices',
  'spiritual',
  'physician',
  'volunteer',
  'fallRisk',
];

type ListFormsProps = {
  dashboardEmbedded?: boolean;
};

/** Beginning of functional component */
export const ListForms = ({ dashboardEmbedded }: ListFormsProps) => {
  const {
    props: {
      grid: gridTheme = {
        className: '',
        rowHeight: 25,
        headerHeight: 25,
      },
    },
  } = useTheme() as any;
  const intl = useIntl();
  const { pathname, search } = window.location;
  const columnHeaderName = intl.formatMessage(messages.chooseQuestionnaire);
  const comingSoonText = intl.formatMessage(messages.comingSoon);
  const betaText = intl.formatMessage(messages.beta);

  const getFormDisplayText = (form: FormName) =>
    intl.formatMessage(messages.forms[form]);

  const activeFormsData = activeFormsList.map((form: FormName) => ({
    key: form,
    status: 'active',
    className: 'active',
    displayText: getFormDisplayText(form),
    embedded: dashboardEmbedded,
  }));

  const betaFormsData = betaFormsList.map((form: FormName) => ({
    key: form,
    status: 'beta',
    className: 'beta',
    displayText: `${getFormDisplayText(form)}${betaText}`,
    embedded: dashboardEmbedded,
  }));

  // TODO see if coming soon text should entirely disappear and coming soon is now only italic text
  const comingSoonFormsData = comingSoonFormsList.map((form: FormName) => ({
    key: form,
    status: 'comingSoon',
    className: 'disabled',
    displayText: `${getFormDisplayText(form)}`,
    // displayText: `${getFormDisplayText(form)}${comingSoonText}`,
    embedded: dashboardEmbedded,
  }));

  // sequence matters here
  const allFormsData = [
    ...activeFormsData,
    ...betaFormsData,
    ...comingSoonFormsData,
  ];

  const { onClick, smallWidget } = useContext(FormsContext);

  const handleOnClick = (nameForm: string) => {
    // issue of timing with on Click as it reload the component and SET_FORM_NAME is reset
    // onClick to get the window to expand
    if (onClick) {
      onClick();
      localStorage.setItem(FORM_NAME, nameForm);
    }
  };

  const gridOptions: GridOptions = {
    columnDefs: [
      {
        headerName: columnHeaderName,
        cellClass: 'no-border',
        field: 'chooseQuestionnaire',
        // use cellRendererFramework for returning JSX
        cellRendererFramework: (params: any) => {
          const { key, className, embedded } = params.data;
          // eslint-disable-next-line no-nested-ternary
          return className === 'disabled' ? (
            <div className={className}>{params.data.displayText}</div>
          ) : embedded ? (
            <a className={className} onClick={() => handleOnClick(key)}>
              {params.data.displayText}
            </a>
          ) : (
            <a className={className} href={`${pathname}/${key}${search}`}>
              {params.data.displayText}
            </a>
          );
        },
      },
    ],
    onGridReady: (params: any) => {
      gridApi = params.api;
      gridApi.sizeColumnsToFit();
    },
  };

  /**
   * OP-27703 Add other forms as "coming soon".
   */
  return (
    <ListFormsWrapper
      smallWidget={smallWidget}
      className={`${gridTheme.className} light`}
    >
      {smallWidget ? null : <SectionHeader title="Choose a Questionnaire" />}
      <AgGridReact
        gridOptions={gridOptions}
        rowData={allFormsData}
        rowHeight={gridTheme.rowHeight}
        // hide the column header (0px) if this is not a small widget
        // - we'll use the section header
        headerHeight={smallWidget ? gridTheme.headerHeight : 0}
      />
    </ListFormsWrapper>
  );
};

export default ListForms;
