import React, { useState, useContext, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ActionIcon,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@mummssoftware/common-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Typography from '@material-ui/core/Typography';
import {
  DividerActionDiv,
  DividerSummaryDiv,
  DividerDiv,
  MeasuresTableWrapper,
} from '../../components/layout/WrapperDiv';
import MeasuresTable from './MeasuresTable';
import { Context } from '../../context';
import { MeasuresContext } from '../MeasuresContext/MeasuresContextProvider';
import { AllMeasuresDefined } from '../MeasuresContext/utils/type';
import {
  SET_TABLE_NAME,
  tableHeight,
} from '../MeasuresContext/utils/constants';

type DividerProps = {
  allMeasures: AllMeasuresDefined;
  name: mumms.measureShortNames;
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Divider = React.memo(({ allMeasures, name }: DividerProps) => {
  const classes = useStyles();
  const {
    props: {
      grid: gridTheme = {
        className: '',
      },
    },
    palette: {
      graph: { graphColor },
    },
  } = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { isPatientActive, patient, currentUser } = useContext(Context);
  const { dispatch, tableSelected, methodSelected } = useContext(
    MeasuresContext,
  );

  const handleChange = () => {
    if (tableSelected === name) {
      setExpanded(!expanded);
      dispatch({
        type: SET_TABLE_NAME,
        payload: null,
      });
    } else {
      dispatch({
        type: SET_TABLE_NAME,
        payload: name,
      });
      setExpanded(true);
    }
  };

  const isPanelExtended =
    tableSelected === name && !methodSelected ? expanded : false;

  const title = isPanelExtended ? 'Hide' : 'More';

  const icon = isPanelExtended ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <DividerDiv>
      <ExpansionPanel expanded={isPanelExtended} onChange={handleChange}>
        <ExpansionPanelSummary>
          <DividerSummaryDiv>
            <DividerActionDiv>
              <ActionIcon
                title={title}
                Icon={icon}
                style={{
                  margin: 0,
                }}
                htmlColor={graphColor}
              />
              <Typography className={classes.heading}>{title}</Typography>
            </DividerActionDiv>
          </DividerSummaryDiv>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Fragment>
            {tableSelected && (
              <MeasuresTableWrapper
                height={tableHeight}
                className={`${gridTheme.className} light`}
              >
                <MeasuresTable
                  patient={patient as mumms.patient}
                  currentUser={currentUser}
                  allMeasures={allMeasures}
                  isPatientActive={isPatientActive}
                />
              </MeasuresTableWrapper>
            )}
          </Fragment>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </DividerDiv>
  );
});
export default Divider;
