import React, {
  Fragment,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  SectionHeader,
} from '@mummssoftware/common-ui';
import CertForm from '../CertForm/CertForm';
import PatientCertHeader from './PatientHeader';
import { CertDispatchContext } from '../../Context';
import { getFormTypeKeys, processCert } from '../../utils';
import {
  CERTIFYING_FORM,
  RESET_VALUES,
  SET_DOCTOR,
  SET_FORM_TYPE,
  SET_PICKER_STATE,
  ATTENDING_FORM,
} from '../../constants';

const PlaceholderPanel = ({ title, detailsProps }) => (
  <ExpansionPanel style={{ width: '100%' }} expanded>
    <ExpansionPanelSummary noCursor>
      <SectionHeader title={title} />
    </ExpansionPanelSummary>
    <ExpansionPanelDetails {...detailsProps}>
      <Grid style={{ minHeight: 30 }} />
    </ExpansionPanelDetails>
  </ExpansionPanel>
);
const toggleNavButtons = (formsLength, selectedFormIndex) => ({
  disableBack: selectedFormIndex === 0,
  disableForward:
    selectedFormIndex === (formsLength ? formsLength - 1 : selectedFormIndex),
});

const getDefaultFormIndex = (forms, passedElectionNumber) => {
  const passedElection = parseInt(passedElectionNumber, 10);
  const lastFormIndex = forms.length ? forms.length - 1 : 0;
  /* eslint-disable no-restricted-globals */
  const lastCompleteFormIndex = forms.findIndex(
    ({ electionNumber, complete }) =>
      typeof passedElection === 'number' && !isNaN(passedElection)
        ? electionNumber === passedElection
        : !complete,
  );
  /* eslint-enable */
  const defaultFormIndex =
    typeof lastCompleteFormIndex === 'number' && lastCompleteFormIndex > -1
      ? lastCompleteFormIndex
      : lastFormIndex;

  return defaultFormIndex;
};

/**
 * @typedef Props
 * @prop {string|number} [certIndex]
 * @prop {import('../CertForm/CertForm').CertFormProps} certProps Props for the CertForm component
 * @prop {string|number} electionNumber
 * @prop {function} formatMessage
 * @prop {function} getCertKeys
 * @prop {import('./PatientHeader').PatientHeaderProps} headerProps Props for the PatientHeader component
 * @prop {number} insuranceCarrierId
 * @prop {function} m2Url
 * @prop {function} navCallback
 * @prop {string} patientNumber
 * @prop {import('../SharedPropDefs').Patient} patient
 * @prop {function} processCerts
 * @prop {function} recordCertification
 * @prop {boolean} [submitting]
 * @prop {boolean} [successMessageOpen]
 * @prop {boolean} [embedded]
 */

/**
 * Certification
 * @param {Props} props
 */
const PatientRecert = ({
  certIndex,
  certProps,
  electionNumber,
  formatMessage,
  getCertKeys,
  headerProps,
  insuranceCarrierId,
  m2Url,
  navCallback,
  patientNumber,
  patient,
  processCerts,
  recordCertification,
  submitting,
  successMessageOpen,
  embedded,
}) => {
  const [prevPatient, setPrevPatient] = useState({});
  const [updates, setUpdatedKeys] = useState([]);
  const [formState, setFormState] = useState({
    currentCertHistory: [],
    disableBack: false,
    defaultDate: null,
    disableForward: false,
    forms: [],
    formPhys: null,
    formPhysId: '',
    formPhysRestricted: '',
    formType: CERTIFYING_FORM,
    generatedOn: '',
    insuranceCarrierId,
    selectedCertIndex: 0,
    selectedFormIndex: parseInt(certIndex, 10),
  });
  const { dispatch } = useContext(CertDispatchContext);
  const getCurrentCert = useCallback(
    () => formState.currentCertHistory[formState.selectedCertIndex],
    [formState.currentCertHistory, formState.selectedCertIndex],
  );

  const handleNewPatient = async (newPatient) => {
    const payerId =
      formState.insuranceCarrierId || newPatient.certPayerIDs[0].id;
    const currentCertHistory = newPatient.certHistory[payerId];
    const forms = await processCerts(currentCertHistory);
    const defaultFormIndex = getDefaultFormIndex(forms, electionNumber);
    let newPosition =
      typeof formState.selectedFormIndex === 'number' &&
        forms[formState.selectedFormIndex - 1]
        ? formState.selectedFormIndex - 1
        : defaultFormIndex;
    if (forms[newPosition].formType !== CERTIFYING_FORM) {
      newPosition = forms[newPosition - 1] ? newPosition - 1 : newPosition;
    }
    const {
      certIndex: selectedCertIndex,
      defaultDate,
      formType,
      formPhys,
      formPhysId,
      formPhysRestricted,
      verbalComplete,
    } = forms[newPosition];
    setFormState({
      selectedCertIndex,
      selectedFormIndex: newPosition,
      generatedOn: new Date(),
      insuranceCarrierId: payerId,
      ...toggleNavButtons(forms.length, newPosition),
      currentCertHistory,
      defaultDate,
      formPhys,
      formPhysId,
      formPhysRestricted,
      formType,
      forms,
      verbalComplete,
    });
  };

  const moveCertificationCursor = (position) => {
    let selectedFormIndex = formState.selectedFormIndex + position;
    if (selectedFormIndex > formState.forms.length - 1) {
      selectedFormIndex = formState.forms.length - 1;
    }
    if (selectedFormIndex < 0) {
      selectedFormIndex = 0;
    }
    const {
      certIndex: selectedCertIndex,
      defaultDate,
      formType,
      formPhys,
      formPhysId,
      formPhysRestricted,
      verbalComplete,
    } = formState.forms[selectedFormIndex];

    setFormState({
      ...formState,
      ...toggleNavButtons(formState.forms.length, selectedFormIndex),
      defaultDate,
      formPhys,
      formPhysId,
      formPhysRestricted,
      formType,
      selectedCertIndex,
      selectedFormIndex,
      verbalComplete,
    });
  };

  useEffect(() => {
    const updateState = async () => {
      const {
        formType,
        notInEpisode,
        formPhysRestricted,
        certIndex: formCertIndex,
        electionNumber: formElectionNumber,
        complete: prevComplete,
        verbalComplete: prevVerbalComplete,
        formPhysId: prevFormPhysId,
      } = formState.forms[formState.selectedFormIndex];
      const updatedCertHistory =
        patient.certHistory[formState.insuranceCarrierId];
      const updatedCert = updatedCertHistory[formState.selectedCertIndex];
      const updatedForm = await processCert({
        cert: updatedCert,
        certIndex: formCertIndex,
        checkRoles: false,
        electionNumber: formElectionNumber,
        formPhysRestricted,
        notInEpisode,
        ...getFormTypeKeys(updatedCert, formType),
      });
      if (
        updatedForm.complete !== prevComplete ||
        updatedForm.verbalComplete !== prevVerbalComplete ||
        updatedForm.formPhysId !== prevFormPhysId
      ) {
        const newForms = [...formState.forms];
        newForms[formState.selectedFormIndex] = updatedForm;
        const newState = {
          ...formState,
          defaultDate: updatedForm.defaultDate,
          formType: updatedForm.formType,
          formPhys: updatedForm.formPhys,
          formPhysId: updatedForm.formPhysId,
          formPhysRestricted: updatedForm.formPhysRestricted,
          verbalComplete: updatedForm.verbalComplete,
          forms: newForms,
          currentCertHistory: updatedCertHistory,
        };
        setFormState(newState);
      }
    };
    // TODO: update forms[formState] if changed; reprocess individual cert
    if (successMessageOpen === true && !updates.length) {
      updateState();
    }
    if (updates.length && successMessageOpen === false) {
      setTimeout(() => {
        setUpdatedKeys([]);
      }, 500);
    }
  }, [updates, successMessageOpen, formState, patient.certHistory]);

  if (patient.patientId !== prevPatient.patientId) {
    setPrevPatient(patient);
    handleNewPatient(patient);
  }

  useEffect(() => {
    const currentCert = getCurrentCert();
    if (currentCert) {
      navCallback({
        hbcLocation: currentCert.episode.hbcLocation,
        number: currentCert.episode.number,
        startDate: currentCert.startDate,
      });
    }
  }, [formState.selectedFormIndex, getCurrentCert, navCallback]);

  // This handles any programmatic dispatches to CertContext (resets, defaults, etc)
  useEffect(() => {
    dispatch({ type: SET_FORM_TYPE, payload: formState.formType });
    const useFormPhys =
      !(certProps.isDoctor && formState.formType === CERTIFYING_FORM) ||
      formState.verbalComplete;
    // @note this is the logic that decides if the pickers are disabled or not
    if (formState.formPhys && useFormPhys) {
      dispatch({
        type: SET_DOCTOR,
        payload: formState.formPhys,
        // verbal complete? Disable it, otherwise, allow the user to choose.
        // (Temporarily, also check if form is attending)
        disablePicker:
          formState.verbalComplete || formState.formType === ATTENDING_FORM,
      });
    } else {
      dispatch({ type: SET_PICKER_STATE, dispatch: false });
      if (useFormPhys) {
        dispatch({ type: RESET_VALUES });
      } else {
        dispatch({ type: SET_DOCTOR, payload: null });
      }
    }
  }, [
    certProps.isDoctor,
    dispatch,
    formState.formType,
    formState.verbalComplete,
    formState.formPhys,
  ]);

  const onCertify = useCallback(
    (...args) =>
      recordCertification(
        formState.insuranceCarrierId,
        formState.selectedCertIndex,
        ...args,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState.insuranceCarrierId, formState.selectedCertIndex],
  );

  const currentCert = getCurrentCert();

  if (!currentCert || !prevPatient) {
    return (
      <Fragment>
        {!embedded && (
          <PatientCertHeader
            key={`patient-header-${patientNumber}`}
            patient={patient}
            primaryAddress={patient.primaryAddress}
            currentCert={{ episode: {} }}
            formatMessage={formatMessage}
            m2Url={m2Url}
            generatedOn={formState.generatedOn}
            {...headerProps}
          />
        )}
        <PlaceholderPanel
          key={`cert-form-${patientNumber}`}
          title={formatMessage({ id: 'Certification' })}
          detailsProps={{ bottom: true }}
        />
      </Fragment>
    );
  }
  return (
    <Fragment>
      {!embedded && (
        <PatientCertHeader
          key={`patient-header-${patientNumber}`}
          patient={patient}
          primaryAddress={patient.primaryAddress}
          currentCert={currentCert}
          formatMessage={formatMessage}
          m2Url={m2Url}
          generatedOn={formState.generatedOn}
          {...headerProps}
        />
      )}
      <CertForm
        key={`cert-form-${patientNumber}`}
        currentCert={currentCert}
        currentCertKeys={getCertKeys(formState.formType)}
        disableBack={formState.disableBack}
        disableForward={formState.disableForward}
        formPhysId={formState.formPhysId}
        formPhysRestricted={formState.formPhysRestricted}
        formType={formState.formType}
        m2Url={m2Url}
        onNavigateCert={moveCertificationCursor}
        patient={patient}
        onCertify={onCertify}
        selectedCertIndex={formState.selectedCertIndex}
        setUpdatedKeys={setUpdatedKeys}
        submitting={submitting}
        updates={updates}
        embedded={embedded}
        {...certProps}
      />
    </Fragment>
  );
};

export default PatientRecert;
