/* eslint-disable indent */
import moment from 'moment-timezone';
import { find, every } from 'lodash-es';
import { fastMap } from '../../Measures/data';

// new Date('11-29-2010')); //doesn't work in safari
export const browserFormatDate = (date: string) =>
  `${moment(date).format('YYYY-MM-DD[T]HH:mm:ss.sssZ')}`;
// most recent first
export const getAscendingMeasures = (measures: mumms.measure[] | undefined) => {
  // need to sort as data not always consistent, measuredatetimeshifted used for timezone
  // new Date('11-29-2010')); //doesn't work in safari
  const formatDatesBrowser: mumms.measure[] | null = measures
    ? measures.map((row: mumms.measure) => {
        const browserdate = browserFormatDate(row.measuredatetimeshifted);
        return {
          ...row,
          browserdate,
        };
      })
    : null;
  const newDateRange: any[] | null = formatDatesBrowser
    ? formatDatesBrowser.map((row: any) => {
        const newdate = new Date(row.browserdate);
        return {
          ...row,
          newdate,
        };
      })
    : null;
  const sortedDates: any[] | null = newDateRange
    ? newDateRange.sort((a, b) => b.newdate.getTime() - a.newdate.getTime())
    : null;
  return sortedDates;
};

// oldest first for timeline
export const getDateRange = (measures: mumms.measure[] | undefined) => {
  // only keep the measures with a value, not going to plot patient refused
  const measuresForDate =
    measures && measures.filter((measure) => measure && measure.value !== '');
  // need to sort as data not always consistent, measuredatetimeshifted used for timezone
  // new Date('11-29-2010')); //doesn't work in safari
  const formatDatesBrowser: mumms.measure[] | null = measuresForDate
    ? measuresForDate.map((row: mumms.measure) => {
        const browserdate = browserFormatDate(row.measuredatetimeshifted);
        return {
          ...row,
          browserdate,
        };
      })
    : null;
  const newDateRange: any[] | null = formatDatesBrowser
    ? formatDatesBrowser.map((row: any) => {
        const newdate = new Date(row.browserdate);
        return {
          ...row,
          newdate,
        };
      })
    : null;
  const sortedDates: any[] | null = newDateRange
    ? newDateRange.sort((a, b) => a.newdate.getTime() - b.newdate.getTime())
    : null;
  const timeShiftedRange = sortedDates?.map(
    (measure) => measure.measuredatetimeshifted,
  );
  const uniqtimeShiftedRange = [
    ...new Set([...(timeShiftedRange as string[])]),
  ];
  return uniqtimeShiftedRange;
};

export const arrayMax = (data: number[]) => Math.max(...data);
export const arrayMin = (data: number[]) => Math.min(...data);

// TODO same utils in scores, common utils?
export const getTypesByName = (data: mumms.measureType[]) =>
  data.reduce((seed, val) => {
    if (val.shortname) {
      const shortName = val.shortname;
      seed[shortName] = val;
    }
    return seed;
  }, {} as Record<string, mumms.measureType>);

export const getMeasuresForGraph = (
  data: mumms.measure[],
  daterange: string[],
  valueInUse: string,
  fast?: string,
) => {
  const graphvalues = daterange.reduce((seed: any, date: any) => {
    // for safari to work
    const newDate = new Date(browserFormatDate(date));
    // HighChart accepts epoch time , epoch = unix()*1000
    const epochDate = moment(newDate).unix() * 1000;
    const measure: any = find(
      data,
      ({ measuredatetimeshifted }: any) => measuredatetimeshifted === date,
    );
    let value;
    if (measure) {
      if (fast) {
        value = (fastMap as any)[measure[valueInUse]];
      } else {
        value = Number(measure[valueInUse]);
      }
    } else {
      value = null;
    }

    seed.push([epochDate, value]);
    return seed;
  }, []);
  // value array [123, 456...]
  const valuearray = graphvalues.map((x: string[]) => x[1]);
  const hasNoValue = every(valuearray, (x) => x === null);
  const measuregraph = hasNoValue ? null : graphvalues;
  // format needed is [[date, value]...]
  return measuregraph;
};

export const formatFullTableDate = (
  date: Date | string | number | moment.Moment,
  // ) => `${moment(date).format('MM-DD-YY h:mm a')}`;
) => `${moment(date).format('MM-DD-YY, hA')}`;

