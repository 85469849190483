import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { difference } from 'lodash-es';
import { Loader } from '@mummssoftware/common-ui';
import { FormattedMessage } from 'react-intl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Flatpickr from 'react-flatpickr';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Button } from '@material-ui/core';
import { DashboardContext } from '../Context/DashboardContext';
import defaults from '../../translations/en.json';
import {
  WidgetConfig,
  PrintWidgetConfigInterface,
  ChoicePrintStateInterface,
} from '../Context/types';
import {
  PrintInterfaceDiv,
  CalendarDiv,
  SubmitDiv,
  PrintSelectionDiv,
} from '../../components/layout/WrapperDiv';
import {
  SET_PRINT_CONFIG,
  SET_PRINT_INTERFACE_OPEN,
  RESET_WIDGETS_READY,
  SET_LOADER,
  SET_ALERT,
  SET_ALERT_MESSAGE,
  SET_PRINT_LAYOUT_OPEN,
} from '../Context/constants';
import {
  radioPrintConfig,
  checkboxPrintConfig,
  Facesheet,
  Manifest,
  printOptionsConfig,
} from './constants';
import 'flatpickr/dist/themes/material_green.css';

const StyledFlatpickr = React.memo(styled(Flatpickr)`
  width: 100%;
  border: 1px solid white;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  font-weight: 500;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1rem;
  margin: 0.2rem;
  &&:focus,
  &&:active {
    outline: none;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
  &&:hover {
    border-color: ${(props) => props.theme.palette.action.active};
  }
`);

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

type PrintInterfaceProps = {
  config: WidgetConfig[];
  printConfigNames: Record<mumms.ComponentName, string>;
};
const useStyles = makeStyles((theme) => ({
  widgets: {
    display: 'flex',
    justifyContent: 'center',
    // NOTE there is a margin left for when displaying radio button
    marginLeft: '2rem',
  },
  dateRange: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    // NOTE there is a maxWidth as by default more space than needed for mumms standards
    maxWidth: '340px',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  formLabel: {
    color: theme.palette.primary.main,
    '&.MuiFormLabel-root.Mui-error': {
      color: theme.palette.error.main,
    },
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5px',
    alignContent: 'center',
    // NOTE there is a maxWidth as by default more space than needed for mumms standards
    maxWidth: '300px',
    flexWrap: 'wrap',
  },
  printRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px',
    alignContent: 'center',
    // NOTE there is a maxWidth as by default more space than needed for mumms standards
    maxWidth: '300px',
    flexWrap: 'wrap',
  },
  radioLabel: {
    fontSize: '14px',
    margin: '0px',
    maxHeight: '30px',
  },
  printRadioLabel: {
    fontSize: '14px',
    margin: '0px',
    maxHeight: '20px',
  },
  // NOTE this is for Meds radio buttons to align w Manifest
  formControlLabel: {
    '&.MuiFormControlLabel-root': {
      marginBottom: '0px',
      minWidth: '135px',
    },
  },
}));
const translations: NonNullable<mumms.JSONObject> = defaults;

export const PrintInterface = ({
  config,
  printConfigNames,
}: PrintInterfaceProps) => {
  const classes = useStyles();
  const { dispatch, widgetsReady, loader } = useContext(DashboardContext);

  // upon componentDidMount is the time to reset widgets ready and printconfig
  useEffect(() => {
    dispatch({
      type: RESET_WIDGETS_READY,
      payload: [],
    });
    // reset it for the keys of the dashboard print components be reset
    dispatch({
      type: SET_PRINT_CONFIG,
      payload: null,
    });
    // not really useful as next message will override the previous but ...
    dispatch({
      type: SET_ALERT_MESSAGE,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardPrintConfig: mumms.ComponentName[] = [...config].map(
    (widget: WidgetConfig) => widget.componentName,
  );

  const printDateRange: WidgetConfig[] | [] = [...config].filter(
    (widget: WidgetConfig) => widget.printRange === true,
  );
  const dateRangeWidget: mumms.ComponentName[] | false =
    !!printDateRange.length &&
    [...printDateRange].map((widget: WidgetConfig) => widget.componentName);

  const printState = dashboardPrintConfig.reduce((seed, widgetName) => {
    if (widgetName === Facesheet) {
      seed[widgetName] = true;
    } else {
      seed[widgetName] = false;
    }
    return seed;
  }, {} as PrintWidgetConfigInterface);

  // filter the widgets with radio button
  const choicePrintConfig: mumms.ComponentName[] = [...config]
    .filter((widget: WidgetConfig) => widget.printChoice)
    .map((widget: WidgetConfig) => widget.componentName);

  const choicePrintState = [...choicePrintConfig].reduce((seed, widgetName) => {
    seed[widgetName] = '';
    return seed;
  }, {} as ChoicePrintStateInterface);

  const checkboxPrintState = [...choicePrintConfig].reduce(
    (seed, widgetName) => {
      seed[widgetName] = checkboxPrintConfig[widgetName]?.option || '';
      return seed;
    },
    {} as ChoicePrintStateInterface,
  );

  const [state, setState] = useState(printState);
  const [readyState, setReadyState] = useState<any>(null);
  const [dateRangeVisible, setDateRangeVisible] = useState<boolean>(false);
  const stateEntries = Object.entries(state);
  const stateValues = Object.values(state);

  const noWidgetsSelected = stateValues.filter((v) => v).length === 0;

  const handleChange = (event: any) => {
    const { name, checked } = event.target;
    let widgetChecked = checked;
    if (checked) {
      const checkboxChecked = (checkboxPrintConfig as any)[name] || {};
      widgetChecked =
        (radioPrintConfig[name as mumms.ComponentName] && {
          view: (radioPrintConfig as any)[name].defaultOption,
          ...checkboxChecked,
        }) ||
        checked;
    }
    setState({
      ...state,
      [name]: widgetChecked,
    });
  };

  const [fromDate, setFromDate] = useState<undefined | Date>(undefined);
  const [toDate, setToDate] = useState<undefined | Date>(undefined);

  const [widgetError, setWidgetError] = useState<undefined | boolean>(
    undefined,
  );
  const [dateError, setDateError] = useState<undefined | boolean>(undefined);

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };
  const handleToDateChange = (date: any) => setToDate(date);
  const submittedState = {
    ...state,
    fromDate,
    toDate,
  };

  const getReadySate = (stateSubmitted: any) => {
    const readystate = Object.entries(stateSubmitted).reduce(
      (seed, [key, value]) => {
        // we are not taking those two keys or !Array.isArray(key)
        if (value && key !== 'fromDate' && key !== 'toDate') {
          seed.push(key);
        }
        return seed;
      },
      [] as any,
    );
    return readystate;
  };

  // when to show the date range
  useEffect(() => {
    const selectedWidgets = getReadySate(state);
    if (dateRangeWidget && selectedWidgets.length) {
      if (
        selectedWidgets.some((name: mumms.ComponentName) =>
          dateRangeWidget.includes(name),
        )
      ) {
        setDateRangeVisible(true);
      } else {
        setDateRangeVisible(false);
      }
    } else {
      setDateRangeVisible(false);
    }
  }, [state, dateRangeWidget]);

  const [printOut, setPrintOut] = useState<boolean>(false);

  const [issues, setIssues] = useState<string[] | null>(null);

  const [printErrorTimer, setPrintErrorTimer] = useState<number | null>(null);

  useEffect(() => {
    // if submit button has been pushed readyState= ['DocLink', 'Facesheet' ...]
    if (readyState) {
      // widgetsReady are the widgets that sent back onWidgetReady()
      const widgetdifference = difference(readyState, widgetsReady);
      setIssues(widgetdifference);
      const isReady = widgetdifference.length === 0;

      // when finally all widgets have signaled ready, then keep going
      if (isReady) {
        // clear the print timeout ID set in submit
        clearTimeout(printErrorTimer as number);
        // close the print interface and stop the loader
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
        dispatch({
          type: SET_PRINT_INTERFACE_OPEN,
          payload: false,
        });
      }
    }

    // need length as useEffect does not detect change in array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetsReady.length, readyState]);

  // if after 18 sec still not ready, open snackbar and print what is available
  useEffect(() => {
    if (printOut) {
      const widgetIssue: string = (issues || readyState).join(', ');

      const payload = (
        <FormattedMessage
          {...translations.printIssue}
          values={{
            widgetIssue,
          }}
        />
      );

      dispatch({
        type: SET_ALERT_MESSAGE,
        payload,
      });
      dispatch({
        type: SET_ALERT,
        payload: true,
      });
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
      dispatch({
        type: SET_PRINT_INTERFACE_OPEN,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printOut]);

  const onSubmit = () => {
    // check for errors
    const widgeterror = stateValues.filter((v) => v).length === 0;
    setWidgetError(!!widgeterror);

    // if radio is print date range then set the error messages
    let dateerror;
    if (isPrintDateRange) {
      dateerror =
        dateRangeVisible && [fromDate, toDate].filter((v) => v).length !== 2;
      setDateError(!!dateerror);
    }

    // if radio is print all dates then clear the fromDate and toDate
    if (!isPrintDateRange) {
      setFromDate(undefined);
      setToDate(undefined);
    }

    // if no error can proceed
    if (!widgeterror && !dateerror) {
      // SET_PRINT_CONFIG to dispatch to widgets if ready or not
      dispatch({
        type: SET_PRINT_CONFIG,
        payload: submittedState,
      });
      setReadyState(getReadySate(submittedState));
      dispatch({
        type: SET_LOADER,
        payload: true,
      });
      dispatch({
        type: SET_PRINT_LAYOUT_OPEN,
        payload: true,
      });
      // set a timeout of 18 sec(?) after hitting submit, if still not working after 18 sec display a error banner and proceed with printing
      const printErrorTimerID = window.setTimeout(() => {
        setPrintOut(true);
      }, 18000);
      // save the print timeout ID
      setPrintErrorTimer(printErrorTimerID);
    }
  };

  // CHECKBOX OPTIONS values
  const [checkboxOptionState, setCheckboxOptionState] = React.useState(
    checkboxPrintState,
  );

  const onOptionClick = async (event: any) => {
    const radioValue = event.target.value;
    const { name } = event.target;
    // Signature or false are the 2 options for Meds, good strategy?
    const optionName =
      radioValue === 'false'
        ? (checkboxPrintConfig as any)[name].option
        : 'false';
    await setCheckboxOptionState({
      ...checkboxOptionState,
      [name]: optionName,
    });
    const optionSelection = { option: optionName };
    setState({
      ...state,
      [name]: Object.assign((state as any)[name], optionSelection),
    });
  };

  // print options for CHECKBOX
  const radioOptionPrintGroup = (name: mumms.ComponentName) => {
    if (checkboxPrintConfig[name]) {
      const selectedWidgets = getReadySate(state);
      if (selectedWidgets.includes(name)) {
        const value = (checkboxPrintConfig as any)[name].option;
        return (
          <div className={classes.radioGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checkboxOptionState[name] === value}
                  onChange={onOptionClick}
                  name={name}
                  value={checkboxOptionState[name]}
                  color="secondary"
                />
              }
              label={value}
              classes={{
                label: classes.radioLabel,
                root: classes.radioLabel,
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  // RADIO PRINT options values
  const [radioState, setRadioState] = React.useState(choicePrintState);

  // eslint-disable-next-line consistent-return
  const handleRadioChange = (name: mumms.ComponentName, event: any) => {
    // TODO fix after Manifest is done
    if (name === Manifest) {
      return undefined;
    }
    const radioName = event.target.value;
    setRadioState({
      ...radioState,
      [name]: radioName,
    });
    const optionSelection = { view: radioName };
    setState({
      ...state,
      [name]: Object.assign(state[name], optionSelection),
    });
  };

  // radio buttons for the widget names
  const radioPrintGroup = (name: mumms.ComponentName) => {
    if (radioPrintConfig[name]) {
      const selectedWidgets = getReadySate(state);
      if (selectedWidgets.includes(name)) {
        const radioConfig: mumms.printConfigView[] = Object.values(
          (radioPrintConfig as any)[name],
        );
        const radioPrintOptions = (_config: any) =>
          _config.map((option: any) => (
            <FormControlLabel
              value={option}
              control={<Radio size="small" />}
              label={option}
              classes={{
                label: classes.radioLabel,
                root: classes.radioLabel,
              }}
            />
          ));

        return (
          <RadioGroup
            aria-label="radio-print"
            className={classes.radioGroup}
            name="radio-print"
            value={
              radioState[name] || (radioPrintConfig as any)[name].defaultOption
            }
            onChange={(event) => handleRadioChange(name, event)}
          >
            {radioPrintOptions(radioConfig)}
          </RadioGroup>
        );
      }
    }
    return null;
  };

  // RADIO PRINT options values
  const [printOptionsState, setPrintOptionsState] = React.useState(
    printOptionsConfig.defaultOption,
  );

  const isPrintDateRange =
    printOptionsState !== printOptionsConfig.defaultOption;

  const handlePrintOptionChange = (event: any) => {
    setPrintOptionsState(event.target.value);
  };

  // radio buttons for the PRINT button
  const printOptionsGroup = () => {
    const radioPrintOptions = () =>
      Object.values(printOptionsConfig).map((option: any) => (
        <FormControlLabel
          value={option}
          control={<Radio size="small" />}
          label={option}
          classes={{
            label: classes.printRadioLabel,
            root: classes.printRadioLabel,
          }}
        />
      ));

    return (
      <RadioGroup
        aria-label="radio-print"
        className={classes.printRadioGroup}
        name="radio-print"
        value={printOptionsState}
        onChange={handlePrintOptionChange}
      >
        {radioPrintOptions()}
      </RadioGroup>
    );
  };

  return (
    <PrintInterfaceDiv id="print-interface-div">
      {loader && <Loader />}

      <div className={classes.widgets}>
        <FormControl
          error={widgetError}
          component="fieldset"
          className={classes.formControl}
        >
          <FormGroup>
            {stateEntries.map((entry) => {
              const widgetName = entry[0];
              const label = printConfigNames[widgetName as mumms.ComponentName];
              const widgetState = entry[1];
              return (
                <PrintSelectionDiv>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={!!widgetState}
                        onChange={handleChange}
                        name={widgetName}
                        color="primary"
                      />
                    }
                    label={label}
                    className={classes.formControlLabel}
                    key={widgetName}
                  />
                  {radioPrintGroup(widgetName as any)}
                  {/* {radioOptionPrintGroup(widgetName as any)} */}
                </PrintSelectionDiv>
              );
            })}
          </FormGroup>
          {widgetError && (
            <FormHelperText>{translations.errorWidgetPrint}</FormHelperText>
          )}
        </FormControl>
      </div>

      {/* PRINT BUTTON SUBMIT */}
      <SubmitDiv id="submit-print-div">
        <Button
          disabled={noWidgetsSelected}
          size="large"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          {translations.print}
        </Button>
        {dateRangeVisible && printOptionsGroup()}
      </SubmitDiv>

      {/* DATE RANGE PICKER */}
      {isPrintDateRange && dateRangeVisible && (
        <div className={classes.dateRange}>
          <FormControl
            error={dateError}
            component="fieldset"
            className={classes.formControl}
          >
            <CalendarDiv>
              {/* <FormLabel component="legend" className={classes.formLabel}>
                {translations.dateRange}
              </FormLabel> */}
              <StyledFlatpickr
                value={fromDate}
                onChange={handleFromDateChange}
                placeholder={translations.fromDate}
              />
              <StyledFlatpickr
                value={toDate}
                onChange={handleToDateChange}
                placeholder={translations.toDate}
              />
            </CalendarDiv>
            {dateError && (
              <FormHelperText> {translations.errorDatePrint}</FormHelperText>
            )}
          </FormControl>
        </div>
      )}
    </PrintInterfaceDiv>
  );
};
