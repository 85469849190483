import React from 'react';
import {
  CellDiv,
  StyledSpan,
  StyledSpanDisabled,
} from '../../components/layout/WrapperDiv';
import { formatFullTableDate } from './utils';

export const LastUpdatedPlanCellRenderer = React.memo((props: any) => {
  const { data } = props && props;

  return (
    <CellDiv>
      <StyledSpanDisabled>Last Updated:</StyledSpanDisabled>
      <StyledSpan>
        {formatFullTableDate(data.modifieddatetime || data.createdatetime)}
      </StyledSpan>
    </CellDiv>
  );
});
