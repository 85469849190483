/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useRef, useState, useEffect } from 'react';
import _ from 'lodash-es';
import { useLocation } from 'react-router-dom';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Pim } from '@mummssoftware/utils';
import { DialogBox } from '@mummssoftware/common-ui';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { WidgetConfig, PrintConfigInterface } from '../Context/types';
import {
  SET_PRINT_INTERFACE_OPEN,
  SET_LOADER,
  SET_PRINT_LAYOUT_OPEN,
  SET_ALERT,
  SET_THEME_OVERLAY,
} from '../Context/constants';
import { DashboardMain, GridDiv } from '../../components/layout/WrapperDiv';
import { SideBar } from '../SideBar';
import { PrintInterface } from '../PrintInterface';
import { DashboardPrintComponent } from '../DashboardPrint';
import { DashboardContext } from '../Context/DashboardContext';
import { DashboardGridComponent } from '../DashboardGrid';
import defaults from '../../translations/en.json';
import { ThemeLoader } from '../ThemeLoader';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

type DashboardProps = {
  config: WidgetConfig[];
  currentUser: mumms.User;
  keycloak: Keycloak.KeycloakInstance;
  patient?: mumms.patient;
  pim: Pim;
  topBarHeight: number;
  logout?: () => void;
};

const translations: NonNullable<mumms.JSONObject> = defaults;

export const DashboardComponent = ({
  config,
  pim,
  keycloak,
  currentUser,
  patient,
  topBarHeight,
  logout,
}: DashboardProps) => {
  const { search } = useLocation();
  const printRef = useRef<HTMLDivElement | null>(null);
  const useQuery = () => new URLSearchParams(search);
  const query = useQuery();
  const agency = query.get('agency');

  const {
    printInterfaceOpen,
    printLayoutOpen,
    dispatch,
    dashboardPrintConfig,
    alertOpen,
    alertMessage,
    themeOverlay,
  } = useContext(DashboardContext);

  const [snackAlertOpen, setSnackAlertOpen] = useState<boolean>(alertOpen);

  useEffect(() => {
    setSnackAlertOpen(alertOpen);
    if (alertOpen) {
      // if user does not click on close, the context won't get updated
      setTimeout(() => {
        dispatch({
          type: SET_ALERT,
          payload: false,
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOpen]);

  // stop theme overlay from showing
  useEffect(() => {
    if (themeOverlay) {
      setTimeout(() => {
        dispatch({
          type: SET_THEME_OVERLAY,
          payload: false,
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeOverlay]);

  const dashboardConfig = _.cloneDeep(config);

  // If agencies in list, do not filter out beta widgets
  const betaAgencies =
    process.env.REACT_APP_WIDGET_BETA === undefined
      ? []
      : process.env.REACT_APP_WIDGET_BETA.split(',');
  const agencyToCheck = currentUser.isMumms
    ? agency
    : currentUser.currentHospiceBin;
  const betaStatus =
    agencyToCheck === null ? false : betaAgencies.includes(agencyToCheck);
  const newConfig = betaStatus
    ? dashboardConfig
    : dashboardConfig.filter((w: WidgetConfig) => w.beta === false);
  const dashboardGridConfig = [...newConfig].sort(
    (a: WidgetConfig, b: WidgetConfig) =>
      Number(a.widgetMenuOrder) - Number(b.widgetMenuOrder),
  );
  const toPrintConfig = [...newConfig].filter((w) => w.print === true);
  const printInterfaceConfig = toPrintConfig.sort(
    (a: WidgetConfig, b: WidgetConfig) =>
      Number(a.printMenuOrder) - Number(b.printMenuOrder),
  );
  const printConfigNames: Record<mumms.ComponentName, string> = [
    ...dashboardGridConfig,
  ].reduce((seed: any, widget: WidgetConfig) => {
    seed[widget.componentName] = widget.widgetTitle;
    return seed;
  }, {});

  const closePrintInterface = () => {
    dispatch({
      type: SET_PRINT_INTERFACE_OPEN,
      payload: false,
    });
    dispatch({
      type: SET_LOADER,
      payload: false,
    });
  };

  const closePrintDialog = () =>
    dispatch({
      type: SET_PRINT_LAYOUT_OPEN,
      payload: false,
    });

  const Alert = (props: AlertProps) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );
  const handleClose = () =>
    dispatch({
      type: SET_ALERT,
      payload: false,
    });

  // NOTE header are from FACESHEET not from printHeader or printFooter
  const pageStyle = `
  @page {
    size: portrait !important;
    margin: 10mm 2mm !important;
  }
  @media print {
    body {
    -webkit-print-color-adjust: exact;
  }
  }
`;

  return (
    <DashboardMain id="dashboard-main">
      <SideBar config={dashboardGridConfig} topBarHeight={topBarHeight} />
      {themeOverlay && <ThemeLoader />}
      <GridDiv>
        <DashboardGridComponent
          pim={pim}
          keycloak={keycloak}
          logout={logout}
          currentUser={currentUser}
          patient={patient}
          config={dashboardGridConfig}
          topBarHeight={topBarHeight}
        />
      </GridDiv>
      <DialogBox
        title={translations.printTitle as string}
        disableBackdropClick
        open={!!printInterfaceOpen}
        handleClick={closePrintInterface}
      >
        <PrintInterface
          config={printInterfaceConfig}
          printConfigNames={printConfigNames}
        />
      </DialogBox>

      <ReactToPrint content={() => printRef.current} pageStyle={pageStyle}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <DialogBox
              title={translations.printDialog as string}
              disableBackdropClick
              open={!!printLayoutOpen}
              handleClick={closePrintDialog}
              fullWidth
              maxWidth="lg"
              onPrint={handlePrint}
              minHeight
            >
              <DashboardPrintComponent
                dashboardPrintConfig={
                  dashboardPrintConfig as PrintConfigInterface
                }
                printConfigNames={printConfigNames}
                ref={printRef}
              />
            </DialogBox>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
      {/* component that is intended only for printing , 2 styles for measures ag grid columns display */}
      {/* <div
        style={
          printInterfaceOpen ? { visibility: 'hidden' } : { display: 'none' }
        }
      >
        <DashboardPrintComponent
          dashboardPrintConfig={dashboardPrintConfig as PrintConfigInterface}
          printConfigNames={printConfigNames}
          ref={printRef}
        />
      </div> */}

      <Snackbar
        open={snackAlertOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </DashboardMain>
  );
};
export default DashboardComponent;
